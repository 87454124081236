<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="metadataApplicationItemDetails.registrations.titleTab" [open]=true>
    <form id="formRegistrations" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

      <!-- Field registration -->
      <div class="form-group col-xl-12">
        <lp-label-field for="registrations.registration" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.registrations.registration"></lp-label-field>
        <lp-text-field id="registrations.registration" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
          cssClass="form-control" name="registrations.registration"
          [(ngModel)]="data.registration"></lp-text-field>
      </div>

      <!-- Field department-->
      <div class="form-group col-xl-12">
        <lp-label-field for="registrations.department" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.registrations.department"></lp-label-field>
        <lp-text-field id="registrations.department" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
          cssClass="form-control" name="registrations.department"
          [(ngModel)]="data.department"></lp-text-field>
      </div>

      <!-- Champ dateRegistration -->
      <div class="form-group col-xl-12">
        <lp-label-field for="registrations.dateRegistration" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.registrations.dateRegistration"></lp-label-field>
        <lp-date id="dregistrations.ateRegistration" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
          name="registrations.dateRegistration" [(ngModel)]="data.dateRegistration"></lp-date>
      </div>

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>

  <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>