import { Component, OnInit } from '@angular/core';
import { ItemComponent } from '../item.component';

@Component({
  selector: 'lp-abstract-item',
  template: ''
})
export class AbstractItemComponent extends ItemComponent implements OnInit {

}
