import { Component, OnInit, Input } from '@angular/core';
import { ValueFieldComponent } from '../value-field.component';

@Component({
  template: ''
})
export class ChoiceFieldComponent extends ValueFieldComponent implements OnInit {

  // Le paramètre Values représente l'ensemble des données qui vont être parcourues par le ngFor dans le template du composant.
  @Input() public values: any[];

  // Le paramètre labelField représente le texte du choix.
  @Input() public labelField: String = 'wording';


  // Le paramètre idField représente la valeur du choix.
  @Input() public idField: String = 'id';

}
