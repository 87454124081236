import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { TranslateService } from '@ngx-translate/core';
import { ZoomMetaData } from '../../models/zoom-metada';
import { RepositoryProvider } from '../../providers/repository.provider';
import { Subscription } from 'rxjs';
import { ZoomService } from '../../services/zoom.service';
import { ReportsProvider, ZOOMID } from 'app/providers/reports.provider';
import { Util } from 'app/statics/utils';
import { RightColService } from 'app/services/root/rightCol.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { LpReportParameters } from 'app/meta/report-parameters';
import { DatetimeService } from 'app/services/datetime.service';
import { ReportService } from 'app/services/report.service';
import { ModalService } from 'app/services/modal.service';
import { LpModalMailComponent } from '../lp-modal/lp-modal-mail/lp-modal-mail.component';

export const KEY_SPINNER: string = 'loading.data';
export const METADATA: string = 'metadata';
export const TITLE_TRANSLATE_KEY: string = 'report.selectReport';

export const KY_LIBELLE: String = 'ky';

// TODO
// gestion de fermeture des écrans
// input pour le verb (tri les resultats en fonction des écrans) -> Noms des écrans à spécifier
// paramétrer les composants dans le formulaire

@Component({
  selector: 'lp-report',
  templateUrl: './lp-report.component.html',
  styleUrls: ['./lp-report.component.scss']
})
export class LpReportComponent implements OnInit, OnDestroy {

 @ViewChild('conatinerCols') public conatinerCols: ElementRef;
 @ViewChild('col1') public col1: ElementRef;
 @ViewChild('col2') public col2: ElementRef;
 @Input() public customerInvoiceKy: string;
 @Input() public ky: string;
 @Input() public isshown: Boolean;
 public formDetailsTab: Array<Array<LpReportParameters>> = new  Array<Array<LpReportParameters>>();
 public formDetailsAgencyEnd: Array<Array<LpReportParameters>> = new  Array<Array<LpReportParameters>>();
 public titleReportFromPrintReport: string;

 public editionType: string = 'EDIT';

 public titleZoom: string = TITLE_TRANSLATE_KEY;
 public metaData: ZoomMetaData;
 public srcPdf: Uint8Array;

 public modelName: String = null;
 public closable: boolean = true;
 public isShownByPrintReport: Boolean = false;
 public dataOutputList: string;

 protected arrayClassesElements: Map<String, String> = new Map<String, String>();

 @Output() protected isShownOutput: EventEmitter<Boolean> = new EventEmitter<Boolean>();

 private subscriptionFieldDetailsEvtEmitter: Subscription;
 private subscriptionZoomEvtEmitter: Subscription;
 private subscriptionChangeRightBlockEvtEmitter: Subscription;

  constructor(
    protected uiSyncService: UiSyncService,
    protected translate: TranslateService,
    protected repositoryProvider: RepositoryProvider,
    protected zoomService: ZoomService,
    protected reportProvider: ReportsProvider,
    protected rightColService: RightColService,
    protected formStackService: FormStackService,
    public ihmStackService: IhmStackService,
    protected dateTimeService: DatetimeService,
    public reportService: ReportService,
    public modalService: ModalService,
    public translateService: TranslateService
    ) {}

  public async ngOnInit(): Promise<void> {
    this.setEditFormActive();
    this.css();
    this.subscribtion();
    if (!this.ihmStackService.ShowPrintReportPDF) {
      this.showListForm();
    }
    this.uiSyncService.loader(true, true, this.translate.instant(KEY_SPINNER));
    this.loadMetaData();
    this.uiSyncService.loader(false);

    this.ky = this.formStackService.CurrentKy;
    if (this.formStackService.currentData['customerInvoice'] !== null
      && this.formStackService.currentData['customerInvoice'] !== undefined) {
      this.customerInvoiceKy = this.formStackService.currentData['customerInvoice']['id'];
    }
    this.init();
  }

  public init(): void {
    if (this.ihmStackService.ShowReport) {
      this.srcPdf = new Uint8Array(this.reportProvider.SrPdfFromPrintReport);
    }
  }

  public printButton(result: Uint8Array): void {
    this.srcPdf = result;
    this.ihmStackService.displayPrintReportPDF()
    this.isShownByPrintReport = true;
  }

  public updateDate(date: Date): string {
    return this.dateTimeService.toString(date);
  }

  public sendDatas(value: string): void {
    this.dataOutputList = value;
  }

  public closeAll(): void {
    this.ihmStackService.ShowReport = false;
    this.reportService.updateTitle();
    this.reportService.updateModelName();
    this.ihmStackService.ShowPrintReportList = true;
    this.ihmStackService.ShowPrintReportForm = false;
    this.ihmStackService.ShowPrintReportPDF = false;
    this.reportService.srcPdf =  null;
    this.ihmStackService.displaySubAPIDetails();
  }

  public return(): void {
    this.reportService.updateTitle();
    this.reportService.updateModelName();
    this.ihmStackService.displayPrintReportList();
  }

  public returnToForm(value: string): void {
    if (value === 'EDIT') {
      this.ihmStackService.ShowPrintReportPDF = false;
      this.ihmStackService.displayPrintReportList();
      this.reportService.updateTitle();
      this.reportService.updateModelName();
    } else if ( value === 'ETAT') {
      this.ihmStackService.ShowPrintReportPDF = false;
      this.ihmStackService.displayPrintReportForm();
    }
  }

   /**
   * Désinscription des différents abonnements.
   */
  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscriptionFieldDetailsEvtEmitter)) { this.subscriptionFieldDetailsEvtEmitter.unsubscribe(); }
    if (!Util.isNullOrUndefined(this.subscriptionZoomEvtEmitter)) { this.subscriptionZoomEvtEmitter.unsubscribe(); }
    if (!Util.isNullOrUndefined(this.subscriptionChangeRightBlockEvtEmitter)) { this.subscriptionChangeRightBlockEvtEmitter.unsubscribe(); }
  }

  public sendRequest(): void {
    try {
      this.modalService.modalPromise(LpModalMailComponent, {
        backdropClass: 'commonToolsBackdropClass'
      }).then( async (closed: boolean) => {
          if ( this.reportService.reportExists() && closed === true) {
              let parameters: Array<LpReportParameters> = await this.reportService.getParameters(this.reportService.itemSelectedInList)
              await this.reportService.executeWithQueryParams(parameters, this.reportService.mail, this.reportService.isSpoolerMode);
              this.modalService.success(this.translateService.instant('general.modalService.mailSent'));
          }
      });
  } catch (e) {
      this.modalService.error(this.translateService.instant('general.modalService.mailNotSent'));
      throw e;
  }
  }

  public pdfExported(value: Uint8Array): void {
    this.srcPdf = value;
  }

  public css(): void {
    this.arrayClassesElements.set('defaultLabel', 'control-label input-smcol-md-5  col-xl-3');
    this.arrayClassesElements.set('defaultInput', 'col-md-10 col-lg-8 col-xl-12');
    this.arrayClassesElements.set('defaultPostcode', 'col-md-4 col-lg-2 col-xl-2 pe-3');
  }

  protected async parameters(ky: string): Promise<Array<LpReportParameters>> {
    return new Promise<Array<LpReportParameters>>( async (resolve) => {
        await this.reportProvider.getParameters(ky).then((parameters: Array<LpReportParameters>) => {
            resolve(parameters);
        });
    });
  }

  protected initDate(arrayOfParam: Array<LpReportParameters>): Array<LpReportParameters> {
    arrayOfParam.forEach((element: LpReportParameters) => {
      if (!Util.isNullOrUndefined(element.value) || (element.name).toLowerCase() === KY_LIBELLE ) {
        if (element.type === 'DATE' && element.value !== null) {
          if (this.dateTimeService.isValid(element.value) && element.value.length === 10) {
           element.value = this.dateTimeService.formatDDMMYYYYtoBddFormat(element.value);
          } else if (this.dateTimeService.isValid(element.value) && element.value.length > 10) {
            element.value = this.dateTimeService.formatDDMMYYYYtoBddFormat(String(this.dateTimeService.dateUiFormat(element.value)));
          }
        } else if ( (element.name).toLowerCase() === KY_LIBELLE ) {
          element.value = this.ky;
        }
      } else {
        element.value = '';
      }
    });
    return arrayOfParam;
  }

  protected buildIHMWith2Columns(array: Array<LpReportParameters>): void {
    let formEnd: Array<any> = new  Array<any>();

    // traitement des dates
    array.forEach( (element) => {
      if ( element && element.type && ( element.type === 'DATE' || element.type === 'DATETIME') ) {
        element.value = this.updateDate(element.value);
      }
    });
    // traitement des 3 champs spéciaux
    for (let i: number = 0; i <= array.length; i = i + 1 ) {
      if ( array[i] && array[i].name
        && ( array[i].name.toUpperCase() === 'LP001SOC'
        || array[i].name.toUpperCase() === 'LP030AGE'
        || array[i].name.toUpperCase() === 'LP033SEC') ) {
        formEnd.push(array[i]);
      }
    }

    if (array && array.length !== 0) {
      for (let i: number = 0; i <= array.length; i = i + 2 ) {
        let form2: Array<any> = new  Array<any>();
          if ( array[i] && array[i].name && array[i].name.toLowerCase() !== 'ky') {

            if ( array[i] && array[i].name && ( array[i].name.toUpperCase() === 'LPTRI' )
            && i === 0 ) {
              // initialisation de la valeur par defaut des LpTri
              ( array[i].value === '' ) ?
              ( ( array[i].listItem && array[i].listItem.length !== 0) ?
                array[i].value = array[i].listItem[0].name : array[i].value = array[i].value ) : array[i].value = array[i].value;
              form2[0] = array[i];
              this.formDetailsTab.push(form2);
              i = -1;
            } else {

            // stock les valeurs dans le tableau final
            if (array[i]) {
              form2[0] = array[i];
            }
            if (array[i + 1]) {
              form2[1] = array[i + 1];
            }
            this.formDetailsTab.push(form2);
          }
        } else {
          i = i - 1;
        }
      }
        // suppression des 3 champs spéciaux
        for (let i: number = 0; i <= this.formDetailsTab.length; i = i + 1 ) {
          for (let k: number = 0; k <= 2; k = k + 1 ) {
            for ( let j: number = 0; j <= formEnd.length; j = j + 1  ) {
              if ( this.formDetailsTab[i] && this.formDetailsTab[i][k] && formEnd[j] ) {
                if ( this.formDetailsTab[i][k] === formEnd[j] ) {
                  this.formDetailsTab[i].splice(k, 1);
                }
              }
            }
          }
        }

        // chargement des 3 champs spéciaux dans le tableau bien formé
        this.formDetailsAgencyEnd.push([formEnd[0], formEnd[1]], [formEnd[2]]);
        this.formDetailsTab.push(this.formDetailsAgencyEnd[0]);
        this.formDetailsTab.push(this.formDetailsAgencyEnd[1]);
    }
  }

/**
 * Chargement des métadonnées
 */
  private async loadMetaData(): Promise<void> {
    await this.repositoryProvider.getZoomMetadata(ZOOMID).then((recordMeta: ZoomMetaData) => {
      this.metaData = recordMeta;
    });
  }

/**
 * Subscribtion
 */
  private subscribtion(): void {
    this.subscriptionFieldDetailsEvtEmitter = this.uiSyncService.fieldDetailsEvtEmitter.subscribe((params) => {
      this.ihmStackService.ShowReport = false;
      this.rightColService.closeAll();
      this.formStackService.currentApplicationItemDetail.editFormActive = false;
      this.isShownOutput.emit(this.ihmStackService.ShowReport);
      this.isShownByPrintReport = false;

     });
     this.subscriptionZoomEvtEmitter = this.zoomService.searchEventEvtEmitter.subscribe((result) => {
      this.ihmStackService.ShowReport = false;
      this.rightColService.closeAll();
      this.isShownOutput.emit(this.ihmStackService.ShowReport);
     });
     this.subscriptionChangeRightBlockEvtEmitter = this.uiSyncService.changeRightBlockEvtEmitter.subscribe((result) => {
      this.ihmStackService.ShowReport = false;
      this.rightColService.closeAll();
      this.isShownOutput.emit(this.ihmStackService.ShowReport);
     });
  }

  /**LIST */
  private showListForm(): void {
    this.ihmStackService.ShowPrintReportList = true;
  }

  private setEditFormActive(): void {
    if (this.formStackService && this.formStackService.currentApplicationItemDetail && this.formStackService.currentApplicationItemDetail.editFormActive &&
      this.formStackService.currentApplicationItemDetail.editFormActive === true) {
      this.formStackService.currentApplicationItemDetail.editFormActive = false;
    }
  }

}
