import { Component, Inject, Input } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ReportService } from 'app/services/report.service';

@Component({
  selector: 'lp-modal-promise-spooler-preview',
  templateUrl: './lp-modal-spooler-preview.component.html'
})
export class LpModalPromiseSpoolerPreviewComponent extends LpModalComponent {

  @Input() public srcPdf: Uint8Array;

  constructor(
    public reportService: ReportService,
    public dialogRef: MatDialogRef<LpModalComponent>,
    public modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    super(dialogRef, data);
  }

}
