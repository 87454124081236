  <div class="save-configuration">
    <div class="container">
      <div class="row">
        <h3 class="col-12 text-center">{{'savePreferences.saveTitle' | translate}}</h3>
      </div>
      <div class="row">
        <div class="col-sm-5">
          <lp-label-field for="user" text="savePreferences.user"></lp-label-field>
        </div>
        <div class="col-sm-7">
          <lp-text-field id="user" className="textField" cssClass="form-control"
              name="user" [ignoreChange]="true" [(ngModel)]="saveConfigUser" [required]="true" autocomplete="cc-csc"></lp-text-field>      
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <lp-label-field for="profile" text="savePreferences.profile"></lp-label-field>
        </div>
        <div class="col-sm-7">
          <lp-text-field id="profile" className="textField" cssClass="form-control"
              name="profile" [ignoreChange]="true" [(ngModel)]="saveConfigProfile" [required]="true" autocomplete="cc-csc"></lp-text-field> 
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <lp-label-field for="location" text="savePreferences.location"></lp-label-field>
        </div>
        <div class="col-sm-7">
          <lp-text-field id="location" className="textField" cssClass="form-control"
              name="location" [ignoreChange]="true" [(ngModel)]="saveConfigLocation" [required]="true" autocomplete="cc-csc"></lp-text-field> 
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <lp-label-field for="company" text="savePreferences.company"></lp-label-field>
        </div>
        <div class="col-sm-7">
          <lp-text-field id="company" className="textField" cssClass="form-control"
              name="company" [ignoreChange]="true" [(ngModel)]="saveConfigCompany" [required]="true" autocomplete="cc-csc"></lp-text-field> 
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-primary me-2 d-inline-block" (click)="validSave()">{{'general.save' | translate}}</button>
        <button class="btn btn-danger d-inline-block" (click)="cancel()">{{'general.button.cancel' | translate}}</button>
      </div>
    </div>
  </div>