<!-- panneau de configuration a droite avec les accordéons dedans -->
<div [hidden]="(!showConfigPanel || isFilterPanelOpen) || showSavePanel" class="scheduler-config-panel">
  <a class="ms-2 btn btn-danger btn-scheduler-panel-close" aria-label="Close" (click)="toggleConfigPanel();">
      <i class="fa fa-times"></i>
  </a>
  <h3 class="col-12">{{'scheduler.configuration' | translate}}</h3>
  <lp-ui-accordion class="row w-100 ms-0" [closeOthers]="true">
    <lp-ui-accordion-group heading="scheduler.day.config" [open]=false key="dayConfig">
      <div class="container">
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="Monday" text="dropdown.monday"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <mat-slide-toggle id="Monday" name="weekend" value="monday" [(ngModel)]="weekendIHM.mon" (change)="defineWeekendDay(weekendIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="Tuesday" text="dropdown.tuesday"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <mat-slide-toggle id="Tuesday" name="weekend" value="tuesday" [(ngModel)]="weekendIHM.tue" (change)="defineWeekendDay(weekendIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="wednesday" text="dropdown.wednesday"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <mat-slide-toggle id="wednesday" name="weekend" value="wednesday" [(ngModel)]="weekendIHM.wed" (change)="defineWeekendDay(weekendIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="Thursday" text="dropdown.thursday"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <mat-slide-toggle id="Thursday" name="weekend" value="thursday" [(ngModel)]="weekendIHM.thu" (change)="defineWeekendDay(weekendIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="Friday" text="dropdown.friday"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <mat-slide-toggle id="Friday" name="weekend" value="friday" [(ngModel)]="weekendIHM.fri" (change)="defineWeekendDay(weekendIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="Saturday" text="dropdown.saturday"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <mat-slide-toggle id="Saturday" name="weekend" value="saturday" [(ngModel)]="weekendIHM.sat" (change)="defineWeekendDay(weekendIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="Sunday" text="dropdown.sunday"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <mat-slide-toggle id="Sunday" name="weekend" value="sunday" [(ngModel)]="weekendIHM.sun" (change)="defineWeekendDay(weekendIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field for="colorWeekEnd" text="scheduler.day.color"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <select [(ngModel)]="weekendColorIHM" class="form-control" [ngClass]="weekendColorIHM" (change)="defineWeekendColor(weekendColorIHM)"
              id="colorWeekEnd" [disabled]="!weekendIHM">
              <option value="red" class="red"></option>
              <option value="orange" class="orange"></option>
              <option value="yellow" class="yellow"></option>
              <option value="green" class="green"></option>
              <option value="blue" class="blue"></option>
              <option value="black" class="black"></option>
              <option value="grey" class="grey" selected="selected"></option>
              <option value="white" class="white"></option>
              <option value="light-red" class="light-red"></option>
              <option value="light-orange" class="light-orange"></option>
              <option value="light-yellow" class="light-yellow"></option>
              <option value="light-green" class="light-green"></option>
              <option value="light-blue" class="light-blue"></option>
              <option value="light-grey" class="light-grey"></option>
            </select>
          </div>
        </div>
        <!--<div class="row">
                    <div class="col-sm-5">
                        <lp-label-field for="specialDayStart" text="scheduler.day.start"></lp-label-field>
                    </div>
                    <div class="col-sm-7">
                        <lp-date [(ngModel)]="specialDateStart" id="returnDate" cssClass="form-control" name="specialDayStart" placeholder="Date"></lp-date>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5">
                        <lp-label-field for="specialDayEnd" text="scheduler.day.end"></lp-label-field>
                    </div>
                    <div class="col-sm-7">
                        <lp-date [(ngModel)]="specialDateEnd" id="specialDayEnd" cssClass="form-control" name="specialDayEnd" placeholder="Date" ></lp-date>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5">
                        <lp-label-field for="colorSpecialDay" text="scheduler.day.color"></lp-label-field>
                    </div>
                    <div class="col-sm-7">
                        <select [(ngModel)]="specialDateColor" class="form-control" id="colorSpecialDay" (change)="defineSpecialDayConfig(specialDateStart, specialDateEnd, specialDateColor)">
                            <option value="bleu">{{'color.blue' | translate}}</option>
                            <option value="yellow">{{'color.yellow' | translate}}</option>
                            <option value="green">{{'color.green' | translate}}</option>
                            <option value="grey">{{'color.grey' | translate}}</option>
                            <option value="black">{{'color.black' | translate}}</option>
                            <option value="red">{{'color.red' | translate}}</option>
                            <option value="light-green">{{'color.lightGreen' | translate}}</option>
                            <option value="light-blue">{{'color.lightBlue' | translate}}</option>
                            <option value="light-grey">{{'color.lightGrey' | translate}}</option>
                            <option value="white">{{'color.white' | translate}}</option>
                        </select>
                    </div>-->
        <!--<button type="button" class="btn btn-light" (click)="defineSpecialDayConfig(specialDateStart, specialDateEnd, specialDateColor)"><i class="fa fa-check" aria-hidden="true"></i></button>-->
        <!--</div>-->
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field  text="preferences.scheduler.firstHour"></lp-label-field>
          </div>
          <div class="col-sm-4 text-center">
            <lp-time [ignoreChange]="true" name="scheduler_firstHour" id="scheduler_firstHour" [(ngModel)]="firstHour" (change)="defineFirstHour(firstHour)"></lp-time>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <lp-label-field text="preferences.scheduler.lastHour"></lp-label-field>
          </div>
          <div class="col-sm-4">
            <lp-time [ignoreChange]="true" name="scheduler_lastHour" id="scheduler_lastHour" [(ngModel)]="lastHour" (change)="defineLastHour(lastHour)"></lp-time>
          </div>
        </div>
      </div>
    </lp-ui-accordion-group>

    <lp-ui-accordion-group heading="scheduler.line.config" [open]=false key="lineConfig">
      <div class="container">
        <!--<div class="row">
                    <lp-label-field for="oddLine" class="{{arrayClassesElements.get('defaultLabel')}}" text="scheduler.line.colorOdd"></lp-label-field>
                    <select [(ngModel)]="oddColorIHM" class="form-control form-control-scheduler" id="oddLine" (change)="defineLineColor(oddColor, pairColor)">
                        <option value="bleu">{{'color.blue' | translate}}</option>
                        <option value="yellow">{{'color.yellow' | translate}}</option>
                        <option value="green">{{'color.green' | translate}}</option>
                        <option value="grey"selected="selected">{{'color.grey' | translate}}</option>
                        <option value="black">{{'color.black' | translate}}</option>
                        <option value="red">{{'color.red' | translate}}</option>
                        <option value="light-green">{{'color.lightGreen' | translate}}</option>
                        <option value="light-blue">{{'color.lightBlue' | translate}}</option>
                        <option value="light-grey">{{'color.lightGrey' | translate}}</option>
                        <option value="white">{{'color.white' | translate}}</option>
                    </select>
                </div>
                <div class="row">
                    <lp-label-field for="pairLine" class="{{arrayClassesElements.get('defaultLabel')}}" text="scheduler.line.colorPair"></lp-label-field>
                    <select [(ngModel)]="pairColorIHM" class="form-control form-control-scheduler" id="pairLine" (change)="defineLineColor(oddColor, pairColor)">
                        <option value="bleu">{{'color.blue' | translate}}</option>
                        <option value="yellow">{{'color.yellow' | translate}}</option>
                        <option value="green">{{'color.green' | translate}}</option>
                        <option value="grey"selected="selected">{{'color.grey' | translate}}</option>
                        <option value="black">{{'color.black' | translate}}</option>
                        <option value="red">{{'color.red' | translate}}</option>
                        <option value="light-green">{{'color.lightGreen' | translate}}</option>
                        <option value="light-blue">{{'color.lightBlue' | translate}}</option>
                        <option value="light-grey">{{'color.lightGrey' | translate}}</option>
                        <option value="white">{{'color.white' | translate}}</option>
                    </select>
                </div>-->
        <div class="row">
          <div class="col-sm-5 mb-0">
            <lp-label-field for="cellHeight" text="scheduler.line.height"></lp-label-field>
          </div>
          <div class="col-sm-7 mb-0">
            <lp-fuel-gauge id="cellHeight" name="cellHeight" min="20" max="100" value="50" step="2"
              [(ngModel)]="cellHeightIHM" (change)="updateMatrixProperty('dy', cellHeightIHM, true)" [showLabel]="false"></lp-fuel-gauge>
          </div>
        </div>
      </div>
    </lp-ui-accordion-group>

    <lp-ui-accordion-group heading="scheduler.event.config" [open]=false key="lineConfig">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <lp-label-field for="displayNow" text="scheduler.event.now"></lp-label-field>
          </div>
          <div class="col-sm-5">
            <mat-slide-toggle id="displayNow" [(ngModel)]="isNowDisplayedIHM" name="displayNow" (change)="defineNow(isNowDisplayedIHM)"></mat-slide-toggle>
          </div>
          <!--<input id="displayNow" type="checkbox" [(ngModel)]="isNowDisplayedIHM" (change)="defineNow(isNowDisplayedIHM)">-->
        </div>
        <div class="row">
          <div class="col-sm-6">
            <lp-label-field for="collision" text="scheduler.event.allowCollision"></lp-label-field>
          </div>
          <div class="col-sm-5">
            <mat-slide-toggle id="collision" [(ngModel)]="isCollisionAllowedIHM" name="collision" (change)="defineCollision(isCollisionAllowedIHM)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <lp-label-field for="showUnaffectedMovements" text="scheduler.event.showUnaffectedMovements"></lp-label-field>
          </div>
          <div class="col-sm-5">
            <mat-slide-toggle id="showUnaffectedMovements" [(ngModel)]="showUnaffectedMovements" name="showUnaffectedMovements" (change)="defineShowUnaffectedMovements(showUnaffectedMovements)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5">
            <lp-label-field for="eventHeight" text="scheduler.event.height"></lp-label-field>
          </div>
          <div class="col-sm-7">
            <lp-fuel-gauge id="eventHeight" name="eventHeight" min="20" max="100" step="5"
              [(ngModel)]="eventHeightIHM" (change)="updateMatrixProperty('event_dy', eventHeightIHM, true)" [showLabel]="false"></lp-fuel-gauge>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <lp-label-field for="eventBuffer" text="scheduler.event.bufferBefore"></lp-label-field>
          </div>
          <div class="col-sm-7">
            <lp-fuel-gauge id="eventBuffer" name="eventBuffer" min="0" max="7" step="1"
              [(ngModel)]="eventBuffer" (change)="defineBuffer(eventBuffer)" [showLabel]="false"></lp-fuel-gauge>
          </div>
        </div>

        <hr class="mt-0 mb-4" />

        <div cdkDropList (cdkDropListDropped)="dropEvent($event)">
          <div *ngFor="let item of movementLabelIHM" class="row" cdkDrag>
            <div class="row cdk-drop-placeholder" *cdkDragPlaceholder>
              <lp-label-field for="{{item.title}}" text="{{item.title}}"></lp-label-field>
            </div>
            <div class="col-sm-6 mb-0">
              <lp-label-field for="{{item.title}}" text="{{item.title}}"></lp-label-field>
            </div>
            <div class="col-sm-4 mb-0">
              <mat-slide-toggle id="{{item.title}}" [(ngModel)]="item.isdisplayed" name="equipmentsModel" (change)="defineEquipmentlabels(item, item.isdisplayed)"></mat-slide-toggle>
            </div>
            <div class="col-sm-1 mb-0" cdkDragHandle title="{{'scheduler.moveItem' | translate}}"></div>
          </div>
        </div>

        <hr class="mt-0 mb-4" />

        <div class="row" *ngFor="let p of positions">
          <div class="col-sm-5">
              <lp-label-field for="eventBuffer" text="{{'scheduler.event.positions.'  + p.position | translate}}"></lp-label-field>
          </div>
          <div class="col-sm-7">
            <select [(ngModel)]="p.color" class="form-control" [ngClass]="p.color" [class.active]="p.color" [class.hove]="p.color" id="colorWeekEnd" (change)="defineEventColor(p.position, p.color)">
              <option value="red" class="red"></option>
              <option value="orange" class="orange"></option>
              <option value="yellow" class="yellow"></option>
              <option value="green" class="green"></option>
              <option value="blue" class="blue"></option>
              <option value="black" class="black"></option>
              <option value="grey" class="grey" selected="selected"></option>
              <option value="white" class="white"></option>
              <option value="light-red" class="light-red"></option>
              <option value="light-orange" class="light-orange"></option>
              <option value="light-yellow" class="light-yellow"></option>
              <option value="light-green" class="light-green"></option>
              <option value="light-blue" class="light-blue"></option>
              <option value="light-grey" class="light-grey"></option>
          </select>
          </div>
        </div>
      </div>
    </lp-ui-accordion-group>

    <lp-ui-accordion-group heading="scheduler.equipment.config" [open]=false key="lineConfig">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <lp-label-field for="availableEquipment" text="scheduler.equipment.free"></lp-label-field>
          </div>
          <div class="col-sm-5">
            <mat-slide-toggle id="availableEquipment" [(ngModel)]="isAvailable" (change)="defineIsEquipmentAvailable(isAvailable)"></mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5">
            <lp-label-field for="pairLine" text="scheduler.equipment.color"></lp-label-field>
          </div>
          <div class="col-sm-7">
            <select [disabled]="!isAvailable" [ngClass]="availableColor" [(ngModel)]="availableColor" class="form-control"
              id="pairLine" (change)="defineAvailableEquipmentColor(availableColor)">
              <option value="red" class="red"></option>
              <option value="orange" class="orange"></option>
              <option value="yellow" class="yellow"></option>
              <option value="green" class="green"></option>
              <option value="blue" class="blue"></option>
              <option value="black" class="black"></option>
              <option value="grey" class="grey" selected="selected"></option>
              <option value="white" class="white"></option>
              <option value="light-red" class="light-red"></option>
              <option value="light-orange" class="light-orange"></option>
              <option value="light-yellow" class="light-yellow"></option>
              <option value="light-green" class="light-green"></option>
              <option value="light-blue" class="light-blue"></option>
              <option value="light-grey" class="light-grey"></option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <lp-label-field for="isDelayed" text="scheduler.delayed"></lp-label-field>
          </div>
          <div class="col-sm-5">
            <mat-slide-toggle id="isDelayed" [(ngModel)]="displayReturnDelayed" (change)="defineReturnDelayed(displayReturnDelayed)"></mat-slide-toggle>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <lp-label-field for="equipmentWidth" text="scheduler.equipment.width"></lp-label-field>
          </div>
          <div class="col-sm-7">
            <lp-fuel-gauge id="equipmentWidth" name="equipmentWidth" min="420" max="750" step="20"
              [(ngModel)]="equipmentWidthIHM" (change)="updateMatrixProperty('dx', equipmentWidthIHM, true)" [showLabel]="false"></lp-fuel-gauge>
          </div>
        </div>

        <hr class="mt-0 mb-4" />

        <div cdkDropList (cdkDropListDropped)="dropEquipment($event)">
          <div *ngFor="let item of labelIHM" class="row" cdkDrag>
            <div class="row cdk-drop-placeholder" *cdkDragPlaceholder>
              <lp-label-field for="{{item.title}}" text="{{item.title}}"></lp-label-field>
            </div>
            <div class="col-sm-6 mb-0">
              <lp-label-field for="equipment_{{item.title}}" text="{{item.title}}"></lp-label-field>
            </div>
            <div class="col-sm-4 mb-0">
              <mat-slide-toggle id="equipment_{{item.title}}" [(ngModel)]="item.isdisplayed" name="equipmentsModel"
                (change)="defineEquipmentlabels(item, item.isdisplayed)"></mat-slide-toggle>
            </div>
            <div class="col-sm-1 mb-0" cdkDragHandle title="{{'scheduler.moveItem' | translate}}"></div>
          </div>
        </div>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>
  <button type="button" class="btn btn-primary scheduler-btn mb-5 mt-0" (click)="toggleSavePanel()">{{'general.save' |
    translate}}</button>
    <button type="button" class="btn btn-primary scheduler-btn mb-5 mt-0" (click)="clearCache()" >{{'general.clearCache' | translate}}</button>
    <button *ngIf="schedulerUIService.schedulerConfig.isCustom" type="button" class="btn btn-primary scheduler-btn mb-5 mt-0" (click)="reinstateConfig(schedulerUIService.schedulerConfig.id)" >{{'general.reinstate' | translate}}</button>
</div>

<div class="" *ngIf="showSavePanel">
  <lp-save-preferences (save)="saveConfig($event)"></lp-save-preferences>
</div>

<!-- panneau de droite avec le lp-field-detail quand on click sur un mouvement (bar horizontal dans le scheduler) -->
<div *ngIf="showMovementPanel" class="scheduler-config-panel">
    <a class="ms-2 btn btn-danger btn-scheduler-panel-close" aria-label="Close" (click)="closeMovementPanel();">
        <i class="fa fa-times"></i>
    </a>
  <lp-field-details valueClass="col-sm-5" labelClass="col-sm-5" [showCloseBtn]="false" [(followLinks)]="iconLinkLpFieldDetail"></lp-field-details><!--[customFieldToDisplay]="fieldToDisplayLpFieldDetails"-->
</div>

<!-- code de la lib du scheduler cf https://docs.dhtmlx.com/scheduler/timeline_view.html -->
<div #scheduler_container class="dhx_cal_container" style="width: 100%; height:90vh">
  <span class="notFound" *ngIf="noEquipment">{{'scheduler.noEquipmentFound' | translate}} </span>
  <!-- beandeau noir en haut. surchargé pour ajouter le date time picker + dropdown de temps(année mois semaine jour) dans le beandeau noir en haut -->
  <div class="dhx_cal_navline"  [style.width.px]="500">
      <div class="scheduler-custome-control" [ngClass]="{'scheduler-custome-control-zoom': isZoom}">
        <!-- permet d'afficher la date en string en blanc dans le beandeau noir (genre: "23 sept 2018 - 29 sept 2018") (natif)-->
        <div class="dhx_cal_date"></div>
      </div>
      <!-- btn prev dans le bendeau noir (natif) -->
      <div class="dhx_cal_prev_button" (click)="prevBtnClick(scales)">&nbsp;</div>
      <!-- champ de date dans le beandeau noir (pas natif) -->
      <!-- TODO afficher une valeur dans la date  -->
      <!--<input type="date" [(ngModel)]="startDate" value="now()" (keydown)="searchDate($event,startDate)" pattern="[0-9]{2}-[0-9]{2}-[0-9]{2}" class="form-control scheduler-search-date">-->
      <lp-date [(ngModel)]="startDate" class="{{arrayClassesElements.get('defaultInput')}} schedulerSearchDate p-0" (pickerClose)="searchDate($event, startDate)" (focusout)="searchDate($event, startDate)" id="startDate" cssClass="textField form-control" name="startDate" [ignoreChange]="true"></lp-date>
      <!--<input [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" class="textField form-control" (keydown)="searchDate(startDate)" [(ngModel)]="startDate" style="width:130px;height:24px;">-->
      <!--<owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'" [firstDayOfWeek]=1 (afterPickerClosed)="searchDate($event,startDate)"></owl-date-time>-->
      <!-- bouton de choix de l'echelle (scale) dans le beandeau noir (pas natif) -->
      <!-- btn next dans le bendeau noir (natif) -->
      <div class="dhx_cal_next_button" (click)="nextBtnClick(scales)">&nbsp;</div>
      <lp-dropdown-field class="ms-2 scheduler-scale-dropdown" [ignoreChange]="true" className="textField" cssClass="form-control"
        name="thirdPartyType" [(ngModel)]="scales" [values]="scalesValue" (dropDownEmitter)="changeScale(scales.id)"></lp-dropdown-field>
  </div>

  <!-- (natif) -->
  <div class="dhx_cal_header ms-2" style=" height: 40px;"></div>
  <!-- (natif) -->
  <div class="dhx_cal_data ms-2"></div>
  
</div>

<!-- container pour afficher le btn configuration dans le beandeau noire. permet d'afficher/masquer le panneau de config a droite -->
<div class="scheduler-config-btn scheduler-right-button" [class.active]="showConfigPanel">
  <i class="icon fa fa-cogs filter-icon-scheduler" aria-hidden="true" title="{{'scheduler.settings' | translate}}"
    (click)="toggleConfigPanel()" id="toggleSitemap"></i>
</div>
