import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { UserService } from 'app/services/user.service';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-save-preferences',
  templateUrl: './lp-save-preferences.component.html'
})
export class LpSavePreferencesComponent implements OnInit {

  // Action du bouton cancel
  @Output() public save: EventEmitter<String> = new EventEmitter();

  public VALUE: Array<String>;
  public PROFILE: Array<String>;
  public saveConfigUser: String = '*';
  public saveConfigProfile: String;
  public saveConfigLocation: String;
  public saveConfigCompany: String;
  constructor(
    public userService: UserService,
    public repositoryProvider: RepositoryProvider,
    public modalService: ModalService,
    public translateService: TranslateService) { 
      this.saveConfigUser = this.userService.getCurrentUser().username;
      this.saveConfigProfile = '*';
      this.saveConfigLocation = '*';
      this.saveConfigCompany = '*';
    }

  public async ngOnInit(): Promise<void> {
    let user: any = this.userService.getCurrentUser();
    this.VALUE = [
      user.username,
      user.profile.id,
      user.location.id,
      user.company.id
    ];
    this.PROFILE = ['user', 'profile', 'location', 'company'];
  }

  public cancel(): void {
    this.save.emit(null);
  }

  public validSave(): void {
    this.save.emit(
      this.fillIdentification(
        this.saveConfigUser.toString(),
        this.saveConfigProfile.toString(),
        this.saveConfigLocation.toString(),
        this.saveConfigCompany.toString()
      )
    );
  }


  /** fonction présente aussi dnas:
   *    scheduler-config
   *    outbar-preferences
   */
  private fillIdentification(user?: string, profile?: string, location?: string, company?: string): string {
    let id: string = '';
    if (!Util.isNullOrUndefined(user)) {
        id += '"user": "' + user + '",';
    } else {
        id += '"user": "*",';
    }
    if (!Util.isNullOrUndefined(profile)) {
        id += '"profile": "' + profile + '",';
    } else {
        id += '"profile": "*",';
    }
    if (!Util.isNullOrUndefined(location)) {
        id += '"location": "' + location + '",';
    } else {
        id += '"location": "*",';
    }
    if (!Util.isNullOrUndefined(company)) {
        id += '"company": "' + company + '",';
    } else {
        id += '"company": "*",';
    }
    return id;
  }
}
