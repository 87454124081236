import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';

export class LpTechnicalSpecification extends LpMeta {
    @propertyMeta('string')
    public code: String;
    @propertyMeta('string')
    public codeWording: String;
    @propertyMeta('string')
    public values: String;
    @propertyMeta('string')
    public valueWording: String;
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('string')
    public dateWording: String;
    @Type(() => FtMeta)
    public specificationType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public equipmentAndOption: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public equipmentSpecification: FtMeta = new FtMeta();
    @propertyMeta('date')
    public endDate: Date;
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('boolean')
    public exist: Boolean = true;
    @propertyMeta('number')
    public valorizationRate: Number = 0;
    @propertyMeta('number')
    public valorizationAmount: Number = 0;
}
