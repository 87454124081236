import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, FACTURATION_LINKS } from '../detail-forms.component';
import { LpFacturationLink } from 'app/meta/facturation-link';

@Component({
  selector: 'lp-facturation-links',
  templateUrl: './facturation-links.component.html'
})

export class FacturationLinksComponent extends DetailFormsComponent implements OnInit {

  public data: LpFacturationLink = null;

  public init(): void {
    // this.data.assign(this.formStackService.currentDetailLineData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(FACTURATION_LINKS);
    this.initUrl();
    this.init();
  }

}
