import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'underscore'
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormStackService } from 'app/services/form-stack.service';
import { Util } from 'app/statics/utils';
import { WidgetInterface } from 'app/models/widget';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-modal-widget-preview',
  templateUrl: './lp-modal-widget-preview.component.html'
})
export class LpModalWidgetPreviewComponent extends LpModalComponent implements OnInit {
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public widgetId: number;
  public widget: WidgetInterface;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formstackService: FormStackService,
    private configService: ConfigService,
  ) {
    super(dialogRef, data);
  }

  public async ngOnInit(): Promise<void> {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.setWidget();
  }

  private setWidget(): void {
    if (!Util.isNullOrUndefined(this.dialogRef) &&
        !Util.isNullOrUndefined(this.dialogRef._containerInstance) &&
        !Util.isNullOrUndefined(this.dialogRef._containerInstance._config &&
        !Util.isNullOrUndefined(this.dialogRef._containerInstance._config['widgetId']))) {
          this.widget = this.dialogRef._containerInstance._config['widget'];
          this.widgetId = this.dialogRef._containerInstance._config['widget'].id;
        }
  }

}
