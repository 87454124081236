import { Component, Inject } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { FormStackService } from 'app/services/form-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'lp-modal-promise-alert',
  templateUrl: './lp-modal-alert.component.html'
})
export class LpModalPromiseAlertComponent extends LpModalComponent {
  constructor(
    public formstackService: FormStackService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<LpModalComponent>,
    public modalService: ModalService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    super(dialogRef, data);
  }
}
