<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="metadataApplicationItemDetails.technicalSpecifications.title" [open]=true>
    <form id="formTechnicalSpecifications" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

       <!-- Field equipmentAndOption-->
       <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.equipmentAndOption" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.equipmentAndOption"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
          name="technicalSpecifications.equipmentAndOption" [(ngModel)]="data.equipmentAndOption" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'equipmentAndOption'}]}"></lp-dropdown-field>
      </div>

      <!-- Field specificationType TODO check path value when implemented in table FT -->
      <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.specificationType" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.specificationType"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
          name="technicalSpecifications.specificationType" [(ngModel)]="data.specificationType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'specificationType'}]}"></lp-dropdown-field>
      </div>

      <!-- Field exist -->
      <div class="form-group col-xl-12">
        <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.exist" for="technicalSpecifications.exist"></lp-label-field>
          <lp-boolean-switch-box name="technicalSpecifications.exist" switchName="exist" id="technicalSpecifications.exist" [(ngModel)]="data.exist"></lp-boolean-switch-box>
      </div>

      <!-- Field codeWording -->
      <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.codeWording" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.codeWording"></lp-label-field>
        <lp-text-field id="technicalSpecifications.codeWording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
          cssClass="form-control" name="technicalSpecifications.codeWording"
          [(ngModel)]="data.codeWording"></lp-text-field>
      </div>

      <!-- Field code -->
      <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.code" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.code"></lp-label-field>
        <lp-text-field id="technicalSpecifications.code" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
          name="technicalSpecifications.code" [(ngModel)]="data.code"></lp-text-field>
      </div>

      <!-- Field valueWording -->
      <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.valueWording" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.valueWording"></lp-label-field>
        <lp-text-field id="vtechnicalSpecifications.alueWording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
          cssClass="form-control" name="technicalSpecifications.valueWording"
          [(ngModel)]="data.valueWording"></lp-text-field>
      </div>

      <!-- Field value -->
      <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.values" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.values"></lp-label-field>
        <lp-text-field id="technicalSpecifications.values" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
          name="technicalSpecifications.values" [(ngModel)]="data.values"></lp-text-field>
      </div>

      <!-- Field dateWording -->
      <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.dateWording" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.dateWording"></lp-label-field>
        <lp-text-field id="technicalSpecifications.dateWording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
          cssClass="form-control" name="technicalSpecifications.dateWording"
          [(ngModel)]="data.dateWording"></lp-text-field>
      </div>

      <!-- Champ date -->
      <div class="form-group col-xl-12">
        <lp-label-field for="technicalSpecifications.date" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.date"></lp-label-field>
        <lp-date id="technicalSpecifications.date" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="technicalSpecifications.date"
          [(ngModel)]="data.date"></lp-date>
      </div>       <!-- Field equipmentSpecification  -- >
       <div class="form-group col-xl-12">
        <lp-label-field for="equipmentSpecification" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.equipmentSpecification"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
          name="equipmentSpecification" [(ngModel)]="data.equipmentSpecification" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'equipmentSpecification'}]}"></lp-dropdown-field>
      </div>

       <!-- Champ startDate -- >
       <div class="form-group col-xl-12">
        <lp-label-field for="startDate" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.startDate"></lp-label-field>
        <lp-date id="startDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="startDate"
          [(ngModel)]="data.startDate"></lp-date>
      </div>

      <!-- Champ endDate -- >
      <div class="form-group col-xl-12">
        <lp-label-field for="endDate" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.endDate"></lp-label-field>
        <lp-date id="endDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="endDate"
          [(ngModel)]="data.endDate"></lp-date>
      </div>

          <!-- Field valorizationRate --><!-- Field valorizationAmount -- >
      <div class="form-group col-xl-12">
        <lp-label-field for="valorizationRate" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.technicalSpecifications.valorizationRateAmount"></lp-label-field>
        <lp-number id="valorizationRate" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="valorizationRate"
          [(ngModel)]="data.valorizationRate"
          minlength="2"></lp-number>
          <div class="col-1 sep-input pe-0 ps-0 text-center">/</div>
        <lp-number id="valorizationAmount" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="valorizationAmount"
          [(ngModel)]="data.valorizationAmount" minlength="2"></lp-number>
      </div>-->

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>