import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LpMemo } from 'app/meta/memo';
import { CommonToolsService } from 'app/services/common-tools.service';
import { MemoProvider } from 'app/providers/memo.provider';
import { Subscription } from 'rxjs';
import { WorkflowProvider } from 'app/providers/workflow.provider';
import { ApplicationItemAction } from 'app/models/application-item-actions';
import {DomSanitizer} from '@angular/platform-browser';
import { Util } from 'app/statics/utils';
import { ZoomService } from 'app/services/zoom.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { ReportService } from 'app/services/report.service';
import { MemoComponent } from 'app/FormComponents/memo/memo.component';
import { LpModalWorkflowListComponent } from '../lp-modal/lp-modal-workflow/lp-modal-workflow-list/lp-workflow-list.component';
import { LpModalConfirmComponent } from '../lp-modal/lp-modal-confim/lp-modal-confirm.component';
import { LpModalTraceComponent } from '../lp-modal/lp-modal-trace/lp-modal-trace.component';
import { ApplicationItemActionsComponent } from 'app/FormComponents/application-item-actions/application-item-actions.component';
import { RightColService } from 'app/services/root/rightCol.service';
import { LpModalEdiListComponent } from '../lp-modal/lp-modal-edi/lp-modal-edi-list/lp-edi-list.component';
import { EdiProvider } from 'app/providers/edi.provider';
import { EvalService, MAIN, BEFORE_DELETE_EVENT } from 'app/services/eval.service';
import { ActivatedRoute } from '@angular/router';
import { FormService } from 'app/services/root/form.service';
import { RouterService } from 'app/services/router.service';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';
import { ZoomIHM } from 'app/meta/zoom/zoomMeta';
import { LpModalPromiseZoomComponent } from '../lp-modal/lp-modal-zoom/lp-modal-zoom.component';
import { MultiProductsService } from 'app/services/multi-products.service';

export const ADD_PARTIAL: string = 'addPartial';
export const REMOVE_PARTIAL: string = 'removePartial';
export const ADD_FULL: string = 'addFull';
export const REMOVE_FULL: string = 'removeFull';
export const ZOOMS_VERB: string = 'zooms';
export const API_ACCESS_MULTI_CONTRACTS: string = 'short-term-contracts';
@Component({
  selector: 'lp-common-tools',
  templateUrl: './lp-common-tools.component.html'
})
export class LpCommonToolsComponent implements OnInit, OnDestroy {
  /**
   * Donne la posiblité d'afficher ou non le boutton Valider
   */
  @Input() public submitButton: Boolean = true;

  /**
   * Donne la posiblité d'afficher le bouton soit dessous les autres actions (true), soit en bas à droite de l'écran (false).
   */
  @Input() public submitButtonFloat: Boolean = false;
  /**
   * Donne la posiblité d'afficher ou non le boutton Supprimer
   */
  @Input() public deleteButton: Boolean = true;
  /**
   * Donne la posiblité d'afficher le bouton soit dessous les autres actions (true), soit en bas à droite de l'écran (false).
   */
  @Input() public deleteButtonFloat: Boolean = false;

  @Input() public positionFromRight: number = null;

  /**
   * paramètre relatedObject correspond à l'objet parent au bon format pour le backend.
   */
   // @Input() public relatedObject: String;

  /**
   * permet de laisser un espace pour le bouton actif / inactif
   */
  @Input() public cssClass: String = 'cols-2';
  @Input() public className: String;

  @Input() public noTrace: Boolean = false;

  /**
   * ID du parent Data
   */
  @Input() public id: String;

  @Output() public showPrintOutput: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Input() public showPrintInput: Boolean;

  public addPartial: string = ADD_PARTIAL;
  public removePartial: string = REMOVE_PARTIAL;
  public addFull: string = ADD_FULL;
  public removeFull: string = REMOVE_FULL;
  public maxInBadge: number  = this.configService.get('maxInBadge');
  @Input() set ky(ky: String) {
    this.id = ky;
    // attention les yeux this.ky !== 'F11VTARIF' =>  urgence démo stime ka veille pour le lendemain
    if (this.id && this.id !== this.configService.get('newdataKeyWord') && this.id !== 'F11VTARIF') {
      this.commonToolsService.hasMemos(this.formStackService.currentApplicationItem.linkedObject, this.id).then((count: number) => {
        this.formStackService.currentData._xVegaAttachmentsCount = count;
      });
      this.workflowProvider.getNbWorkflows(this.formStackService.currentApplicationItem.linkedObject, this.id
        , this.formStackService.CurrentVerb).then((result: number) => {
        this.formStackService.currentData._xVegaWorkflowsCount = result;
      })
      this.ediProvider.getNbEdis(this.formStackService.currentApplicationItem.linkedObject, this.id
        , this.formStackService.CurrentVerb).then((result: number) => {
        this.formStackService.currentData._xVegaEdisCount = result;
      });
    }
  }

  public hideMoreItem: Boolean = true;
  public hideActionItem: Boolean = true;
  public newdataKeyWord: String = this.configService.get('newdataKeyWord');
  public refreshWorkflowListSubscription: Subscription;
  public refreshEdiListSubscription: Subscription;
  public dragLeft: number = null;
  public stackLoad: Boolean = false;
  private showPrint: Boolean = false;
  private refreshMemoListSubscription: Subscription;

  constructor (public uiSyncService: UiSyncService,
               private alocproProvider: AlocproProvider,
               private modalService: ModalService,
               private routerService: RouterService,
               private translate: TranslateService,
               private commonToolsService: CommonToolsService,
               protected route: ActivatedRoute,
               public formService: FormService,
               private memoProvider: MemoProvider,
               private workflowProvider: WorkflowProvider,
               private ediProvider: EdiProvider,
               private zoomService: ZoomService,
               public sanitizer: DomSanitizer,
               public formStackService: FormStackService,
               public ihmStackService: IhmStackService,
               public reportService: ReportService,
               public rightColService: RightColService,
               private evalService: EvalService,
               private configService: ConfigService,
               private userHstoryService: UserHistoryService,
               public ihmService: IhmStackService,
               public multiProductsService: MultiProductsService) {}

  public ngOnInit(): void {
    // TODO SUB
    // Rafraichit le nombre de mémos disponibles.
    this.subscribtions();
    if (this.ky && this.ky !== this.configService.get('newdataKeyWord') ) {
      this.commonToolsService.hasMemos(this.formStackService.currentApplicationItem.linkedObject, this.ky).then((count: number) => {
        this.formStackService.currentData._xVegaAttachmentsCount = count;
      });
      this.workflowProvider.getNbWorkflows(this.formStackService.currentApplicationItem.linkedObject
        , this.id, this.formStackService.CurrentVerb).then((result: number) => {
        this.formStackService.currentData._xVegaWorkflowsCount = result;
      });      
      this.ediProvider.getNbEdis(this.formStackService.currentApplicationItem.linkedObject, this.id
        , this.formStackService.CurrentVerb).then((result: number) => {
        this.formStackService.currentData._xVegaEdisCount = result;
      });
    }
  }

  public async delete(event: any): Promise<void> {
    const result: boolean = await this.modalService.modalPromise(LpModalConfirmComponent, {
      backdropClass: 'alertBackdropClass',
      id : 'modalWithHeader'
    });
    if (result) {
      await this.evalService.executeContrainte(
        BEFORE_DELETE_EVENT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString(), '*', this.formStackService.currentData
            , this.formStackService.currentFields);
       this.alocproProvider.delete(this.uiSyncService.formatURL(this.formStackService.CurrentVerb, this.id), this.id).then(async () => {
          this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
          this.translate.instant('general.modalService.deletionCompleted'));
          let stackIdToLoad: number = null;
          this.userHstoryService.addEvent('delete', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb);
          if (this.formStackService.stackExist()) {
            stackIdToLoad = this.formStackService.stack.size - 1;
            this.uiSyncService.lastBreadcrumbItemClickIndex = stackIdToLoad;
            await this.formService.build(this.formStackService.CurrentVerb, this.formStackService.CurrentKy, stackIdToLoad);
          } else{
            this.routerService.navigate(this.formStackService.CurrentVerb);
          }
        });
    }
  }

  public showSwitcher(): boolean {
    let showSwitcher: boolean = false;
    if (
      this.formStackService &&
      this.formStackService.CurrentVerb &&
      this.formStackService.CurrentVerb === API_ACCESS_MULTI_CONTRACTS && 
      this.multiProductsService.list.body.length <= 1 && 
      this.configService.get('displayMultiShortTermContracts') &&
      this.configService.get('displayMultiShortTermContracts') === true
      ) {
      showSwitcher = true;
    }
    return showSwitcher;
  }

  /**La fonction accordionsToggle(), permet d'ouvrir ou fermer tous les accordéons.
  */
  public accordionsToggle(): void {
    let open = false;
    this.uiSyncService.accordiongroups.forEach((value: Boolean, key: String) => {
      // Si il y a un accordéon de fermé on ouvre tout
      // Si tous le accordéons sont ouvert, on les ferme tous
      if (!value) {
        open = true;
      }
    });
    
    this.uiSyncService.accordiongroups.forEach((value: Boolean, key: String) => {
      this.uiSyncService.accordiongroups.set(key, open);
    });
  }

  public showTrace(): void {
    if (this.isShown() && !this.isDisabled() &&  !this.noTrace) {      
      this.userHstoryService.addEvent('show', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.showTrace'));
      this.modalService.modalPromise(LpModalTraceComponent, {
        height : '70vh',
        width : '80vw',
        backdropClass: 'commonToolsBackdropClass',
        data: {
          verb: this.formStackService.CurrentVerb,
          ky: this.id
        }
      });        
    }
  }

  public showMemos(): void {
    if (this.isShown()) {
        this.userHstoryService.addEvent('show', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.showMemo'));
        this.modalService.modalPromise(MemoComponent, {
          height : '70vh',
          width : '80vw',
          backdropClass: 'commonToolsBackdropClass',
        });      }
    }

  public async showWorkflows(): Promise<void> {
    if (this.isShown()) {
        /*this.uiSyncService.showWorkflows(
          this.formStackService.currentApplicationItem.linkedObject,
          this.formStackService.currentData.id,
          this.formStackService.currentApplicationItem.verb
        );*/
        this.userHstoryService.addEvent('show', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.showWorkflow'));
        await this.modalService.modalPromise(LpModalWorkflowListComponent, {
          height : '70vh',
          width : '80vw',
          me: true,
          backdropClass: 'commonToolsBackdropClass',
        });    
      }
  }

  public async showEdis(): Promise<void> {
    if (this.isShown()) {        
      this.userHstoryService.addEvent('show', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.showEDI'));
      await this.modalService.modalPromise(LpModalEdiListComponent, {
        minHeight: '70vh',
        maxHeight : '90vh',
        width : '80vw',
        me: true,
        backdropClass: 'commonToolsBackdropClass',
      });
    }
  }

  public clickSubmitButton(): void {
    this.uiSyncService.noTtestFormChange = true;
  }

  public returnShowPrint(isSpoolerMode?: boolean): void {
    this.reportService.isSpoolerMode = Util.isNullOrUndefined(isSpoolerMode) ? false : isSpoolerMode;
    this.zoomService.closeZoom();
    if (this.showPrintInput !== this.showPrint) {
      this.showPrint = !this.showPrintInput;
    } else {
      this.showPrint = !this.showPrint;
    }
    this.reportService.updateTitle();
    this.reportService.updateModelName();
    // this.uiSyncService.fieldDetails(null);
    this.ihmStackService.displayRightZoomComponent();
    // this.uiSyncService.changeRightBlockEvtEmitter.emit(false);
    this.showPrintOutput.emit(this.showPrint);
    this.ihmStackService.displayReport();
  }

  public isShown(): Boolean {
    if (Util.isNullOrUndefined(this.id) || this.id === '' || this.id === this.newdataKeyWord) {
      return false;
    } else {
      return true;
    }
  }

  public isDisabled(): Boolean {
    return false;
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.refreshMemoListSubscription)) {
      this.refreshMemoListSubscription.unsubscribe();
    }
    if (!Util.isNullOrUndefined(this.refreshWorkflowListSubscription)) {
      this.refreshWorkflowListSubscription.unsubscribe();
    }
    if (!Util.isNullOrUndefined(this.refreshEdiListSubscription)) {
      this.refreshEdiListSubscription.unsubscribe();
    }
  }

  /**
   * Cette fonctione demande l'ouverture d'un écran action
   */
  public executeRootFormAction(action: ApplicationItemAction): void {
    if ( action.classes !== 'disabled') {
      this.modalService.modalPromise(ApplicationItemActionsComponent, {
        width : '600px',
        backdropClass: 'commonToolsBackdropClass',
        data: {
          rootFormAction: action
        }
      });
    }
  }

  public duplicateLines(): void {
    const rf = this.formStackService.editFormToAction(this.formStackService.currentApplicationItem.editForm, 'commonTools.duplicateLines');
      this.modalService.modalPromise(ApplicationItemActionsComponent, {
        width : '800px',
        backdropClass: 'commonToolsBackdropClass',
        data: {
          rootFormAction: rf,
          ky: this.id
        }
      });
  }

  public getActiveBtnPosition(): String {
    if (this.dragLeft) {
      return (this.dragLeft - 9) + 'px';
    }
    return 'calc(50% - 34px)';
  }

  public getDisplayDeductibleBuyBacks(): boolean {
    let displayDeductibleBuyBacks: boolean = false;
    if ( this.formStackService && this.formStackService.currentData && this.formStackService.currentData['status']
        && this.formStackService.currentData['status'].id && this.formStackService.currentData['status'].id < 3) {
      displayDeductibleBuyBacks = true;
    }
    return displayDeductibleBuyBacks;
  }

  public async removeOdAddDeductibleBuyBacks(mode: string): Promise<void> {
    try {
      await this.commonToolsService.putDeductibleBuyBacks(this.formStackService.CurrentKy, mode);
      this.changeRightCol();
      this.modalService.success(
        this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.recordingDone'));
    } catch (error) {
      this.modalService.error(
        this.translate.instant('general.modalService.recordingFailed'), this.translate.instant('general.modalService.recordingFailed'));
      throw error;
    }
  }

  public changeRightCol(): void {
    this.rightColService.currentApplicationItemDetailLoading = this.formStackService.currentApplicationItem.details[0].id;
    this.formStackService.initLpLine(this.formStackService.currentApplicationItemDetail.key.toString())
    this.rightColService.displaySubAPIDetailsTab(
      this.formStackService.currentApplicationItem.details[0], this.formStackService.currentData);
  }

  public displayWorkflows(): boolean {
    let display: boolean = true;
    if ( !Util.isNullOrUndefined(this.formStackService)&&
         !Util.isNullOrUndefined(this.formStackService.currentApplicationItem) &&
         !Util.isNullOrUndefined(this.formStackService.currentApplicationItem.workflows) &&
         this.formStackService.currentApplicationItem.workflows === false) {
      display = false;
    }
    return display;
  }

  public displayEdis(): boolean {
    let display: boolean = true;
    if ( !Util.isNullOrUndefined(this.formStackService) &&
         !Util.isNullOrUndefined(this.formStackService.currentApplicationItem) &&
         !Util.isNullOrUndefined(this.formStackService.currentApplicationItem.edis) &&
         this.formStackService.currentApplicationItem.edis === false ) {
      display = false;
    }
    return display;
  }

  public displayTraces(): boolean {
    let display: boolean = true;
    if ( !Util.isNullOrUndefined(this.formStackService) &&
         !Util.isNullOrUndefined(this.formStackService.currentApplicationItem) &&
         !Util.isNullOrUndefined(this.formStackService.currentApplicationItem.traces) &&
         this.formStackService.currentApplicationItem.traces === false) {
      display = false;
    }
    return display;
  }

  public getActionTitle(action: ApplicationItemAction): String {
    let title: String = this.translate.instant('rootFormActions.'+action.title+'.title');

    if(action.RefAction !== undefined && action.RefAction !== null &&action.RefAction !== '') {
      title+= ' ('+action.RefAction.toUpperCase() +')';
    }
    
    return title;
  }

  public async zoomPreview(): Promise<void> {
    const parameters: ZoomIHM = await this.zoomService.getParametersFromBackend(String(this.id));
    const zoomIHM = this.zoomService.buildConfig(parameters)
    this.zoomService.setConfigAction(zoomIHM, zoomIHM.id);
    this.modalService.modalPromise(LpModalPromiseZoomComponent, {
      width: '80vw',
      backdropClass: 'zoomBackdropClass',
      data: { 'param': parameters, 'guid': zoomIHM.id, 'config': zoomIHM },
    });
  }

  public transformIntoMutliProducts(): void {
    this.ihmStackService.ShowSwitcher = true;
  }

  public disabledZoomButton() {    
    if (this.formStackService 
      && this.formStackService.CurrentVerb
      && this.formStackService.CurrentVerb === 'zooms'
      && this.formStackService.CurrentKy !== 'new'
      && this.formStackService.currentData["zoomType"] !== null
      && this.formStackService.currentData["table"] !== null
      && this.formStackService.currentData["returnField"] !== null
      && this.formStackService.currentData["request"] !== "") {
        return false
    }
    return true;
  }

  private async subscribtions(): Promise<void> {
    try {
      this. refreshMemoListSubscription = this.memoProvider.refreshMemoListEvtEmitter.subscribe( (memos: Array<LpMemo>) => {
        this.commonToolsService.hasMemos(this.formStackService.currentApplicationItem.linkedObject, this.id).then((count: number) => {
          this.formStackService.currentData._xVegaAttachmentsCount = count;
        })
      });
       // Rafraichit le nombre de workflow disponibles.
      this.refreshWorkflowListSubscription  = this.workflowProvider.refreshWorkflowListEvtEmitter.subscribe(() => {
        this.workflowProvider.getNbWorkflows(this.formStackService.currentApplicationItem.linkedObject
          , this.id, this.formStackService.CurrentVerb).then((count: number) => {
          this.formStackService.currentData._xVegaWorkflowsCount = count;
        })
      });
      // Rafraichit le nombre d'EDI disponibles.
      this.refreshEdiListSubscription  = this.ediProvider.refreshEdiListEvtEmitter.subscribe(() => {        
          this.ediProvider.getNbEdis(this.formStackService.currentApplicationItem.linkedObject, this.id
            , this.formStackService.CurrentVerb).then((result: number) => {
            this.formStackService.currentData._xVegaEdisCount = result;
          });
      }); 
    } catch (error) {
      throw error;
    }
  }
  
}
