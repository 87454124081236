import { OnDestroy, Component, OnInit } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { Subscription } from 'rxjs';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-ui-zoom-pdf',
  templateUrl: './lp-ui-zoom-pdf.component.html'
})
export class LpUiZoomPdfComponent implements OnInit, OnDestroy {
  public pdf: Uint8Array;

  private subscription: Subscription;
  constructor(protected uiSyncService: UiSyncService) {}

  public ngOnInit(): void {
    this.subscription = this.uiSyncService.displayZoomPdfEvtEmitter.subscribe((pdfString: String) => {
      this.pdf = this.convertDataURIToBinary(pdfString);
    });
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscription)) { this.subscription.unsubscribe(); }
  }

  public hideZoom(): void {
    this.pdf = undefined;
  }

  private convertDataURIToBinary(dataURI: String): Uint8Array {
    let BASE64_MARKER: String = ';base64,';
    let base64Index: Number = dataURI.indexOf(BASE64_MARKER.toString()) + BASE64_MARKER.length;
    let base64: String = dataURI.substring(base64Index.valueOf());
    let raw: String = window.atob(base64.toString());
    let rawLength: Number = raw.length;
    let array: Uint8Array = new Uint8Array(new ArrayBuffer(rawLength.valueOf()));

    for (let i: number = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

}
