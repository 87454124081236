<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.crashLinks.titleTab" [open]=true>
      <form id="formCrashLinks" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

        <div class="form-group col-xl-12">
            <lp-label-field for="crashLinks.crashType" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.crashType"></lp-label-field>
            <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="crashLinks.crashType" [(ngModel)]="data.crashType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'crashType'}]}">
            </lp-dropdown-field>
        </div>    

        <div class="form-group col-xl-12">
            <lp-label-field for="crashLinks.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wording"></lp-label-field>
            <lp-text-field id="crashLinks.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="crashLinks.wording"
            [(ngModel)]="data.wording" placeholder="damages.wording"></lp-text-field>
        </div>    

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>