<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="zooms.zooms"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm  && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
    <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
    <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
    <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

    <div class="container container-2-cols left" #containerCols>
        <div class="card-box col-md-6 label-2-colonnes" #col1>
            <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
                <!--  composant pour afficher lien flottant à droite du formulaire -->
                <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"
                [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
                (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
                <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
                    
                </div>
                <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
            </form>
        </div>
        <div class="col-md-6 card-box" #col2>
            <lp-right-col></lp-right-col>
        </div>
    </div>
    <div class="clearfix"></div>
  </div>
<div class="clearfix"></div>