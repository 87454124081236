


<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="addresses.address"
  [title]="data.corporateName" ></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">

  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire-->
        <lp-common-tools  *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [deleteButton]="deleteButton" [positionFromRight]="dragRight ? dragRight : null"></lp-common-tools>
        <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>

          <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
            <div class="container">

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="corporateName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="corporateName"  [(ngModel)]="data.corporateName" label="addresses.corporateName"
                     type="text"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                    <lp-text-field id="address1" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="address1" [(ngModel)]="data.address1" label="addresses.address1"
                      type="text"></lp-text-field>
                  </div>
                <div class="form-group col-xl-6">
                    <lp-text-field id="address2" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                        cssClass="form-control" name="address2" [(ngModel)]="data.address2" label="addresses.address2"
                        type="text"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="address3" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="addresses.address3"
                    cssClass="form-control" name="address3" [(ngModel)]="data.address3" type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="address4" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="addresses.address4"
                      cssClass="form-control" name="address4" [(ngModel)]="data.address4" type="text"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="zipCode" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="addresses.zipCode"
                    cssClass="form-control" name="zipCode" [(ngModel)]="data.zipCode"
                    ></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="city" [required]=true class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="city" [(ngModel)]="data.city" label="addresses.city"
                    ></lp-text-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" 
                  cssClass="form-control" name="country" label="addresses.country"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'country'}]}" [(ngModel)]="data.country"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="country" id="country" name="country" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.country" label="addresses.country"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="siret" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="addresses.siret"
                    cssClass="form-control" name="siret" [(ngModel)]="data.siret"
                    type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="inChargeOf" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="inChargeOf" [(ngModel)]="data.inChargeOf" label="addresses.inChargeOf"
                    type="text"></lp-text-field>
                </div>
              </div>

              <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="keyword" className="textField"
                      class="{{arrayClassesElements.get('defaultInput')}}" label="addresses.keyword"
                      cssClass="form-control" name="keyword" [(ngModel)]="data.keyword" type="text"></lp-text-field>
                  </div>
              </div>

            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="addresses.googleMaps" [open]=false key="gmap">
              
            <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="latitude" className="textField" class="col-md-4 col-xl-5" label="addresses.latitudeLongitude"
                    cssClass="form-control" name="latitude" [(ngModel)]="data.latitude"
                    type="text"></lp-number>
                    <div class="col-1 sep-input pe-0 ps-0 text-center">/</div>
                  <lp-number id="longitude" className="textField" class="col-md-4 col-xl-5" label=" "
                    cssClass="form-control" name="longitude" [(ngModel)]="data.longitude"
                    type="text"></lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-number id="streetViewMapsAngle" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="addresses.streetViewMapsAngle"
                    cssClass="form-control" name="streetViewMapsAngle" [(ngModel)]="data.streetViewMapsAngle" min="0" max="360"></lp-number>
                </div>
              </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="addresses.linksTitle" [open]=false key="links">
              <ngx-datatable class="material table-height-35" [rows]="data.links" [columnMode]="'force'"
                [headerHeight]="35" [rowHeight]="'auto'" width="100%"
                [messages]="{emptyMessage: 'general.emptyMessage' |translate}" [footerHeight]="0">
      
                <ngx-datatable-column 
                  name="" [cellClass]="'application-item-detail'"
                  [width]="50" [resizeable]="" [sortable]="" [draggable]=""
                  [canAutoResize]="false">
      
                  <!-- template de base -->
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
                    let-i="index"><i *ngIf="row.applicationItem" class="icon {{row.applicationItem.classes}}" title="{{row.applicationItem.title | translate}}"></i></ng-template>
                </ngx-datatable-column>
      
                <ngx-datatable-column name="{{'addresses.links.type' |translate}}">            
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <lp-cell [value]="row.type.wording"></lp-cell>
                  </ng-template>
                </ngx-datatable-column>
      
                <ngx-datatable-column name="{{'addresses.links.wording' |translate}}">  
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <lp-cell [value]="row.wording"></lp-cell>
                  </ng-template>
                </ngx-datatable-column>
      
                <ngx-datatable-column [cellClass]="'application-item-detail'" [width]="50" [canAutoResize]="false">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
                    let-i="index">
                    <lp-childform-link
                      [verb]="row.relatedObject.split('/')[0]" key="{{uiSyncService.historyKey}}" [childType]="row.relatedObject.split('/')[0]"
                      [add]="false" [ky]="row.relatedObject.split('/')[1]"
                      class="applicationItemDetailAction btn-update">
                      <i class="icon vega-click" title="{{'general.button.relatedObject' | translate}}"></i>
                    </lp-childform-link>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
          </lp-ui-accordion-group>
          
          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>


        </lp-ui-accordion>
        </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>
