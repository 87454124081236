import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {PopoverModule, PopoverConfig} from 'ngx-bootstrap/popover';
import {TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {OwlDateTimeModule, OwlNativeDateTimeModule,  OwlDateTimeIntl} from '@danielmoncada/angular-datetime-picker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { environment } from 'environments/environment';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime-ex';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    NgxDatatableModule,
    NgxChartsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PopoverModule,
    RouterModule,
    TranslateModule,
    TypeaheadModule,
    CarouselModule,
    DragDropModule,
  ],
  providers: [
    PopoverConfig,
    {provide: OWL_DATE_TIME_FORMATS, useValue: environment.MY_MOMENT_FORMATS }, // Calendrier
    {provide: OwlDateTimeIntl, useValue: environment.DefaultIntl} // Calendrier
  ],
  declarations: [],
  exports: [
    FormsModule,
    CommonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    NgxDatatableModule,
    NgxChartsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PopoverModule,
    RouterModule,
    TranslateModule,
    TypeaheadModule,
    CarouselModule,
    DragDropModule
  ]
})
export class AlocproThirdPartyLibsModule {
}
