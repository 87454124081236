import { ApplicationItem } from '../models/application-item';
import { Injectable } from '@angular/core';
import { RootFormDetails } from 'app/models/rootform-details';
import { RepositoryProvider, APPLICATION_ITEMS, APPLICATION_ITEM_SCREENID, APPLICATION_ITEM_VERB } from '../providers/repository.provider';
import { ZoomConfig } from 'app/models/zoom-metada';
import { ApplicationItemDetail } from 'app/models/application-item-detail';
import { Util } from 'app/statics/utils';

@Injectable({
  providedIn: 'root'
})
export class ApplicationItemProvider {

  constructor(private repositoryProvider: RepositoryProvider) { }

  public getApplicationItemDetails(key: string): Promise<ApplicationItemDetail> {
    return this.repositoryProvider.getApplicationItemDetails(key);
  }

  public getItem(verb: String): Promise<ApplicationItem> {
    return this.repositoryProvider.getApplicationItem(verb);
  }

  public async getApplicationItemsScreens(): Promise<any> {
    return await this.repositoryProvider.get(APPLICATION_ITEMS, APPLICATION_ITEM_SCREENID.toString())
  }

  public async getApplicationItemsVerbs(): Promise<any> {
    return await this.repositoryProvider.get(APPLICATION_ITEMS, APPLICATION_ITEM_VERB);
  }

  public getObjectDetails(verb: string): Promise<ApplicationItem> {
    let arrayTemp: RootFormDetails = new RootFormDetails();
    let mainZoomConfig: ZoomConfig = new ZoomConfig();
    return new Promise<ApplicationItem>((resolve) => {
      this.getItem(verb).then((applicationItem: ApplicationItem) => {
        let temp: ApplicationItem = { ...applicationItem };
        if (temp.details) {
          for (let detail of temp.details) {
            arrayTemp.add(detail.id, detail.icon, detail.key, detail.path, detail.active, detail);
            if (detail.active) {
              temp.defaultDetail = detail.id;
            }
          }
        }
        if (!Util.isNullOrUndefined(temp.mainZoomConfig)) {
          temp.mainZoomConfig.modal = true;
        }
        Object.assign(mainZoomConfig, applicationItem.mainZoomConfig);
        temp.mainZoomConfig = mainZoomConfig;
        temp.oldDetails = arrayTemp;
        temp.details = applicationItem.details;
        temp.ecrId = applicationItem.ecrId;
        temp.ecrIndiceid = applicationItem.ecrIndiceid;
        let applicationItemObject: ApplicationItem = new ApplicationItem();
        Object.assign(applicationItemObject, temp);
        resolve(applicationItemObject);
      }).catch(err => {
        console.log(err);
      });
    });
  }
}
