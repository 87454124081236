import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';

// TODO RIGHT
export class LpContact extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public value: String;
    @propertyMeta('string')
    public data: string
    @Type(() => LpMeta)
    public type: LpMeta = new LpMeta();

}
