import { HttpClient, HttpParams, HttpHeaders, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from 'app/statics/utils';
import { LoggerService } from 'app/services/logger.service';
import { Observable, lastValueFrom } from 'rxjs';
import { tap, map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })

export class HttpProvider {

    protected headers: HttpHeaders;

    constructor(protected http: HttpClient, private log: LoggerService) {
        this.headers = new HttpHeaders();
    }

    public getClient(): HttpClient {
        return this.http;
    }

    public httpGet(url: string, params?: HttpParams, fullHttpResponse?: boolean, isBinary?: boolean, headerList?: any): Promise<any> {
        let headers: HttpHeaders = this.headers;
        if (!Util.isNullOrUndefined(headerList)) {
            for (let index: number = 0; index < headerList.length; index++) {
                headers = headers.set(headerList[index].prop, headerList[index].value);
            }
        }
        let options: any = { headers: headers, params: params };
        if (isBinary) {
            options = { ...options
                , responseType: 'arrayBuffer' as 'text' };
        }
        if (fullHttpResponse) {
            options = { ...options
                , observe: 'response' };
        }
        return lastValueFrom(this.http.get(url, options)
        .pipe(
            tap ({
                next: response => {
                    this.log.log('GET ' + url, options, response)
                  },
                  error: error => {
                    this.log.error('GET ' + url, options, error)
                  }
            })
        ))
    }

    public httpGetObservable(url: string, params?: HttpParams): Observable<any> {
        let headers: HttpHeaders = this.headers;
        let options: any = { headers: headers, params: params, responseType: 'text' };
            options = { ...options
                , observe: 'body' };
        return this.http.get(url, options)
        .pipe(
            tap ({
                next: response => {
                    this.log.log('GET ' + url, options, response)
                  },
                  error: error => {
                    this.log.error('GET ' + url, options, error)
                  }
            })
        )
        .pipe(
            map(
                (res: any) => JSON.parse(res)
            )
         )
    }

    public getCountObject(NbObjectInHeader: String): number {
        let count: number = -1;
        if (!Util.isNullOrUndefined(NbObjectInHeader)) {
          count = parseInt(NbObjectInHeader.toString(), 10);
        }
        return count;
      }

    public async httpDelete(url: String, headerList?: any): Promise<any> {
        let headers: HttpHeaders = this.headers;
        if (!Util.isNullOrUndefined(headerList)) {
            for (let index: number = 0; index < headerList.length; index++) {
                headers = headers.set(headerList[index].prop, headerList[index].value);
            }
        }
        return await lastValueFrom(this.http.delete(url.toString(), { headers: headers })
            .pipe(
                tap ({
                    next: response => {
                        this.log.log('GET ' + url, headers, response)
                    },
                    error: error => {
                        this.log.error('GET ' + url, headers, error)
                    }
                })
            ))
    }

    public async httpPost(url: String, data: any, headerList?: any): Promise<any> {
        let headers: HttpHeaders = this.headers;
        if (!Util.isNullOrUndefined(headerList)) {
            for (let index: number = 0; index < headerList.length; index++) {
                headers = headers.set(headerList[index].prop, headerList[index].value);
            }
        }
        return await lastValueFrom(this.http.post(url.toString(), data, {headers: headers, observe: 'response'})
            .pipe(
                tap ({
                    next: response => {
                        this.log.log('GET ' + url, headers, response)
                    },
                    error: error => {
                        this.log.error('GET ' + url, headers, error)
                    }
                })
            ))
    }

    public async httpPut(url: String, data?: any, headerList?: any): Promise<any> {
        let headers: HttpHeaders = this.headers;
        if (!Util.isNullOrUndefined(headerList)) {
            for (let index: number = 0; index < headerList.length; index++) {
                headers = headers.set(headerList[index].prop, headerList[index].value);
            }
        }
        return await lastValueFrom(this.http.put(url.toString(), data, { headers: headers })
            .pipe(
                tap ({
                    next: response => {
                        this.log.log('GET ' + url, headers, response)
                    },
                    error: error => {
                        this.log.error('GET ' + url, headers, error)
                    }
                })
            ))
    }

    public addHeader(key: String, value: String): void {
        this.headers = this.headers.set(key.toString(), value.toString());
    }

    public getHeaders(key: string): string {
        let h: string;
        if (!Util.isNullOrUndefined(key)) {
            h = this.headers.get(key.toString());
        }
        return h;
    }
}
