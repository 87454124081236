import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, KINDS_LINKS } from '../detail-forms.component';
import { LpKindsLink } from 'app/meta/kinds-link';

@Component({
  selector: 'lp-kinds-links',
  templateUrl: './kinds-links.component.html'
})

export class KindsLinksComponent extends DetailFormsComponent implements OnInit {

  public data: LpKindsLink = null;

  public init(): void {
    // this.data.assign(this.formStackService.currentDetailLineData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(KINDS_LINKS);
    this.initUrl();
    this.init();
  }

}
