



<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="services.services"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">


  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire -->
        <lp-common-tools  *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"
          [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
          (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
            <!-- placer le contenu de l'acccordéons ici -->

            <!--field details-->
            <div class="row">
              <!--field wording-->
              <div class="form-group col-xl-6">
                <lp-text-field id="id" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="services.id"
                  cssClass="form-control" name="details" [(ngModel)]="data.id" [readonly]="true">
                </lp-text-field>
              </div>
              <div class="form-group col-xl-6">
                <lp-text-field id="wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="services.wording"
                  cssClass="form-control" name="wording" [(ngModel)]="data.wording">
                </lp-text-field>
              </div>
            </div>

            <!-- field kind-->
            <div class="row">
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="kind" class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                  cssClass="form-control" name="kind" label="services.kind"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'serviceKind'}]}"
                  [(ngModel)]="data.kind"></lp-dropdown-field>-->
                <lp-zoom-field id="kind" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control"
                  [(ngModel)]="data.kind" name="kind" zoomId="kind" label="services.kind">
                </lp-zoom-field>
              </div>
              <!--Field calculationMode-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="calculationMode" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="calculationMode" label="services.calculationMode"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'calculationMode'}]}"
                  [(ngModel)]="data.calculationMode"></lp-dropdown-field>-->
                <lp-zoom-field id="calculationMode" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control"
                  [(ngModel)]="data.calculationMode" name="calculationMode" zoomId="calculationMode" label="services.calculationMode">
                </lp-zoom-field>
              </div>
            </div>

            <!-- field level-->
            <div class="row">
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="level" class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                  cssClass="form-control" name="level" label="services.level"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'serviceLevel'}]}"
                  [(ngModel)]="data.level"></lp-dropdown-field>-->
                <lp-zoom-field id="level" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control"
                  [(ngModel)]="data.level" name="level" zoomId="level" label="services.level">
                </lp-zoom-field>
              </div>
              <!--Field family-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="family" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="family" label="services.family"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'productFamily'}]}"
                  [(ngModel)]="data.family"></lp-dropdown-field>-->
                <lp-zoom-field id="family" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control"
                  [(ngModel)]="data.family" name="family" zoomId="family" label="services.family">
                </lp-zoom-field>
              </div>
            </div>

            <!-- field vatCode-->
            <div class="row">
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="vatCode" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="vatCode" label="services.vatCode"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'vatCode'}]}"
                  [(ngModel)]="data.vatCode"></lp-dropdown-field>-->
                <lp-zoom-field id="vatCode" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control"
                  [(ngModel)]="data.vatCode" name="vatCode" zoomId="vatCode" label="services.vatCode">
                </lp-zoom-field>
              </div>
              <!--Field productAccount-->
              <div class="form-group col-xl-6">
                  <lp-zoom-field id="productAccount" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control"
                  [(ngModel)]="data.productAccount" name="productAccount" zoomId="productAccount" label="services.productAccount">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field quantity-->
              <div class="form-group col-xl-6">
                <lp-number id="quantity" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
                  name="quantity" [(ngModel)]="data.quantity" minlength="1" label="services.quantity"></lp-number>
              </div>
              <!--Field quantityA-->
              <div class="form-group col-xl-6">
                <lp-number id="quantityA" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
                  name="quantityA" [(ngModel)]="data.quantityA" minlength="1" label="services.quantityA"></lp-number>
              </div>
            </div>

            <div class="row">
              <!--Field unitPrice-->
              <div class="form-group col-xl-6">
                <lp-currency id="unitPrice" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
                  name="unitPrice" [(ngModel)]="data.unitPrice" minlength="1" label="services.unitPrice"></lp-currency>
              </div>
            </div>

            <div class="row">
              <!-- Field isWithoutTax -->
              <div class="form-group col-xl-6">
                <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" id="isWithoutTax" name="isWithoutTax"
                  switchName="isWithoutTax" [(ngModel)]="data.isWithoutTax" label="services.isWithoutTax">
                </lp-boolean-switch-box>
              </div>
              <!--Field directorWord-->
              <div class="form-group col-xl-6">
                <lp-text-field id="directorWord" className="textField" label="services.directorWord"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="directorWord"
                  [(ngModel)]="data.directorWord">
                </lp-text-field>
              </div>
            </div>

            <!-- field fuelType -->
            <div class="row">
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="fuelType" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="fuelType" label="services.fuelType"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'fuelType'}]}"
                  [(ngModel)]="data.fuelType"></lp-dropdown-field>-->
                <lp-zoom-field id="fuelType" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control"
                  [(ngModel)]="data.fuelType" name="fuelType" zoomId="fuelType" label="services.fuelType">
                </lp-zoom-field>
              </div>
              <!-- field company -->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="company" class="{{arrayClassesElements.get('defaultInput')}}"
                   zoomId='companies' className="zoomField" cssClass="form-control" label="services.company"
                  [(ngModel)]="data.company" name="company" >
                </lp-zoom-field>
              </div>
            </div>

          </lp-ui-accordion-group>
          <lp-ui-accordion-group heading="services.other" [open]=false key="general">

            <!-- field hourType-->
            <div class="row">
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="hourType" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="hourType" label="services.hourType"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'hourType'}]}"
                  [(ngModel)]="data.hourType"></lp-dropdown-field>-->
                <lp-zoom-field id="hourType" class="{{arrayClassesElements.get('defaultInput')}}"
                   zoomId='hourType' className="zoomField" cssClass="form-control" label="services.hourType"
                  [(ngModel)]="data.hourType" name="hourType" >
                </lp-zoom-field>
              </div>
              <!--Field subHourType-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="subHourType" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="subHourType" label="services.subHourType"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'hourType'}]}"
                  [(ngModel)]="data.subHourType"></lp-dropdown-field>-->
                <lp-zoom-field id="subHourType" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId='subHourType' className="zoomField" cssClass="form-control" label="services.subHourType"
                 [(ngModel)]="data.subHourType" name="subHourType">
               </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field startPosition-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="startPosition" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="startPosition" label="services.startPosition"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'position'}]}"
                  [(ngModel)]="data.startPosition"></lp-dropdown-field>-->
                <lp-zoom-field id="startPosition" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId='startPosition' className="zoomField" cssClass="form-control" label="services.startPosition"
                 [(ngModel)]="data.startPosition" name="startPosition">
                </lp-zoom-field>
              </div>
              <!-- field returnPosition -->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="returnPosition" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="returnPosition" label="services.returnPosition"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'position'}]}"
                  [(ngModel)]="data.returnPosition"></lp-dropdown-field>-->
                <lp-zoom-field id="returnPosition" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId='returnPosition' className="zoomField" cssClass="form-control" label="services.returnPosition"
                 [(ngModel)]="data.returnPosition" name="returnPosition">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field position-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="position" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="position" label="services.position"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'position'}]}"
                  [(ngModel)]="data.position"></lp-dropdown-field>-->
                <lp-zoom-field id="position" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId='position' className="zoomField" cssClass="form-control" label="services.position"
                 [(ngModel)]="data.position" name="position">
                </lp-zoom-field>
              </div>

              <!--Field movementBehavior-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="movementBehavior" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="movementBehavior" label="services.movementBehavior"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'movementBehavior'}]}"
                  [(ngModel)]="data.movementBehavior"></lp-dropdown-field>-->
                <lp-zoom-field id="movementBehavior" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId='movementBehavior' className="zoomField" cssClass="form-control" label="services.movementBehavior"
                 [(ngModel)]="data.movementBehavior" name="movementBehavior">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field warrantyType-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="warrantyType" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="warrantyType" label="services.warrantyType"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'warrantyType'}]}"
                  [(ngModel)]="data.warrantyType"></lp-dropdown-field>-->
                <lp-zoom-field id="warrantyType" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId='warrantyType' className="zoomField" cssClass="form-control" label="services.warrantyType"
                 [(ngModel)]="data.warrantyType" name="warrantyType">
                </lp-zoom-field>
              </div>
              <!--Field ean-->
              <div class="form-group col-xl-6">
                <lp-text-field id="ean" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="services.ean"
                  cssClass="form-control" name="ean" [(ngModel)]="data.ean">
                </lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--Field preventiveCalculationMode-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="preventiveCalculationMode" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="preventiveCalculationMode" label="services.preventiveCalculationMode"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'preventiveCalculationMode'}]}"
                  [(ngModel)]="data.preventiveCalculationMode"></lp-dropdown-field>-->
                <lp-zoom-field id="preventiveCalculationMode" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId='preventiveCalculationMode' className="zoomField" cssClass="form-control" label="services.preventiveCalculationMode"
                 [(ngModel)]="data.preventiveCalculationMode" name="preventiveCalculationMode">
                </lp-zoom-field>
              </div>
              <!-- field order -->
              <div class="form-group col-xl-6">
                <lp-text-field id="order" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="services.order"
                  cssClass="form-control" name="order" [(ngModel)]="data.order">
                </lp-text-field>
              </div>
            </div>

          </lp-ui-accordion-group>

            <!--Accordion Debugger-->
            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>