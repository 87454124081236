      <ul class="top">
        <li>
          <a (click)="setFormStack('/')" title="{{'general.other.home' | translate}}">
              <i class="vega-home cursor-pointer"></i>
          </a>
        </li>
        <ng-container *ngIf="userService && userService.getCurrentUser() && userService.getCurrentUser().outbar">
          <li *ngFor="let menu of userService.getCurrentUser().outbar">
            <a *ngIf="menu && !menu.link" title="{{ menu.label | translate}}" (click)="setFormStack(menu.verb)" class="cursor-pointer" [class.activeVerb]="formStackService.currentVerb == menu.verb">
              <i *ngIf='!menu || menu === undefined || menu === null || !menu.icon' class="icon vega-warning"></i>
              <i *ngIf="menu && menu !== undefined && menu !== null && menu.icon" class="icon {{ menu.icon }}"></i>
            </a>
          </li>
          <li><hr class="m-1 ms-2 me-2"/></li>
          <li *ngFor="let link of links">
            <a *ngIf="link && link.url && link.icon" title="{{ link.title | translate}}" href="{{link.url}}" target="_blank" class="cursor-pointer">
              <i *ngIf='!link || link === undefined || link === null || !link.icon' class="icon vega-warning"></i>
              <i *ngIf="link && link !== undefined && link !== null && link.icon" class="icon {{ link.icon }}"></i>
            </a>
          </li>
        </ng-container>
      </ul>
