

<!-- passer en input tout les champs renvoyer par l'appel /parameters -->
<lp-top-banner *ngIf="isReady" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm"
  titleTranslationKey="inspections.inspection"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="isReady && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)"
  (drop)="drop($event)">

  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
  <div class="container p-0" id="inspection">

    <div class="container-2-cols left" #containerCols>
      <div class="card-box col-md-6 label-2-colonnes" #col1>
        <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate class="formInspection">
          <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"  [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="false"
            (showPrintOutput)="changeShowZoomPrint($event)"
            [showPrintInput]="showZoomPrint"></lp-common-tools>
          <lp-ui-accordion *ngIf="showCheckList && showCheckList === true" class="row" [menu]=true >
           <!-- <lp-ui-accordion-group *ngFor="let group of checkListGroups" heading="{{group.wording}}" [open]=true key="{{group.id}}">
              
              <ng-container *ngFor="let line of data.checklists">
                <div class="row" *ngIf="line && line.groupCategory && group && line.groupCategory.id === group.id">
                  <div class="col-3">{{line.wording}}</div>
                  <lp-dropdown-field 
                  *ngIf="line && line.damageCategory && line.damageCategory.id && damageCategories.get(line.damageCategory.id) && damageCategories.get(line.damageCategory.id).possibleValues.length > 2"
                    [values]="damageCategories.get(line.damageCategory.id) ? damageCategories.get(line.damageCategory.id).possibleValues : []"
                    [readonly]="formStackService.isDataReadOnly()"  class="col-8 col-xl-4 h-auto"  className="textField" cssClass="form-control p-1 h-auto" [(ngModel)]="line.damageValue" id="damageValues-{{line.id}}" name="damageValues-{{line.id}}"></lp-dropdown-field>
                  <lp-radio-box 
                    *ngIf="line && line.damageCategory && line.damageCategory.id && damageCategories.get(line.damageCategory.id) && damageCategories.get(line.damageCategory.id).possibleValues.length < 3" 
                    [readonly]="formStackService.isDataReadOnly()"
                    [values]="damageCategories.get(line.damageCategory.id) ? damageCategories.get(line.damageCategory.id).possibleValues : []" class="col-8" cssClass="col-xl-4" [(ngModel)]="line.damageValue.id" name="damageValues-{{line.id}}"></lp-radio-box>
                    <div class="col-xl-4" *ngIf="damageCategories.get(line.damageCategory.id) && damageCategories.get(line.damageCategory.id).possibleValues.length > 2"></div>
                    <div class="col-1 text-end">
                    <a [class.available]="line.nbMemos > 0" class="inspection-memo" title="{{'general.memos' | translate}} ({{line.nbMemos}})" (click)="showMemos(line.id)">
                      <i class="icon vega-attachment"></i>
                    </a>
                 </div>
                </div>
              </ng-container>

            </lp-ui-accordion-group>-->

            <lp-ui-accordion-group *ngFor="let group of checkListGroups"  heading="{{group.wording}}" key="{{group.id}}" [open]=true >

              <ng-container *ngFor="let line of data.checklists">
                <div class="row" *ngIf="line && line.groupCategory && group && line.groupCategory.id === group.id">
                  <div class="col-3">{{line.wording}}</div>
                  <lp-dropdown-field 
                  *ngIf="line && line.damageCategory && line.damageCategory.id && damageCategories.get(line.damageCategory.id) && damageCategories.get(line.damageCategory.id).possibleValues.length > 2"
                    [values]="damageCategories.get(line.damageCategory.id) ? damageCategories.get(line.damageCategory.id).possibleValues : []"
                    [readonly]="formStackService.isDataReadOnly()"  class="col-8 col-xl-4 h-auto"  className="textField" cssClass="form-control p-1 h-auto" [(ngModel)]="line.damageValue" id="damageValues-{{line.id}}" name="damageValues-{{line.id}}"></lp-dropdown-field>
                    {{line?.damageCategory?.id | json }} ---
                    <lp-radio-box 
                    *ngIf="line && line.damageCategory && line.damageCategory.id && damageCategories.get(line.damageCategory.id) && damageCategories.get(line.damageCategory.id).possibleValues.length < 3" 
                    [readonly]="formStackService.isDataReadOnly()"
                    [values]="damageCategories.get(line.damageCategory.id) ? damageCategories.get(line.damageCategory.id).possibleValues : []" class="col-8" cssClass="col-xl-4" [(ngModel)]="line?.damageValue.id" name="damageValues-{{line.id}}"></lp-radio-box>
                    <div class="col-xl-4" *ngIf="damageCategories.get(line.damageCategory.id) && damageCategories.get(line.damageCategory.id).possibleValues.length > 2"></div>
                    <div class="col-1 text-end">
                    <a [class.available]="line.nbMemos > 0" class="inspection-memo" title="{{'general.memos' | translate}} ({{line.nbMemos}})" (click)="showMemos(line.id)">
                      <i class="icon vega-attachment"></i>
                    </a>
                  </div>
                </div>
              </ng-container>

              <!--VERSION DEV A GARDER-->
              <!--<ng-container>
                <div class="row" *ngFor="let element of line.elements">
                  <div class="col-3">{{element.wording}} </div>

                  <lp-dropdown-field 
                  *ngIf="element && element.checklistModelId && damageCategories.get(element.checklistModelId) && damageCategories.get(element.checklistModelId).possibleValues.length > 2"
                    [values]="damageCategories.get(element.checklistModelId) ? damageCategories.get(element.checklistModelId).possibleValues : []"
                    [readonly]="formStackService.isDataReadOnly()"  
                    class="col-8 col-xl-4 h-auto"  
                    className="textField" 
                    cssClass="form-control p-1 h-auto" 
                    [(ngModel)]="element.savedValue" id="damageValues-{{element.checklistModelId}}" name="damageValues-{{element.checklistModelId}}"></lp-dropdown-field>

                  <lp-radio-box 
                  *ngIf="element && element.checklistModelId && damageCategories.get(element.checklistModelId) && damageCategories.get(element.checklistModelId).possibleValues.length < 3"
                    [readonly]="formStackService.isDataReadOnly()"
                    [values]="damageCategories.get(element.checklistModelId) ? damageCategories.get(element.checklistModelId).possibleValues : []"
                    [(ngModel)]="element.savedValue"
                    class="col-8" cssClass="col-xl-4" id="damageValues-{{element.checklistModelId}}" name="damageValues-{{element.checklistModelId}}"></lp-radio-box>


                  <div class="col-xl-4" *ngIf="damageCategories.get(element.checklistModelId) && damageCategories.get(element.checklistModelId).possibleValues.length > 2"></div>
                  <div class="col-1 text-end">
                    <a [class.available]="element && element.attachments && element.attachments.length > 0" class="inspection-memo" title="{{'general.memos' | translate}} ({{element.attachments.length}})" (click)="showMemos(element.id)">
                      <i class="icon vega-attachment"></i>
                    </a>
                  </div>
                </div>
              </ng-container>-->


            </lp-ui-accordion-group>
            <lp-ui-accordion-group *ngIf="showSignature" heading="{{'inspections.signatures' | translate}}" [open]=true >
              <div class="row ">
                  <div  class="col-md-6">                        
                    <p>{{'inspections.customer_signature' | translate}} <span *ngIf="signatureCustomerMemo && signatureCustomerMemo.id">{{data.driverName}}</span></p>
                    <lp-signature-pad *ngIf="signatureCustomerMemo && !signatureCustomerMemo.id" [(ngModel)]="customerSignature" id="customerSignature" name="customerSignature" class="mt-2 d-block"></lp-signature-pad>
                    <p class="m-0 text-center">
                      {{signatureCustomerMemo.file}}
                      <img *ngIf="signatureCustomerMemo && signatureCustomerMemo.id" class="mw-100" src="{{signatureCustomerMemo.file}}"(click)="uiSyncService.displayZoomImage(signatureCustomerMemo.file)"/>
                    </p>  
                  </div>
                 <div  class="col-md-6">
                    <p>{{'inspections.agent_signature' | translate}} <span *ngIf="signatureAgentMemo && signatureAgentMemo.id">{{data.agentName}}</span></p>
                    <lp-signature-pad *ngIf="signatureAgentMemo && !signatureAgentMemo.id" [(ngModel)]="agentSignature" id="agentSignature" name="agentSignature" class="mt-2 d-block"></lp-signature-pad>
                    <p class="m-0 text-center">
                      <img *ngIf="signatureAgentMemo && signatureAgentMemo.id" class="mw-100" src="{{signatureAgentMemo.file}}"(click)="uiSyncService.displayZoomImage(signatureAgentMemo.file)"/>
                    </p>
                  </div>
              </div>
            </lp-ui-accordion-group>
            <!--<lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>-->
          </lp-ui-accordion>
        </form>
      </div>
      <div class="col-md-6 card-box" #col2>
          <lp-ui-accordion class="row" [menu]=false *ngIf="showCarousel && !ihmService.ShowReport" >
              <lp-ui-accordion-group  heading="{{'inspections.titleCarousel' | translate}}" [open]=true >
                <div class="row border-bottom mb-2">
                    <div  class="col-md-4">
                        <lp-boolean-switch-box name="showOldDamage" switchName="showOldDamage" [(ngModel)]="showOldDamage" [readonly]="formStackService.isDataReadOnly()"></lp-boolean-switch-box>
                        <lp-label-field text="inspections.showOldDamage" class="ms-2 d-inline-block"></lp-label-field>
                    </div>
                    <div  class="col-md-4">
                        <lp-boolean-switch-box name="showNewDamage" switchName="showNewDamage" [(ngModel)]="showNewDamage" [readonly]="formStackService.isDataReadOnly()"></lp-boolean-switch-box>
                        <lp-label-field text="inspections.showNewDamage" class="ms-2 d-inline-block"></lp-label-field>
                    </div>
                    <div  class="col-md-4">
                        <lp-boolean-switch-box name="showInactiveDamage" switchName="showInactiveDamage" [(ngModel)]="showInactiveDamage" [readonly]="formStackService.isDataReadOnly()"></lp-boolean-switch-box>
                        <lp-label-field text="inspections.showInactiveDamage" class="ms-2 d-inline-block"></lp-label-field>
                    </div>
                </div>
                <div #carouselFaces id="carouselFaces">
                  <carousel [interval]="false" class="carousel-damage" (activeSlideChange)="activeSlideChange($event)" [(activeSlide)]="activeSlide">
                    <slide *ngFor="let face of data.faces; let i = index;">
                      <h3 class="text-center mb-2 pb-2">{{face.wording}}</h3>                      
                      <svg width="850" height="500" [ngStyle]="{'zoom': svgRatio}">
                          <image [attr.xlink:href]="face.picture" id="pictureSVG" height="100%" width="100%"></image>
                          
                          <g *ngFor="let zone of face.zones">
                              <polygon *ngIf="zone.show && zone.shapeType == 'POLY'" [attr.fill]="uiSyncService.primaryColor"
                                        [attr.stroke]="uiSyncService.primaryColor" style="fill-opacity:0.3;stroke-width:1"
                                        (mouseleave)="changeZoneHover(face)"
                                        [attr.points]="zone.coordinates" (click)="createDamage($event, face, zone, i)"/>

                              <polygon *ngIf="!zone.show && zone.shapeType == 'POLY'" [attr.fill]="uiSyncService.primaryColor"
                                        [attr.stroke]="uiSyncService.primaryColor" style="fill-opacity:0;stroke-width:0"
                                        (mouseenter)="changeZoneHover(face,zone)"
                                        [attr.points]="zone.coordinates" (click)="createDamage($event, face, zone, i)"/>
          
                              <circle *ngIf="zone.show && zone.shapeType == 'CIRCLE'" [attr.fill]="uiSyncService.primaryColor"
                                      [attr.stroke]="uiSyncService.primaryColor" style="fill-opacity:0.3;stroke-width:1"
                                      (click)="createDamage($event, face, zone, i)" [attr.cx]="zone.coordinates.split(',')[0]"
                                      (mouseleave)="changeZoneHover(face)"
                                      [attr.cy]="zone.coordinates.split(',')[1]" [attr.r]="zone.coordinates.split(',')[2]"/>

                              <circle *ngIf="!zone.show && zone.shapeType == 'CIRCLE'" [attr.fill]="uiSyncService.primaryColor"
                                      [attr.stroke]="uiSyncService.primaryColor" style="fill-opacity:0;stroke-width:0"
                                      (click)="createDamage($event, face, zone, i)" [attr.cx]="zone.coordinates.split(',')[0]"
                                      (mouseenter)="changeZoneHover(face,zone)"
                                      [attr.cy]="zone.coordinates.split(',')[1]" [attr.r]="zone.coordinates.split(',')[2]"/>
                          </g>

                            <g *ngFor="let damage of face.damages; let i = index;">   
                              {{damage | json}}
                                <image *ngIf="!isNewDamage(damage) && showOldDamage && isActiveDamage(damage)"
                                      [attr.x]="damage.xAxis - getPinHalfWidth()"
                                      [attr.y]="damage.yAxis - getPinHeight()"
                                      [attr.width]= "2 * getPinHalfWidth()"
                                      [attr.height]="getPinHeight()"
                                      xlink:href="/assets/img/pinOldDamage.svg"
                                      id="pin-{{damage.rowNumber}}"></image>
  
                                <image *ngIf="isNewDamage(damage) && showNewDamage && isActiveDamage(damage)"
                                      [attr.x]="damage.xAxis - getPinHalfWidth()"
                                      [attr.y]="damage.yAxis - getPinHeight()"
                                      [attr.width]= "2 * getPinHalfWidth()"
                                      [attr.height]="getPinHeight()"
                                      xlink:href="/assets/img/pinDamage.svg"
                                      id="pin-{{damage.rowNumber}}"></image>
  
                                <image *ngIf="showInactiveDamage && !isActiveDamage(damage)"
                                      [attr.x]="damage.xAxis - getPinHalfWidth()"
                                      [attr.y]="damage.yAxis - getPinHeight()"
                                      [attr.width]= "2 * getPinHalfWidth()"
                                      [attr.height]="getPinHeight()"
                                      xlink:href="/assets/img/pinDeactivateDamage.svg"
                                      id="pin-{{damage.rowNumber}}"></image>
                                <text *ngIf="(isNewDamage(damage) && showNewDamage && isActiveDamage(damage)) || (!isNewDamage(damage) && showOldDamage && isActiveDamage(damage)) || (showInactiveDamage && !isActiveDamage(damage))" [attr.x]="damage.xAxis" text-anchor="middle"  [attr.y]="damage.yAxis - 22" [attr.width]= "2 * getPinHalfWidth()" fill="#000000" font-size="18" id="txt-{{damage.rowNumber}}">{{damage.rowNumber}}</text>
                            </g>

                      </svg>
                    </slide>
                  </carousel>
                </div>
              </lp-ui-accordion-group>
              <lp-ui-accordion-group  heading="{{'inspections.allDamages' | translate}}" [open]=true>  

                <ngx-datatable  *ngIf="showDamageList" class="material table-height-35 list-damage" [rows]="allDamages" [columnMode]="'force'" [rowClass]="getRowClass"
                  [headerHeight]="0" [rowHeight]="'auto'" width="100%"
                  [messages]="{emptyMessage: 'general.emptyMessage' |translate}" [footerHeight]="0">

                  <ngx-datatable-column [minWidth]="40">                  
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-damage="row"
                      let-i="index">
                      <span (click)="changeActiveSlide(damage.faceId)" class="rowNumber-list" title="{{'inspections.goToFace' | translate}} ">{{damage.rowNumber}}</span>
                        <img *ngIf="!isNewDamage(damage) && isActiveDamage(damage)" id="list-{{damage.rowNumber}}" class="img-list-damage" height="40px" src="/assets/img/pinOldDamage.svg" alt="{{'inspections.oldDamage' |translate}}" title="{{'inspections.oldDamage' |translate}}"/>
                        <img *ngIf="!isActiveDamage(damage)" id="list-{{damage.rowNumber}}"  class="img-list-damage" height="40px" src="/assets/img/pinDeactivateDamage.svg" alt="{{'inspections.newDamage' |translate}}" title="{{'inspections.newDamage' |translate}}"/>
                        <img *ngIf="isNewDamage(damage) && isActiveDamage(damage)" id="list-{{damage.rowNumber}}"  class="img-list-damage" height="40px" src="/assets/img/pinDamage.svg" alt="{{'inspections.newDamage' |translate}}" title="{{'inspections.newDamage' |translate}}"/>
                      </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column>                  
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-damage="row"
                      let-i="index">
                      <lp-cell [value]="damage.faceWording"></lp-cell>                        
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column>                  
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-damage="row"
                      let-i="index">
                      <lp-cell [value]="damage.groupCategory.wording"></lp-cell>                        
                    </ng-template>
                  </ngx-datatable-column>

                 <!--<ngx-datatable-column>                  
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-damage="row"
                      let-i="index">
                        <lp-cell [value]="damage.damageValue.wording"></lp-cell>   
                        
                    </ng-template>
                  </ngx-datatable-column>-->

                  <ngx-datatable-column>                  
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-damage="row"
                      let-i="index">
                        <lp-cell value="{{getDamageResponsability(damage) | translate}}"></lp-cell>   
                        
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column>                  
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-damage="row"
                      let-i="index">
                        <lp-cell [type]="5" [value]="damage.updateDate"></lp-cell>   
                    </ng-template>
                  </ngx-datatable-column>
        
                  <ngx-datatable-column [width]="50" [resizeable]="false"
                    [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'">
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
                      let-i="index" style="margin-top: 10px;">
                      <a *ngIf="row.id" [class.available]="row.nbMemos > 0" class="inspection-memo" title="{{'general.memos' | translate}} ({{row.nbMemos}})" (click)="showMemos(row.id)">
                        <i class="icon vega-attachment"></i>
                      </a>
                      <a *ngIf="!row.id" [class.available]="row.attachments.length > 0" class="inspection-memo awaiting-backup" title="{{'general.awaitingBackup' | translate}}" (click)="showModalAwaitingBackup()">
                        <i class="icon vega-attachment"></i>
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
        
                  <ngx-datatable-column [width]="50" [resizeable]="false"
                    [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'">
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-damage="row"
                      let-i="index" style="margin-top: 10px;">
                      <a class="inspection-memo" title="{{'general.button.deleteDamage' | translate}}"  (click)="deleteDamage(damage, rowIndex)">
                        <i class="icon vega-delete"></i> 
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
        
                </ngx-datatable>                
              </lp-ui-accordion-group>
              <lp-ui-accordion-group *ngIf="!formStackService.isDataReadOnly()" heading="{{'inspections.editDamage' | translate}}" [open]=true id="damageForm">  
                <p *ngIf="!(currentDamage && currentZone)">{{'inspections.clickToStartDamage' | translate}}</p>
                <form *ngIf="currentDamage && currentZone" #formDamage="ngForm" ngNativeValidate>
                    <div class="form-group col-xl-12">
                      <lp-label-field for="clientResponsibility" class="{{arrayClassesElements.get('defaultLabel')}}" text="inspections.clientResponsibility"></lp-label-field>
                      <lp-dropdown-field id="clientResponsibility" outputType="string" class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" name="clientResponsibility" [values]="responsabilities" [(ngModel)]="currentDamage.clientResponsibility"></lp-dropdown-field>
                    </div>  
                  <div class="form-group col-xl-12">
                    <lp-label-field for="groupCategory" [required]="true" class="{{arrayClassesElements.get('defaultLabel')}}" text="inspections.groupCategory"></lp-label-field>
                    <lp-dropdown-field id="groupCategory" [required]="true" class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" name="groupCategory" [values]="currentZone.elements" [(ngModel)]="currentDamage.groupCategory" (putEvtEmitter)="afterSelectGroupCategory()"></lp-dropdown-field>
                  </div>       
                  <div class="form-group col-xl-12" *ngIf="currentDamage && currentDamage.damageCategory">
                    <lp-label-field for="damageValue" [required]="true" class="{{arrayClassesElements.get('defaultLabel')}}" text="inspections.damageValue"></lp-label-field>
                    <lp-dropdown-field id="damageValue" [required]="true" class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" name="damageValue" [values]="currentDamageValues" [(ngModel)]="currentDamage.damageValue" (putEvtEmitter)="afterSelectDamageValue()"></lp-dropdown-field>
                  </div>        
                  <div class="form-group col-xl-12">
                    <lp-label-field for="comment" class="{{arrayClassesElements.get('defaultLabel')}}" text="inspections.comment"></lp-label-field>
                    <lp-textarea-field id="comment" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="textareaField" name="comment" [(ngModel)]="tempComment">
                    </lp-textarea-field>  
                  </div>    
                  <div class="form-group col-xl-12">
                    <lp-label-field for="picture" class="{{arrayClassesElements.get('defaultLabel')}}" text="inspections.picture"></lp-label-field>
                    <div class="col-md-8 col-lg-8 col-xl-7">
                      <lp-drop-file name="file" filename="picture" [(ngModel)]="tempPicture"></lp-drop-file>
                    </div>
                    <div class="col-md-4 col-lg-4 col-xl-5" *ngIf="tempPicture && fileService.isImage(tempPicture)">
                      <img src="{{tempPicture}}" (click)="uiSyncService.displayZoomImage(tempPicture)" class="previewTempImg"/>
                    </div>
                  </div>                      

                  <div class="text-end">
                    <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm" (click)="saveDamage(formDamage.form.valid)"><i class="icon vega-diskette" title="{{'inspections.addDamage' |translate}}"></i></button>
                  </div>
                </form> 

              </lp-ui-accordion-group>
              
              <lp-ui-accordion-group *ngIf="currentDamage && currentZone" heading="debuggage" [open]=false >  
                <pre>
                  {{allDamages | json}}
                </pre>
              </lp-ui-accordion-group>
          </lp-ui-accordion>
  
        <!--zoom pour les impressions-->
        <lp-report [ky]="ky" [customerInvoiceKy]="data.customerInvoice?.id" *ngIf="ihmService.ShowReport" (isShownOutput)="changeShowZoomPrint($event)"></lp-report>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>