import { Injectable } from '@angular/core';
import { FormStackService, SCREEN_TEST } from '../form-stack.service';
import { FormMetadataValues } from 'app/models/form-metadata-values';
import { LpMeta } from 'app/meta/meta';
import { EvalService, BEFORE_UPDATE_EVENT, BEFORE_GET_EVENT, AFTER_GET_EVENT, MAIN } from '../eval.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { UiSyncService } from '../ui-sync.service';
import { RightColService } from './rightCol.service';
import { Util } from 'app/statics/utils';
import { ChangeService } from '../change.service';
import { UserHistoryService } from '../user-history.service';

@Injectable({
  providedIn: 'root'
})
export class KyService {

  constructor(  private formStackService: FormStackService,
                private evalService: EvalService,
                private alocproProvider: AlocproProvider,
                private uiSyncService: UiSyncService,
                private rightColService: RightColService,
                private changeService: ChangeService,
                private userHstoryService: UserHistoryService) { }

  public async initKyForm(ky: string): Promise<void> {
    this.changeService.initChange();
    
    if (this.formStackService.currentApplicationItem.actions !== null
      && this.formStackService.currentApplicationItem.actions !== undefined
      && this.formStackService.currentApplicationItem.actions.length > 0 ) {
      this.formStackService.isLoadingEvalActions = true;
    }    
    await this.evalService.executeContrainte(BEFORE_GET_EVENT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString()
    , '*', this.formStackService.currentData, this.formStackService.currentFields);
    await this.getBackEndData(this.formStackService.CurrentVerb, ky);
    await this.setIHMValues();
    await this.getBackEndMetadata(this.formStackService.CurrentVerb, ky);
    await this.executeCommonToolButtonCode(this.formStackService.currentData);
    if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail)
      && !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.id)) {
      this.formStackService.initLpLine(this.formStackService.currentApplicationItemDetail.key.toString())
      await this.rightColService.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail
        , this.formStackService.currentData);
    }
    setTimeout(async () => {
      await this.evalService.executeContrainte(AFTER_GET_EVENT, MAIN
        , this.formStackService.currentApplicationItem.ecrId.toString(), '*', this.formStackService.currentData
        , this.formStackService.currentFields);
      let formMeta: FormMetadataValues = {'fromPlanning': this.formStackService.isPreviousStackPlanning(), 'isNewForm': false, subApi: false};
      this.uiSyncService.emitFormMetadataLoaded(formMeta);
    });
  }

  public async submit(verb: string, ky: string, data: LpMeta): Promise<string> {
    try {
      await this.evalService.executeContrainte(
        BEFORE_UPDATE_EVENT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString(), '*', data
        , this.formStackService.currentFields);
      await this.alocproProvider.put(verb, ky, data.optimized());
      this.userHstoryService.addEvent('modify', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb);
      return this.formStackService.CurrentKy;
    } catch (e) {
      throw(e);
    }
  }

  private async getBackEndMetadata(verb: string, ky: string): Promise<void> {
    try {
// return this.formStackService.getApiMetadata(verb, true, false).then(() => {
      if (verb !== SCREEN_TEST) {
        await this.formStackService.getAllMetadata(verb, ky, false)
        Promise.resolve();
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private async getBackEndData(verb: string, ky: string): Promise<void> {
    try {
      if (verb !== SCREEN_TEST) {
        if (!this.formStackService.ignoreDataLoading) {
          await this.formStackService.currentDataAssign(verb, ky)
        } else {
          this.formStackService.ignoreDataLoading = false;
          this.formStackService.isCurrentDataPreloaded = false;
        }
        this.uiSyncThings();
      }
      return;
    } catch (e) {
      console.log(e);
      return Promise.resolve(null);
    }
  }

  private uiSyncThings(): void {
    this.uiSyncService.loadObject();
    if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail)) {
      this.uiSyncService.changeRightBlock(this.formStackService.currentApplicationItemDetail.key, false, null, true);
    }
  }

  private async executeCommonToolButtonCode(data: LpMeta): Promise<void> {
    if (this.formStackService.currentApplicationItem.actions !== null
      && this.formStackService.currentApplicationItem.actions !== undefined
      && this.formStackService.currentApplicationItem.actions.length > 0 ) {
      for (let i in this.formStackService.currentApplicationItem.actions) {
        
          if (this.formStackService.currentApplicationItem.actions[i].availableEval
            && this.formStackService.currentApplicationItem.actions[i].availableEval !== '') {
            this.formStackService.currentApplicationItem.actions[i].hidden = true;
              await this.evalService.eval(
                this.formStackService.currentApplicationItem.actions[i].availableEval,
                data,
                null,
                {'action': this.formStackService.currentApplicationItem.actions[i]}
              );
              this.formStackService.currentApplicationItem.actions[i]._show = true;
          } else {
            this.formStackService.currentApplicationItem.actions[i]._show = true;
          }
      }
      this.formStackService.isLoadingEvalActions = false;
      this.uiSyncService.loader(false);
    }
  }

  private async setIHMValues(): Promise<void> {
    this.uiSyncService.IsKyForm = true;
  }
}
