<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="third-parties.thirdPartiesRelations.title" [open]=true>
    <form id="formthirdPartiesRelations" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>


      <div class="row">
        <!-- Ligne du formulaire à insérer ici -->
        <div class="form-group col-xl-6">
          <lp-label-field for="thirdPartiesRelations.isActive" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="third-parties.thirdPartiesRelations.isActive"></lp-label-field>
          <lp-boolean-switch-box name="thirdPartiesRelations.isActive" switchName="isActive" [(ngModel)]="data.isActive"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-6">
          <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}" for="thirdPartiesRelations.isFatherTariff"
            text="third-parties.thirdPartiesRelations.isFatherTariff"></lp-label-field>
          <lp-boolean-switch-box name="thirdPartiesRelations.isFatherTariff" switchName="isFatherTariff" [(ngModel)]="data.isFatherTariff"></lp-boolean-switch-box>
        </div>        
      </div>
      <div class="row">
        <div class="form-group col-xl-6">
          <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
            text="third-parties.thirdPartiesRelations.relation" for="thirdPartiesRelations.relation" [required]=true></lp-label-field>
            <lp-switch-box class="{{arrayClassesElements.get('defaultInput')}}" inputBox="tabSwitch" name="thirdPartiesRelations.state" id="thirdPartiesRelations.relation" (change)="changeRelation()"
              [values]="relations"
              switchName="state" [(ngModel)]="data._relationSwitch" [required]=true></lp-switch-box>
        </div>

        <div class="form-group col-xl-6" *ngIf="isSon()">
          <lp-label-field for="thirdPartiesRelations.father" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="third-parties.thirdPartiesRelations.father"></lp-label-field>
          <lp-zoom-field id="thirdPartiesRelations.father"  zoomId="thirdPartiesRelations.relationLink" name="thirdPartiesRelations.father" className="zoomField form-control" [(ngModel)]="data.father"
          class="{{arrayClassesElements.get('defaultInput')}}">
          </lp-zoom-field>
        </div>

        <!-- Field son -->
        <div class="form-group col-xl-6" *ngIf="isFather()">
          <lp-label-field for="thirdPartiesRelations.son" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="third-parties.thirdPartiesRelations.son"></lp-label-field>
          <lp-zoom-field id="thirdPartiesRelations.son" zoomId="thirdPartiesRelations.relationLink" name="thirdPartiesRelations.son" [(ngModel)]="data.son"
          class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
          </lp-zoom-field>
        </div>
      </div>

      <!-- Field Link TODO check all field after update swagger -->
      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesRelations.link" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="third-parties.thirdPartiesRelations.link"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" id="thirdPartiesRelations.link"
          name="thirdPartiesRelations.link" [(ngModel)]="data.link" labelField="sonWording" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'link'}]}"></lp-dropdown-field>
      </div>

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>