import { LpMeta, propertyMeta } from './meta';
import { LpMovement } from './movement';
import { LpLocation } from './location';
import { LpThirdParty } from './third-party';
import { FtMeta } from './ft-meta';
import { LpPricing } from './pricing';
import { LpPricingMethod } from './pricing-method';
import { Type } from 'class-transformer';
import { LpInvoice } from './invoice';

export class LpShortTermContract extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('string')
    public contractNumber: String;
    @propertyMeta('string')
    public bookingCode: String;
    @propertyMeta('string')
    public wantedDays: Number;
    @propertyMeta('string')
    public wantedMileage: Number;
    @propertyMeta('string')
    public cumulativeMileage: Number;
    @Type(() => LpLocation)
    public startLocation: LpLocation = new LpLocation();
    @propertyMeta('date')
    public plannedReturnDate: Date;
    @propertyMeta('date')
    public returnDate: Date;
    @Type(() => LpLocation)
    public plannedReturnLocation: LpLocation = new LpLocation();
    @Type(() => LpLocation)
    public returnLocation: LpLocation = new LpLocation();
    @Type(() => LpLocation)
    public invoiceLocation: LpLocation = new LpLocation();
    @propertyMeta('date')
    public invoiceStart: Date;
    @propertyMeta('boolean')
    public isTourOperatorPricing: boolean = null;
    @propertyMeta('date')
    public invoiceReturn: Date;
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('boolean')
    public closed: Boolean = false;
    @propertyMeta('string')
    public increaseRate: Number;
    @propertyMeta('string')
    public freePeriod: String;
    @propertyMeta('string')
    public billingRentDue: String;
    @propertyMeta('string')
    public invoiceMaxDays: Number;
    @propertyMeta('string')
    public replaceRegistration: String;
    @propertyMeta('string')
    public discountRate: Number;
    @propertyMeta('string')
    public discountAmount: Number;
    @propertyMeta('string')
    public driverDiscountRate: Number;
    @propertyMeta('string')
    public promotionalCode: String;
    @propertyMeta('number')
    public deposit: Number;
    @propertyMeta('string')
    public voucher: String;
    @propertyMeta('date')
    public voucherDate: Date;
    @propertyMeta('number')
    public voucherAmount: Number;
    @propertyMeta('string')
    public customerReference: String;
    @propertyMeta('string')
    public transportReference1: String;
    @propertyMeta('string')
    public transportReference2: String;
    @propertyMeta('string')
    public itvCode: String;
    @propertyMeta('string')
    public includedDays: Number;
    @propertyMeta('string')
    public includedMileage: Number;
    @propertyMeta('string')
    public includedMileageByDay: Number;
    @propertyMeta('string')
    public hourlyPricing: String;
    @Type(() => LpMovement)
    public movement: LpMovement = new LpMovement();
    @Type(() => LpThirdParty)
    public customer: LpThirdParty = new LpThirdParty();
    @Type(() => LpInvoice)
    public customerInvoice: LpInvoice = new LpInvoice();
    @Type(() => LpInvoice)
    public driverInvoice: LpInvoice = new LpInvoice();
    @Type(() => LpThirdParty)
    public driver: LpThirdParty = new LpThirdParty();
    @Type(() => FtMeta)
    public category: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public rentalType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public status: FtMeta = new FtMeta();
    // TODO Refactorisation
    public metadatas: any;
    @Type(() => FtMeta)
    public cancelReason: FtMeta = new FtMeta();
    // public additionalDrivers: LpThirdParty[];
    @Type(() => LpPricing)
    public pricing: LpPricing = new LpPricing();
    @Type(() => LpPricingMethod)
    public pricingMethod: LpPricingMethod = new LpPricingMethod();
    @Type(() => LpThirdParty)
    public merchant: LpThirdParty = new LpThirdParty();
    @propertyMeta('string')
    public currentDeductibleBuyback: String;
    public groupingCode: string;

    protected _unoptimizedKeys: String[] = ['movement'];

}
