import { LpMeta } from './meta';

export class LpDuplicateLpMeta extends LpMeta {
    public optimized(): any {
        let duplicateLpMeta: LpDuplicateLpMeta = super.optimized();

        delete duplicateLpMeta.id;
        delete duplicateLpMeta.details;
        delete duplicateLpMeta._readOnly;
        delete duplicateLpMeta._currentActionData;
        delete duplicateLpMeta._unoptimizedKeys;
        delete duplicateLpMeta._xVegaAttachmentsCount;
        delete duplicateLpMeta._xVegaEdisCount;
        delete duplicateLpMeta._xVegaWorkflowsCount;

        Object.keys(duplicateLpMeta).forEach((key) => {
            if (duplicateLpMeta[key] === null) {
                delete duplicateLpMeta[key];
            } else if (duplicateLpMeta[key] instanceof LpMeta || duplicateLpMeta[key] instanceof Object) {
                duplicateLpMeta[key] = duplicateLpMeta[key].id;
            }
        });
        return duplicateLpMeta;
    }
}
