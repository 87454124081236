
<div class="container label-2-colonnes">
  <div *ngFor="let field of formDetailsTab; let i = index">
    <div class="row">
      <div *ngIf=" field[0] && field[0].name && ( field[0].name.toUpperCase() === 'LPTRI' ) && field[0].wording.toUpperCase() !== 'VIDE'" class="form-group col-xl-6">
          <lp-label-field *ngIf="field[0]" for="inChargeOf" class="{{arrayClassesElements.get('defaultLabel')}}" text="{{field[0].wording}}"></lp-label-field>
          <select *ngIf="field[0].type == 'LISTE'" class="textField form-control" name="{{field[0].name}}" [(ngModel)]="field[0].value" >
            <option *ngFor="let v of field[0].listItem" id="{{v.name}}" [value]="v.name" >{{v.name}}</option>
          </select>
      </div>
      <div *ngIf=" field[0] && field[0].name && ( field[0].name.toUpperCase() === 'LPTRI' ) && field[0].wording.toUpperCase() !== 'VIDE'" class="form-group col-xl-6">       
      </div>
      <div *ngIf="field[0] && field[0].name && ( field[0].name.toUpperCase() !== 'LPTRI')" class="form-group col-xl-6" [hidden]="field[0].wording.toUpperCase() === 'VIDE'">
        <lp-label-field *ngIf="field[0]" for="inChargeOf" class="{{arrayClassesElements.get('defaultLabel')}}" text="{{field[0].wording}}"></lp-label-field>
        <!--lp-text-field-->
        <lp-text-field *ngIf="field[0].type === 'TEXT' || field[0].type === 'ZOOM'" id="inChargeOf" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
          cssClass="form-control" name="{{field[0].wording}}" [(ngModel)]="field[0].value" [ignoreChange]="true"></lp-text-field>

        <!--lp-checkbox-->
        <lp-boolean-switch-box *ngIf="field[0].type == 'CHKB'"  name="{{field[0].wording}}" switchName="{{field[0].wording}}" [(ngModel)]="field[0].value" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"></lp-boolean-switch-box>

        <!--lp-dropdown-field
        <lp-dropdown-field *ngIf="field[0].type == 'LISTE'" class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
          cssClass="form-control" name="{{field[0].wording}}" [values]="formatValues(field[0].listItem)" [required]=true [(ngModel)]="field[0].value"></lp-dropdown-field>value {{field[0].value}}-->
          <div class="col-md-7 col-lg-11 ">
            <select *ngIf="field[0].type == 'LISTE'" class="{{arrayClassesElements.get('defaultInput')}} textField form-control" name="{{field[0].name}}" [(ngModel)]="field[0].value" >
              <option *ngFor="let v of field[0].listItem" id="{{v.name}}" [value]="v.name">{{v.name}}</option>
            </select>
          </div>

        <!--lp-switch-box // TODO --> 
        <lp-switch-box *ngIf="field[0].type == 'switch'" class="{{arrayClassesElements.get('defaultInput')}}" inputBox="field[0].listItem"
          idField="field[0].name" className="" cssClass="" name="{{field[0].wording}}" [values]="field[0].listItem" labelField="{{field[0].wording}}"
          switchName="gender" [(ngModel)]="field[0].value" [ignoreChange]="true"></lp-switch-box>

        <!--lp-date-time //TODO-->
        <lp-date-time *ngIf="field[0].type == 'DATETIME'" [(ngModel)]="field[0].value" class="{{arrayClassesElements.get('defaultInput')}}"
          id="carteFin" cssClass="form-control" name="{{field[0].wording}}" [ignoreChange]="true"></lp-date-time>

        <!--lp-date-->
        <lp-date *ngIf="field[0].type == 'DATE'" id="{{field[0].name}}" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
          name="{{field[0].wording}}" [(ngModel)]="field[0].value" [ignoreChange]="true"></lp-date>

        <!--lp-time-->
        <lp-time *ngIf="field[0].type == 'TIME'" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="field[0].value" [ignoreChange]="true"></lp-time>

        <!--lp-number-->
        <lp-number *ngIf="field[0].type == 'NUMBER'" id="{{field[0].wording}}" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
          name="{{field[0].wording}}" [(ngModel)]="field[0].value" [ignoreChange]="true"></lp-number>

        <!--lp-currency //TODO-->
        <lp-currency *ngIf="field[0].type == 'currency'" id="{{field[0].wording}}" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
          name="{{field[0].wording}}" [(ngModel)]="field[0].value" [ignoreChange]="true"></lp-currency>

        <!--lp-radio-box-->
        <!--Pas encore d'exemple-->

        <!--lp-check-list-box //TODO
        <lp-check-list-box *ngIf="field[0].type == 'check-list'" class="{{arrayClassesElements.get('defaultInput')}}" (add)="addF05Z($event);"
          (remove)="removeF05Z($event)" inputBox="F05ZTYPE" bindingKey="field[0].value" id="{{field[0].wording}}" idField="{{field[0].id}}"
          className="checkList" cssClass="" name="{{field[0].wording}}" [binding]="field[0].value" [values]="field[0].listItem" labelField="field[0].wording"
          [singleCol]="false"></lp-check-list-box>-->

        <!--lp-fuel-gauge //TODO-->
        <lp-fuel-gauge *ngIf="field[0].type == 'gauge'" id="startFuel" class="{{arrayClassesElements.get('defaultInput')}}" name="{{field[0].wording}}"
          [(ngModel)]="field[0].value" [ignoreChange]="true"></lp-fuel-gauge>

      </div>
      <div *ngIf="field[1]" class="form-group col-xl-6" [hidden]="field[1].wording.toUpperCase() === 'VIDE'">
          <lp-label-field *ngIf="field[1]" for="inChargeOf" class="{{arrayClassesElements.get('defaultLabel')}}" text="{{field[1].wording}}"></lp-label-field>

          <!--lp-text-field-->
          <lp-text-field *ngIf="field[1].type === 'TEXT' || field[1].type === 'ZOOM'" id="inChargeOf" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
            cssClass="form-control" name="{{field[1].wording}}" [(ngModel)]="field[1].value" [ignoreChange]="true"></lp-text-field>

          <!--lp-checkbox-->
            <lp-boolean-switch-box *ngIf="field[1].type == 'CHKB'"  name="{{field[1].wording}}" switchName="{{field[1].wording}}" [(ngModel)]="field[1].value" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"></lp-boolean-switch-box>

          <!--lp-dropdown-field
          <lp-dropdown-field *ngIf="field.type == 'LISTE'" class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
            cssClass="form-control" name="{{field.wording}}" [values]="formatValues(field.listItem)" [required]=true [(ngModel)]="field.value"></lp-dropdown-field>-->
            
          <select *ngIf="field[1].type == 'LISTE'" class="{{arrayClassesElements.get('defaultInput')}} textField form-control" name="{{field[1].name}}" [(ngModel)]="field[1].value" >
            <option *ngFor="let v of field[1].listItem" id="{{v.name}}" [value]="v.name">{{v.name}}</option>
          </select>

          <!--lp-switch-box // TODO --> 
          <lp-switch-box *ngIf="field[1].type == 'switch'" class="{{arrayClassesElements.get('defaultInput')}}" inputBox="FT15TITSWITCH"
            idField="FT15KY" className="" cssClass="" name="{{field[1].wording}}" [values]="FT15TITSWITCH" labelField="{{field[1].wording}}"
            switchName="gender" [(ngModel)]="field[1].value" [ignoreChange]="true"></lp-switch-box>

          <!--lp-date-time //TODO-->
          <lp-date-time *ngIf="field[1].type == 'DATETIME'" [(ngModel)]="field[1].value" class="{{arrayClassesElements.get('defaultInput')}}"
            id="carteFin" cssClass="form-control" name="{{field[1].wording}}" [ignoreChange]="true"></lp-date-time>

          <!--lp-date-->
          <lp-date *ngIf="field[1].type == 'DATE'" id="{{field[1].name}}" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
            name="{{field[1].wording}}" [(ngModel)]="field[1].value" [ignoreChange]="true"></lp-date>

          <!--lp-time-->
          <lp-time *ngIf="field[1].type == 'TIME'" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="field[1].value" [ignoreChange]="true"></lp-time>

          <!--lp-number-->
          <lp-number *ngIf="field[1].type == 'NUMBER'" id="plafond" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
            name="{{field[1].wording}}" [(ngModel)]="field[1].value" minlength="3" [ignoreChange]="true"></lp-number>

          <!--lp-currency //TODO-->
          <lp-currency *ngIf="field[1].type == 'currency'" id="currency" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
            name="{{field[1].wording}}" [(ngModel)]="field[1].value" [ignoreChange]="true"></lp-currency>

          <!--lp-radio-box-->
          <!--Pas encore d'exemple-->

          <!--lp-check-list-box //TODO
          <lp-check-list-box *ngIf="field[1].type == 'check-list'" class="{{arrayClassesElements.get('defaultInput')}}" (add)="addF05Z($event);"
            (remove)="removeF05Z($event)" inputBox="F05ZTYPE" bindingKey="K05ZT05TYP" id="typeClient" idField="FT05KY"
            className="checkList" cssClass="" name="{{field[1].wording}}" [binding]="data" [values]="VT05TYP" labelField="F901MSG"
            [singleCol]="false"></lp-check-list-box>-->

          <!--lp-fuel-gauge //TODO-->
          <lp-fuel-gauge *ngIf="field[1].type == 'gauge'" id="startFuel" class="{{arrayClassesElements.get('defaultInput')}}" name="{{field[1].wording}}"
            [(ngModel)]="field[1].value" [ignoreChange]="true"></lp-fuel-gauge>

        </div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="hasParamsToDisplay">
    <lp-mail-report [isShortcut]="false" [reportId]="reportService.itemSelectedInList" [arrayOfParameters]="arrayOfParameters" class="mail-footer"></lp-mail-report>
    <button name="btn-save" title="{{'report.validate' | translate}}" class="sidebar-item btn-save no-float" (click)="actionAfterParameters(arrayOfParameters)">
        <i class="icon vega-print"></i>
      </button>
  </div>
</div>
