import { Component, Inject, ViewChild} from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import * as _ from 'underscore'
import { LpModalComponent } from '../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';
import { FtProvider } from 'app/providers/ft.provider';
import { Injectable } from '@angular/core';
import { GeneralParameters } from 'app/models/general-parameters';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Util } from 'app/statics/utils';

const APPLICATION_ITEM: string = 'applicationItem';
const CUSTOME_CODE: string = 'CUSTOME_CODE';
const CUSTOME_FUNCTION: string = 'CUSTOME_FUNCTION';
const GENERAL_PARAMETERS: string = 'GENERAL_PARAMETERS';
const POST_GENERAL_PARAMETERS: string = 'POST';
const PUT_GENERAL_PARAMETERS: string = 'PUT';
const GET_GENERAL_PARAMETERS: string = 'GET';


@Component({
  selector: 'lp-modal-customization',
  templateUrl: './lp-modal-customization.component.html'
})
@Injectable()
export class LpModalCustomizationComponent extends LpModalComponent{
  @ViewChild(MatTabGroup, {static: true}) tabGroup: MatTabGroup;
  public currentGeneralParameter: GeneralParameters = new GeneralParameters();
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public repositoryVerb: string;
  public repositoryId: string;
  public generalparamVerb: string;
  public generalparamId: string;
  public company : string;
  public ecranIdArray: Array<any>;
  public ecranNameArray: Array<any>;
  public showCustomeCodeSaveButton: boolean;
  public showApplicationItemSaveButton: boolean;
  public ecrId: any = null;
  public jsonRepository: any;
  public jsonApplicationItem: any;
  public jsonCode: any;
  public jsonFunction: any;
  public applicationItemVerb: any = null;
  public loaderRepository: Boolean = false;
  public loaderGeneralParam: Boolean = false;
  public loaderApplicationItem: Boolean = false;
  public loaderCustomCode: Boolean = false
  public function: any = null;
  public functions: Array<any> = [];
  public dataGeneralParameters: GeneralParameters | Array<GeneralParameters> = [];
  public action: string = '';
  public showGeneralParamsForm:Boolean=false;
  public itemSearched: string;
  public configTab: String ='';

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: ModalService,
    private repositoryProvider: RepositoryProvider,
    private translate: TranslateService,
    private applicationItemProvider: ApplicationItemProvider,
    private ftProvider : FtProvider,
    private configService: ConfigService,
    private userHstoryService: UserHistoryService
  ){
    super(dialogRef, data);
  }

  public ngOnInit(): void {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
    this.ecranIdArray = [];
    this.refreshScreenLists();
  }
  
  public async getGeneralParamData(): Promise<any> {
    try {
      this.action = GET_GENERAL_PARAMETERS;
      this.loaderGeneralParam = true;
      if ( !Util.isNullOrUndefined(this.generalparamVerb) || 
           !Util.isNullOrUndefined(this.generalparamId) ||
           (!Util.isNullOrUndefined(this.data.company) &&
           !Util.isNullOrUndefined(this.data.company.id))
      ) {
        let queryParams: Map<string,string> = new Map<string, string>();
        if (!Util.isNullOrUndefined(this.generalparamVerb)) { queryParams.set('table', this.generalparamVerb) }
        if (!Util.isNullOrUndefined(this.generalparamId)) { queryParams.set('argument', this.generalparamId) }
        if (!Util.isNullOrUndefined(this.data.company) && !Util.isNullOrUndefined(this.data.company.id)) { queryParams.set('company', this.data.company.id) }
        this.dataGeneralParameters = await this.ftProvider.getGeneralParameters(queryParams);
      } else {
        this.dataGeneralParameters = await this.ftProvider.getListGeneralParameters();
      }

      if (this.dataGeneralParameters !== undefined){
        this.itemSearched = GENERAL_PARAMETERS;
      }
      this.loaderGeneralParam = false;
      this.showGeneralParamsForm = true;
    } catch (error) {
      this.loaderGeneralParam = false;
      throw error;
    }
    
  }

  public async getRepositoryData(): Promise<any> {
    this.loaderRepository = true;
    this.itemSearched = null;
    this.jsonRepository = await this.repositoryProvider.get(this.repositoryVerb, this.repositoryId, null, true);
    this.loaderRepository = false;
  }

  public getApplicationItemData(applicationItemVerb: string): void {
    this.loaderApplicationItem = true;
    this.itemSearched = APPLICATION_ITEM;
    this.repositoryProvider.getApplicationItemFromJsonEditor(applicationItemVerb).then((d) => {
      this.jsonApplicationItem = d;
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = true;
      this.loaderApplicationItem = false;
    });
  }

  public async saveApplicationItemCode(verb: string, data: any): Promise<any> {
    this.repositoryProvider.clearCache(verb);
    this.repositoryProvider.saveApplicationItem(verb, data).then((result) => {
      this.jsonApplicationItem = '';
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = false;
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
        this.translate.instant('general.modalService.success'));
        
      let c: any = _.find(this.ecranNameArray, function (item: any): boolean {
        return item.id.toString() === verb;
      });
      this.userHstoryService.addEvent('customization', null, null, null, this.translate.instant('userHistory.modify') + " "+ this.translate.instant('preferences.applicationItem-tab') + " : " + c.wording);
    });
  }

  public getCustomCode(ecrId: string): void {
    this.loaderCustomCode = true;
    this.itemSearched = CUSTOME_CODE;
    this.repositoryProvider.getCustomCodeScriptsByEcran(ecrId).then((d) => {
      if (d.length === 0) {
        this.jsonCode = [{ 'ecran': ecrId, 'sub': 'main', 'event': '', 'field': '', 'scripts': '', 'order': '', 'desciption': '' }];
      } else {
        this.jsonCode = d;
      }
      this.showCustomeCodeSaveButton = true;
      this.showApplicationItemSaveButton = false;
      this.loaderCustomCode = false;
    });
  }
  
  public itemChange(){
    this.itemSearched = GENERAL_PARAMETERS;
  }

  public saveCustomCode(ecrId: string, data: any): void {
    this.repositoryProvider.saveCustomCodeByEcran(ecrId, data).then((result) => {
      this.jsonCode = '';
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = false;
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
      let c: any = _.find(this.ecranIdArray, function (item: any): boolean {
        return item.id.toString() === ecrId;
      });
      this.userHstoryService.addEvent('customization', null, null, null, this.translate.instant('userHistory.modify') + " "+ this.translate.instant('preferences.code-tab') + " : " + c.wording);
    });
  }

  public async saveCustomFonction(functionName: string, data: any): Promise<void> {
    try {
      await this.repositoryProvider.saveCustomCodefunction(functionName, data)
      this.jsonFunction = '';
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = false;
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
        this.translate.instant('general.modalService.success'));
      this.userHstoryService.addEvent('customization', null, null, null, this.translate.instant('userHistory.modify') + " "+ this.translate.instant('preferences.function-tab') + " : " + data.name + "()");    
      await this.refreshScreenLists();
    } catch (e){
      this.modalService.error(this.translate.instant(e.message), this.translate.instant('general.modalService.error')); 
    }
  }

 public async saveGeneral_Parameters(data: GeneralParameters): Promise<void> {
    try {
      this.action = PUT_GENERAL_PARAMETERS;
      await this.ftProvider.saveGeneral_Parameters(data.id.toString(), data);
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = false;
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
      this.translate.instant('general.modalService.success'));
      this.userHstoryService.addEvent('customization', null, null, null, this.translate.instant('userHistory.modify') + " "+ this.translate.instant('preferences.function-tab') + " : " + data.name + "()");  
      await this.getGeneralParamData();
    } catch (e) {
      this.modalService.error(this.translate.instant(e.message), this.translate.instant('general.modalService.error')); 
    }
  }

  public postFunction(): void {
    let data: any = {};
    data = this.jsonFunction;
    data.name = this.jsonFunction.name + "_copy";
    this.repositoryProvider.createCustomCodefunction(data).then(async (f) => {
      this.jsonFunction = f;
      this.function = f;
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = false;
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
        this.translate.instant('general.modalService.success'));
      await this.refreshScreenLists();
    });
  }

  public async postGeneralParameters(data : GeneralParameters): Promise<void> {
    try {
      this.loaderGeneralParam = true;
      this.itemSearched = GENERAL_PARAMETERS;
      await this.ftProvider.createGeneralParameters(data);
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = false;
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
        this.translate.instant('general.modalService.success'));
      await this.getGeneralParamData();
      this.loaderGeneralParam = false;
    } catch (error) {
      throw error;
    }
    
  }

  public  createGeneralParameters(){
    this.itemSearched = GENERAL_PARAMETERS;
    this.action = POST_GENERAL_PARAMETERS;
    this.showGeneralParamsForm = true;
  }

  public async deleteCustomFonction(id: string): Promise<void> {
    await this.repositoryProvider.deleteById('custom-code-functions', id);
  }
  
  public async deleteGeneral_parameters(data: GeneralParameters): Promise<void> {
    try {
      await this.ftProvider.deleteGeneral_Parameters(data.id.toString(), data);
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = false;
      this.modalService.success(this.translate.instant('general.modalService.deletionCompleted'),
      this.translate.instant('general.modalService.success'));
      this.dataGeneralParameters = new GeneralParameters();
      this.itemSearched = null;
      this.userHstoryService.addEvent('customization', null, null, null, this.translate.instant('userHistory.modify') + " "+ this.translate.instant('preferences.function-tab') + " : " + data.name + "()");    
    } catch (e){
      this.modalService.error(this.translate.instant(e.message), this.translate.instant('general.modalService.error')); 
       this.refreshScreenLists();
    }
  }

  public saveJson(data: any): void {
    if (this.itemSearched === APPLICATION_ITEM) {
      this.saveApplicationItemCode(this.applicationItemVerb.id, data);
    } else if (this.itemSearched === CUSTOME_CODE) {
      this.saveCustomCode(this.ecrId.id, data);
    } else if (this.itemSearched === CUSTOME_FUNCTION) {
      this.saveCustomFonction(this.function.id, data);
    } else if (this.itemSearched === GENERAL_PARAMETERS) {      
      if(this.currentGeneralParameter.id !== undefined){
        this.saveGeneral_Parameters(this.currentGeneralParameter);
      } else {
        this.postGeneralParameters(this.currentGeneralParameter);
      }
    }
    this.itemSearched = null;
  }

  public async deleteFunction(): Promise<void> {
    if (this.itemSearched === CUSTOME_FUNCTION) {
      const id: string = this.function.id;  
      let f: any = _.find(this.functions, function (item: any): boolean {
        return item.id.toString() === id;
      });
      this.userHstoryService.addEvent('customization', null, null, null, this.translate.instant('userHistory.delete') + " "+ this.translate.instant('preferences.function-tab') + " : " + f.name + "()");
      await this.deleteCustomFonction(this.function.id);
      this.function = null;
    }
    this.jsonFunction = '';
    this.showCustomeCodeSaveButton = false;
    this.showApplicationItemSaveButton = false;
    this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
      this.translate.instant('general.modalService.success'));
    this.refreshScreenLists();
  }

  public getFunction(functionName: string): void {
    this.loaderApplicationItem = true;
    this.itemSearched = CUSTOME_FUNCTION;
    this.repositoryProvider.getCustomCodeFunction(functionName).then((d) => {
      this.jsonFunction = d;
      this.showCustomeCodeSaveButton = false;
      this.showApplicationItemSaveButton = true;
      this.loaderApplicationItem = false;
    });
  }

  public onTabChanged($event: MatTabChangeEvent): void{
    this.itemSearched = null;
  }

  public assignGeneralParameter(data: GeneralParameters): void {
    this.currentGeneralParameter = data;
  }

  public async changeConfigTac(tab) {
    this.configTab = tab;    
  }

  private refreshScreenLists(): void {
    this.applicationItemProvider.getApplicationItemsScreens().then(d => {
      this.ecranIdArray = this.translateAndSort(d);
    });

    this.applicationItemProvider.getApplicationItemsVerbs().then((d: Array<any>) => {
      this.ecranNameArray = this.translateAndSort(d);
    });

    this.repositoryProvider.getCustomCodeFunctions().then((d: Array<any>) => {
      this.functions = this.translateAndSortFunctions(d);
    });
  }

  private translateAndSort(d: any[]): any[] {
    let array: any[] = [];
    let mapTemp: Map<string, any> = new Map();
    for (let index: number = 0; index < d.length; index++) {
      mapTemp.set(this.translate.instant(d[index].wording), d[index]);
    }
    let keys: Array<String> = Array.from(mapTemp.keys()).sort();
    for (let index: number = 0; index < keys.length; index++) {
      array.push(mapTemp.get(keys[index].toString()));
    }
    return array;
  }

  private translateAndSortFunctions(d: any[]): any[] {
    let array: any[] = [];
    let mapTemp: Map<string, any> = new Map();
    for (let index: number = 0; index < d.length; index++) {
      d[index].name = this.translate.instant(d[index].name);
      mapTemp.set(d[index].name, d[index]);
    }
    let keys: Array<String> = Array.from(mapTemp.keys()).sort();
    for (let index: number = 0; index < keys.length; index++) {
      array.push(mapTemp.get(keys[index].toString()));
    }
    return array;
  }

}
