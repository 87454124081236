import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LpModalComponent } from '../lp-modal.component';

@Component({
  selector: 'lp-modal-eval',
  templateUrl: './lp-modal-eval.component.html'
})
export class LpModalEvalComponent extends LpModalComponent {

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef, data);
  }

}
