import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent,LOCATION_SITUATION } from '../detail-forms.component';
import { LpLocationsSituation } from 'app/meta/locations-situation';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-locations-situation',
  templateUrl: './locations-situation.component.html'
})
export class LocationsSituationsComponent extends DetailFormsComponent implements OnInit {

  public data: LpLocationsSituation = null;

  public ngOnInit(): void {
    super.initData();
    this.setId(LOCATION_SITUATION);
    if (Util.isNullOrUndefined(this.data)) {
      this.data = new LpLocationsSituation();
    }
    if (Util.isNullOrUndefined(this.formStackService.currentDetailLineData)) {
      this.formStackService.currentDetailLineData = new LpLocationsSituation();
    }
    this.data.assign(this.formStackService.currentDetailLineData);
    this.initUrl();
}

  public init(): void {
    this.loadObjectRootFormSubscription = this.uiSyncService.loadObjectEvtEmitter.subscribe(() => {
      this.loadObjectRootFormSubscription.unsubscribe();
    });
  }
}
