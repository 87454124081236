
<div class="col col-md-3 ">
  <div class="col col-md-10 offset-md-1">
    <div class="logos"></div>
    <div class="card card-box login-card pb-0">

      <div class="login-bloc" *ngIf="!isForgotPwd">
        <div class="text-center">
          <h4 id="h4-titleLoginPage" class="text-uppercase font-bold mb-0">{{'login.connexion' | translate}}</h4>
        </div>
        <div class="ps-3 pe-3 pb-3 pt-1">
          <form class="form-horizontal m-t-20" (ngSubmit)="onSubmit()">

            <label class="col-xs-12 d-none" for="username">{{'login.username' | translate}}</label>
            <div class="form-group col-xs-12">
                <input id="username" name="username" type="text" class="textField form-control" #usernameInput
                  [(ngModel)]="username"  minlength="3" placeholder="{{'login.username' | translate}}"  [required]="required"/>
            </div>

            <label class="col-xs-12 d-none" for="password">{{'login.password'| translate}}</label>
            <div class="form-group input-group col-xs-12">
                <input id="password" name="password" class="textField form-control" type="{{inputType}}" autocomplete="off"
                  [(ngModel)]="password"  minlength="3" placeholder="{{'login.password'| translate}}"  [required]="required"/>

                  <span class="input-group-addon">
                    <button class="btn btn-transparent" type="button" (click)="changePasswordMode()" title="Afficher / Masquer le mot de passe." tabindex="-1">
                      <i class="fa {{iconPassword}}"></i>
                    </button>
                  </span>
            </div>

            <div class="text-center mt-5">
              <div class="col-xs-12">
                <button id="btn-login" class="btn btn-custom btn-bordred btn-block waves-effect waves-light btn-primary"
                  type="submit">{{'login.login' | translate}}</button>
              </div>
            </div>

            <div class="bottom-links">
              <div *ngIf="multilanguage" class="translateFlags pull-right">
                <img id="flag-setToFR" class="flag me-1 ps-10" src="/assets/img/flags/France.png" (click)="setLanguage('fr')">
                <img id="flag-setToEN" class="flag" src="/assets/img/flags/United-Kingdom.png" (click)="setLanguage('en')">
              </div>
              <a *ngIf="nativePasswords" class="d-block mt-3 pt-1 text-muted cursor-pointer" (click)="isForgotPwd = !isForgotPwd">
                <i class="fa fa-lock m-r-5"></i> {{'login.forgotPwd' | translate}}
              </a>
            </div>
          </form>
        </div>
      </div>
      <div class="forgotPwd-bloc" *ngIf="isForgotPwd">
        <div class="text-center">
          <h4 id="h4-titleLoginPage" class="text-uppercase font-bold mb-0">{{'login.forgotPwdTitle' | translate}}</h4>
        </div>
        <div class="p-20">
          <form class="form-horizontal m-t-20" (ngSubmit)="onSubmitForgotPwd()">

            <label class="col-xs-12 d-none" for="username">{{'login.username' | translate}}</label>
            <div class="form-group">
              <div class="col-xs-12">
                <input id="username" name="username" type="text" class="textField form-control"
                  [(ngModel)]="usernameForgotPwd.username"  minlength="3" placeholder="{{'login.username' | translate}}"  [required]="required"/>
              </div>
            </div>

            <div class="form-group text-center m-t-30">
              <div class="col-xs-12">
                <button id="btn-login" class="btn btn-custom btn-bordred btn-block waves-effect waves-light btn-primary"
                [disabled]="(!usernameForgotPwd.username || usernameForgotPwd.username === '') ? true : false" type="submit">{{'login.btnForgotPwd' | translate}}</button>
              </div>
            </div>
            
            <div class="bottom-links">
              <div class="text-center">
                <img id="flag-setToFR" class="flag me-1 ps-10" src="/assets/img/flags/France.png"
                  (click)="setLanguage('fr')">
                <img id="flag-setToEN" class="flag" src="/assets/img/flags/United-Kingdom.png"
                  (click)="setLanguage('en')">
              </div>
              <a *ngIf="nativePasswords" class="d-block mt-3 pt-1 text-muted cursor-pointer" (click)="isForgotPwd = !isForgotPwd">
                {{'login.loginForm' | translate}}
              </a>
            </div>
          </form>

        </div>

      </div>

    </div>
    <!-- end card-box-->
    <div class="col-sm-12 text-center irium-link">
      <p class="mt-2 mb-0">
        <a href="https://www.irium-software.fr" class="text-primary" target="_blank">IRIUM SOFTWARE</a>
      </p>
    </div>
  </div>
</div>