



<!-- passer en input tout les champs renvoyer par l'appel /parameters -->

<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="locations.locations"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
  <div class="container p-0">

    <div class="container-2-cols left" #containerCols>
      <div class="card-box col-md-6 label-2-colonnes" #col1>
        <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
          <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"
            [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
            (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
            <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
            <lp-ui-accordion class="row" [menu]=true>

            <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
              <div class="container">
                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="id" className="textField" label="locations.id"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id"
                      [(ngModel)]="data.id" type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-text-field id="wording" className="textField" label="locations.wording"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="wording"
                      [(ngModel)]="data.wording" type="text"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="inChargeOf" className="textField" label="locations.manager"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="inChargeOf"
                      [(ngModel)]="data.inChargeOf" type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-zoom-field for="company" id="company" class="{{arrayClassesElements.get('defaultInput')}}"
                      className="zoomField" cssClass="form-control" [(ngModel)]="data.company" label="locations.company"
                      name="company" zoomId="company">
                    </lp-zoom-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" id="isExternal" label="locations.isExternal"
                      name="isExternal" switchName="isExternal" [(ngModel)]="data.isExternal">
                    </lp-boolean-switch-box>
                  </div>
                </div>

                <!--<lp-subgroup [bordertop]="false">-->
                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="address1" className="textField" label="locations.mainAddress.address"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="address1"
                      [(ngModel)]="data.mainAddress.address1"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-text-field id="address2" className="textField" label="&nbsp;"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="address2"
                      [(ngModel)]="data.mainAddress.address2"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="address3" className="textField"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="address3"
                      [(ngModel)]="data.mainAddress.address3"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-text-field id="address4" className="textField"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="address4"
                      [(ngModel)]="data.mainAddress.address4"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-zoom-field id="mainAddress.zipCode" class="{{arrayClassesElements.get('defaultInput')}}" name="mainAddress.zipCode" label="locations.mainAddress.zipCode"
                    className="zipCode" cssClass="form-control" [(ngModel)]="data.mainAddress.zipCode" zoomId="zipCode">
                  </lp-zoom-field>
                  </div>
                  <div class="form-group col-6">
                    <lp-text-field id="city" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="city" label="locations.mainAddress.city"
                      [(ngModel)]="data.mainAddress.city">
                    </lp-text-field>
                  </div>
                </div>
                <!--</lp-subgroup>-->

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="word" className="textField" label="locations.word"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="word"
                      [(ngModel)]="data.word" type="text">
                    </lp-text-field>
                  </div>
                </div>
              </div>
            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>
          </lp-ui-accordion>
        </div>
          <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
        </form>
      </div>
      <div class="col-md-6 card-box" #col2>
        <lp-right-col></lp-right-col>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>