<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<span  [class.readonly]="this.formStackService.getFields(name).readonly" [class.disabled]="disabled" [hidden]="this.formStackService.getFields(name).hidden">
  <div class="input-group">
    <input [owlDateTime]="dt1" class="textField form-control" placeholder="{{placeholder | translate}}" [(ngModel)]="displayValue" (keydown)="keyDown($event, id)" [ngStyle]="style"
    (focus)="focus($event)" (focusout)="pushChange($event, 'focusout', id)" [disabled]="this.formStackService.getFields(name).readonly" [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [disabled]="this.formStackService.getFields(name).readonly" title=""/>
    <i class="input-notif"></i>
    <span class="input-group-btn">
      <button type="button" tabindex="-1" class="btn waves-effect waves-light btn-primary" [owlDateTimeTrigger]="dt1">
        <i class="icon vega-openingHours"></i>
      </button>
    </span>
  </div>
  <owl-date-time #dt1 [pickerMode]="'dialog'" backdropClass="calendarBackdropClass" [firstDayOfWeek]=1 (afterPickerOpen)="focusPicker($event)" (afterPickerClosed)="pushChange($event, 'focusout', id)"></owl-date-time>
</span>
<div *ngIf="validDate==false" class="alert alert-danger" required>
  <input style="width:0px;display:inline-block;border:none;background-color:#f2dede;color:#a94442;" required> Date non valide.
</div>
  