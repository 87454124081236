import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { UserService } from './user.service';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { ModalService } from './modal.service';
import { HttpError } from 'app/models/http-error';
import { TranslateService } from '@ngx-translate/core';
import { Util } from 'app/statics/utils';
import { LoggerService } from './logger.service';
import { RouterService } from './router.service';
import { LOGIN, REFRESH_TOKEN, LOGOUT, RepositoryProvider } from '../providers/repository.provider'
import { UiSyncService } from './ui-sync.service';
import { LpModalPromiseErrorComponent } from 'app/ui/lp-modal/lp-modal-error/lp-modal-error.component';
import { /*AlocproProvider,*/ BEARER_AUTH } from 'app/providers/alocpro.provider';
import { LpModalPromiseWarningComponent } from 'app/ui/lp-modal/lp-modal-warning/lp-modal-warning.component';
import { LpModalPromiseErrorRefreshTokenComponent } from 'app/ui/lp-modal/lp-modal-error-refresh-token/lp-modal-error-refresh-token.component';
import { ConfigService } from './config.service';

const loginRoute: string = `/${LOGIN}`;
const refreshTokenRoute: string = `/${REFRESH_TOKEN}`;
const isVerboseToaster: boolean = true;

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

  private refreshTokenInProgress: boolean = false;

  private tokenRefreshedSource: Subject<any> = new Subject();
  private tokenRefreshed$: Observable<any> = this.tokenRefreshedSource.asObservable();

  constructor(
    private routerService: RouterService, 
    private userService: UserService, 
    private modalService: ModalService,
    private translate: TranslateService, 
    private log: LoggerService, 
    private uiSyncService: UiSyncService,
    private repositoryProvider: RepositoryProvider,
    private configService: ConfigService
    //private alocproProvider: AlocproProvider,
    ) { }

  // "tap" permet de renvoyer le body de la réponse également en cas d'erreur.
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(resp => {
        if (this.routerService.getUrl() !== loginRoute && resp instanceof HttpResponse && resp.status === 401) {
          this.userService.urlReferer = this.routerService.getUrl();
          // TODO add delete token car non valide dans la map(cache) de lpkRepo lpBackendService
          this.logout();
          return Observable.throwError(resp);
        }
      }), catchError((err) => {
        if (err.url.includes('news') || err.url.includes('multi-short-term-contracts')) {
          return;
        }
        return this.handleResponseError(err, req, next);
      })
    )
  };

  private logout(): void {
    if (this.userService.hasRefreshToken()) {
      this.modalService.modalPromise(LpModalPromiseErrorRefreshTokenComponent, {
        width : '350px',
        backdropClass: 'alertBackdropClass'
      });
    } else {
      // this.alocproProvider.logout();
    }
    JSON.stringify(this.userService.getCurrentUser());
    this.refreshTokenInProgress = false;
    //this.repositoryProvider.logout();
    this.userService.userLogout();
    this.routerService.navigate(loginRoute);
  }

  private handleResponseError(err: HttpErrorResponse, req?: HttpRequest<any>, next?: HttpHandler): Observable<HttpEvent<any>> {
    if (err instanceof HttpErrorResponse) {
      this.log.error('http-error', err);
      let error: HttpError = new HttpError(err, this.configService);
      if (!Util.isNullOrUndefined(err.error.message) && err.error.message !== "") {
        error.message = err.error.message;
      }
      if (this.routerService.getUrl() === loginRoute && (err.status === 403 || err.status === 401)) {
        error.translateKey = 'httpError.badRequest.INVALID_IDENTIFICATION';
      }
      if (this.routerService.getUrl() !== loginRoute && (err.status === 401 ||( req && req.url.indexOf(refreshTokenRoute) !== -1))) {
        if (req.url.indexOf(refreshTokenRoute) === -1 && this.userService.hasRefreshToken()) {
          return this.refreshToken().pipe(
            switchMap(() => {
                req = req.clone({
                  setHeaders: {
                    'X-VEGA-token': `${this.userService.getCurrentUser().accessToken}`,
                    Authorization: `${BEARER_AUTH} ${this.userService.getCurrentUser().accessToken}`
                  }
                })
                return next.handle(req);
            }),
            catchError(e => {
                if (e.status !== 401) {
                    return this.handleResponseError(e);
                } else {                  
                    this.logout();
                }
            }));
        } else {
          this.logout();
        }
      }
      if (isVerboseToaster && !err.url.endsWith('metadata') && err.url.indexOf('version.json') === -1 
          // && req.url.indexOf(refreshTokenRoute) === -1 
          && req && req.url.indexOf(LOGOUT) === -1) {
        if (Util.isNullOrUndefined(req.headers.get('noerroralert')) || req.headers.get('noerroralert') !== 'true') {
          if (err.status === 401  && !this.userService.hasRefreshToken() && this.routerService.getUrl() !== loginRoute) {
            let content = this.translate.instant("httpError.unauthorized");
            let title = this.translate.instant("httpError.unauthorizedTitle");
            this.modalService.modalPromise(LpModalPromiseErrorComponent, {
              backdropClass: 'alertBackdropClass',
              id : 'modalWithHeader'
            }, error);
            this.modalService.error(content, title);
          } else {
            if (err.status === 401 && this.routerService.getUrl() !== loginRoute) {
              this.modalService.error(this.translate.instant(error.translateKey.toString()), error.getErrorTitle());
            } else if (err.status === 400) {
              // tslint:disable-next-line: no-floating-promises
              this.modalService.modalPromise(LpModalPromiseWarningComponent, {
                backdropClass: 'alertBackdropClass',
                id : 'modalWithHeader'
              }, error);
            } else { 
              if ( err.status === 401 && this.routerService.getUrl() !== `/${LOGIN}`) {
                // tslint:disable-next-line: no-floating-promises                
                this.modalService.modalPromise(LpModalPromiseErrorComponent, {
                  backdropClass: 'alertBackdropClass',
                  id : 'modalWithHeader'
                }, error);
              } else if (err.status === 401 &&
                this.routerService.getUrl() === `/${LOGIN}` && 
                this.modalService.arrayHttpError.length === 0 ) {
                  // tslint:disable-next-line: no-floating-promises
                  this.modalService.modalPromise(LpModalPromiseErrorComponent, {
                    backdropClass: 'alertBackdropClass',
                    id : 'modalWithHeader'
                  }, error);
              } else if (err.status !== 401) {
                // tslint:disable-next-line: no-floating-promises
                  this.modalService.modalPromise(LpModalPromiseErrorComponent, {
                    backdropClass: 'alertBackdropClass',
                    id : 'modalWithHeader'
                  }, error);
              }
            }
          }
        }
      }
    }

    if (err.url.includes('news') || err.url.includes('multi-short-term-contracts')) {
      return;
    }

    if (!err.url.endsWith('metadata')) {
      this.uiSyncService.loader(false);
    }
    return throwError(err);
  }

  private refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
        return new Observable(observer => {
            this.tokenRefreshed$.subscribe(() => {
                observer.next();
                observer.complete();
            });
        });
    } else {
        this.refreshTokenInProgress = true;

        return this.repositoryProvider.refreshToken().pipe(
            tap(() => {
                this.refreshTokenInProgress = false;
                this.tokenRefreshedSource.next(null);
            }))
           ;
    }
}

}
