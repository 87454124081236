import { Injectable } from '@angular/core';
import * as forge from 'node-forge';


@Injectable()
export class CryptoService {

  public getSHA256(value: string): string {
    const md = forge.md.sha256.create();
    md.update(value);
    return md.digest().toHex();
  }
}
