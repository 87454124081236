import { Injectable } from '@angular/core';
import { RepositoryProvider } from '../providers/repository.provider'
import { LpVisualSettings } from 'app/meta/visual-settings';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

const VISUAL_SETTINGS: string = 'visual-settings';

@Injectable({
    providedIn: 'root'
})

export class VisualSettingsProvider {

    constructor(
        private repositoryProvider: RepositoryProvider,
        private translate: TranslateService,
        private modalService: ModalService
        ) {};
    

        public async getVisualSettingsByAgencyId(agencyId: string): Promise<LpVisualSettings> {
            return await this.repositoryProvider.get(`${VISUAL_SETTINGS}/${agencyId}`);
        }

        public async putVisualSettingsByAgencyId(visualSettings: LpVisualSettings, agencyId: string): Promise<void> {
            return await this.repositoryProvider.put(`${VISUAL_SETTINGS}/${agencyId}`, null, visualSettings).then(() => {
                this.modalService.success(this.translate.instant('general.modalService.successVisualSettings'),
                  this.translate.instant('general.modalService.success'));
              }).catch((error) => {
                this.modalService.error(this.translate.instant('general.modalService.errorVisualSettings'),
                  this.translate.instant('general.modalService.erreur'));
              });;
        }

        public async postVisualSettingsByAgencyId(visualSettings: LpVisualSettings, agencyId: string): Promise<LpVisualSettings> {
            return await this.repositoryProvider.post(`${VISUAL_SETTINGS}`, visualSettings);
        }

        public async deleteVisualSettingsByAgencyId(visualSettings: LpVisualSettings, agencyId: string): Promise<LpVisualSettings> {
            return await this.repositoryProvider.delete(`${VISUAL_SETTINGS}/${agencyId}`, visualSettings);
        }

}
