import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, EQUIPMENT_RELATIONS } from '../detail-forms.component';
import { LpEquipmentsRelation } from 'app/meta/equipments-relation';
import { LpEquipment } from 'app/meta/equipment';
import { RELATION_FATHER, RELATION_SON } from 'app/meta/meta';

@Component({
  selector: 'lp-equipments-relations',
  templateUrl: './equipments-relations.component.html'
})

export class EquipmentsRelationsComponent extends DetailFormsComponent implements OnInit {

  public data: LpEquipmentsRelation = null;


  public ngOnInit(): void {
    super.ngOnInit();
    this.setId(EQUIPMENT_RELATIONS);
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.initUrl();
    this.initSonFather();
  }

  public changeRelation(): void {
    if (this.data._relationSwitch === RELATION_SON) {
      this.data.father.renew();
      this.data.son = new LpEquipment();
      this.data.son.assign(this.formStackService.currentData)
    } else {
      this.data.son.renew();
      this.data.father = new LpEquipment();
      this.data.father.assign(this.formStackService.currentData)
    }
    this.uiSyncService.loadObject();
  }

  public initSonFather(): void {
    if (this.data._relationSwitch === RELATION_SON) {
      this.data.son = new LpEquipment();
      this.data.son.assign(this.formStackService.currentData)
    } else {
      this.data.father = new LpEquipment();
      this.data.father.assign(this.formStackService.currentData)
    }
  }

  public isSon(): Boolean {
    return this.data._relationSwitch === RELATION_SON;
  }

  public isFather(): Boolean {
    return this.data._relationSwitch === RELATION_FATHER;
  }
}
