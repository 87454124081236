import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, OnInit, OnDestroy, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { FileFieldComponent } from '../file-field.component';
import { Util } from 'app/statics/utils';
import { CsvConfigConsts } from 'app/models/json-csv';

@Component({
  selector: 'lp-drop-file',
  templateUrl: './drop-file.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropFileComponent),
      multi: true
    }
  ]
})
export class DropFileComponent extends FileFieldComponent implements OnInit, OnDestroy {


  @Input() public isBinding: Boolean = true; 

  @Input() public importCSV: Boolean = false;

  @Output() public uploaded: EventEmitter<any> = new EventEmitter();

  public fileInpuText: string;


  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.uiSyncService.dragOver = false;
    let files: any = evt.dataTransfer.files;
    if (files.length > 0) {
      this.pushChange(evt, files);
    }
  }

  public async ngOnInit(): Promise<void> {
    try {
      await super.ngOnInit();

      if (this.label === null || this.label === "") {
        this.label = 'general.button.dragFile';
      }
    } catch (error) {
      throw error;
    }
  }

  public async ngOnDestroy(): Promise<void> {
    try {
      await super.ngOnDestroy();
    } catch (error) {
      throw error;
    }
  }

  public async pushChange(event?: any, files?: any): Promise<void> {
    this.fileInpuText = '';
    try {
      super.pushChange();
      if (!Util.isNullOrUndefined(this.isBinding) && this.isBinding) {
        this.setDisplayValue(null);
      }
      for (let index: number = 0; index < files.length; index++) {
        const element: any = files[index];
        if (element.size <= this.configService.get('maxUploadFile')) {
          const myReader: FileReader = new FileReader();
          if (this.importCSV) {
            myReader.onloadend = async (e) => {
              if (!Util.isNullOrUndefined(this.isBinding) && this.isBinding) {
                this.setDisplayValue(myReader.result);
              }
              this.uploaded.emit(this.csvToJSON(myReader.result));
            }
            myReader.readAsText(element);
          } else {
            myReader.onloadend = async (e) => {
              this.currentTypeMime = await this.base64MimeType(myReader.result);
              if (this.configService.get('iconTypeMime')[this.currentTypeMime.toString()]) {
                if (!Util.isNullOrUndefined(this.isBinding) && this.isBinding) {
                  this.setDisplayValue(myReader.result);
                }
                await this.setIconTypeMime(this.currentTypeMime);
                this.isImageType();
                this.uploaded.emit(myReader.result);
              } else {
                this.errorUnknowFileType();
              }
            }
            myReader.readAsDataURL(element);
          }
        } else {
          if (!Util.isNullOrUndefined(this.isBinding) && this.isBinding) {
            this.setDisplayValue(null);
          }
          this.errorTooLarge();
        }
        if (!this.ignoreChange) {
          this.setHasChanged(true, this);
        }
      }
    } catch (error) {
      throw error;
    }
  }

  public deleteAttachment(): void {
    this.setDisplayValue(null);
    this.isImage = false;
    if (!this.ignoreChange) {
      this.setHasChanged(true, this);
    }
  }

  private csvToJSON(csv: string | ArrayBuffer): any {
    let lines: string[] = csv.toString().split(CsvConfigConsts.EOL);
    let result: string[] = [];
    let headers: string[] = lines[0].split(CsvConfigConsts.DEFAULT_FIELD_SEPARATOR);
    for (let i: number = 1; i < lines.length - 1; i++) {
        let obj: any = {};
        let currentline: string[] = lines[i].split(CsvConfigConsts.DEFAULT_FIELD_SEPARATOR);
        for (let j: number = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);
    }
    return result;
}
}
