import { Component, OnInit } from '@angular/core';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-zooms',
  templateUrl: './zooms.component.html'
})
export class ZoomsComponent extends RootformComponent implements OnInit{

  public async ngOnInit(): Promise<void> {
    await super.build('zooms', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data = this.formStackService.currentData;
    }
  }

}
