<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="rib.title" [open]=true>
    <form id="formRib" #formRib="ngForm" (ngSubmit)="submit()" ngNativeValidate>
      <div class="form-group col-xl-12">
        <lp-label-field for="ribs.isActive" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.isActive"></lp-label-field>
        <lp-boolean-switch-box name="isActive" switchName="isActive" [(ngModel)]="data.isActive"></lp-boolean-switch-box>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field required for="ribs.order" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.order"></lp-label-field>
        <lp-number id="ribs.order" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="ribs.order"
           [(ngModel)]="data.order"></lp-number>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="ribs.domicile" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.domicile"></lp-label-field>
        <lp-text-field id="ribs.domicile" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="ribs.domicile"
           [(ngModel)]="data.domicile"></lp-text-field>
      </div>
      <!--
      <div class="form-group col-xl-12">
        <lp-label-field for="bank" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.bank"></lp-label-field>
        <lp-text-field id="bank" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="bank"
          [(ngModel)]="data.bank"></lp-text-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="desk" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.desk"></lp-label-field>
        <lp-text-field id="desk" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="desk"
           [(ngModel)]="data.desk"></lp-text-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="account" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.account"></lp-label-field>
        <lp-text-field id="account" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="account"
           [(ngModel)]="data.account"></lp-text-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="key" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.key"></lp-label-field>
        <lp-text-field id="key" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="key"
          [(ngModel)]="data.key"></lp-text-field>
      </div>-->
      <div class="form-group col-xl-12">
        <lp-label-field for="ribs.iban" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.iban"></lp-label-field>
        <lp-text-field id="ribs.iban" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="ribs.iban"
           [(ngModel)]="data.iban"></lp-text-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="ribs.bic" class="{{arrayClassesElements.get('defaultLabel')}}" text="rib.bic"></lp-label-field>
        <lp-text-field id="ribs.bic" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="ribs.bic"
          [(ngModel)]="data.bic"></lp-text-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="ribs.holder" class="{{arrayClassesElements.get('defaultLabel')}}"  text="rib.holder"></lp-label-field>
        <lp-text-field id="ribs.holder" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="ribs.holder"
          [(ngModel)]="data.holder"></lp-text-field>
      </div>
      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger RIB" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>
