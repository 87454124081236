import { Component, Input, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { LoggerService } from 'app/services/logger.service';
import { ModalService } from 'app/services/modal.service';
import { WidgetInterface, WidgetOptionsInterface} from 'app/models/widget';
import { WidgetService } from 'app/services/widget.service';
import { ZoomService } from 'app/services/zoom.service';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'app/models/guid';

const TRADUCTIONFIELD: string = 'options-widgets.';
/*const WIDGET_DESIGNER_URL = '/administration/designer/widgets-designer';*/

@Component({
  selector: 'lp-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {

  public widgetConfiguration: WidgetInterface;
  public values: any;
  public ready: boolean = false;
  @Input() public id: string;
  @Input() public screen: string;
  @ViewChild('cardContent', { static: false }) public cardContent: ElementRef;
  // @Input() public externalFilters: Filter[] = [];
  @Input() public displayOptions: Boolean = false;

  @Input() public title: Boolean;
  @Input() public dataInput: WidgetInterface;
  @Output() public label: EventEmitter<any> = new EventEmitter<string>();

  public options: WidgetOptionsInterface;
  public optionsCheckBox: any = [];
  public optionsInput: any = [];
  public optionsDropDown: any = [];
  public svgRatio: any = 1;
  public col: String = '';
  public showLoadingIndicator: Boolean = true;

  public doughnutChartOptions: any = {
    responsive: false,
    cutoutPercentage: 80,
    circumference: 2 * Math.PI,
    legend: { display: false },
    tooltips: { enabled: false },
    animation: {
      onComplete: function (): void {
        const ctx: any = this.chart.ctx;
        const txt: any = this.data.datasets[0].data[0];
        const centerX: any = ((this.chartArea.left + this.chartArea.right) / 2);
        const centerY: any = ((this.chartArea.top + this.chartArea.bottom) / 2);
        ctx.font = '14px Arial';
        ctx.fillStyle = 'rgba(0,0,0,1)';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(txt, centerX, centerY);
      }
    }
  };



  /*curves = {
    'Basis': shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    'Bundle': shape.curveBundle.beta(1),
    'Cardinal': shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    'Linear': shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    'Natural': shape.curveNatural,
    'Step': shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    'default': shape.curveLinear
  };

  curveType;
  curve: any;*/

  public mapValue: Map<string, string> = new Map<string, string>();
  public mapField: Map<string, string> = new Map<string, string>();
  public mapOperator: Map<string, string> = new Map<string, string>();
  public mapLabel: Map<string, string> = new Map<string, string>();
  public mapOptionsCheckbox: Map<string, string> = new Map<string, string>();
  public mapOptionsInput: Map<string, string> = new Map<string, string>();
  public mapOptionsDropDown: Map<string, string> = new Map<string, string>();
  public mapOptionsTraduction: Map<string, string> = new Map<string, string>();
  public mapCanvas: Map<number, number> = new Map<number, number>();
  public mapTuiles: Map<number, number> = new Map<number, number>();
  public guid: string;
  public sanitizedUrl: any;

  /*public maxPopulation;
  public link: string;
  public isDesigner: boolean;
  public legendTitle: string;
  public isCustom: Boolean = true;*/

  protected defaultFilters: any;

  protected indexProviders: any = 0;

  constructor(
    protected widgetService: WidgetService,
    protected modalService: ModalService,
    protected loggerService: LoggerService,
    private zoomService: ZoomService,
    private translateService: TranslateService
  ) {}

    public async ngOnInit(): Promise<void> {
      this.widgetConfiguration = await this.widgetService.getWidgetConfiguration(this.id)
      if (this.widgetConfiguration.type !== 8) {
      this.values = await this.widgetService.getData(this.widgetConfiguration, this.screen);
      } else {
        this.guid = JSON.stringify(Guid.newGuid());
        await this.zoomService.displayZoom(this.widgetConfiguration.settings.config, this.guid);
      }
      this.options = this.widgetConfiguration.options;
      this.initOption();
      this.ready = true;
      this.showLoadingIndicator = false;
      //this.initURL();
    }

    public displayRouage(): void {
      this.displayOptions = !this.displayOptions;
    }

    public showOptions(event: any, option: any): void {
       const optionTrad: string = this.mapOptionsTraduction.get(option);
      if (typeof (this.widgetConfiguration.options[optionTrad]) === 'boolean') {
        this.widgetConfiguration.options[optionTrad] = !(this.widgetConfiguration.options[optionTrad]);
      }
    }

    public initOption(): void {
      if (this.options) {
        this.mapOptionsCheckbox.clear();
        this.mapOptionsInput.clear();
        this.mapOptionsDropDown.clear();
        this.mapOptionsTraduction.clear();
        this.optionsCheckBox = [];
        this.optionsInput = [];
        this.optionsDropDown = [];
        Object.keys(this.options).forEach((opt) => {
          const optionTraduction: any = this.translateService.instant(TRADUCTIONFIELD + opt);
          if (typeof (this.options[opt]) === 'boolean') {
            this.mapOptionsCheckbox.set(optionTraduction, this.options[opt]);
            this.optionsCheckBox.push(optionTraduction);
          } else if (typeof (this.options[opt]) === 'string') {
            this.mapOptionsInput.set(optionTraduction, this.options[opt]);
            this.optionsInput.push(optionTraduction);
          } else if (Array.isArray(this.options[opt])) {
            this.mapOptionsDropDown.set(optionTraduction, this.options[opt]);
            this.optionsDropDown.push(optionTraduction);
          }
          this.mapOptionsTraduction.set(optionTraduction, opt);
        });
      }
    }

  /*private async initialization() {
    //this.isDesigner = this.userService.isDesigner();
    this.showLoadingIndicator = true;
    this.mapCanvas = new Map<number, number>();
    try {
      if (this.dataInput) {
        this.datasWidget = this.dataInput;
      } else if (this.id) {
        //this.datasWidget = await this.lpWidgetProvider.read(this.id);
        this.datasWidget = await this.widgetService.getData(this.id);
      }

      if (this.datasWidget) {
        if (!Util.isNullOrUndefined(this.datasWidget.isCustom) && this.datasWidget.isCustom === false ) {
          this.isCustom = false;
        }
        this.typeWidget = this.datasWidget.type;
        this.maxPopulation = this.datasWidget.maxPopulation;
        this.settings = this.datasWidget.settings;
        if (this.datasWidget.options && Object.keys(this.datasWidget.options).length > 0) {
          this.options = this.datasWidget.options;
        }
        if (this.options) {
          this.mapOptionsCheckbox.clear();
          this.mapOptionsInput.clear();
          this.mapOptionsDropDown.clear();
          this.mapOptionsTraduction.clear();
          this.optionsCheckBox = [];
          this.optionsInput = [];
          this.optionsDropDown = [];
          Object.keys(this.options).forEach((opt) => {
            const optionTraduction = this.translate.instant(TRADUCTIONFIELD + opt);
            if (typeof (this.options[opt]) === 'boolean') {
              this.mapOptionsCheckbox.set(optionTraduction, this.options[opt]);
              this.optionsCheckBox.push(optionTraduction);
            } else if (typeof (this.options[opt]) === 'string') {
              this.mapOptionsInput.set(optionTraduction, this.options[opt]);
              this.optionsInput.push(optionTraduction);
            } else if (Array.isArray(this.options[opt])) {
              this.mapOptionsDropDown.set(optionTraduction, this.options[opt]);
              this.optionsDropDown.push(optionTraduction);
            }
            this.mapOptionsTraduction.set(optionTraduction, opt);
          });
        }

        //if (this.datasWidget && this.datasWidget.settings && this.datasWidget.settings.defaultFilters) {
          //this.defaultFilters = this.datasWidget.settings.defaultFilters;
        //}

        if (this.typeWidget !== 4 && this.typeWidget !== 5) {
          if (this.datasWidget.colorScheme !== undefined && this.datasWidget.colorScheme.domain.length !== 0) {
            this.datasWidget.colorScheme = this.datasWidget.colorScheme;
            this.updateDatas();
          } else {
            // couleur normal pour tous les widgets
            //this.chartColorProvider.read(DEFAULT_COLOR_THEMES).then((colorData: any) => {
              //if (colorData) {
                //this.datasWidget.colorScheme = colorData.colorScheme;
                this.updateDatas();
              //} else {
                //this.loggerService.low(colorData, 'Erreur ColorProvider');
              //}
            //});
          }
        } else {

          switch (this.typeWidget) {
            case 4: {
              // couleur chart table (frigo)
              if (this.datasWidget.colorScheme !== undefined && this.datasWidget.colorScheme.domain.length !== 0) {
                this.colorWidgetChartTable = this.datasWidget.colorScheme.domain;
                this.updateDatas();
              } else {
                //this.chartTableColorProvider.list().then((colorData: any) => {
                  //if (colorData && colorData.length !== 0) {
                    //this.colorWidgetChartTable = colorData;
                    this.updateDatas();
                  //} else {
                    //this.loggerService.low(colorData, 'Erreur ChartTableColorProvider');
                  //}
                //});
              }
              break;
            }
            case 5: {
              // couleur chart tuiles
              if (this.datasWidget.colorScheme !== undefined && this.datasWidget.colorScheme.domain.length !== 0) {
                this.colorWidgetTuiles = this.datasWidget.colorScheme.domain;
                this.updateDatas();
              } else {
                //this.chartTuilesColorProvider.list().then((colorData: any) => {
                  //if (colorData && colorData.length !== 0) {
                    //this.colorWidgetTuiles = colorData;
                    this.updateDatas();
                  //} else {
                    //this.loggerService.low(colorData, 'Erreur ChartTuilesColorProvider');
                  //}
                //});
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      } else {
        //this.loggerService.low(this.datasWidget, 'Erreur lpWidgetProvider');
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (changes['externalFilters'] && !changes['externalFilters'].firstChange && this.externalFilters) {
      this.updateDatas();
    }
    if ((changes['id'] && changes['id'].currentValue) || (changes['dataInput'] && changes['dataInput'].currentValue)) {
      this.indexes.items.length = 0;
      this.initialization();
    }
  }

  private computeCol(value): void {
    switch (value) {
      case 1:
        this.col = 'col-lg-12';
        break;
      case 2:
        this.col = 'col-sm-6';
        break;
      case 3:
        this.col = 'col-sm-6 col-lg-4';
        break;
      case 4:
        this.col = 'col-sm-6 col-lg-3';
        break;
      default:
        this.col = 'col-xs-12';
    }
  }

  public updateDatas(): void {
    try {
      this.showLoadingIndicator = true;
      const dataItems = [];
      const dataTemp = [];
      let dataTempCanvas: number[] = [];
      let providerLength = 0;
      let indexColor = 0;
      let indexCanvas = 0;
      let indexSettingsCanvas = 0;
      let countProvider = 0;
      let filters = [];

      let keyValues;
      let values;
      let keyOperator;
      let operator;
      let keyField;
      let field;
      let keyLabel;
      let label;

      const dataItemBarPieTemp: WidgetDataSerieItem = {
        'name': 'Other',
        'value': 0
      };

      // (this.datasWidget && this.datasWidget.settings
        // && this.datasWidget.settings.defaultFilters && this.datasWidget.settings.defaultFilters.length > 0) ?
        // filters = this.headerService.getFilters().concat(this.datasWidget.settings.defaultFilters)
        // : filters = this.headerService.getFilters();

      if (this.externalFilters && this.externalFilters.length > 0) {
        filters = filters.concat(this.externalFilters);
      }

      if (this.settings && this.settings.providers) {

        providerLength = Object.keys(this.settings.providers).length;
        this.providers = this.settings.providers;

        this.providers.forEach((provider, indexProvider) => {
          const serie = { 'name': '', 'series': [] };
          this.widgetService.getWidgetConfiguration(this.id).then((value) => {
            if (value && value.length !== 0) {
              value.map((item, index) => {
                if (this.typeWidget === 1) {
                  const optionTempArea: WidgetOptionsAreaChartInterface = this.options;
                  serie.name = provider.serieName;
                  const dataItemArea: WidgetDataSerieItem = {
                    'name': (optionTempArea.timeline) ?
                      new Date(item[this.settings.fields[indexProvider]['name']]) : item[this.settings.fields[indexProvider]['name']],
                    'value': parseInt(item[this.settings.fields[indexProvider]['value']], 0)
                  };

                  //(this.datasWidget && this.datasWidget.clickFilters && this.datasWidget.clickFilters[indexProvider].value) ?
                    //values = this.datasWidget.clickFilters[indexProvider].value : values = dataItemArea.name;

                  //if (this.datasWidget && this.datasWidget.clickFilters) {
                    keyValues = provider.serieName;
                    keyOperator = provider.serieName;
                    //operator = this.datasWidget.clickFilters[indexProvider].operator;
                    keyField = provider.serieName;
                    //field = this.datasWidget.clickFilters[indexProvider].field;
                    keyLabel = provider.serieName;
                    //label = this.datasWidget.clickFilters[indexProvider].label;
                  //}

                  serie.series.push(dataItemArea);
                  dataTemp[indexProvider] = serie;
                  if ((indexProvider + 1 === this.providers.length) && (index + 1 === value.length)) {
                    this.indexes.items = dataTemp;
                  }

                  // histogramme et camembert -------
                } else if (this.typeWidget === 2 || this.typeWidget === 3) {
                  const dataItemBarPie: WidgetDataSerieItem = {
                    'name': (item[this.settings.fields[indexProvider]['name']]) ?
                      item[this.settings.fields[indexProvider]['name']] : 'Sans valeur', // un des champs name d'un des Item est null
                    'value': item[this.settings.fields[indexProvider]['value']]
                  };
                  //(this.datasWidget && this.datasWidget.clickFilters && this.datasWidget.clickFilters[indexProvider].value) ?
                    //values = this.datasWidget.clickFilters[indexProvider].value : values = dataItemBarPie.name;
                  if (this.typeWidget === 2) {
                    //values = item[this.datasWidget.clickFilters[indexProvider].value];
                  }
                  //if (this.datasWidget && this.datasWidget.clickFilters) {
                    keyValues = dataItemBarPie.name;
                    keyOperator = dataItemBarPie.name;
                    //operator = this.datasWidget.clickFilters[indexProvider].operator;
                    keyField = dataItemBarPie.name;
                    //field = this.datasWidget.clickFilters[indexProvider].field;
                    keyLabel = dataItemBarPie.name;
                    //label = this.datasWidget.clickFilters[indexProvider].label;
                  //}

                  // dataItems[dataItems.length] = dataItemBarPie;
                  if (this.maxPopulation && this.maxPopulation !== undefined && this.maxPopulation.length !== 0) {
                    if (dataItems.length + 1 < this.maxPopulation) {
                      dataItems[dataItems.length] = dataItemBarPie;
                    } else {
                      if (index === value.length - 1) {
                        dataItems[dataItems.length] = dataItemBarPieTemp;
                      } else {
                        const tempValue = +dataItemBarPieTemp.value;
                        const pieValue = +dataItemBarPie.value;
                        dataItemBarPieTemp.value = tempValue + pieValue;
                      }
                    }
                  } else {
                    dataItems[dataItems.length] = dataItemBarPie;
                  }

                  if (index + 1 === value.length) { countProvider = countProvider + 1; }
                  if ((countProvider === this.providers.length) && (index + 1 === value.length)
                  ) {
                    if (this.maxPopulation && this.maxPopulation.length !== 0) {
                      this.indexes.items = dataItems;
                    } else {
                      if (dataItems.length >= value.length) {
                        this.indexes.items = dataItems;
                      }
                    }
                  };

                  // Widget frigot -------
                } else if (this.typeWidget === 4) {
                  const dataItemChartTable: WidgetDataChartTable = {
                    'label': item[this.settings.fields[indexProvider]['label']],
                    'class': item[this.settings.fields[indexProvider]['class']],
                    'number': item[this.settings.fields[indexProvider]['number']],
                    'color': (this.colorWidgetChartTable[indexColor].color) ?
                      this.colorWidgetChartTable[indexColor].color : this.colorWidgetChartTable[indexColor]
                  };

//                  (this.datasWidget && this.datasWidget.clickFilters && this.datasWidget.clickFilters[indexProvider].value) ?
  //                  values = this.datasWidget.clickFilters[indexProvider].value : values = item[this.settings.fields[indexProvider]['label']];

                  //if (this.datasWidget && this.datasWidget.clickFilters) {
                    keyValues = dataItemChartTable.label;
                    keyOperator = dataItemChartTable.label;
                    //operator = this.datasWidget.clickFilters[indexProvider].operator;
                    keyField = dataItemChartTable.label;
                    //field = this.datasWidget.clickFilters[indexProvider].field;
                    keyLabel = dataItemChartTable.label;
                    //label = this.datasWidget.clickFilters[indexProvider].label;
                  //}

                  //dataItems.push(dataItemChartTable);
                  (indexColor + 1 === this.colorWidgetChartTable.length) ? indexColor = -1 : indexColor = indexColor;
                  if ((index + 1) === value.length) { this.indexes.items = dataItems; }

                  // Tuiles -------
                } else if (this.typeWidget === 5) {
                  const data: number = parseInt(item[this.settings.fields[indexProvider]['value']], 0);
                  this.mapTuiles.set(indexProvider, data);
                  this.computeCol(this.providers.length);
                  const dataItemTuile: WidgetDataTuiles = {
                    'bgColor': (this.settings.fields[indexSettingsCanvas]['bgColor']) ?
                      this.settings.fields[indexSettingsCanvas]['bgColor'] : this.colorWidgetTuiles[indexSettingsCanvas].color,
                    'icon': this.settings.fields[indexSettingsCanvas]['icon'],
                    'value': item[this.settings.fields[indexSettingsCanvas]['value']],
                    'title': this.settings.fields[indexSettingsCanvas]['title'],
                    'router': this.settings.fields[indexSettingsCanvas]['router']
                  };

                  //(this.datasWidget && this.datasWidget.clickFilters && this.datasWidget.clickFilters[indexProvider].value) ?
                    
                    //values = this.datasWidget.clickFilters[indexProvider].value : values = this.settings.fields[indexSettingsCanvas]['title'];

                  //if (this.datasWidget && this.datasWidget.clickFilters) {
                    keyValues = dataItemTuile.title;
                    keyOperator = dataItemTuile.title;
                    //operator = this.datasWidget.clickFilters[indexProvider].operator;
                    keyField = dataItemTuile.title;
                    //field = this.datasWidget.clickFilters[indexProvider].field;
                    keyLabel = dataItemTuile.title;
                    //label = this.datasWidget.clickFilters[indexProvider].label;
                  //}

                  (indexSettingsCanvas + 1 === this.colorWidgetTuiles.length) ?
                    indexSettingsCanvas = -1 : indexSettingsCanvas = indexSettingsCanvas;

                  dataItems.push(dataItemTuile);
                  let i = 0;
                  if (dataItems.length === this.providers.length) {
                    while (i <= this.providers.length - 1) {
                      dataItems[i].value = this.mapTuiles.get(i);
                      //if (isCalculatedField(dataItems[i].bgColor)) {
                        try {
                          const dataItem = dataItems[i];
                          // tslint:disable-next-line:no-eval
                          eval(dataItem.bgColor.replace('}}', '').replace('{{', ''));
                        } catch (error) {
                          console.log(error);
                        }
                      //}
                      i = i + 1;
                    }
                    this.indexes.items = dataItems;
                  }

                  // jauges indicatives O -------
                } else if (this.typeWidget === 6) {
                  let indexField = Math.floor(indexProvider / 2);
                  let indexSubField = 0;
                  if (indexField !== indexProvider / 2) {
                    indexSubField = 1;
                  }
                  let data: number = null;
                  if (!Util.isNullOrUndefined(item[this.settings.fields[indexField]['data'][indexSubField]])) {
                    data = item[this.settings.fields[indexField]['data'][indexSubField]];
                  }
                  this.mapCanvas.set(indexProvider, data);

                  if (this.mapCanvas.size === this.providers.length) {
                    dataItems.splice(0);
                    for (let ii = 0; ii < this.providers.length; ii++) {
                      indexField = Math.floor(ii / 2);
                      if (indexField === ii / 2) {
                        dataTempCanvas = [];
                      }
                      dataTempCanvas.push(this.mapCanvas.get(ii));
                      if (indexField !== ii / 2) {
                        const dataItemCanvas: WidgetDataCanvas = {
                          'title': this.settings.fields[indexField]['title'],
                          'data': dataTempCanvas,
                          'tooltip': this.settings.fields[indexField]['tooltip']
                        };
                        dataItems.push(dataItemCanvas);
                      }
                    }
                    this.indexes.items = dataItems;
                    this.colorsWidgetCanvas[0].backgroundColor = this.datasWidget.colorScheme.domain;
                  }
                } else if (this.typeWidget === 7) {
                  const optionTempLine: WidgetOptionsLineChartInterface = this.options;
                  serie.name = this.translate.instant(provider.serieName);
                  //  console.log(provider);
                  if (item && item.length !== 0) {
                    serie.series.push(
                      {
                        'value': item[this.settings.fields['value']],
                        'name': (optionTempLine.timeline) ?
                          new Date(
                            parseInt(item[this.settings.fields['name'][0]], 0),
                            parseInt(item[this.settings.fields['name'][1]], 0)) : item[this.settings.fields['name']]
                      });
                    if (index + 1 === value.length) {
                      dataTemp.push(serie);
                    };

                    //if (this.datasWidget && this.datasWidget.clickFilters) {
                      //this.mapValue.set(this.translate.instant(provider.serieName)
                        //, this.datasWidget.clickFilters[indexProvider].value);
                    //}

                    //(this.datasWidget && this.datasWidget.clickFilters[indexProvider].value) ?
                      //values = this.datasWidget.clickFilters[indexProvider].value :
                      //values = serie.name;

                    //if (this.datasWidget && this.datasWidget.clickFilters) {
                      keyValues = serie.name;
                      keyOperator = serie.name;
                      //operator = this.datasWidget.clickFilters[indexProvider].operator;
                      keyField = serie.name;
                      //field = this.datasWidget.clickFilters[indexProvider].field;
                      keyLabel = serie.name;
                      //label = this.datasWidget.clickFilters[indexProvider].label;
                    //}

                  } else { providerLength = providerLength - 1; }
                  if (providerLength === dataTemp.length) {
                    this.indexes.items = dataTemp;
                  }
                } else if (this.typeWidget === 8) {
                  const dataItemTable: WidgetDataTable = {
                    'title': item[this.settings.fields[indexProvider]['title']],
                    'text': item[this.settings.fields[indexProvider]['text']],
                    'date': item[this.settings.fields[indexProvider]['created']],
                  };

                  //(this.datasWidget && this.datasWidget.clickFilters && this.datasWidget.clickFilters[indexProvider].value) ?
                    // tslint:disable-next-line:max-line-length
                    // values = this.datasWidget.clickFilters[indexProvider].value : values = item[this.settings.fields[indexProvider]['label']];

                  //if (this.datasWidget && this.datasWidget.clickFilters) {
                    keyValues = dataItemTable.title;
                    keyOperator = dataItemTable.title;
                    //operator = this.datasWidget.clickFilters[indexProvider].operator;
                    keyField = dataItemTable.title;
                    //field = this.datasWidget.clickFilters[indexProvider].field;
                    keyLabel = dataItemTable.title;
                    //label = this.datasWidget.clickFilters[indexProvider].label;
                  //}

                  dataItems.push(dataItemTable);
                  (indexColor + 1 === this.colorWidgetChartTable.length) ? indexColor = -1 : indexColor = indexColor;
                  if ((index + 1) === value.length) { this.indexes.items = dataItems; }
                }
                indexColor = indexColor + 1;
                indexCanvas = indexCanvas + 1;
                indexSettingsCanvas = indexSettingsCanvas + 1;

                // store values, operators, fields and labels
                //if (this.datasWidget && this.datasWidget.clickFilters) {
                  this.mapValue.set(keyValues, values);
                  this.mapOperator.set(keyOperator, operator);
                  this.mapField.set(keyField, field);
                  this.mapLabel.set(keyLabel, label)
                //}
                if (this.datasWidget.options.legendTitle) {
                  this.translate.get(this.datasWidget.options.legendTitle.toString()).subscribe(translation => {
                    this.legendTitle = translation
                  })
                }
                // Init zoom
                this.refreshPositions();
              });

            } else {
              this.indexes.items = dataItems;
              this.showLoadingIndicator = false;
              //this.loggerService.low(value, 'Erreur lpWidgetProvider TypeWidget 1');
            }
          }).then(() => {
            if (indexProvider + 1 === providerLength) {
              this.indexProviders = indexProvider;
              this.showLoadingIndicator = false;
            }
          })
            .catch((e) => {
              this.showLoadingIndicator = false;
              throw e;
            });
        });
      } else {
        this.providers = null;
        this.showLoadingIndicator = false;
      }
    } catch (e) {
      console.log(e);
      this.showLoadingIndicator = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // this.refreshPositions();
    }, 5000);
  }

  onSelect(event) {
    //if (this.datasWidget && this.datasWidget.clickFilters && event !== 'Other') {
      let filter: Filter[];
      let value;
      let field;
      let operator;
      let label;
      let eventTemp;

      (event && event.name) ? eventTemp = event.name : eventTemp = event;
      value = this.mapValue.get(eventTemp);
      field = this.mapField.get(eventTemp);
      operator = this.mapOperator.get(eventTemp);
      label = this.mapLabel.get(eventTemp);
      filter = [{
        field: field,
        value: value,
        operator: operator,
        label: label
      }];
      if (filter[0].field !== undefined && filter[0].value !== undefined &&
        filter[0].operator !== undefined && filter[0].label !== undefined) {
        //this.pushFilters(filter);
      }
    //}
  }

  showOptions(event, option) {
    event.stopPropagation();
     const optionTrad = this.mapOptionsTraduction.get(option);
    if (typeof (this.datasWidget.options[optionTrad]) === 'boolean') {
      this.datasWidget.options[optionTrad] = !(this.datasWidget.options[optionTrad]);
    }
    //this.cacheService.clear(`${PATH}/${this.id}`);
    //this.userProvider.postCustomWidget(this.userService.CurrentUser.id, this.datasWidget).then(() => this.isCustom = true)
  }

  public savePreferences(event, option): void {
    event.stopPropagation();
    this.showOptions(event, option);
  }

  public constructLink(link) {
    if (link && link.routing) {
      return link.routing;
    } else {
      return link;
    }
  }

  private refreshPositions(): void {
    this.svgRatio = 1;
    if (!Util.isNullOrUndefined(this.cardContent)) {
      const eleWdith: number = this.cardContent.nativeElement.offsetWidth;
      if (this.datasWidget.type === 6) {
        if (this.indexes.items.length) {
          this.svgRatio = eleWdith / (this.indexes.items.length * 100);
        } else {
          this.svgRatio = 1;
        }
      } else {
        if (this.datasWidget.dimensions && eleWdith < this.datasWidget.dimensions['view'][0]) {
          this.svgRatio = eleWdith / this.datasWidget.dimensions['view'][0];
        } else {
          this.svgRatio = 1;
        }
      }
    }

    if (this.svgRatio > 1) {
      this.svgRatio = 1;
    }
  }

  public goToDesigner() {
    //this.designerService.setWidgetIdForDesign(this.id);
    this.route.navigate([WIDGET_DESIGNER_URL]);
  }

  public prepareToDelete() {
    this.modalService.modalPromise(LpModalChangeComponent, 'modal.warning-reset-widget').then((bool: Boolean) => {
      if (bool) {
        this.deleteCustomWidget();
      }
    });
  }

  public deleteCustomWidget() {
    //const user = this.userService.CurrentUser;
    //this.widgetProvider.deleteCustomWidgetUser(user.id, parseInt(this.id, 0)).then(() => {
      this.modalService.success('modal.success-reset')
      this.mapOptionsCheckbox.clear();
      this.initialization();
    //}
    //).catch(err => {
//      if (err.status !== 200) {
  //      this.modalService.modalPromise(ErrorModalComponent)
    //  }
    //})
  }

  formatXAxis = (val) => {
    if (isDateValid(val)) {
      return this.dateTimeService.displayDateFormat(val, this.translate.currentLang);
    } else if (!isNaN(val)) {
      return parseFloat(val).toLocaleString(this.translate.currentLang);
    } else {
      return val;
    }
  }

  formatYAxis = (val) => {
    if (isDateValid(val)) {
      return this.dateTimeService.displayDateFormat(val, this.translate.currentLang);
    } else if (!isNaN(parseInt(val, 0))) {
      return parseFloat(val).toLocaleString(this.translate.currentLang);
    } else {
      return val;
    }
  }*/
}
