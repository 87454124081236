import { Injectable } from '@angular/core';
import { LpReportParameters } from 'app/meta/report-parameters';
import { ReportsProvider, ZOOMID } from 'app/providers/reports.provider';
import { IhmStackService } from './ihm-stack.service';
import { FormStackService } from './form-stack.service';
import { LpMeta } from 'app/meta/meta';
import { Util } from 'app/statics/utils';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { ZoomMetaData } from 'app/models/zoom-metada';
import { LpReport } from 'app/meta/report';
import { PaginatedData } from 'app/models/paginated-data';
import { ApplicationItemDetail } from 'app/models/application-item-detail';
import { ModalService } from './modal.service';
import { TranslateService } from '@ngx-translate/core';

export const OBJECT: string = 'object';
export const EDITION_TYPE: string = 'editionType';
const PRINT: string = 'Imprimer';
const ACTIVE: string = 'active=true'
export const TITLE_TRANSLATE_KEY: string = 'report.selectReport';

@Injectable({
    providedIn: 'root'
  })
export class ReportService {
    public mail: string;
    public queryParamTypeOfPrint: string;
    public queryParamObject: string;
    public itemSelectedInList: string;
    public isSpoolerMode: boolean = false;
    public titleReportFromPrintReport: string;
    public titleReport: string = TITLE_TRANSLATE_KEY;
    public modelName: String = null;

    public srcPdf: Uint8Array;
    public srcPdfForSpooler: Uint8Array;

    protected arrayClassesElements: Map<String, String> = new Map<String, String>();

    private idReport: string;

    constructor(private formStackService: FormStackService,
                private reportProvider: ReportsProvider,
                private ihmStackService: IhmStackService,
                private repositoryProvider: RepositoryProvider,
                private modalService: ModalService,
                private translate: TranslateService
         ) { }

    public css(): Map<String, String> {
        this.arrayClassesElements.set('defaultLabel', 'control-label input-smcol-md-5  col-xl-3');
        this.arrayClassesElements.set('defaultInput', 'col-md-10 col-lg-8 col-xl-12');
        this.arrayClassesElements.set('defaultPostcode', 'col-md-4 col-lg-2 col-xl-2 pe-3');
        return this.arrayClassesElements;
    }

    public closeParametersPanel(event: boolean): void {
        this.ihmStackService.ShowPrintReportForm = event;
        this.ihmStackService.ShowPrintReportPDF = !event;
    }

    public updateModelName(value?: string): void {
        ( value && value.length !== 0) ? this.modelName = value : this.modelName = '';
      }

      public updateTitle(value?: string): void {
        ( value && value.length !== 0) ? this.titleReport = value : this.titleReport = TITLE_TRANSLATE_KEY;
      }

    public async loadMetaData(): Promise<ZoomMetaData> {
        return await this.repositoryProvider.getZoomMetadata(ZOOMID)
    }

    public setTitleFromPrintReport(value: string): void {
        this.titleReportFromPrintReport = value;
    }

    public pdfExported(value: Uint8Array): void {
        this.srcPdf = value;
    }

    /**
    * Fonction qui s'occupe d'envoyer la requête au back - end pour récupérer la liste des impressions.
    */
    public async list(queryParamObject: string, queryParamTypeOfPrint: string): Promise<LpReport> {
        let queryParam: string = '';
        let object: string = '';
        queryParam = `?${EDITION_TYPE}=${queryParamTypeOfPrint}`;
        ( queryParamObject && queryParamObject.length !== 0 ) ? object = `${OBJECT}=${queryParamObject}` : object = '';
        return await this.reportProvider.getReportList(`${queryParam}&${object}&${ACTIVE}`)
    }

    public getReportObject(): string {
        /*if (this.formStackService &&
            this.formStackService.currentApplicationItemDetail &&
            this.formStackService.currentApplicationItemDetail.reportObject &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportId) {
              return this.formStackService.currentApplicationItemDetail.reportObject.reportId;
          } else {
              return this.formStackService.currentApplicationItem.linkedObject.toString();
          }*/
          return this.formStackService.currentApplicationItem.linkedObject.toString();
    }

    public getTitle(): string {
        if (this.formStackService &&
            this.formStackService.currentApplicationItemDetail &&
            this.formStackService.currentApplicationItemDetail.reportObject &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportTitle ) {
            return this.formStackService.currentApplicationItemDetail.reportObject.reportTitle;
        } else {
            return PRINT;
        }
    }

    public async getParameters(ky: string): Promise<Array<LpReportParameters>> {
        this.idReport = ky;
        return new Promise<Array<LpReportParameters>>( async (resolve) => {
            await this.reportProvider.getParameters(ky).then((parameters: Array<LpReportParameters>) => {
                resolve(parameters);
            });
        });
    }

    public async getParametersDataKy(ky: string): Promise<PaginatedData> {
        return new Promise<PaginatedData>( async (resolve) => {
            await this.reportProvider.getParametersDataKy(ky).then((data: PaginatedData) => {
                resolve(data);
            });
        });
    }

    public async execute(parameters: Array<LpReportParameters>, isSpoolerMode?: boolean): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>( async (resolve, reject) => {
            await this.reportProvider.executeReport(this.idReport, parameters, this.getReportDataKY(), isSpoolerMode)
            .then( (binaryReport: any) => {
                if(!Util.isNullOrUndefined(binaryReport)) {
                    if(!Util.isNullOrUndefined(binaryReport.mode) && binaryReport.mode === 'spooler'){
                        this.isSpoolerMode = true
                        this.modalService.success(this.translate.instant('general.modalService.sendToSpooler'),
                        this.translate.instant('general.modalService.spooler'));
                    } else {
                        this.isSpoolerMode = false
                    }
                    resolve(binaryReport.report);
                } 
                resolve(null)
            }).catch((e) => {
                reject(e)
            });
        });
    }

    public async executeWithQueryParams(parameters: Array<LpReportParameters>, mailAddress: string, isSpoolerMode?: boolean): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>( async (resolve) => {
            await this.reportProvider.executeReportWithQueryParam(this.idReport, parameters, this.getReportDataKY(), mailAddress, isSpoolerMode)
            .then( (binaryReport: any) => {
                if(!Util.isNullOrUndefined(binaryReport)) {
                    if(!Util.isNullOrUndefined(binaryReport) && !Util.isNullOrUndefined(binaryReport.mode) && binaryReport.mode === 'spooler'){
                        this.isSpoolerMode = true
                        this.modalService.success(this.translate.instant('general.modalService.sendToSpooler'),
                        this.translate.instant('general.modalService.spooler'));
                    } else {
                        this.isSpoolerMode = false
                    }
                    resolve(binaryReport);
                }
                resolve(null);
            });
        });
    }

    public displayPrintShortCut(): boolean {
        let display: boolean = false;
        if (this.formStackService &&
            this.formStackService.currentApplicationItemDetail &&
            this.formStackService.currentApplicationItemDetail.reportObject &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportId &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportKyData &&
            this.ihmStackService.ShowPrintReport === true &&
            this.getLpMetaFromKey(
                this.formStackService.currentData, this.formStackService.currentApplicationItemDetail.reportObject.reportKyData) !== null &&
            this.getLpMetaFromKey(
                this.formStackService.currentData, this.formStackService.currentApplicationItemDetail.reportObject.reportKyData
                    ) !== undefined) {
                display = true;
        }
        return display;
    }

    public showReportList(): void {
        this.ihmStackService.displayPrintReportList()
    }

    public showReportParameters(): void {
        this.ihmStackService.displayPrintReportForm()
    }

    public showReportPDF(): void {
        this.ihmStackService.displayPrintReportPDF()
    }

    public hideReportPDF(): void {
        this.ihmStackService.ShowPrintReportPDF = false;
    }

    public reportExists(): boolean {
        return this.getReportDataId() && this.getReportDataId().length !== 0 &&
        this.getReportDataKY() && this.getReportDataKY().length !== 0;
    }

    public update(value: boolean, editionType: string): void {
        this.ihmStackService.ShowPrintReportList = value;
        (editionType === 'EDIT') ? this.ihmStackService.displayPrintReportPDF() : this.ihmStackService.displayPrintReportForm();
    }

    private getReportDataKY(): string {
        let reportDataKY: string = null;
        var tempApplicationItemDetail: ApplicationItemDetail = new ApplicationItemDetail();
        if (this.formStackService &&
            this.formStackService.currentApplicationItemDetail &&
            this.formStackService.currentApplicationItemDetail.reportObject &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportKyData) {
                tempApplicationItemDetail = this.formStackService.currentApplicationItemDetail;
        } else if (
            this.formStackService &&
            this.formStackService.currentApplicationItem &&
            this.formStackService.currentApplicationItem.details &&
            this.formStackService.currentApplicationItem.details[0] &&
            this.formStackService.currentApplicationItem.details[0].reportObject &&
            this.formStackService.currentApplicationItem.details[0].reportObject.reportKyData) {
                tempApplicationItemDetail = this.formStackService.currentApplicationItem.details[0];
        }
        if (tempApplicationItemDetail.reportObject &&
            tempApplicationItemDetail.reportObject.reportKyData ) {
            this.idReport = tempApplicationItemDetail.reportObject.reportId;
            reportDataKY = this.getLpMetaFromKey( this.formStackService.currentData,
                tempApplicationItemDetail.reportObject.reportKyData)
        } 
        if (reportDataKY === null) { reportDataKY = this.formStackService.CurrentKy }
        return reportDataKY;
    }

    private getReportDataId(): string {
        let idReport: string = null;
        if (this.formStackService &&
            this.formStackService.currentApplicationItemDetail &&
            this.formStackService.currentApplicationItemDetail.reportObject &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportId ) {
            idReport = this.formStackService.currentApplicationItemDetail.reportObject.reportId;
        }
        return idReport;
    }

    private getLpMetaFromKey(data: LpMeta, key: string): any {
        let final: string;
        if (key.indexOf('.') === -1) {
            final = data[key];
        } else {
            let temp: any = data;
            for (let i: number = 0; i < key.split('.').length; i++) {
                if (!Util.isNullOrUndefined(temp[key.split('.')[i]])) {
                    temp = temp[key.split('.')[i]]
                } else {
                    return null;
                }
            }
            final = temp;
        }
        return final
    }
}
