import { Component, Inject, OnInit } from '@angular/core';
import { LpLocation } from 'app/meta/location';
import { UiSyncService } from 'app/services/ui-sync.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/services/user.service';
import { MenuItem, Preference, UserInterface } from 'app/models/user.interface';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Util } from 'app/statics/utils';
import { LoginProvider } from 'app/providers/login.provider';
import { RouterService } from 'app/services/router.service';
import { BulkService } from 'app/services/bulk.service';
import { ReportsProvider } from 'app/providers/reports.provider';
import { ZoomProvider } from 'app/providers/zoom.provider';
import { LpVisualSettings } from 'app/meta/visual-settings';
import { VisualSettingsProvider } from 'app/providers/visual-settings.provider';
@Component({
  selector: 'lp-modal-change-location',
  templateUrl: './lp-modal-change-location.component.html'
})
export class LpModalChangeLocationComponent extends LpModalComponent implements OnInit {

  public location: LpLocation;
  public currentUser: UserInterface;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private uiSyncService: UiSyncService,
    private alocproProvider: AlocproProvider,
    private repositoryProvider: RepositoryProvider,
    private modalService: ModalService,
    private translate: TranslateService,
    private loginProvider: LoginProvider,
    private routerService: RouterService,
    private bulkService: BulkService,
    private zoomProvider: ZoomProvider,
    private reportProvider: ReportsProvider,
    private visualSettingsProvider: VisualSettingsProvider
    ) {
      super(dialogRef, data);
    }

  public ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.location = new LpLocation();
    this.location.assign(this.currentUser.location);
    this.uiSyncService.loadObject();
  }

  public async validForm(): Promise<void> {
    try {
      this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
      const user: UserInterface = await this.repositoryProvider.putChangeLocation('change-location', this.location.optimized());
      if (!Util.isNullOrUndefined(user)) {
        await this.clearCache();
        await this.setUserInformations(user);
        await this.setMenu();
        await this.setPreferences();
        await this.getVisualSettings();
        await this.routerService.navigate('/');
        await this.modalSuccess();
        this.uiSyncService.reloadDashboardAfterChangedLocation();
      }
    } catch (error) {
      throw error;
    }
  }

  private async getVisualSettings(): Promise<void> {
    try {
      let visualSettings: LpVisualSettings = await this.visualSettingsProvider.getVisualSettingsByAgencyId('*');
      this.userService.setVisualSettings(visualSettings);
      return;
    } catch (error) {
      throw (error);
    }
  }

  private async setPreferences(): Promise<void> {
    try {
      const preferences: Preference = await this.repositoryProvider.getPreferences();
      this.userService.userLogin(preferences);
    } catch (error) {
      throw error;
    }
  }

  private async setMenu(): Promise<void> {
    try {
      const menu: MenuItem[] = await this.loginProvider.getFullMenu();
      this.userService.setMenu(menu);
    } catch (error) {
      throw error;
    }
  }

  private async clearCache(): Promise<void> {
    try {
      this.alocproProvider.clearCache();
      this.repositoryProvider.clearCache();
      this.bulkService.clearCache();
      this.zoomProvider.clearMapZoomForDropDown();
      this.reportProvider.clearCache();
    } catch (error) {
      throw error;
    }
  }

  private async setUserInformations(user: UserInterface): Promise<void> {
    try {
      user.token = this.userService.getCurrentUser().token;
      user.location = this.location;
      user.api_key = this.userService.getCurrentUser().api_key;
      this.userService.setCurrentUser(user);
    } catch (error) {
      throw error;
    }
  }

  private async modalSuccess(): Promise<void> {
    try {
      this.modalService.success(
        this.translate.instant('general.modalService.successChangeLocation'),
        this.translate.instant('general.modalService.changeLocation'));
      this.uiSyncService.loader(false);
      this.close(true);
    } catch (error) {
      throw error;
    }
  }
}
