
<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="equipments.equipment"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm  && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)"
  (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire -->
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"
          [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
          (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>

          <!--Accordion général-->
          <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
            <!-- Champ identifiant-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-text-field id="id" className="textField"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id" label="equipments.id"
                  [(ngModel)]="data.id" [disabled]="true"></lp-text-field>
              </div>
              <!-- field equipment model -->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="model" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="equipments.model"
                  cssClass="form-control" [(ngModel)]="data.model" name="model" zoomId="equipmentModel">
                </lp-zoom-field>
              </div>
            </div>
            
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-zoom-field for="company" id="company" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="company" label="equipments.company"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.company" name="company">
                </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <lp-zoom-field id="location" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="location" label="equipments.location"
                  className="zoomField" cssClass="form-control" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="data.location" name="location">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!-- field type -->
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="equipmentType" id="equipmentType" name="equipmentType" label="equipments.type" 
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.type"> </lp-zoom-field>
              </div>
              <!-- Champ equipmentWording-->
              <div class="form-group col-xl-6">
                <lp-text-field id="wording" className="textField"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="wording" label="equipments.label"
                  [(ngModel)]="data.wording"></lp-text-field>
              </div>
            </div>

            <!-- field equipment registration -->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-text-field id="registration.registration" className="textField"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="registration.registration" label="equipments.registration.registration"
                  [(ngModel)]="data.registration.registration">
                </lp-text-field>
              </div>
              <!-- Champ registrationDate equipment-->
              <div class="form-group col-xl-6">
                <lp-date id="registration.dateRegistration" cssClass="form-control"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="registration.dateRegistration" label="equipments.registration.dateRegistration"
                  [(ngModel)]="data.registration.dateRegistration"></lp-date>
              </div>
            </div>

            <div class="row">
              <!-- Champ serialNumber equipment-->
              <div class="form-group col-xl-6">
                <lp-text-field id="serialNumber" className="textField"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="serialNumber" label="equipments.serialNumber"
                  [(ngModel)]="data.serialNumber"></lp-text-field>
              </div>
              <!-- field Position -->
              <div *ngIf="data.position" class="form-group col-xl-6">
                <lp-zoom-field zoomId="position" id="position" name="position" cssClass="form-control" label="equipments.position"
                 class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.position"> </lp-zoom-field>
              </div>
            </div>

            <lp-subgroup [bordertop]="true">

              <!-- field previsionnalDateIn  -->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-date id="previsionnalDateIn" cssClass="form-control" label="equipments.previsionnalDateIn"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="previsionnalDateIn"
                    [(ngModel)]="data.previsionnalDateIn"></lp-date>
                </div>
                <div class="form-group col-xl-6">
                  <!-- Champ dateIn equipment-->
                  <lp-date id="dateIn" cssClass="form-control" label="equipments.dateIn"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="dateIn"
                    [(ngModel)]="data.dateIn"></lp-date>
                </div>
              </div>


              <div class="row">
                <div class="form-group col-xl-6">
                  <!-- field previsionnalDateOut  -->
                  <lp-date id="previsionnalDateOut" cssClass="form-control" label="equipments.previsionnalDateOut"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="previsionnalDateOut"
                    [(ngModel)]="data.previsionnalDateOut"></lp-date>
                </div>
                <!-- Champ dateOut equipment-->
                <div class="form-group col-xl-6">
                  <lp-date id="dateOut" cssClass="form-control" label="equipments.dateOut"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="dateOut"
                    [(ngModel)]="data.dateOut"></lp-date>
                </div>
              </div>

              <!-- Champ patternOut equipment-->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-zoom-field zoomId="patternOut" id="patternOut" name="patternOut" label="equipments.patternOut" 
                  cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.patternOut"> </lp-zoom-field>
                </div>
              </div>
            </lp-subgroup>

          </lp-ui-accordion-group>

          <!--Accordion model-->
          <lp-ui-accordion-group heading="personalModel.personalModel" [open]=false key="personalModel">

            <div class="row">
              <div class="form-group col-xl-6">
                <!--field acris-->
                <lp-zoom-field zoomId="personalModelAcris" label="models.personalModel.acris" id="personalModelAcris" name="personalModelAcris" 
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.acris"> </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <!--field "carrosserie"-->
                <lp-zoom-field zoomId="personalModelBody" id="personalModelBody" name="personalModelBody" cssClass="form-control" label="models.personalModel.body" 
                class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.body"> </lp-zoom-field>
              </div>
            </div>

            <!-- field brand -->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="personalModelBrand" id="personalModelBrand" label="models.personalModel.brand" name="personalModelBrand"
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.brand"> </lp-zoom-field>
              </div>
              <!-- field kind -->
              <div class="form-group col-xl-6">                
                <lp-zoom-field zoomId="personalModelKind" id="personalModelKind" name="personalModelKind" cssClass="form-control" label="equipments.kind"
                class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.kind"> </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field category -->
                <lp-zoom-field zoomId="personalModelCategory" id="personalModelCategory" name="personalModelCategory" label="models.personalModel.category" 
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.category"> </lp-zoom-field>
              </div>
              <!-- field counterKind -->
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="personalModelCounterKind" id="personalModelCounterKind" label="models.personalModel.counterKind" name="personalModelCounterKind"
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.counterKind"> </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field doors -->
                <lp-number id="personalModel.doors" cssClass="form-control" label="models.personalModel.doors"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.doors"
                  [(ngModel)]="data.personalModel.doors" minlength="0"></lp-number>
              </div>
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.eanCode" className="textField" label="models.personalModel.eanCode"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.eanCode"
                  [(ngModel)]="data.personalModel.eanCode"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!-- field energy -->
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="personalModelEnergy" label="models.personalModel.energy" id="personalModelEnergy" name="personalModelEnergy"
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.energy"> </lp-zoom-field>
              </div>
              <!--field motorCode-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.motorCode" className="textField" label="models.personalModel.motorCode"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.motorCode"
                  [(ngModel)]="data.personalModel.motorCode"></lp-text-field>
              </div>
            </div>


            <div class="row">
              <div class="form-group col-xl-6">
                <!-- Field isLimitedSerie -->
                <lp-boolean-switch-box id="personalModel.isLimitedSerie" name="personalModel.isLimitedSerie" switchName="personalModel.isLimitedSerie" label="models.personalModel.isLimitedSerie"
                  [(ngModel)]="data.personalModel.isLimitedSerie"></lp-boolean-switch-box>
              </div>
              <div class="form-group col-xl-3">
                <!-- Field isCarStickerEligible -->
                <lp-boolean-switch-box id="isCarStickerEligible" name="isCarStickerEligible" label="equipments.isCarStickerEligible"
                  switchName="isCarStickerEligible" [(ngModel)]="data.isCarStickerEligible">
                </lp-boolean-switch-box>
              </div>
              <div class="form-group col-xl-3">
                <!-- Field isAxlTaxEligible -->
                <lp-boolean-switch-box id="isAxlTaxEligible" name="isAxlTaxEligible" switchName="isAxlTaxEligible" label="equipments.isAxlTaxEligible"
                  [(ngModel)]="data.isAxlTaxEligible"></lp-boolean-switch-box>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!--field color-->
                <lp-text-field id="color" className="textField" label="equipments.color"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="color"
                  [(ngModel)]="data.color">
                </lp-text-field>
              </div>
              <!-- field fabricationDate  -->
              <div class="form-group col-xl-6">
                <lp-date id="fabricationDate" cssClass="form-control" label="equipments.fabricationDate"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="fabricationDate"
                  [(ngModel)]="data.fabricationDate"></lp-date>
              </div>
            </div>

            <lp-subgroup [bordertop]="true">

              <!-- Champ mileageValue equipment-->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="mileageValue" cssClass="form-control" label="equipments.counter"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="mileageValue"
                    [(ngModel)]="data.mileageValue" minlength="0"></lp-number>
                </div>
                <!-- Champ mileageDate equipment-->
                <div class="form-group col-xl-6">
                  <lp-date id="mileageDate" cssClass="form-control" label="equipments.mileageDate"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="mileageDate"
                    [(ngModel)]="data.mileageDate"></lp-date>
                </div>
              </div>

              <!-- field mileageValue2  -->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="mileageValue2" cssClass="form-control" label="equipments.mileageValue2"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="mileageValue2"
                    [(ngModel)]="data.mileageValue2" minlength="0"></lp-number>
                </div>
                <!-- Field mileageDate2-->
                <div class="form-group col-xl-6">
                  <lp-date id="mileageDate2" cssClass="form-control" label="equipments.mileageDate2"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="mileageDate2"
                    [(ngModel)]="data.mileageDate2"></lp-date>
                </div>
              </div>

              <!-- Field mileageOldValue2-->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="mileageOldValue" cssClass="form-control" label="equipments.mileageOldValue"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="mileageOldValue"
                    [(ngModel)]="data.mileageOldValue" minlength="0">
                  </lp-number>
                </div>
                <!-- Champ mileageOldValue equipment-->
                <div class="form-group col-xl-6">
                  <lp-number id="mileageOldValue2" cssClass="form-control" label="equipments.mileageOldValue2"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="mileageOldValue2"
                    [(ngModel)]="data.mileageOldValue2" minlength="0">
                  </lp-number>
                </div>
              </div>

            </lp-subgroup>

            <lp-subgroup [bordertop]="true">

              <!-- field urbanConsumption  -->
              <div class="row">
                <div class="form-group col-xl-6">
                  <!--field tankCapacity -->
                  <lp-number id="personalModel.tankCapacity" cssClass="form-control" label="models.personalModel.tankCapacity"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="doors"
                    [(ngModel)]="data.personalModel.tankCapacity" minlength="0">
                  </lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-number id="personalModel.urbanConsumption" cssClass="form-control" label="models.personalModel.urbanConsumption"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.urbanConsumption"
                    [(ngModel)]="data.personalModel.urbanConsumption"
                    minlength="0">
                  </lp-number>
                </div>
              </div>

              <!-- field highwayConsumption  -->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="personalModel.mixedConsumption" cssClass="form-control" label="models.personalModel.mixedConsumption"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.mixedConsumption"
                    [(ngModel)]="data.personalModel.mixedConsumption"
                    minlength="0">
                  </lp-number>
                </div>
                <!-- field mixedConsumption  -->
                <div class="form-group col-xl-6">
                  <lp-number id="personalModel.highwayConsumption" cssClass="form-control" label="models.personalModel.highwayConsumption"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.highwayConsumption"
                    [(ngModel)]="data.personalModel.highwayConsumption"
                    minlength="0"></lp-number>
                </div>
              </div>

            </lp-subgroup>

          </lp-ui-accordion-group>

          <!--Accordion Buy Sell-->
          <lp-ui-accordion-group heading="equipments.BuySell" [open]=false key="BuySell">


            <!-- field provider  -->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-zoom-field id="provider" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField"  label="equipments.provider"
                  cssClass="form-control" [(ngModel)]="data.provider" name="provider" zoomId="provider">
                </lp-zoom-field>
              </div>
              <!-- Field isBuyBack -->
              <div class="form-group col-xl-6">
                <lp-boolean-switch-box id="isBuyBack" name="isBuyBack" switchName="isBuyBack" label="equipments.isBuyBack"
                  [(ngModel)]="data.isBuyBack"></lp-boolean-switch-box>
              </div>
            </div>


            <div class="row">
              <div class="form-group col-xl-6">
                <!--Field financer-->
                <lp-zoom-field id="equipmentsfinancer" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="financer" label="equipments.financer"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.financer" name="financer">
                </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <!-- field detentionDuration  -->
                <lp-number id="detentionDuration" cssClass="form-control" label="equipments.detentionDuration"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="detentionDuration"
                  [(ngModel)]="data.detentionDuration" minlength="0">
                </lp-number>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field purchaseType -->
                <lp-zoom-field zoomId="purchaseType" id="purchaseType" label="equipments.purchaseType" name="purchaseType"
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.purchaseType"> </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <!-- field purchaseInvoiceNumber  -->
                <lp-text-field id="purchaseInvoiceNumber" className="textField"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="purchaseInvoiceNumber" label="equipments.purchaseInvoiceNumber"
                  [(ngModel)]="data.purchaseInvoiceNumber"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field purchaseDate  -->
                <lp-date id="purchaseDate" cssClass="form-control" label="equipments.purchaseDate"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="purchaseDate"
                  [(ngModel)]="data.purchaseDate"></lp-date>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field purchaseAmountHT  -->
                <lp-number id="purchaseAmountHT" cssClass="form-control" label="equipments.purchaseAmountHT"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="purchaseAmountHT"
                  [(ngModel)]="data.purchaseAmountHT" minlength="0">
                </lp-number>
              </div>
              <div class="form-group col-xl-6">
                <!-- field purchaseAmountVAT  -->
                <lp-number id="purchaseAmountVAT" cssClass="form-control" label="equipments.purchaseAmountVAT"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="purchaseAmountVAT"
                  [(ngModel)]="data.purchaseAmountVAT" minlength="0">
                </lp-number>
              </div>
            </div>

            <div class="row">
              <hr class="col-sm-10 mb-3 mt-0" />
            </div>

            <!-- field sellingDate  -->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-date id="sellingDate" cssClass="form-control" label="equipments.sellingDate"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="sellingDate"
                  [(ngModel)]="data.sellingDate"></lp-date>
              </div>
              <!-- field sellingAmount  -->
              <div class="form-group col-xl-6">
                <lp-number id="sellingAmount" cssClass="form-control" label="equipments.sellingAmount"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="sellingAmount"
                  [(ngModel)]="data.sellingAmount" minlength="0"></lp-number>
              </div>
            </div>

          </lp-ui-accordion-group>

          <!--Accordion maintenance-->
          <lp-ui-accordion-group heading="equipments.maintenance" [open]=false key="maintenance">

            <!-- field propertyType -->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="propertyType" id="propertyType" label="equipments.propertyType" name="propertyType"
                cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.propertyType"> </lp-zoom-field>
              </div>
              <!--Field isMCPE-->
              <div class="form-group col-xl-6">
                <lp-boolean-switch-box id="isMCPE" name="isMCPE" switchName="isMCPE" [(ngModel)]="data.isMCPE" label="equipments.isMCPE">
                </lp-boolean-switch-box>
              </div>
            </div>

            <!-- field lastMcpeDate  -->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-date id="lastMcpeDate" cssClass="form-control"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="lastMcpeDate" label="equipments.lastMcpeDate"
                  [(ngModel)]="data.lastMcpeDate"></lp-date>
              </div>
              <!-- field maintenanceModelsRules -->
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="personalModelMaintenanceModelsRules" id="personalModelMaintenanceModelsRules" label="models.personalModel.maintenanceModelsRules"
                name="personalModelMaintenanceModelsRules" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.maintenanceModelsRules"> </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--tyresType-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.tyresType" className="textField" label="models.personalModel.tyresType"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.tyresType"
                  [(ngModel)]="data.personalModel.tyresType"></lp-text-field>
              </div>
              <!-- field tyreState  -->
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="personalModelTyreState" id="personalModelTyreState" name="personalModelTyreState" label="models.personalModel.tyreState"
                 cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.tyreState"> </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!-- field breakState -->
              <div class="form-group col-xl-6">
                <lp-zoom-field zoomId="personalModelBreakState" id="personalModelBreakState" name="personalModelBreakState" label="models.personalModel.breakState"
                 cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.breakState"> </lp-zoom-field>
              </div>
              <!-- field maxCounter  -->
              <div class="form-group col-xl-6">
                <lp-number id="maxCounter" cssClass="form-control" label="equipments.maxCounter"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="maxCounter"
                  [(ngModel)]="data.maxCounter" minlength="0"></lp-number> 
              </div>
            </div>
          </lp-ui-accordion-group>

          <!--Accordion registration-->
          <lp-ui-accordion-group heading="equipments.registrationTitle" [open]=false key="registration">

            <div class="row">
            <!--Champ firstRegistrationDate (B)-->
              <div class="form-group col-xl-6">
                <lp-date id="firstRegistrationDate" cssClass="form-control" label="equipments.firstRegistrationDate"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="firstRegistrationDate"
                  [(ngModel)]="data.firstRegistrationDate"></lp-date>
              </div>
              <!--Champ registrationOwner (C1) -->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="registrationOwner" class="{{arrayClassesElements.get('defaultInput')}}"  zoomId="registrationOwner" label="equipments.registrationOwner"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.registrationOwner" name="registrationOwner">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--field type_variant (D2)-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationD2" className="textField" label="models.personalModel.registrationD2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.registrationD2"
                  [(ngModel)]="data.personalModel.registrationD2"></lp-text-field>
              </div>
              <!--field mineType (D2.1)-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.mineType" className="textField" label="models.personalModel.mineType"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.mineType"
                  [(ngModel)]="data.personalModel.mineType"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--field tradeName dénomination commerciale (D3)-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationD3" className="textField" label="models.personalModel.registrationD3"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.registrationD3"
                  [(ngModel)]="data.personalModel.registrationD3"></lp-text-field>
              </div>
              <!--field max_load_mass (F1)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationF1" cssClass="form-control" label="models.personalModel.registrationF1"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationF1"
                  [(ngModel)]="data.personalModel.registrationF1" minlength="0">
                </lp-number>
              </div>
            </div>

            <div class="row">
              <!--field "PTAC" (F2)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationF2" cssClass="form-control" label="models.personalModel.registrationF2"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationF2"
                  [(ngModel)]="data.personalModel.registrationF2"
                  minlength="0">
                </lp-number>
              </div>
              <!--field PTRA (F3)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationF3" cssClass="form-control" label="models.personalModel.registrationF3"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationF3"
                  [(ngModel)]="data.personalModel.registrationF3"
                  minlength="0"></lp-number>
              </div>
            </div>

            <div class="row">
              <!--field personalModel.registrationG (G)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationG" cssClass="form-control" label="models.personalModel.registrationG"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationG"
                  [(ngModel)]="data.personalModel.registrationG" minlength="0">
                </lp-number>
              </div>
              <!--field unloaded weight (G1)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationG1" cssClass="form-control" label="models.personalModel.registrationG1"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationG1"
                  [(ngModel)]="data.personalModel.registrationG1" minlength="0">
                </lp-number>
              </div>
            </div>

            <!--field category (J)-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationJ" className="textField" label="models.personalModel.registrationJ"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationJ"
                  [(ngModel)]="data.personalModel.registrationJ"></lp-text-field>
              </div>
              <!--field body (J2)-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationJ2" className="textField" label="models.personalModel.registrationJ2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationJ2"
                  [(ngModel)]="data.personalModel.registrationJ2"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--field personalModel.registrationP2 (P2)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationP2" cssClass="form-control" label="models.personalModel.registrationP2"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationP2"
                  [(ngModel)]="data.personalModel.registrationP2" minlength="0">
                </lp-number>
              </div>
              <!--field fiscalPower (P6)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.fiscalPower" cssClass="form-control" label="models.personalModel.fiscalPower"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.fiscalPower"
                  [(ngModel)]="data.personalModel.fiscalPower" minlength="0">
                </lp-number>
              </div>
            </div>

            <!--field nb places ass (S1)-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationS1" cssClass="form-control" label="models.personalModel.registrationS1"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationS1"
                  [(ngModel)]="data.personalModel.registrationS1" minlength="0">
                </lp-number>
              </div>
              <!--field nb places deb (S2)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationS2" cssClass="form-control" label="models.personalModel.registrationS2"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationS2"
                  [(ngModel)]="data.personalModel.registrationS2" minlength="0">
                </lp-number>
              </div>
            </div>

            <div class="row">
              <!--field niveau sonore arrété (U1)-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationU1" className="textField" label="models.personalModel.registrationU1"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationU1"
                  [(ngModel)]="data.personalModel.registrationU1"></lp-text-field>
              </div>
              <!--field speedEngine (U2)-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationU2" className="textField" label="models.personalModel.registrationU2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.registrationU2"
                  [(ngModel)]="data.personalModel.registrationU2"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--field CO2 (V7)-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationV7" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="models.personalModel.registrationV7"
                  name="personalModel.registrationV7" [(ngModel)]="data.personalModel.registrationV7" minlength="0">
                </lp-number>
              </div>
              <!--field environmental class (V9)-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationV9" className="textField" label="models.personalModel.registrationV9"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationV9"
                  [(ngModel)]="data.personalModel.registrationV9"></lp-text-field>
              </div>
            </div>

            <!--field registrationOwnerDescription-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-textarea-field id="registrationOwnerDescription" className="textField"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="registrationOwnerDescription" label="equipments.registrationOwnerDescription"
                  [(ngModel)]="data.registrationOwnerDescription"></lp-textarea-field>
              </div>
              <!--Champ department-->
              <div class="form-group col-xl-6">
                <lp-text-field id="department" className="textField" label="equipments.department"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="department"
                  [(ngModel)]="data.department"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!-- field payload (F) -->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.payload" cssClass="form-control"  label="models.personalModel.payload"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="payload"
                  [(ngModel)]="data.personalModel.payload" minlength="0">
                </lp-number>
              </div>
            </div>

          </lp-ui-accordion-group>

          <!--Accordion localization-->
          <lp-ui-accordion-group heading="equipments.localization" [open]=false key="localization">

            <!-- Field positionDate -->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-date id="positionDate" cssClass="form-control" label="equipments.positionDate"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="positionDate"
                  [(ngModel)]="data.positionDate"></lp-date>
              </div>
              <div class="form-group col-xl-6">
                <lp-number id="latitude" cssClass="form-control" class="col-5" name="latitude" label="equipments.latitudeLongitude"
                  [(ngModel)]="data.latitude" minlength="0"></lp-number>
                <div class="col-2 sep-input pe-0 ps-0 text-center">/</div>
                <lp-number id="longitude" cssClass="form-control" class="col-5" name="longitude" label=" " 
                  [(ngModel)]="data.longitude" minlength="0"></lp-number>
              </div>
            </div>

          </lp-ui-accordion-group>

          <!--Accordion Divers-->
          <lp-ui-accordion-group heading="equipments.various" [open]=false key="various">

            <!--field lastMovement-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-text-field id="lastMovement" className="textField" label="movements.lastMovement"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="movements.lastMovement" [(ngModel)]="data.lastMovement">
                </lp-text-field>
              </div>
              <!--Champ owner-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="owner" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="owner" label="equipments.owner"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.owner" name="owner">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field state -->
                <lp-zoom-field zoomId="personalModelState" id="personalModelState" name="personalModelState" cssClass="form-control" label="models.personalModel.state"
                 class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.state"> </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <!-- field displacement  -->
                <lp-number id="personalModel.displacement" cssClass="form-control" label="models.personalModel.displacement"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.displacement"
                  [(ngModel)]="data.personalModel.displacement" minlength="0"></lp-number>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- Field isInsured -->
                <lp-boolean-switch-box id="isInsured" name="isInsured" switchName="isInsured" label="equipments.isInsured"
                  [(ngModel)]="data.isInsured"></lp-boolean-switch-box>
              </div>
              <!-- Field isPlanned -->
              <div class="form-group col-xl-6">
                <lp-boolean-switch-box id="isPlanned" name="isPlanned" switchName="isPlanned" label="equipments.isPlanned"
                  [(ngModel)]="data.isPlanned"></lp-boolean-switch-box>
              </div>
            </div>

            <lp-subgroup [bordertop]="true">

              <!-- field family / family1 / family2 / family3 -->
              <div class="row">
                <div class="form-group col-xl-6">
                    <lp-zoom-field zoomId="personalModelFamily" id="personalModelFamily" name="personalModelFamily" cssClass="form-control" label="models.personalModel.family"
                      class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.family"> </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field zoomId="personalModelFamily1" id="personalModelFamily1" name="personalModelFamily1" cssClass="form-control" label="models.personalModel.family1"
                      class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.family1"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-zoom-field zoomId="personalModelFamily2" id="personalModelFamily2" name="personalModelFamily2" cssClass="form-control" label="models.personalModel.family2"
                      class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.family2"> </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field zoomId="personalModelFamily3" id="personalModelFamily3" name="personalModelFamily3" cssClass="form-control" label="models.personalModel.family3"
                      class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.personalModel.family3"> </lp-zoom-field>
                </div>
              </div>
            </lp-subgroup>

            <lp-subgroup [bordertop]="true">

              <!--field costCenter1-->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="costCenter1" className="textField"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="costCenter1" [(ngModel)]="data.costCenter1">
                  </lp-text-field>
                </div>
                <!--field costCenter2-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="costCenter2" className="textField"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="costCenter2" [(ngModel)]="data.costCenter2">
                  </lp-text-field>
                </div>
              </div>

              <!--field costCenter3-->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="costCenter3" className="textField"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="costCenter3" [(ngModel)]="data.costCenter3">
                  </lp-text-field>
                </div>
              </div>
            </lp-subgroup>

            <div class="row">
              <hr class="col-sm-10 mb-3 mt-0" />
            </div>

            <!--field keyword-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-text-field id="keyword" className="textField" label="equipments.keyword"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="keyword"
                  [(ngModel)]="data.keyword">
                </lp-text-field>
              </div>
            </div>

          </lp-ui-accordion-group>


          <!--Accordion Debugger-->
          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
              <pre>{{ formStackService.currentData | json }}</pre>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>