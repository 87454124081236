import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpModel } from './model';
import { LpEquipment } from './equipment';
import { FtMeta } from './ft-meta';
import { LpService } from './service';

export class LpLine extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @Type(() => LpService)
    public service: LpService = new LpService();
    @Type(() => LpModel)
    public model: LpModel = new LpModel();
    @Type(() => LpEquipment)
    public equipment: LpEquipment = new LpEquipment();
    @Type(() => FtMeta)
    public vatCode: FtMeta = new FtMeta();
    // TODO a changer
    @Type(() => FtMeta)
    public mainAccount: FtMeta = new FtMeta();
    @propertyMeta('number')
    public quantity: number;
    @propertyMeta('boolean')
    public isWithoutTax: Boolean;
    @propertyMeta('number')
    public unitPrice: number;
    @propertyMeta('number')
    public unitPriceCurrency: number;
    @propertyMeta('number')
    public percentageAmount: number;
    @propertyMeta('number')
    public discountAmount: number;
    @propertyMeta('number')
    public discountAmountCurrency: number;
    @propertyMeta('number')
    public excludingTaxAmount: number;
    @propertyMeta('number')
    public excludingTaxAmountCurrency: number;
    @propertyMeta('number')
    public vatAmount: number;
    @propertyMeta('number')
    public vatAmountCurrency: number;
    @propertyMeta('number')
    public includingTaxAmount: number;
    @propertyMeta('number')
    public includingTaxAmountCurrency: number;
    @propertyMeta('number')
    public mileage: number;
    @propertyMeta('number')
    public vatRate: number;
    @Type(() => FtMeta)
    public amountAssignment: FtMeta;
    @Type(() => FtMeta)
    public quantityAssignment : FtMeta;
    @propertyMeta('boolean')
    public amountCanBeUpdated: Boolean;
    @propertyMeta('boolean')
    public quantityCanBeUpdated: Boolean;

}
