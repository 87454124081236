import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { XAxis } from '../../meta/scheduler/xAxis';
import { SchedulerData, SchedulerDate, SchedulerEquipmentLabel } from '../../meta/scheduler/scheduler-data';
import { SchedulerUIService } from '../../services/scheduler-ui.service'
import { UiSyncService } from '../../services/ui-sync.service';
import { SchedulerLink } from '../../meta/scheduler/scheduler-link';
import { ApplicationItemProvider } from '../../providers/application-item.provider';
import { FormStackService } from '../../services/form-stack.service';
import { SchedulerMovement } from '../../meta/scheduler/scheduler-movements';
import { EvalService } from '../../services/eval.service';
import { SchedulerFilter } from 'app/meta/scheduler/scheduler-filter';
import { Util } from 'app/statics/utils';
import { SchedulerDateNaviguation } from 'app/meta/scheduler/scheduler-date-naviguation';
import { SchedulerEquipment } from 'app/meta/scheduler/scheduler-equipment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MenuProvider } from 'app/providers/menu.provider';
import { ApplicationItem } from 'app/models/application-item';
import { DatetimeService } from 'app/services/datetime.service';
import { LpShortTermContract } from 'app/meta/short-term-contract';
import { LpMovement } from 'app/meta/movement';
import { ModalService } from 'app/services/modal.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import * as _ from 'underscore'
import { RouterService, STACK_PLANNING, PLANNING } from 'app/services/router.service';
import { FormService } from 'app/services/root/form.service';
import { UserService } from 'app/services/user.service';

import { MetadataDetailsColumns } from 'app/models/metadata-details';
import { FieldDetailsService } from 'app/services/fieldDetails.service';
import { LpModalConfirmComponent } from 'app/ui/lp-modal/lp-modal-confim/lp-modal-confirm.component';
import { LpModalSchedulerChangeComponent } from 'app/ui/lp-modal/lp-modal-scheduler-confirm/lp-modal-scheduler-confirm';
import { ConfigService } from 'app/services/config.service';
import { SchedulerStatic } from '@irium/dhtmlxscheduler';
import { DateTime } from 'luxon';

const WARNING_ICON: string = '<i class=\"icon vega-warning delayed\" aria-hidden=\"true\" style="float: right;"></i>'


// const ID_POSITION_DISPO: string = 'DISPO';
// const ID_POSITION_SANS: string = 'SANS';
const DAY: string = 'day';
const WEEK: string = 'week';
const MONTH: string = 'month';
const YEAR: string = 'year';
const SCHEDULER_COLLECTION_EQUIPMENT: string = 'equipments';
const SCHEDULER_COLLECTION_MOVEMENT: string = 'movements';
const FORMULE: string = 'formule';
const ICON_FORMULE: string = 'iconFormule';
const DAYS_TO_ADD_WHILE_NAVIGATING: number = 14;
const DAYS_PER_MONTH: number = 31;
const DAYS_PER_WEEK: number = 7;
const Y_PROPERTY: string = 'sectionId';
const RENDER_TYPE: string = 'bar';
const SCHEDULER_MODE: string = 'timeline';

let SCHEDULER_WE_DEFINITION: any;
let SCHEDULER_TODAY_MARKED: Boolean;
let SCHEDULER_SCALE: number;

declare var scheduler: SchedulerStatic;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'lp-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['scheduler.component.scss'],
  providers: [RouterService, UserService]
})
export class SchedulerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('scheduler_container', { static: true }) public schedulerContainer: ElementRef;
  @Input() public x: Array<XAxis>;
  @Input() public isZoom: boolean;
  @Input() public isFilterPanelOpen: Boolean;
  @Input() public detailService: FieldDetailsService;
  @Input() public isMovementsDetailOpen: Boolean;
  @Input() public filter: SchedulerFilter;
  @Input() public startDate: String;
  @Input() public endDate: String;
  @Input() public isLoaded: Boolean;

  @Input()
  public set data(d: Array<SchedulerData>) {
    this._data = d//this.setUtcDate(d);
    if (scheduler.isViewExists(SCHEDULER_MODE)) {
      scheduler.clearAll();
      scheduler.serverList(SCHEDULER_COLLECTION_MOVEMENT, this._data);
      scheduler.parse(scheduler.serverList(SCHEDULER_COLLECTION_MOVEMENT));
    }
  }

  public get data(): Array<SchedulerData> {
    return this._data;
  }

  @Output() public label: EventEmitter<Array<String>> = new EventEmitter<Array<String>>(false);
  @Output() public configPanelState: EventEmitter<Boolean> = new EventEmitter();
  @Output() public outputData: EventEmitter<SchedulerEquipment> = new EventEmitter();
  @Output() public refresh: EventEmitter<void> = new EventEmitter();
  public VALUE: Array<String>;
  public PROFILE: Array<String>;
  public fieldToDisplayLpFieldDetails: Array<MetadataDetailsColumns> = [];
  public saveConfigUser: String;
  public saveConfigProfile: String;
  public saveConfigLocation: String;
  public saveConfigCompany: String;
  public showSavePanel: Boolean = false;
  public showConfigPanel: boolean;
  public showMovementPanel: boolean;
  public isNowDisplayedIHM: boolean;
  public isCollisionAllowedIHM: boolean;
  public showUnaffectedMovements: boolean;
  public availableColor: String;
  public specialDateColor: any;
  public specialDateStart: String;
  public isAvailable: boolean;
  public displayReturnDelayed: boolean;
  public eventBuffer: number;
  public weekendIHM: any = {};
  public weekendColorIHM: String;
  public cellHeightIHM: Number;
  public equipmentWidthIHM: Number;
  public eventHeightIHM: Number;
  public labelIHM: Array<SchedulerEquipmentLabel> = [];
  public movementLabelIHM: Array<SchedulerEquipmentLabel> = [];
  public scaleTextForIHM: String = this.translate.instant('scheduler.scale.week');
  public scale: String = MONTH;
  public iconLinkLpFieldDetail: Array<SchedulerLink>;
  public isWeekEndSelected: Boolean;
  public pairColorIHM: String;
  public specialDateEnd: String;
  public oddColorIHM: String;
  public scalesValue: Array<any> = [{ 'id': 1, 'value': 'month', 'wording': this.translate.instant('scheduler.scale.month') },
  { 'id': 2, 'value': 'week', 'wording': this.translate.instant('scheduler.scale.week') },
  { 'id': 3, 'value': 'day', 'wording': this.translate.instant('scheduler.scale.day') }];
  public positions: Array<any> = [];
  public scales: any;
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public noEquipment: boolean = false;
  public lastHour: String;
  public firstHour: String;
  private _data: Array<SchedulerData>;
  private attachedEvent: string[] = [];
  private priorities: Array<any> = [
    { key: 'movements', label: this.translate.instant('scheduler.movement') },
    { key: 'short-term-contracts', label: this.translate.instant('scheduler.shortTermContract'), default_value: true }
  ];
  // private eventColor: Map<String, String> = new Map<String, String>();

  private daysOfWeek: Array<any> = [
    { id: 1, description: this.translate.instant('dropdown.sunday') },
    { id: 2, description: this.translate.instant('dropdown.monday') },
    { id: 3, description: this.translate.instant('dropdown.tuesday') },
    { id: 4, description: this.translate.instant('dropdown.wednesday') },
    { id: 5, description: this.translate.instant('dropdown.thursday') },
    { id: 6, description: this.translate.instant('dropdown.friday') },
    { id: 7, description: this.translate.instant('dropdown.saturday') }
  ];
  private months: Array<any> = [
    { id: 1, description: this.translate.instant('dropdown.january') },
    { id: 2, description: this.translate.instant('dropdown.february') },
    { id: 3, description: this.translate.instant('dropdown.march') },
    { id: 4, description: this.translate.instant('dropdown.april') },
    { id: 5, description: this.translate.instant('dropdown.may') },
    { id: 6, description: this.translate.instant('dropdown.june') },
    { id: 7, description: this.translate.instant('dropdown.july') },
    { id: 8, description: this.translate.instant('dropdown.august') },
    { id: 9, description: this.translate.instant('dropdown.september') },
    { id: 10, description: this.translate.instant('dropdown.october') },
    { id: 11, description: this.translate.instant('dropdown.november') },
    { id: 12, description: this.translate.instant('dropdown.december') }
  ];

  constructor(public alocproProvider: AlocproProvider, public schedulerUIService: SchedulerUIService,
    private translate: TranslateService, private uiSyncService: UiSyncService, private routerService: RouterService,
    private formStackService: FormStackService, private applicationItemProvider: ApplicationItemProvider,
    private evalService: EvalService, private menuProvider: MenuProvider, private dateTimeService: DatetimeService,
    private modalService: ModalService, private formService: FormService, private userService: UserService, private configService: ConfigService) { }

  public async ngOnInit(): Promise<void> {
    this.schedulerUIService.IsAlreadyLoaded = false;
    this.showConfigPanel = false;
    this.showMovementPanel = false;
    this.eventHeightIHM = 20;
    this.cellHeightIHM = 24;
    this.equipmentWidthIHM = 350;
    this.arrayClassesElements.set('defaultLabel', 'control-label input-smcol-md-5  col-xl-3');
    this.arrayClassesElements.set('defaultInput', 'col-md-10 col-lg-8 col-xl-5');
    this.arrayClassesElements.set('defaultPostcode', 'col-md-4 col-lg-2 col-xl-2 pe-3');
    this.iconLinkLpFieldDetail = [];
    let user: any = this.userService.getCurrentUser();
    this.VALUE = [
      user.username,
      user.profile.id,
      user.location.id,
      user.company.id
    ];
    this.saveConfigUser = user.username;
    this.PROFILE = ['user', 'profile', 'location', 'company'];
    if (this.isLoaded) {
      this.showConfigPanel = this.schedulerUIService.getConfigPanelState();
      await this.initScheduler();
      // this.defineLocalForScheduler();
      scheduler.locale.date.month_short = [this.translate.instant('dropdown.short.jan'), this.translate.instant('dropdown.short.feb'),
      this.translate.instant('dropdown.short.mar'), this.translate.instant('dropdown.short.apr'),
      this.translate.instant('dropdown.may'), this.translate.instant('dropdown.short.jun'),
      this.translate.instant('dropdown.short.jul'), this.translate.instant('dropdown.short.aug'),
      this.translate.instant('dropdown.short.sep'), this.translate.instant('dropdown.short.oct'),
      this.translate.instant('dropdown.short.nov'), this.translate.instant('dropdown.short.dec')]
      this.clearPreviousConfig();
      this.applyAllConfig().then((d: boolean) => {
        this.applyCellClasses();
      });
      if (this.routerService.getUrl() === STACK_PLANNING) {
        this.defineStackBehavior();
      }
      this.positions = this.getIterableObjectFromEventColor();
    }
  }

  public ngOnDestroy(): void {
    this.attachedEvent.forEach(event => {
      scheduler.detachEvent(event);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.x) {
      if (!Util.isNullOrUndefined(changes.x.currentValue) && changes.x.currentValue.length !== 0) {
        this.noEquipment = false;
      } else {
        this.noEquipment = true;
      }
      scheduler.updateCollection(SCHEDULER_COLLECTION_EQUIPMENT, changes.x.currentValue);
    } else if (changes.isFilterPanelOpen) {
      this.showSavePanel = false;
      this.showConfigPanel = this.schedulerUIService.getConfigPanelState();
      this.showMovementPanel = this.schedulerUIService.getMovementDetailPanelState();
    }
    if (changes.isLoaded) {
      if (!changes.isLoaded.firstChange && changes.isLoaded.currentValue && !changes.isLoaded.previousValue) {
        this.ngOnInit();
      }
      if (changes.isLoaded.firstChange) {
        this.attachEvent();
      }
    }
  }

  public prevBtnClick(scale: any): void {
    this.schedulerUIService.loader = true;
    let d: SchedulerDateNaviguation = null;
    d = this.displayPrevious(scale.value, (Util.isNullOrUndefined(this.startDate)) ? this.dateTimeService.newDate().toString()
      : this.startDate.toString());
    this.schedulerUIService.sendDate(d.date, false);
  }

  public nextBtnClick(scale: any): void {
    this.schedulerUIService.loader = true;
    let d: SchedulerDateNaviguation = null;
    d = this.displayNext(scale.value, (Util.isNullOrUndefined(this.startDate)) ? this.dateTimeService.newDate().toString()
      : this.startDate.toString());
    this.schedulerUIService.sendDate(d.date, false);
  }

  public changeScale(id: any): void {
    if (id) {
      let level: string = this.getLevelFromId(id);
      this.schedulerUIService.loader = true;
      let startDate: String;
      let endDate: String;
      if (level === DAY) {
        this.setDayScale();
        startDate = this.dateTimeService.getNow();
        endDate = this.dateTimeService.toString(this.dateTimeService.toDate(this.dateTimeService.add(String(startDate), 1, 'days')));
      } else if (level === WEEK) {
        this.setWeekScale();
        startDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.subtract(this.dateTimeService.newDate().toString(),
          this.schedulerUIService.schedulerConfig.bufferBefore, 'days'));
        endDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.add(startDate.toString(), 7, 'days'));
      } else if (level === MONTH) {
        this.setMonthScale();
        startDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.dateFormat(this.startDate.toString()));
        endDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.add(startDate.toString(), DAYS_PER_MONTH, 'days'));
      } else if (level === YEAR) {
        this.setYearScale();
      }
      this.schedulerUIService.setFilterPanelState(true);
      this.schedulerUIService.DateDisplayed.scale = level;
      const date: SchedulerDate = { startDate: startDate, endDate: endDate };
      this.schedulerUIService.sendDate(date, true);
    }

  }

  public searchDate(event: any, searchedDate: String): void {
    //console.log(event, searchedDate);
    if(Util.isNullOrUndefined(event) || event && !event.target.classList.contains('btn-calendar')){
      this.schedulerUIService.setFilterPanelState(true);
      let startDate: String = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.dateFormat(searchedDate.toString()))
      let endDate: String = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.add(startDate.toString(), DAYS_PER_MONTH, 'days'));
      const date: SchedulerDate = { startDate: startDate, endDate: endDate };
      this.schedulerUIService.sendDate(date, true);
    }
  }

  public defineFirstHour(h: String): void {
    this.schedulerUIService.setFirstHour(h);
  }

  public defineLastHour(h: String): void {
    this.schedulerUIService.setLastHour(h);
  }

  public defineWeekendColor(color: any): void {
    this.schedulerUIService.saveWeekendColor(color);
    this.applyWeekendConfig(true);
  }

  public dropEvent(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.schedulerUIService.schedulerConfig.eventLabels, event.previousIndex, event.currentIndex);
    this.applyEventLabel(true);
  }

  public dropEquipment(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.schedulerUIService.schedulerConfig.equipmentLabels, event.previousIndex, event.currentIndex);
    this.applyEquipmentsLabels(true);
  }

  public applyCellClasses() {

    SCHEDULER_WE_DEFINITION = this.schedulerUIService.getWeekendDefinition();
    SCHEDULER_TODAY_MARKED = !!this.schedulerUIService.isTodayMarked();
    SCHEDULER_SCALE = this.scales.id;
    scheduler.templates.timeline_cell_class = function(evs, date: Date, section){
      let now = new Date();
      if (SCHEDULER_SCALE === 3) {
        if (date.getHours() === now.getHours()) {
          return "today";
        }
      } else {
        if (date.getDate() === now.getDate()
          && date.getMonth() === now.getMonth()
          && date.getFullYear() === now.getFullYear()
          && SCHEDULER_TODAY_MARKED) {
            return "today";
        } else if(SCHEDULER_WE_DEFINITION.days.includes(date.getDay())) {
          return SCHEDULER_WE_DEFINITION.css;
        }
      }

      return "";
    };
    scheduler.updateView();
  }

  public defineWeekendDay(weekend: any): void {
    this.schedulerUIService.saveWeekendDay(weekend);
    this.applyCellClasses();
    this.applyWeekendConfig(true);
  }

  public defineReturnDelayed(isDelayed: boolean): void {
    this.schedulerUIService.saveDelayedReturn(isDelayed);
    this.applyEquipmentsLabels(true);
  }

  public isReturnDelayed(): Boolean {
    return this.schedulerUIService.schedulerConfig.displayReturnDelayed;
  }

  public defineSpecialDayConfig(searchedDateStart: String, searchedDateEnd: String, color: String): void {
    if (searchedDateStart && searchedDateEnd) {
      this.schedulerUIService.saveSpecialDay(searchedDateStart, searchedDateEnd, color);
      this.applySpecialDayConfig(true)
      this.specialDateEnd = null;
      this.specialDateColor = null;
    }
  }

  public defineCollision(isCollisionAllowed: boolean): void {
    this.schedulerUIService.saveCollision(isCollisionAllowed);
  }

  public defineShowUnaffectedMovements(showUnaffectedMovements: boolean): void {
    this.schedulerUIService.saveShowUnaffectedMovements(showUnaffectedMovements);
    this.clearCache();
  }

  public defineBuffer(buffer: number): void {
    const previousBuffer: number = this.schedulerUIService.schedulerConfig.bufferBefore;
    this.schedulerUIService.saveBufferBefore(buffer);
    this.applyBuffer(previousBuffer, buffer);
  }

  public defineEventLabel(isShown: Boolean, object: String, property?: String[]): void {
    this.schedulerUIService.saveEventsLabel(isShown, object, property);
    this.applyEventLabel(true);
  }

  public defineEquipmentlabels(isShown: Boolean, object: String, property?: String): void {
    this.schedulerUIService.saveEquipmentLabels(isShown, object, property);
    this.applyEquipmentStyle();
  }

  public defineIsEquipmentAvailable(showAvailable: Boolean): void {
    this.schedulerUIService.saveEquipmentsAvailability(showAvailable);
    this.applyEquipmentAvailableColor(true);
    scheduler.setCurrentView();
  }

  // not fully implemented
  public defineAvailableEquipmentColor(color: String): void {
    this.schedulerUIService.saveEquipmentsAvailableColor(color);
    this.applyEquipmentAvailableColor(true);
    scheduler.setCurrentView();
  }

  // not fully implemented
  public defineEventColor(id: String, color: String): void {
    this.schedulerUIService.saveEventColor(id, color);
    this.applyEventColor(true);
  }

  public defineNow(isNowDisplayed: Boolean): void {
    this.schedulerUIService.saveNowMarker(isNowDisplayed);
    this.applyShowNow(true);
  }

  // some properties like cell heigh, event height or equipment width are override with the scheduler.matrix property
  public updateMatrixProperty(property: String, value: any, persist: Boolean = false): void {
    if (persist) {
      this.schedulerUIService.saveMatrixProperty(property, value);
    }
    if (Number.isNaN(parseInt(value))) {
      scheduler.matrix['timeline'][property.toString()] = value;
    } else {
      scheduler.matrix['timeline'][property.toString()] = parseInt(value);
    }
    scheduler.setCurrentView();
  }

  public toggleSavePanel(): void {
    if (this.userService.isAdmin()) {
      this.showSavePanel = !this.showSavePanel;
    } else {
      this.saveConfig(`"user": "${this.userService.getCurrentUser().username}", "profile": "*","location": "*","company": "*",`);
    }
  }

  public toggleConfigPanel(): void {
    if (!this.schedulerUIService.getConfigPanelState()) {
      this.showConfigPanel = true;
      this.showMovementPanel = false;
      this.schedulerUIService.setConfigPanelState(this.showConfigPanel);
      this.schedulerUIService.setMovementDetailPanelState(this.showMovementPanel);
      this.configPanelState.emit(this.showConfigPanel);
    } else {
      this.showConfigPanel = false;
      this.schedulerUIService.setConfigPanelState(this.showConfigPanel);
      this.showMovementPanel = false;
      this.schedulerUIService.setMovementDetailPanelState(this.showMovementPanel);
    }
    this.showSavePanel = false;
  }

  public closeMovementPanel(): void {
    this.showMovementPanel = false;
    this.schedulerUIService.setMovementDetailPanelState(this.showMovementPanel);

  }

  public saveConfig(id: string): void {
    if (Util.isNullOrUndefined(id)) {
      this.showSavePanel = false;
    } else {
      this.schedulerUIService.persistConfig(id).then((createdId) => {
        this.showSavePanel = false;
        this.schedulerUIService.schedulerConfig.isCustom = true
        this.schedulerUIService.schedulerConfig.id = createdId
      }).catch((err) => {
        this.showSavePanel = false;
      });
    }
  }

  public getLastMouvement(arrayMovement: Array<SchedulerMovement>): SchedulerMovement {
    let maxDate: Date = this.dateTimeService.newDate(1900, 0, 0);
    let movementObject: SchedulerMovement = new SchedulerMovement();
    for (let m of arrayMovement) {
      let tempDate: Date = this.dateTimeService.newDate(m.returnDate);
      if (tempDate > maxDate) {
        maxDate = tempDate;
        movementObject = m;
      }
    }
    return movementObject;
  }

  public clearCache(): void {
    this.schedulerUIService.emptyCache();
  }

  public async reinstateConfig(id: number): Promise<void> {
    await this.schedulerUIService.reinstateConfig(id);
    this.initIHMAccordingConfig()
    this.ngOnInit();
  }

  private getLevelFromId(id: string): string {
    let val: string = '';
    _.each(this.scalesValue, (v) => {
      if (v.id.toString() === id) {
        val = v.value
        this.scales = v;
      }
    })
    return val;
  }

  private async initScheduler(): Promise<void> {
    /*scheduler._click.dhx_cal_next_button = null;
    scheduler._cclick.dhx_cal_prev_button = null;*/
    scheduler.locale.labels.icon_save = this.translate.instant('general.button.create');
    scheduler.locale.labels.icon_cancel = this.translate.instant('general.button.no');
    /*icon_cancel: this.translate.instant('general.button.no'),
    icon_save: this.translate.instant('general.save'),*/

    scheduler.serverList(SCHEDULER_COLLECTION_MOVEMENT, this.data);
    this.setSchedulerConfig();
    scheduler.setLoadMode(Util.isNullOrUndefined(this.schedulerUIService.DateDisplayed.scale)
      ? MONTH.toString() : this.schedulerUIService.DateDisplayed.scale.toString());
    scheduler.createTimelineView({
      name: SCHEDULER_MODE,
      x_date: '%d',
      section_autoheight: false,
      dx: 450,
      dy: 20,
      x_start: 0,
      x_length: 1,
      y_unit: scheduler.serverList(SCHEDULER_COLLECTION_EQUIPMENT),
      y_property: Y_PROPERTY,
      render: RENDER_TYPE
    });
    scheduler.init(this.schedulerContainer.nativeElement,
      this.dateTimeService.toDate(this.startDate.toString()), SCHEDULER_MODE);
    scheduler.parse(scheduler.serverList(SCHEDULER_COLLECTION_MOVEMENT));
    await this.initScale(this.schedulerUIService.DateDisplayed.scale);
  }

  private async initScale(scale: String): Promise<void> {
    if (Util.isNullOrUndefined(scale)) {
      this.setMonthScale();
    } else if (scale === WEEK) {
      this.setWeekScale();
    } else if (scale === MONTH) {
      this.setMonthScale();
    } else if (scale === DAY) {
      await this.setDayScale();
    }
  }

  private async setDayScale(): Promise<void> {
    let x_start: number;
    let x_length: number;
    if(!Util.isNullOrUndefined(this.schedulerUIService.schedulerConfig.firstHour)){
      x_start = parseInt(this.schedulerUIService.schedulerConfig.firstHour.substr(0,2));
    }
    if(!Util.isNullOrUndefined(this.schedulerUIService.schedulerConfig.lastHour)){
      if(this.schedulerUIService.schedulerConfig.lastHour === "23:59"){
        x_length = 24 - parseInt(this.schedulerUIService.schedulerConfig.firstHour.substr(0,2)) ;
      } else {
        x_length = parseInt(this.schedulerUIService.schedulerConfig.lastHour.substr(0,2)) - parseInt(this.schedulerUIService.schedulerConfig.firstHour.substr(0,2)) ;
      }

    }
    this.scales = { 'id': 3, 'value': 'day', 'wording': this.translate.instant('scheduler.scale.day') }
    this.updateMatrixProperty('x_step', 1);
    this.updateMatrixProperty('x_unit', 'hour');
    this.updateMatrixProperty('x_size', Util.isNullOrUndefined(x_length) ? 24 : x_length );
    this.updateMatrixProperty('x_length', Util.isNullOrUndefined(x_length) ? 24 : x_length);
    this.updateMatrixProperty('x_start', Util.isNullOrUndefined(x_start) ? 0 : x_start);
    scheduler.templates.timeline_scale_date = (date: Date) => {
      return date.getHours() + ':00';
    };
  }

  private setWeekScale(): void {
    this.scales = { 'id': 2, 'value': 'week', 'wording': this.translate.instant('scheduler.scale.week') }
    this.updateMatrixProperty('x_unit', 'day');
    this.updateMatrixProperty('x_step', 1);
    this.updateMatrixProperty('x_size', DAYS_PER_WEEK);
    this.updateMatrixProperty('x_length', DAYS_PER_WEEK);
    scheduler.templates.timeline_scale_date = (date: Date) => {
      return this.daysOfWeek[date.getDay()].description + ' ' + date.getDate();
    };
  }

  private setMonthScale(): void {
    this.scales = { 'id': 1, 'value': 'month', 'wording': this.translate.instant('scheduler.scale.month') }
    this.updateMatrixProperty('x_unit', 'day');
    this.updateMatrixProperty('x_step', 1);
    this.updateMatrixProperty('x_size', DAYS_PER_MONTH);
    scheduler.templates.timeline_scale_date = (date: Date) => {
      return date.getDate().toString();
    };
  }

  private setYearScale(): void {
    this.scaleTextForIHM = this.translate.instant('scheduler.scale.year');
    this.scale = YEAR;
    this.updateMatrixProperty('x_unit', 'month');
    this.updateMatrixProperty('x_size', 6);
    this.updateMatrixProperty('x_length', 6);
    this.updateMatrixProperty('x_step', 1);
    scheduler.templates.timeline_scale_date = (date: Date) => {
      return this.months[date.getMonth()].description + '-' + date.getFullYear();
    };
  }

  private setSchedulerConfig(): void {
    scheduler.config.dblclick_create = false;
    scheduler.config.xml_date = '%Y-%m-%dT%H:%i:%sZ';
    scheduler.config.mark_now = !!this.schedulerUIService.isTodayMarked();
    scheduler.config.start_on_monday = false;
    scheduler.config.occurrence_timestamp_in_utc = true;
    scheduler.config.details_on_create = false;
    scheduler.config.details_on_dblclick = true;
    scheduler.config.show_loading = true;
    //
    scheduler.config.server_utc = true;
    scheduler.config.occurrence_timestamp_in_utc = true;
    //
    scheduler.config.load_date = '%Y-%m-%d:T%h:%i:%sZ';
    scheduler.config.buttons_left = [];
    scheduler.config.buttons_right = ['dhx_save_btn', 'dhx_cancel_btn'];
    scheduler.config.lightbox.sections = [{
      name: this.translate.instant('scheduler.typeNewEvent'),
      height: 58,
      options: this.priorities,
      map_to: 'eventType',
      type: 'radio',
      default_value: 'short-term-contracts'
    }];
    if (!Util.isNullOrUndefined(this.isZoom) && this.isZoom) {
      scheduler.config.readonly = true;
    } else {
      scheduler.config.readonly = false;
    }
  }

  private applyAllConfig(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // this.getConfig().then((d: boolean) => {
      this.initIHMAccordingConfig();
      this.applyEquipmentsLabels(false);
      this.applyEquipmentAvailableColor(false);
      this.applyEquipmentUnAffected(false);
      // this.applyEventColor(false);
      this.applyEventLabel(false);
      // this.applyLineColor(false);
      this.applyShowNow(false);
      this.applySpecialDayConfig(false);
      this.applyWeekendConfig(false);
      this.updateMatrixProperty('dy', this.schedulerUIService.schedulerConfig.dy, false);
      this.updateMatrixProperty('event_dy', this.schedulerUIService.schedulerConfig.event_dy, false);
      this.updateMatrixProperty('dx', this.schedulerUIService.schedulerConfig.dx, false);
      scheduler.updateView();
      resolve(true);
    });
    // });
  }

  private applyWeekendConfig(updateView: boolean): void {
    this.applyCellClasses();
  }

  private applySpecialDayConfig(updateView: boolean): void {
    this.schedulerUIService.getAllSpecialsDayConfig().forEach((value: any, key: String) => {
      if (this.schedulerUIService.specialDayAlreadyExist(key)) {
        let t: any = this.formatSpecialDayForDisplay(this.schedulerUIService.getSpecialDayConfig(key));
        scheduler.deleteMarkedTimespan(this.formatSpecialDayForDisplay(t));
      }
      scheduler.addMarkedTimespan(this.formatSpecialDayForDisplay(this.schedulerUIService.getSpecialDayConfig(key)));
    });
    if (updateView) {
      scheduler.updateView();
    }
  }

  private applyEquipmentAvailableColor(updateView: Boolean): void {
    scheduler.templates.timeline_scaley_class = (key: string, label: string, section: XAxis) => {
      if (!Util.isNullOrUndefined(this.startDate) && !Util.isNullOrUndefined(this.endDate)) {
        if (this.schedulerUIService.checkIfEquipmentAvailable(this.startDate, this.endDate, section.key,
          scheduler.serverList(SCHEDULER_COLLECTION_MOVEMENT))) {
          return this.schedulerUIService.getEquipmentsAvailableColor().toString();
        }
      }
      return '';
    }
    if (updateView) {
      scheduler.updateView();
    }
  }

  private applyEquipmentUnAffected(updateView: Boolean): void {
    scheduler.templates.timeline_scaley_class = (key: string, label: string, section: XAxis) => {
      if (section.key === 1) {
        return 'unaffected';
      }
    }
    if (updateView) {
      scheduler.updateView();
    }
  }

  private applyEquipmentStyle(): void {
    this.applyEquipmentsLabels(true);
    this.applyEquipmentAvailableColor(true);
  }

  private applyShowNow(updateView: boolean): void {
    scheduler.config.mark_now = !!this.schedulerUIService.isTodayMarked();
    this.applyCellClasses();
    if (updateView) {
      scheduler.updateView();
    }
  }

  private applyEventColor(updateView: boolean): void {
    scheduler.templates.event_class = (start, end, ev) => {
      if ((ev.object && ev.object.position && ev.object.position.id && this.schedulerUIService)) {
        return this.schedulerUIService.getEventColor(ev.object.position.id).toString();
      } else {
        return '';
      }
    }
    if (updateView) {
      scheduler.updateView();
    }
  }

  private applyBuffer(previousBuffer: number, buffer: number): void {
    let dif: Number = buffer - previousBuffer;
    const startDate: string =
      this.dateTimeService.dateDBFormatComplete(this.dateTimeService.subtract(this.startDate.toString(), dif, 'days'));
    const endDate: string =
      this.dateTimeService.dateDBFormatComplete(this.dateTimeService.subtract(this.startDate.toString(), DAYS_PER_MONTH, 'days'));
    let d: SchedulerDate = { startDate: startDate, endDate: endDate };
    this.schedulerUIService.sendDate(d, false);
  }

  private applyEquipmentsLabels(updateView: Boolean): void {
    scheduler.templates.timeline_scale_label = (key: string, label: string, section: any) => {
      let displayLabel: String = '';
      let length: number = (this.schedulerUIService.getNbEquipmentsLabelsTodisplays());
      displayLabel += this.constructEquipmentsLabelsHTML(section, length).toString();
      if (this.schedulerUIService.schedulerConfig.displayReturnDelayed) {
        let movement: SchedulerMovement = this.getLastMouvement(section.obj.movements);
        if (this.dateTimeService.newDate(movement.returnDate) < this.dateTimeService.newDate()
          && (movement.isClosed instanceof Boolean && !movement.isClosed)) {
          displayLabel += WARNING_ICON;
        }
      }
      return displayLabel.toString();
    };
    if (updateView) {
      scheduler.updateView();
    }
  }

  private async applyEventLabel(updateView: Boolean): Promise<void> {
    scheduler.templates.event_bar_text = (key: Date, label: Date, section: any) => {
      let displayLabel: String = '';
      if (section.object) {
        for (let e of this.schedulerUIService.getEventLabel()) {
          if (e.isdisplayed) {
            if (typeof e.label === 'object') {
              // pour les objet (customer.name, driver.lastName.....
              if (section.object[e.label[0]]) {
                for (let i: number = 1; i < e.label.length; i++) {
                  displayLabel += section.object[e.label[0]][e.label[i]] + ' ';
                }
              }
            } else if (e.label === FORMULE) {
              if (this.schedulerUIService.schedulerConfig.eval.movement.text) {
                /*displayLabel += await this.evalService.eval(this.schedulerUIService.schedulerConfig.eval.movement.text
                  , section, null, null) + ' ';*/
                  this.evalService.eval(this.schedulerUIService.schedulerConfig.eval.movement.text
                    , section, null, null).then((value) => {
                      displayLabel += value + ' ';
                    });
              }
            } else if (e.label === ICON_FORMULE) {
              if (this.schedulerUIService.schedulerConfig.eval.movement.picto) {
                //displayLabel +=  eval(this.schedulerUIService.schedulerConfig.eval.movement.picto) + ' ';
                //displayLabel += this.evalService.executeContrainteForScheduler(this.schedulerUIService.schedulerConfig.eval.movement.picto, section);
                this.evalService.executeContrainteForScheduler(this.schedulerUIService.schedulerConfig.eval.movement.picto, section).then((value) => {
                  displayLabel += value;
                })
              }
            } else {
              if (section.object[e.label]) {
                displayLabel += section.object[e.label] + ' ';
              }
            }
          }
        }
      } else {
        displayLabel += '';
      }
      return displayLabel.toString();
    };
    if (updateView) {
      scheduler.updateView();
    }
  }

  private initIHMAccordingConfig(): void {
    this.weekendIHM = this.schedulerUIService.getWeekendObjectForIHM();
    this.isNowDisplayedIHM = !!this.schedulerUIService.isTodayMarked();
    this.isCollisionAllowedIHM = !!this.schedulerUIService.isCollisionAllowed();
    this.showUnaffectedMovements = !!this.schedulerUIService.showUnaffectedMovements();
    this.weekendColorIHM = this.schedulerUIService.getWeekendColorForIHM();
    this.oddColorIHM = this.schedulerUIService.getLineColor('odd');
    this.pairColorIHM = this.schedulerUIService.getLineColor('pair');
    this.cellHeightIHM = this.schedulerUIService.getMatrixProperty('dy');
    this.equipmentWidthIHM = this.schedulerUIService.getMatrixProperty('dx');
    this.eventHeightIHM = this.schedulerUIService.getMatrixProperty('event_dy');
    this.labelIHM = this.schedulerUIService.getEquipmentsLabelsForIHM();
    this.movementLabelIHM = this.schedulerUIService.getMovementLabelsForIHM();
    this.availableColor = this.schedulerUIService.getEquipmentsAvailableColor();
    this.isAvailable = !!this.schedulerUIService.isEquipmentAvailable();
    this.displayReturnDelayed = !!this.schedulerUIService.isEquipmentAvailable();
    this.eventBuffer = this.schedulerUIService.getBufferBefore()
    this.lastHour = this.schedulerUIService.getLastHour();
    this.firstHour = this.schedulerUIService.getFirstHour();
  }

  /*private getConfig(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.schedulerUIService.IsAlreadyLoaded) {
        this.schedulerUIService.IsAlreadyLoaded = true;
        /*this.schedulerUIService.retrieveConfig().then((d) => {
          resolve(d);
        });*
      } else {
        resolve(false);
      }
    });
  }/*/

  private attachEvent(): void {
    let startEquipment: SchedulerEquipment;
    let itemBeforeDrag: any;
    let coordinateBeforeDrag: any;

    // Détache tous les évènements avant de les rattaché.
    // Permet déviter d'avoir des évènements rattachés plusieurs fois au cours de l'utilisation.
    this.attachedEvent.forEach(event => {
      scheduler.detachEvent(event);
    });
    this.attachedEvent = [];  

    this.attachedEvent.push(scheduler.attachEvent("onDblClick", function (id: string, e: Event){
      return false;
    }));
    this.attachedEvent.push(scheduler.attachEvent("onCellDblClick", function (x_ind, y_ind, x_val, y_val, e){
      return false;
    }));
    this.attachedEvent.push(scheduler.attachEvent('onEventCollision', () => {
      return !this.schedulerUIService.getCollisionAllowed();
    }));
    this.attachedEvent.push(scheduler.attachEvent('onClick', (id: string, e: Event) => {
      // this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
      if (!this.isZoom) {
        this.clickOnBarEvent(parseInt(id, 10));
      }
      return false;
    }));
    this.attachedEvent.push(scheduler.attachEvent('onBeforeDrag', (id: string, mode: any, e: Event) => {
      coordinateBeforeDrag = e
      if (!this.isZoom && mode !== 'create' && !Util.isNullOrUndefined(id)) {
        startEquipment = this.getEquipment(id);

      }
      return true;
    }));

    this.attachedEvent.push(scheduler.attachEvent("onBeforeEventChanged", function(ev, e, is_new, original){
      itemBeforeDrag = original;
      return true;
    }));
    this.attachedEvent.push(scheduler.attachEvent('onDragEnd', (id: string, mode: any, e: any) => {

      if(!Util.isNullOrUndefined(coordinateBeforeDrag) && coordinateBeforeDrag.x !== e.x && coordinateBeforeDrag.y !== e.y ){
        if (e.shiftKey) {
          this.onDrag(id, startEquipment, true, itemBeforeDrag);
        } else if (!this.isZoom && e.type !== 'click') {
          this.onDrag(id, startEquipment, false, itemBeforeDrag);
        }
      }
      itemBeforeDrag = null
      coordinateBeforeDrag = null;
      return false;
    }));
    this.attachedEvent.push(scheduler.attachEvent('onYScaleClick', (index, section, e) => {
      // this.uiSyncService.loader(true, true, this.translate.instant('loading.equipment'));
      if (!this.isZoom) {
        this.clickOnYAXIS(section.obj);
      } else {
        this.outputData.emit(section.obj);
      }
      return false;
    }));

    /* Suppression de la timezone lors de la création d'un contrat via le calendrier. */
    this.attachedEvent.push(scheduler.attachEvent("onBeforeLightbox", function (id){
        var task = scheduler.getEvent(id);      
        task.start_date = new Date(task.start_date.toISOString().slice(0, -1)); 
        task.end_date = new Date(task.end_date.toISOString().slice(0, -1));  
        return true;
    }));

    this.attachedEvent.push(scheduler.attachEvent('onEventSave', async (id: string, ev: any, is_new: Date) => {
      let isReady: boolean = await this.saveLightbox(id, ev);
      return isReady;
    }));

    this.attachedEvent.push(scheduler.attachEvent('onLightbox', async (id : any) => {      
      
      let elements: HTMLCollectionOf<Element> = document.getElementsByClassName('dhx_cal_radio');
      let a: HTMLAnchorElement = document.createElement('a');
      [].forEach.call(elements, element => {
        if (element.children[element.children.length - 1].localName !== 'a') {
          element.appendChild(a);
        }
      });

      elements = document.getElementsByClassName('dhx_save_btn_set');
      [].forEach.call(elements, element => {
        if (element.children[element.children.length - 1].localName !== 'a') {
          element.className = 'btn-primary btn float-right';
        }
      });

      elements = document.getElementsByClassName('dhx_cancel_btn_set');
      [].forEach.call(elements, element => {
        if (element.children[element.children.length - 1].localName !== 'a') {
          element.className = 'btn btn-outline-danger me-2 float-right';
        }
      });
    }));
  }

  private async clickOnBarEvent(id: number): Promise<void> {
    this.iconLinkLpFieldDetail = [];
    this.iconLinkLpFieldDetail = await this.pushMovementLink(id)
    this.detailService.data = scheduler.getEvent(id).object;
    const datas: any = await this.alocproProvider.find('movements', this.detailService.data.id);
    if(!Util.isNullOrUndefined(datas.returnDate) && !Util.isNullOrUndefined(datas.startDate)){
      datas.startDate = datas.startDate.substr(0, datas.startDate.length -1);
      datas.returnDate = datas.returnDate.substr(0,datas.returnDate.length -1);
    }
    await this.detailService.buildRightDetail(true, 'movements', String(this.detailService.data.id), null, datas, 'scheduler.detail');
    this.schedulerUIService.setConfigPanelState(false);
    this.schedulerUIService.setFilterPanelState(false);
    this.schedulerUIService.setMovementDetailPanelState(true);
    this.showMovementPanel = true;
    this.showConfigPanel = false;
    this.configPanelState.emit(false);
    // this.uiSyncService.fieldDetails({metadataId: 'movements', data: scheduler.getEvent(id).object, ecrId: 4006});
    this.showMovementPanel = true;
    this.formStackService.ignoreDataLoading = false;
    this.schedulerUIService.setMovementDetailPanelState(true);
  }

  private async clickOnYAXIS(obj: SchedulerEquipment): Promise<void> {
    this.iconLinkLpFieldDetail = [];
    this.pushEquipmentLink(obj).then(async () => {
      await this.detailService.buildRightDetail(true, 'equipments', String(obj.id), null, obj, 'scheduler.detail')
      this.schedulerUIService.setConfigPanelState(false);
      this.schedulerUIService.setFilterPanelState(false);
      this.schedulerUIService.setMovementDetailPanelState(true);
      this.showMovementPanel = true;
      this.showConfigPanel = false;
      this.configPanelState.emit(false);
      // this.uiSyncService.fieldDetails({metadataId: 'schedulerEquipments', data: obj, ecrId: 4006});
      this.showMovementPanel = true;
      this.schedulerUIService.setMovementDetailPanelState(true);
    });
  }

  private async onDrag(id: string, startEquipment: SchedulerEquipment, keepOriginalDate: boolean, originalEvent: any): Promise<void> {
    let eventStartDate: string;
    let eventEndDate: string;
    let dataStartDate: string;
    let dataEndDate: string;
    let equipment: SchedulerEquipment = this.getEquipment(id);
    let data: SchedulerMovement = scheduler.getEvent(id).object;
    let temp: any = scheduler.getEvent(id);
    scheduler.setEvent(id, this.roundDate(temp))
    if (Util.isNullOrUndefined(data)) {
      scheduler.showLightbox(id);
    } else if (!keepOriginalDate) {
      const result: boolean = await this.modalService.modalPromise(LpModalSchedulerChangeComponent, {
        backdropClass: 'alertBackdropClass',
      });
      if(result){
        eventStartDate = this.dateTimeService.dateFormat(scheduler.getEvent(id).start_date).toString();
        dataStartDate = this.dateTimeService.dateFormat(data.startDate.toString()).toString();
        eventEndDate = this.dateTimeService.dateFormat(scheduler.getEvent(id).end_date).toString();
        dataEndDate = this.dateTimeService.dateFormat(data.returnDate.toString()).toString();
        if (dataStartDate !== eventStartDate || dataEndDate !== eventEndDate || startEquipment.id !== equipment.id) {
          data.startDate = scheduler.getEvent(id).start_date;
          data.returnDate = scheduler.getEvent(id).end_date;
          if (!Util.isNullOrUndefined(equipment)) {
            this.uiSyncService.showTransition = true;
            this.uiSyncService.loader(true, false, this.translate.instant('loading.form'));
            this.redirectToRelatedObject(data, eventStartDate, eventEndDate, equipment);
          }
        }
      } else {
        scheduler.getEvent(id).start_date = originalEvent.start_date;
        scheduler.getEvent(id).end_date = originalEvent.end_date;
        scheduler.getEvent(id).sectionId = originalEvent.sectionId;
        scheduler.updateView()
      }
    } else {
      const result: boolean = await this.modalService.modalPromise(LpModalConfirmComponent, {
        backdropClass: 'alertBackdropClass',
        id : 'modalWithHeader'
      });
      if (result) {
        dataStartDate = this.dateTimeService.dateFormat(data.startDate.toString()).toString();
        dataEndDate = this.dateTimeService.dateFormat(data.returnDate.toString()).toString();
        scheduler.getEvent(id).start_date = new Date(data.startDate.toString());
        scheduler.getEvent(id).end_date = new Date(data.returnDate.toString());
        scheduler.updateView();
        setTimeout(() => {
          this.redirectToRelatedObject(data, dataStartDate, dataEndDate, equipment);
        }, 500);
      } else {
        scheduler.getEvent(id).start_date = originalEvent.start_date;
        scheduler.getEvent(id).end_date = originalEvent.end_date;
        scheduler.getEvent(id).sectionId = originalEvent.sectionId;
        scheduler.updateView()
      }
    }
  }

  private async saveLightbox(id: string, ev: any): Promise<boolean> {
    let data: SchedulerMovement = new SchedulerMovement();
    let equipment: SchedulerEquipment = this.getEquipment(id);
    if (ev.eventType) {
      let relatedObject: string = ev.eventType;
      if (relatedObject === 'short-term-contracts') {
        data.relatedObject = 'short-term-contracts';
      } else {
        data.relatedObject = 'movements';
      }
      // let applicationItem: ApplicationItem = await this.applicationItemProvider.getItem('planning')
      let tempStartDate: string = this.dateTimeService.dateDefaultFormatMultiInput(ev.start_date)
      let tempendDate: string = this.dateTimeService.dateDefaultFormatMultiInput(ev.end_date)
      data.startDate = tempStartDate;
      data.returnDate = tempendDate;
      // let infos: BreadcrumbInfoItem[] = [];
      this.uiSyncService.showTransition = true;
      this.uiSyncService.loader(true, false, this.translate.instant('loading.form'));
      this.formStackService.isStackLink = true;
      this.uiSyncService.noTtestFormChange = true;
      this.formStackService.IsPlanningPrevious = true;
      this.formStackService.currentData = data.getFTMetaFromSchedulerMovement(equipment, true);
      await this.formStackService.addStackIem(data.getFTMetaFromSchedulerMovement(equipment, true), null,
        relatedObject, this.configService.get('newdataKeyWord'), true);
      await this.formService.build(data.relatedObject.split('/')[0].toString(), this.configService.get('newdataKeyWord'), null, false);
      scheduler.endLightbox(true);
      return true;
    } else {
      this.modalService.error(this.translate.instant('general.modalService.noEventChosen'),
        this.translate.instant('general.modalService.recordingFailed'));
      return false;
    }
  }

  private redirectToRelatedObject(data: SchedulerMovement, start_date: string, end_date: string, equipment: SchedulerEquipment): void {
    let object: string = 'movements';
    let url: String = data.details;
    if (!Util.isNullOrUndefined(data.relatedObject)) {
      object = 'short-term-contracts';
      url = data.relatedObject;
    }
    this.menuProvider.getBreadcrumb(object).then((res) => {
      // let infos: BreadcrumbInfoItem[] = [];
      this.applicationItemProvider.getItem('planning').then((applicationItem: ApplicationItem) => {
        let verb: string;
        let temp: any;
        if (Util.isNullOrUndefined(data.relatedObject)) {
          temp = new LpMovement();
          Object.assign(temp, data.getFTMetaFromSchedulerMovement(equipment, false));
          verb = 'movements';
        } else {
          temp = new LpShortTermContract();
          Object.assign(temp, data.getFTMetaFromSchedulerMovement(equipment, false));
          verb = 'short-term-contracts';
        }
        this.alocproProvider.find(verb, temp.id).then(async (value) => {
          let t: any;
          if (Util.isNullOrUndefined(data.relatedObject)) {
            data.relatedObject = temp.details
            t = new LpMovement();
            Object.keys(value).forEach((key) => (value[key] == null) && delete value[key]);
            Object.assign(t, value);
            t.equipment = temp.equipment;
            // t.category = temp.category;
            t.details = temp.details;
            t.startDate = temp.startDate;
            t.returnDate = temp.returnDate;
            t.id = temp.id;
          } else {
            t = new LpShortTermContract();
            Object.keys(value).forEach((key) => (value[key] == null) && delete value[key]);
            Object.assign(t, value);
            // t.category = temp.category;
            t.plannedReturnDate = temp.plannedReturnDate;
            t.startDate = temp.startDate;
            t.movement.equipment = temp.movement.equipment;
            const s: DateTime = DateTime.fromJSDate(new Date(temp.startDate));
            const p:  DateTime = DateTime.fromJSDate(new Date(temp.plannedReturnDate));
            t.wantedDays = Math.ceil(p.diff(s, 'days').toObject().days);

            if (t.wantedDays === 0) {
              t.wantedDays = 1;
            }
          }
          await this.formStackService.addStackIem(t, null, url.split('/')[0], url.split('/')[1], true);
          // await this.formService.build(data.relatedObject.split('/')[0].toString(), t.id, t);
          await this.routerService.navigate(data.relatedObject.split('/')[0].toString(), t.id);
        });
      });
    });
  }

  private displayNext(scale: String, previousStartDate: string): SchedulerDateNaviguation {
    const d: SchedulerDateNaviguation = { date: { startDate: null, endDate: null }, scale: null };
    if (scale === MONTH) {
      d.date.startDate = this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.add(previousStartDate.toString(), DAYS_TO_ADD_WHILE_NAVIGATING, 'days')
      );
      d.date.endDate = this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.add(d.date.startDate.toString(), DAYS_PER_MONTH, 'days')
      );
    } else if (scale === WEEK) {
      let daysToAdd: number = 0;
      if (previousStartDate !==
        this.dateTimeService.dateDBFormatComplete(this.dateTimeService.dateFormat(this.dateTimeService.newDate().toString()))) {
        daysToAdd = DAYS_PER_WEEK;
      }
      d.date.startDate = this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.add(previousStartDate.toString(), daysToAdd, 'days')
      );
      d.date.endDate = this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.add(previousStartDate.toString(), (daysToAdd + 7), 'days')
      );
    } else {
      d.date.startDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.add(previousStartDate.toString(), 1, 'days'));
      d.date.endDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.add(previousStartDate.toString(), 2, 'days'));
    }
    d.scale = this.scales.value;
    return d;
  }

  private displayPrevious(scale: String, previousStartDate: string): SchedulerDateNaviguation {
    const date: SchedulerDate = { startDate: null, endDate: null };
    const d: SchedulerDateNaviguation = { date: date, scale: null };
    if (scale === MONTH) {
      d.date.startDate =
        this.dateTimeService.dateDBFormatComplete(this.dateTimeService.subtract(previousStartDate.toString(), DAYS_TO_ADD_WHILE_NAVIGATING, 'days'));
      d.date.endDate = this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.add(d.date.startDate.toString(), DAYS_PER_MONTH, 'days')
      );
    } else if (scale === WEEK) {
      d.date.startDate = this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.subtract(previousStartDate.toString(), DAYS_PER_WEEK, 'days')
      );
      d.date.endDate = this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.dateFormat(previousStartDate.toString())
      );
    } else {
      d.date.startDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.subtract(previousStartDate.toString(), 1, 'days'));
      d.date.endDate = this.dateTimeService.dateDBFormatComplete(this.dateTimeService.dateFormat(previousStartDate.toString()));
    }
    d.scale = this.scales.value;
    return d;
  }

  /**
   * la fonction pushLink() permet d'ajouter des liens à afficher dans lebloc du mouvement
   */
  private async pushMovementLink(id: number): Promise<Array<SchedulerLink>> {
    let array: Array<SchedulerLink> = [];
    let mouvement: SchedulerMovement = await scheduler.getEvent(id).object;
    if (!Util.isNullOrUndefined(mouvement.relatedObject) && mouvement.relatedObject !== 'short-term-contracts/null'
    && !mouvement.relatedObject.includes('movements/')) {
      let t: ApplicationItem = await this.applicationItemProvider.getItem('short-term-contracts')
      array.push({
        'icon': t,
        'details': mouvement.relatedObject
      });
    } else {
      array.push({
        'icon': await this.applicationItemProvider.getItem('movements'),
        'details': mouvement.details
      });
    }
    if (!Util.isNullOrUndefined(mouvement.customer)) {
      array.push({
        'icon': await this.applicationItemProvider.getItem('third-parties'),
        'details': mouvement.customer.details
      });
    }
    if (!Util.isNullOrUndefined(mouvement.driver)) {
      array.push({
        'icon': await this.applicationItemProvider.getItem('third-parties'),
        'details': mouvement.driver.details
      });
    }
    if (this.schedulerUIService.getEquipmentFromMouvementId(scheduler.getEvent(id).sectionId, this.x).obj) {
      array.push({
        'icon': await this.applicationItemProvider.getItem('equipments'),
        'details': this.schedulerUIService.getEquipmentFromMouvementId(scheduler.getEvent(id).sectionId, this.x).obj.details
      });
    }
    return array;
  }

  private async pushEquipmentLink(obj: SchedulerEquipment): Promise<void> {
    if (obj) {
      this.iconLinkLpFieldDetail.push({
        'icon': await this.applicationItemProvider.getItem('equipments'),
        'details': obj.details
      });
    }
  }

  private clearPreviousConfig(): void {
    scheduler.deleteMarkedTimespan("days");
  }

  private async defineStackBehavior(): Promise<void> {
    if (!this.formStackService.getStack('planning')) {
      this.routerService.navigate(PLANNING);
      return;
    }
    this.showMovementPanel = true;
    this.showConfigPanel = false;
    this.schedulerUIService.setMovementDetailPanelState(this.showMovementPanel);
    this.schedulerUIService.setConfigPanelState(false);
    this.schedulerUIService.setFilterPanelState(false);
    this.updateView(this.formStackService.getStack('planning')['data'].startDate);
    let eventTemp: any;
    for (let e of scheduler.getEvents()) {
      if (e['object']['id'] === this.formStackService.getStack('planning')['data'].id) {
        eventTemp = e;
      }
    }
    // this.uiSyncService.fieldDetails({metadataId: 'movements', data: eventTemp.object, ecrId: 4006});
    this.iconLinkLpFieldDetail = [];
    this.iconLinkLpFieldDetail = await this.pushMovementLink(eventTemp.id);
  }

  private getEquipment(id: string): SchedulerEquipment {
    let equipment: SchedulerEquipment;
    scheduler.matrix.timeline.y_unit.forEach((e: any, i: number) => {
      if (scheduler.getEvent(id).sectionId === e.key) {
        equipment = scheduler.matrix.timeline.y_unit[i].obj;
      }
    });
    return equipment;
  }

  private updateView(date: Date): void {
    if (date) {
      scheduler.setCurrentView(this.dateTimeService.newDate(date), 'timeline');
    }
  }

  private formatSpecialDayForDisplay(specialDay: any): any {
    specialDay.end_date = this.dateTimeService.newDate(specialDay.end_date);
    specialDay.start_date = this.dateTimeService.newDate(specialDay.start_date);
    specialDay.zones = [0, 1440];
    specialDay.types = 'default';
    return specialDay;
  }

  private constructEquipmentsLabelsHTML(section: any, length: number): String {
    let html: String = '';
    let nbPicto: number = 0;
    const pictoWidth: number = 20;
    for (let e of this.labelIHM) {
      if (e.label === ICON_FORMULE && e.isdisplayed) {
        nbPicto = nbPicto + 1;
      }
    }
    let totalWidth: String = '(100% - ' + (nbPicto * pictoWidth) + 'px)';

    for (let e of this.schedulerUIService.getEquipmentsLabels()) {
      if (e.isdisplayed) {
        if (typeof e.label === 'object') {
          // pour les objet (customer.name, driver.lastName.....)
          if (section.obj[e.label[0]]) {
            html += '<div style="width: calc(' + totalWidth + '*' + (1 / (length - nbPicto))
              + ')">' + section.obj[e.label[0]][e.label[1]] + '</div>';
          }
        } else if (e.label === FORMULE) {
          if (this.schedulerUIService.schedulerConfig.eval.equipment.text) {
            html += '<div style="width: calc(' + totalWidth + '*' + (1 / (length - nbPicto)) + ')">' +
              this.evalService.eval(this.schedulerUIService.schedulerConfig.eval.equipment.text, section, null, null) + '</div>';
          }
        } else if (e.label === ICON_FORMULE) {
          if (this.schedulerUIService.schedulerConfig.eval.equipment.picto) {
            html += '<div style="width: ' + pictoWidth + 'px">' +
              this.evalService.eval(this.schedulerUIService.schedulerConfig.eval.equipment.picto, section, null, null) + '</div>';
          }
        } else {
          if (section.obj[e.label]) {
            html += '<div style="width: calc(' + totalWidth + '*' + (1 / (length - nbPicto)) + ')">' + section.obj[e.label] + '</div>';
          }
        }
      }
    }
    return html;
  }

  // function to get all position from movement in order to apply color for these positions
  private getIterableObjectFromEventColor(): Array<any> {
    let a: Array<any> = [];
    this.schedulerUIService.getPositions(this.data).forEach((color: String, position: String) => {
      a.push({ position: position, color: color });
    });
    return a;
  }

  private roundDate(temp: any): any {
    //let startBeforeTime: string = moment(temp.start_date).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toString();
    let startBeforeTime: string = this.dateTimeService.set(temp.start_date,{ hour: 0, minute: 0, second: 0, millisecond: 0 });
    //let startAfterTime: string = moment(temp.start_date).utcOffset(0).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toString();
    let startAfterTime: string =this.dateTimeService.set(temp.start_date, { hour: 12, minute: 0, second: 0, millisecond: 0 });
    let start_date: string = this.dateTimeService.momentHourUiFormat(temp.start_date.toString());
    //let endBeforeime: string = moment(temp.end_date).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toString();
    let endBeforeime: string = this.dateTimeService.set(temp.end_date, { hour: 0, minute: 0, second: 0, millisecond: 0 });
    //let endAfterTime: string = moment(temp.end_date).utcOffset(0).set({ hour: 14, minute: 0, second: 0, millisecond: 0 }).toString();
    let endAfterTime: string = this.dateTimeService.set(temp.end_date, { hour: 14, minute: 0, second: 0, millisecond: 0 });
    let end_date: string = this.dateTimeService.momentHourUiFormat(temp.end_date.toString());
    // Si le calendrier est à la journée
    if (this.scales.id === 3) {
      start_date = this.dateTimeService.halfHourRoundDate(start_date);
      end_date = this.dateTimeService.halfHourRoundDate(end_date);
    } else {
      //if (start_date.isBetween(startBeforeTime.toString(), startAfterTime.toString())) {
      if (this.dateTimeService.isBetween(start_date, startBeforeTime.toString(), startAfterTime.toString())) {
        start_date = this.dateTimeService.set(start_date, {'hour': 8});
      } else {
        start_date = this.dateTimeService.set(start_date, {'hour': 14});
      }
      start_date = this.dateTimeService.set(start_date, {'minute': 0});

      if (this.dateTimeService.isBetween(end_date, endBeforeime.toString(), endAfterTime.toString())) {
        end_date = this.dateTimeService.set(end_date, {'hour': 14});
      } else {
        end_date = this.dateTimeService.set(end_date, {'hour': 19});
      }
      end_date = this.dateTimeService.set(end_date, {'minute': 0});
    }
    temp.start_date = this.dateTimeService.newDate(start_date.toString());
    temp.end_date = this.dateTimeService.newDate(end_date.toString());
    return temp;
  }

  /*private defineLocalForScheduler(): void {
    scheduler.locale = {
      date: {
        month_full: [this.translate.instant('dropdown.january'), this.translate.instant('dropdown.february'),
        this.translate.instant('dropdown.march'), this.translate.instant('dropdown.april'), this.translate.instant('dropdown.may'),
        this.translate.instant('dropdown.june'), this.translate.instant('dropdown.july'),
        this.translate.instant('dropdown.august'), this.translate.instant('dropdown.september'),
        this.translate.instant('dropdown.october'), this.translate.instant('dropdown.november'),
        this.translate.instant('dropdown.december')],
        month_short: [this.translate.instant('dropdown.short.jan'), this.translate.instant('dropdown.short.feb'),
        this.translate.instant('dropdown.short.mar'), this.translate.instant('dropdown.short.apr'),
        this.translate.instant('dropdown.may'), this.translate.instant('dropdown.short.jun'),
        this.translate.instant('dropdown.short.jul'), this.translate.instant('dropdown.short.aug'),
        this.translate.instant('dropdown.short.sep'), this.translate.instant('dropdown.short.oct'),
        this.translate.instant('dropdown.short.nov'), this.translate.instant('dropdown.short.dec')],
        day_full: [this.translate.instant('dropdown.sunday'), this.translate.instant('dropdown.monday'),
        this.translate.instant('dropdown.tuesday'), this.translate.instant('dropdown.wednesday'),
        this.translate.instant('dropdown.thursday'), this.translate.instant('dropdown.friday'),
        this.translate.instant('dropdown.saturday')],
        day_short: [this.translate.instant('dropdown.short.sun'), this.translate.instant('dropdown.short.mon'),
        this.translate.instant('dropdown.short.tue'), this.translate.instant('dropdown.short.wed'),
        this.translate.instant('dropdown.short.thu'), this.translate.instant('dropdown.short.fri'),
        this.translate.instant('dropdown.short.sat')]
      }, labels: {
        dhx_cal_today_button: this.translate.instant('schduler.today'),
        day_tab: this.translate.instant('schduler.days'),
        week_tab: this.translate.instant('schduler.week'),
        month_tab: this.translate.instant('schduler.month'),
        new_event: this.translate.instant('schduler.newEvent'),
        icon_save: this.translate.instant('general.save'),
        icon_cancel: this.translate.instant('general.button.no'),
        icon_details: this.translate.instant('scheduler.detail'),
        icon_edit: this.translate.instant('scheduler.edit'),
        icon_delete: this.translate.instant('scheduler.delete'),
        confirm_closing: this.translate.instant('scheduler.closing'),
        confirm_deleting: this.translate.instant('scheduler.deleting'),
        section_description: this.translate.instant('scheduler.description'),
        section_time: this.translate.instant('scheduler.timePeriode'),
        unit_tab: this.translate.instant('scheduler.unit'),
      }
    }
  }*/

  /*private setUtcDate(d: Array<SchedulerData>): Array<SchedulerData> {
    d.forEach((element: SchedulerData) => {
      element.start_date = scheduler.date.convert_to_utc(new Date(element.start_date));
    });
    return d;
  }*/
}
