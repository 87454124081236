import { Component, Input } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormStackService } from 'app/services/form-stack.service';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { Util } from 'app/statics/utils';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { IhmStackService } from 'app/services/ihm-stack.service';

@Component({
  selector: 'lp-ui-sort-list',
  templateUrl: './lp-ui-sort-list.component.html'
})
export class LpUiSortListComponent {
  @Input() public list: any[];
  @Input() public prefix: String;
  @Input() public label: String;
  @Input() public prop: String;
  @Input() public isBulk: boolean;
  @Input() public isNotADetailButAModal: boolean = false;

  constructor(public formstackService: FormStackService,
              public repositoryProvider: RepositoryProvider,
              public modalService: ModalService,
              public translateService: TranslateService,
              private uiSyncService: UiSyncService,
              private ihmStackService: IhmStackService) {}

  public dropEvent(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
    this.saveDetailConfig()
  }

  public async reinstateDetailConfig(): Promise<void> {
    this.uiSyncService.loader(true, true, this.translateService.instant('loading.loading'));
    if (!Util.isNullOrUndefined(this.formstackService.currentApplicationItemDetail.originDetailId)) {
      await this.repositoryProvider.deleteCustomDetail(this.formstackService.currentApplicationItemDetail.originDetailId.toString());
    } else if (!Util.isNullOrUndefined(this.formstackService.currentApplicationItemDetail.id)) {
      await this.repositoryProvider.deleteCustomDetail(this.formstackService.currentApplicationItemDetail.id.toString());
    } else {
      this.modalService.error(this.translateService.instant('general.modalService.noCustomDetail'));
    }
    this.formstackService.currentApplicationItemDetail
      = await this.repositoryProvider.getApplicationItemDetails(this.formstackService.currentApplicationItemDetail.key.toString(), true)
    this.uiSyncService.loader(false);
    this.formstackService.currentApplicationItemDetail.active = true;
    this.ihmStackService.displaySubAPIDetails(this.formstackService.currentApplicationItemDetail.key.toString());
  }

  public async saveDetailConfig(): Promise<void> {
      this.uiSyncService.loader(true, true, this.translateService.instant('loading.loading'));
      await this.repositoryProvider.saveDetailConfig(this.formstackService.currentApplicationItemDetail);
      this.formstackService.currentApplicationItemDetail.isCustom = true
      this.modalService.success(this.translateService.instant('general.modalService.additionDone'));
      this.uiSyncService.loader(false);
  }

}
