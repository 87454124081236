import {Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import * as _ from 'underscore'
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-pricings',
  templateUrl: './pricings.component.html'
})

export class PricingsComponent extends RootformComponent implements OnInit, OnDestroy {

  public ky: string = null;
  public topBanner: boolean = false;
  private type: string;
  private subscriptionFilterPricings: Subscription;

  public async ngOnInit(): Promise<void> {
    this.type = this.route.snapshot.routeConfig.path;
    await super.build(this.type, this.configService.get('newdataKeyWord'));
    this.ihmStackService.displaySubAPIDetails();
    this.ihmStackService.ShowSubAPIDetailsTable = true;
    this.rightColService.currentApplicationItemDetailTitle = this.translate
    .instant(this.formStackService.currentApplicationItemDetail.title.toString());
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.formStackService.CurrentApplicationItemDetailData = null;
      this.data = this.formStackService.currentData;
      this.ihmStackService.displaySubAPIDetails();
    }
    this.subscribtion();
    this.topBanner = true;
  }  

  public subscribtion(): void {
    this.subscriptionFilterPricings = this.uiSyncService.filterPricings.subscribe(() => {
      this.filterPricings();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionFilterPricings) {
      this.subscriptionFilterPricings.unsubscribe();
    }

  }

  public async filterPricings(): Promise<void> {
    try {
      this.rightColService.applciationItemDetailloadingIndicator = true; 
      this.uiSyncService.changeDetector.emit();
      this.formStackService.CurrentApplicationItemDetailData = await this.pricingCustomService.getDetailsData(this.type, this.data);
      this.formStackService.CurrentApplicationItemDetailDataReadonly = false;
      this.uiSyncService.updateGrid.emit();
    } catch (error) {
      this.rightColService.applciationItemDetailloadingIndicator = false; 
    }
  }
}

