<ul *ngIf="uisyncService.currentBreadCrumb.length"> 
  <li *ngFor="let item of uisyncService.currentBreadCrumb; index as i">    
    <lp-ui-popover-info *ngIf="item[1].currentInfosBreadcrumb.length > 0" [tableTooltip]="item[1].currentInfosBreadcrumb" placement="right" contentTooltip="<h4>{{'breadcrumb.titlePopover.'+item[1].currentVerb |translate}}</h4>">
      <a class="item" (click)="click(item[1], item[0], i)">
        <i class="icon {{item[1].currentApplicationItem.classes}}"></i>  
      </a>
    </lp-ui-popover-info>
    <a *ngIf="item[1].currentInfosBreadcrumb.length === 0" class="item" (click)="click(item[1], item[0], i)">
      <i class="icon {{item[1].currentApplicationItem.classes}}"></i>
    </a>
    <div *ngIf="change.getHasChanged(i) || change.areDetailsChanged2()" class="cursor-pointer notsave" title="{{'general.modificationNotSave' | translate}}">*</div>    
    <span class="sep">></span>
  </li>
</ul>