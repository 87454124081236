import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'lp-button-cancel',
  templateUrl: './lp-button-cancel.component.html'
})
export class LpButtonCancelComponent {
  @Output() public action: EventEmitter<any> = new EventEmitter();
  @Input() public title: String = 'general.button.close';

  constructor() { }

}
