import { LpMeta, propertyMeta } from './meta';
import { LpAddress } from './address';
import { FtMeta } from './ft-meta';
import { LpCompany } from './company';
import { Type } from 'class-transformer';

export class LpLocation extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpAddress)
    public mainAddress: LpAddress = new LpAddress();
    @propertyMeta('string')
    public inChargeOf: String;
    @propertyMeta('string')
    public documentNumber: String;
    @Type(() => FtMeta)
    public vatDomain: FtMeta = new FtMeta();
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('boolean')
    public isExternal: Boolean;
    @propertyMeta('string')
    public word: String;
    @propertyMeta('number')
    public latitude: Number;
    @propertyMeta('number')
    public longitude: Number;

    protected _unoptimizedKeys: String[] = ['mainAddress'];

}


