import { Injectable } from '@angular/core';
import { FtParams } from 'app/meta/ft';
import { ApplicationItemDetailColumn } from 'app/models/application-item-detail';
import { PaginatedData } from 'app/models/paginated-data';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { FormStackService } from './form-stack.service';
import { MainEditForm } from '../models/main-detail-forms';
import { Accordion } from 'app/models/accordion';
import { EditRows } from 'app/models/edit-rows';
import { InputField } from 'app/models/input-field';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { Util } from 'app/statics/utils';
import { ZoomConfig } from 'app/models/zoom-metada';
import * as _ from 'lodash';
import { LpMeta } from 'app/meta/meta';
import { ZoomProvider } from 'app/providers/zoom.provider';

const CHKB: string = 'CHKB'
const INTEGER: string = 'INTEGER';
const CURRENCY: string = 'CURRENCY';
const DATE: string = 'DATE';
const DATETIME: string = 'DATETIME';
const STRING: string = 'STRING';
const ZMLST: string = 'ZMLST';
export const EXECUTE: string = '/execute'
const PARAMETERS: string = '/parameters'
@Injectable({
  providedIn: 'root'
})
export class BulkService {

  public searchCriterias: Map <string, string> = new Map();
  public verb: string;
  public ky: string;
  public mapCache: Map<String, any> = new Map;
  public linesPerPage: Number;

  constructor(private formstackService: FormStackService,
              private alocproProvider: AlocproProvider,
              private repositoryProvider: RepositoryProvider,
              private zoomProvider: ZoomProvider) { }

  public async bulkConfig(verb: string, ky: string): Promise<Array<FtParams>> {
    //this.searchCriterias.clear();
    this.verb = verb;
    this.ky = ky
    let params: Array<FtParams> = await this.getParams(verb, ky);
    this.formstackService.currentApplicationItem.useJsonEditForm = this.hasVisibleCriterias(params)
    this.formstackService.currentApplicationItem.childZoom = []
    if (this.formstackService.currentApplicationItem.useJsonEditForm) {
      this.formstackService.currentApplicationItem.editForm = this.buildMain(params);
    }
    this.formstackService.currentApplicationItemDetail =
      await this.repositoryProvider.getApplicationItemDetails(this.formstackService.currentApplicationItem.details[0].key.toString())
    this.formstackService.currentApplicationItemDetail.columns = this.buildDetailsColumns(params);
    this.formstackService.currentApplicationItemDetail.pagination = true;
    this.formstackService.currentApplicationItemDetail.path = verb + '/' + ky;
    return params;
  }

  public async getParams(verb: string, ky: string): Promise<Array<any>> {
    const tempd: PaginatedData = await this.alocproProvider.getPaginatedData(`${verb}/${ky}${PARAMETERS}`);
    return tempd.body;
  }

  public async getData(verb: string, ky: string, arrayOfDataKey?: Array<string>, page?: Number, linesPerPage?: Number,config?: Array<FtParams>): Promise<LpMeta[]>  {
    if (!Util.isNullOrUndefined(arrayOfDataKey)) {
      this.searchCriterias = this.formstackService.hydrateMapFromArrayOfKey(arrayOfDataKey)
    }
    if(Util.isNullOrUndefined(page)){
      page = 1;
    }
    if(Util.isNullOrUndefined(linesPerPage)){
      linesPerPage = this.linesPerPage;
    }
    const tempd: PaginatedData = await this.alocproProvider.getPaginatedData(`${verb}/${ky}${EXECUTE}`, page, this.searchCriterias
    , null, linesPerPage, null, null, null, true);
    this.formstackService.CurrentApplicationItemDetailDataCount = tempd.count;
    if(!Util.isNullOrUndefined(tempd) && !Util.isNullOrUndefined(tempd.body) && !Util.isNullOrUndefined(config)){
      await this.hydrateData(tempd.body, config);
    }

    return Promise.resolve(tempd.body);
  }

  public clearCache(): void {
    this.mapCache.clear();
  }

  private buildMain(params: Array<FtParams>): MainEditForm {
    let form: any = {};
    form.active = true;
    form.availableEval = '';
    form.id = params[0].id

    form.accordions = [this.generateAccordion(params)];
    let f: MainEditForm = form;
    return f;
  }

  private generateAccordion(params: Array<FtParams>): Accordion {
    let accordion: any = {};
    accordion.columns = 1;
    accordion.rows = [];
    for (let p of params) {
      if (!Util.isNullOrUndefined(p.isReadOnly) && !p.isReadOnly) {
        accordion.rows.push(this.buildFields(p));
      }
    }
    let a: Accordion = accordion;
    return a
  }

  private buildFields(p: FtParams): EditRows {
    let row: any = {};
    row.columns = 1;
    let input: InputField = {
      'prop': p.paramName,
      'label': p.wording,
      'readonly': p.isReadOnly,
      'mandatory': p.isMandatory,
      'defaultValue': '',
      'defaultValueEval': '',
      'afterBindEval': '',
      'type': this.getTextType(p.type),
      'params': {
        'ignoreChange': true
      }
    }
    if (input.type === 'zoom') {
      input.params['zoomId'] = p.attribute
      this.addChildZoomConfig(p, false, false)
    }
    row.fields = [input];
    let r: EditRows = row;
    return r;
  }

  private buildDetailsColumns(params: Array<FtParams>): Array<ApplicationItemDetailColumn> {
    let finalColumns: Array<ApplicationItemDetailColumn> = [];
    for (let i in params) {
      let col: ApplicationItemDetailColumn = this.buildColumn(params[i]);
      if (col.type === 0 && !Util.isNullOrUndefined(params[i].resource)) {
        this.addChildZoomConfig(params[i], false, true)
      } else {
        this.addChildZoomConfig(params[i], true, true)
      }
      finalColumns.push(col);
    }
    return finalColumns;
  }

  private buildColumn(p: FtParams): ApplicationItemDetailColumn {
    let col: any = {};
    col.isDisplayed = p.isHidden;
    col.name = p.attribute;
    col.prop = p.paramName;
    col.type = this.getNumberType(p.type);
    col.modify = true;
    col.wording = p.wording;
    if (col.type === 0) {
      col.params = {};
      col.params.zoomId = ''  + p.attribute
    }
    // col.inputField = buildInputField(c);
    let c: ApplicationItemDetailColumn = col;
    return c;
  }

  private getNumberType(locproType: String): number {
    if (locproType === CHKB) {
      return 7;
    } else if (locproType === INTEGER) {
      return 2;
    } else if (locproType === CURRENCY) {
      return 10;
    } else if (locproType === DATE) {
      return 4;
    } else if (locproType === DATETIME) {
      return 5;
    } else if (locproType === STRING) {
      return 1;
    } else if (locproType === ZMLST) {
      return 0;
    } else if (locproType === null || locproType === undefined) {
      return 1;
    } else {
      return 1;
    }
  }

  private getTextType(locproType: String): string {
    if (locproType === CHKB) {
      return 'boolean';
    } else if (locproType === INTEGER) {
      return 'number';
    } else if (locproType === CURRENCY) {
      return 'currency';
    } else if (locproType === DATE) {
      return 'date';
    } else if (locproType === DATETIME) {
      return 'datetime';
    } else if (locproType === STRING) {
      return 'text';
    } else if (locproType === ZMLST) {
      return 'zoom';
    } else if (locproType === null || locproType === undefined) {
      return 'text';
    } else {
      return 'text';
    }
  }

  private hasVisibleCriterias(parameters: Array<FtParams>): boolean {
    for (let p of parameters) {
       if (!p.isReadOnly) {
         return true
       }
     }
     return false;
  }

  private addChildZoomConfig(param: FtParams, isString: boolean, isDetailsZoom: boolean): void {
    if (!this.configExists(isDetailsZoom ? 'details_' + param.attribute : param.attribute)) {
      let zoomConfig: ZoomConfig = {
        'isFromChild': false,
         'kySearch': 'id',
         'addModifyVerb': (Util.isNullOrUndefined(param.resource) ? null:param.resource),
         'inputSearch': 'id',
         'searchTextField': 'id',
         'tableBindValues':  ['id'],
         'tableShowField': ['wording'],
         'urlNew': null,
         'bindingProp': param.paramName,
         'historyKey': 'H_oh2ojf',
         'modal': true,
         'isScheduler': false,
         'title': 'test',
         'textSearch': 'id',
         'isClosable': true,
         'showLpFieldDetails': false,
         'showAddButton': false,
         'showModifyButton': false,
         'zoomId': (isDetailsZoom ? 'details_' + param.attribute : param.attribute),
         'isLocProZoom': true,
         'locProZoomObject': 'zoom',
         'locProZoomAttribut': param.attribute,
         'bindingValueKeys': null,
         'selectedDataKeys': null,
         'disabled': null,
         'isString': false,
         'parentZoomBindFields': [],
         'parentTableBindValues': [],
         'id': null,
         'linesPerPage': null,
         'isMainZoom': null,
         'displayAddButton': null
       }
       this.formstackService.currentApplicationItem.childZoom.push(zoomConfig);
    }
  }

  private configExists(zoomId: string): boolean {
    for (let z of this.formstackService.currentApplicationItem.childZoom) {
      if (z.zoomId === zoomId) {
        return true;
      }
    }
    return false;
  } 

  private async hydrateData(data: Array<LpMeta>, config: Array<FtParams>): Promise<Array<LpMeta>> {
    for(let c of config){
      if (c.type === ZMLST){
        for(let d of data){
          if(!Util.isNullOrUndefined(c.resource) && !Util.isNullOrUndefined(c.paramName) && !Util.isNullOrUndefined(d[c.paramName])){
            let mapKey: string = `${c.resource}/${d[c.paramName]}`;
            if(!this.existInCache(mapKey)){
              d[c.paramName] = await this.alocproProvider.find(c.resource, d[c.paramName])
              this.setDataInCache(d[c.paramName], mapKey)
            } else {
              d[c.paramName] = this.mapCache.get(mapKey);
            }
          }
          if(Util.isNullOrUndefined(c.resource) && !Util.isNullOrUndefined(c.paramName) && !Util.isNullOrUndefined(d[c.paramName])){
            let mapKey: string = `zoom-link/attribute=${c.attribute}&object=zoom`;
            let ftData: PaginatedData;
            if(!this.existInCache(mapKey)){
              ftData = await this.zoomProvider.getZoomFullDataList(c.attribute, 'zoom');
              this.setDataInCache(ftData, mapKey)
            } else {
              ftData = this.mapCache.get(mapKey);
            }
            d[c.paramName] = this.searchInDatas(d[c.paramName], ftData.body);
          }
        }
      }
    }
    return data;
  }

  private setDataInCache(data: any, path: String): void {
    if (!this.mapCache.has(path)) {
      let temp: any = {};
      Object.assign(temp, data);
      this.mapCache.set(path, temp);
    }
  }

  public existInCache(path: String): boolean {
    if (Util.isNullOrUndefined(path)) {
      return false;
    } else {
      return this.mapCache.has(path);
    }
  }

  private searchInDatas(id: string, data: Array<LpMeta>): any {
    try {
      if ( !Util.isNullOrUndefined(id) && !Util.isNullOrUndefined(data)) {
        let line: LpMeta = _.find(data, (item: LpMeta) => {
          if ( item['field0'] === undefined && !Util.isNullOrUndefined(item.id)) {
            // cas où l'on reçoit data.body = [{id: , wording: }]
            return ( item.id === id || item['wording'] === id);
          } else {
            // cas où l'on reçoit data.body = [{field0: , field1: }]
            return ( item['field0'] === id || item['field1'] === id);
          }
        });
        return {'id': line['field0'], 'wording': line['field1'], }

      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
