<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.thirdPartyLinks.titleTab" [open]=true>
      <form id="formThirdPartyLinks" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

        <div class="form-group col-xl-12">
          <lp-label-field for="thirdPartyLinks.thirdParty" class="{{arrayClassesElements.get('defaultLabel')}}"
              text="damages.thirdParty"></lp-label-field>
              <lp-zoom-field id="thirdPartyLinks.thirdParty" class="{{arrayClassesElements.get('defaultInput')}}"className="zoomField"
              cssClass="form-control" [(ngModel)]="data.LpThirdParty" name="thirdPartyLinks.thirdParty" zoomId="thirdPartyLinks.thirdParty"> </lp-zoom-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="thirdPartyLinks.linkType" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.linkType"></lp-label-field>
          <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="thirdPartyLinks.linkType" [(ngModel)]="data.linkType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'linkThirdPartyType'}]}">
          </lp-dropdown-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="thirdPartyLinks.reference" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.reference"></lp-label-field>
          <lp-text-field id="thirdPartyLinks.reference" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="thirdPartyLinks.reference"
            [(ngModel)]="data.reference" placeholder="damages.reference"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="thirdPartyLinks.date" class="{{arrayClassesElements.get('defaultLabel')}}" text="accountingEvents.date"></lp-label-field>
          <lp-date [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}" id="thirdPartyLinks.date"
          cssClass="form-control" name="thirdPartyLinks.date" placeholder="accountingEvents.date"
          [required]=true></lp-date>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="thirdPartyLinks.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wording"></lp-label-field>
          <lp-text-field id="thirdPartyLinks.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="thirdPartyLinks.wording"
            [(ngModel)]="data.wording" placeholder="damages.wording"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="thirdPartyLinks.attachment" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.attachment"></lp-label-field>
          <lp-text-field id="thirdPartyLinks.attachment" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="thirdPartyLinks.attachment"
            [(ngModel)]="data.attachment" placeholder="damages.attachment"></lp-text-field>
        </div>

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>