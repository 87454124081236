import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent } from 'app/DetailForms/detail-forms.component';
import { Util } from 'app/statics/utils';
import { InputField } from 'app/models/input-field';

@Component({
  selector: 'lp-dynamic-detail-form',
  templateUrl: './dynamic-detail-form.component.html',
  styleUrls: ['./dynamic-detail-form.component.scss']
})
export class DynamicDetailFormComponent extends DetailFormsComponent implements OnInit {

  // permet pour les detail d'avoir un nam + id en html avec le nom de du detail additionnalDriver.
  // ou encore invoiceLine. (notemment util pour identifier un champ en custom-code)
  public detailId: string;

  public ngOnInit(): void {
    this.detailId = this.formStackService.currentApplicationItemDetail.editForm.id + '.'
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(this.formStackService.currentApplicationItemDetail.editForm.id);
    this.initUrl();
  }

  public isMultipleInputPerLine(i: number, fields: Array<InputField>): boolean {
    return Util.isNullOrUndefined(fields[i].label);
  }

  public getRowSize(i: number, fields: Array<InputField>): string {
    let total: number = 0
    let labelIndex: number = this.getIndexOfPreviousLabel(i - 1 , fields);
    let k: number = labelIndex + 1;
    while (Util.isNullOrUndefined(fields[k].label)) {
      total += 1;
      k += 1;
    }
    return Math.ceil(12 / total).toString();
  }

  public getClasses(i: number, f: InputField): string {
    if(f.classes != null && f.classes !== undefined) {
      return 'form-group '+f.classes;
    } else if (this.isMultipleInputPerLine(i, this.formStackService.currentApplicationItemDetail.editForm.fields)) {
      return 'form-group col-xl-' + this.getRowSize(i, this.formStackService.currentApplicationItemDetail.editForm.fields);
    } else {
      return 'form-group col-xl-12';
    }
  }

  private getIndexOfPreviousLabel(index: number, fields: Array<InputField>): number {
    let j: number = 0;
    let i: number = index
    while (fields[i].type !== 'label') {
      j += 1;
      i -= 1
    }
    return index - j;
  }
}
