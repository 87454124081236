import { LpMeta, propertyMeta } from './meta';
import { LpPricing } from './pricing';
import { Type } from 'class-transformer';

export class LpPricingTime extends LpMeta {
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('date')
    public endDate: Date;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => LpPricing)
    public pricing: LpPricing = new LpPricing();
    @propertyMeta('string')
    public index: String;
    @propertyMeta('string')
    public relatedPeriod: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('boolean')
    public priority: Boolean;
}
