import { LpCurrency } from './currency';
import { LpMeta, propertyMeta } from './meta';
import { LpPaymentMethod } from './payment-method';
import { LpThirdParty } from './third-party';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';

export class LpExpense extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('number')
    public fiscalPower: Number;
    @Type(() => FtMeta)
    public service: FtMeta = new FtMeta();
    @Type(() => LpCurrency)
    public currency: LpCurrency = new LpCurrency();
    @propertyMeta('date')
    public date: Date;
    public state: FtMeta = new FtMeta();
    @propertyMeta('number')
    public amount: Number;
    @propertyMeta('boolean')
    public billable: Boolean;
    @propertyMeta('number')
    public kilometers: Number;
    @propertyMeta('string')
    public title: String;
    @Type(() => LpPaymentMethod)
    public paymentMethod: LpPaymentMethod = new LpPaymentMethod();
    @Type(() => LpThirdParty)
    public customer: LpThirdParty = new LpThirdParty();
    @propertyMeta('string')
    public customerReference: String;
    @propertyMeta('string')
    public picture: String;
    @propertyMeta('string')
    public txt: String;
    @Type(() => LpThirdParty)
    public employee: LpThirdParty = new LpThirdParty();
}
