import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LpShortTermContract } from 'app/meta/short-term-contract';
import { ApplicationItemDetail } from 'app/models/application-item-detail';
import { PaginatedData } from 'app/models/paginated-data';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { ConfigService } from 'app/services/config.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { JsdataService } from 'app/services/jsdata.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { MultiProductsService } from 'app/services/multi-products.service';
import { PricingService } from 'app/services/pricing.service';
import { FormService } from 'app/services/root/form.service';
import { RightColService } from 'app/services/root/rightCol.service';
import { RouterService } from 'app/services/router.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { UserService } from 'app/services/user.service';
import { Util } from 'app/statics/utils';
import { lastValueFrom } from 'rxjs';

const NEW: string = 'new';
export const NEW_CONTRACT: string = 'multiproduct.new';
const LOADING: string = 'multiproduct.loading';
const LABEL: string = 'id';
@Component({
  selector: 'lp-switcher',
  templateUrl: './lp-switcher.component.html'
})
export class LpSwitcherComponent implements OnInit, OnDestroy, AfterViewChecked {

  public stackFormStackService: Map<string, FormStackService> = new Map<string, FormStackService>();
  public list: PaginatedData = {count: 0, body: [], _readonly: false};
  public label: string = LABEL;
  public displaySwitcher: boolean = false;
  public displayAddButtom: boolean = true;
  public displayWheel: boolean = true;
  public newContractId: string = NEW_CONTRACT;
  public loading: string = LOADING;
  public groupingCode: string = '';
  public isToLong:Boolean = false;
  
  @ViewChild('container') public container: ElementRef;

  constructor(
    private formStackService: FormStackService,
    private multiProductsService: MultiProductsService,
    private formService: FormService,
    private uiSyncService: UiSyncService,
    private applicationItemProvider: ApplicationItemProvider,
    public ihmService: IhmStackService,
    private formMetadataProvider: FormMetadataProvider,
    private alocproProvider: AlocproProvider,
    private routerService: RouterService,
    private pricingService: PricingService,
    private jsdataService: JsdataService,
    private lpFactory: MetaFactoryService,
    private configService: ConfigService,
    public rightColService: RightColService,
    public translateService: TranslateService,
    private userService: UserService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.init();
  }

  public ngOnDestroy(): void {
    this.eraseFormStackService();
  }

  public ngAfterViewChecked(): void {    
    if (this.container == undefined) {
      return;
    }
    let w:number = 0;

    // Loop over all elements and set sum of widths for each menu item
    for (const eachChild of this.container.nativeElement.children) {
      w += eachChild.clientWidth;
    }
    
    if (w > this.container.nativeElement.clientWidth) {
      this.isToLong = true;
    }
  }

  public async selectItem(label: any, index: number): Promise<void> {
    this.displayWheel = true;
    if (await this.multiProductsService.detectChange()) {
      if (label === NEW_CONTRACT) {
        await this.switchToNew(label, index);
      } else {
        await this.switchToOther(label, index);
      }
    }
    this.ihmService.ShowSwitcher = true;
    setTimeout(() => {
      this.displayWheel = false;
    }, 2000)
  }

  public async saveNewContract(): Promise<void> {
    let translation: any = await lastValueFrom(this.translateService.get('loading.data'));
    this.uiSyncService.loader(true, true, translation);
    //this.displayWheel = true;
    let newStc: LpShortTermContract = new LpShortTermContract();
    newStc = this.formStackService.currentData as LpShortTermContract;
    const newKy: string = await this.multiProductsService.postShortTermContractForMultiProducts(this.groupingCode,newStc);
    if (!Util.isNullOrUndefined(newKy)) {
      await this.formService.build(this.formStackService.CurrentVerb, newKy);
      this.list = await this.multiProductsService.getShortTermContractsList(this.formStackService.currentData['groupingCode']);
    }
    //this.displayWheel = false;
    this.uiSyncService.loader(false);
  }

  private async init(): Promise<void> {
    if (this.formStackService && this.formStackService.CurrentKy && this.formStackService.CurrentKy !== NEW) {
      this.list = await this.multiProductsService.getShortTermContractsList(this.formStackService.currentData['groupingCode']);
      this.displayComponent();
      this.stackFormStackService.set(this.createNewString(this.formStackService.CurrentKy), {...this.formStackService} as FormStackService);
      this.displayWheel = false;
      this.groupingCode = this.formStackService.currentData['groupingCode'];
      this.multiProductsService.list = this.list;
    }
  }

  private displayComponent(): void {
    if (!Util.isNullOrUndefined(this.list) && !Util.isNullOrUndefined(this.list.body) && this.list.body.length > 1) {
      this.displaySwitcher = true;
      this.ihmService.ShowSwitcher = true;
    } else {
      this.displaySwitcher = false;
    } 
  }

  private async switchToOther(id: string, index: number): Promise<void> {
    await this.switch();
    // je charge l'ecran
    await this.formService.build(this.formStackService.CurrentVerb, id);
    //sauvegarde du formStack avec le formStackService mis à jour pendant le build
    this.stackFormStackService.set(this.createNewString(this.formStackService.CurrentKy), {...this.formStackService} as FormStackService);
    //Object.assign(this.formStackService.currentData, {...this.list.body[index]}); 
    this.uiSyncService.refreshDynamicMainForm();
  }

  private async switchToNew(id: string, index: number): Promise<void> {
    const oldFormStack: FormStackService = await this.switch();
    // je charge la nouvelle config
    Object.assign(this.formStackService, {...this.stackFormStackService.get(id)});
    await this.formService.build(this.formStackService.CurrentVerb, NEW, null, false);
    Object.assign(this.formStackService.currentData, {...this.list.body[index]}); 
    this.formStackService.currentDetailsCount = new Map<String, Number>();
    this.formStackService.currentApplicationItem.details = await this.defineEnableDetailToFalse(this.formStackService.currentApplicationItem.details);   
    this.multiProductsService.assignDatas(oldFormStack);
    this.uiSyncService.refreshDynamicMainForm();
  }

  public async createLink(): Promise<void> {
    //sauvegarde des anciennes données
    // je viens save l'ancien formstack, pour mettre à jour certaines infos qui disparaissent
    let oldFormStack: FormStackService = this.createNewFormStackService(this.formStackService);
    let kyTemp: string = '';
    let currentDetailsDataCount: Map<String, Number> = new Map<String, Number>(this.formStackService.currentDetailsCount);
    kyTemp = this.createNewString(this.formStackService.CurrentKy);
    oldFormStack.currentDetailsCount = currentDetailsDataCount;
    this.stackFormStackService.set(kyTemp, {...oldFormStack} as FormStackService);

    // je cré le nouveau formstackservice pour le NEW
    let newFormStack: FormStackService = this.createNewFormStackService(this.formStackService);
    const newShortTermContract: LpShortTermContract = new LpShortTermContract();
    newShortTermContract.id = NEW_CONTRACT;
    newFormStack.currentData = newShortTermContract;
    newFormStack.CurrentKy = NEW;
    newFormStack['currentKy'] = NEW;
    newFormStack['currentApplicationItemDetailDataCount'] = 0;
    newFormStack.CurrentApplicationItemDetailData = [];
    newFormStack.currentDetailsCount = new Map<String, Number>();
    newFormStack.currentApplicationItem.details = await this.defineEnableDetailToFalse(this.formStackService.currentApplicationItem.details)

    // j'ajout le STC dans la liste de données
    this.list.body.push({...newShortTermContract} as LpShortTermContract);

    // je set et je build le new
    await this.formService.build(this.formStackService.CurrentVerb, NEW); 
    this.stackFormStackService.set(NEW_CONTRACT, {...newFormStack} as FormStackService);
    Object.assign(this.formStackService, {...this.stackFormStackService.get(NEW_CONTRACT)});   
    this.multiProductsService.assignDatas(oldFormStack);
    this.uiSyncService.refreshDynamicMainForm();
    this.displayAddButtom = false;
    this.ihmService.ShowSwitcher = true;
  }

  private async defineEnableDetailToFalse(details: Array<ApplicationItemDetail>): Promise<Array<ApplicationItemDetail>> {
    // je remets les details à 0  
    if ( details && details.length !== 0 ) {
        for (let i: number = 0; i < details.length; i++) {
          details[i].enable = false;
          details[i].active = false;
          details[i]._ready = true;
      }
      let newDetails: Array<ApplicationItemDetail>= new Array<ApplicationItemDetail>();
      Object.assign(newDetails, {...details});
      return newDetails;
    }
  }

  private eraseFormStackService(): void {
    this.stackFormStackService.clear();
    this.ihmService.ShowSwitcher = false;
  }

  private createNewString(originalString: string): string {
    return (' ' + originalString).slice(1);
  }

  private createNewFormStackService(originalFormStackService: FormStackService): FormStackService {
    let newFormStackService: FormStackService = new FormStackService(
      this.applicationItemProvider, 
      this.ihmService, 
      this.formMetadataProvider, 
      this.alocproProvider, 
      this.routerService, 
      this.pricingService, 
      this.jsdataService, 
      this.lpFactory, 
      this.configService,
      this.userService);
      Object.assign(newFormStackService, {...originalFormStackService} as FormStackService);
      return newFormStackService;
  }

  private async switch(): Promise<FormStackService> {
    // je sauvegarde l'ancienne config
    let oldFormStack = new FormStackService(
      this.applicationItemProvider, 
      this.ihmService, 
      this.formMetadataProvider, 
      this.alocproProvider, 
      this.routerService, 
      this.pricingService, 
      this.jsdataService, 
      this.lpFactory, 
      this.configService,
      this.userService);

    // je sauvegarde l'ancien 
    oldFormStack.currentData = new LpShortTermContract();
    Object.assign(oldFormStack, {...this.formStackService});
    if ( this.formStackService.CurrentKy === NEW ) {
      this.stackFormStackService.set(NEW_CONTRACT, {...oldFormStack} as FormStackService);
    } else {
      this.stackFormStackService.set(this.createNewString(this.formStackService.CurrentKy), {...oldFormStack} as FormStackService);
    }
    return oldFormStack;
  }

}
