
import { ZoomProvider } from './zoom.provider';
import { WidgetInterface } from 'app/models/widget';
import { Injectable } from '@angular/core';
import { RepositoryProvider } from './repository.provider';
import { Dashboard } from 'app/models/dashboard';

@Injectable({
    providedIn: 'root'
})

export class DashboardProvider {

    constructor(
        private dataProvider: ZoomProvider,
        private configProvider: RepositoryProvider) {};

    public async getDashboardConfiguration(dashboardId: string): Promise<Dashboard> {
        return await this.configProvider.getDashboardConfiguration(dashboardId);
    }

    public async getMyDashboardLevel(): Promise<Dashboard> {
        return await this.configProvider.getMyDashboardLevel();
    }

    public async getData(dashboardId: string, type: Number): Promise<any> {
        return await this.dataProvider.getWidgetData(dashboardId, type);
    }

    public async getWidgetConfiguration(dashboardId: string ): Promise<WidgetInterface> {
      return await this.configProvider.getWidgetConfig(dashboardId);
    }

    public async putMyDashboardConfiguration(dashboardId: string, data: any): Promise<WidgetInterface> {
        return await this.configProvider.put('dashboards', dashboardId, data);
    }
}
