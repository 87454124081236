import { Injectable } from '@angular/core';
import { FormStackService, SCREEN_TEST } from '../form-stack.service';
import { LpMeta } from 'app/meta/meta';
import { ApplicationItemDetail} from 'app/models/application-item-detail';
import { Util } from 'app/statics/utils';
import { PaginatedData } from 'app/models/paginated-data';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { UiSyncService } from '../ui-sync.service';
import * as _ from 'underscore'
import { IhmStackService } from '../ihm-stack.service';
import { EvalService, INIT_NEW_EVENT, BEFORE_GET_EVENT, AFTER_GET_EVENT, BEFORE_INSERT_EVENT, BEFORE_UPDATE_EVENT } from '../eval.service';
import { UserService } from '../user.service';
import { InputField, INPUT_FIELD_TYPE_LABEL } from 'app/models/input-field';
import { ExampleDetailsProvider } from 'app/Forms/examples/provider/metadata/examples.details.provider';
import { ChangeService } from '../change.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../modal.service';
import { ConfigService } from '../config.service';
import { UserHistoryService } from '../user-history.service';
import { RepositoryProvider } from 'app/providers/repository.provider';

@Injectable({
  providedIn: 'root'
})
export class RightColService {
    public applciationItemDetailloadingIndicator: Boolean = false;

    /**
     * permet d'afficher la roue crantée à la place de l'icon de l'AID en cours de chargement dans le top banner.
    */
    public currentApplicationItemDetailLoading: Number;
    public currentApplicationItemDetailTitle: string = '""';

    constructor (
        private ihmStack: IhmStackService,
        private formStackService: FormStackService,
        private alocProProvider: AlocproProvider,
        private uiSyncService: UiSyncService,
        private evalService: EvalService,
        private userService: UserService,
        private  examplesDetailsProvider: ExampleDetailsProvider,
        private changeService: ChangeService,
        private translateService: TranslateService,
        private modalService: ModalService,
        private configService: ConfigService,
        private userHstoryService: UserHistoryService,
        private repositoryProvider: RepositoryProvider
    ) {}

    public displayRightCol(detail?: ApplicationItemDetail, data?: LpMeta): void {
        if (this.ihmStack.ShowZoomFieldDetail) {
            this.displayZoomFieldDetail();
        } else if (this.ihmStack.ShowSubAPIDetailsForm) {
            this.displaySubAPIDetailsTab(detail, data);
        } else if (this.ihmStack.ShowSubAPIDetailsTable) {

        } else if (this.ihmStack.ShowRightZoomComponent) {

        } else if (this.ihmStack.ShowReport) {

        }
    }

    public displaySubAPIDetailsTab(detail: ApplicationItemDetail, data?: LpMeta): void {
        if (!detail.bulk) {
            this.changeService.clearDetailsChanged2();
            this.applciationItemDetailloadingIndicator = true;
            this.formStackService.currentApplicationItemDetail = detail;
            this.formStackService.CurrentApplicationItemDetailData = null;
            this.formStackService.CurrentApplicationItemDetailDataCount = 0;
            if (!Util.isNullOrUndefined(data)) {
                this.changeDetailData(detail.id, data);
            }
        }
        this.setDetailTitle(detail);
        this.ihmStack.displaySubAPIDetails();
    }

    public displaySubAPIDetailsForm(): void {
        this.ihmStack.displaySubAPIDetailsForm();
    }

    public async displayOrEditSubApiDetail(detailLineId: string, detailLineDefaultData: LpMeta): Promise<void> {
       if (detailLineId !== null && detailLineId !== undefined) {
            if (this.formStackService.CurrentVerb === SCREEN_TEST) {
                this.formStackService.currentDetailLineData.assign(this.formStackService.CurrentApplicationItemDetailData[detailLineId]);
            } else {
                await this.showExistingLine(detailLineId)
            }
        } else {
            await this.reinitLineValue();
        }
        this.uiSyncService.loadObject();
        this.ihmStack.displaySubAPIDetailsForm();
        this.uiSyncService.emitAvailableEvalForJsonInputField();
        this.uiSyncService.loader(false);     
        
        /* setimout obligatoire le temps d'apparition du bloc dans le DOM */
        if(this.formStackService.CurrentApplicationItemDetail.editForm !== undefined) {
            setTimeout(() => {
                this.uiSyncService.scrollTo(this.formStackService.CurrentApplicationItemDetail.editForm.id.toString());
            }, 0);              
        }

    }

    public displayRightZoomComponent(): void {
        this.ihmStack.displayRightZoomComponent();
    }

    public displayZoomFieldDetail(): void {
        this.ihmStack.displayZoomFieldDetail();
    }

    public setApplictionItemDetailFooter(detailId: Number): void {
        let applicationItemDetail: ApplicationItemDetail;
        for (let i: number = 0; i < this.formStackService.currentApplicationItem.details.length; i++) {
            if (+this.formStackService.currentApplicationItem.details[i].id === detailId) {
                applicationItemDetail = this.formStackService.currentApplicationItem.details[i];
            }
        }
        if (!Util.isNullOrUndefined(applicationItemDetail) && applicationItemDetail.TableSummaryRow) {
            if (!Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData)
                        && this.formStackService.CurrentApplicationItemDetailData.length > 0) {
                if ((_.last(this.formStackService.CurrentApplicationItemDetailData) as LpMeta).id === 'footer_table') {
                    this.formStackService.CurrentApplicationItemDetailData =
                            this.formStackService.CurrentApplicationItemDetailData.slice(0, -1);
                }
                this.formStackService.CurrentApplicationItemDetailData.push(this.computeFooter(applicationItemDetail));
            }
        }
    }

    public async submitDetail(data: LpMeta, bulkData?: Array<LpMeta>): Promise<void> {
        if (this.formStackService.currentApplicationItemDetail.bulk && bulkData !== null && bulkData !== undefined) {
            await this.bulkDetail(bulkData, data.id.toString());
        } else if (!Util.isNullOrUndefined(data) && !Util.isNullOrUndefined(data.id)) {
            await this.updateDetail(data);
            await this.evalService.executeContrainte(
                BEFORE_UPDATE_EVENT,  this.formStackService.currentApplicationItemDetail.key.toString(),
                this.formStackService.currentApplicationItem.ecrId.toString(), '*', data,
                this.formStackService.currentFields, null, this.formStackService.currentData);            
        } else {
            await this.evalService.executeContrainte(
                BEFORE_INSERT_EVENT,  this.formStackService.currentApplicationItemDetail.key.toString(),
                this.formStackService.currentApplicationItem.ecrId.toString(), '*', data,
                this.formStackService.currentFields, null, this.formStackService.currentData);
            await this.createDetail(data);
        }
    }

    public closeAll(): void {
        this.ihmStack.hideAll();
    }
    
  public displaySubApi(): boolean {
    return !Util.isNullOrUndefined(this.formStackService) && !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail)
          && (this.ihmStack.ShowSubAPIDetailsForm || this.formStackService.currentApplicationItemDetail.editFormActive === true)
          && !this.ihmStack.ShowRightZoomComponent
          && this.formStackService.CurrentKy !== this.configService.get('newdataKeyWord')
          && !this.formStackService.currentApplicationItemDetail.hidden
  }

    
  public displayReportButton(): boolean {
    return !this.displaySubApi();
  }

  public hideSubApi() {
    this.ihmStack.ShowSubAPIDetailsForm = false;
  }

  public CurrentApplicationItemDetailTitle() {
      return this.currentApplicationItemDetailTitle;
  }

  public async saveDetailRepository(path: string): Promise<void> {
    let url: string = '';
    let result: any;
    if (!Util.isNullOrUndefined(path)) {
        url = path
    } else{
        url = this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.formStackService.CurrentApplicationItemDetailRepositoryData);
    }
    if (
        !Util.isNullOrUndefined(this.formStackService) &&
        !Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailRepositoryData) && 
        Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailRepositoryData.id)
        ) {
        result = await this.repositoryProvider.post(url, this.formStackService.CurrentApplicationItemDetailRepositoryData);
        result = result.body;
        result.id = result.agencyId;
    } else if (
        !Util.isNullOrUndefined(this.formStackService) &&
        !Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailRepositoryData) && 
        !Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailRepositoryData.id)
        ) {
        result= await this.repositoryProvider.put(url, null, this.formStackService.CurrentApplicationItemDetailRepositoryData)
        result.id = result.agencyId;
    }
    this.modalService.success(this.translateService.instant('general.modalService.recordingDone'), this.translateService.instant('general.modalService.success'));
    await this.loadDetail(null, result);
  }

  public async delete(): Promise<void> {
    let url: string  = this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.formStackService.CurrentApplicationItemDetailRepositoryData);
    await this.repositoryProvider.delete(url, null)
    this.modalService.success(this.translateService.instant('general.modalService.recordingDone'), this.translateService.instant('general.modalService.success'));
    await this.loadDetail(null, { id: "*" } as unknown as LpMeta);
  }
    /*
    private getApplicationItemNameFromId(id: number): String {
        return (this.formStackService.currentApplicationItem.details[id].key === null ? null
                : this.formStackService.currentApplicationItem.details[id].key);
    }

    private getApplicationItemNameFromDetailId(id: number): String {
        let index: number = _.findIndex(this.formStackService.currentApplicationItem.details, (i: ApplicationItemDetail) => {
            return +i.id === id;
        });
        return (this.formStackService.currentApplicationItem.details[index].key === null ? null
                : this.formStackService.currentApplicationItem.details[index].key);
    }
    */
    private getDetailFromDetailId(detailId: number): ApplicationItemDetail {
        let index: number = _.findIndex(this.formStackService.currentApplicationItem.details, (i: ApplicationItemDetail) => {
            return +i.id === detailId;
        });
        return (this.formStackService.currentApplicationItem.details[index] === null ? null
                : this.formStackService.currentApplicationItem.details[index]);
    }


    private async changeDetailData(itemTitle: number, data: LpMeta): Promise<void> {
        this.applciationItemDetailloadingIndicator = true;
        this.formStackService.initLpLine(this.formStackService.currentApplicationItemDetail.key.toString());
        if (this.formStackService.currentApplicationItem.details !== null
            && this.formStackService.currentApplicationItem.details !== undefined
            && this.formStackService.currentApplicationItem.details.length > 0) {
            this.getDetailFromDetailId(+this.formStackService.currentApplicationItemDetail.id).data = data;
            if (this.formStackService.CurrentVerb === SCREEN_TEST) {
                this.examplesDetailsData();
            } else {
                const paginatedData: PaginatedData = await this.loadDetail(itemTitle, data);
                if (!Util.isNullOrUndefined(paginatedData)) {
                    if (Util.isNullOrUndefined(paginatedData.body.length) && paginatedData.count !== 0) {
                        let lpMetatab: Array<LpMeta> = new Array<LpMeta>();
                        let lpMeta: LpMeta = new LpMeta();
                        lpMeta = paginatedData.body as unknown as LpMeta;
                        lpMetatab.push(lpMeta);
                        this.formStackService.CurrentApplicationItemDetailData = lpMetatab;
                    } else {
                        this.formStackService.CurrentApplicationItemDetailData = paginatedData.body;
                    }
                    this.formStackService.CurrentApplicationItemDetailDataCount = paginatedData.count;
                    this.setApplictionItemDetailFooter(itemTitle);
                } else {
                    this.formStackService.CurrentApplicationItemDetailData = [];
                    this.formStackService.CurrentApplicationItemDetailDataCount = 0;
                }
                this.currentApplicationItemDetailLoading = null;
            }
        }
        this.applciationItemDetailloadingIndicator = false;
    }

    /*private async loadDetail(detailId: number, data: LpMeta): Promise<PaginatedData> {
        return new Promise<PaginatedData>((resolve) => {
            if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail)) {
                if (this.formStackService.currentApplicationItemDetail.pathOnRepository && !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.path)) {
                    this.repositoryProvider.get(this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, data), null, null, true).then((data) => {
                        this.formStackService.CurrentApplicationItemDetailRepositoryData = data;
                    });
                } else if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.path)) {
                    this.alocProProvider.getPaginatedData(
                        this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, data)
                        , this.formStackService.currentApplicationItemDetail.pagination?1:null 
                        , ApplicationItemDetail.buildQueryParams('GET', this.formStackService.currentApplicationItemDetail), false
                        , this.formStackService.currentApplicationItemDetail.pagination?Number(this.userService.getCurrentUser().preference.linesPerPage):null)
                    .then((paginatedData: PaginatedData) => {
                        let d: PaginatedData;
                        if (!Util.isNullOrUndefined(paginatedData) && !Util.isNullOrUndefined(paginatedData.body)) {
                            this.formStackService.CurrentApplicationItemDetailDataCount = paginatedData.count;
                            this.formStackService.CurrentApplicationItemDetailDataReadonly = paginatedData._readonly;
                            d = paginatedData;
                        }
                        resolve(d);
                    }).catch((error) => {
                        resolve(null);
                    });
                } else {
                    resolve(null);
                }
            }
        });
    }*/

    private async loadDetail(detailId: number, data: LpMeta): Promise<PaginatedData> {
        if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail)) {
            if (this.formStackService.currentApplicationItemDetail.pathOnRepository && !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.path)) {
                const datas = await this.repositoryProvider.get(this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, data), null, null, true);
                this.formStackService.CurrentApplicationItemDetailRepositoryData = datas;
                
            } else if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.path)) {
                let paginatedData: PaginatedData = await this.alocProProvider.getPaginatedData(
                    this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, data)
                    , this.formStackService.currentApplicationItemDetail.pagination?1:null 
                    , ApplicationItemDetail.buildQueryParams('GET', this.formStackService.currentApplicationItemDetail), false
                    , this.formStackService.currentApplicationItemDetail.pagination?Number(this.userService.getCurrentUser().preference.linesPerPage):null)
                let d: PaginatedData;
                if (!Util.isNullOrUndefined(paginatedData) && !Util.isNullOrUndefined(paginatedData.body)) {
                    this.formStackService.CurrentApplicationItemDetailDataCount = paginatedData.count;
                    this.formStackService.CurrentApplicationItemDetailDataReadonly = paginatedData._readonly;
                    d = paginatedData;
                    return d;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }

    private computeFooter(applicationItemDetail: ApplicationItemDetail): LpMeta {
        let footer: LpMeta = new LpMeta();
        let prop: String;
        footer['id'] = 'footer_table';

        if (!Util.isNullOrUndefined(applicationItemDetail) && !Util.isNullOrUndefined(applicationItemDetail.columns)) {
            for (let i: number = 0; i < applicationItemDetail.columns.length; i++) {
                prop = applicationItemDetail.columns[i].prop;
                if (applicationItemDetail.columns[i].summaryFunc) {
                    switch (applicationItemDetail.columns[i].summaryFunc) {
                    case 'sum': {
                        footer[prop.toString()] = this.sumNumber(prop, this.formStackService.CurrentApplicationItemDetailData);
                        break;
                    }
                    case 'avg': {
                        footer[prop.toString()] = this.avgNumber(prop, this.formStackService.CurrentApplicationItemDetailData);
                        break;
                    }
                    }
                } else if (prop) {
                    footer[prop.toString()] = null;
                }
            }
        }
        return footer;
    }

    private sumNumber(prop: String, data: Array<LpMeta>): number {
        let sum: number = 0;
        for (let i: number = 0; i < data.length; i++) {
          sum += data[i][prop.toString()];
        }
        return sum;
    }

    private avgNumber(prop: String, data: Array<LpMeta>): number {
        return this.sumNumber(prop.toString(), data) / data.length;
    }

    private async showExistingLine(lineId: string): Promise<void> {
        this.formStackService.currentDetailLineData.renew();
        await this.evalService.executeContrainte(BEFORE_GET_EVENT, this.formStackService.currentApplicationItemDetail.key.toString(),
            this.formStackService.currentApplicationItem.ecrId.toString(), '*', this.formStackService.currentDetailLineData,
            this.formStackService.currentFields, null, this.formStackService.currentData);
        if (this.formStackService.hasIdPath()) {
            await this.findDetailLineItem(lineId);
        } else {            
            await this.getDetailsLineItem(lineId);
        }
        await this.evalService.executeContrainte(AFTER_GET_EVENT, this.formStackService.currentApplicationItemDetail.key.toString(),
            this.formStackService.currentApplicationItem.ecrId.toString(), '*', this.formStackService.currentDetailLineData,
            this.formStackService.currentFields, null, this.formStackService.currentData);
        return;
    }

    private async reinitLineValue(): Promise<void> {
        this.formStackService.currentDetailLineData.renew();
        if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.editForm)
                && !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.editForm.id)) {
            this.initJsonTemplateDefaultValue(this.formStackService.currentApplicationItemDetail.editForm.fields);
        }
        await this.evalService.executeContrainte(INIT_NEW_EVENT, this.formStackService.currentApplicationItemDetail.key.toString
                    (), this.formStackService.currentApplicationItem.ecrId.toString(), '*', this.formStackService.currentDetailLineData
                    , this.formStackService.currentFields,null, this.formStackService.currentData);
    }

    private findDetailLineItem(id: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.alocProProvider.find(this.formStackService.buildDetailUrl(this.formStackService.currentApplicationItemDetail.path)
                    , id).then((records: LpMeta) => {
                if (!Util.isNullOrUndefined(records)) {
                    this.formStackService.currentDetailLineData.assign(records);
                }
                resolve();
            });
        });
    }

    private getDetailsLineItem(id: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.alocProProvider.getPaginatedData(
                this.formStackService.buildDetailUrl(this.formStackService.currentApplicationItemDetail.path)
                ).then((records: PaginatedData) => {
                if (!Util.isNullOrUndefined(records)) {
                    for (let i in records.body) {
                        if (records.body[i].id === id) {
                            this.formStackService.currentDetailLineData.assign(records.body[i]);
                        }
                   }
                   resolve();
                }
            });
        });
    }

    private initJsonTemplateDefaultValue(fields: Array<InputField>): void {
        _.each(fields, (async (f: InputField)  => {
          if (f.type !== INPUT_FIELD_TYPE_LABEL) {
            if (!Util.isNullOrUndefined(f.defaultValue) && f.defaultValue !== '') {
                this.formStackService.currentDetailLineData[f.prop.toString()] = f.defaultValue;
            }
            if (!Util.isNullOrUndefined(f.defaultValueEval) && f.defaultValueEval !== '') {
                this.formStackService.currentDetailLineData[f.prop.toString()]
                        = await this.evalService.evalWithReturnStatement(f.defaultValueEval, this.formStackService.currentData);
            }
            /*if (!Util.isNullOrUndefined(f.readonly) && f.readonly !== true && f.readonly !== false && typeof f.readonly === 'string') {
                f.readonly = this.formStackService.currentDetailLineData[String(f.readonly)];
            }*/
          }
        } ));
    }

    private async examplesDetailsData(): Promise<void> {
        const paginatedData: PaginatedData = await this.examplesDetailsProvider.loadDetail();
        this.formStackService.CurrentApplicationItemDetailData = paginatedData.body;
        this.formStackService.CurrentApplicationItemDetailDataCount = paginatedData.count;
    }

    public async setDetailTitle(details: ApplicationItemDetail): Promise<void> {
        if (!Util.isNullOrUndefined(details) && !Util.isNullOrUndefined(details.summaryEval)) {
            this.currentApplicationItemDetailTitle =
                await this.evalService.summaryEval(this.translateService.instant(details.title.toString()), details.summaryEval
                , this.formStackService.currentData, this.formStackService.CurrentApplicationItemDetailData);
        } else {
            this.currentApplicationItemDetailTitle = this.translateService.instant(details.title.toString());
        }
    }

    private async createDetail(data: LpMeta): Promise<string> {
        try {
            let ky: string = await this.alocProProvider.post(
                this.formStackService.buildDetailUrl(this.formStackService.currentApplicationItemDetail.path), data, ApplicationItemDetail.buildQueryParams('POST', this.formStackService.currentApplicationItemDetail)
            );
            this.modalService.success(this.translateService.instant('general.modalService.recordingDone'), this.translateService.instant('general.modalService.success'));
            this.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail, this.formStackService.currentData);
            this.uiSyncService.loader(false);
            this.userHstoryService.addEvent(
                'add',
                this.formStackService.currentApplicationItem,
                ky,
                this.formStackService.currentApplicationItem.verb,
                null,
                (this.formStackService.currentApplicationItemDetail.editForm  !== null 
                && this.formStackService.currentApplicationItemDetail.editForm  !== undefined
                && this.formStackService.currentApplicationItemDetail.editForm.title !== undefined
                && this.formStackService.currentApplicationItemDetail.editForm.title !== null) 
                ? this.translateService.instant(this.formStackService.currentApplicationItemDetail.editForm.title.toString()) 
                : ''
                +' (ID '+data.id+')'
            );
            return ky;
        } catch (error)  {
            console.error(error);
            this.modalService.error(this.translateService.instant('general.modalService.additionFailed'));
            this.uiSyncService.loader(false);
        }
    }

    private async updateDetail(data: LpMeta): Promise<void> {
        try {
            await this.alocProProvider.put(this.formStackService.buildDetailUrl(this.formStackService.currentApplicationItemDetail.path).toString(), data.id.toString(), data.optimized(),
            ApplicationItemDetail.buildQueryParams('PUT', this.formStackService.currentApplicationItemDetail));
            this.modalService.success(this.translateService.instant('general.modalService.recordingDone'), this.translateService.instant('general.modalService.success'));
            this.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail, this.formStackService.currentData);
            this.uiSyncService.loader(false);
            this.userHstoryService.addEvent(
                'modify',
                this.formStackService.currentApplicationItem,
                this.formStackService.currentData.id,
                this.formStackService.currentApplicationItem.verb,
                null,
                (this.formStackService.currentApplicationItemDetail.editForm  !== null 
                && this.formStackService.currentApplicationItemDetail.editForm  !== undefined
                && this.formStackService.currentApplicationItemDetail.editForm.title !== undefined
                && this.formStackService.currentApplicationItemDetail.editForm.title !== null) 
                ? this.translateService.instant(this.formStackService.currentApplicationItemDetail.editForm.title.toString()) 
                : ''
                +' (ID '+data.id+')'
            );
        } catch (error) {
            this.modalService.error(this.translateService.instant('general.modalService.updateFailed'));
            this.uiSyncService.loader(false);
        }
    }

    private async bulkDetail(data: Array<LpMeta>, ky: string): Promise<void> {
        try {
            await this.alocProProvider.bulk(this.formStackService.currentApplicationItemDetail.path.toString(), data)
            this.modalService.success(this.translateService.instant('general.modalService.recordingDone'), this.translateService.instant('general.modalService.success'));
            this.uiSyncService.loader(false);
        } catch (error) {
            this.modalService.error(this.translateService.instant('general.modalService.updateFailed'));
            this.uiSyncService.loader(false);
        }
    }
}

