import { Component, Input } from '@angular/core';

@Component({
  selector: 'lp-ui-loader-mini',
  templateUrl: './lp-ui-loader-mini.component.html'
})
export class LpUiLoaderMiniComponent {
  /**
   * La propriété message permet d'afficher un message sous le loader.
   */
  @Input() public message: String = '';

  constructor() {}
}
