import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { DateTime, Duration  } from "luxon";
import { Util } from 'app/statics/utils';
import { ConfigService } from './config.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
  })
export class DatetimeService {

    constructor(private userService: UserService,
                public configService: ConfigService) {}
                
    public newDate(val1?: any, val2?: any, val3?: any): Date {
        if (!Util.isNullOrUndefined(val1) && !Util.isNullOrUndefined(val2) && !Util.isNullOrUndefined(val3)) {
            return new Date(val1, val2, val3);
        } else if (!Util.isNullOrUndefined(val1) && Util.isNullOrUndefined(val2) && Util.isNullOrUndefined(val3)) {
            return new Date(val1);
        } else if (Util.isNullOrUndefined(val1) && Util.isNullOrUndefined(val2) && Util.isNullOrUndefined(val3)) {
            return new Date();
        }
    }

    public locale(locale: string): moment.Moment {
        return moment().locale(locale)
    }

    /**
     * @param dateString : string
     * @returns string date format UI: 'DD/MM/YYYY'
     */
    public dateUiFormat(dateString: string): String {
        /*console.log(DateTime.fromJSDate(new Date(dateString)).toFormat('dd/MM/yyyy'));
        console.log(moment(dateString).format(this.configService.get('formats').date_ui_format));*/
        return DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_ui_format);
    }

    /**
     * Parse to format 'DD/MM/YYYY HH:mm:ss'
     * @param date: string
     * @returns: string 'DD/MM/YYYY HH:mm:ss'date_hour_ui_format
     */
    public dateFormatFR(dateString: string): string {
        if (dateString.indexOf('Z') > -1){
            dateString = dateString.substring(0, dateString.length - 1)
        }
        let temp: string = DateTime.fromISO(dateString).toFormat(this.configService.get('formats').date_hour_ui_format)
        return temp;
    }

    /**
     * Parse date to format BDD without changing the hour value.
     * @param string date
     * @returns string 'YYYY-MM-DDTHH:mm' to local timeZone (ex fr: returns ...HH+2:mm...)
     */

    public dateDBFormatComplete_2(dateString: string): string {
        //return DateTime.fromISO(dateString).toFormat(this.configService.get('formats').date_db_format_complete)
        /*console.log(moment(dateString, this.configService.get('formats').date_fr_format).format(this.configService.get('formats').date_db_format_complete));
        console.log(DateTime.fromFormat(dateString, 'dd/MM/yyyy HH:mm').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));*/
        return this.deleteZtoDate(DateTime.fromFormat(dateString, 'dd/MM/yyyy HH:mm').toFormat(this.configService.get('formats').date_db_format_complete));
    }

    /**
     * Parse date to format BDD without changing the hour value.
     * @param string date
     * @returns string 'YYYY-MM-DDTHH:mm' to local timeZone (ex fr: returns ...HH+2:mm...)
     */
    public dateDBFormatComplete(dateString: string): string {
        dateString = this.deleteZtoDate(dateString);
        let temp: string = DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_db_format_complete);
        return temp;
    }

      /**
     * Parse date to BDD format without 'Z': 'YYYY-MM-DDTHH:mm:ss.sss' / Used in date-component
     * @param string valide moment date
     * @returns string 'YYYY-MM-DDTHH:mm'
     
    public dateComponent(dateString: string): string {
        if (!Util.isNullOrUndefined(dateString)) {
            dateString = this.deleteZtoDate(dateString);
            console.log(moment(dateString).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]').toString());
            return moment(dateString).format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
            //return moment(dateString).format(this.configService.get('formats').date_db_format_complete).toString();
        } else {
            return null;
        }
    }*/

    public dateComponent(dateString: string): string {

        if (!Util.isNullOrUndefined(dateString)) {

            dateString = this.deleteZtoDate(dateString);

            return moment(dateString).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]').toString();

        } else {

            return null;

        }

    }

    /**
     * Utilisé pour renvoyer une date au format BDD depuis une entrée de type DD/MM/YYYY
     * @param date format DD/MM/YYYY
     * @returns string ex: 2002-01-20T00:00:00+02:00 (format BDD)
     */
    public formatDDMMYYYYtoBddFormat(dateString: string): string {
        return DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_db_format_complete);;
    }

    /**
     * Utilisé pour renvoyer une date au format BDD depuis une entrée de type DD-MM-YYYY
     * @param date format DD-MM-YYYY
     * @returns string ex: 2002-01-20T00:00:00Z (format BDD)
     */
     public bddFormatWithZeroHours(dateString: string): string {
        let temp = new Date( new Date(dateString) .setHours(0o0) );
        return DateTime.fromJSDate( temp).toFormat(this.configService.get('formats').date_db_format_complete);
    }

    public includesAndTransform(dateString: string, character: string): string {
        if (dateString && dateString.length && dateString.length !== 0 && !dateString.includes(character)) {
            dateString = this.formatDDMMYYYYtoBddFormat(dateString)
        }
        return dateString;
    }

    public getDateFromMoment(date: moment.Moment): string {
        return DateTime.fromJSDate(new Date(date.toString())).toFormat(this.configService.get('formats').date_db_format_complete);
    }

    /**
     * Parse string date to format DD/MM/YYYY
     * @param date: string
     * @returns DD/MM/YYYY
     */
    public formatDateForReportExecute(dateString: string): string {
        dateString = this.includesAndTransform(dateString, 'Z');
        let temp: string = DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_reports);
        return temp;
    }

    // to do later
    public momentHourUiFormat(dateString: string): string {
        return DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_db_format_complete);
        /* moment(dateString, this.configService.get('formats').hour_ui_format)
        return moment(dateString, this.configService.get('formats').hour_ui_format); */
    }

    public dateFormat(dateString: string): string {
        let temp =  DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('moment').dateFormat)
        return temp;
    }

    public displayDateFormat(value: string, locale?: string): string {
        if (Util.isNullOrUndefined(locale)) {
            locale = this.userService.getCurrentLang();
        }
        if ( locale === 'en') {
            return DateTime.fromJSDate(new Date(value)).toFormat(this.configService.get('moment').dateEn);
        } else if ( locale === 'fr') {
            return DateTime.fromJSDate(new Date(value)).toFormat(this.configService.get('formats').date_ui_format);
        }
    }

    public displayDateHourFormat(value: string, locale?: string): string {
        if (Util.isNullOrUndefined(locale)) {
            locale = this.userService.getCurrentLang();
        }
        if ( locale === 'en') {
            return DateTime.fromJSDate(new Date (value)).toFormat(this.configService.get('moment').datehourEn);
        } else if ( locale === 'fr') {
            return DateTime.fromJSDate(new Date (value)).toFormat(this.configService.get('formats').date_hour_ui_format);
        }
    }

    public dateDefaultFormat(dateString: string): string {
        /*console.log(DateTime.fromJSDate(new Date(dateString)).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));
        console.log(moment(dateString).format(this.configService.get('moment').defaultFormat));*/
        return DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_db_format_complete);
    }

    public dateDefaultFormatMultiInput(dateString: string, locale?: string): string {
        if (Util.isNullOrUndefined(locale)) {
            locale = this.userService.getCurrentLang();
        }
        if ( locale === 'en') {
            return DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_db_format_complete);
        } else if ( locale === 'fr') {
            return DateTime.fromJSDate(new Date(dateString)).toFormat(this.configService.get('formats').date_db_format_complete);
        }
    }

    // used in html 1 time
    /**
     * Pase string date to format 'DD/MM/YYYY HH:mm'
     * @param dateString
     * @returns string 'DD/MM/YYYY HH:mm'
     */
    public dateTimeFormat(dateString: string): string {
        /*console.log(moment(dateString, this.configService.get('formats').date_db_format_complete).format(this.configService.get('formats').date_hour_ui_format));
        console.log(DateTime.fromFormat(dateString, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").toFormat("dd/MM/yyyy HH:mm"));*/
        return DateTime.fromFormat(dateString, this.configService.get('formats').date_db_format_complete).toFormat(this.configService.get('formats').date_hour_ui_format);
    }

    public subtract(date: string, duration: any, unit: string): string {
        let dur: any = {};
        dur[unit]= duration;
        /* console.log(DateTime.fromJSDate(new Date(date)).minus(dur).toFormat('yyyy/MM/dd'));
        console.log(this.dateFormat(moment(date.toString()).subtract(duration, unit.toString()).toString())); */
        return DateTime.fromJSDate(new Date(date)).minus(dur).toFormat(this.configService.get('moment').dateFormat);
        //return this.dateFormat(moment(date.toString()).subtract(duration, unit.toString()).toString());
    }

    public subtractWithTime(date: string, duration: any, unit: string): string {
        let dur: any = {};
        dur[unit]= duration;
        /* console.log(DateTime.fromJSDate(new Date(date)).minus(dur).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));
        console.log(this.dateDefaultFormat(moment(date.toString()).subtract(duration, unit.toString()).toString())); */
        return DateTime.fromJSDate(new Date(date)).minus(dur).toFormat(this.configService.get('formats').date_db_format_complete);
        // return this.dateDefaultFormat(moment(date.toString()).subtract(duration, unit.toString()).toString());
    }

    public add(date: string, duration: any, unit: string): string {
        let dur: any = {};
        dur[unit]= duration;
        /*console.log(DateTime.fromJSDate(new Date(date)).plus(dur).toFormat('yyyy/MM/dd'));
        console.log(this.dateFormat(moment(date.toString()).add(duration, unit.toString()).toString()));*/
        return DateTime.fromJSDate(new Date(date)).plus(dur).toFormat(this.configService.get('moment').dateFormat);
    }

    public addWithTime(date: string, duration: any, unit: string): string {
        let dur: any = {};
        dur[unit]= duration;
        /*console.log(DateTime.fromJSDate(new Date(date)).plus(dur).toFormat('yyyy/MM/dd'));
        console.log(this.dateFormat(moment(date.toString()).add(duration, unit.toString()).toString()));*/
        return DateTime.fromJSDate(new Date(date)).plus(dur).toFormat(this.configService.get('formats').date_db_format_complete);
    }

    /**
     * Parse a string date to object Date
     * @param string date string
     * @returns Object Date
     */
    public toDate(date: string): Date {
        return DateTime.fromJSDate(new Date(date)).toJSDate();
    }

    /**
     *
     * @param date Check if the string date is a valide moment format.
     * @returns boolean
     */
    public isValid(date: string): boolean {
        return DateTime.fromJSDate(new Date(date)).isValid
    }

    public isAfter(date1: string, date2: string): boolean {
        let temp: Duration;
        let date1Temp = DateTime.fromJSDate(new Date(date1));
        let date2Temp = DateTime.fromJSDate(new Date(date2));
        temp = date1Temp.diff(date2Temp);
        return temp.valueOf() > 0;
    }

    public isBefore(date1: string, date2: string): boolean {
        let temp: Duration;
        let date1Temp = DateTime.fromJSDate(new Date(date1));
        let date2Temp = DateTime.fromJSDate(new Date(date2));
        temp = date1Temp.diff(date2Temp);
        return temp.valueOf() < 0;
        //return moment(date1.toString(), this.configService.get('formats').moment_formats).isBefore( moment(date2.toString(), this.configService.get('formats').moment_formats))
    }

    /**
     * Parse Date to string
     * @param date: Object Date
     * @returns string 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
     */
    public toString(date: Date): string {
        return DateTime.fromJSDate(new Date(date)).toFormat(this.configService.get('formats').date_db_format_complete);
    }

    /**
     * set the date to the first of this month ex: 2001-10-22... => 'Mon Oct 01 2001 00:00:00 GMT+0200'
     * @param date : String of a valid date
     * @returns string
     */
    public startOfMonth(date: String): string {
        return new Date(DateTime.fromJSDate(new Date(date.toString())).startOf('month').toFormat(this.configService.get('formats').date_db_format_complete)).toString();
    }

    /**
     * set the date to the end of this month ex: 2001-10-22... => 'Wed Oct 31 2001 23:59:59 GMT+0100'
     * @param date : String of a valid date
     * @returns string
     */
    public endOfMonth(date: String): string {
        return new Date(DateTime.fromJSDate(new Date(date.toString())).endOf('month').toFormat(this.configService.get('formats').date_db_format_complete)).toString();
    }

    /**
     * Renvoie la date actuelle au format BDD.
     * @returns string to BDD format
     */
    public getNow(): string {
        return DateTime.now().toFormat(this.configService.get('formats').date_db_format_complete);
    }

    public set(date, obj): string{
        return DateTime.fromJSDate(new Date(date.toString())).set(obj).toFormat(this.configService.get('formats').date_db_format_complete);
    }

    public isBetween(refDate: string, dateMin: string, dateMax: string){
        return this.isAfter(refDate, dateMin) && this.isBefore(refDate, dateMax);
    }

    /** 
    * Use this fonction to delete the utc format, 'Z' at the end of the string date.
    * @param date: object date || string format BDD ex: 2019-06-04T10:12:26.000Z
    * @returns string, date not utc ex: 2019-06-04T10:12:26.000
    */
    public deleteZtoDate(date: any): string {
        if (date) {
            switch (typeof date) {
                case 'string': {
                    if (date.lastIndexOf('Z') === date.length - 1) {
                        return date.slice(0, -1);
                    } else {
                        return date;
                    }
                }
                case 'object': {
                    date = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]').toString();
                    if (date.lastIndexOf('Z') === date.length - 1) {
                        return date.slice(0, -1);
                    } else {
                        return date;
                    }
                }
            }
        }
    }

    public halfHourRoundDate(date: string): string {
        let final = DateTime.fromJSDate(new Date(date));
        const hours: number = DateTime.fromJSDate(new Date(date)).get('hour')
        const minutes: number = DateTime.fromJSDate(new Date(date)).get('minute')
        if (minutes >= 0) {
          if (minutes < 15) {
            final.set({'minute': 0});
          } else if (minutes >= 15 && minutes < 45) {
            final.set({'minute': 30});
          } else if (minutes >= 45) {
            final.set({'hour': hours + 1});
            final.set({'minute' : 0});
          }
        }
        return final.toFormat(this.configService.get('formats').date_db_format_complete);
    }

}