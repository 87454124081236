import { Pipe, PipeTransform } from '@angular/core';
import { GouvAddress } from 'app/meta/gouv-address';
import { Util } from 'app/statics/utils';
/*
 * user an array of string (pattern) to define a specific format (the order in the array is important !!!)
 * => ['postalCode', 'city'] => 26000 Valence
 * => ['name', 'city'] => 10 Boulevard Foch Grenoble
 * if no pattern then use default format 
 * => ['name', 'postcode', 'city']
*/
@Pipe({name: 'addressFormat'})
export class AddressFormatPipe implements PipeTransform {
  transform(value: GouvAddress, pattern: Array<String>): string {
    if(Util.isNullOrUndefined(pattern)) {
      return `${value.name}, ${value.postcode} ${value.city}`  ;
    } else {
      let final: string = ""
      pattern.forEach((element: string, index: number) => {
        final += value[element] + ' '
      })
      return final;
    }
    
  }
}