import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AbstractItemComponent } from '../abstract-item.component';
import { Subscription } from 'rxjs';
import { FormMetadata } from 'app/models/form-metadata';
import { Util } from 'app/statics/utils';
import { FormMetadataValues } from 'app/models/form-metadata-values';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';

@Component({
  selector: 'lp-label-field',
  templateUrl: './label-field.component.html',
})
export class LabelFieldComponent extends AbstractItemComponent implements OnInit, OnDestroy {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;

  /**Le paramètre Binding représente les données reçus par le composant par l'intermédaire du [(ngModel)].
   */
  @Input() public binding: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   */
  @Input() public name: string;

  /**Le paramètre InputLabel sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputLabel: string;

  /**Le paramètre Text représente une alternative au paramètre Binding provenant du [(ngModel)].
   *Soit le label peut prendre comme chaine de caractère Binding, soit il prendra Text.
   */
  @Input() public text: string;

  /**Le paramètre For est une aide à la séléction, ou une référence. Lorsque l'on clique sur le label,
   * grâce à l'intermédiaire du paramètre for, le champs correspondant au label sera automatiquement séléctionné.
   * Démarche de fonctionnement : for = "for[NomDuChampDuFormulaire]"
   */
  @Input() public for: string;

  /**Le paramètre Title permet de configuré un titre au composant.
   */
  @Input() public required: Boolean = false;

  @Input() public hidden: Boolean;

  protected backendMetadata: Subscription;

  constructor(
    public changeService: ChangeService,
    public evalService: EvalService,
    public formMetadataProvider: FormMetadataProvider,
    public formStackService: FormStackService,
    public uiSyncService: UiSyncService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super(
      changeService,
      evalService,
      formMetadataProvider,
      formStackService,
      uiSyncService
      );
  }

  public ngOnInit(): void {
    //this.formStackService.currentFields.set(this.for, {name: this.for})
    this.inputLabel = this.id;
    this.subscribe();
  }

  public ngOnDestroy(): void {
    if (this.backendMetadata) {
      this.backendMetadata.unsubscribe();
    }
  }

  private subscribe(): void {
    this.backendMetadata = this.uiSyncService.formMetadataEventEmitter.subscribe((formMetadataValues: FormMetadataValues) => {
      if (!Util.isNullOrUndefined(this.for)) {
        if (this.formStackService.CurrentMetadata.has(this.for.toString())) {
            this.setDefaultValue(this.formStackService.CurrentMetadata.get(this.for.toString()));
        }
      }
    });
  }

  private setDefaultValue(formMetaData: FormMetadata): void {
      if (!Util.isNullOrUndefined(formMetaData.mandatory)) {
        this.required = formMetaData.mandatory;
      }
      if (!Util.isNullOrUndefined(formMetaData.hidden)) {
        this.hidden = formMetaData.hidden;
      }
      this.changeDetectorRef.detectChanges();
  }

}
