import { Component, Input, OnInit } from '@angular/core';
import { UiSyncService } from '../../../services/ui-sync.service';
import { TranslateService } from '@ngx-translate/core';
import { ZoomMetaData } from '../../../models/zoom-metada';
import { LpReport } from '../../../meta/report';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { ReportService } from 'app/services/report.service';
import { LpReportParameters } from 'app/meta/report-parameters';
import { Util } from 'app/statics/utils';

export const PATH: string = 'reports';
export const KEY_SPINNER: string = 'loading.data';
export const LIST: string = 'list';
const PRINTS: string = 'report.prints';
const STATES: string = 'report.reports';

@Component({
  selector: 'lp-report-list',
  templateUrl: './lp-report-list.component.html'
})
export class LpReportListComponent implements OnInit  {

  @Input() isInStatisticScreen: boolean = false;
  public metaData: ZoomMetaData;
  @Input() public data: LpReport;
  public loadingIndicator: Boolean = true;
  public modelName: string;
  public tabSwitch: Array<any> =
   [{id: 'EDIT', wording: this.translateService.instant(PRINTS)}, {id: 'ETAT', wording: this.translateService.instant(STATES)}];
  public editionType: string = 'EDIT';
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  private parentField: any;
  private wording: string;
  private idReport: string;

  constructor(
    public ihmStackService: IhmStackService,
    private reportService: ReportService,
    private uiSyncService: UiSyncService,
    private translateService: TranslateService ) {}

  public async ngOnInit(): Promise<void> {
    // A GARDER - on vide le srcPDF pour eviter d'afficher le pdf précédement cliqué,
    // car lorsqu'on enchaine plusieurs affichage de pdf, on affichaient les pdf précedent
    this.setDefaultStatus();
    this.initSrcPDF();
    await this.initCSS();
    await this.initList();
  }

  public async switch(): Promise<void> {
    this.loadingIndicator = true;
    this.data = await this.reportService.list(this.reportService.getReportObject(), this.editionType);
    this.loadingIndicator = false;
  }

   /**
   * Permet de séléctionner la ligne dans la datatable et d'envoyer le nom du .rtp choisi au back - end.
   */
  public async selectItem(row: LpReport, event: Event): Promise<void> {
    if((event.target as Element).className.search("icon") > -1) {
      // L'utilisateur a cliqué sur le bouton d'envoi
      return ;
    }
    this.initItemSelected();
    this.initSrcPDF();
    this.uiSyncService.loader(true, false, this.translateService.instant(KEY_SPINNER));
    if (this.parentField === undefined) {
      this.reportService.itemSelectedInList = row.id.toString();
      this.idReport = row.id.toString();
      this.modelName = row.modelName.toString();
      this.wording = row.wording.toString();
      this.reportService.update(false, this.editionType);
      this.reportService.updateTitle(this.wording);
      this.reportService.updateModelName(this.modelName);
    }
    await this.displayNextStep(this.editionType);
    this.uiSyncService.loader(false);
  }

  public setDefaultSwitchBar(): boolean {
    if ( !Util.isNullOrUndefined(this.isInStatisticScreen) && this.isInStatisticScreen === true ) {
      return false;
    } else if (!Util.isNullOrUndefined(this.isInStatisticScreen) && this.isInStatisticScreen === false) {
      return true;
    }
  }

  public setDefaultStatus(): void {
    if ( !Util.isNullOrUndefined(this.isInStatisticScreen) && this.isInStatisticScreen === true ) {
      this.editionType = 'ETAT';
    } else if (!Util.isNullOrUndefined(this.isInStatisticScreen) && this.isInStatisticScreen === false) {
      this.editionType = 'EDIT';
    }
  }

  private initSrcPDF(): void {
    if (this.reportService && this.reportService.srcPdf && this.reportService.srcPdf.length !== 0) { this.reportService.srcPdf = null}
  }

  private initItemSelected(): void {
    if (this.reportService && this.reportService.itemSelectedInList ) { this.reportService.itemSelectedInList = null}
  }

  private async initCSS(): Promise<void> {
    try {
      this.arrayClassesElements = this.reportService.css();
      this.metaData = await this.reportService.loadMetaData();
    } catch (error) {
      throw error;
    }
  }

  private async initList(): Promise<void> {
    try {
      if ( !Util.isNullOrUndefined(this.isInStatisticScreen) && this.isInStatisticScreen === true ) {
        this.loadingIndicator = false;
      } else if (!Util.isNullOrUndefined(this.isInStatisticScreen) && this.isInStatisticScreen === false) {
        if (this.editionType) {
          this.data = await this.reportService.list(this.reportService.getReportObject(), this.editionType);
          this.loadingIndicator = false;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  private async displayNextStep(editionType: string): Promise<void> {
    if (editionType === 'EDIT') {
      let parameters: Array<LpReportParameters> = await this.reportService.getParameters(this.idReport);
      let binaryReport: ArrayBuffer = await this.reportService.execute(parameters, this.reportService.isSpoolerMode);
      if ( binaryReport ) {
        this.reportService.pdfExported(new Uint8Array(binaryReport));
      }
      if(this.reportService.isSpoolerMode) {
        this.ihmStackService.displaySubAPIDetails();
      }
      if (this.ihmStackService.ShowReport === true) {
        this.reportService.showReportPDF();
      }
    } else {
      this.ihmStackService.displayPrintReportForm();
    }
  }

}
