import { Type } from 'class-transformer';
import { LpAnalytic } from './analytic';
import { LpCompany } from './company';
import { FtMeta } from './ft-meta';
import { LpListOfTables } from './listOfTables';
import { LpLocation } from './location';
import { LpMeta, propertyMeta } from './meta';
import { LpSector } from './sector';
import { LpThirdParty } from './third-party';

export class LpEdi extends LpMeta {
    
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public relatedKey: String;
    @Type(() => FtMeta)
    public natureLink: FtMeta = new FtMeta();
   /* @Type(() => LpPersonalModel)
    public listOfTables: LpPersonalModel = new LpPersonalModel();*/
    @Type(() => LpThirdParty)
    public thirdParty: LpThirdParty = new LpThirdParty();
    @propertyMeta('string')
    public thirdPartyReference: String;
    @propertyMeta('string')
    public subReference: String;
    @propertyMeta('date')
    public endDate: Date;
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('date')
    public date: Date;
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpAnalytic)
    public analytic: LpAnalytic = new LpAnalytic();
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => LpSector)
    public sector: LpSector = new LpSector();
    @Type(() => LpSector)
    public listOfTables: LpListOfTables = new LpListOfTables();

    /*contactDetailsType: null
    documentType: null
    formatType: null
    listOfTables: {id: "F001SOC", wording: "Sociétés -", details: "technical/ft?object=listOfTable&key=F001SOC"}
    sector: null*/

}
