import { LpMeta, propertyMeta } from './meta';
import { LpLocation } from './location';
import { FtMeta } from './ft-meta';
import { LpPersonalModel } from './personal-model';
import { Type } from 'class-transformer';

export class LpModel extends LpMeta {
    @propertyMeta('string')
    public description: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public label: String;
    public location: LpLocation = new LpLocation();
    @propertyMeta('string')
    public codexCode: String;
    @propertyMeta('string')
    public directorWord: String;
    @Type(() => FtMeta)
    public equipmentType: FtMeta = new FtMeta();
    @Type(() => LpPersonalModel)
    public personalModel: LpPersonalModel = new LpPersonalModel();
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => FtMeta)
    public modelType: FtMeta = new FtMeta();

    protected _unoptimizedKeys: String[] = ['personalModel'];

}
