import { Injectable, EventEmitter, Output } from '@angular/core';
import { SchedulerData, SchedulerEquipmentLabel, SchedulerEventLabel, SchedulerDate } from '../meta/scheduler/scheduler-data';
import { RepositoryProvider } from '../providers/repository.provider';
import { SchedulerConfig } from '../meta/scheduler/scheduler-config';
import { XAxis } from '../meta/scheduler/xAxis';
import { SchedulerDateNaviguation } from 'app/meta/scheduler/scheduler-date-naviguation';
import { Util } from 'app/statics/utils';
import { resolve } from 'bluebird';
import { SchedulerFilter } from 'app/meta/scheduler/scheduler-filter';
import { ModalService } from './modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeService } from './datetime.service';
import * as _ from 'underscore'
import { UserService } from './user.service';
import { ZoomService } from './zoom.service';
import { ZoomConfig } from 'app/models/zoom-metada';
import { LpMeta } from 'app/meta/meta';
import { ApplicationItem } from 'app/models/application-item';

const DHTMLX_FULLDAY: string = 'fullday';

const LIGHT_GREEN_CSS_CLASS: string = 'light-green';
const GREY_CSS_CLASS: string = 'grey';
const ODD: string = 'odd';
const PAIR: string = 'pair';


@Injectable({
  providedIn: 'root'
})

export class SchedulerUIService {
    public schedulerConfig: SchedulerConfig;
    public refresh: EventEmitter<void> = new EventEmitter();
    public clearCache: EventEmitter<void> = new EventEmitter();
    public loader: Boolean;
    public filter: SchedulerFilter = new SchedulerFilter();
    @Output() public date: EventEmitter<SchedulerDate> = new EventEmitter();
    public isFilterLoaded: boolean = false;
    private isConfigPanelDisplayed: boolean = !!false;
    private isFilterPanelDisplayed: boolean = !!false;
    private isMovementPanelDisplayed: boolean = !!false;
    private isAlreadyLoaded: boolean = false;
    private d: SchedulerDate = {'startDate': null, 'endDate': null}
    private dateDisplayed: SchedulerDateNaviguation = {'date': this.d, 'scale': null};
    //private filter: SchedulerFilter;
   // private userConnectedSubscription: Subscription = new Subscription();

    /*get Filter(): SchedulerFilter {
        return this.filter;
    }
    set Filter (f: SchedulerFilter) {
        this.filter = f;
    }*/
    get DateDisplayed(): SchedulerDateNaviguation {
        return this.dateDisplayed;
    }
    set DateDisplayed(object: SchedulerDateNaviguation) {
        this.dateDisplayed = object;
    }
    get IsAlreadyLoaded(): boolean {
        return this.isAlreadyLoaded;
    }
    set IsAlreadyLoaded(isActive: boolean) {
        this.isAlreadyLoaded = isActive;
    }

    constructor(private repositoryProvider: RepositoryProvider, 
        private translate: TranslateService,
        private modalService: ModalService,
        private datetimeService: DatetimeService,
        private userService: UserService,
        private zoomService: ZoomService) {
        this.schedulerConfig = new SchedulerConfig();
        this.schedulerConfig.affichage = DHTMLX_FULLDAY;
        //this.filter: SchedulerFilter;
        this.userService.userConnectedEvtEmitter.subscribe((bool: Boolean) => {
            this.isFilterLoaded = false
            if (bool) {
                this.schedulerConfig = new SchedulerConfig();
                //this.filter = new SchedulerFilter(this.userService);
            }
        })
    }

    public renewFilter(): void {
        this.filter = new SchedulerFilter();
    }

    public sendDate(d: SchedulerDate, refresh: boolean): void {
        if (refresh) {
            this.refresh.emit();
        }
        this.date.emit(d);
    }

    public getDates(): SchedulerDate {
        if (!Util.isNullOrUndefined(this.schedulerConfig.bufferBefore) && this.schedulerConfig.bufferBefore > 0) {
            this.dateDisplayed.date.startDate = this.datetimeService.dateDBFormatComplete(
                this.datetimeService.subtract(this.dateDisplayed.date.startDate.toString(), this.schedulerConfig.bufferBefore, 'day')
                );
            this.dateDisplayed.date.endDate = this.datetimeService.dateDBFormatComplete(
                this.datetimeService.subtract(this.dateDisplayed.date.endDate.toString(), this.schedulerConfig.bufferBefore, 'day')
                );
        }
        return this.dateDisplayed.date;
    }

    public setDates(d: SchedulerDate): void {
        this.dateDisplayed.date = d;
    }

    public saveWeekendDay(weekend: any): void {
        for (let day of Object.keys(weekend)) {
            this.schedulerConfig.weekend.set(day, weekend[day]);
        }
    }

    public getDefaultWeekEndDefinition(): any {
        return  {days:  [5, 6], zones: DHTMLX_FULLDAY, css: GREY_CSS_CLASS };
    }

    public getWeekendDefinition(): any {
        let cpt: number = 0;
        let arrayOfDay: Array<number> = [];
        this.schedulerConfig.weekend.forEach((value: boolean, key: String) => {
            if (value) {
                arrayOfDay.push(cpt);
            }
            cpt ++;
        });
        return {
            days: arrayOfDay,
            zones: DHTMLX_FULLDAY,
            css: this.schedulerConfig.weekendColor
        };
    }

    public getWeekendObjectForIHM(): any {
        let temp: any = {};
        this.schedulerConfig.weekend.forEach((value: boolean, key: String) => {
            temp[key.toString()] = value;
        });
        return temp;
    }

    public getWeekendColorForIHM(): String {
        return this.schedulerConfig.weekendColor;
    }

    public saveWeekendColor(color: String): void {
        this.schedulerConfig.weekendColor = color;
    }

    public specialDayAlreadyExist(startDateEncodeBase64: String): boolean {
        if (startDateEncodeBase64) { return this.schedulerConfig.specialDay.has(startDateEncodeBase64.toString())};
    }

    public getSpecialDayConfig(startDateEncodeBase64: String): any {
        if (startDateEncodeBase64) { return this.schedulerConfig.specialDay.get(startDateEncodeBase64.toString()) };
    }

    public getAllSpecialsDayConfig(): Map<String, any> {
        return this.schedulerConfig.specialDay;
    }

    public saveSpecialDay(from: String, to: String, color?: String): void {
        if (from && to) {
            this.schedulerConfig.specialDay.set(btoa(from.toString()).toString(),
            {start_date: from.substr(0, (from.length - 1)), end_date: to.substr(0, (to.length - 1)),
                css: (color ? color : GREY_CSS_CLASS), zones: DHTMLX_FULLDAY, type: 'default'});
        }
    }

    public setSpecialDayId(startDateEncodeBase64: String, id: Number): void {
        let specialDayConfig: any = this.getSpecialDayConfig(startDateEncodeBase64);
        specialDayConfig.id = id;
        this.schedulerConfig.specialDay.set(startDateEncodeBase64, specialDayConfig);
    }

    public saveLineColor(odd: String, pair: String): void {
        this.schedulerConfig.lineStyleColor.set(ODD, odd);
        this.schedulerConfig.lineStyleColor.set(PAIR, pair);
    }

    public getLineColor(key: String): string {
        if (this.schedulerConfig.lineStyleColor.get(key)) { return this.schedulerConfig.lineStyleColor.get(key).toString(); }
    }

    public saveCollision(isCollisionAllowed: Boolean): void {
        this.schedulerConfig.isCollisionAllowed = !!isCollisionAllowed;
    }

    public saveShowUnaffectedMovements(showUnaffectedMovements: Boolean): void {
        this.schedulerConfig.showUnaffectedMovements = !!showUnaffectedMovements;
    }

    public getCollisionAllowed(): Boolean {
        return this.schedulerConfig.isCollisionAllowed;
    }

    public saveEventsLabel(isShown: Boolean, object: String, property?: String[]): void {
        let alreadyExists: boolean = false;
        let index: number = 0;
        for (let value of this.schedulerConfig.eventLabels) {
            if (typeof value === 'object') {
                if (value[0] === object) {
                    alreadyExists = true;
                    if (!isShown) {
                        this.schedulerConfig.eventLabels.splice(index, 1);
                    }
                }
            }
            if (value === object) {
                alreadyExists = true;
                if (!isShown) {
                    this.schedulerConfig.eventLabels.splice(index, 1);
                }
            }
            index++;
        }
        if (!alreadyExists) {
            if (property) {
                this.schedulerConfig.eventLabels.push([object, property]);
            } else {
                this.schedulerConfig.eventLabels.push(object);
            }
        }
    }

    public getEventLabel(): Array<SchedulerEventLabel> {
        return this.schedulerConfig.eventLabels;
    }

    public saveEquipmentTextAlign(position: String): void {
        this.schedulerConfig.equipmentTextAlign = position;
    }

    public saveEquipmentLabels(isShown: Boolean, object: String, property?: String): void {
        if (!Util.isNullOrUndefined(property)) {
            this.schedulerConfig.equipmentLabels.forEach((e: any, index: number) => {
                if (e.label[0] === object && e.label[1] === property) {
                    e.isdisplayed = isShown;
                }
            });
        } else {
            this.schedulerConfig.equipmentLabels.forEach((e: any, index: number) => {
                if (e.label === object) {
                    e.isdisplayed = isShown;
                }
            });
        }
    }

    public getEquipmentsLabelsForIHM(): Array<SchedulerEquipmentLabel> {
        return this.schedulerConfig.equipmentLabels;
    }

    public getMovementLabelsForIHM(): Array<SchedulerEventLabel> {
        return this.schedulerConfig.eventLabels;
    }

    public getEquipmentsLabels(): Array<SchedulerEquipmentLabel> {
        return this.schedulerConfig.equipmentLabels;
    }

    public  getNbEquipmentsLabelsTodisplays(): number {
        let cnt: number = 0;
        this.schedulerConfig.equipmentLabels.forEach((e) => {
            if (e.isdisplayed) {
                cnt ++;
            }
        });
        return cnt;
    }

    public getEquipmentStyle(): string {
        return  this.getEquipmentsAvailableColor().toString();
    }

    public getLineStyle(id: number): String {
        return this.schedulerConfig.equipmentTextAlign + ' ' + this.schedulerConfig.lineStyleColor.get((id % 2 === 0 ? PAIR : ODD));
    }

    public saveNowMarker(isNowDisplayed: Boolean): void {
        this.schedulerConfig.isNowDisplayed = isNowDisplayed;
    }

    public isTodayMarked(): Boolean {
        return this.schedulerConfig.isNowDisplayed;
    }

    public setLastHour(h: String): void {
        this.schedulerConfig.lastHour = h;
    }

    public getLastHour(): String {
        return this.schedulerConfig.lastHour;
    }

    public setFirstHour(h: String): void {
        this.schedulerConfig.firstHour = h;
    }

    public getFirstHour(): String {
        return this.schedulerConfig.firstHour;
    }

    public isCollisionAllowed(): Boolean {
        return this.schedulerConfig.isCollisionAllowed;
    }

    public showUnaffectedMovements(): Boolean {
        return this.schedulerConfig.showUnaffectedMovements;
    }

    public getBufferBefore(): number {
        return this.schedulerConfig.bufferBefore;
    }

    public saveBufferBefore(bufferValue: number): void {
        this.schedulerConfig.bufferBefore = bufferValue;
    }

    public saveEquipmentsAvailability(showAvailable: Boolean): void {
        this.schedulerConfig.showEquipmentAvailable = showAvailable;
        if (!this.schedulerConfig.availableEquimentColor) {
            this.schedulerConfig.availableEquimentColor = LIGHT_GREEN_CSS_CLASS;
        }
    }

    public isEquipmentAvailable(): Boolean {
        return this.schedulerConfig.showEquipmentAvailable;
    }

    public checkIfEquipmentAvailable(startDate: String, endDate: String, sectionId: Number, equipmentMovements: Array<any>): Boolean {
        let isAvailable: boolean = true;
        _.each(equipmentMovements, (e) =>  {
            if (e.sectionId === sectionId) {
                if (!this.datetimeService.isAfter(this.datetimeService.dateDefaultFormat(e.start_date), endDate.toString())
                && !this.datetimeService.isBefore(this.datetimeService.dateDefaultFormat(e.end_date), startDate.toString())) {
                    isAvailable = false;
                }
            }
        });
        return isAvailable;
    }

    public saveEquipmentsAvailableColor(color: String): void {
        this.schedulerConfig.availableEquimentColor = color;
    }

    public getEquipmentsAvailableColor(): String {
        return this.schedulerConfig.availableEquimentColor;
    }

    public getPositions(data: Array<SchedulerData>): Map<String, String> {
        if (this.schedulerConfig.eventColor.size === 0) {
            this.initEventColor(data);
        }
        return this.schedulerConfig.eventColor;
    }

    public saveEventColor(id: String, color: String): void {
        this.schedulerConfig.eventColor.set(id, color);
    }

    public getEventColor(position: String): string {
        if (this.schedulerConfig.eventColor.get(position) && this.schedulerConfig.eventColor.get(position) !== null) {
            return this.schedulerConfig.eventColor.get(position).toString();
        } else {
            return this.schedulerConfig.eventColor.get('default').toString();
        }
    }

    public initEventColor(data: Array<SchedulerData>): void {
        data.forEach(element => {
            if (!this.schedulerConfig.eventColor.has(element.position)) {
                this.schedulerConfig.eventColor.set(element.position.toString(),
                this.getEventColor(this.schedulerConfig.eventColor.get(element.position)));
            }
        });
    }

    public saveDelayedReturn(isDelayed: boolean): void {
        this.schedulerConfig.displayReturnDelayed = isDelayed;
    }

    public saveMatrixProperty(property: String, value: String): void {
        this.schedulerConfig[property.toString()] = value;
    }

    public getMatrixProperty(property: String): number {
        return this.schedulerConfig[property.toString()];
    }

    public getConfig(): void {
        this.retrieveConfig();
    }

    public async getDefaultFilter(): Promise<SchedulerFilter>{
        let d: any = await this.repositoryProvider.getschedulerConfig();
        return d.filter
    }

    /**
     * The fonction is used to get the user/profile/location/company scheduler configuration.
     */
    public async retrieveConfig(): Promise<SchedulerConfig> {
        let data: any = await this.repositoryProvider.getschedulerConfig();
        this.schedulerConfig.deserializeJSONConfig(data.config);
        this.schedulerConfig.id = data.id
        if(!Util.isNullOrUndefined(data.isCustom)){
            this.schedulerConfig.isCustom = data.isCustom
        } else {
            this.schedulerConfig.isCustom = false;
        }
        this.schedulerConfig.deserializeJSONFilter(data);
        this.isAlreadyLoaded = true;
        return this.schedulerConfig;
    }

    public async persistConfig(id: string): Promise<any> {
        try {
            let d: any = await this.repositoryProvider.putPostSchedulerConfig(this.schedulerConfig.serializeJSON(id))
            this.modalService.success(this.translate.instant('general.modalService.recordingDone'),this.translate.instant('general.modalService.recordingConfig'));
            return d.body.id
        }catch(e){
            this.modalService.error(this.translate.instant('general.modalService.recordingFailed'), this.translate.instant('general.modalService.recordingConfig'));
            resolve(false)
        }
    }

    public getConfigPanelState(): boolean {
        return this.isConfigPanelDisplayed;
    }

    public getFilterPanelState(): boolean {
        return this.isFilterPanelDisplayed;
    }

    public getMovementDetailPanelState(): boolean {
        return this.isMovementPanelDisplayed;
    }

    public setFilterPanelState(value: boolean): void {
        this.isFilterPanelDisplayed = value;
    }

    public setConfigPanelState(value: boolean): void {
        this.isConfigPanelDisplayed = value;
    }

    public setMovementDetailPanelState(value: boolean): void {
        this.isMovementPanelDisplayed = value;
    }

    public getEquipmentFromMouvementId(equipmentId: String, arrayOfData: Array<XAxis>): XAxis {
        for (let item of arrayOfData) {
            if (item.key === parseInt(equipmentId.toString(), 10)) {
                return item;
            }
        }
    }

    public emptyCache(): void {
        this.clearCache.emit();
    }

    public async reinstateConfig(id: number): Promise<void> {
        try {
            await this.repositoryProvider.deleteCustomConfig(id);
            this.modalService.success(this.translate.instant('general.modalService.deletionCompleted'),
            this.translate.instant('general.modalService.deletion'));
            await this.retrieveConfig();
        } catch(e) {
            console.error(e);
            this.modalService.error(this.translate.instant('general.modalService.deletionFailed'),
            this.translate.instant('general.modalService.deletion'));
        }
    }

    public getHexaFromClassColor(color: string): string {
        if (color === 'red') {
            return '#F44336';
        } else if (color === 'orange') {
            return '#FF9800';
        } else if (color === 'yellow') {
            return '#FFEB3B';
        } else if (color === 'green') {
            return '#4CAF50';
        } else if (color === 'blue') {
            return '#2196F3';
        } else if (color === 'red') {
            return '#F44336';
        } else if (color === 'black') {
            return '#424242';
        } else if (color === 'grey') {
            return '#9E9E9E';
        } else if (color === 'white') {
            return '#F44336';
        } else if (color === 'light-red') {
            return '#EF9A9A';
        } else if (color === 'light-orange') {
            return '#FFCC80';
        } else if (color === 'light-yellow') {
            return '#FFF59D';
        } else if (color === 'light-green') {
            return '#A5D6A7';
        } else if (color === 'light-blue') {
            return '#90CAF9';
        } else if (color === 'light-grey') {
            return '#F44336';
        } else {
            return '#727b84';
        }
    }

    public hasActiveFilter(): boolean{
        let isActive: boolean = false;
        Object.keys(this.filter).forEach((value, key) => {
            if (this.filter[value]) {
                if (this.filter[value].id) {
                    isActive = true;
                }
            }
        });
        return isActive;
    }

    public async getActiveFilter(): Promise<Array<Array<String>>> {
        let filters: Array<Array<String>> = [];
        for(let key of Object.keys(this.filter)){
            if(this.isValidCriteria(key, this.filter)){
                let appItem: ApplicationItem = await this.repositoryProvider.getApplicationItem('planning');
                let displayed: ZoomConfig = this.zoomService.getConfigbyBindingProp(key, appItem)
                if(!Util.isNullOrUndefined(displayed)){
                    filters.push(['scheduler.panelConfiguration.'  + key, this.filter[key][displayed.tableShowField[0]], key]);
                } else {
                    if(!Util.isNullOrUndefined(this.filter[key].registration)){
                        filters.push(['scheduler.panelConfiguration.'  + key, this.filter[key].registration, key]);
                    } else {
                        filters.push(['scheduler.panelConfiguration.'  + key, this.filter[key], key]);
                    }
                }
            }
        }
        return filters;
    }

    public getFilterRequestString(date: SchedulerDate): Map<string, string> {
        let returnMap: Map<string, string> = new Map();
        // let value: String = '?';
        if (!Util.isNullOrUndefined(date.startDate)) {
            // value += 'startDate=' + date.startDate + '&endDate=' + date.endDate + '&';
            returnMap.set('startDate', date.startDate.toString());
            returnMap.set('endDate', date.endDate.toString());
        } else {
            let startDate: string =
                this.datetimeService.dateDBFormatComplete(this.datetimeService.startOfMonth(this.datetimeService.newDate().toString()));
            let endDate: string =
                this.datetimeService.dateDBFormatComplete(this.datetimeService.endOfMonth(this.datetimeService.newDate().toString()));
            // value += 'startDate=' + startDate + '&endDate=' + endDate + '&';
            returnMap.set('startDate', startDate);
            returnMap.set('endDate', endDate);
        }
        let added: boolean = false;
        if (this.filter.actualLocation && this.filter.actualLocation.id) {
            // value += 'actualLocation=' + this.actualLocation.id;
            returnMap.set('actualLocation', this.filter.actualLocation.id.toString());
            added = true;
        }
        if (this.filter.brand && this.filter.brand.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'brand=' + this.brand.id;
            returnMap.set('brand', this.filter.brand.id.toString());
            added = true;
        }
        if (this.filter.registration && this.filter.registration.registration) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'registration=' + this.registration.registration;
            returnMap.set('registration', this.filter.registration.registration.toString());
            added = true;
        }
        if (this.filter.administrativeLocation && this.filter.administrativeLocation.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'administrativeLocation=' + this.administrativeLocation.id;
            returnMap.set('administrativeLocation', this.filter.administrativeLocation.id.toString());
            added = true;
        }
        if (this.filter.univers && this.filter.univers.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'category=' + this.category.id;
            returnMap.set('univers', this.filter.univers.id.toString());
            added = true;
        }        
        if (this.filter.category && this.filter.category.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'category=' + this.category.id;
            returnMap.set('category', this.filter.category.id.toString());
            added = true;
        }
        if (this.filter.family && this.filter.family.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'family=' + this.family.id;
            returnMap.set('family', this.filter.family.id.toString());
            added = true;
        }
        if (this.filter.model && this.filter.model.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'model=' + this.model.id;
            returnMap.set('model', this.filter.model.id.toString());
            added = true;
        }
        if (this.filter.owner && this.filter.owner.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'owner=' + this.owner.id;
            returnMap.set('owner', this.filter.owner.id.toString());
            added = true;
        }
        if (this.filter.type && this.filter.type.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'type=' + this.type.id;
            returnMap.set('type', this.filter.type.id.toString());
            added = true;
        }
        if (this.filter.company && this.filter.company.id) {
            if (added) {
                // value += '&'
                added = false;
            }
            // value += 'company=' + this.company.id;
            returnMap.set('company', this.filter.company.id.toString());
            added = true;
        }
        return returnMap;
        // return value;
    }

    public getFilter(): Promise<SchedulerFilter> {
        return new Promise<SchedulerFilter>((resolve) => {
          if (!this.isFilterLoaded) {
            // this.repositoryService.getschedulerConfig().then((data: SchedulerConfig) => {
            this.retrieveConfig().then((data: SchedulerConfig) => {
              this.renewFilter();
              this.isFilterLoaded = true;
              this.filter.assign(data.filter);
              resolve(this.filter);
            });
          } else {
            resolve(this.filter);
          }
        });
      }

    private isValidCriteria(key: string, filter: SchedulerFilter){
        return key !== 'isLoaded' && key !== 'details' && key !== 'endDate' && key !== 'id' && key !== 'startDate' && key !== '_readOnly' && key !== '_xVegaWorkflowsCount' && key !== '_xVegaAttachmentsCount' && key !== '_xVegaEdisCount'
            && ((filter[key] instanceof LpMeta && ((!Util.isNullOrUndefined(filter[key].id) && filter[key].id !== '') || (!Util.isNullOrUndefined(filter[key].registration) && filter[key].registration !== ''))) || !(filter[key] instanceof LpMeta) && !Util.isNullOrUndefined(filter[key]));
    }
}
