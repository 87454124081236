import { Component, OnInit, Input } from '@angular/core';
import { LpComponent } from '../lp.component';

@Component({
  selector: 'lp-item',
  template: ''
})
export class ItemComponent extends LpComponent implements OnInit {

  // Le paramètre CssClass permet d'attribuer aux balises du composant, des noms de classe spécifiques css.
 @Input() public cssClass: string;

 // Le paramètre ClassName permet d'attribuer aux balises du composant, des noms de classe spécifiques.
 @Input() public className: string;

}
