import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFieldComponent } from '../input-field.component';
import { MAIN, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { ApplicationItem } from 'app/models/application-item';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-text-field',
  templateUrl: './text-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true
    }
  ]
})
export class TextFieldComponent extends InputFieldComponent implements OnInit {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;
  @Input() public idInput: string = Math.random().toString();
  @Input() public pattern: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;
  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public isCounter: Boolean = false;

  /**Le paramètre InputText sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputText: string;

  /**Le paramètre Type permet de configurer le type des balises HTML qui viennent constituer le composant.
   */
  @Input() public type: String = 'text';

  /**Le output TextFieldEmitter va permettre l'exécution de la fonction qui lui est associée.
   * La fonction associée est déclarée dans le template où le composant est utilisé.
   */
  @Output() public textFieldEmitter: EventEmitter<any> = new EventEmitter();

  /** Le paramètre InputType est utilisé dans le propriété "type" de l'input.
  */
  public inputType: String = 'text';

  /** Le paramètre IconPassword est utilisé pour changer l'icon du mot de passe (affiché/caché).
  */
  private iconPassword: String = 'fa-eye-slash';

  public ngOnInit(): void {
    super.ngOnInit();
    this.inputText = this.name;
    this.inputType = this.type;
  }

  public pushChange(): void {
    super.pushChange();
    this.focusOutCustomCode();
  }

  public keyDown (event: any): void {
    if (event.key === 'Enter') {
      this.pushChange()
    }
  }

  public async focus(event: any): Promise<void> {
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    if (!Util.isNullOrUndefined(applicationItem)) {
      await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
        , applicationItem.ecrId.toString(), this.name.toString()
        , data, this.formStackService.currentFields, this.previousValue);
    }
    let d: string = event.target.value
    this.setPreviousValue(d)
  }

  // permet de masque ou de rendre visibles es charactères du champ mot de passe.
  public changePasswordMode(): void {
    if (this.iconPassword === 'fa-eye-slash') {
      // Rendre le mot de passe lisible
      this.iconPassword = 'fa-eye';
      this.inputType = 'text';
    } else {
      // Caché le mot de passe
      this.iconPassword = 'fa-eye-slash';
      this.inputType = 'password';
    }
  }
}
