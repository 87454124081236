import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, USER_GROUPS } from '../detail-forms.component';
import { Util } from 'app/statics/utils';
import { LpUserGroups } from 'app/meta/user-groups';

@Component({
  selector: 'lp-user-groups',
  templateUrl: './user-groups.component.html'
})
export class UserGroupsComponent extends DetailFormsComponent implements OnInit {

  public data: LpUserGroups = null;

  public ngOnInit(): void {
      this.api = 'group-authorizations';
      super.initData();
      if (Util.isNullOrUndefined(this.data)) {
        this.data = new LpUserGroups();
      }
      if (Util.isNullOrUndefined(this.formStackService.currentDetailLineData)) {
        this.formStackService.currentDetailLineData = new LpUserGroups();
      }
      this.data.assign(this.formStackService.currentDetailLineData);
      this.setId(USER_GROUPS);
      this.object = 'user';
      this.verb = 'users';
      this.initUrl();
    // this.init();
  }

  public init(): void {
    this.loadObjectRootFormSubscription = this.uiSyncService.loadObjectEvtEmitter.subscribe(() => {
      this.loadObjectRootFormSubscription.unsubscribe();
    });
  }
}
