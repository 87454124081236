import { Component, OnInit } from '@angular/core';
import { LpMovement } from '../../meta/movement';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-movements',
  templateUrl: './movements.component.html',
})
export class MovementsComponent extends RootformComponent implements OnInit {

  public data: LpMovement = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('movements', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
