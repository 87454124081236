import { Component, OnInit } from '@angular/core';
import { LpCompany } from '../../meta/company';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-companies',
  templateUrl: './companies.component.html'
})
export class CompaniesComponent  extends RootformComponent implements OnInit {

  public data: LpCompany = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('companies', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
       this.data.assign(this.formStackService.currentData);
    }
  }
}
