<div id="modalPlanning" bindField="bindField" secondBindField="secondBindField" ngDefaultControl>

<div class="modal-header modal-planning-header p-2">
  <div class="col-10">
    <h5 class="modal-title pull-left m-0 me-3">{{'general.planning' |  translate}}</h5>
    <ul class="active-filter-container m-1" [hidden]="!isFilterActive()">
      <li *ngFor="let filter of activeFilter" class="ms-2 ps-2 pe-4" (click)="resetFilter(filter[0])"
        title="{{'scheduler.deleteThisFilter' | translate}}">
        <span class="active-filter"><small>{{filter[0] | translate}}:</small><br /><b>{{filter[1]}}</b></span>
        <i class="icon vega-delete" aria-hidden="true"></i>
      </li>
      <li class="ms-2 me-3 ps-2 pe-4" (click)="resetFilter()" title="{{'scheduler.deleteAllFilter' | translate}}">
        <span
          class="active-filter"><small>{{'scheduler.delete' | translate}}:</small><br /><b>{{'scheduler.allFilter' | translate}}</b></span>
        <i class="icon vega-delete" aria-hidden="true"></i>
      </li>
    </ul>
  </div>

  <div class="mb-0 pull-right">
    <!-- container pour afficher de manière fixed/static le bouton de filtrage dans le bandeau noire (ce btn permet d'afficher le panneau de filtre ci dessous) -->
    <div class="planning-btn scheduler-right-button" [class.active]="showFilterPanel">
      <i class="icon fa fa-filter filter-icon-scheduler" aria-hidden="true" title="{{'scheduler.configure' | translate}}" (click)="toggleFilterPlanning()" id="toggleSitemap"></i>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-2 text-end">
    <lp-button-cancel (action)="closeModal()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">
  <div class="scheduler ms-2 me-2 pe-4 ps-4" *ngIf="d">
    <lp-scheduler [x]="x" [data]="d" class="component" [isMovementsDetailOpen]="false" [isFilterPanelOpen]="showFilterPanel" [filter]="filter"
      (outputData)="send($event)" (configPanelState)="toggleConfigPlanning($event)" [startDate]="startDate" [endDate]="endDate" [isZoom]="true"
      [(isLoaded)]="isLoaded"></lp-scheduler>
  </div>



  <div class="filter-tab" *ngIf="showFilterPanel">      
    <a class="ms-2 btn btn-danger btn-scheduler-panel-close" aria-label="Close" (click)="toggleFilterPlanning();">
        <i class="fa fa-times"></i>
    </a>
    <div class="" [class.show]="filterPlanningBtn">
      <div class="filter-panel">
        <h3 class="col-12">{{'scheduler.filters' | translate}}</h3>
        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="registration" text="scheduler.panelConfiguration.registration"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-text-field id="registration" className="textField" cssClass="form-control" name="registration" [ignoreChange]="true" [(ngModel)]="schedulerFilter.registration.registration"></lp-text-field>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="category"  text="scheduler.panelConfiguration.category"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-dropdown-field className="textField" cssClass="form-control" name="category" [ignoreChange]="true" [required]=false [(ngModel)]="schedulerFilter.category" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'category'}]}"></lp-dropdown-field>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="brand" text="scheduler.panelConfiguration.brand"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-dropdown-field className="textField" cssClass="form-control" name="brand" [ignoreChange]="true" [required]=false [(ngModel)]="schedulerFilter.brand" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'brand'}]}" ></lp-dropdown-field>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="type" text="scheduler.panelConfiguration.type"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-dropdown-field className="textField" cssClass="form-control" name="type" [ignoreChange]="true" [required]=false [(ngModel)]="schedulerFilter.type" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'equipmentType'}]}"></lp-dropdown-field>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="family" text="scheduler.panelConfiguration.family"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-dropdown-field className="textField" cssClass="form-control" name="family" [ignoreChange]="true" [required]=false [(ngModel)]="schedulerFilter.family" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'family'}]}"></lp-dropdown-field>
          </div>
        </div>

        <!--<div class="row">
          <div class="col-sm-5 p-0">
            <lp-label-field for="model" text="scheduler.panelConfiguration.model"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-dropdown-field tableToCheck="id" className="textField" cssClass="form-control" name="model" [ignoreChange]="true" [values]="model" tableValueBox="wording" [required]=false [(ngModel)]="schedulerFilter.model" (change)="filterPlanning(filter)"></lp-dropdown-field>
          </div>
        </div>-->

        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="owner" text="scheduler.panelConfiguration.owner"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-zoom-field id="owner" addModifyVerb="third-parties"  libSearch="lastName" zoomId='owner' className="zoomField" cssClass="form-control" kySearch='id' 
            [(ngModel)]="schedulerFilter.owner" [inputSearch]="schedulerFilter.owner?.id" searchTextField="id" [modal]=true [tableBindValues]="['id','lastName']" [tableShowField]="['lastName']"
            ecrId="" name="owner" [ignoreChange]="true"></lp-zoom-field>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="company"  text="scheduler.panelConfiguration.company"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-zoom-field id="company" addModifyVerb="companies"  libSearch="corporateName"
              zoomId='company' className="zoomField" cssClass="form-control" [(ngModel)]="schedulerFilter.company" [inputSearch]="schedulerFilter.company?.id"
              searchTextField="id" [tableBindValues]="['id','corporateName']" [tableShowField]="['corporateName']" kySearch='id'
              ecrId="" [ignoreChange]="true"
              name="company" [modal]=true></lp-zoom-field>
          </div>
        </div>

        <div class="row m-0">
            <div class="col-sm-5 p-0">
              <lp-label-field for="administrativeLocation" text="scheduler.panelConfiguration.administrativeLocation"></lp-label-field>
            </div>
            <div class="col-sm-7 pe-0">
              <lp-zoom-field id="administrativeLocation" addModifyVerb="locations"  libSearch="wording"
                zoomId='administrativeLocation' className="zoomField" cssClass="form-control" [(ngModel)]="schedulerFilter.administrativeLocation" [inputSearch]="schedulerFilter.administrativeLocation?.id"
                searchTextField="id" [tableBindValues]="['id','wording']" [tableShowField]="['wording']" kySearch='id'
                ecrId="" [ignoreChange]="true" name="administrativeLocation" [modal]=true></lp-zoom-field>
            </div>
        </div>

        <div class="row m-0">
          <div class="col-sm-5 p-0">
            <lp-label-field for="actualLocation" text="scheduler.panelConfiguration.actualLocation"></lp-label-field>
          </div>
          <div class="col-sm-7 pe-0">
            <lp-zoom-field id="actualLocation" addModifyVerb="locations"  libSearch="wording"
              zoomId='actualLocation' className="zoomField" cssClass="form-control" [(ngModel)]="schedulerFilter.actualLocation" [inputSearch]="schedulerFilter.actualLocation?.id"
              searchTextField="id" [tableBindValues]="['id','wording']" [tableShowField]="['wording']" kySearch='id'
              ecrId="" [ignoreChange]="true" name="actualLocation" [modal]=true></lp-zoom-field>
          </div>
        </div>

        <div class="col-sm-7 pe-0">
        </div>
        <!-- Bouton pour appliquer les filtres de type zoom uniquement -->
        <div class="col-12 mb-3">
          <button (click)="filterPlanning(filter)" class="btn btn-primary pull-right">{{'scheduler.panelConfiguration.apply' | translate}}</button>
        </div>

          
        </div>
      </div>
    </div>


</div>
</div>
