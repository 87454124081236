<lp-ui-accordion class="row" [menu]=true>
    <div *ngFor="let s of applicationItem.editForm.accordions; let i = index">
        <!--use of 'hidden' instead of '*ngIf' because we want fomStackService.currentFields to contain all fields, even hidden ones-->
        <lp-ui-accordion-group heading="{{s.title}}" [open]="(i===0 || s.open) ? true : false" key="{{'tab-'+i}}" [hidden]="!(s.hidden===null || s.hidden===undefined || s.hidden===false)">
            <div *ngIf="s.rows ">
                <div *ngFor="let r of s.rows; let j = index" class="row" [ngClass]="r.classes">
                    <div *ngFor="let f of r.fields; let j = index" [ngClass]="getRowSize(s, r)" [class.d-none]="f.type === 'hidden'">
                        <lp-fields [f]="f" [data]="data" [ngClass]="getCssClass(s, r, subgroup)" [isDetail]="false" namePrefix="{{namePrefix}}"></lp-fields>
                    </div>
                </div>
            </div>

            <div *ngIf="s.subgroup"> 
                <div *ngFor="let subgroup of s.subgroup">
                    <lp-subgroup [bordertop]="true" label="{{subgroup.title}}">
                        <div *ngFor="let r of subgroup.rows; let j = index" class="row" [ngClass]="r.classes">
                            <div *ngFor="let f of r.fields; let j = index" [ngClass]="getRowSize(s, r, subgroup)">
                                <lp-fields [f]="f" [data]="data" [ngClass]="getCssClass(s, r, subgroup)" [isDetail]="false" namePrefix="{{namePrefix}}"></lp-fields>
                            </div>
                        </div>
                    </lp-subgroup>
                </div>
            </div> 
        </lp-ui-accordion-group>
    </div>

    <lp-ui-accordion-group heading="Debugger formulaire" *ngIf="debugg">
        <pre *ngIf="!dataInput">{{ formStackService.currentData | json }}</pre>
        <pre *ngIf="dataInput">{{ dataInput }}</pre>
    </lp-ui-accordion-group>
</lp-ui-accordion>