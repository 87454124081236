import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent } from '../detail-forms.component';
import { Util } from 'app/statics/utils';
import { LpLocationsType } from 'app/meta/locations-type';

@Component({
  selector: 'lp-locations-type',
  templateUrl: './locations-type.component.html'
})
export class LocationsTypeComponent extends DetailFormsComponent implements OnInit {

  public data: LpLocationsType = null;

  public ngOnInit(): void {
      super.initData();
      if (Util.isNullOrUndefined(this.data)) {
        this.data = new LpLocationsType();
      }
      if (Util.isNullOrUndefined(this.formStackService.currentDetailLineData)) {
        this.formStackService.currentDetailLineData = new LpLocationsType();
      }
      this.data.assign(this.formStackService.currentDetailLineData);

      this.initUrl();
      // this.init();
  }

  public init(): void {
    this.loadObjectRootFormSubscription = this.uiSyncService.loadObjectEvtEmitter.subscribe(() => {
      this.loadObjectRootFormSubscription.unsubscribe();
    });
  }
}
