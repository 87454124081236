import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GridsterComponent, IGridsterOptions, IGridsterDraggableOptions } from '@khajegan/angular2gridster';
import { Widget, Dashboard } from 'app/models/dashboard';
import { DashboardProvider } from 'app/providers/dashboard.provider';
import { ConfigService } from 'app/services/config.service';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';

export const TYPES: any[] = [
  { id: 1, description: 'chart' },
  { id: 2, description: 'arrows' }
];

@Component({
  selector: 'lp-dashboard',
  templateUrl: 'lp-dashboard.component.html',
  styleUrls: ['lp-dashboard.component.scss']
})
export class LpDashboardComponent implements OnInit, OnDestroy {

  @ViewChild(GridsterComponent, {static: false}) public gridster: GridsterComponent;

  public items: Widget[] = [];
  public type: any[] = TYPES;
  public loading: Boolean = false;
  public activeButtonCloseWidget: Boolean = false;
  public titleWidget: Boolean = true;
  public gridsterOptions: IGridsterOptions = {
    lanes: 12, // how many lines (grid cells) dashboard has
    direction: 'vertical', // items floating direction: vertical/horizontal
    dragAndDrop: !this.configService.get('readonlyHomepage'), // possible to change items position by drag n drop
    useCSSTransforms: false,
    responsiveView: false,
    floating: false,
    widthHeightRatio: 1,
    shrink: true,
    resizable: !this.configService.get('readonlyHomepage'),
    responsiveOptions: [
      { breakpoint: 'sm', minWidth: 0},
      { breakpoint: 'md', minWidth: 768,},
      { breakpoint: 'lg', minWidth: 1250},
      { breakpoint: 'xl', minWidth: 4000}
    ],
  };

  public gridsterDraggableOptions: IGridsterDraggableOptions = { handlerClass: 'card-header' };
  public dashboardId: number;
  private dashboard: Dashboard;

  constructor(
    private uiSyncService: UiSyncService,
    private dashboardProvider: DashboardProvider,
    private configService: ConfigService,
    private formstackService: FormStackService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.subscriptions();
    this.dashboard = await this.dashboardProvider.getMyDashboardLevel();
    this.items = this.dashboard.data;
    this.formstackService.dashboard = this.dashboard;
  }

  public ngOnDestroy(): void {
    //this.unsubscribe()
  }

  public optionsChange(options: IGridsterOptions): void {
    this.gridsterOptions = options;
  }

  public itemChanged(item?: any, event?: any): void {
    this.formstackService.dashboard.data = this.items;
    this.uiSyncService.FooterWidth = 1;
    if (event === undefined ) {
      this.changes();
    }
  }

  public changes(): void {
    this.dashboard.data = this.items;
  }


  public closeWidget(widget: Widget): void {
    widget.active = false;
    this.itemChanged();
  }

  private subscriptions(): void {
    this.uiSyncService.reloadDashboardAfterChangeLocation.subscribe(async () => {
      this.dashboard = await this.dashboardProvider.getMyDashboardLevel();
      this.items = this.dashboard.data; 
      this.formstackService.dashboard = this.dashboard;
    });

    this.uiSyncService.reloadDashboardChangeWidgets.subscribe(async () => {
      this.dashboard = this.formstackService.dashboard;
      this.items = this.dashboard.data; 
    });
  }

  /*private unsubscribe(): void {
    this.uiSyncService.reloadDashboardAfterChangeLocation.unsubscribe();
    this.uiSyncService.reloadDashboardChangeWidgets.unsubscribe();
  }*/

}
