import { Component, OnInit } from '@angular/core';
import { ACCOUNTING, ACCOUNTING_EVENTS, ADDITIONAL_DRIVERS, ADDRESSES,
  CIRCUMSTANCE_LINKS, CONTACTS, LOCATION_CONTACTS, CRASH_LINKS, CUSTOMER_DEPOSITS, DEPOSITS,
  DRIVER_DEPOSITS, EQUIPMENT_RELATIONS, FACTURATION_LINKS, FINANCIAL_ELEMENTS_LINKS, HISTORICAL_MILEAGE,
  INVOICE_LINES, KINDS_LINKS, LINK_DAMAGE_HISTORICAL_OPERATIONS, LINK_DAMAGE_VALUE, OPENNING_HOURS, REGISTRATIONS,
  THIRD_PARTIES_RELATIONS, THIRD_PARTY_LINKS, RIBS, SHORT_TERM_CONTRACTS_MEDIAS, TECHNICAL_SPECIFICATION,
  THIRD_PARTY_DEPOSIT, DetailFormsComponent, DRIVER_RELATIONS, CUSTOMER_RELATIONS, CUSTOMER_RIBS, DRIVER_RIBS,
  THIRD_PARTY_TYPE, CUSTOMER_TYPE, DRIVER_TYPE, CUSTOMER_CONTACTS, DRIVER_CONTACTS, CUSTOMER_ADDRESSES, DRIVER_ADDRESSES,
  CUSTOMER_ACCOUNTING, DRIVER_ACCOUNTING, CUSTOMER_DEPOSIT, DRIVER_DEPOSIT, THIRD_PARTY_MANDATES, DRIVER_MANDATES,
  CUSTOMER_MANDATES, LOCATION_SITUATION, LOCATION_TYPE, USER_GROUPS, COMPANIES_IHUBCHECKPLUS,LOCATION_SECTOR
} from '../../detail-forms.component';
import * as _ from 'underscore';

@Component({
  selector: 'lp-sub-api',
  templateUrl: './sub-api.component.html'
})
export class SubApiComponent extends DetailFormsComponent implements OnInit {

  public ACCOUNTING: string = ACCOUNTING;
  public ACCOUNTING_EVENTS: string = ACCOUNTING_EVENTS;
  public ADDITIONAL_DRIVERS: string = ADDITIONAL_DRIVERS;
  public ADDRESSES: string = ADDRESSES;
  public CIRCUMSTANCE_LINKS: string = CIRCUMSTANCE_LINKS;
  public CONTACTS: string = CONTACTS;
  public LOCATION_CONTACTS: string = LOCATION_CONTACTS;
  public CUSTOMER_CONTACTS: string = CUSTOMER_CONTACTS;
  public DRIVER_CONTACTS: string = DRIVER_CONTACTS;
  public CRASH_LINKS: string = CRASH_LINKS;
  public CUSTOMER_ACCOUNTING: string = CUSTOMER_ACCOUNTING;
  public CUSTOMER_ADDRESSES: string = CUSTOMER_ADDRESSES;
  public CUSTOMER_DEPOSITS: string = CUSTOMER_DEPOSITS;
  public CUSTOMER_DEPOSIT: string = CUSTOMER_DEPOSIT;
  public CUSTOMER_TYPE: string = CUSTOMER_TYPE;
  public CUSTOMER_RIBS: string = CUSTOMER_RIBS;
  public CUSTOMER_RELATIONS: string = CUSTOMER_RELATIONS;
  public CUSTOMER_INVOICE: string = 'customerInvoices';
  public CUSTOMER_ACCOUNTING_EVENTS: string = 'customerAccountingEvents';
  public DEPOSITS: string = DEPOSITS;
  public DRIVER_ACCOUNTING: string = DRIVER_ACCOUNTING;
  public DRIVER_ADDRESSES: string = DRIVER_ADDRESSES;
  public DRIVER_ACCOUNTING_EVENT: string = 'driverAccountingEvents';
  public DRIVER_DEPOSITS: string = DRIVER_DEPOSITS;
  public DRIVER_DEPOSIT: string = DRIVER_DEPOSIT;
  public DRIVER_INVOICES: string = 'driverInvoices';
  public DRIVER_RIBS: string = DRIVER_RIBS;
  public DRIVER_RELATIONS: string = DRIVER_RELATIONS;
  public DRIVER_TYPE: string = DRIVER_TYPE;
  public EQUIPMENT_RELATIONS: string = EQUIPMENT_RELATIONS;
  public FACTURATION_LINKS: string = FACTURATION_LINKS;
  public FINANCIAL_ELEMENTS_LINKS: string = FINANCIAL_ELEMENTS_LINKS;
  public HISTORICAL_MILEAGE: string = HISTORICAL_MILEAGE;
  public INVOICE_LINES: string = INVOICE_LINES;
  public KINDS_LINKS: string = KINDS_LINKS;
  public LINK_DAMAGE_HISTORICAL_OPERATIONS: string = LINK_DAMAGE_HISTORICAL_OPERATIONS;
  public LINK_DAMAGE_VALUE: string = LINK_DAMAGE_VALUE;
  public MOVEMENTS: string = 'movements';
  public OPENNING_HOURS: string = OPENNING_HOURS;
  public REGISTRATIONS: string = REGISTRATIONS;
  public RELATIONS: string = 'relations';
  public THIRD_PARTIES_RELATIONS: string = THIRD_PARTIES_RELATIONS;
  public THIRD_PARTY_LINKS: string = THIRD_PARTY_LINKS;
  public RIBS: string = RIBS;
  public SHORT_TERM_CONTRACTS_INVOICE_LINES: string = 'shortTermContractsInvoiceLines';
  public SHORT_TERM_CONTRACTS_DRIVER_INVOICE_LINES: string = 'shortTermContractsDriverInvoiceLines';
  public SHORT_TERM_CONTRACTS_MEDIAS: string = SHORT_TERM_CONTRACTS_MEDIAS;
  public TECHNICAL_SPECIFICATION: string = TECHNICAL_SPECIFICATION;
  public THIRD_PARTY_DEPOSIT: string = THIRD_PARTY_DEPOSIT;
  public THIRD_PARTY_TYPE: string = THIRD_PARTY_TYPE;
  public THIRD_PARTY_MANDATES: string = THIRD_PARTY_MANDATES;
  public DRIVER_MANDATES: string = DRIVER_MANDATES;
  public CUSTOMER_MANDATES: string = CUSTOMER_MANDATES;
  public USER_GROUPS: string = USER_GROUPS;
  public LOCATION_TYPE: string = LOCATION_TYPE;
  public LOCATION_SITUATION: string = LOCATION_SITUATION;
  public LOCATION_SECTOR: string = LOCATION_SECTOR;
  public COMPANIES_IHUBCHECKPLUS: string = COMPANIES_IHUBCHECKPLUS;

  public ngOnInit(): void {
  }
}
