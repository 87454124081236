import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, CRASH_LINKS } from '../detail-forms.component';
import { LpCrashLink } from 'app/meta/crash-link';


@Component({
  selector: 'lp-crash-links',
  templateUrl: './crash-links.component.html'
})

export class CrashLinksComponent extends DetailFormsComponent implements OnInit {

  public data: LpCrashLink = null;

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(CRASH_LINKS);
    this.initUrl();
    // this.init();
  }

}
