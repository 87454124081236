<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="models.model"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire -->
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null"
          [deleteButton]="deleteButton" object="model"></lp-common-tools>
          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>

          <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">

            <div class="row">
              <!--Field Id Model-->
              <div class="form-group col-xl-6">
                <lp-text-field id="id" className="textField" label="models.id"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id"
                    [(ngModel)]="data.id" [disabled]=true></lp-text-field>
              </div>
              <div class="form-group col-xl-6">
                <!--Field label Model-->
                <lp-text-field id="label" className="textField" label="models.label"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="label"
                  [(ngModel)]="data.label"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!--Field equipmentType Model-->
                <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                  id="equipmentType" cssClass="form-control" name="equipmentType" label="models.equipmentType.title"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'equipmentType'}]}"
                  [(ngModel)]="data.equipmentType" ></lp-dropdown-field>-->
                <lp-zoom-field  id="equipmentType" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="equipmentType" [(ngModel)]="data.equipmentType" label="models.equipmentType.title"
                  className="zoomField" cssClass="form-control" name="equipmentType"></lp-zoom-field> 
              </div>
              <!--Field codexCode Model-->
              <div class="form-group col-xl-6">
                <lp-zoom-field  id="location" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="location" [(ngModel)]="data.location" label="models.location" 
                  className="zoomField" cssClass="form-control" name="location"></lp-zoom-field>                        
              </div>
            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="models.personalModel" [open]=false key="personalModel">


            <!--field acrissCode-->
            <div class="row">
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.acris" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.acris"label="models.personalModel.acris"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'acriss'}]}"
                  [(ngModel)]="data.personalModel.acris"></lp-dropdown-field>-->
                <lp-zoom-field  id="personalModel.acriss" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="acris" [(ngModel)]="data.personalModel.acris" label="models.personalModel.acris"
                  className="zoomField" cssClass="form-control" name="personalModel.acris"></lp-zoom-field> 
              </div>
              <div class="form-group col-xl-6">
                <!--field body-->
                <!--<lp-dropdown-field id="personalModel.body" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.body" label="models.personalModel.body"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'body'}]}"
                  [(ngModel)]="data.personalModel.body"></lp-dropdown-field>-->
                <lp-zoom-field  id="personalModel.body" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="body" [(ngModel)]="data.personalModel.body" label="models.personalModel.body"
                  className="zoomField" cssClass="form-control" name="personalModel.body"></lp-zoom-field> 
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.brand" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.brand" label="models.personalModel.brand"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'brand'}]}"
                  [(ngModel)]="data.personalModel.brand"></lp-dropdown-field>-->
                <lp-zoom-field  id="personalModel.brand" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="brand" [(ngModel)]="data.personalModel.brand" label="models.personalModel.brand"
                  className="zoomField" cssClass="form-control" name="personalModel.brand"></lp-zoom-field> 
              </div>
              <!-- field kind -->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.kind" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="kind" label="models.personalModel.kind"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'kind'}]}"
                  [(ngModel)]="data.personalModel.kind"></lp-dropdown-field>-->
                <lp-zoom-field  id="personalModel.kind" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="kind" [(ngModel)]="data.personalModel.kind" label="models.personalModel.kind"
                  className="zoomField" cssClass="form-control" name="personalModel.kind"></lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!-- field category -->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.category" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.category" label="models.personalModel.category"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'category'}]}"
                  [(ngModel)]="data.personalModel.category"></lp-dropdown-field>-->
                <lp-zoom-field  id="personalModel.category" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="category" [(ngModel)]="data.personalModel.category" label="models.personalModel.category"
                  className="zoomField" cssClass="form-control" name="personalModel.category"></lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.counterKind" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.counterKind" label="models.personalModel.counterKind"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'kilometerKind'}]}"
                  [(ngModel)]="data.personalModel.counterKind"></lp-dropdown-field>-->
                <lp-zoom-field  id="personalModel.counterKind" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="counterKind" [(ngModel)]="data.personalModel.counterKind" label="models.personalModel.counterKind"
                  className="zoomField" cssClass="form-control" name="personalModel.counterKind"></lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field doors -->
                <lp-number id="personalModel.doors" cssClass="form-control" label="models.personalModel.doors"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.doors"
                  [(ngModel)]="data.personalModel.doors"></lp-number>
              </div>
              <!-- field energy -->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.energy" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.energy" label="models.personalModel.energy"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'energy'}]}"
                  [(ngModel)]="data.personalModel.energy"></lp-dropdown-field>-->
                <lp-zoom-field  id="personalModel.energy" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="energy" [(ngModel)]="data.personalModel.energy" label="models.personalModel.energy"
                  className="zoomField" cssClass="form-control" name="personalModel.energy"></lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--field motorCode-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.motorCode" className="textField" label="models.personalModel.motorCode"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.motorCode"
                  [(ngModel)]="data.personalModel.motorCode"></lp-text-field>
              </div>
              <div class="form-group col-xl-6">
                <!-- Field isLimitedSerie -->
                  <lp-boolean-switch-box name="personalModel.isLimitedSerie" switchName="personalModel.isLimitedSerie" label="models.personalModel.isLimitedSerie"
                  [(ngModel)]="data.personalModel.isLimitedSerie"></lp-boolean-switch-box>
              </div>
            </div>

            <lp-subgroup [bordertop]="true">
              <!-- field urbanConsumption  -->
              <div class="row">
                <div class="form-group col-xl-6">
                  <!--field tankCapacity -->
                  <lp-number id="personalModel.tankCapacity" cssClass="form-control" label="models.personalModel.tankCapacity"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.tankCapacity"
                    [(ngModel)]="data.personalModel.tankCapacity">
                  </lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-number id="personalModel.urbanConsumption" cssClass="form-control" label="models.personalModel.urbanConsumption"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.urbanConsumption"
                    [(ngModel)]="data.personalModel.urbanConsumption"></lp-number>
                </div>
              </div>
              <!-- field highwayConsumption  -->
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="personalModel.mixedConsumption" cssClass="form-control" label="models.personalModel.mixedConsumption"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.mixedConsumption"
                    [(ngModel)]="data.personalModel.mixedConsumption"></lp-number>
                </div>
                <!-- field mixedConsumption  -->
                <div class="form-group col-xl-6">
                  <lp-number id="personalModel.highwayConsumption" cssClass="form-control" label="models.personalModel.highwayConsumption"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.highwayConsumption"
                    [(ngModel)]="data.personalModel.highwayConsumption"></lp-number>
                </div>
              </div>

            </lp-subgroup>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="models.maintenance" [open]=false key="maintenance">
            <div class="row">
              <!-- field maintenanceModelsRules -->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.maintenanceModelsRules"
                  class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                  cssClass="form-control" name="personalModel.maintenanceModelsRules" label="models.personalModel.maintenanceModelsRules"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'maintenanceModelRule'}]}"
                  [(ngModel)]="data.personalModel.maintenanceModelsRules"></lp-dropdown-field>-->
                <lp-zoom-field id="personalModel.maintenanceModelsRules" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="maintenanceModelsRules" [(ngModel)]="data.personalModel.maintenanceModelsRules" label="models.personalModel.maintenanceModelsRules"
                  className="zoomField" cssClass="form-control" name="personalModel.maintenanceModelsRules"></lp-zoom-field>
              </div>
              <!--mcpeModel-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.mcpeModel" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.mcpeModel" label="models.personalModel.mcpeModel"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'mcpeModel'}]}"
                  [(ngModel)]="data.personalModel.mcpeModel"></lp-dropdown-field>-->
                <lp-zoom-field id="personalModel.mcpeModel" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="mcpeModel" [(ngModel)]="data.personalModel.mcpeModel" label="models.personalModel.mcpeModel"
                  className="zoomField" cssClass="form-control" name="personalModel.mcpeModel"></lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--tyresType-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.tyresType" className="textField" label="models.personalModel.tyresType"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.tyresType"
                  [(ngModel)]="data.personalModel.tyresType"></lp-text-field>
              </div>
              <!--Tyrestate-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.tyreState" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.tyreState" label="models.personalModel.tyreState"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'tyreState'}]}"
                  [(ngModel)]="data.personalModel.tyreState"></lp-dropdown-field>-->
                <lp-zoom-field id="personalModel.tyreState" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="tyreState" [(ngModel)]="data.personalModel.tyreState" label="models.personalModel.tyreState"
                  className="zoomField" cssClass="form-control" name="personalModel.tyreState"></lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--BreakState-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="personalModel.breakState" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.breakState" label="models.personalModel.breakState"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'breakState'}]}"
                  [(ngModel)]="data.personalModel.breakState"></lp-dropdown-field>-->
                <lp-zoom-field id="personalModel.breakState" class="{{arrayClassesElements.get('defaultInput')}}"
                  zoomId="breakState" [(ngModel)]="data.personalModel.breakState" label="models.personalModel.breakState"
                  className="zoomField" cssClass="form-control" name="personalModel.breakState"></lp-zoom-field>
              </div>
            </div>

          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="models.registration" [open]=false key="registration">

            <div class="row">
              <!--field mineType-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.mineType" className="textField" label="models.personalModel.mineType"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.mineType"
                  [(ngModel)]="data.personalModel.mineType"></lp-text-field>
              </div>
              <div class="form-group col-xl-6">
                <!--field fiscalPower-->
                <lp-number id="personalModel.fiscalPower" cssClass="form-control" label="models.personalModel.fiscalPower"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.fiscalPower"
                  [(ngModel)]="data.personalModel.fiscalPower">
                </lp-number>
              </div>
            </div>

            <div class="row">
              <!--field type_variant "D2"-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationD2" className="textField" label="models.personalModel.registrationD2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.registrationD2"
                  [(ngModel)]="data.personalModel.registrationD2"></lp-text-field>
              </div>
              <!--field tradeName "dénomination commerciale D3"-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationD3" className="textField" label="models.personalModel.registrationD3"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.registrationD3"
                  [(ngModel)]="data.personalModel.registrationD3"></lp-text-field>
              </div>
            </div>

            <!--field max_load_mass "F1"-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationF1" cssClass="form-control" label="models.personalModel.registrationF1"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationF1"
                  [(ngModel)]="data.personalModel.registrationF1">
                </lp-number>
              </div>
              <!--field max_load_mass "F2"-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationF2" cssClass="form-control" label="models.personalModel.registrationF2"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationF2"
                  [(ngModel)]="data.personalModel.registrationF2">
                </lp-number>
              </div>
            </div>

            <div class="row">
              <!--field registrationF3 "PTRA"-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationF3" cssClass="form-control" label="models.personalModel.registrationF3"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationF3"
                  [(ngModel)]="data.personalModel.registrationF3">
                </lp-number>
              </div>
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationG" cssClass="form-control" label="models.personalModel.registrationG"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationG"
                  [(ngModel)]="data.personalModel.registrationG">
                </lp-number>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationG1" cssClass="form-control" label="models.personalModel.registrationG1"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="registrationG1"
                  [(ngModel)]="data.personalModel.registrationG1">
                </lp-number>
              </div>
              <!--field registrationJ "catégorie"-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationJ" className="textField" label="models.personalModel.registrationJ"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="registrationJ"
                  [(ngModel)]="data.personalModel.registrationJ"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--field registrationJ2 "body"-->
              <div class="form-group col-xl-6">
                <lp-text-field id="registrationJ2Model" className="textField" label="models.personalModel.registrationJ2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="registrationJ2" [(ngModel)]="data.personalModel.registrationJ2"></lp-text-field>
              </div>

              <!--field registration P2-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationP2" className="textField" label="models.personalModel.registrationP2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationP2"
                  [(ngModel)]="data.personalModel.registrationP2"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--field registration S1-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationS1" className="textField" label="models.personalModel.registrationS1"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationS1"
                  [(ngModel)]="data.personalModel.registrationS1"></lp-text-field>
              </div>

              <!--field registration S2-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationS2" className="textField" label="models.personalModel.registrationS2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationS2"
                  [(ngModel)]="data.personalModel.registrationS2"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--field registrationU1 niveau sonore arrété "U1"-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationU1" className="textField" label="models.personalModel.registrationU1"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationU1"
                  [(ngModel)]="data.personalModel.registrationU1"></lp-text-field>
              </div>
              <!--field speedEngin class "U2"-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationU2" className="textField" label="models.personalModel.registrationU2"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="speedEngin"
                  [(ngModel)]="data.personalModel.registrationU2"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--field CO2 "V7"-->
              <div class="form-group col-xl-6">
                <lp-number id="personalModel.registrationV7" cssClass="form-control" label="models.personalModel.registrationV7"
                  class="{{arrayClassesElements.get('defaultInput')}}" name="personalModel.registrationV7"
                  [(ngModel)]="data.personalModel.registrationV7">
                </lp-number>
              </div>
              <!--field environmental class "V9"-->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.registrationV9" className="textField" label="models.personalModel.registrationV9"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="personalModel.registrationV9"
                  [(ngModel)]="data.personalModel.registrationV9"></lp-text-field>
              </div>
            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="models.various" [open]=false key="various">

            <div class="row">
              <div class="form-group col-xl-6">
                <!-- field state -->
                <!--<lp-dropdown-field id="personalModel.state" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="personalModel.state" label="models.personalModel.state"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'state'}]}"
                  [(ngModel)]="data.personalModel.state"></lp-dropdown-field>-->
                <lp-zoom-field id="personalModel.state" class="{{arrayClassesElements.get('defaultInput')}}"
                    zoomId="state" [(ngModel)]="data.personalModel.state" label="models.personalModel.state"
                    className="zoomField" cssClass="form-control" name="personalModel.state"></lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <!--Field codexCode Model-->
                <lp-text-field id="codexCode" className="textField" label="models.codexCode"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="codexCode" [(ngModel)]="data.codexCode"></lp-text-field>
              </div>
            </div>

            <div class="row">                    
              <!-- field eanCode -->
              <div class="form-group col-xl-6">
                <lp-text-field id="personalModel.eanCode" className="textField" label="models.personalModel.eanCode"
                  class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="personalModel.eanCode"
                  [(ngModel)]="data.personalModel.eanCode"></lp-text-field>
              </div>
            </div>

            <lp-subgroup [bordertop]="true">

              <!-- field family / family1 / family2 / family3 -->
              <div class="row">
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field id="personalModel.family" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="personalModel.family" label="models.personalModel.family"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'family'}]}"
                    [(ngModel)]="data.personalModel.family"></lp-dropdown-field>-->
                  <lp-zoom-field id="personalModel.family" class="{{arrayClassesElements.get('defaultInput')}}"
                    zoomId="family" [(ngModel)]="data.personalModel.family" label="models.personalModel.family"
                    className="zoomField" cssClass="form-control" name="personalModel.family"></lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field id="personalModel.family1" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="personalModel.family1" label="models.personalModel.family1"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'family'}]}"
                    [(ngModel)]="data.personalModel.family1"></lp-dropdown-field>-->
                  <lp-zoom-field id="personalModel.family1" class="{{arrayClassesElements.get('defaultInput')}}"
                    zoomId="family1" [(ngModel)]="data.personalModel.family1" label="models.personalModel.family1"
                    className="zoomField" cssClass="form-control" name="personalModel.family1"></lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field id="personalModel.family2" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="family2" label="models.personalModel.family2"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'family'}]}"
                    [(ngModel)]="data.personalModel.family2"></lp-dropdown-field>-->
                  <lp-zoom-field id="personalModel.family2" class="{{arrayClassesElements.get('defaultInput')}}"
                    zoomId="family2" [(ngModel)]="data.personalModel.family2" label="models.personalModel.family2"
                    className="zoomField" cssClass="form-control" name="personalModel.family2"></lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field id="personalModel.family3" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="personalModel.family3" label="models.personalModel.family3"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'family'}]}"
                    [(ngModel)]="data.personalModel.family3"></lp-dropdown-field>-->
                  <lp-zoom-field id="personalModel.family3" class="{{arrayClassesElements.get('defaultInput')}}"
                    zoomId="family3" [(ngModel)]="data.personalModel.family3" label="models.personalModel.family3"
                    className="zoomField" cssClass="form-control" name="personalModel.family3"></lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <hr class="col-sm-10 mb-3 mt-0" />
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <!--Field directorWord Model-->
                  <lp-text-field id="directorWord" className="textField" label="models.directorWord"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="directorWord" [(ngModel)]="data.directorWord">
                  </lp-text-field>
                </div>
              </div>
            </lp-subgroup>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>