import { Component, OnInit, Input, Inject } from '@angular/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { LpUiAccordionComponent } from './lp-ui-accordion.component';

@Component({
  selector: 'lp-ui-accordion-group',
  templateUrl: './lp-ui-accordion-group.component.html',
  styleUrls: ['./lp-ui-accordion-group.component.scss']
})
export class LpUiAccordionGroupComponent implements OnInit {
    @Input() public heading: string;
    @Input() public icon: String = '';
    @Input() public panelClass: string;
    @Input() public open: Boolean;
    @Input() public key: String;

    protected accordion: LpUiAccordionComponent;

    constructor(@Inject(LpUiAccordionComponent) accordion: LpUiAccordionComponent, public uiSyncService: UiSyncService) {
        this.accordion = accordion;
      }

    public ngOnInit(): void {
      if (this.key === null || this.key === undefined) {
        const stringArr = [];
        for(let i = 0; i< 5; i++){
          const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
          stringArr.push(S4);
        }
        this.key = stringArr.join('-');
      }
      if (this.open === true) {
        this.uiSyncService.accordiongroups.set(this.key, true);
      } else {
        this.uiSyncService.accordiongroups.set(this.key, false);
      }
    }

    public toggleOpen(): void {
        this.accordion.closeOtherPanels(this);
        this.uiSyncService.accordiongroups.set(this.key, !this.uiSyncService.accordiongroups.get(this.key));
    }

    public oppend(): Boolean {
      if(this.key && this.uiSyncService.accordiongroups) {
        return this.uiSyncService.accordiongroups.get(this.key);
      }
      return false;
    }
}
