import { LpMeta, propertyMeta } from './meta';

export class LpChangePassword extends LpMeta {
    @propertyMeta('string')
    public oldPassword: String;
    @propertyMeta('string')
    public newPassword: String;
    @propertyMeta('string')
    public newPassword2: String;
}
