import { LpLocation } from '../location';
import { LpRegistration } from '../registration';
import { FtMeta } from '../ft-meta';
import { LpModel } from '../model';
import { LpThirdParty } from '../third-party';
import { LpCompany } from '../company';

import { LpMeta } from '../meta';

/** example de config dans le jsons server :
 * { "user":"GGO",
 * "profile":"*",
 * "location":"*",
 * "company":"*",
 * --> "filter":{"category":{"id":"A"},"administrativeLocation":{"id":"GYZMO01","wording":"GYZMO VALENCE"}}, <--
 * "config": .........
 */

export class SchedulerFilter extends LpMeta{

    public isLoaded: Boolean;
    public administrativeLocation: LpLocation;
    public actualLocation: LpLocation;
    public registration: LpRegistration;
    public category: FtMeta;
    public brand: FtMeta;
    public type: FtMeta;
    public univers: FtMeta;
    public family: FtMeta;
    public model: LpModel;
    public owner: LpThirdParty;
    public company: LpCompany;
    public isActive: Boolean;
    public startDate: String;
    public endDate: String;

    constructor() {
        super();
        this.isLoaded = false;
        this.actualLocation = new LpLocation();
        this.administrativeLocation = new LpLocation();
        this.brand = new FtMeta();
        this.registration = new LpRegistration();
        this.category = new FtMeta();
        this.univers = new FtMeta();
        this.company = new LpCompany();
        this.family = new FtMeta();
        this.model = new LpModel();
        this.owner = new LpThirdParty();
        this.type = new FtMeta();
        this.startDate = '';
        this.endDate = '';
    }
}
