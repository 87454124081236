import { LpMeta } from './meta';

export class LpIHubCheckPlusSettings extends LpMeta {
    public agencyId: string;
    public checklist: LpIHubCheckPlusChecklist;
    public faces: LpIHubCheckPlusFaces;
    public generalPictures: LpIHubCheckPlusGeneralPictures[];
    public home: LpIHubCheckPlusHome;
    public movement: LpIHubCheckPlusMovement;
    public search: LpIHubCheckPlusSearch;
    public summary: LpIHubCheckPlusSummary;
}

export class Metadata {
    public visibility: boolean;
    public mandatory: boolean;
    public readOnly: boolean;
    public defaultValue: string;
    public maxValuePerDay: number;
    public reasons: Reasons[];
}

export class Reasons {
    public id: number;
    public labels: Labels[];
}

export class Labels {
    public fr: string;
    public en: string;
}

export class LpIHubCheckPlusChecklist {
    public generalComment: Metadata;
    public pictureTakingEnabled: boolean;
}

export class LpIHubCheckPlusGeneralPictures {
    public id: boolean;
    public wording: boolean;
    public order: boolean;
}

export class LpIHubCheckPlusFaces {
    public damagesVisibility: boolean;
    public zoningVisibility: boolean;
}

export class LpIHubCheckPlusSearch {
    public searchByBarCodeEnabled: boolean;
    public searchByLicensePlateEnabled: boolean;
}

export class LpIHubCheckPlusHome {
    public cardContent: string;
    public generateDepartureEnabled: boolean;
    public generateIntermediateEnabled: boolean;
    public generateReturnEnabled: boolean;
    public infiniteViewEnabled: boolean;
}

export class LpIHubCheckPlusMovement {
    public equipmentInfoVisibility: boolean;
    public customerInfoVisibility: boolean;
    public driverInfoVisibility: boolean;
    public canDeletionInspection: boolean;
    public licensePicture: Metadata;
    public idPicture: Metadata;
    public counterPicture: Metadata;
    public startDate: Metadata;
    public returnDate: Metadata;
    public counter1: Metadata;
    public counter2: Metadata;
    public gauge1: Metadata;
    public gauge2: Metadata;
}

export class LpIHubCheckPlusSummary {
    public contractInfoVisibility: boolean;
    public equipmentVisibility: boolean;
    public agentName: Metadata;
    public customerName: Metadata;
    public termsAndConditions: Metadata;
    public driverLicenseValidity: Metadata;
    public sendInspectionByMail: Metadata;
    public signatureDenial: Metadata;
}



