<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.preference' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">
  <div class="row m-0">
    <div class="card-box col-md-6 col-lg-9" #col1>
      <form #form="ngForm" ngNativeValidate *ngIf="dataPreference">
        <div class="container-fluid">
          <lp-ui-accordion class="row" [menu]=true>
            <lp-ui-accordion-group heading="preferences.general" [open]=true key="general" >
              <div *ngIf="!showSavePanel">
                <div class="row">
                  <div class="form-group col-xl-5">
                    <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
                      text="preferences.linesPerPage"></lp-label-field>
                  <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" [ignoreChange]="true"
                      name="linesPerPage" outputType="string" [(ngModel)]="dataPreference.linesPerPage" [values]="availablesLinesPerPage" required>
                    </lp-dropdown-field>
                  </div>
                  <div class="form-group col-xl-3">
                    <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
                      text="preferences.changeService"></lp-label-field>
                    <lp-boolean-switch-box [ignoreChange]="true" name="changeService" id="changeService" switchName="changeService"
                      [(ngModel)]="dataPreference.changeService"></lp-boolean-switch-box>
                  </div>
                  <div class="form-group col-xl-2">
                    <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
                      text="preferences.debugg"></lp-label-field>
                  <lp-boolean-switch-box [ignoreChange]="true" name="debugg" id="debugg" switchName="debugg"
                      [(ngModel)]="dataPreference.debug"></lp-boolean-switch-box>
                  </div>
                  <div class="form-group col-xl-2">
                    <div class="row p-0">
                      <div class="col-xl-6">
                        <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
                          text="preferences.isLogActive"></lp-label-field>
                        <lp-boolean-switch-box [ignoreChange]="true" name="log" id="log" switchName="log"
                          [(ngModel)]="dataPreference.isLogActive"></lp-boolean-switch-box>
                      </div>
                      <div class="col-xl-6" *ngIf="dataPreference.isLogActive">
                        <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
                          text="preferences.verbose"></lp-label-field>
                        <lp-boolean-switch-box [ignoreChange]="true" name="verbose" id="verbose" switchName="verbose"
                          [(ngModel)]="dataPreference.isLogVerbose"></lp-boolean-switch-box>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button class="transparent-btn pull-right mb-4" (click)="toggleSavePanel()">
                      <i class="vega-diskette"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row justify-content-md-center m-0" *ngIf="showSavePanel">
                <lp-save-preferences class="col-4" (save)="saveConfig($event)"></lp-save-preferences>
              </div>
            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="Outbar" [open]=true >
              <div class="row">
                <div class="form-group col-xl-12">
                  <lp-outbar-preferences></lp-outbar-preferences>
                </div>
              </div>
            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="preferences.links" [open]=true >
              <div class="row">
                <div class="form-group col-xl-12">
                <lp-links></lp-links>
                </div>
              </div>
            </lp-ui-accordion-group>
          </lp-ui-accordion>

        </div>
      </form>
    </div>
    <div class="col-md-6 col-lg-3 pt-2 col-right" #col2>
      <p class="mt-1 mb-4">
        <span class="btn btn-outline-primary btn-i rounded-circle pull-right" (click)="openInfoModal('preferences.clearCache.info.l1', 'preferences.clearCache.info.l2')">
          <span>i</span>
        </span>
        <span class="btn btn-primary rounded-pill d-block me-5" (click)="clearCache()">
          <span>{{'preferences.clearCache' | translate}}</span>
        </span>
      </p>
      <p class="mt-1 mb-4">
        <span class="btn btn-outline-primary btn-i rounded-circle pull-right" (click)="openInfoModal('preferences.backEndClearCache.info.l1', 'preferences.backEndClearCache.info.l2')">
          <span>i</span>
        </span>
        <span class="btn btn-primary rounded-pill d-block me-5" (click)="clearBackEndCache()">
          <span>{{'preferences.backEndClearCache' | translate}}</span>
        </span>
      </p>
    </div>
  </div>
</div>
