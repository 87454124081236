
<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="paymentMethods.paymentMethods"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
  <div class="container p-0">
    <div class="container container-2-cols left" #containerCols>
      <div class="card-box col-md-6 label-2-colonnes" #col1>
        <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
          <!--  composant pour afficher lien flottant à droite du formulaire -->
          <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
            (showPrintOutput)="changeShowZoomPrint($event)"
            [showPrintInput]="showZoomPrint"></lp-common-tools>
            <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
          <lp-ui-accordion class="row" [menu]=true>

            <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">

              <div class="row">
                <!--Field id third-parties-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="id" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="paymentMethods.id"
                    cssClass="form-control" name="id" [(ngModel)]="data.id" [disabled]="true"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="wording" [(ngModel)]="data.wording" [required]=true label="paymentMethods.wording" type="text"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                    <lp-boolean-switch-box name="discount" switchName="discount" label="paymentMethods.discount" [(ngModel)]="data.discount"></lp-boolean-switch-box>
                </div>
                <div class="form-group col-xl-6">
                    <lp-boolean-switch-box name="accounting" switchName="accounting" [(ngModel)]="data.accounting" label="paymentMethods.accounting"></lp-boolean-switch-box>
                </div>
              </div>

              <div class="row">
                <!--Field location-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="location" class="{{arrayClassesElements.get('defaultInput')}}"
                    zoomId="location" className="zoomField" cssClass="form-control" label="paymentMethods.location"
                    [(ngModel)]="data.location" name="location">
                  </lp-zoom-field>
                </div>
                <!--Field company Fine-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="company" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='company' label="paymentMethods.company"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.company" name="company">
                  </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--Field relatedPaymentMethod-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="relatedPaymentMethod" class="{{arrayClassesElements.get('defaultInput')}}" label="paymentMethods.relatedPaymentMethod"                          
                    className="zoomField" cssClass="form-control" zoomId="payment-methods" name="relatedPaymentMethod"
                    [(ngModel)]="data.relatedPaymentMethod">
                  </lp-zoom-field>
                </div>
              </div>
            </lp-ui-accordion-group>
            <!--Accordion Debugger-->
            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>
          </lp-ui-accordion>
        </div>
          <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
        </form>
      </div>
      <div class="col-md-6 card-box" #col2>
        <lp-right-col></lp-right-col>
      </div>
    </div>

  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>
