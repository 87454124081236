import { Component, forwardRef, Input, OnInit, } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LpMeta } from 'app/meta/meta';
import { InputFieldComponent } from '../input-field.component';

@Component({
  selector: 'lp-hidden-field',
  templateUrl: './hidden-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HiddenFieldComponent),
      multi: true
    }
  ]
})
export class HiddenFieldComponent extends InputFieldComponent implements OnInit {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;
  @Input() public idInput: string = Math.random().toString();
  @Input() public pattern: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre Type permet de binder un objet (object), n string (default).
   *
   *
   */
  @Input() public type: string;

  /** La fonction inputTextFormatter(), formatte l'affichage du champs texte du zoom field
   * et affiche l'objet sélectionné selon les infos saisies dans le paramètre tableShowField.
   */

  protected async transform(value: LpMeta): Promise<void> {
    switch (this.type) {
      case 'object':
        if (value instanceof LpMeta) {
          this.displayValue = this.metaFactoryService.getRessourceFromObject(value).assign(value);
        } else {
          this.displayValue = new LpMeta();
          this.displayValue.id = value;
        }
        break;
      default:
        this.displayValue = value;
    }
  }
}
