<lp-button-cancel *ngIf="!formStackService.currentApplicationItemDetail.editFormActive" (action)="rightColService.hideSubApi()"></lp-button-cancel>
  <div *ngIf="!formStackService.currentApplicationItemDetail.useJsonEditForm">
    <lp-accounting-events *ngIf="formStackService.currentApplicationItemDetail?.key === ACCOUNTING_EVENTS ||
                                formStackService.currentApplicationItemDetail?.key === DRIVER_ACCOUNTING_EVENT ||
                                formStackService.currentApplicationItemDetail?.key === CUSTOMER_ACCOUNTING_EVENTS"></lp-accounting-events>
    <lp-accountings *ngIf="formStackService.currentApplicationItemDetail?.key === ACCOUNTING || 
                            formStackService.currentApplicationItemDetail?.key === CUSTOMER_ACCOUNTING || 
                            formStackService.currentApplicationItemDetail?.key === DRIVER_ACCOUNTING"></lp-accountings>
    <lp-additional-drivers *ngIf="formStackService.currentApplicationItemDetail?.key === ADDITIONAL_DRIVERS"></lp-additional-drivers>
    <lp-addresses-detail-form *ngIf="formStackService.currentApplicationItemDetail?.key === ADDRESSES ||
                                    formStackService.currentApplicationItemDetail?.key === CUSTOMER_ADDRESSES ||
                                    formStackService.currentApplicationItemDetail?.key === DRIVER_ADDRESSES"></lp-addresses-detail-form>
    <lp-circumstance-links *ngIf="formStackService.currentApplicationItemDetail?.key === CIRCUMSTANCE_LINKS"></lp-circumstance-links>
    <lp-contacts *ngIf="formStackService.currentApplicationItemDetail?.key === CONTACTS ||
                        formStackService.currentApplicationItemDetail?.key === CUSTOMER_CONTACTS ||
                        formStackService.currentApplicationItemDetail?.key === DRIVER_CONTACTS"></lp-contacts>
    <lp-crash-links *ngIf="formStackService.currentApplicationItemDetail?.key === CRASH_LINKS"></lp-crash-links>
    <lp-deposits *ngIf="formStackService.currentApplicationItemDetail?.key === DEPOSITS ||
                        formStackService.currentApplicationItemDetail?.key === CUSTOMER_DEPOSITS || 
                        formStackService.currentApplicationItemDetail?.key === DRIVER_DEPOSITS ||
                        formStackService.currentApplicationItemDetail?.key === CUSTOMER_DEPOSIT || 
                        formStackService.currentApplicationItemDetail?.key === DRIVER_DEPOSIT ||
                        formStackService.currentApplicationItemDetail?.key === THIRD_PARTY_DEPOSIT"></lp-deposits>
    <lp-equipments-relations *ngIf="formStackService.currentApplicationItemDetail?.key === EQUIPMENT_RELATIONS"></lp-equipments-relations>
    <lp-facturation-links *ngIf="formStackService.currentApplicationItemDetail?.key === FACTURATION_LINKS"></lp-facturation-links>
    <lp-financial-elements-links *ngIf="formStackService.currentApplicationItemDetail?.key === FINANCIAL_ELEMENTS_LINKS"></lp-financial-elements-links>
    <lp-historical-mileages *ngIf="formStackService.currentApplicationItemDetail?.key === HISTORICAL_MILEAGE"></lp-historical-mileages>
    <lp-invoice-lines *ngIf="formStackService.currentApplicationItemDetail?.key === INVOICE_LINES ||
                            formStackService.currentApplicationItemDetail?.key === CUSTOMER_INVOICE ||
                            formStackService.currentApplicationItemDetail?.key === SHORT_TERM_CONTRACTS_DRIVER_INVOICE_LINES ||
                            formStackService.currentApplicationItemDetail?.key === DRIVER_INVOICES"></lp-invoice-lines>
    <lp-kinds-links *ngIf="formStackService.currentApplicationItemDetail?.key === KINDS_LINKS"></lp-kinds-links>
    <lp-link-damage-historical-operations *ngIf="formStackService.currentApplicationItemDetail?.key === LINK_DAMAGE_HISTORICAL_OPERATIONS"></lp-link-damage-historical-operations>
    <lp-link-damage-value *ngIf="formStackService.currentApplicationItemDetail?.key === LINK_DAMAGE_VALUE"></lp-link-damage-value>
    <lp-location-contacts *ngIf="formStackService.currentApplicationItemDetail?.key === LOCATION_CONTACTS"></lp-location-contacts>
    <lp-opening-hours *ngIf="formStackService.currentApplicationItemDetail?.key === OPENNING_HOURS"></lp-opening-hours>
    <lp-registrations *ngIf="formStackService.currentApplicationItemDetail?.key === REGISTRATIONS"></lp-registrations>
    <lp-third-parties-relations *ngIf="formStackService.currentApplicationItemDetail?.key === RELATIONS ||
                            formStackService.currentApplicationItemDetail?.key === THIRD_PARTIES_RELATIONS ||
                            formStackService.currentApplicationItemDetail?.key === CUSTOMER_RELATIONS ||
                            formStackService.currentApplicationItemDetail?.key === DRIVER_RELATIONS"></lp-third-parties-relations>
    <lp-ribs *ngIf="formStackService.currentApplicationItemDetail?.key === RIBS ||
                    formStackService.currentApplicationItemDetail?.key === CUSTOMER_RIBS ||
                    formStackService.currentApplicationItemDetail?.key === DRIVER_RIBS"></lp-ribs>
    <lp-short-term-contracts-medias *ngIf="formStackService.currentApplicationItemDetail?.key === SHORT_TERM_CONTRACTS_MEDIAS"></lp-short-term-contracts-medias>
    <lp-technical-specifications *ngIf="formStackService.currentApplicationItemDetail?.key === TECHNICAL_SPECIFICATION"></lp-technical-specifications>
    <lp-third-party-links *ngIf="formStackService.currentApplicationItemDetail?.key === THIRD_PARTY_LINKS"></lp-third-party-links>
    <lp-third-party-type *ngIf="formStackService.currentApplicationItemDetail?.key === THIRD_PARTY_TYPE ||
                                formStackService.currentApplicationItemDetail?.key === CUSTOMER_TYPE ||
                                formStackService.currentApplicationItemDetail?.key === DRIVER_TYPE"></lp-third-party-type>
    <lp-mandates *ngIf="formStackService.currentApplicationItemDetail?.key === THIRD_PARTY_MANDATES || 
                        formStackService.currentApplicationItemDetail?.key === CUSTOMER_MANDATES ||
                        formStackService.currentApplicationItemDetail?.key === DRIVER_MANDATES"></lp-mandates>
    <lp-user-groups *ngIf="formStackService.currentApplicationItemDetail?.key === USER_GROUPS"></lp-user-groups>
    <lp-locations-type *ngIf="formStackService.currentApplicationItemDetail?.key === LOCATION_TYPE"></lp-locations-type>
    <lp-locations-situation *ngIf="formStackService.currentApplicationItemDetail?.key === LOCATION_SITUATION"></lp-locations-situation>
    <lp-locations-sector *ngIf="formStackService.currentApplicationItemDetail?.key === LOCATION_SECTOR"></lp-locations-sector>
    <lp-ihubcheckplus-settings *ngIf="formStackService.currentApplicationItemDetail?.key === COMPANIES_IHUBCHECKPLUS"></lp-ihubcheckplus-settings>
    <lp-lines *ngIf="formStackService.currentApplicationItemDetail?.key === SHORT_TERM_CONTRACTS_INVOICE_LINES"></lp-lines>
</div>
<div *ngIf="formStackService.currentApplicationItemDetail.useJsonEditForm">
    <lp-dynamic-detail-form></lp-dynamic-detail-form>
</div>