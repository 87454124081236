



<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm"
  titleTranslationKey="customers.customers"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
  <div class="container p-0">
    <div class="container container-2-cols left" #containerCols>
      <div class="card-box col-md-6 label-2-colonnes" #col1>
        <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
          <!--  composant pour afficher lien flottant à droite du formulaire -->
          <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
          (showPrintOutput)="changeShowZoomPrint($event)"
          [showPrintInput]="showZoomPrint" ></lp-common-tools>
          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
          <lp-ui-accordion class="row" [menu]=true>
            <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
              <div class="row">
                <!--Field id customers-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="id" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.id"
                    cssClass="form-control" name="id" [(ngModel)]="data.id" [disabled]="true"></lp-text-field>
                </div>
                <!--Field civility customers-->
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                    id="civility" cssClass="form-control" name="civility" [(ngModel)]="data.civility" label="customers.civility"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'civility'}]}"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="civility" id="civility" name="civility" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.civility" label="third-parties.civility"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--Field lastName customers-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="lastName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.lastName"
                    cssClass="form-control" name="lastName"  [(ngModel)]="data.lastName"></lp-text-field>
                </div>
                  <!--Field firstName customers-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="firstName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.firstName"
                    cssClass="form-control" name="firstName" [(ngModel)]="data.firstName"></lp-text-field>
                </div>
              </div>

              <!--<lp-subgroup [bordertop]="false">-->
                <div class="row">
                  <div class="form-group col-xl-6" *ngIf="data.mainAddress">
                    <lp-text-field id="mainAddress.address1" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="mainAddress.address1" label="customers.addresse"
                      [(ngModel)]="data.mainAddress.address1"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6" *ngIf="data.mainAddress">
                    <lp-text-field id="mainAddress.address2" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="mainAddress.address2" label="&nbsp;"
                      [(ngModel)]="data.mainAddress.address2"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6" *ngIf="data.mainAddress">
                    <lp-text-field id=".mainAddress.address3" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name=".mainAddress.address3"
                      [(ngModel)]="data.mainAddress.address3"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6" *ngIf="data.mainAddress">
                    <lp-text-field id="mainAddress.address4" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="mainAddress.address4"
                      [(ngModel)]="data.mainAddress.address4"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6" *ngIf="data.mainAddress">
                    <lp-zoom-field id="mainAddress.zipCode" class="{{arrayClassesElements.get('defaultInput')}}" name="mainAddress.zipCode" label="customers.mainAddress.zipCode"
                    className="zipCode" cssClass="form-control" [(ngModel)]="data.mainAddress.zipCode" zoomId="zipCode">
                  </lp-zoom-field>
                  </div>
                  <div class="form-group col-6" *ngIf="data.mainAddress">
                    <lp-text-field id="mainAddress.city" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.mainAddress.city"
                      cssClass="form-control" name="mainAddress.city" [(ngModel)]="data.mainAddress.city"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6" >
                    <lp-text-field id="mail" class="{{arrayClassesElements.get('defaultInput')}}" label="third-parties.mail"
                      cssClass="form-control" name="mail" [(ngModel)]="data.mail.data"></lp-text-field>
                  </div>
                  <div class="form-group col-6" >
                    
                    <lp-text-field id="phone" class="{{arrayClassesElements.get('defaultInput')}}" label="third-parties.phone"
                      cssClass="form-control" name="phone" [(ngModel)]="data.phone.data"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field id="mainAddress.country" class="{{arrayClassesElements.get('defaultInput')}}" className="textField" label="customers.mainAddress.country"
                      cssClass="form-control" name="mainAddress.country" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'country'}]}"
                      [(ngModel)]="data.mainAddress.country"></lp-dropdown-field>-->
                      <lp-zoom-field zoomId="country" id="country" name="country" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.mainAddress.country" label="customers.mainAddress.country"> </lp-zoom-field>
                  </div>
                  <!--Field sirenNumber customers-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="sirenNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" 
                    cssClass="form-control" name="sirenNumber" label="customers.sirenNumber"
                    [(ngModel)]="data.sirenNumber"></lp-text-field>
                </div>
                </div>

                <div class="row">
                  <!--Field APE customers-->
                  <div class="form-group col-xl-6">
                    <lp-text-field id="APE" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"  label="customers.APE"
                      cssClass="form-control" name="APE" [ngModel]="toLowerCaseAPE(data.APE)" (ngModelChange)="data.APE=$event"></lp-text-field>
                  </div>
                  <!--Field intraCommunityVat customers-->
                  <div class="form-group col-xl-6">
                      <!--<lp-dropdown-field  id="vatDomain" class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                      cssClass="form-control" name="vatDomain" label="customers.vatDomain"
                      [(ngModel)]="data.vatDomain" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'vatDomain'}]}"></lp-dropdown-field>-->
                      <lp-zoom-field zoomId="vatDomain" id="vatDomain" name="vatDomain" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.vatDomain" label="customers.vatDomain"> </lp-zoom-field>
                  </div>
                </div>
              <!--</lp-subgroup>-->

            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="customers.card" [open]=false key="card">

              <div class="row">
                <!--Field cardNumber customers-->
                <div class="form-group col-xl-6">
                    <lp-text-field id="cardNumber" className="textField" class="col-md-10 col-lg-8 col-xl-6" 
                      cssClass="form-control" name="cardNumber"  pattern="[0-9]+" label="customers.cardNumber"
                      [(ngModel)]="data.cardNumber" minlength="16" maxlength="16"></lp-text-field>
                    <div class="col-md-10 col-lg-8 col-xl-6">
                      <div class="col-1 sep-input pe-0 ps-0">/</div>
                      <lp-number id="cardCrypto" className="textField" class="col-11 pe-0" minlength="3" maxlength="3" 
                        cssClass="form-control" name="cardCrypto" step="1" [(ngModel)]="data.cardCrypto"></lp-number>
                    </div>
                  </div>
                <div class="form-group col-xl-6">
                  <!--Field cardExpirationDate customers-->
                      <lp-date-month [(ngModel)]="data.cardExpirationDate" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.cardExpirationDate" 
                    id="cardExpirationDate" cssClass="form-control" name="cardExpirationDate"></lp-date-month>
                  </div>
              </div>
            </lp-ui-accordion-group>

            <!-- TODO : Mettre les id, wording et details dans les ngModel des tarifs quand disponibles-->
            <lp-ui-accordion-group heading="customers.tariffs.title" [open]=false key="tariff">

              <div class="row">
                <!--Field idTariff -->
                <div class="form-group col-xl-6">
                    <lp-zoom-field id="tariff" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="customers.tariff"
                    name="tariff" cssClass="form-control" [(ngModel)]="data.tariff" zoomId="pricing">
                  </lp-zoom-field>
                </div>
                <!--Field discountRate customers-->
                <div class="form-group col-xl-6">
                  <lp-number id="discountRate" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.discountRate"
                  minlength="3" maxlength="3" cssClass="form-control" name="discountRate" step="1"
                  [(ngModel)]="data.discountRate" iconClass="fa fa-percent"></lp-number>
                </div>
              </div>

              <div class="row">
                <!--Field commissionRate1 customers-->
                <div class="form-group col-xl-6">
                  <lp-number id="commissionRate1" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    minlength="3" maxlength="3" cssClass="form-control" name="commissionRate1" step="1" label="customers.commissionRate1"
                    [(ngModel)]="data.commissionRate1" iconClass="fa fa-percent"></lp-number>
                </div>
                <!--Field commissionRate2 customers-->
                <div class="form-group col-xl-6">
                  <lp-number id="commissionRate2" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.commissionRate2"
                    minlength="3" maxlength="3" cssClass="form-control" name="commissionRate2" step="1"
                    [(ngModel)]="data.commissionRate2" iconClass="fa fa-percent"></lp-number>
                </div>
              </div>

              <div class="row">
                <!--Field cardLimit customers-->
                <div class="form-group col-xl-6">
                    <lp-number id="cardNumberLimit" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.cardNumberLimit"
                      name="cardNumberLimit" [(ngModel)]="data.cardNumberLimit"
                      minlength="3"></lp-number>
                  </div>
              </div>

            </lp-ui-accordion-group>

            <!--A garder-->
            <!--<lp-ui-accordion-group heading="customers.pro" [open]=false key="pro">
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="directorWord" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.directorWord"
                    cssClass="form-control" name="directorWord" [(ngModel)]="data.directorWord"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="sirenNumbercustomer" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="sirenNumbercustomer" label="customers.sirenNumber"
                    [(ngModel)]="data.sirenNumber"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="APE" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.APE"
                    cssClass="form-control" name="APE" [ngModel]="toLowerCaseAPE(data.APE)" (ngModelChange)="data.APE=$event"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                    <lp-dropdown-field  id="intraCommunityVat" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" label="customers.intraCommunityVat" name="intraCommunityVat"
                    [(ngModel)]="data.vatDomain" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'vatDomain'}]}"></lp-dropdown-field>
                </div>
              </div>

            </lp-ui-accordion-group>-->

            <!-- Accordion Driver Informations-->
            <lp-ui-accordion-group heading="customers.driverInformation" [open]=false key="driverInformation">

              <div class="row">
                <!--Field BirthDate-->
                <div class="form-group col-xl-6">
                  <lp-date [(ngModel)]="data.driverInformation.birthDate" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.driverInfo.birthDate"
                    id="driverInformation.birthDate" cssClass="form-control" name="driverInformation.birthDate"></lp-date>
                </div>
                <!-- Field BirthPlace-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="driverInformation.birthPlace" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" 
                    cssClass="form-control" name="driverInformation.birthPlace" label="customers.driverInfo.birthPlace"
                    [(ngModel)]="data.driverInformation.birthPlace"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <!-- Field birthNationality-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="driverInformation.birthNationality" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="driverInformation.birthNationality" label="customers.driverInfo.birthNationality"
                    [(ngModel)]="data.driverInformation.birthNationality"></lp-text-field>
                </div>
              </div>

              <lp-subgroup [bordertop]="true" label="customers.driverInfo.licenceseTitle">

                <div class="row">
                  <!--Field driverLicenseIssuingDate-->
                  <div class="form-group col-xl-6">
                    <lp-date [(ngModel)]="data.driverInformation.driverLicenseIssuingDate"
                    class="{{arrayClassesElements.get('defaultInput')}}" label="customers.driverInfo.driverLicenseIssuingDate"
                      id="driverInformation.driverLicenseIssuingDate" cssClass="form-control" name="driverInformation.driverLicenseIssuingDate"></lp-date>
                  </div>
                  <!-- Field driverLicenseIssuingAuthority-->
                  <div class="form-group col-xl-6">
                    <lp-text-field id="driverInformation.driverLicenseIssuingAuthority" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="driverInformation.driverLicenseIssuingAuthority"  label="customers.driverInfo.driverLicenseIssuingAuthority"
                      [(ngModel)]="data.driverInformation.driverLicenseIssuingAuthority"></lp-text-field>
                  </div>
                </div>
                
                <div class="row">
                    <!-- Field driverLicenseIssuingPlace-->
                    <div class="form-group col-xl-6">
                      <lp-text-field id="driverInformation.driverLicenseIssuingPlace" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                        cssClass="form-control" name="driverInformation.driverLicenseIssuingPlace" label="customers.driverInfo.driverLicenseIssuingPlace"
                        [(ngModel)]="data.driverInformation.driverLicenseIssuingPlace"></lp-text-field>
                    </div>
                  </div>

                <div class="row">
                  <!--Field driverLicenseExpirationDate-->
                  <div class="form-group col-xl-6">
                    <lp-date [(ngModel)]="data.driverInformation.driverLicenseExpirationDate" class="{{arrayClassesElements.get('defaultInput')}}"
                      id="driverInfo.driverLicenseExpirationDate" cssClass="form-control" name="driverInfo.driverLicenseExpirationDate" label="customers.driverInfo.driverLicenseExpirationDate"
                      ></lp-date>
                  </div>
                </div>

                <div class="row">
                  <!-- Field driverLicenseNumber-->
                  <div class="form-group col-xl-6">
                    <lp-text-field id="driverInformation.driverLicenseNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="driverInformation.driverLicenseNumber" label="customers.driverInfo.driverLicenseNumber"
                      [(ngModel)]="data.driverInformation.driverLicenseNumber"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <!-- Field driverLicenseType-->
                  <div class="form-group col-xl-6">
                    <lp-text-field id="driverInformation.driverLicenseType" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="driverInformation.driverLicenseType" label="customers.driverInfo.driverLicenseType"
                      [(ngModel)]="data.driverInformation.driverLicenseType"></lp-text-field>
                  </div>
                </div>
                </lp-subgroup>



                <lp-subgroup [bordertop]="true" label="customers.driverInfo.passportTitle">
                  <div class="row">
                    <!-- Field passportNumber-->
                    <div class="form-group col-xl-6">
                      <lp-text-field id="driverInformation.passportNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                        cssClass="form-control" name="driverInformation.passportNumber" label="customers.driverInfo.passportNumber"
                        [(ngModel)]="data.driverInformation.passportNumber"></lp-text-field>
                    </div>
                    <!--Field passportIssuingDate-->
                    <div class="form-group col-xl-6">
                      <lp-date [(ngModel)]="data.driverInformation.passportIssuingDate" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.driverInfo.passportIssuingDate"
                        id="driverInformation.passportIssuingDate" cssClass="form-control" name="driverInformation.passportIssuingDate"></lp-date>
                    </div>
                  </div>

                  <div class="row">
                    <!-- Field passportIssuingAuthority-->
                    <div class="form-group col-xl-6">
                      <lp-text-field id="driverInformation.passportIssuingAuthority" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                        cssClass="form-control" name="driverInformation.passportIssuingAuthority" label="customers.driverInfo.passportIssuingAuthority"
                        [(ngModel)]="data.driverInformation.passportIssuingAuthority"></lp-text-field>
                    </div>
                    <!-- Field passportIssuingPlace-->
                    <div class="form-group col-xl-6">
                      <lp-text-field id="driverInformation.passportIssuingPlace" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                        cssClass="form-control" name="driverInformation.passportIssuingPlace" label="customers.driverInfo.passportIssuingPlace"
                        [(ngModel)]="data.driverInformation.passportIssuingPlace"></lp-text-field>
                    </div>
                  </div>

                  <div class="row">
                    <!--Field passportExpirationDate-->
                    <div class="form-group col-xl-6">
                      <lp-date [(ngModel)]="data.driverInformation.passportExpirationDate" class="{{arrayClassesElements.get('defaultInput')}}"
                        id="driverInformation.passportExpirationDate" cssClass="form-control" name="driverInformation.passportExpirationDate" label="customers.driverInfo.passportExpirationDate"
                        ></lp-date>
                    </div>
                  </div>
                </lp-subgroup>

            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="customers.divers.title" [open]=false key="divers">

              <div class="row">
                <!--Field socialSecurityNumber customers-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="socialSecurityNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="socialSecurityNumber" label="customers.socialSecurityNumber"
                    [(ngModel)]="data.socialSecurityNumber"></lp-text-field>
                </div>
                <!--Field company customers-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="company" class="{{arrayClassesElements.get('defaultInput')}}" 
                    name="company" className="zoomField" cssClass="form-control" label="customers.company"
                    [(ngModel)]="data.company" zoomId="company" 
                    kySearch="id"
                    addModifyVerb="companies"
                    urlNew="companies"
                    bindingProp="company"
                    historyKey="H_3nr88r"
                    modal=false
                    isScheduler=false
                    textSearch="id"
                    isClosable=true
                    showLpFieldDetails=true
                    zoomId="company"
                    isLocProZoom=true
                    locProZoomObject="Customer"
                    locProZoomAttribut="company"
                    libSearch="wording"
                    inputSearch="data.company?.id"
                    searchTextField="id"
                    showAddButton=false
                    showModifyButton=false
                    tableBindValues="['id','corporateName','details']"
                    tableShowField= "['corporateName']">
                  </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--Field location customers-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="location" class="{{arrayClassesElements.get('defaultInput')}}" name="location" label="customers.location"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.location" zoomId="startLocation">
                  </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="commercial" class="{{arrayClassesElements.get('defaultInput')}}" name="commercial" label="customers.commercial"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.commercial" zoomId="commercial"> 
                  </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--Field currency customers-->
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" label="customers.currency"
                    id="currency" cssClass="form-control" name="currency" [(ngModel)]="data.currency" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'currency'}]}"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="currency" id="currency" name="currency" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.currency" label="customers.currency"> </lp-zoom-field>
                  </div>
                <!--Field language customers-->
                <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" label="customers.language"
                    id="language" cssClass="form-control" name="language" [(ngModel)]="data.language" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'language'}]}"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="language" id="language" name="language" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.language" label="customers.language"> </lp-zoom-field>
                  </div>
              </div>

              <div class="row d-none">
                <!--Field code customers-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="code" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="customers.code"
                    cssClass="form-control" name="code" [(ngModel)]="data.code"></lp-text-field>
                </div>
                <!--Field MediaCode customers-->
                <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field id="mediacode" class="{{arrayClassesElements.get('defaultInput')}}" className="textField" label="customers.mediacode.title"
                    cssClass="form-control" name="mediacode" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'mediaType'}, {'key':'filter', 'value': 'F450CD'}]}"
                    [(ngModel)]="data.mediacode"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="mediacode" id="mediacode" name="mediacode" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.mediacode" label="customers.mediacode"> </lp-zoom-field>
                </div>
              </div>

              <div class="row d-none">
                <div class="form-group col-xl-6">
                  <lp-text-field id="mainAddress.corporateName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="mainAddress.corporateName" label="customers.mainAddress.corporateName"
                    [(ngModel)]="data.mainAddress.corporateName"></lp-text-field>
                </div>
              </div>

            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>


          </lp-ui-accordion>
        </div>
          <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
        </form>
      </div>
      <div class="col-md-6 card-box" #col2>
        <lp-right-col></lp-right-col>
      </div>
    </div>

  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>
