import { LpMeta, propertyMeta } from "./meta";

export class LpZoom extends LpMeta {
    @propertyMeta('string')
    public displayFields: String;
    @propertyMeta('string')
    public displayFieldsHeaders: String;
    @propertyMeta('string')
    public displayFieldsSize: String;
    @propertyMeta('string')
    public filter: Boolean;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('boolean')
    public isCustom: String; 
    @propertyMeta('string')
    public request: String;
    @propertyMeta('string')
    public returnField: String;
}