
<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="short-term-contracts.shortTermContractsInvoiceLines.title" [open]=true>
    <form id="formAccountingEvents" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

      <div class="form-group col-xl-12">
        <lp-label-field for="service" class="{{arrayClassesElements.get('defaultLabel')}}" text="invoices.invoiceLinesForm.service"></lp-label-field>
        <lp-zoom-field id="service" class="{{arrayClassesElements.get('defaultInput')}}" name="lines.service" zoomId='lines.services' className="zoomField form-control" [(ngModel)]="data.service"></lp-zoom-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="quantity" class="{{arrayClassesElements.get('defaultLabel')}}" text="invoices.invoiceLinesForm.quantity"></lp-label-field>
        <lp-number id="quantity" [readonly]="!data.service.quantityCanBeUpdated" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="lines.quantity" [(ngModel)]="data.quantity"></lp-number>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="unitPrice" class="{{arrayClassesElements.get('defaultLabel')}}" text="invoices.invoiceLinesForm.unitPrice"></lp-label-field>
        <lp-number id="unitPrice" [readonly]="!data.service.amountCanBeUpdated" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="lines.unitPrice" [(ngModel)]="data.unitPrice"></lp-number>
      </div>

      <div class="text-end">
        <button  *ngIf="!formStackService.isDataReadOnly()"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>