import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {AlocproProvider} from '../providers/alocpro.provider';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ZoomService} from '../services/zoom.service';
import {PaginatedData} from '../models/paginated-data';
import {RepositoryProvider} from '../providers/repository.provider';
import {UiSyncService} from '../services/ui-sync.service';
import {TranslateService} from '@ngx-translate/core';
import {SchedulerData, SchedulerDate} from 'app/meta/scheduler/scheduler-data';
import {XAxis} from 'app/meta/scheduler/xAxis';
import {SchedulerEquipment} from 'app/meta/scheduler/scheduler-equipment';
import {SchedulerMovement} from 'app/meta/scheduler/scheduler-movements';
import {SchedulerUIService} from 'app/services/scheduler-ui.service';
import {DatetimeService} from 'app/services/datetime.service';
import {FtMeta} from 'app/meta/ft-meta';
import {LpLocation} from 'app/meta/location';
import {SchedulerFilter} from 'app/meta/scheduler/scheduler-filter';
import {ModalService} from 'app/services/modal.service';
import {ZoomProvider} from 'app/providers/zoom.provider';
import { debounceTime } from 'rxjs/operators';
import { Util } from 'app/statics/utils';

const DEBOUNCETIME: number = 1000;
const NB_DAYS_TO_DISPLAY: number = 31;

@Component({
  selector: 'lp-zoom-scheduler',
  templateUrl: './schedulerZoom.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SchedulerZoomComponent),
      multi: true
    }
  ]
})
export class SchedulerZoomComponent implements OnInit {

  @Input() public startDate: String;
  @Input() public endDate: String;
  @Input() public category: FtMeta;
  @Input() public isShown: boolean = true;
  @Input() public startLocation: LpLocation;
  @Output() public outputData: EventEmitter<FtMeta> = new EventEmitter();
  @Output() private modalClose: EventEmitter<void> = new EventEmitter();
  public x: Array<XAxis>;
  public d: Array<SchedulerData>
  public activeFilter: Array<Array<String>>;
  public filter: SchedulerFilter;
  public isLoaded: boolean;
  public refresh: boolean
  public titleMainZoom: String = 'zoom.schedulerZoomTitle';
  public schedulerFilter: SchedulerFilter;
  public showMovementPanel: boolean;
  public  showFilterPanel: boolean;
  constructor(protected schedulerUIService: SchedulerUIService, protected dateTimeService: DatetimeService,
    protected alocproProvider: AlocproProvider, protected repositoryProvider: RepositoryProvider, protected zoomService: ZoomService,
    protected zoomProvider: ZoomProvider, protected uiSyncService: UiSyncService, protected translate: TranslateService,
    protected changeDetectorRef: ChangeDetectorRef, protected modalService: ModalService, private datetimeService: DatetimeService) {
  }

  public ngOnInit(): void {

    this.schedulerFilter = new SchedulerFilter();
    this.uiSyncService.loader(true, false, this.translate.instant(this.translate.instant('loading.data')));
    this.init();
    this.uiSyncService.loader(false);
    this.schedulerUIService.date.pipe(debounceTime(DEBOUNCETIME)).subscribe((date: SchedulerDate) => {
      this.schedulerUIService.DateDisplayed.date = date;
      this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
      this.updateDataDateChanged(date);
    });
    this.schedulerUIService.refresh.subscribe((refresh: boolean) => {
      this.refresh = false;
    });
  }

  public async init(): Promise<void> {
    this.uiSyncService.loader(true, false, this.translate.instant(this.translate.instant('loading.data')));
    this.isLoaded = false;
    this.schedulerFilter.category = this.category;
    this.schedulerFilter.actualLocation = this.startLocation;
    this.filter = await this.schedulerUIService.getFilter();
    let dateReference: String = (isNullOrUndefined(this.startDate) || this.startDate === 'Invalid date' ?
    this.dateTimeService.subtract(new Date().toString(), 0, 'days') :
    this.dateTimeService.subtract(this.startDate.toString(), 0, 'days'));
    const obj: SchedulerDate = this.getDate(dateReference);
    this.startDate = obj.startDate.toString();
    this.schedulerUIService.filter = this.schedulerFilter;
    this.activeFilter = await this.schedulerUIService.getActiveFilter();
    this.getData(this.schedulerUIService.getFilterRequestString(obj)).then(d => {
      this.isLoaded = true;
      this.uiSyncService.loadObject();
      this.zoomService.loadingEquipmentZoom = false;
      this.schedulerUIService.isFilterLoaded = false
    });
  }

  public isFilterActive(): Boolean {
    return this.schedulerUIService.hasActiveFilter();
  }

  public resetFilter(filter?: String): void {
    this.schedulerUIService.loader = true;
    // this.schedulerUIService.Filter.resetFilter(filter);
    // this.schedulerFilter = this.schedulerUIService.Filter;
    this.filter.renew()
    this.filterPlanning(this.schedulerFilter);
  }

  public async filterPlanning(f: SchedulerFilter): Promise<void> {
    this.uiSyncService.loader(true, true, this.translate.instant(this.translate.instant('loading.data')));
    let params: Map<string, string> = new Map();
    Object.assign(this.schedulerFilter, f);
    params = this.schedulerUIService.getFilterRequestString(this.getDateScope(this.startDate.toString()));
    this.activeFilter = await this.schedulerUIService.getActiveFilter();
    this.getData(params).then(d => {
      this.uiSyncService.loadObject();
      this.uiSyncService.loader(false);
    });
  }

  // REVIEW factorisation
  public async updateDataDateChanged(d: SchedulerDate): Promise<void> {
    this.startDate = d.startDate;
    this.isLoaded = false;
    let params: Map<string, string> = new Map();
    params = this.schedulerUIService.getFilterRequestString(d);
    this.activeFilter = await this.schedulerUIService.getActiveFilter();
    this.getData(params).then(() => {
      this.uiSyncService.loadObject();
      this.isLoaded = true;
      this.refresh = true;
      this.uiSyncService.loader(false);
    });
  }

  public closeModal(): void {
    this.isLoaded = false;
    this.isShown = false;
    this.zoomService.closePlanning();
    this.modalClose.emit(); 
  }

  public isModal(): boolean {
    return true;
  }

  public send(data: SchedulerEquipment): void {
    this.zoomService.loadingEquipmentZoom = true;
    this.closeModal();
    this.zoomService.selectItemFromSchedulerZoomEvtEmitter.emit(data);    
  }

  public toggleFilterPlanning(): void {
    if (!isNullOrUndefined(event)) {
      event.preventDefault();
    }
    if (!this.schedulerUIService.getFilterPanelState()) {
      this.showFilterPanel = true;
    } else {
      this.showFilterPanel = false;
    }
    this.schedulerUIService.setFilterPanelState(!!this.showFilterPanel);
    if (this.schedulerUIService.getConfigPanelState()) {
      this.schedulerUIService.setConfigPanelState(false);
    }
    if (this.schedulerUIService.getMovementDetailPanelState()) {
      this.showMovementPanel = false;
      this.schedulerUIService.setMovementDetailPanelState(false);
    }
    this.uiSyncService.loadObject();
  }

  public toggleConfigPlanning(configStatePanel: boolean): void {
    event.preventDefault();
    this.showMovementPanel = configStatePanel;
    if (configStatePanel) {
      this.showFilterPanel = false;
      this.schedulerUIService.setFilterPanelState(!!this.showFilterPanel);
    }
    this.showFilterPanel = this.schedulerUIService.getFilterPanelState();
  }

  private getDateScope(startDate: string): SchedulerDate {
    return {
      startDate: startDate, endDate: this.dateTimeService.dateDBFormatComplete(
        this.dateTimeService.add(startDate, NB_DAYS_TO_DISPLAY, 'days'))
    }
  }

  private getData(params: Map<string, string>): Promise<void> {
    return new Promise<void>((resolve) => {
      // TODO change to paginated data
      this.alocproProvider.getPaginatedData('equipments/plannings', null, params).then((data: PaginatedData) => {
        let index: number = 1;
        let tempData: Array<SchedulerData> = [];
        let tempXaxis: Array<XAxis> = [];
        if (data.body) {
          for (let datum of data.body) {
            if (!isNullOrUndefined(datum.id)) {
              let schedulerEquipment: SchedulerEquipment = new SchedulerEquipment();
              Object.assign(schedulerEquipment, datum);
              tempXaxis.push(this.serializeEquipmentForScheduler(index, schedulerEquipment));
              for (let movement of schedulerEquipment.movements) {
                let schedulerMovement: SchedulerMovement = new SchedulerMovement();
                Object.assign(schedulerMovement, movement)
                if(Util.isNullOrUndefined(movement.returnDate) || (movement.returnDate === '')){
                    movement.returnDate = '2099-01-01T00:00:00.000Z';
                    movement.undefinedReturnDate = true;
                }
                Object.assign(schedulerMovement, movement)
                let objectTOPush: SchedulerData = this.serializeMovementForScheduler(index, schedulerEquipment, schedulerMovement);
                objectTOPush.color =
                    this.schedulerUIService.getHexaFromClassColor(this.schedulerUIService.getEventColor(objectTOPush.position));
                tempData.push(objectTOPush);
              }
              index += 1;
            }
          }
        }
        this.x = tempXaxis;
        this.d = tempData;
        resolve();
      });
    });
  }

  private getDate(startDate: String): SchedulerDate {
    let obj: SchedulerDate;
    obj = {
      startDate: this.dateTimeService.dateDBFormatComplete(this.dateTimeService.subtract(startDate.toString(), 0, 'days')),
      endDate: this.dateTimeService.dateDBFormatComplete(this.dateTimeService.add(startDate.toString(), NB_DAYS_TO_DISPLAY - 0, 'days'))
    }
    return obj
  }

  private serializeMovementForScheduler(sectionId: number, schedulerEquipment: SchedulerEquipment, schedulerMovement: SchedulerMovement): SchedulerData {
    if(schedulerMovement.startDate.lastIndexOf('Z') > -1){
        schedulerMovement.startDate = schedulerMovement.startDate.slice(0, schedulerMovement.startDate.length-1);
    }
    if(schedulerMovement.returnDate.lastIndexOf('Z') > -1){
        schedulerMovement.returnDate = schedulerMovement.returnDate.slice(0, schedulerMovement.returnDate.length-1);
    }
    if (schedulerEquipment.id) {
        return { start_date: this.datetimeService.newDate(schedulerMovement.startDate), end_date: this.datetimeService.newDate(schedulerMovement.returnDate),
            sectionId: sectionId, customer: (schedulerMovement.customer ? schedulerMovement.customer.lastName : 'NC'),
            documentNumber: schedulerMovement.documentNumber, position: (schedulerMovement.position ? schedulerMovement.position.id : ''), color: '', object: schedulerMovement };
    } else  {
        return { start_date: this.datetimeService.newDate(schedulerMovement.startDate), end_date: this.datetimeService.newDate(schedulerMovement.returnDate),
            sectionId: 1, customer: (schedulerMovement.customer ? schedulerMovement.customer.lastName : 'NC'),
            documentNumber: 'NC', position: (schedulerMovement.position ? schedulerMovement.position.id : ''), color: '', object: schedulerMovement };
    }
}

// TODO esssyaer de renvoyer ligne de tableau + class css pour taille (%) pour avoir qqch de tabulaire
private serializeEquipmentForScheduler(index: number, schedulerEquipment: SchedulerEquipment): XAxis {
    if (schedulerEquipment.id) {
        return {key: index, label: '', obj: schedulerEquipment};
    } else {
        return {key: 1, label: 'nc', obj: schedulerEquipment};
    }
}

}
