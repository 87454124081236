<div class="overlay" *ngIf="progressBarService.active">
  <div class="content p-3">
    <p *ngIf="progressBarService.title" class="label">{{progressBarService.title}}</p>
    <div>
      <mat-progress-bar
      class=""
      mode="determinate"
      [value]="progressBarService.value * 100 / progressBarService.max">
    </mat-progress-bar>  
  </div>
    
    <!--<p>
      {{progressBarService.value }} - 
      {{progressBarService.max }} - 
      {{progressBarService.value * 100 / progressBarService.max }}
    </p>-->
  </div>
  
</div>
