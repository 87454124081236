import { Type } from 'class-transformer';
import { FtMeta } from './ft-meta';
import { LpMeta, propertyMeta } from './meta';

export class LpZipCode extends LpMeta {
    @propertyMeta('string')
    public city: String;
    @Type(() => FtMeta)
    public country: FtMeta = new FtMeta();
    @propertyMeta('number')
    public insee: String;
    @Type(() => FtMeta)
    public province: FtMeta = new FtMeta();;
}
