<div class="modal-header">
  <h4 class="modal-title pull-left">{{'changeLocation.title' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (click)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body"
     *ngIf="location">
  <div class="form-group col-12">
    <lp-label-field for="location"
                    text="changeLocation.location"></lp-label-field>
    <lp-zoom-field id="location"
                   [ignoreChange]="true"
                   addModifyVerb="locations"
                   libSearch="wording"
                   zoomId='location'
                   className="zoomField"
                   cssClass="form-control"
                   [showModifyButton]="false"
                   [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="location"
                   [inputSearch]="location.id"
                   searchTextField="id"
                   [modal]="true"
                   [tableShowField]="['id','wording']"
                   name="location"
                   kySearch="id"
                   locProZoomObject="user"
                   locProZoomAttribut="changeLocation">
    </lp-zoom-field>
  </div>

  <div class="col-12 text-center mb-4">
    <button type="button"
            class="btn btn-primary"
            [disabled]="(!location.id || this.currentUser.location.id === location.id) ? true : false"
            (click)="validForm()"
            [mat-dialog-close]="true">{{'changeLocation.btn' | translate}}</button>
  </div>

</div>
