import { LpMeta, propertyMeta } from './meta';

export class LpCurrency extends LpMeta {
    @propertyMeta('string')
    public symbol: String;
    @propertyMeta('string')
    public details: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
}
