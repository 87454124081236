import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { GeneralParameters } from 'app/models/general-parameters';
import { ConfigService } from 'app/services/config.service';
import { Util } from 'app/statics/utils';

const POST_GENERAL_PARAMETERS: string = 'POST';
const PUT_GENERAL_PARAMETERS: string = 'PUT';
const GET_GENERAL_PARAMETERS: string = 'GET';
@Component({
  selector: 'lp-general-parameters',
  templateUrl: './general-parameters.component.html',
  styleUrls: ['./general-parameters.component.scss'],
})
export class GeneralParametersComponent {

  @Input() public datas : Array<GeneralParameters> = [];
  @Input() public action : string;
  @Output() public emitCurrentGeneralParameter = new EventEmitter<GeneralParameters>();
  public currentGeneralparameter: GeneralParameters = new GeneralParameters();
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public columns: Array<Object> = [
    {
      'prop': 'wording',
      'name': 'general_parameters.libelle',
    },
    {
      'prop': 'table',
      'name': 'general_parameters.table',
    },
    {
      'prop': 'code',
      'name': 'general_parameters.argument',
    },
    {
      'prop': 'action',
      'name': '',
    }
  ];

  constructor(
    private configService: ConfigService,
    ) {
      this.datas = [];
      this.setCssVariable();
   }

  public ngOnChanges(simpleChanges: SimpleChange): void {
    if (!Util.isNullOrUndefined(simpleChanges) && !Util.isNullOrUndefined(simpleChanges['action'])) {
      this.configureAction(simpleChanges['action']['currentValue']);
    } 
  }

  public selectItem(event: any): void {
    if (event && event.type && event.type === 'click' ) {
      this.currentGeneralparameter = event.row;
      this.emitCurrentGeneralParameter.emit(this.currentGeneralparameter);
    }
  }

  private setCssVariable(): void {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
  }

  private configureAction(action: string): void {
    try {
      if (!Util.isNullOrUndefined(action)) {
        switch(action) { 
          case POST_GENERAL_PARAMETERS: { 
            this.currentGeneralparameter = new GeneralParameters();
            break; 
          } 
          case PUT_GENERAL_PARAMETERS: { 

            break; 
          } 
          case GET_GENERAL_PARAMETERS: { 

            break; 
         } 
          default: { 

            break; 
          } 
      }
     } 
    } catch (error) {
      throw error;
    }
  }

}
