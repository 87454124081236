import { LpReportParameters } from 'app/meta/report-parameters';
import { Component, OnInit, Input } from '@angular/core';
import { ReportService } from 'app/services/report.service';
import { ModalService } from 'app/services/modal.service';
import {LpModalMailComponent} from '../../lp-modal/lp-modal-mail/lp-modal-mail.component'
import { TranslateService } from '@ngx-translate/core';
import { Util } from 'app/statics/utils';
import { FormStackService } from 'app/services/form-stack.service';

const MAIL_SENT: string = 'general.modalService.mailSent';
const MAIL_NOT_SENT: string = 'general.modalService.mailNotSent';
const OBJECT: string = 'object';
const KEY: string = 'key';
@Component({
    selector: 'lp-mail-report',
    templateUrl: './lp-mail-report.component.html',
    styleUrls: ['./lp-mail-report.component.scss']
  })
export class LpMailReportComponent implements OnInit {

    @Input() public isShortcut: boolean;
    @Input() public reportId: string;
    @Input() public arrayOfParameters: Array<LpReportParameters>;
    public idReport: string;
    public reportDataKY: string;
    public title: string = 'report.mailReportTitle';
    private objectParameters: LpReportParameters = new LpReportParameters(); 
    private keyParameters: LpReportParameters = new LpReportParameters(); 

    constructor(
        public reportService: ReportService,
        private modalService: ModalService,
        private translateService: TranslateService,
        private formStackService: FormStackService
        ) { }

    public ngOnInit(): void {
    }

    public async sendRequest(): Promise<void> {
        try {
            this.modalService.modalPromise(LpModalMailComponent, {
                backdropClass: 'commonToolsBackdropClass'
            }).then( async (closed: boolean) => {
                if ( closed === true) {
                    if (! this.isShortcut && !Util.isNullOrUndefined(this.reportId)) {
                        this.idReport = this.reportId;
                    } else {
                        this.idReport = await this.getReportIdFromCustom();
                    }
                    if (Util.isNullOrUndefined(this.arrayOfParameters)) {
                        this.arrayOfParameters = await this.reportService.getParameters(this.idReport);
                        await this.addParametersObjectAndKey();
                    }
                    await this.reportService.executeWithQueryParams(this.arrayOfParameters, this.reportService.mail, this.reportService.isSpoolerMode);
                    this.modalService.success(this.translateService.instant(MAIL_SENT));
                }
            });
        } catch (e) {
            this.modalService.error(this.translateService.instant(MAIL_NOT_SENT));
            throw e;
        }
    }

    private async addParametersObjectAndKey(): Promise<void> {
        if ( !Util.isNullOrUndefined(this.formStackService) && 
             !Util.isNullOrUndefined(this.formStackService.currentApplicationItem) &&
             !Util.isNullOrUndefined(this.formStackService.currentApplicationItem.linkedObject) &&
             !Util.isNullOrUndefined(this.formStackService.currentData) &&
             !Util.isNullOrUndefined(this.formStackService.currentData.id)) {
                
                for(let i: number = this.arrayOfParameters.length - 1; i>0; i--) {
                    if (this.arrayOfParameters[i].name === OBJECT || this.arrayOfParameters[i].name === KEY) {
                        this.arrayOfParameters.splice(i);
                    }
                }
                this.objectParameters.name = OBJECT;
                this.objectParameters.value = this.formStackService.currentApplicationItem.linkedObject;
                this.arrayOfParameters.push(this.objectParameters);
                this.keyParameters.name = KEY;
                this.keyParameters.value = this.formStackService.currentData.id;
                this.arrayOfParameters.push(this.keyParameters);

        }
    }

    private async getReportIdFromCustom(): Promise<string> {
        let reportIdFromCustom: string;
        if ( this.formStackService && this.formStackService.currentApplicationItemDetail && 
             this.formStackService.currentApplicationItemDetail.reportObject &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportId ) {
                reportIdFromCustom = this.formStackService.currentApplicationItemDetail.reportObject.reportId;
            }
        return reportIdFromCustom;    
    }

}
