import { propertyMeta } from "./meta";

export class LpLinks {
    @propertyMeta('string')
    public id: string;
    @propertyMeta('string')
    public url: string;
    @propertyMeta('string')
    public icon: string;
    @propertyMeta('string')
    public title: string;
}