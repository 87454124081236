import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, CUSTOMER_DEPOSITS, DRIVER_DEPOSITS, DEPOSITS, CUSTOMER_DEPOSIT, DRIVER_DEPOSIT } from '../detail-forms.component';
import { LpDeposit } from 'app/meta/deposit';
import { LpShortTermContract } from 'app/meta/short-term-contract';
import { LpMeta } from 'app/meta/meta';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-deposits',
  templateUrl: './deposits.component.html'
})
export class DepositsComponent extends DetailFormsComponent implements OnInit {

  public data: LpDeposit = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.resultObject = true;
    if (this.formStackService.currentApplicationItemDetail.key === CUSTOMER_DEPOSITS) {
      this.setId(CUSTOMER_DEPOSITS);
      this.object = CUSTOMER_DEPOSITS
    } else {
      this.setId(DRIVER_DEPOSITS);
      this.object = DRIVER_DEPOSITS
    }
    if (this.formStackService.currentApplicationItemDetail.key === CUSTOMER_DEPOSIT) {
      this.setId(CUSTOMER_DEPOSIT);
      this.object = CUSTOMER_DEPOSIT
    } else {
      this.setId(DRIVER_DEPOSIT);
      this.object = DRIVER_DEPOSIT
    }
    this.initUrl();
    this.init();
  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
    this.data['status'].id = '1';
    if (!Util.isNullOrUndefined(this.formStackService.currentData['startLocation'])) {
      this.data['location'] = this.formStackService.currentData['startLocation'];
      this.data['company'] = this.formStackService.currentData['startLocation']['company'];
    }

    if (!Util.isNullOrUndefined(this.formStackService.currentData['startLocation'])
      && this.formStackService.currentData['contractNumber'] !== '') {
        this.data['dealNumber'] = this.formStackService.currentData['contractNumber'];
    } else if (!Util.isNullOrUndefined(this.formStackService.currentData['startLocation'])
    && this.formStackService.currentData['bookingCode'] !== '') {
      this.data['dealNumber'] = this.formStackService.currentData['bookingCode'];
    }
  }

  public async submit(): Promise<void> {
    this.data = this.initDeposit(this.formStackService.currentData, this.data, this.object);
    this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.updateInProgress'));
    if (this.data.id) {
      this.alocproProvider.put(this.uiSyncService.formatURL(this.urlEdit, this.formStackService.currentData),
       this.data.id.toString(), this.data).then(() => {
        this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
        // this.uiSyncService.changeRightBlock(this.applicationItem.id);
        this.rightColService.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail, this.formStackService.currentData);
        this.uiSyncService.loader(false);
      }).catch((error) => {
        this.uiSyncService.loader(false);
      });
    } else {
      this.alocproProvider.post(this.uiSyncService.formatURL(this.urlEdit, this.formStackService.currentData), this.data)
      .then((ky: String) => {
        this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
        this.rightColService.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail, this.formStackService.currentData);
        // this.uiSyncService.changeRightBlock(this.applicationItem.id);
        this.uiSyncService.loader(false);
      }).catch((error) => {
        this.uiSyncService.loader(false);
      });
    }
  }

  protected initUrl(): void {
    if (this.object === CUSTOMER_DEPOSITS) {
      this.urlGet = `invoices/{{customerInvoice.id}}/deposits`;
      this.setId(CUSTOMER_DEPOSITS);
    } else if (this.object === DRIVER_DEPOSITS) {
      this.urlGet = `invoices/{{driverInvoice.id}}/deposits`;
      this.setId(DRIVER_DEPOSITS);
    } else {
      this.setId(DEPOSITS);
      this.urlGet = `${this.object}/{{id}}/deposits`;
    }
    this.urlEdit = this.urlGet;
  }

  private initDeposit(stc: LpMeta, deposit: LpMeta, object: String): LpDeposit {
    let data: LpShortTermContract = new LpShortTermContract();
    if (!Util.isNullOrUndefined(stc)) {
      data.assign(stc);
    }
    let dataDeposit: LpDeposit = new LpDeposit();
    if (!Util.isNullOrUndefined(deposit)) {
      dataDeposit.assign(deposit);
    }
    if (object === CUSTOMER_DEPOSITS) {
      dataDeposit.thirdParty = data.customer;

    } else {
      dataDeposit.thirdParty = data.driver
    }
    return dataDeposit;
  }
}
