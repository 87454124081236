<lp-card *ngIf="widgetConfiguration"
accentColor="{{ widgetConfiguration.accentColor }} position-relative">
<lp-card-header *ngIf="widgetConfiguration.type!==8 && widgetConfiguration.type!==99">
  <div class="btn-group pull-right no-relative">
    <i class="fa fa-cog chartPreference text-warning" (click)="displayRouage()">
    </i>
    <div *ngIf="displayOptions" class="dropdown-menu pt-2" id="optionsRouage" role="menu">
        <ul class="d-table p-0">
          <ng-container *ngIf="options">
            <li *ngFor="let options of optionsCheckBox; let index = index;" role="menuitem" class="d-table-row menuitem checkboxShowLabel datatable-checkbox">
              <input type="checkbox" class="showLabel ms-2 mt-1 mb-1 align-middle me-0 d-table-cell" id="{{widgetConfiguration.title}}-{{index}}"
                [checked]="this.mapOptionsCheckbox.get(options)" (click)="showOptions($event, options)" ng-touched>
              <label class="labelOptionsRouage d-table-cell ps-2 pe-3" for="{{widgetConfiguration.title}}-{{index}}">{{options}}</label>
            </li>
          </ng-container>
        </ul>
        <ul class="p-0">
          <ng-container *ngIf="options">
            <li role="menuitem" class="pt-2 ps-2 pe-2">
              <hr class="border-top m-0">
            </li>
            <li *ngFor="let options of optionsInput;" role="menuitem" class="ps-2 pe-2 pt-2">
              <div class="menuitem checkboxShowLabel datatable-checkbox ms-0">
                <label class="labelOptionsRouage m-0">{{options}} : </label>
                <input class="showLabel optionsInputSelect form-control w-100 m-0"
                  [(ngModel)]="widgetConfiguration.options[this.mapOptionsTraduction.get(options)]"
                  (click)="savePreferences($event, options)" ng-touched>
              </div>
            </li>
          </ng-container>
          <ng-container *ngIf="options">
            <div *ngFor="let options of optionsDropDown;" class="ps-2 pe-2 pt-2">
              <label class="labelOptionsRouage m-0">{{options}} : </label>
              <select class="optionsInputSelect form-control w-100 m-0" role="menuitem">
                <option *ngFor="let option of (mapOptionsDropDown.get(options));  let i = index;"
                  (click)="savePreferences($event, options)">{{option}}</option>
              </select>
            </div>
          </ng-container>
          <ng-container>
            <li role="menuitem" class="pt-0">
              <div class="menuitem">
                <hr *ngIf="options" class="border-top p-1 m-0 mt-2">
                <!--<lp-button-link *ngIf="isDesigner" (click)="goToDesigner()"></lp-button-link>-->
              </div>
              <div *ngIf="options" class="menuitem">
                <!--<lp-button-reset-query *ngIf="isCustom" (click)="prepareToDelete()">
                </lp-button-reset-query>-->
              </div>
            </li>
          </ng-container>
        </ul>
    </div>
  </div>

  <p *ngIf="title" class="h4 text-center mt-1">
      <i *ngIf="title && widgetConfiguration.title !== ''"  class="{{ widgetConfiguration.icon }}"></i>
    <span class="ms-1">{{ widgetConfiguration.title | translate }}</span>
    <ng-content class="pull-right"></ng-content>
  </p>
  <div class="widgetId" style="display: none;">{{widgetConfiguration.id}}</div>
</lp-card-header>
<lp-card-content class="p-3">
  <div #cardContent>
    <div [ngStyle]="{'zoom': svgRatio}">
      <ng-container>
        <lp-progress-spinner *ngIf="showLoadingIndicator"></lp-progress-spinner>
        <div *ngIf="widgetConfiguration.type===1">
          <ngx-charts-area-chart [view]="widgetConfiguration.dimensions?.view" [results]="indexes.items"
            [scheme]="widgetConfiguration.colorScheme" [gradient]="widgetConfiguration.options.gradient"
            [xAxis]="widgetConfiguration.options.xAxis" [yAxis]="widgetConfiguration.options.yAxis"
            [legend]="widgetConfiguration.options.showLegend" [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
            [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" [autoScale]="widgetConfiguration.options.autoScale"
            [timeline]="widgetConfiguration.options.timeline" [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" [schemeType]="widgetConfiguration.options.schemeType"
            [showGridLines]="widgetConfiguration.options.showGridLines" [roundDomains]="widgetConfiguration.options.roundDomains"
            [xScaleMin]="widgetConfiguration.options.xScaleMin" [xScaleMax]="widgetConfiguration.options.xScaleMax"
            [yScaleMin]="widgetConfiguration.options.yScaleMin" [yScaleMax]="widgetConfiguration.options.yScaleMax"
            [animations]="widgetConfiguration.options.animations" [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled" [customColors]="widgetConfiguration.options.customColors"
            (select)="onSelect($event)">
          </ngx-charts-area-chart>
        </div>

        <div *ngIf="widgetConfiguration.type===2">
         <ngx-charts-bar-vertical [view]="widgetConfiguration.dimensions" [scheme]="widgetConfiguration.colorScheme"
            [results]="values" [gradient]="widgetConfiguration.options.gradient" [xAxis]="widgetConfiguration.options.xAxis"
            [yAxis]="widgetConfiguration.options.yAxis" [legend]="widgetConfiguration.options.legend"
            [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
            [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
            [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" 
            [schemeType]="widgetConfiguration.options.schemeType"
            [showGridLines]="widgetConfiguration.options.showGridLines" 
            [roundDomains]="widgetConfiguration.options.roundDomains"
            [barPadding]="widgetConfiguration.options.barPadding" 
            [customColors]="widgetConfiguration.options.customColors"
            [animations]="widgetConfiguration.options.animations" 
            [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
            [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
            [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
            [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
            [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
            [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-vertical>
        </div>

        <div *ngIf="widgetConfiguration.type===3 && ready">
          <ngx-charts-pie-chart [view]="widgetConfiguration.dimensions" [scheme]="widgetConfiguration.colorScheme"
            [results]="values" [gradient]="widgetConfiguration.options.gradient" [legend]="widgetConfiguration.options.legend"
            [labels]="widgetConfiguration.options.labels" [explodeSlices]="widgetConfiguration.options.explodeSlices"
            [doughnut]="widgetConfiguration.options.doughnut" [maxLabelLength]="widgetConfiguration.options.maxLabelLength"
            [trimLabels]="widgetConfiguration.options.trimLabels" [arcWidth]="widgetConfiguration.options.arcWidth"
            [customColors]="widgetConfiguration.options.customColors" [animations]="widgetConfiguration.options.animations"
            [legendTitle]="widgetConfiguration.options.legendTitle | translate" [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled" (select)="onSelect($event)">
          </ngx-charts-pie-chart>
        </div>

        <div *ngIf="widgetConfiguration.type===4">
          <div *ngFor="let item of indexes.items">
            <div class="row">
              <div class="col-md-9">
                <div class="progress {{ item.color }} emission-progress">
                  <div class="progress-bar d-flex justify-content-between emission {{ item.color }}"
                    (click)="onSelect(item.label)">
                    <span *ngIf="item.label != null">{{ item.label }}</span>
                    <span *ngIf="item.label == null">{{ 'widgets.no_value_label' | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 text-end">
                <span class="emission-vehicule">{{ item.number }} {{ "widgets.vehicles" | translate }}</span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="widgetConfiguration.type===5">
          <lp-card-content textColor="white">
            <div class="row">
              <div *ngFor="let item of indexes.items;  let i = index;" [class]="col">
                <div class="card card-inverse card-linear card-{{ item.bgColor }}-linear">
                  <div class="card-block text-center" routerLink="{{constructLink(widgetConfiguration.settings.link[i])}}"
                    [class.routerlink]="constructLink(widgetConfiguration.settings.link[i])"
                    [queryParams]="widgetConfiguration.settings.link[i]?.queryParams">
                    <i [class]="item.icon"></i>
                    <div class="h4 m-b-0">{{ item.value }}</div>
                    <small>{{ item.title | translate }}</small>
                  </div>
                </div>
              </div>
            </div>
          </lp-card-content>
        </div>

        <div *ngIf="widgetConfiguration.type===6">
          <div class="row">
            <div *ngFor="let item of indexes.items;  let i = index;" class="col-sm-4 col-lg-3 p-0" placement="bottom"
              container="body" [attr.data-index]="i"><!-- tooltip="{{ item.tooltip }}"-->
             <canvas class="canvas" routerLink="{{constructLink(widgetConfiguration.settings.link[i])}}"
                [class.routerlink]="constructLink(widgetConfiguration.settings.link[i])" baseChart
                style="margin:0 auto; width: 100px; height: 100px" attr.data="{{item.data}}" chartType="doughnut"
                attr.options="{{doughnutChartOptions}}" attr.colors="{{colorsWidgetCanvas}}"
                [queryParams]="widgetConfiguration.settings.link[i]?.queryParams">
              </canvas>
              <div class="text-center">{{ item.title | translate }}</div>
            </div>
          </div>
        </div>

        <div *ngIf="widgetConfiguration.type===7">
          <ngx-charts-line-chart [results]="indexes.items" [view]="widgetConfiguration.dimensions?.view"
            [scheme]="widgetConfiguration.colorScheme" [gradient]="widgetConfiguration.options.gradient"
            [xAxis]="widgetConfiguration.options.xAxis" [yAxis]="widgetConfiguration.options.yAxis"
            [legend]="widgetConfiguration.options.legend" [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel  | translate"
            [xAxisLabel]="widgetConfiguration.options.xAxisLabel  | translate" [timeline]="widgetConfiguration.options.timeline"
            [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel" [autoScale]="widgetConfiguration.options.autoScale"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled" [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" [curve]="curves[widgetConfiguration.options.curveType]"
            [schemeType]="widgetConfiguration.options.schemeType" [rangeFillOpacity]="widgetConfiguration.options.rangeFillOpacity"
            [showGridLines]="widgetConfiguration.options.showGridLines" [roundDomains]="widgetConfiguration.options.roundDomains"
            [referenceLines]="widgetConfiguration.options.referenceLines" [showRefLines]="widgetConfiguration.options.showRefLines"
            [showRefLabels]="widgetConfiguration.options.showRefLabels" [xScaleMin]="widgetConfiguration.options.xScaleMin"
            [xScaleMax]="widgetConfiguration.options.xScaleMax" [yScaleMin]="widgetConfiguration.options.yScaleMin"
            [yScaleMax]="widgetConfiguration.options.yScaleMax" [customColors]="widgetConfiguration.options.customColors"
            [animations]="widgetConfiguration.options.animations" [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries" (select)="onSelect($event)">
          </ngx-charts-line-chart>
        </div>
        <div *ngIf="widgetConfiguration.type===8">
          <!--<ngx-datatable 
          [class.loading-table]="loadingIndicator && zoomDataList.length ===0"
          class="material table-height-35 table-lpzoom  mb-0 mt-2" 
          [externalSorting] = "true"
          [rows]="values.data" 
          [columnMode]="'force'"
          [headerHeight]="35"
          [rowHeight]="40"
          [scrollbarH]="true"
          [loadingIndicator]="loadingIndicator"
          [messages]="{emptyMessage: 'zoom.emptyDataMessage' | translate}">
              <ngx-datatable-column *ngFor="let column of values.columns" [width]="column.width" name="{{column.wording | translate }}" prop="{{column.prop}}"
              [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                  <lp-cell [type]="column.type" [value]="value[column.id]"></lp-cell>
                </ng-template>
              </ngx-datatable-column>
          </ngx-datatable>-->
          <lp-zoom *ngIf="guid !== null && guid !== undefined" [locProZoomObject]="homepage" [isWidget]=true [modal]=false [widgetObject]="values" [guid]="guid"></lp-zoom>
        </div>

        <div *ngIf="widgetConfiguration.type===9">
          <ngx-charts-bar-horizontal-stacked
            [view]="widgetConfiguration.dimensions" 
            [scheme]="widgetConfiguration.colorScheme"
            [results]="values" 
            [gradient]="widgetConfiguration.options.gradient"  
            [legend]="widgetConfiguration.options.legend"
            [xAxis]="widgetConfiguration.options.xAxis" 
            [yAxis]="widgetConfiguration.options.yAxis"
            [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
            [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" 
            [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
            [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" 
            [showGridLines]="widgetConfiguration.options.showGridLines" 
            [roundDomains]="widgetConfiguration.options.roundDomains"
            [customColors]="widgetConfiguration.options.customColors"
            [animations]="widgetConfiguration.options.animations" 
            [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
            [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
            [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
            [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
            [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
            [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-horizontal-stacked>
        </div>

        <div *ngIf="widgetConfiguration.type===10">
          <ngx-charts-bar-vertical-stacked
            [view]="widgetConfiguration.dimensions" 
            [scheme]="widgetConfiguration.colorScheme"
            [results]="values" 
            [gradient]="widgetConfiguration.options.gradient"  
            [legend]="widgetConfiguration.options.legend"
            [xAxis]="widgetConfiguration.options.xAxis" 
            [yAxis]="widgetConfiguration.options.yAxis"
            [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
            [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" 
            [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
            [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" 
            [showGridLines]="widgetConfiguration.options.showGridLines" 
            [roundDomains]="widgetConfiguration.options.roundDomains"
            [customColors]="widgetConfiguration.options.customColors"
            [animations]="widgetConfiguration.options.animations" 
            [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
            [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
            [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
            [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
            [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
            [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-vertical-stacked>
        </div>

        <div *ngIf="widgetConfiguration.type===11">
          <ngx-charts-bar-horizontal-2d
          [view]="widgetConfiguration.dimensions" 
          [scheme]="widgetConfiguration.colorScheme"
          [results]="values" 
          [gradient]="widgetConfiguration.options.gradient"  
          [legend]="widgetConfiguration.options.legend"
          [xAxis]="widgetConfiguration.options.xAxis" 
          [yAxis]="widgetConfiguration.options.yAxis"
          [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
          [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" 
          [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
          [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
          [yAxisTickFormatting]="formatYAxis"
          [xAxisTickFormatting]="formatXAxis" 
          [showGridLines]="widgetConfiguration.options.showGridLines" 
          [roundDomains]="widgetConfiguration.options.roundDomains"
          [customColors]="widgetConfiguration.options.customColors"
          [animations]="widgetConfiguration.options.animations" 
          [legendTitle]="widgetConfiguration.options.legendTitle | translate"
          [activeEntries]="widgetConfiguration.options.activeEntries"
          [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
          [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
          [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
          [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
          [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
          [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-horizontal-2d>
        </div>

        <div *ngIf="widgetConfiguration.type===12">
          <ngx-charts-bar-vertical-2d
          [view]="widgetConfiguration.dimensions" 
          [scheme]="widgetConfiguration.colorScheme"
          [results]="values" 
          [gradient]="widgetConfiguration.options.gradient"  
          [legend]="widgetConfiguration.options.legend"
          [xAxis]="widgetConfiguration.options.xAxis" 
          [yAxis]="widgetConfiguration.options.yAxis"
          [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
          [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" 
          [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
          [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
          [yAxisTickFormatting]="formatYAxis"
          [xAxisTickFormatting]="formatXAxis" 
          [showGridLines]="widgetConfiguration.options.showGridLines" 
          [roundDomains]="widgetConfiguration.options.roundDomains"
          [customColors]="widgetConfiguration.options.customColors"
          [animations]="widgetConfiguration.options.animations" 
          [legendTitle]="widgetConfiguration.options.legendTitle | translate"
          [activeEntries]="widgetConfiguration.options.activeEntries"
          [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
          [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
          [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
          [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
          [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
          [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-vertical-2d>
        </div>

        <div *ngIf="widgetConfiguration.type===13">
          <ngx-charts-bar-vertical-normalized
            [view]="widgetConfiguration.dimensions" 
            [scheme]="widgetConfiguration.colorScheme"
            [results]="values" 
            [gradient]="widgetConfiguration.options.gradient"  
            [legend]="widgetConfiguration.options.legend"
            [xAxis]="widgetConfiguration.options.xAxis" 
            [yAxis]="widgetConfiguration.options.yAxis"
            [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
            [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" 
            [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
            [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" 
            [showGridLines]="widgetConfiguration.options.showGridLines" 
            [roundDomains]="widgetConfiguration.options.roundDomains"
            [customColors]="widgetConfiguration.options.customColors"
            [animations]="widgetConfiguration.options.animations" 
            [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
            [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
            [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
            [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
            [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
            [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-vertical-normalized>
        </div>

        <div *ngIf="widgetConfiguration.type===14">
          <ngx-charts-bar-horizontal-normalized
            [view]="widgetConfiguration.dimensions" 
            [scheme]="widgetConfiguration.colorScheme"
            [results]="values" 
            [gradient]="widgetConfiguration.options.gradient"  
            [legend]="widgetConfiguration.options.legend"
            [xAxis]="widgetConfiguration.options.xAxis" 
            [yAxis]="widgetConfiguration.options.yAxis"
            [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
            [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" 
            [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
            [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" 
            [showGridLines]="widgetConfiguration.options.showGridLines" 
            [roundDomains]="widgetConfiguration.options.roundDomains"
            [customColors]="widgetConfiguration.options.customColors"
            [animations]="widgetConfiguration.options.animations" 
            [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
            [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
            [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
            [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
            [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
            [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-horizontal-normalized>
        </div>

        <div *ngIf="widgetConfiguration.type===15">
          <ngx-charts-bar-horizontal
            [view]="widgetConfiguration.dimensions" 
            [scheme]="widgetConfiguration.colorScheme"
            [results]="values" 
            [gradient]="widgetConfiguration.options.gradient"  
            [legend]="widgetConfiguration.options.legend"
            [xAxis]="widgetConfiguration.options.xAxis" 
            [yAxis]="widgetConfiguration.options.yAxis"
            [showXAxisLabel]="widgetConfiguration.options.showXAxisLabel"
            [showYAxisLabel]="widgetConfiguration.options.showYAxisLabel" 
            [xAxisLabel]="widgetConfiguration.options.xAxisLabel | translate"
            [yAxisLabel]="widgetConfiguration.options.yAxisLabel | translate" 
            [yAxisTickFormatting]="formatYAxis"
            [xAxisTickFormatting]="formatXAxis" 
            [showGridLines]="widgetConfiguration.options.showGridLines" 
            [roundDomains]="widgetConfiguration.options.roundDomains"
            [customColors]="widgetConfiguration.options.customColors"
            [animations]="widgetConfiguration.options.animations" 
            [legendTitle]="widgetConfiguration.options.legendTitle | translate"
            [activeEntries]="widgetConfiguration.options.activeEntries"
            [tooltipDisabled]="widgetConfiguration.options.tooltipDisabled"
            [trimXAxisTicks]="widgetConfiguration.options.trimXAxisTicks"
            [trimYAxisTicks]="widgetConfiguration.options.trimYAxisTicks"
            [maxXAxisTickLength]="widgetConfiguration.options.maxXAxisTickLength"
            [maxYAxisTickLength]="widgetConfiguration.options.maxYAxisTickLength" 
            [ngStyle]="{'zoom': svgRatio}">
          </ngx-charts-bar-horizontal>
        </div>

        <div *ngIf="widgetConfiguration.type===16 && sanitizedUrl">
          <iframe [src]="sanitizedUrl" width="100%" height="100%" style="min-height: 600px;"></iframe>
        </div>

        <div *ngIf="widgetConfiguration.type===99">
          <div [innerHTML]="values"></div>
        </div>

      </ng-container>
    </div>
  </div>
</lp-card-content>
</lp-card>