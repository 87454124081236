<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<span  [class.readonly]="readonly" [class.disabled]="disabled">
  <div class="input-group">
    <input class="textField form-control"
      placeholder="{{placeholder | translate}}" [(ngModel)]="displayValue" (focus)="focus($event)"
      (focusout)="updateFocusOut();" [disabled]="disabled" title="" [ngStyle]="style"
      [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [readonly]="this.formStackService.getFields(name).readonly"
      pattern="(0[1-9]|[12][0-9]|3[01])[- /.]\d\d"/>
    <i class="input-notif"></i>
    <span class="input-group-btn">
      <button type="button" class="btn waves-effect waves-light btn-primary" [owlDateTimeTrigger]="dt1">
        <i class="icon vega-openingHours"></i>
      </button>
    </span>
  </div>
  <owl-date-time #dt1 [pickerMode]="'dialog'" [firstDayOfWeek]=1 [pickerType]="'calendar'"
  [startView]="'multi-years'"
  (afterPickerOpen)="focusPicker($event)" 
  (yearSelected)="chosenYearHandler($event)"
  (monthSelected)="chosenMonthHandler($event, dt1)"></owl-date-time>
</span>

<input type="hidden" [owlDateTime]="dt1" />