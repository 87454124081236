<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.debugg' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body debug-modal">
    <lp-ui-accordion [menu]=false>
    <lp-ui-accordion-group heading="Current data"  [open]=true> 
      <!--<json-editor #editor1 [options]="jsonEditorOptions" name="formStackInput" id="formStackInput" [(ngModel)]="formStackServiceData" (onChange)="setTextAndCode()">
      </json-editor>-->
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Version"  [open]=false >
      <div>
        <pre>{{ appVersion | json }}</pre>
      </div>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Repository version"  [open]=false >
      <div>
        <pre>{{ repositoryVersion | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>
</div>
