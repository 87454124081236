import { Injectable } from "@angular/core";
import { Pricing } from "app/meta/pricings/pricings";
import { AlocproProvider } from "./alocpro.provider";

@Injectable({
    providedIn: 'root'
  })
export class PricingProvider {

    constructor(protected aloc: AlocproProvider) {}

    public async getDetailsData(type :string, url: string) : Promise<Pricing>{
        let tempData: any = {"details": null, "_readOnly":false, "_xVegaWorkflowsCount": 0, "_xVegaAttachmentsCount":0, "_xVegaEdisCount":0, "id": "NATIONAL","wording": "TARIF NATIONAL","pricingMethods" : [{"id": "K590WAYVP024NG14001V","wording": "Location 7 Jours / 1 500 Km","includedDays" : 2,"includedKilometers" : 100,"category" : {"id" : "A","wording": "7/9m3"},"isActive" : true,"amount" : {"day" : 25,"additionnalDay" : 25,"kilometers" : 25,"additionnalKilometers" : 25,"fullDeductibleBuyBack": 25,"reducedDeductibleBuyback": 25,"deductibleBuyBack": 25},"pricingTime" : {"id": "xxxx","startDate": "2022-01-01T00:00:00.000Z","endDate": "2022/12/31T00:00:00.000Z"}},{"id": "K590WAYVP024NG14001N","wording": "Location 7 Jours / 1 500 Km","includedDays" : 2,"includedKilometers" : 100,"category" : {"id" : "B","wording": "Citadine"},"isActive" : true,"amount" : {"day" : 50,"additionnalDay" : 50,"kilometers" : 50,"additionnalKilometers" : 50,"fullDeductibleBuyBack": 50,"reducedDeductibleBuyback": 50,"deductibleBuyBack": 50},"pricingTime" : {"id": "xxxx","startDate": "2022-01-01T00:00:00.000Z","endDate": "2022-12-31T00:00:00.000Z"}}],"specificPricingMethods" : [
            {"id": "id","linkedId": "K590WAYVP024NG14001V","wording": "Location 7 Jours / 1 500 Km","category" : {"id" : "A","wording": "7/9m3"},"isActive" : true,"amount" : {"day" : 10,"additionnalDay" : 10,"kilometers" :  10,"additionnalKilometers" : 10,"fullDeductibleBuyBack": 10,"reducedDeductibleBuyback": 10,"deductibleBuyBack": 10},"pricingTime" : {"id": "xxxx ","startDate": "01/10/2022","endDate": "31/12/2022"},"pricing" : {"id": "01100","wording": "TARIF PERSO AGENCE 01100"}},
            {"id": "id","linkedId": "K590WAYVP024NG14001V","wording": "Location 7 Jours / 1 500 Km","category" : {"id" : "A","wording": "7/9m3"},"isActive" : true,"amount" : {"day" : 10,"additionnalDay" : 10,"kilometers" :  10,"additionnalKilometers" : 10,"fullDeductibleBuyBack": 10,"reducedDeductibleBuyback": 10,"deductibleBuyBack": 10},"pricingTime" : {"id": "xxxx ","startDate": "01/10/2022","endDate": "31/12/2022"},"pricing" : {"id": "01100","wording": "TARIF PERSO AGENCE 01100"}}
        ]};
        return Promise.resolve(tempData);
    }

    public async getDetailsRealDatas(url: string) : Promise<Pricing>{
        try { 
            let pricingDetail: Pricing = await this.aloc.getPricing(url);
            return pricingDetail;
        } catch(e) {
            throw e
        }
    }

    public async removeLine(url: string, id: string): Promise<void> {
        await this.aloc.delete(url, id);
    }

}