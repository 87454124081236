<div class="modal-header mb-3">
  <h4 class="modal-title pull-left">{{ 'workflows.edition' | translate }}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="closeModal('editModal')"></lp-button-cancel>
  </div>
</div>

<div class="container label-2-colonnes">
  <form #form="ngForm" ngNativeValidate>
    <div *ngIf="!formStackService.specificApplicationItem || !formStackService.specificApplicationItem.useJsonEditForm">
      <div class="row">
        <div class="form-group col col-md-6">
          <lp-label-field for="activity" class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
            text="workflows.activity"></lp-label-field>
          <lp-dropdown-field id="activity" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="textField" cssClass="form-control" name="workflows.activity"
            [path]="{'verb': 'workflows/activities'}"
            [(ngModel)]="currentWorkflow.activity" (change)="setFieldsWithActivityData()"></lp-dropdown-field>
        </div>
        <!-- field type -->
        <div class="form-group col col-md-6">
          <lp-label-field for="type" class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
            text="workflows.type" [required]="true"></lp-label-field>
          <lp-dropdown-field id="type" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}" className="textField"
            cssClass="form-control" name="workflows.type"
            [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'workflowType'}]}"
            [(ngModel)]="currentWorkflow.type" [required]=true></lp-dropdown-field>
        </div>
        <!-- field priority -->
        <div class="form-group col col-md-6">
          <lp-label-field for="priority" class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
            text="workflows.priority" [required]="true"></lp-label-field>
          <lp-dropdown-field id="priority" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="textField" cssClass="form-control" name="workflows.priority"
            [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'priority'}]}"
            [(ngModel)]="currentWorkflow.priority" [required]=true></lp-dropdown-field>
        </div>
        <!-- Field triggerDate-->
        <div class="form-group col col-md-6">
          <lp-label-field for="triggerDate" class="{{ configService.get('constanteClasses').DEFAULT_LABEL }}"
              text="workflows.date" [required]="true"></lp-label-field>
          <lp-date-time id="triggerDate" cssClass="form-control" class="{{ configService.get('constanteClasses').DEFAULT_INPUT }}"
            name="workflows.triggerDate" [(ngModel)]="currentWorkflow.triggerDate" [required]=true></lp-date-time>
        </div>
      </div>

      <div class="row">
        <div class="form-group col col-md-6" *ngIf="currentWorkflow.relatedObject !== verb+'/'+ky">
          <lp-label-field for="relatedObject" class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
            text="workflows.relatedObject"></lp-label-field>
            <lp-childform-link verb="short-term-contracts" class="btn-update" [isStacked]=false>
              <lp-text-field id="relatedObject" className="form-control"
                class="{{configService.get('constanteClasses').DEFAULT_INPUT}}" cssClass="textareaField"
                name="workflows.relatedObject" [(ngModel)]="currentWorkflow.relatedObject" [disabled]=true>
              </lp-text-field>
            </lp-childform-link>
        </div>
        <!--Field recipient-->

      <!-- field wording -->
      <div class="form-group col col-md-6">
          <lp-label-field for="wording" [required]="true" class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
            text="workflows.wording"></lp-label-field>
          <lp-text-field id="wording" className="textField" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            cssClass="form-control" name="workflows.wording" [(ngModel)]="currentWorkflow.wording" [required]=true type="text">
          </lp-text-field>
        </div>
        <!-- field recipient -->
        <div class="form-group col col-md-6">
          <lp-label-field for="recipient" class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
              text="workflows.recipient"></lp-label-field>
          <lp-zoom-field id="recipient" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            addModifyVerb="users"  libSearch="wording" zoomId='users' className="zoomField"
            cssClass="form-control" [(ngModel)]="currentWorkflow.recipient"
            [inputSearch]="currentWorkflow.recipient?.wording" searchTextField="wording"
            [tableBindValues]="['id', 'wording']" [tableShowField]="['wording']"
              name="workflows.recipient" kySearch="id" title="general.selectUser"
            [modal]=true [showModifyButton]=false [showAddButton]=false>
          </lp-zoom-field>
        </div>
      </div>

      <div class="row">
        <!-- field details -->
        <div class="form-group col col-md-6">
          <lp-label-field class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
            text="workflows.details"></lp-label-field>
          <lp-textarea-field id="details" className="textField" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            cssClass="textareaField" name="workflows.details" [(ngModel)]="currentWorkflow.details">
          </lp-textarea-field>
        </div>

        <!-- field remarq -->
        <div class="form-group ccol col-md-6">
          <lp-label-field class="{{configService.get('constanteClasses').DEFAULT_LABEL}}" for="remarq"
            text="workflows.remarq"></lp-label-field>
          <lp-textarea-field id="remarq" className="textField" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            cssClass="textareaField" name="workflows.remarq" [(ngModel)]="currentWorkflow.remarq">
          </lp-textarea-field>
        </div>
      </div>
      <div class="row">

        <!-- field documentNumber -->
        <div class="form-group col col-md-6 d-none">
          <lp-label-field for="documentNumber" class="{{configService.get('constanteClasses').DEFAULT_LABEL}}"
            text="workflows.documentNumber"></lp-label-field>
          <lp-text-field id="documentNumber" className="textField"
            class="{{configService.get('constanteClasses').DEFAULT_INPUT}}" cssClass="form-control" name="workflows.documentNumber"
            [(ngModel)]="currentWorkflow.documentNumber" type="text">
          </lp-text-field>
        </div>

        <!-- Field lastUpdateDate-->
        <div class="form-group col col-md-6">
          <lp-label-field for="lastUpdateDate" class="{{ configService.get('constanteClasses').DEFAULT_LABEL }}"
            text="workflows.lastUpdateDate"></lp-label-field>
          <lp-date id="lastUpdateDate" cssClass="form-control"
            class="{{ configService.get('constanteClasses').DEFAULT_INPUT }}" name="workflows.lastUpdateDate"
            [(ngModel)]="currentWorkflow.lastUpdateDate" [disabled]=true></lp-date>
        </div>
        <!-- Field closure 1/2 TODO check after back implementation-->
        <div class="form-group col col-md-6">
          <lp-label-field for="closureDate" class="{{ configService.get('constanteClasses').DEFAULT_LABEL }}"
            text="workflows.closureDate"></lp-label-field>
          <lp-date id="closureDate" cssClass="form-control" class="{{ configService.get('constanteClasses').DEFAULT_INPUT }}"
            name="workflows.closureDate" [(ngModel)]="currentWorkflow.closureDate" [disabled]=true>
          </lp-date>
        </div>
      </div>
    </div>
    <!-- field activity -->

  
    <lp-dynamic-main-form *ngIf="formStackService.specificApplicationItem && formStackService.specificApplicationItem.useJsonEditForm" [data]="currentWorkflow" [specificApplicationItem]="true"></lp-dynamic-main-form>

    <div class="col-12 m-2">
      <button class="transparent-btn pull-right" (click)="saveCurrentWorkflow(form.valid)" title="{{'general.save' | translate}}">
        <i class="vega-diskette"></i>
      </button>
      <button class="transparent-btn pull-right" (click)="modalDeleteWorkflow(currentWorkflow.id)" title="{{'general.delete' | translate}}"
        *ngIf="currentWorkflow.id">
        <i class="vega-dustbin"></i>
      </button>
      <button class="transparent-btn pull-right"  [ngClass]="{'active': currentWorkflow.closureDate == null}" title="{{'workflows.cancel' | translate}}"
      aria-label="Close" (click)="cancelCurrentWorkflow()" *ngIf="currentWorkflow.isCancelled && currentWorkflow.id">
        <i class="vega-delete"></i>
      </button>
      <button class="transparent-btn pull-right" [ngClass]="{'active': currentWorkflow.closureDate === null}"
      (click)="closeCurrentWorkflow()" *ngIf="currentWorkflow.closureDate != null"  title="{{'workflows.done' | translate}}">
        <i class="vega-check"></i>
      </button>
      <button class="transparent-btn pull-right" (click)="navigateToAttachment()" *ngIf="currentWorkflow.relatedObject != null"
      title="{{'workflows.relatedObject' | translate}}"> <i class="vega-relatedObject"></i>
    </button>
    </div>
  </form>
  
</div>