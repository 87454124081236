import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'lp-card-content',
    templateUrl: './card-content.component.html'
})
export class LpCardContentComponent implements OnInit {

    @Input() public color: string;
    @Input() public textColor: string;

    public ngOnInit(): void {
        this.color = (this.color) ? 'card-' + this.color : '';
        this.textColor = (this.textColor) ? 'text-' + this.textColor : '';
    }

}
