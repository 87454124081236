<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="fines.fines"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire -->
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject"[ky]="data.id"  [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton" 
           (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
           <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">

            <!--Field number Fine-->
            <div class="row">
              <div class="form-group col-xl-6">
                <lp-text-field id="number" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" label="fines.number"
                  name="number" [(ngModel)]="data.number" placeholder="fines.number"></lp-text-field>
              </div>
              <!--Field increasedFineNumber Fine-->
              <div class="form-group col-xl-6">
                <lp-text-field id="increasedFineNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                  name="increasedFineNumber" [(ngModel)]="data.increasedFineNumber" label="fines.increasedFineNumber"
                  placeholder="fines.increasedFineNumber"></lp-text-field>
              </div>
            </div>

            <div class="row">
              <!--Field place Fine-->
              <div class="form-group col-xl-6">
                <lp-text-field id="place" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" label="fines.place"
                  name="place" [(ngModel)]="data.place" placeholder="fines.place"></lp-text-field>
              </div>
              <!--Field registration Fine-->
              <div class="form-group col-xl-6">
                <lp-text-field id="registration" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" label="fines.registration"
                  name="registration" [(ngModel)]="data.registration" placeholder="fines.registration"></lp-text-field>
              </div>
            </div>

          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="general.other.description" [open]=false key="description">
            
            <div class="row">
              <!--Field nature Fine-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="nature" cssClass="form-control" label="fines.nature"
                  name="nature" [required]=true [(ngModel)]="data.nature" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'fineNature'}]}"></lp-dropdown-field>-->
                  <lp-zoom-field id="nature"  class="{{arrayClassesElements.get('defaultInput')}}" zoomId='nature' label="fines.nature"
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.nature" name="nature">
                  </lp-zoom-field>
                </div>
              <!--Field level Fine-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="level" cssClass="form-control" label="fines.level"
                  name="level" [required]=true [(ngModel)]="data.level" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'fineLevel'}]}"></lp-dropdown-field>-->
                <lp-zoom-field id="level"  class="{{arrayClassesElements.get('defaultInput')}}" zoomId='level' label="fines.level"
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.level" name="level">
                </lp-zoom-field>
                </div>
            </div>

            <div class="row">
              <!--Field driver Fine-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="driver"  class="{{arrayClassesElements.get('defaultInput')}}" zoomId='driver' label="fines.driver"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.driver" name="driver">
                </lp-zoom-field>
              </div>
              <!--Field customer Fine-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="customer" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='customer' label="fines.customer"
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.customer" name="customer">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field company Fine-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="company" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='company'  label="fines.company"
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.company" name="company">
                </lp-zoom-field>
              </div>
              <!--Field movement Fine-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="movement" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='movements'  label="fines.movement"
                    className="zoomField" cssClass="form-control" [ngModel]="data.movement" name="movement">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field location Fine-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="location"  class="{{arrayClassesElements.get('defaultInput')}}" zoomId='startLocation' label="fines.location"
                className="zoomField" cssClass="form-control" [(ngModel)]="data.location" name="location">
                </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <lp-zoom-field id="customer" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='customer'  label="fines.denounced"
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.denounced" name="customer">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field equipment Fine-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="equipment" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='equipment'  label="fines.equipment"
                className="zoomField" cssClass="form-control" [(ngModel)]="data.equipment" name="equipment">
                </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" label="fines.status"
                id="status" [ignoreChange]="true" cssClass="form-control" name="status"
                [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'various'}]}"
                [(ngModel)]="data.status"></lp-dropdown-field>-->
                <lp-zoom-field id="status" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='status'  label="fines.status"
                className="zoomField" cssClass="form-control" [(ngModel)]="data.status" name="status">
                </lp-zoom-field>
              </div>
            </div>

          </lp-ui-accordion-group>

          <!--Accordion Debugger-->
          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>