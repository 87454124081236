<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="metadataApplicationItemDetails.historicalMileages.mileage" [open]=true>
    <form id="formHistoricalMileage" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

      <!-- Field wording -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.wording" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.wording"></lp-label-field>
        <lp-text-field id="historicalMileages.wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
          name="historicalMileages.wording" [(ngModel)]="data.wording"></lp-text-field>
      </div>

      <!-- Champ historicDate -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.historicDate" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.historicDate"></lp-label-field>
        <lp-date id="historicalMileages.historicDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="historicalMileages.historicDate"
           [(ngModel)]="data.historicDate"></lp-date>
      </div>

      <!-- Field mileage --><!-- Champ mileageDate -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.mileage" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.mileageValueDate"></lp-label-field>
        <lp-number id="mileage" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="mileage"
           [(ngModel)]="data.mileage" minlength="2"></lp-number>
        <lp-date id="historicalMileages.mileageDate" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-3" name="historicalMileages.mileageDate"
           [(ngModel)]="data.mileageDate"></lp-date>
      </div>

      <!-- field mileage2 --><!-- Field mileage2Date-->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.mileage2" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.mileage2ValueDate"></lp-label-field>
        <lp-number id="historicalMileages.mileage2" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="historicalMileages.mileage2"
          [(ngModel)]="data.mileage2" minlength="2"></lp-number>
        <lp-date id="historicalMileages.mileage2Date" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-3" name="historicalMileages.mileage2Date"
          [(ngModel)]="data.mileage2Date"></lp-date>
      </div>

      <!-- Field isSet -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.isSet" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.isSet"></lp-label-field>
         <lp-boolean-switch-box name="historicalMileages.isSet" switchName="isSet" id="historicalMileages.isSet" [(ngModel)]="data.isSet"></lp-boolean-switch-box>
      </div>

      <!-- Field counterStart --><!-- Field counterEnd -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.counterStartEnd" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.counterStartEnd"></lp-label-field>
        <lp-number id="historicalMileages.counterStart" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="historicalMileages.counterStart"
          [(ngModel)]="data.counterStart"
          minlength="3"></lp-number>
          <div class="col-1 sep-input pe-0 ps-0 text-center">/</div>
        <lp-number id="historicalMileages.counterEnd" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="historicalMileages.counterEnd"
          [(ngModel)]="data.counterEnd" minlength="3"></lp-number>
      </div>

      <!-- Field kilometerKind -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.kilometerKind" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.kilometerKind"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
          name="historicalMileages.kilometerKind" [(ngModel)]="data.kilometerKind" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'kilometerKind'}]}"></lp-dropdown-field>
      </div>

      <!-- Field movement -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.movement" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.movement"></lp-label-field>
        <lp-zoom-field id="historicalMileages.movement" zoomId="historicalMileages.movements" name="historicalMileages.movement" [(ngModel)]="data.movement"
        class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
        </lp-zoom-field>
      </div>

      <!-- Field longitude and latitude -->
      <div class="form-group col-xl-12">
          <lp-label-field for="historicalMileages.latitudeLongitude" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.latitudeLongitude"></lp-label-field>
        <lp-number id="historicalMileages.latitude" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="historicalMileages.latitude"
          [(ngModel)]="data.latitude" minlength="3"></lp-number>
        <div class="col-1 sep-input pe-0 ps-0 text-center">/</div>
        <lp-number id="historicalMileages.longitude" cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2" name="historicalMileages.longitude"
          [(ngModel)]="data.longitude" minlength="3"></lp-number>
      </div>

      <!-- Champ positionDate -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.positionDate" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.positionDate"></lp-label-field>
        <lp-date id="historicalMileages.positionDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="historicalMileages.positionDate"
          [(ngModel)]="data.positionDate"></lp-date>
      </div>

      <!-- Field various -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.various" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.various"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" id="historicalMileages.various"
          name="historicalMileages.various" [(ngModel)]="data.various" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'various'}]}"></lp-dropdown-field>
      </div>

      <!-- Field moduleOriginLink -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.moduleOriginLink" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.moduleOriginLink"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" id="historicalMileages.moduleOriginLink"
          name="historicalMileages.moduleOriginLink" [(ngModel)]="data.moduleOriginLink" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'listOfTables'}]}">
        </lp-dropdown-field>
      </div>

      <!-- Field moduleOriginId -->
      <div class="form-group col-xl-12">
        <lp-label-field for="historicalMileages.moduleOriginId" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.historicalMileages.moduleOriginId"></lp-label-field>
        <lp-text-field id="historicalMileages.moduleOriginId" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
          cssClass="form-control" name="historicalMileages.moduleOriginId"
          [(ngModel)]="data.moduleOriginId" disabled=true></lp-text-field>
      </div>

      <!-- TODO  -->
      <!-- Field equipmentAttached -->
      <!-- Field uniqueCode -->


      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>

  <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>