import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChoiceFieldComponent } from '../choice-field.component';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-switch-box',
  templateUrl: './switch-box.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchBoxComponent),
      multi: true
    }
  ]
})
export class SwitchBoxComponent extends ChoiceFieldComponent implements OnInit {

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre switchName permet de donner un nom au  champ.
   *
   */
  @Input() public switchName: string;

  /**Le paramètre InputBox représente le nom de la structure présente dans notre back - end, dont on veut modifier/ajouter des champs.
   *
   */
  @Input() public inputBox: string;

  /**Le paramètre Checked est le booléen qui sera passé à TRUE, pour venir pré-cocher la checkbox.
   * Le booléen est utilisé dans la fonction checkedBox().
   */
  @Input() public checked: Boolean;

  public ngOnInit(): void {
    super.ngOnInit();
    this.checked = false;
  }

  /**La fonction emit() permet de mettre à jour la valeur bindingValue.
   * La fonction emit() est utilisé par un click, situé dans le template du composant.
   * Ici la valeur à récupérer dans le parametre event, pour ceci, nous utilisons event.target.value.
   * @param event : event
   */
  public emit(event: any): void {
    if (this.previousValue !== event.target.value && Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange) {
      this.setHasChanged(true, this);
    }
    this.setDisplayValue(event.target.value);
  }
}
