import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MenuItem } from 'app/models/user.interface';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ChangeService } from 'app/services/change.service';
import { ModalService } from 'app/services/modal.service';
import { RouterService } from 'app/services/router.service';
import { LpModalChangeComponent } from '../lp-modal/lp-modal-change/lp-modal-change.component';
import { LpLinks } from 'app/meta/links';
import { LinksProvider } from 'app/providers/links.provider';
import { LinksService } from 'app/services/links.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lp-outbar',
  templateUrl: './lp-outbar.component.html'
})
export class LpOutbarComponent implements OnInit, OnDestroy {

  public outbarMenu: any;
  public tempArray: Array<MenuItem> = [];
  public links: Array<LpLinks> = [];
  private subscriptionRefreshLinks: Subscription;


  constructor(
    public userService: UserService,
    private formStackService: FormStackService,
    private uiSyncService: UiSyncService,
    private changeService: ChangeService,
    private modalService: ModalService,
    private routerService: RouterService,
    private linksProvider: LinksProvider,
    private linksService: LinksService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.subscribe();
    await this.getLinks();
  }

  public async ngOnDestroy(): Promise<void> {
    await this.unsubscribe();
  }

  public setFormStack(verb: string): void {
      if (this.changeService.checkIfpreviousChanged(-1) || this.changeService.areDetailsChanged2()) {
        this.modalService.modalPromise(LpModalChangeComponent, {
          backdropClass: 'alertBackdropClass',
          id : 'modalWithHeader'
        }).then((result: boolean) => {
          if (result) {
            this.formStackService.CurrentVerb = verb;
            this.formStackService.clearStack();
            this.uiSyncService.currentBreadCrumb = [];
            this.routerService.navigate(verb);
            this.changeService.clearAllDetailsChanged2();
            this.changeService.clearAll();
          }
        });
      } else {
        this.formStackService.CurrentVerb = verb;
        this.formStackService.clearStack();
        this.changeService.clearAll();
        this.uiSyncService.currentBreadCrumb = [];
        this.routerService.navigate(verb);
      }
  }

  public async getLinks(): Promise<void> {
    this.links = await this.linksProvider.getLinksList()
  }

  public async subscribe(): Promise<void> {
    this.subscriptionRefreshLinks = this.linksService.refreshLinksEvtEmitter.subscribe( async () => {
      await this.getLinks();
    });
  }

  public async unsubscribe(): Promise<void> {
    if (this.subscriptionRefreshLinks) {
      this.subscriptionRefreshLinks.unsubscribe();
    }
  }

}
