import { Injectable } from '@angular/core';
import { LpMeta } from 'app/meta/meta';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class JsdataService {
  constructor() {
  }

  public hasDataRef(data: LpMeta | any, path: any): Boolean {
    return _.has(data, path);
  }

  public getDataRef(data: LpMeta | any, path: any): any {
    return _.get(data, path);
  }

  public getDataRefForZoom(data: any, path: any): any {
    return _.get(data, path);
  }

  public setDataRef(data: LpMeta, path: any, value: any): any {
    return _.set(data, path, value);
  }

}
