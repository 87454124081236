import { RootFormDetails } from './rootform-details';
import { ApplicationItemDetail } from './application-item-detail';
import { ZoomConfig } from './zoom-metada';
import { ApplicationItemAction } from './application-item-actions';
import { LpMeta } from 'app/meta/meta';
import { MainEditForm } from './main-detail-forms';

export class ApplicationItem {
    public id: Number;
    public verb: String;
    public classes: String;
    public title: String;
    public ecrIndiceid: String;
    public ecrId: String;
    public oldDetails: RootFormDetails = new RootFormDetails();
    public details: ApplicationItemDetail[] = [];
    public data: LpMeta[];
    public applicationItemDetail: ApplicationItemDetail;
    public actions: ApplicationItemAction[];
    public defaultDetail: Number;
    public active: boolean;
    public mainZoomConfig: ZoomConfig;
    public childZoom: ZoomConfig[];
    public linkedObject: String;
    public paging: Boolean;
    public useJsonEditForm: boolean
    public editForm?: MainEditForm
    public workflows?: boolean;
    public traces?: boolean;
    public edis?: boolean;
    public delete?: boolean;
    public defaultSaveAction?: string
}
