import { Injectable } from '@angular/core';
import { ApplicationItem } from 'app/models/application-item';
import { ExampleApplicationItemDetailsProvider } from './example.application-item-details.provider';
import { ApplicationItemDetail } from 'app/models/application-item-detail';

@Injectable()
export class ExampleApplicationItemsProvider {

  private applicationItem: ApplicationItem = {
    'verb': 'examples',
    'title': 'examples.examples',
    'linkedObject': 'examples',
    'ecrIndiceid': '',
    'ecrId': 'test',
    'classes': 'lp-8003-robotics',
    'details': [],
    'oldDetails': null,
    'data': null,
    'applicationItemDetail': null,
    'actions': null,
    'defaultDetail': null,
    'active': true,
    'mainZoomConfig': null,
    'paging': null,
    'useJsonEditForm': true,
    'editForm': {
      'columns': 2,
      'id': 'examples',
      'active': true,
      'title': 'titre',
      'availableEval': '',
      'accordions': [
      ]
    },
    'childZoom': [
      {
        'isFromChild': false,
        'kySearch': 'id',
        'bindingValueKeys': null,
        'selectedDataKeys': null,
        'disabled': null,
        'showAddButton': null,
        'isString': null,
        'id': null,
        'linesPerPage': null,
        'isMainZoom': null,
        'displayAddButton': null,
        'searchTextField': 'id',
        'tableBindValues': [
          'id',
          'firstName',
          'lastName',
          'details'
        ],
        'tableShowField': [
          'id',
          'firstName',
          'lastName'
        ],
        'addModifyVerb': 'third-parties',
        'urlNew': 'third-parties',
        'bindingProp': 'customer',
        'historyKey': 'H_y1q8q9',
        'modal': false,
        'isScheduler': false,
        'showModifyButton': true,
        'title': 'general.selectTiers',
        'textSearch': 'id',
        'isClosable': true,
        'showLpFieldDetails': true,
        'zoomId': 'zoom',
        'inputSearch': 'data.customer?.id',
        'parentZoomBindFields': [
          'discountRate'
        ],
        'parentTableBindValues': [
          'discountRate'
        ],
        'isLocProZoom': true,
        'locProZoomObject': 'shortTermContract',
        'locProZoomAttribut': 'customer'
      },
      {
        'isFromChild': false,
        'kySearch': 'id',
        'bindingValueKeys': null,
        'selectedDataKeys': null,
        'disabled': true,
        'showAddButton': null,
        'isString': null,
        'id': null,
        'linesPerPage': null,
        'isMainZoom': null,
        'displayAddButton': null,
        'searchTextField': 'id',
        'tableBindValues': [
          'id',
          'firstName',
          'lastName',
          'details'
        ],
        'tableShowField': [
          'id',
          'firstName',
          'lastName'
        ],
        'addModifyVerb': 'third-parties',
        'urlNew': 'third-parties',
        'bindingProp': 'customer',
        'historyKey': 'H_y1q8q9',
        'modal': false,
        'isScheduler': false,
        'showModifyButton': true,
        'title': 'general.selectTiers',
        'textSearch': 'id',
        'isClosable': true,
        'showLpFieldDetails': true,
        'zoomId': 'customCodeZoom',
        'inputSearch': 'data.customer?.id',
        'parentZoomBindFields': [
          'discountRate'
        ],
        'parentTableBindValues': [
          'discountRate'
        ],
        'isLocProZoom': true,
        'locProZoomObject': 'shortTermContract',
        'locProZoomAttribut': 'customer'
      }
    ],
    'id': 33
  };


  constructor(
    private exampleApplicationItemDetailsProvider: ExampleApplicationItemDetailsProvider) {
  }


  public getAppicationItem(): Promise<ApplicationItem> {
    return new Promise((resolve) => {
      /*const fs = require('fs');
      let rawdata = fs.readFileSync('/metadata/application-item.json');
      let applicationItem = JSON.parse(rawdata);
      console.log(applicationItem);*/
      this.exampleApplicationItemDetailsProvider.getAppicationItemDetails().then((applicationItemDetail: Array<ApplicationItemDetail>) => {
        this.applicationItem.details = applicationItemDetail;
        resolve(this.applicationItem);
      })

    });
  }

}
