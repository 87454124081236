import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public localStorage: Storage;

  constructor() {
    if (!localStorage) {
      throw new Error('Current browser does not support Local Storage');
    }
    this.localStorage = localStorage;
  }

  public set(key: string, value: string): void {
    this.localStorage.setItem(key, value);
  }

  public get(key: string): string | null {
    return this.localStorage.getItem(key) || null;
  }

  public setObject(key: string, value: any): void {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  public getObject(key: string): any {
    return JSON.parse(this.localStorage.getItem(key) || null);
  }

  public remove(key: string): void {
    this.localStorage.removeItem(key);
  }

  public removeAll(): void {
    this.localStorage.clear();
  }
}
