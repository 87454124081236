import { Injectable } from '@angular/core';
import { FormStackService } from '../form-stack.service';
import { MainZoomService } from './mainZoom.service';
import { KyService } from './ky.service';
import { HistoryService } from './history.service';
import { ApplicationItem } from 'app/models/application-item';
import { Util } from 'app/statics/utils';
import { NewService } from './new.service';
import { LpMeta } from 'app/meta/meta';
import { UiSyncService } from '../ui-sync.service';
import { ChangeService } from '../change.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../modal.service';
import { ApplicationItemDetail } from 'app/models/application-item-detail';
import { RouterService } from '../router.service';
import { RightColService } from './rightCol.service';
import { ConfigService } from '../config.service';
import { UserHistoryService } from '../user-history.service';
import { lastValueFrom } from 'rxjs';

const TO_CREATE: string = 'ToCreate';
@Injectable({
  providedIn: 'root'
})
export class FormService {

  public currentApplicationItem: ApplicationItem;
  public currentApplicationItemDetail: ApplicationItemDetail;
  public isHisto: boolean;

  constructor(
    private formStackService: FormStackService,
    private mainZoomService: MainZoomService,
    private kyService: KyService,
    private newService: NewService,
    private historyService: HistoryService,
    private uiSyncService: UiSyncService,
    private changeService: ChangeService,
    private translate: TranslateService,
    private modalService: ModalService,
    private router: RouterService,
    private rightColService: RightColService,
    private configService: ConfigService,
    private userHstoryService: UserHistoryService) { }

  public async build(currentVerb?: string, currentKy?: string, stackId?: number, previouslySaved?: boolean): Promise<void> {
    let translation: any = await lastValueFrom(this.translate.get('loading.data'));
    this.uiSyncService.loader(true, true, translation);
    this.uiSyncService.IsReadyToDisplayForm = false;
    await this.initCommon(currentVerb);
    this.uiSyncService.IsReadyToDisplayForm = true;
    await this.buildApplication(currentKy, stackId, previouslySaved);
    this.uiSyncService.loadObject();
    if(!this.formStackService.isLoadingEvalActions) {      
      this.uiSyncService.loader(false);
    }
    return;
  }

  public async submit(verb: string, data: LpMeta, ky?: string, stackId?: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.translate.get('general.modalService.updateInProgress').subscribe(async (value: string) => {
        try {
          let submitted: boolean = false;
          let kySubmited: string = ky;
          this.uiSyncService.loader(true, true, this.translate.instant(value));
          if (this.hasToLoadPreviousAPI()) {
            try {
              kySubmited = await this.newService.submit(this.getPreviousAPIPath(), this.getPreviousAPIData());
              submitted = true;
              this.router.navigate(this.formStackService.getLastStack().currentVerb, this.formStackService.getLastStack().currentKy);
            } catch (error) {
              throw (error)
            }
          } else if (ky === null || ky === undefined || ky === this.configService.get('newdataKeyWord')) {
            try {
              kySubmited = await this.newService.submit(verb, data);
              submitted = true;
              this.router.navigate(this.formStackService.CurrentVerb, kySubmited);
            } catch (error) {
              throw (error)
            }
          } else {
            try {
              kySubmited = await this.kyService.submit(verb, kySubmited, data);
              submitted = true;
            } catch (error) {
              throw error;
            }
          }
          this.changeService.formSubmited();
          await this.build(verb, kySubmited, stackId, true);
          this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
            this.translate.instant('general.modalService.success'));
          resolve(submitted);
        } catch (e) {
          console.error(e);
          resolve(false);
          // throw(e);
        } finally {
          this.uiSyncService.loader(false);
        }
      });
    });
  }

  private async initCommon(currentVerb: string): Promise<void> {
    this.clearApplicationItem();
    this.closeRight()
    if (this.screenHasChanged(currentVerb)) {
      this.formStackService.clearFields();
      this.formStackService.CurrentMetadata.clear();
      this.formStackService.CurrentVerb = currentVerb;
      await this.formStackService.getApplicationItem(currentVerb);
    }
    this.currentApplicationItem = this.formStackService.currentApplicationItem
    this.currentApplicationItemDetail = this.formStackService.currentApplicationItemDetail;
  }

  private clearApplicationItem(): void {
    this.currentApplicationItem = null;
    this.currentApplicationItemDetail = null;
    this.formStackService.currentDetailsCount.clear();
  }

  private closeRight(): void {
    // this.uiSyncService.showDetailForm(null);
  }

  private screenHasChanged(verb: string): boolean {
    return ((this.formStackService.currentApplicationItem === undefined ||
      this.formStackService.currentApplicationItem === null) ||
      verb !== this.formStackService.currentApplicationItem.verb || verb !== this.formStackService.CurrentVerb);
  }

  private isMainZoom(ky: string): boolean {
    return ky === undefined || ky === null
  }

  private isKyForm(ky: string, stackIdToLoad?: number): boolean {
    return !this.isMainZoom(ky) && !this.isNewForm(ky) && !this.isHistoForm(stackIdToLoad)
  }

  private isHistoForm(stackIdToLoad?: number): boolean {
    return this.formStackService.stackExist() && !Util.isNullOrUndefined(stackIdToLoad)
      && !Util.isNullOrUndefined(Array.from(this.formStackService.stack)[stackIdToLoad]);
  }

  private isNewForm(ky: string): boolean {
    return ky === this.configService.get('newdataKeyWord')
  }

  private async buildApplication(ky: string, stackIdToLoad?: number, previouslySaved?: boolean): Promise<void> {
    try {
      this.rightColService.closeAll();
      this.formStackService.CurrentKy = ky;
      this.formStackService.mainZoomBody = false;
      if (this.isMainZoom(ky)) {
        await this.mainZoomService.initMainZoom();
      } else if (this.isNewForm(ky) && !this.isHistoForm(stackIdToLoad)) {
        await this.newService.initNewForm();
      } else if (this.isHistoForm(stackIdToLoad)) {
        await this.historyService.initHisto(stackIdToLoad, previouslySaved);
      } else if (this.isKyForm(ky, stackIdToLoad)) {
        await this.kyService.initKyForm(ky);
        if(!previouslySaved) {
          this.userHstoryService.addEvent('show', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb);
        }
      }
    } catch (error) {
      throw error;
    }
  }

  private hasToLoadPreviousAPI(): Boolean {
    return this.formStackService.stack.size > 0 &&
      !Util.isNullOrUndefined(this.formStackService.getLastStack().bindingProp) &&
      this.formStackService.getLastStack().usePreviousAPIConfig;
  }

  private getPreviousAPIPath(): string {
    let temp: String = (this.formStackService.getLastStack().currentApplicationItemDetail.path)
    let temped: String = this.formStackService.buildDetailUrl(temp, this.formStackService.getLastStack().currentData);
    return temped.toString();
  }


  private getPreviousAPIData(): LpMeta {
    let object: string = this.formStackService.getLastStack().bindingProp.toString()
      .split('.')[(this.formStackService.getLastStack().bindingProp.toString().split('.').length - 1)]
    this.formStackService.getLastStack().currentDetailLineData[object] = this.formStackService.currentData;

    if (object && object.includes(TO_CREATE)) {
      return this.formStackService.getLastStack().currentDetailLineData;
    } else {
      return this.formStackService.currentData;
    }
  }

}

