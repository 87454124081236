import { Injectable, EventEmitter } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { LpMeta } from 'app/meta/meta';
import { LpMemo } from 'app/meta/memo';
import { HttpProvider } from './http.provider';
import { ConfigService } from 'app/services/config.service';
import { Util } from 'app/statics/utils';

const TECH_ATTACHMENTS: String = 'technical/attachments/';
const OBJECTS: String = '?object=';
const KEY: String = '&key=';

@Injectable({
  providedIn: 'root'
})

export class MemoProvider {
  /**
   * L'évènement refreshMemoListEvtEmitter est utilisé lorsque l'utilisateur à fait une mise à jour sur la liste des mémos.
   */
  public refreshMemoListEvtEmitter: EventEmitter<any> = new EventEmitter();

  constructor(public alloc: AlocproProvider, public http: HttpProvider, private configService: ConfigService) {}

  public refreshMemoList(memos: LpMemo[]): void {
    this.refreshMemoListEvtEmitter.emit({'memos': memos});
  }

  public putMemo(object: String, key: String, data: LpMemo, noCatch?: Boolean): Promise<any> {
    data = this.initMemoFiles(data);
    this.alloc.checkIdentificationData();
    return new Promise<any>((resolve, reject) => {
    let a: Promise<any>;
    if (noCatch) {
      a = this.http.httpPut(`${this.configService.get('alocproServiceUrl')}${TECH_ATTACHMENTS}${data['id']}${OBJECTS}${object}${KEY}${key}`, data);
    } else {
      a = this.http.httpPut(`${this.configService.get('alocproServiceUrl')}${TECH_ATTACHMENTS}${data['id']}${OBJECTS}${object}${KEY}${key}`, data);
    }
    a.then((result) => {
      resolve(result);
    });
  });
}

  public postMemo(object: String, key: String, data: LpMemo, noCatch?: Boolean): Promise<any> {
    data = this.initMemoFiles(data);
    this.alloc.checkIdentificationData();
    return new Promise<any>((resolve, reject) => {
      let a: Promise<any>;
      if (noCatch) {
        a = this.http.httpPost(`${this.configService.get('alocproServiceUrl')}${TECH_ATTACHMENTS}${OBJECTS}${object}${KEY}${key}`, data);
      } else {
        a = this.http.httpPost(`${this.configService.get('alocproServiceUrl')}${TECH_ATTACHMENTS}${OBJECTS}${object}${KEY}${key}`, data);
      }
      a.then((response) => {
        resolve(resolve(response.headers.get('x-vega-id')));
      });
    })
  }

  public deleteOneMemo(object: String, key: String, idMemo: number, noCatch?: Boolean): Promise<any> {
    this.alloc.checkIdentificationData();
    return new Promise<any>((resolve, reject) => {
      this.http.httpDelete(`${this.configService.get('alocproServiceUrl')}${TECH_ATTACHMENTS}${idMemo}${OBJECTS}${object}${KEY}${key}`).then((result) => {
        resolve(result);
      });
    })
  }

  public getAttachmentType(file: string): String {

    if (!file) {
      return 'nofile';
    }
    if (file.startsWith('data:image')) {
      return 'image';
    }

    if (file.startsWith('data:application/pdf')) {
      return 'pdf';
    }

    if (file.startsWith('data:text/plain')) {
      return 'text';
    }

    return 'other';
  }

  public async getFullMemos(path: String, ky: String): Promise<LpMemo[]> {
    let fullMemos: LpMemo[] = [];
    let memos: LpMeta[] = await this.alloc.find(path, ky) as unknown as LpMemo[];
    if (memos) {
      for (let memo of memos) {
        fullMemos.push(await this.alloc.find(TECH_ATTACHMENTS, memo.id) as LpMemo);
      }
    }
    return fullMemos;
  }

  public async getMemo(path: String, id?: String): Promise<LpMemo> {
    if (Util.isNullOrUndefined(id)){
      let memo: LpMemo = await this.alloc.find(path, null) as LpMemo;
      return this.getMemoFiles(memo);  
    }
    else {
      let memo: LpMemo = await this.alloc.find(path, id) as LpMemo;
      return this.getMemoFiles(memo);
    }
  }
  public createLpMemo(): LpMemo {
    return new LpMemo();
  }

  private getMemoFiles(memo: LpMemo): LpMemo {
    memo._files = [];
    if (memo.file !== undefined && memo.file !== null && memo.file !== '') {
      memo._files.push(memo.file);
      for (let index: number = 0; index < memo.attachedDocuments.length; index++) {
        const file: LpMemo = memo.attachedDocuments[index];
        memo._files.push(file.file);
      }
      memo.file = null;
      memo.attachedDocuments = [];
    }
    return memo;
  }

  private initMemoFiles(data: LpMemo): LpMemo {
    /* Réidratation des fichiers joints dans les bonnes variables pour le LpBackend */
    if (data._files.length > 0) {
      data.file = data._files[0];
      for (let index: number = 1; index < data._files.length; index++) {
        let memoTemp: LpMemo =  this.createLpMemo();
        memoTemp.file = data._files[index];
        data.attachedDocuments.push(memoTemp);
      }
    }
    /* Suppression de la variables pour ne pas surcharger la requête */
    data._files = undefined;
    return data;
  }
}
