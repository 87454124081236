import { Component, Inject } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { FormStackService } from 'app/services/form-stack.service';
import { Util } from 'app/statics/utils';
import { TranslateService } from '@ngx-translate/core';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UserService } from 'app/services/user.service';
import { HttpError } from 'app/models/http-error';

@Component({
  selector: 'lp-modal-promise-error',
  templateUrl: './lp-modal-error.component.html'
})
export class LpModalPromiseErrorComponent extends LpModalComponent {

  public showDetail: boolean = false;
  public title: string;

  constructor(
    public formstackService: FormStackService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<LpModalComponent>,
    public modalService: ModalService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    super(dialogRef, data);
    this.title = this.translateService.instant(this.errorTitleTranslation());
  }

  public errorTitleTranslation(): string {
    if (!this.modalService.arrayHttpError || this.modalService.arrayHttpError.length === 0) {
      return 'general.modalService.interruptedConnetion';
    } else {
      const error: HttpError = this.modalService.arrayHttpError[0];
      if ( Util.isNullOrUndefined(error.translateKey) || error.translateKey === '' ) {
       return 'general.modalService.error';
      } else {
        return error.translateKey.toString();
      }
    }
  }

  public translateByDefault(error: HttpError): String {
    let translateKey: String;
    if ( Util.isNullOrUndefined(error.translateKey) || error.translateKey === '' ) {
      if ( !Util.isNullOrUndefined(error.error) || error.error !== '' ) {
        if ( error.statusCode === 404) {
          translateKey = error.error;
        } else {
          translateKey = 'general.modalService.error';
        }
      } else {
        if ( error.statusCode === 404) {
          translateKey = 'httpError.datasNotFound';
        } else {
          translateKey = 'general.modalService.error';
        }
      }
    } else {
      translateKey = error.translateKey;
    }
    return translateKey;
  }

  public isAdminOrNotLogin(): boolean {
    return (!this.userService.getCurrentUser() || this.userService.getCurrentUser().level  == '0' || this.userService.getCurrentUser().level  == '1')
  }

}
