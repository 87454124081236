import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { LpMeta } from 'app/meta/meta';
import { Pricing, PricingMethod, SpecificPricingMethod } from 'app/meta/pricings/pricings';
import { PricingProvider } from 'app/providers/pricing.povider';
import { Util } from 'app/statics/utils';
import * as _ from 'lodash';
import { isUndefined } from 'lodash';

const PRICING_DETAILS_CUSTOM_PRICINGS: string = 'pricing-details/custom-pricings';
const PRICING_URL: string = 'pricing-details/NATIONAL';
const NATIONAL_PRICING: string = 'pricings-national';
const CUSTOM_PRICING: string = 'pricings-custom';
const NATIONAL_TYPE: string = 'N';
const CUSTOM_TYPE: string = 'P';
const PRICING_CUSTOM_QUERY_PARAM: string = 'isCustomPricingRoad';

//perso c'est les deux 
@Injectable({
    providedIn: 'root'
  })
export class PricingCustomService {

    constructor(
      private pricingPovider: PricingProvider) { }

      public async getDetailsData(type: string, data: LpMeta): Promise<Array<PricingMethod> | Array<SpecificPricingMethod>>{
        let url: string = this.buildUrl(data);
        url = this.addQueryParam(type, url);
        //const pricingDetails: Pricing = await this.pricingPovider.getDetailsData(null, null);
        const pricingDetails: Pricing = await this.pricingPovider.getDetailsRealDatas(url);
        if ( !isNullOrUndefined(type) && type === NATIONAL_PRICING) {
          return pricingDetails.pricingMethods;
        } else if (!isNullOrUndefined(type) && type === CUSTOM_PRICING) {
          return await this.concat(pricingDetails);
        }
      }

      public async deletePricingDetailsNew(line: LpMeta): Promise<void> {
        if (!Util.isNullOrUndefined(line) && !Util.isNullOrUndefined(line.id) && !Util.isNullOrUndefined(line['internalId'])) {
          await this.pricingPovider.removeLine(PRICING_DETAILS_CUSTOM_PRICINGS, `${line['linkedId']}?internalId=${line['internalId']}`);
        }
      }

      private addQueryParam(type: string, url: string): string {
        if (!isNullOrUndefined(type) && type === CUSTOM_PRICING) { 
          url = `${url}${PRICING_CUSTOM_QUERY_PARAM}=true`;
        } 
        return url;
      }

      private async concat(data: any): Promise<Array<SpecificPricingMethod>>{
        let finalArray: Array<any> = new Array<any>();
        finalArray = await this.pushInTabWithLinkedProperty(data.pricingMethods, data.specificPricingMethods, 'linkedId', 'linkedId');
        finalArray = await this.sort(finalArray, 'type', 'P', ['startDate', 'endDate']);
        return finalArray;
      }

      private async sort(data: any, property: string, valueOfProperty: string, groupOfSortingProperty: Array<string>): Promise<Array<SpecificPricingMethod>>{
        let sortedArray: Array<any> = await this.generalLoop(data, property, valueOfProperty, groupOfSortingProperty);
        return sortedArray;
      }

      private async generalLoop(data: any, property: string, valueOfProperty: string, groupOfSortingProperty): Promise<Array<SpecificPricingMethod>> {
        let sortedArray: Array<any> = new Array<any>();
        for (let generalCounter: number = 0; generalCounter < data.length; generalCounter = generalCounter + 1) {
          // on ajoute les P
          if ( data[generalCounter][property] === valueOfProperty ) {
            //on parcour pour voir si il y a des P
            const propertySortedTab: Array<SpecificPricingMethod> = await this.loopForAdd(data, generalCounter, property, valueOfProperty, groupOfSortingProperty)
            sortedArray = sortedArray.concat(propertySortedTab);
            generalCounter = generalCounter + propertySortedTab.length - 1;
          } else {
            // On ajoute les N
            sortedArray.push(data[generalCounter]);
          } 
        }
        return sortedArray;
      }

      private async loopForAdd(data: any, startIndex: number, property: string, valueOfProperty: string, groupOfSortingProperty: Array<string>): Promise<any> {
        let arrayToSorted: Array<any> = new Array<any>();
        let sortedArray: Array<any> = new Array<any>();
        for (let arrayToSortedCounter: number = startIndex; arrayToSortedCounter < data.length; arrayToSortedCounter = arrayToSortedCounter + 1)  {

          //si c'est un P, je lajoute dans un nv tableau a trier
          if ( data[arrayToSortedCounter][property] === valueOfProperty) {
            arrayToSorted.push(data[arrayToSortedCounter]);
          } else {
            // si je tombe sur un N, j'arrète la boucle
            arrayToSortedCounter = data.length;
          }
        }
        sortedArray = await this.sortAnArrayWithConditions(arrayToSorted, groupOfSortingProperty);
        return sortedArray;
      }

      private async sortAnArrayWithConditions(data:  Array<SpecificPricingMethod>, conditions: Array<string>): Promise<Array<SpecificPricingMethod>> {
        let tempArray: Array<any> = new Array<any>();
        let finalArray: Array<any> = new Array<any>();
        for (let index: number = 0; index < data.length; index = index + 1)  {
          data[index]['idTemp'] = index;
        }
        for (let index: number = 0; index < data.length; index = index + 1)  {
          tempArray.push({id: data[index]['idTemp'], startDate: new Date(data[index].pricingTime.startDate) , endDate: new Date(data[index].pricingTime.endDate)});
        }
        let sortedArray: Array<any> = _.sortBy(tempArray, conditions);

        for (let index: number = 0; index < sortedArray.length; index = index + 1)  {
          finalArray.push(data[sortedArray[index]['id']]);
        }
        for (let index: number = 0; index < finalArray.length; index = index + 1)  {
          delete finalArray[index]['idTemp'];
        }

        return finalArray;
      }

      private async addProperty(data: LpMeta, property: string, value: string): Promise<any>{
        data[property] = value;
        return data;
      }

      private async pushInTabWithLinkedProperty(array: Array<any>, datas: Array<any>, propertyInArray?: string, propertyInDatas?: string): Promise<Array<any>> {
        let concatedArray: Array<any> = new Array<any>();
        if ( !isNullOrUndefined(array) ) {
            if (array.length > 0) {
                if (!isNullOrUndefined(propertyInArray) && !isNullOrUndefined(propertyInDatas)) {
                    for (let i: number = 0; i < array.length; i = i + 1) {
                      array[i] = await this.addProperty(array[i], 'type', NATIONAL_TYPE);
                      concatedArray.push(array[i]);
                      for (let j: number = 0; j < datas.length; j = j + 1) {
                        if (array[i][propertyInArray] === datas[j][propertyInDatas]) {
                          datas[j] = await this.addProperty(datas[j], 'type', CUSTOM_TYPE);
                          concatedArray.push(datas[j]);
                        }
                      }
                    }
                } else {
                    for (let i: number = 0; i < array.length; i = i + 1) {  
                      array[i] = await this.addProperty(array[i], 'type', NATIONAL_TYPE);
                      concatedArray.push(array[i]);
                    }
                    if ( isNullOrUndefined(datas)) {
                      concatedArray.push(new Array<any>())
                  }
                }
            }
        }
        return concatedArray;
      }

      // TODO
      // prend l'objet data en params (champs du formulaire de geuche formStackService.currentData) et sort une url ex : /pricing?pdv=00032&univer=MOBILITE&category=A&etc....
      private buildUrl(data: LpMeta) : string {
        let url: string = '';
        let baseUrl: string = `${PRICING_URL}?`;
        for (let key of Object.keys(data)) {
          if (key.indexOf('_') === -1) {
            if ((data[key] instanceof LpMeta && !Util.isNullOrUndefined(data[key]['id'])) || (!Util.isNullOrUndefined(data[key])
                                                                                                    && !Util.isNullOrUndefined(data[key]['id']))) {
              url += `${ key }=${ data[key]['id'] }&`;
            } else if (!Util.isNullOrUndefined(data[key]) && isUndefined(data[key]['id'])) {
              url += `${ key }=${ data[key] }&`;
            }
          }
        }
        return baseUrl + url;
      }
      //////////\\\\\\\\\\\

}
