import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../services/modal.service';
import { EdiProvider } from 'app/providers/edi.provider';
import { Util } from 'app/statics/utils';
import { PaginatedData } from 'app/models/paginated-data';
import { LpModalComponent } from '../../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormStackService } from 'app/services/form-stack.service';
import { LpEdi } from 'app/meta/edi';
import { ApplicationItemDetail, ApplicationItemDetailColumn } from 'app/models/application-item-detail';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { UserService } from 'app/services/user.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { JsdataService } from 'app/services/jsdata.service';
import { LpModalEdiEditComponent } from '../lp-modal-edi-edit/lp-edi-edit.component';

/*const DUSTBIN: string = 'icon vega-dustbin';
const NEW: string = 'NEW';*/


const DUSTBIN: string = 'icon vega-dustbin';
const NEW: string = 'NEW';
const EDIS = "edis";
const BULK_ACTION_DELETE: string = 'delete';

@Component({
  selector: 'lp-modal-edi-list',
  templateUrl: './lp-edi-list.component.html'
})
export class LpModalEdiListComponent extends LpModalComponent implements OnInit, OnDestroy {

  public loadingIndicator: boolean = false;
  public ky: string;
  public verb: string;
  public currentPage: Number;
  public columns: Object[] = this.getDisplayedColumns();
  public edis: LpEdi[] = [];
  public currentEdi: LpEdi = new LpEdi();
  public actualPageNumber: number = 1;
  public nbEdi: number = 0;
  public ediStruct: ApplicationItem = new ApplicationItem();
  public showConfigPanel: Boolean = false;
  protected currentIdForDelete: String = '';
  private isMe: boolean;
  private nbEdiPerPage: number = 10;
  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public modalService: ModalService,
    public formStackService: FormStackService,
    private ediProvider: EdiProvider,
    private applicationItemProvider: ApplicationItemProvider,
    private userService: UserService,
    private uiSyncService: UiSyncService,
    private alocproProvider: AlocproProvider,
    public jsdataService: JsdataService

  ) {
    super(dialogRef, data);
  }

  public ngOnDestroy(): void {
    if (this.modalService && !Util.isNullOrUndefined(this.modalService.isMe) && this.modalService.isMe === true) {
      this.modalService.isMe = false;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.isMe = this.modalService.isMe;
    this.ediStruct = await this.applicationItemProvider.getObjectDetails(EDIS);
    this.edis = await this.refreshEdisList();
  }

  public changeConfigPanel(): void {
    this.showConfigPanel = !this.showConfigPanel;
  }

  public async changePage(pageInfo: any): Promise<void> {
    this.actualPageNumber = pageInfo.currentPage;
    this.nbEdiPerPage = pageInfo.linesPerPage;
    this.edis = await this.refreshEdisList();
  }

  public async refreshEdisList(): Promise<LpEdi[]> {
    let edisTmp: LpEdi[] = [];
    this.loadingIndicator = true;
    if (!Util.isNullOrUndefined(this.ediProvider.ediSelectedForDelete) &&
      this.actualPageNumber > 1 && this.edis && this.edis.length === 1) {
      this.actualPageNumber = this.actualPageNumber - 1;
    }
    const params: Array<string> = this.setParams();
    this.edis = [];
    let result: PaginatedData = await this.ediProvider.getEdis(params[0], params[1],
      this.actualPageNumber, this.nbEdiPerPage, this.isMe);
    if (!Util.isNullOrUndefined(result)) {
      this.nbEdi = 0;
      let edisTemp: LpEdi[] = [];
      let nbEdiTemp: number = 0;
      if (!Util.isNullOrUndefined(result.count)) {
        nbEdiTemp = result.count;
      }
      if (!Util.isNullOrUndefined(result.body) && result.body.length !== 0) {
        result.body.forEach(async (element) => {
          edisTemp.push(element as LpEdi);
        });
        edisTmp = edisTemp;
        this.nbEdi = nbEdiTemp;
      }
    }
    this.loadingIndicator = false; 
    return edisTmp;
  }

  private setParams(): Array<string> {
    let params: Array<string> = [null, null];
    if (this.formStackService &&
      this.formStackService.currentApplicationItem &&
      this.formStackService.currentApplicationItem.linkedObject !== null &&
      this.formStackService.currentApplicationItem.linkedObject !== undefined) {
      params[0] = String(this.formStackService.currentApplicationItem.linkedObject);
    }
    if (this.formStackService &&
      this.formStackService.currentData &&
      this.formStackService.currentData.id !== null &&
      this.formStackService.currentData.id !== undefined) {
      params[1] = String(this.formStackService.currentData.id);
    }
    return params;
  }

  public getDisplayedColumns(): Array<ApplicationItemDetailColumn> {
    let activeColums: ApplicationItemDetailColumn[] = [];
    for (let index: number = 0; index < this.ediStruct?.details[0]?.columns.length; index++) {
      if (this.ediStruct?.details[0]?.columns[index].isDisplayed) {
        activeColums.push(this.ediStruct?.details[0]?.columns[index]);
      }
    }
    return activeColums;
  }

  public rowClass = (row: any) => {
    if (!Util.isNullOrUndefined(row)) {
      return {
        'footer-table': row.id === 'footer_table',
        'barre': row.action === BULK_ACTION_DELETE
      }
    }
  }

  public onSort(event: any): void {
    this.loadingIndicator = true;
    this.currentPage = 1;
    this.alocproProvider.getPaginatedData(
      this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.edis),
      this.currentPage,
      ApplicationItemDetail.buildQueryParams('GET', this.formStackService.currentApplicationItemDetail),
      false,
      Number(this.userService.getCurrentUser().preference.linesPerPage),
      event.sorts[0].dir.toUpperCase(),
      event.sorts[0].prop)
      .then((records: PaginatedData) => {
        this.formStackService.CurrentApplicationItemDetailData = records.body;
        this.formStackService.CurrentApplicationItemDetailDataReadonly = records._readonly;
        this.loadingIndicator = false;
      });
  }

  private async setWorkFlowSelectedOrNot(value: any): Promise<void> {
    if (value && value.row && value.row.id && (value.row.id !== null || value.row.id !== undefined) ) {
      this.ediProvider.ediSelected = value.row.id;
    }
  }

  public async actionOnEdi(value: any): Promise<void> {
    if (
      (value.type === 'click' && value.event &&
      value.event.srcElement && value.event.srcElement.className !== DUSTBIN)
      || (value === NEW)) {
      this.modalEditEdi();
      await this.setWorkFlowSelectedOrNot(value);
    } else if (value.type === 'click' && value.event &&
    value.event.srcElement && value.event.srcElement.className === DUSTBIN) {
      await this.modalDeleteEdi(value.row.id);
    }
  }

  public async modalDeleteEdi(workflowID: String): Promise<void> {
    /*this.ediProvider.ediSelectedForDelete = workflowID;
    await this.modalService.modalPromise(LpModalWorkflowConfirmComponent, {
      backdropClass: 'alertBackdropClass'
    });
    await this.refreshWorkflowsList();
    this.ediProvider.ediSelectedForDelete = null;*/
  }

  public async modalEditEdi(): Promise<void> {
    await this.modalService.modalPromise(LpModalEdiEditComponent,  {
      maxHeight : '90vh',
      backdropClass: 'commonToolsBackdropClass'
    });
    this.edis = await this.refreshEdisList();
    this.ediProvider.ediSelectedForDelete = null;
  }

}
