import {
  ViewContainerRef, ChangeDetectorRef, ViewChild,
  ElementRef, Renderer2, Component
 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlocproProvider } from '../../providers/alocpro.provider';
import { LpMeta } from '../../meta/meta';
import { UiSyncService } from '../../services/ui-sync.service';
import { ZoomService } from '../../services/zoom.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { RepositoryProvider } from '../../providers/repository.provider';
import { FormStackService } from '../../services/form-stack.service';
import { ChangeService } from '../../services/change.service';
import { ApplicationItem } from '../../models/application-item';
import { Util } from 'app/statics/utils';
import { StackItem } from 'app/models/stackitem';
import { MemoProvider } from 'app/providers/memo.provider';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpError } from 'app/models/http-error';
import { EvalService } from 'app/services/eval.service';
import { Preference } from 'app/models/user.interface';
import { RouterService, ROOT } from 'app/services/router.service';
import { LoggerService } from 'app/services/logger.service';
import { FormService } from 'app/services/root/form.service';
import * as _ from 'underscore'
import { IhmStackService } from 'app/services/ihm-stack.service';
import { LpModalChangeComponent } from 'app/ui/lp-modal/lp-modal-change/lp-modal-change.component';
import { RightColService } from 'app/services/root/rightCol.service';
import { BulkService } from 'app/services/bulk.service';
import { ConfigService } from 'app/services/config.service';
import { PricingService } from 'app/services/pricing.service';
import { PricingCustomService } from 'app/services/pricing-custom.service';

@Component({
  template: ''
})
export class RootformComponent {
  public ky: string = null;
  public applicationItem: ApplicationItem;
  public data: LpMeta;
  public verb: string;
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public debugg: Boolean;
  public showZoomPrint: Boolean = false;
  public dragLeft: number = null;
  @ViewChild('containerCols') public containerCols: ElementRef;
  @ViewChild('col1') public col1: ElementRef;
  @ViewChild('col2') public col2: ElementRef;
  protected dragRight: number = null;

  constructor(
    protected log: LoggerService,
    protected route: ActivatedRoute,
    protected routerService: RouterService,
    public alocproProvider: AlocproProvider,
    public zoomService: ZoomService,
    protected repositoryProvider: RepositoryProvider,
    public uiSyncService: UiSyncService,
    public vcr: ViewContainerRef,
    protected changeDetectorRef: ChangeDetectorRef,
    protected translate: TranslateService,
    protected renderer: Renderer2,
    protected modalService: ModalService,
    public formStackService: FormStackService,
    protected changeService: ChangeService,
    public formService: FormService,
    public memoProvider: MemoProvider,
    public userService: UserService,
    public evalService: EvalService,
    public ihmStackService: IhmStackService,
    public rightColService: RightColService,
    public bulkService: BulkService,
    protected configService: ConfigService,
    protected pricingService: PricingService,
    protected pricingCustomService: PricingCustomService
    ) { }

  public async build(verb: string, ky: string): Promise<void> {
    this.formStackService.initLpMeta(verb);
    if (ky === this.configService.get('newdataKeyWord')) {
      this.formStackService.isCurrentDataPreloaded = false;
    }
    this.setRootVariables(verb);
    this.initCommon(verb);
    if (this.formStackService.IsPlanningPrevious) {
      this.formStackService.IsPlanningPrevious = false;
    } else {
      await this.formService.build(verb, ky, null, false);
    }
  }

  /**La fonction Submit(), permet d'ajouter des données dans notre Back - end.
  * Elle utilise la fonction post() présente dans le service AlocProProvider.
  * Fonction utile pour le bouton de soumission du formulaire.
  *   */
  public async submit(event: Event): Promise<void> {
    try {
      if (this.changeService.isDetailsChanged2()) {
        this.modalService.modalPromise(LpModalChangeComponent, {
          backdropClass: 'alertBackdropClass',
          id : 'modalWithHeader'
        }).then((result: boolean) => {
          if (result) {
            this.changeService.clearDetailsChanged2();
            return this.save(event);
          }
        });
      } else {
        return this.save(event);
      }
    } catch (error) {
      throw error;
    }
  }

  /**La fonction Home(), permet d'être redirigé vers la page principale.
  */
  public home(): void {
    this.ky = undefined;
    this.routerService.navigate(ROOT);
  }

  /**La fonction backClicked(), elle permet de revenir en arrière.
  * Elle est utilisée pour le bouton annuler dans le sticky footer notamment.
  */
  public backClicked(): Boolean {
    this.routerService.navigate('');
    return false;
  }

  public AfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
  * La fonction closeRightColBlock() ferme tous le bloc de droite correspond à l'ID passé en paramètre.
  */
  public closeRightColBlock(key: String): void {
    // this.applicationItem.oldDetails.get(key).active = false;
  }

  /**
  * Fonction utilisé lors du drag&drop pour décaler les colonnes
  * @param event
  */
  public dragover(event: any): Boolean {
    event.preventDefault();
    return false;
  }

  /**
  * Fonction utilisé lors du drag&drop pour décaler les colonnes
  * @param event
  */
  public drop(event: any): Boolean {
    if (this.uiSyncService.lastDragEvent.target['id'] === 'dragBlocResizeCol') {
      // largeur du navigateur
      const clientWidth: number = this.containerCols.nativeElement.clientWidth;
      // Largeurs min et max de la première colonne
      const maxWidth: number = clientWidth * 75 / 100 + 13;
      const minWidth: number = clientWidth * 25 / 100;
      // Récupération de la position du curseur.
      // 50 correspond à la largeur de la barre de menu à gauche;
      this.dragLeft = event.pageX - 50;
      // Récupération de la largeur à assigner à la première colonne
      // 20 correspond à la largeur de la scrollbar
      let width: number = this.dragLeft;
      // Si la taille de la colonne de gauche et supérieur à la taille max, on bloque à la taille max.
      if (this.dragLeft > maxWidth) {
        this.dragLeft = maxWidth;
        width = maxWidth - 20;
      }
      // Si la taille de la colonne de gauche et inférieur à la taille min, on bloque à la taille min.
      if (this.dragLeft < minWidth) {
        this.dragLeft = minWidth + 20;
        width = minWidth;
      }
      // On assigne la taille à la colonne de gauche.
      this.col1.nativeElement.style.width = width + 'px';
      this.col1.nativeElement.style.maxWidth = '75%';
      // On récupère la taille de la colonne de droite en fonction la largeur de la fenêtre et de la largeur de la colonne de gauche.
      const widthCol2: number = clientWidth - width;
      this.dragRight = widthCol2 + 17;
      // On assigne la taille à la colonne de droite.
      this.col2.nativeElement.style.width = widthCol2 + 'px';
      this.col2.nativeElement.style.maxWidth = '75%';
      // Envoi d'un emi pour indiquer aux autres composants que la taille des colonnes à changé.
      this.uiSyncService.rootformColChanged({'left': width, 'right': widthCol2 });
    }
    event.preventDefault();
    return false;
  }

  /**
  * la fonction reInitCols() permet de reinitialiser la taille des colonnes.
  * Appelée lors du double click sur la zone de drag & drop
  */
  public reInitCols(): void {
    this.renderer.removeAttribute(this.col1.nativeElement, 'style');
    this.renderer.removeAttribute(this.col2.nativeElement, 'style');
    this.dragLeft = null;
    this.dragRight = null;
    this.uiSyncService.rootformColChanged(null);
  }

  public changeShowZoomPrint(event: any): void {
    this.showZoomPrint = event;
  }

  public getContentWidth(): String {
    return 'calc(100% - ' + this.formStackService.getAllStack().size.toString() + ' * 35px - 1px)';
  }

  public shouldDisplayItem(): boolean {
    return !Util.isNullOrUndefined(this.formStackService.currentApplicationItem) && this.uiSyncService.isApplicationItemDetails
  }

  protected initCommon(verb: string): void {
    this.setCssVariable();
    this.repositoryProvider.getPreferences().then((preferences: Preference) => {
        this.debugg = preferences.debug;
    });
    if (!this.formStackService.getStack('planning')) {
      this.formStackService.isStackLink = false;
    }
    this.initDashboard();
    this.initVisualSettings();
    this.uiSyncService.noTtestFormChange = false;
    this.uiSyncService.showTransition = false;
  }

  private initDashboard(): void {
    this.formStackService.dashboard = this.userService.getDashboard();
  }

  private initVisualSettings(): void {
    this.formStackService.VisualSettings = this.userService.getVisualSettings();
  }

  protected navigate(id?: String): void {
    if (id) {
      this.routerService.navigate(this.verb, id);
    } else {
      this.routerService.navigate(this.verb);
    }
  }

  protected handleError(e: any): void {
    if (e instanceof HttpErrorResponse) {
      let error: HttpError = new HttpError(e, this.configService);
      this.modalService.error(this.translate.instant(error.translateKey.toString(), error.getErrorTitle()));
    }
    this.log.error(e);
  }

  /**
  * La fonction closeAllRightColBlock() ferme tous les bloc de droite (applicationItem).
  */
    protected closeAllRightColBlock(ignoreShowDetailForm?: Boolean): void {
    if (!Util.isNullOrUndefined(this.applicationItem) && !Util.isNullOrUndefined(this.applicationItem.oldDetails)) {
      if (!ignoreShowDetailForm) {
        this.uiSyncService.showDetailForm(null);
      }
    }
  }

  private async save(event: Event): Promise<void> {
    let stackIdToLoad: number = null;
    if (this.formStackService.stackExist() && this.isStackLink(event)) {
      stackIdToLoad = this.formStackService.stack.size - 1;
      this.uiSyncService.lastBreadcrumbItemClickIndex = stackIdToLoad;
    }
    let b: boolean = await this.formService.submit(this.formStackService.CurrentVerb, this.formStackService.currentData,
      this.route.snapshot.params['ky'], stackIdToLoad);
    if (b && stackIdToLoad !== null) {
      this.uiSyncService.currentBreadCrumb = this.getNewBreadcrumbItem(this.formStackService.stack, stackIdToLoad);
    }
    Promise.resolve();
  }

  private setCssVariable(): void {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
  }

  private setRootVariables(verb: string, mainZoomId: string = verb): void {
    this.data = this.formStackService.currentData;
    this.verb = verb;
    this.uiSyncService.parentVerb = verb;
  }

  private getNewBreadcrumbItem(StackItemkey: Map<String, StackItem>, stackId: number): [String, StackItem][] {
    let m:  Map<String, StackItem> = new Map();
    _.each(Array.from(StackItemkey), (item: [string, StackItem], index: number) => {
      if (index < stackId) {
        m.set(item[0], item[1]);
      }
    });
    return Array.from(m);
  }

  /**
   * Fonction utilisée lors du submit d'un formulaire.
   * Permet de savoir si on charge une stack après le submit ou si l'on reste sur l'écran.
  */
  private isStackLink(event: Event): Boolean {
    return (!Util.isNullOrUndefined(event.srcElement['elements']['btn-save-stack']) &&
           event.srcElement['elements']['btn-save-stack']['value'] === '1') ||
           (!Util.isNullOrUndefined(event.srcElement['elements']['btn-save']) && event.srcElement['elements']['btn-save']['value'] !== 'true');
  }
}
