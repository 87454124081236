import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { Subscription } from 'rxjs';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-ui-popover-info',
  templateUrl: './lp-ui-popover-info.component.html',
})
export class LpUiPopoverInfoComponent implements OnInit, OnDestroy {

  /* Contenu de l'infobulle
  */
  @Input() public contentTooltip: String;
  @Input() public hoverHide: Boolean = true;
  @Input() public placement: String = 'bottom';
  @Input() public ecrId: String;
  @Input() public field: String;

  /**
   * Permet de passer un tableau en paramètre qui sera parser dans l'infobulle.
   */
  @Input() public tableTooltip: Object[] = null;


  private isOpen: Boolean = false;
  private subscription: Subscription;

  constructor(private uiSyncService: UiSyncService) {}

  public ngOnInit(): void {
    this.subscription = this.uiSyncService.lockPopoverEvtEmitter.subscribe(() => {
      this.lockPopover();
    });
  }

  public ngOnDestroy(): void {
      if (!Util.isNullOrUndefined(this.subscription)) { this.subscription.unsubscribe(); }
  }

  public lockPopover(): void {
    if (this.isOpen) {
      this.hoverHide = !this.hoverHide;
    }
  }

  public mouseleave(pop: any): void {
    if (this.hoverHide) {
      pop.hide();
    }
  }

}
