import { LpMeta, propertyMeta } from './meta';
import { LpPaymentMethod } from './payment-method';
import { Type } from 'class-transformer';
import { LpThirdParty } from './third-party';
import { LpCompany } from './company';
import { LpLocation } from './location';

export class LpDeposit extends LpMeta {
    @propertyMeta('string')
    public amount: Number;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('string')
    public groupingCode: String;
    @Type(() => LpPaymentMethod)
    public paymentMethod: LpPaymentMethod = new LpPaymentMethod();
    @propertyMeta('string')
    public dealNumber: String;
    @propertyMeta('string')
    public agreementDeadLine: String;
    @propertyMeta('string')
    public documentNumber: String;
    @propertyMeta('date')
    public cardValidityDate: Date;
    @propertyMeta('date')
    public cancellationDate: Date;
    @Type(() => LpThirdParty)
    public thirdParty: LpThirdParty = new LpThirdParty();
    @Type(() => LpMeta)
    public status: LpMeta = new LpMeta();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @propertyMeta('string')
    public cryptogram: String;
    @Type(() => LpMeta)
    public kind: LpMeta = new LpMeta();
}
