import { LpMeta, propertyMeta} from './meta';
import { FtMeta } from './ft-meta';
import { LpLocation } from './location';
import { LpPricing } from './pricing';
import { Type } from 'class-transformer';
import { LpPricingMethod } from './pricing-method';


export class LpQuickNote extends LpMeta {
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('date')
    public returnDate: Date;
    @propertyMeta('string')
    public wantedMileage: Number;
    @propertyMeta('Number')
    public includingTaxAmount: Number;
    @Type(() => LpLocation)
    public startLocation: LpLocation = new LpLocation();
    @Type(() => FtMeta)
    public category: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public type: FtMeta = new FtMeta();
    @Type(() => LpPricing)
    public pricing: LpPricing = new LpPricing();
    @Type(() => LpPricingMethod)
    public pricingMethod: LpPricingMethod = new LpPricingMethod();

}
