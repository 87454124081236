
<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="additionalDrivers.title" [open]=true>
    <form id="formAccountingEvents" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>
      
      <!--<div class="form-group col-xl-12">
        <lp-label-field for="index" class="{{arrayClassesElements.get('defaultLabel')}}" text="additionalDrivers.index" [required]="true"></lp-label-field>
        <lp-number id="index" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="index"
          [(ngModel)]="data.index" [required]="true"></lp-number>
      </div>-->

      <div class="form-group col-xl-12">
        <lp-label-field for="additionalDrivers.relatedDriver" class="{{arrayClassesElements.get('defaultLabel')}}" text="additionalDrivers.relatedDriver"></lp-label-field>
        <lp-zoom-field id="additionalDrivers.relatedDriver" name="additionalDrivers.relatedDriver" zoomId="additionalDrivers.relatedDriver" [(ngModel)]="data.driverToCreate"
        class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
        </lp-zoom-field>
      </div> 

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger formulaire" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>
