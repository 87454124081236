import { Component, OnInit } from '@angular/core';
import { LpFine } from '../../meta/fine';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-fines',
  templateUrl: './fines.component.html',
})
export class FinesComponent extends RootformComponent implements OnInit {

  public data: LpFine = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('fines', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
