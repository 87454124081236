<ul class="p-0">
  <li *ngFor="let link of links; let i = index" class="row align-item-bottom border-bottom">
    <div class="form-group col-5">
      <lp-label-field for="link.url" text="{{'preferences.linkUrl' | translate}}"></lp-label-field>
      <lp-text-field id="link.url" cssClass="form-control" name="link.url"
       [(ngModel)]="link.url" placeholder="link.url"></lp-text-field>
    </div>
    <div class="form-group col-5">
      <lp-label-field for="link.icon" text="{{'preferences.linkIcon' | translate}}"></lp-label-field>
      <lp-text-field id="link.icon" cssClass="form-control" name="link.icon"
       [(ngModel)]="link.icon" placeholder="link.icon"></lp-text-field>
    </div>
    <div class="form-group col-5">
      <lp-label-field for="link.title" text="{{'preferences.linkTitle' | translate}}"></lp-label-field>
      <lp-text-field id="link.title" cssClass="form-control" name="link.title"
       [(ngModel)]="link.title" placeholder="link.title"></lp-text-field>
    </div>
    <div class="col-2 d-flex align-items-end">
      <a class="btn btn-primary me-2" (click)="saveLink(link)">
        <i class="icon vega-diskette" title="{{'general.delete' | translate}}"></i>
      </a>
      <a class="btn btn-danger" (click)="deleteLink(link, i)">
        <i class="icon vega-dustbin" title="{{'general.delete' | translate}}"></i>
      </a>
    </div>     
  </li>
</ul>
<p class="mt-1 mb-4">
  <a class="btn btn-outline-primary" (click)="addLink()">
    <i class="fa fa-plus me-2"></i> {{'preferences.addLinks' | translate}}
  </a>
</p> 
