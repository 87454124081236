import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { LpMovement } from './movement';
import { Type } from 'class-transformer';

export class LpHistoricalMileage extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('number')
    public mileage: Number;
    @propertyMeta('number')
    public mileage2: Number;
    @propertyMeta('date')
    public mileageDate: Date;
    @propertyMeta('date')
    public mileage2Date: Date;
    @Type(() => FtMeta)
    public moduleOriginLink: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public uniqueCode: FtMeta = new FtMeta();
    @propertyMeta('string')
    public moduleOriginId: String;
    @propertyMeta('date')
    public historicDate: Date;
    @propertyMeta('boolean')
    public isSet: Boolean = true;
    @propertyMeta('string')
    public order: String;
    @propertyMeta('number')
    public counterStart: Number = 0;
    @propertyMeta('number')
    public counterEnd: Number = 0;
    @propertyMeta('boolean')
    public isMain: Boolean = true;
    @Type(() => FtMeta)
    public equipmentAttached: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public kilometerKind: FtMeta = new FtMeta();
    @Type(() => LpMovement)
    public movement: LpMovement = new LpMovement();
    @propertyMeta('number')
    public latitude: Number = 0;
    @propertyMeta('number')
    public longitude: Number = 0;
    @propertyMeta('date')
    public positionDate: Date;
    @Type(() => FtMeta)
    public various: FtMeta = new FtMeta();
}
