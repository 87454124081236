<div class="app-search menu-search row justify-content-md-center m-0"  *ngIf="!showSavePanel">

  <div class="col-5">
    <h2>{{ 'preferences.complete' | translate}}</h2>

    <div
    cdkDropList
    #doneList="cdkDropList"
    class="list-preference-menu"
    [cdkDropListData]="items"
    [cdkDropListConnectedTo]="[todoList]"
    (cdkDropListDropped)="drop($event)">
    <div *ngFor="let menu of items" cdkDrag class="m-1 border">
      <div class="row cdk-drop-placeholder" *cdkDragPlaceholder>
        <i *ngIf='!menu || menu === undefined || menu === null || !menu.icon' class="icon vega-warning"></i>
        <i *ngIf="menu && menu !== undefined && menu !== null && menu.icon" class="icon {{ menu.icon }}"></i>
        <lp-label-field text="{{ menu.label | translate}}"></lp-label-field>
      </div>
      <div class="content">
        <i *ngIf='!menu || menu === undefined || menu === null || !menu.icon' class="icon vega-warning"></i>
        <i *ngIf="menu && menu !== undefined && menu !== null && menu.icon" class="icon {{ menu.icon }}"></i>
        <span *ngIf="!menu.ky">{{ menu.label | translate}}</span>
        <span *ngIf="menu.ky">{{ menu.label+'.'+menu.ky | translate}}</span>
      </div>      
    </div>
    </div>
  </div> 
  <div class="col-1"></div>

    <div class="col-5">
        <h2>{{ 'preferences.outbar' | translate}}</h2>
    
        <div
        cdkDropList
        #todoList="cdkDropList"
        class="list-preference-menu"
        [cdkDropListData]="tempArray"
        [cdkDropListConnectedTo]="[doneList]"
        (cdkDropListDropped)="drop($event)">
          <div *ngFor="let menu of tempArray" cdkDrag class="m-1 border">
            <div class="row cdk-drop-placeholder" *cdkDragPlaceholder>
              <i *ngIf='!menu || menu === undefined || menu === null || !menu.icon' class="icon vega-warning"></i>
              <i *ngIf="menu && menu !== undefined && menu !== null && menu.icon" class="icon {{ menu.icon }}"></i>
              <lp-label-field text="{{ menu.label | translate}}"></lp-label-field>
            </div>
            <div class="content">
              <i *ngIf='!menu || menu === undefined || menu === null || !menu.icon' class="icon vega-warning"></i>
              <i *ngIf="menu && menu !== undefined && menu !== null && menu.icon" class="icon {{ menu.icon }}"></i>
              <span *ngIf="!menu.ky">{{ menu.label | translate}}</span>
              <span *ngIf="menu.ky">{{ menu.label+'.'+menu.ky | translate}}</span>
            </div>      
          </div>
        </div>
    </div>
  
    <div class="col-12">      
      <button class="transparent-btn pull-right mb-4" (click)="toggleSavePanel()">
        <i class="vega-diskette"></i>
      </button>
      <button (click)="resetOutbar()" class="pull-right transparent-btn mb-4 me-2" title="{{'preferences.resetOutbar' | translate}}">
        <i class="vega-reset"></i>
      </button>
    </div>
</div>

<div class="row justify-content-md-center m-0" *ngIf="showSavePanel">
  <lp-save-preferences class="col-4" (save)="saveConfig($event)"></lp-save-preferences>
</div>