import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../services/modal.service';
import { LpWorkflow } from 'app/meta/workflow';
import { LpModalComponent } from '../../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkflowProvider } from 'app/providers/workflow.provider';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ChangeService } from 'app/services/change.service';
import { LpModalWorkflowConfirmComponent } from '../lp-modal-workflow-confirm/lp-workflow-confirm.component';
import { RouterService } from 'app/services/router.service';
import { FormStackService } from 'app/services/form-stack.service';
import { FormService } from 'app/services/root/form.service';
import { LpModalWorkflowExitComponent } from '../lp-modal-workflow-exit/lp-workflow-exit.component';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { AFTER_GET_EVENT, BEFORE_GET_EVENT, BEFORE_INSERT_EVENT, BEFORE_UPDATE_EVENT, EvalService, INIT_NEW_EVENT, MAIN } from 'app/services/eval.service';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';

const WORKFLOWS_PREFIX: String = 'workflows';
const NEW: string = 'NEW';
@Component({
  selector: 'lp-modal-workflow-edit',
  templateUrl: './lp-workflow-edit.component.html'
})
export class LpModalWorkflowEditComponent extends LpModalComponent implements OnInit {

  public relatedObject: String;
  public relatedObjectId: String;
  public ky: string;
  public verb: string;
  public listeMessages: any = {
    emptyMessage: '',
    totalMessage: ''
  };
  public workflows: LpWorkflow[] = [];
  public currentWorkflow: LpWorkflow = new LpWorkflow();
  public showCancelAction: Boolean = false;
  public workflowPerPage: number = this.configService.get('workflowsLimitInList');
  public actualPageNumber: number = 1;
  public nbWorkflow: number = 0;
  protected currentIdForDelete: String = '';

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public modalService: ModalService,
    public workflowProvider: WorkflowProvider,
    private uiSyncService: UiSyncService,
    private changeService: ChangeService,
    private routerService: RouterService,
    public formStackService: FormStackService,
    private formService: FormService,
    public applicationItemProvider: ApplicationItemProvider,
    private evalService: EvalService,
    public configService: ConfigService,
    private userHstoryService: UserHistoryService
  ) {
    super(dialogRef, data);
    this.applicationItemProvider.getItem('workflows').then((applicationItem: ApplicationItem) => {
      this.formStackService.specificApplicationItem = applicationItem;
    })
  }

  public async ngOnInit(): Promise<void> {
    await this.init();
  }

  public async closeModal(modal: String): Promise<void> {
    if ( this.changeService.isDetailsChanged2() === true) {
      const notAbandon: boolean = await this.modalService.modalPromise(LpModalWorkflowExitComponent, {
        backdropClass: 'commonToolsBackdropClass'
      });
      if (notAbandon === false) {
        this.changeService.clearDetailsChanged2();
        this.workflowProvider.workflowSelected = null;
        this.close();
      }
    } else {
      this.workflowProvider.workflowSelected = null;
      this.close();
    }
  }

  public async saveCurrentWorkflow(valid: any): Promise<void> {
    try {
      if (valid) {
        this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.updateInProgress'));
        if (this.currentWorkflow.id) {
          await this.update();
        } else {
          const url: string = await this.setUrl();
          await this.create(url);
          this.close();
        }
        this.changeService.clearCurrentSpecificChanges(WORKFLOWS_PREFIX);
        this.changeService.clearDetailsChanged2();
      } else {
        this.modalService.error(this.translate.instant('general.modalService.formNotValid'),
          this.translate.instant('general.modalService.error'));
      }
    } catch (error) {
      throw error;
    }
  }

  public async update(): Promise<void> {
    await this.evalService.executeContrainte(BEFORE_UPDATE_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
    this.currentWorkflow, this.formStackService.currentFields);
    try {
      await this.workflowProvider.putWorkflow(this.currentWorkflow);
      await this.actionAfterCreateOrUpdate();
      this.userHstoryService.addEvent('modify', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.Workflow')+' (ID '+this.currentWorkflow.id+')');
    } catch (error) {
      this.uiSyncService.loader(false);
    }
  }

  public async create(url: string): Promise<void> {
    await this.evalService.executeContrainte(BEFORE_INSERT_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
    this.currentWorkflow, this.formStackService.currentFields);
    try {
      let id = await this.workflowProvider.postWorkflow(this.currentWorkflow, url);
      await this.actionAfterCreateOrUpdate();
      this.userHstoryService.addEvent('add', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.Workflow')+' (ID '+id+')');
    } catch (error) {
      this.uiSyncService.loader(false);
    }
  }

  public async actionAfterCreateOrUpdate(): Promise<void> {
    this.uiSyncService.loader(false);
    this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
      this.translate.instant('general.modalService.success'));
  }

  public async setFieldsWithActivityData(): Promise<any> {
   try {
      const activity: any = await this.workflowProvider.getWorkflowActivities(this.currentWorkflow);
      this.currentWorkflow.type = activity.activityType;
      this.currentWorkflow.priority = activity.activityPriority;
    }  catch (error) {
      throw error;
    }
  }

  public async modalDeleteWorkflow(workflowID: String): Promise<void> {
    this.workflowProvider.workflowSelectedForDelete = workflowID;
    await this.modalService.modalPromise(LpModalWorkflowConfirmComponent, {
      backdropClass: 'alertBackdropClass'
    });
    this.close();
    this.workflowProvider.workflowSelected = null;
  }

  public async navigateToAttachment(): Promise<void> {
    if (this.currentWorkflow.relatedObject !== '' &&
        this.currentWorkflow.relatedObject !== null &&
        this.currentWorkflow.relatedObject !== undefined) {
          if ( this.routerService.getUrl() !== `/${this.currentWorkflow.relatedObject}`) {
            await this.routerService.navigate(this.currentWorkflow.relatedObject);
            await this.formService.build(this.formStackService.CurrentVerb, this.formStackService.CurrentKy, null, false);
          }
    }
  }

  public closeCurrentWorkflow(): void {
    if (!this.currentWorkflow.closureDate && this.currentWorkflow.id) {
      this.workflowProvider.closeWorflow(this.currentWorkflow)
        .then( async (result) => {
          this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
            this.translate.instant('general.modalService.success'));
          this.uiSyncService.loadObject();
        });
    } else {
      this.modalService.error(this.translate.instant('pas possible'), this.translate.instant('pas possible'));
    }
  }

  public cancelCurrentWorkflow(): void {
    if (!this.currentWorkflow.isCancelled && this.currentWorkflow.id) {
      this.workflowProvider.cancelWorflow(this.currentWorkflow)
        .then( async (result) => {
          // await this.refreshWorkflowsList(this.actualPageNumber);
          this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
            this.translate.instant('general.modalService.success'));
          this.uiSyncService.loadObject();
        });
    }
  }

  private async init(): Promise<void> {
    if (this.workflowProvider &&
      this.workflowProvider.workflowSelected &&
     (this.workflowProvider.workflowSelected !== null || this.workflowProvider.workflowSelected !== undefined ||
      this.workflowProvider.workflowSelected !== NEW ) ) {
        // PUT
        await this.evalService.executeContrainte(BEFORE_GET_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentWorkflow, this.formStackService.specificFields);
        this.currentWorkflow = await this.workflowProvider.findWorkflow(this.workflowProvider.workflowSelected)
        await this.evalService.executeContrainte(AFTER_GET_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*', 
        this.currentWorkflow, this.formStackService.specificFields);

      } else if (this.workflowProvider.workflowSelected === NEW) {
        // POST
        this.currentWorkflow = new LpWorkflow();
        await this.evalService.executeContrainte(INIT_NEW_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentWorkflow, this.formStackService.specificFields);
      } else {
        await this.evalService.executeContrainte(INIT_NEW_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentWorkflow, this.formStackService.specificFields);
      }
      this.formStackService.specificData = this.currentWorkflow;
  }

  private async setUrl(): Promise<string> {
    let url: string = null;
    if ( this.modalService.isMe === false && this.formStackService &&
        (this.formStackService.CurrentVerb !== undefined && this.formStackService.CurrentKy !== undefined) ) {
        url = `${this.formStackService.CurrentVerb}/${this.formStackService.CurrentKy}`;
    }
    return url;
  }


}
