import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';

export class LpMemo extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public file: String;
    @propertyMeta('string')
    public txt: String;
    @propertyMeta('string')
    public media: String;
    @Type(() => FtMeta)
    public attachmentKind: FtMeta = new FtMeta();
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('string')
    public documentNumber: String;
    @propertyMeta('boolean')
    public generic: Boolean;
    @propertyMeta('string')
    public table: String;
    public attachedDocuments: LpMemo[] = [];
    public _files: any = [];
}
