import { Component } from '@angular/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { ReportService } from 'app/services/report.service';
import { RightColService } from 'app/services/root/rightCol.service';



@Component({
  selector: 'lp-right-col',
  templateUrl: './right-col.component.html'
})
export class RightColComponent {

constructor(
  public uiSyncService: UiSyncService,
  public formStackService: FormStackService,
  public ihmStackService: IhmStackService,
  public reportService: ReportService,
  public rightColService: RightColService
) {}
}
