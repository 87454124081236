import { Component } from '@angular/core';
import { Notification, NotificationType } from "./notification";
import { Subscription } from "rxjs";
import { ModalService } from 'app/services/modal.service';

@Component({
  selector: 'notification', 
  templateUrl: './notification.component.html'
})
export class NotificationComponent {

  notifications: Notification[] = [];
  private _subscription: Subscription;

  constructor(private modalService: ModalService) { }

private _addNotification(notification: Notification) {
    this.notifications.push(notification);

    if (notification.timeout !== undefined && notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

 ngOnInit() {
    this._subscription = this.modalService.getObservable().subscribe(notification => this._addNotification(notification));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
  }


className(notification: Notification): string {

    let style: string;

    switch (notification.type) {

      case NotificationType.success:
        style = 'success';
        break;

      case NotificationType.warning:
        style = 'warning';
        break;

      case NotificationType.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }
}