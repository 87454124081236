<div *ngIf=" news && news.id && newsText" class="m-2 gridster-item-inner">
  <lp-card class="ng-star-inserted">
    <div class="card card-accent-info position-relative">
      <lp-card-content>
        <div class="card-block p-0">
          <div >
            <div>
              <div class="ng-star-inserted">
                <div ng-reflect-ng-class="panel-heading card-header" class="panel-heading card-header">
                  <h4>Informations</h4>
                </div>
                <div class="p-3" [innerHTML]="newsText"></div>
              </div>
            </div>
          </div>
        </div>
      </lp-card-content>
    </div>
  </lp-card>
