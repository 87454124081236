import { FtMeta } from './ft-meta';
import { LpMeta, propertyMeta } from './meta';
import { LpThirdParty } from './third-party';
import { LpLocation } from './location';
import { LpCompany } from './company';
import { Type } from 'class-transformer';

export class LpUser extends LpMeta {
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => FtMeta)
    public profile: FtMeta = new FtMeta();
    @propertyMeta('string')
    public language: String = '001';
    @Type(() => LpThirdParty)
    public thirdParty: LpThirdParty = new LpThirdParty();
    @propertyMeta('string')
    public username: String;
    @propertyMeta('string')
    public password: String;
    @propertyMeta('string')
    public wording: String;
}
