import { Component, Inject } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { FormStackService } from 'app/services/form-stack.service';
import { Util } from 'app/statics/utils';
import { TranslateService } from '@ngx-translate/core';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UserService } from 'app/services/user.service';
import { HttpError } from 'app/models/http-error';
@Component({
  selector: 'lp-modal-promise-error',
  templateUrl: './lp-modal-warning.component.html'
})
export class LpModalPromiseWarningComponent extends LpModalComponent {

  public showDetail: boolean = false;

  constructor(
    public formstackService: FormStackService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<LpModalComponent>,
    public modalService: ModalService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    super(dialogRef, data);
  }

  public translateByDefault(error: HttpError): String {
    if ( Util.isNullOrUndefined(error.translateKey) || error.translateKey === '' ) {
      if (Util.isNullOrUndefined(error.message) || error.message === '') {
        return this.translateService.instant('general.modalService.unknowerror');
      } else { 
          return error.message;
      }      
    } else if (error.prop !== undefined && error.prop !== null && error.prop !== '') {
      const tranlsatePoopKey: string = `${this.formstackService.CurrentVerb}.${error.prop}`;
      const translateProp: string = this.translateService.instant(tranlsatePoopKey);
      if (translateProp !== tranlsatePoopKey) {
        return this.translateService.instant(error.translateKey.toString()).replace('{{prop}}', translateProp);
      } else {
        return this.translateService.instant(error.translateKey.toString()).replace('{{prop}}', error.prop);
      }
    } else {
      return this.translateService.instant(error.translateKey.toString());
    }
  }

  public isAdminOrNotLogin(): boolean {
    return this.showDetail && (!this.userService.getCurrentUser() || this.userService.getCurrentUser().level  == '0' || this.userService.getCurrentUser().level  == '1')
  }
}
