import { LpMeta, propertyMeta, RELATION_SON, RELATION_FATHER } from './meta';
import { LpThirdParty } from './third-party';
import { LpLink } from './link';
import { Type } from 'class-transformer';

export class LpThirdPartiesRelation extends LpMeta {
    @propertyMeta('String')
    public _relationSwitch: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => LpLink)
    public link: LpLink;
    @propertyMeta('boolean')
    public isFatherTariff: Boolean;
    @Type(() => LpThirdParty)
    public father: LpThirdParty = new LpThirdParty();
    @Type(() => LpThirdParty)
    public son: LpThirdParty = new LpThirdParty();
    @propertyMeta('string')
    public originId: String;

    public assign(data: any): LpMeta {
      if (data.son.id === data.originId) {
        data._relationSwitch = RELATION_SON;
      } if (data.father.id === data.originId) {
        data._relationSwitch = RELATION_FATHER;
      }
       return super.assign(data);
    }

    public renew(): void {
        super.renew();
        this._relationSwitch = null;
    }
}
