import { Component, OnInit } from '@angular/core';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-short-term-contracts',
  templateUrl: './short-term-contracts.component.html'
})
export class ShortTermContractsComponent extends RootformComponent implements OnInit {

  public title: string;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('short-term-contracts', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data = this.formStackService.currentData;
    }
  }

  public showSwitcher(): boolean {
    let showSwitcher: boolean = false;
    if (
      this.configService &&
      this.configService.get('displayMultiShortTermContracts') && 
      this.configService.get('displayMultiShortTermContracts') === true
      ) {
      showSwitcher = true;
    }
    return showSwitcher;
  }
}
