import { Component, OnInit } from '@angular/core';
import { RootformComponent } from 'app/Forms/rootform/rootform.component';
import { LpMeta } from 'app/meta/meta';
import { LpPricingMethod } from 'app/meta/pricing-method';
import { LpThirdParty } from 'app/meta/third-party';

@Component({
  selector: 'lp-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss']
})
export class ExamplesComponent extends RootformComponent implements OnInit {

  public prop: any;
  public api: any;
  public object: any;
  public filter: any

  public async ngOnInit(): Promise<void> {
    await super.build('examples', '1');
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data = this.formStackService.currentData;
    }
    await this.initDataForAllZoomFieldType();
    await this.initCurrentApplicationItemDetailData();
  }

  private async initCurrentApplicationItemDetailData(): Promise<void> {
    const applicationItemDetailsData: any = [{
      "billingDate": "2014-10-24T00:00:00.000Z",
      "billingNumber": "F0000001",
      "details": "invoices/73552932614857521243",
      "documentNumber": "",
      "excludingTaxAmount": 47.5,
      "id": "73552932614857521243",
      "includingTaxAmount": 54,
      "remainingAmount": -27,
      "status": null,
      "vatAmount": 6.5,
      "wording": "BERARD - "
    }];
    this.formStackService.CurrentApplicationItemDetailData = applicationItemDetailsData;
  }

  private async initDataForAllZoomFieldType(): Promise<void> {
    this.data['string'] = 'oui';
    this.data['zoom'].language = '001';
    this.data['zoom'].mainAddress.zipCode = '26000';
    this.data['zoom'].mainAddress.city = 'VALENCE';
    this.data['zoom'].mainAddress.country.id = 'AN';
    this.data['zoom'].mainAddress.country.wording = 'Antilles Néerlandaise';
    this.data['zoom'].tariff.id = 'CDMODELE';
    this.data['zoom'].tariff.wording = 'TARIF CD MODELE';
    this.data['zoom'].pricingMethod = new LpPricingMethod();
    this.data['zoom'].pricingMethod.id = 'M1';
    // Zoom ( isString – path – addModifyverb === null ) - Config = pas d'exemple
    this.data['zoom'].example1 = 'example1';
    // Zoom ( isString – pas de path – addModifyverb === null ) - Config : pas d'exemple
    this.data['zoom'].example2 = 'example2';
    // Zoom ( non isString – path – addModifyverb === null ) - Config : pas d'exemple
    this.data['zoom'].example3 = new LpMeta;
    this.data['zoom'].example3.id = 'example3';
    this.data['zoom'].thirdParty = new LpThirdParty();
    this.data['zoom'].thirdParty.id = 'GGO';
    this.data['zoom'].thirdParty.firstName = 'Gregory';
    this.data['zoom'].thirdParty.lastName = 'GONDOUIN';
    this.data['zoom'].documentLink = 'https://workdoc.vega-systems.com/display/INNOV/LP3K+-+Documentation+classification+Zoom-fields';
    this.data['dateTimeJetLag'] = "2014-10-24T00:00:00.000Z";
  }

/*
  private initDetailTab(): Array<LpMeta> {
    let temp: Array<LpMeta> = []
    for (let i: number = 1; i < 6; i++) {
      let t: LpMeta = new LpMeta();
      t.id = 'id' + i;
      t.details = 'details' + i;
      t['wording'] = 'wording' + i;
      t['date'] = '2020-0' + i + '-21T17:02:45.000Z';
      temp.push(t);
    }
    return temp
  }
  */
}




/*
fausse data pour :
zoomfield dropbox

deux sous api
une avec pagination et lautre sans

repositoryProvider
voir le if qui interroge verb === exemples alors providerMock

mettre entrée de menu dan sle menu en haut a droiteen dessous de debugage
*/
