<lp-panel class="panel card panel-primary" [ngClass]="panelClass" [class.open]="oppend()" id="{{key}}">
  <lp-panel class="panel-heading card-header" (click)="toggleOpen()">
    <lp-panel cssClass="panel-title">
      <lp-panel role="button" cssClass="accordion-toggle text-dark" [attr.aria-expanded]="oppend()">
        <lp-panel *ngIf="heading" [ngClass]="{'text-muted': isDisabled}">
          <h5>{{ heading | translate}}</h5>
        </lp-panel>
      </lp-panel>
    </lp-panel>
  </lp-panel>
  <lp-panel class="panel-collapse collapse">
    <lp-panel cssClass="panel-body card-block card-body">
      <ng-content></ng-content>      
      <lp-panel cssClass="clearfix"></lp-panel>
    </lp-panel>
  </lp-panel>
</lp-panel>
