import { SchedulerMovement } from './scheduler-movements';
import { LpEquipment } from '../equipment';

export class SchedulerEquipment extends LpEquipment {

    // TODO basicMeta
    public id: String;
    public movements: Array<SchedulerMovement>;
    public label: String;
    // TODO basicMeta
    public registration: any;
    // TODO basicMeta
    public category: any;
    // TODO basicMeta
    public administrativeLocation: any;
    // TODO basicMeta
    public actualLocation: any;
    // TODO basicMeta
    public position: any;
    // TODO basicMeta
    public familly: any;
    // TODO basicMeta
    public model: any;
    public previsionnalDateIn: Date;
    public dateIn: Date;
    public previsionnalDateOut: Date;
    public dateOut: Date;
    public registrationNumber: String;

    public getIcon(): String {
        if (this.id === '1') {
            return '<i class="fa fa-bus fa-2x" aria-hidden="true"></i> s';
        } else if (this.id === '000008') {
            return '<i class="fa fa-truck fa-2x" aria-hidden="true"></i> ';
        } else {
            return '<i class="fa fa-car fa-2x" aria-hidden="true"></i> ';
        }
    }
}
