<div class="modal-header">
    <h4 class="modal-title pull-left">{{'preferences.confidentialite' | translate}}</h4>
  
    <div class="pull-right">
      <lp-button-cancel (action)="close()"></lp-button-cancel>
    </div>
</div>
<div class="modal-body">
  
    <mat-tab-group mat-align-tabs="start" >
      <mat-tab label="{{ 'preferences.confidentialite.cnil' | translate}}">
        <div class="row justify-content-md-center m-0 mt-4">
          <div [innerHTML]="cnil"></div>
        </div>
      </mat-tab>
  
      <mat-tab label="{{ 'preferences.confidentialite.cgu' | translate}}">
        <div class="row justify-content-md-center m-0 mt-4">  
          <div [innerHTML]="cgu"></div>
        </div>
      </mat-tab>

    </mat-tab-group>
  
    <div class="row justify-content-md-center m-0 mt-3">
      <div class="form-group col-xl-10">

      </div>
    </div>
</div>
