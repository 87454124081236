<div *ngIf="formStackService && formStackService.CurrentApplicationItemDetail && formStackService?.CurrentApplicationItemDetail.active && showTable" id="{{formStackService.CurrentApplicationItemDetail.verb}}" class="panel-group">
    <div [ngClass]="'panel card panel-primary open'">
      <div [ngClass]="'panel-heading card-header'">
      <h5>{{(this.formStackService.currentApplicationItemDetail.summaryEval) ? rightColService.CurrentApplicationItemDetailTitle() : formStackService.currentApplicationItemDetail.title | translate}}</h5>
      <div [ngClass]="'title-buttons'">
        
        <lp-childform-link *ngIf="formStackService.CurrentApplicationItemDetail.externalModifyFormPath && formStackService.CurrentApplicationItemDetail.externalAddForm && formStackService.CurrentApplicationItemDetail.add && !formStackService.isApplicationItemDataReadOnly()"
          verb="{{parentVerb}}" key="{{uiSyncService.historyKey}}" ky="{{newdataKeyWord}}" childType="{{formStackService.CurrentApplicationItemDetail.externalAddFormPath.split('/')[0]}}"
          [childLabel]="formStackService.CurrentApplicationItemDetail.key" [prop]="formStackService.CurrentApplicationItemDetail?.bindingProp"
          class="btn-update pull-right" title="{{'general.button.new' | translate}}" [usePreviousAPIConfig]="true">
          <span class="btn btn-primary">
            <i class="fa fa-plus"></i>
          </span>
        </lp-childform-link>

        <lp-childform-link *ngIf="!formStackService.CurrentApplicationItemDetail.externalModifyFormPath && formStackService.CurrentApplicationItemDetail.externalAddForm && formStackService.CurrentApplicationItemDetail.add && !formStackService.isApplicationItemDataReadOnly()"
          verb="{{parentVerb}}" key="{{uiSyncService.historyKey}}" ky="{{newdataKeyWord}}" childType="{{formStackService.CurrentApplicationItemDetail.externalAddFormPath.split('/')[0]}}"
          [childLabel]="formStackService.CurrentApplicationItemDetail.key" 
          class="btn-update pull-right" title="{{'general.button.new' | translate}}" [usePreviousAPIConfig]="false">
          <span class="btn btn-primary">
            <i class="fa fa-plus"></i>
          </span>
        </lp-childform-link>


        <lp-button-new *ngIf="!formStackService.CurrentApplicationItemDetail.externalAddForm && formStackService.CurrentApplicationItemDetail.add && !formStackService.isApplicationItemDataReadOnly()" class="pull-right" (action)="showDetailForm()"></lp-button-new>
        <span (click)="changeConfigPanel()" class="btn-config pull-right">
          <i class="icon fa fa-cogs icon-tableSetings"></i>
        </span>
        <span *ngIf="formStackService.CurrentApplicationItemDetail.exportable" (click)="export()" class="btn-export">
          <i class="fa fa-upload" title="{{'general.button.export' | translate}}"></i>
        </span>
        <span *ngIf="formStackService.CurrentApplicationItemDetail.bulk" (click)="showImportBlock = !showImportBlock" class="btn-import">
          <i class="icon fa fa-download" title="{{'general.button.import' | translate}}"></i>
        </span>
        <!--<lp-button-cancel (action)="closeComponent()"></lp-button-cancel>-->

        </div>
    </div>

    <div class="modal-body">
      <div [class.show]="showImportBlock" class="importBolck col-12 mb-3 mt-1">
        <lp-drop-file [ignoreChange]="true" label='memos.dragFile' name="importLines" filename="lines" (uploaded)="importLines($event)" [isBinding]="false" [importCSV]="true"></lp-drop-file>
      </div> 

        <ngx-datatable class="material table-height-35"
          [rows]="formStackService.CurrentApplicationItemDetailData" 
          [columnMode]="'force'"
          [headerHeight]="35" 
          [rowHeight]="'auto'"
          [messages]="{emptyMessage: 'general.emptyMessage' |translate}"
          [rowClass]="rowClass" 
          [footerHeight]="(formStackService.CurrentApplicationItemDetail.pagination || formStackService.CurrentApplicationItemDetail.bulk) ? 35 : 0" 
          [loadingIndicator]="rightColService.applciationItemDetailloadingIndicator" 
          [class.loading-table]="rightColService.applciationItemDetailloadingIndicator" 
          (page)="changePage($event)"
          (sort)="onSort($event)"
          width="100%">         
            
          <ngx-datatable-column *ngFor="let col of getDisplayedColumns() index as colIndex"
          
            name="{{(col.wording !== null && col.wording !== undefined) ? col.wording : ((col.prop === 'actionColumn') ? '' : formStackService?.currentVerb + '.' +  formStackService?.CurrentApplicationItemDetail?.key + '.' + col.name |translate)}}"
            [width]="col.width" [resizeable]="col.resizeable" [sortable]="formStackService.CurrentApplicationItemDetail.sortable" [draggable]="col.draggable" data-prop="{{col.prop}}"
            [canAutoResize]="col.canAutoResize" [hidden]="!col.isDisplayed" >
            
            <!-- template de base -->
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row" let-i="index">
              <div *ngIf="!formStackService?.CurrentApplicationItemDetail?.bulk" [style]="getStyle(col.name, rowIndex, colIndex)" class="{{col.classes}}">
                <div [style]="getComponentStyle(col.name, rowIndex, colIndex)"  [class.lpactive]="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 7)) && col.prop !== 'actionColumn' && col.modify">
                  
                  <lp-cell 
                    *ngIf="(row.id != 'footer_table' || (row.id == 'footer_table' && col.summaryFunc)) && col.prop !== 'actionColumn' && (!col.duplicate && !col.modify) || formStackService.isApplicationItemDataReadOnly()"
                    [type]="col.type" [value]="jsDataService.getDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop)">
                  </lp-cell>
                  <lp-text-field
                    *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (!col.type || col.type === 1)) && col.prop !== 'actionColumn' && col.modify"
                    id="{{col.prop}}-{{rowIndex}}" name="{{formStackService.currentApplicationItemDetail.key}}.{{col.prop}}-{{rowIndex}}" [ignoreChange]="getIgnoreChange(col)"
                    [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]" [readonly]="isCellReadOnly(col, rowIndex)"
                    (putEvtEmitter)="focusOutSaveLine(row.id, rowIndex, null, col)"></lp-text-field>
                  <lp-number *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 2)) && col.prop !== 'actionColumn' && col.modify"
                    id="{{col.prop}}-{{rowIndex}}" name="{{formStackService.currentApplicationItemDetail.key}}.{{col.prop}}-{{rowIndex}}" [ignoreChange]="getIgnoreChange(col)"
                    [ngModel]="jsDataService.getDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop)" (ngModelChange)="jsDataService.setDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop, $event)"
                    [readonly]="isCellReadOnly(col, rowIndex)" [integer]="col.integer"
                    (putEvtEmitter)="focusOutSaveLine(row.id, rowIndex, 'quantityAssignment', col, row)"></lp-number>
                  <lp-currency
                    *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 10)) && col.prop !== 'actionColumn' && col.modify"
                    id="{{col.prop}}-{{rowIndex}}" name="{{formStackService.currentApplicationItemDetail.key}}.{{col.prop}}-{{rowIndex}}" [ignoreChange]="getIgnoreChange(col)"
                    [ngModel]="jsDataService.getDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop)"  (ngModelChange)="jsDataService.setDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop, $event)"
                    [readonly]="isCellReadOnly(col, rowIndex)"
                    (putEvtEmitter)="focusOutSaveLine(row.id, rowIndex,  'amountAssignment', col)"></lp-currency>

                    <lp-active (ngModelChange)="jsDataService.setDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop, $event)" (putEvtEmitter)="focusOutSaveLine(row.id, rowIndex,  'amountAssignment', col)"
                    *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 7)) && col.prop !== 'actionColumn' && col.modify"
                    id="{{col.prop}}-{{rowIndex}}" name="{{formStackService.currentApplicationItemDetail.key}}.{{col.prop}}-{{rowIndex}}" [ignoreChange]="getIgnoreChange(col)"
                    [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]"  [readonly]="isCellReadOnly(col, rowIndex)"></lp-active>

                    <lp-date-time
                    *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 5)) && col.prop !== 'actionColumn' && col.modify"
                    id="{{col.prop}}-{{rowIndex}}" name="{{formStackService.currentApplicationItemDetail.key}}.{{col.prop}}-{{rowIndex}}" [ignoreChange]="getIgnoreChange(col)"
                    [ngModel]="jsDataService.getDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop)" (ngModelChange)="jsDataService.setDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop, $event)"
                    [readonly]="isCellReadOnly(col, rowIndex)"
                    (putEvtEmitter)="focusOutSaveLine(row.id, rowIndex,  'amountAssignment', col)"></lp-date-time>
                    
                    <lp-date  *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 4))  && col.prop !== 'actionColumn' && col.modify" 
                    id="{{col.prop}}-{{rowIndex}}" name="{{formStackService.currentApplicationItemDetail.key}}.{{col.prop}}-{{rowIndex}}" [ignoreChange]="getIgnoreChange(col)"
                    [ngModel]="jsDataService.getDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop)" (ngModelChange)="jsDataService.setDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop, $event)"
                    [readonly]="isCellReadOnly(col, rowIndex)"
                    (putEvtEmitter)="focusOutSaveLine(row.id, rowIndex,  'amountAssignment', col)"></lp-date>

                    <lp-duplicate class="duplicate" (click)="duplicateLine(row, rowIndex)"
                    *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 11)) && col.prop !== 'actionColumn' && col.duplicate"
                    [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]" name="isActive" title="Active" id="isActive" [ignoreChange]="getIgnoreChange(col)"></lp-duplicate>

                    <lp-remove class="remove" (click)="removeLine(row)"
                    *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 11)) && col.prop !== 'actionColumn' && col.remove"
                    [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]" name="remove" title="remove" id="remove"></lp-remove>

                    <lp-save class="save" (click)="buttonSaveLine(row.id, rowIndex,  'amountAssignment', col)"
                    *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 11)) && col.prop !== 'actionColumn' && col.save"
                    [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]" name="remove" title="remove" id="remove"></lp-save>

                </div>

                <lp-ui-loader-mini
                  *ngIf="isLoading && col.prop !== 'actionColumn' && !col.modify && isTheGoodLine(rowIndex) && (isLoadingID == row.id || isLoadingID == row.linkedId || row.id == 'footer_table')"
                  message="{{( row.id === 'footer_table') ? 'loading.updateFooter' : 'loading.saveModif'}}"></lp-ui-loader-mini>    
              </div>

              <div *ngIf="formStackService?.CurrentApplicationItemDetail?.bulk" >
                <lp-fields *ngIf="col.inputField" [f]="col.inputField" [data]="formStackService.CurrentApplicationItemDetailData[rowIndex]" [id]="col.prop+'-'+rowIndex" [isDetail]="false" ></lp-fields>
                <lp-text-field
                  *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type === 1)) && col.prop !== 'actionColumn' && col.modify  && !col.inputField"
                  id="{{col.prop}}-{{rowIndex}}" name="{{col.prop}}-{{rowIndex}}"
                  [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]" [ignoreChange]="false"
                  (putEvtEmitter)="saveBulk(row, rowIndex, col.prop)"></lp-text-field>
                <lp-number *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 2)) && col.prop !== 'actionColumn' && col.modify  && !col.inputField"
                  id="{{col.prop}}-{{rowIndex}}" name="{{col.prop}}-{{rowIndex}}" [ignoreChange]="false"
                  [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]"
                  (putEvtEmitter)="saveBulk(row, rowIndex, col.prop)"></lp-number>
                <lp-currency
                  *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type && col.type === 10)) && col.prop !== 'actionColumn' && col.modify  && !col.inputField"
                  id="{{col.prop}}-{{rowIndex}}" name="{{col.prop}}-{{rowIndex}}" [ignoreChange]="false"
                  [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]"
                  (putEvtEmitter)="saveBulk(row, rowIndex, col.prop)"></lp-currency>
                <lp-boolean-switch-box *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && ( col.type === 7)) && col.prop !== 'actionColumn' && col.modify  && !col.inputField" 
                  id="{{col.prop}}-{{rowIndex}}" name="{{col.prop}}-{{rowIndex}}" switchName="chkb_{{col.prop}}-{{rowIndex}}" 
                  [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]"></lp-boolean-switch-box>
                <lp-zoom-field *ngIf="!formStackService.isApplicationItemDataReadOnly() && (row.id !== 'footer_table' && (col.type === 0)) && col.prop !== 'actionColumn' && col.modify  && !col.inputField" id="{{col.prop}}-{{rowIndex}}" zoomId='details_{{col.params.zoomId}}' className="zoomField form-control" 
                  [(ngModel)]="formStackService.CurrentApplicationItemDetailData[rowIndex][col.prop]" name="{{col.prop}}-{{rowIndex}}"
                  [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [ignoreChange]="false" (putEvtEmitter)="saveBulk(row, rowIndex, col.prop)"></lp-zoom-field>
                <lp-cell
                  *ngIf="(row.id != 'footer_table' || (row.id == 'footer_table' && col.summaryFunc)) && col.prop !== 'actionColumn' && (!col.modify || formStackService.isApplicationItemDataReadOnly()) && !col.inputField"
                  [type]="col.type" [value]="jsDataService.getDataRef(formStackService.CurrentApplicationItemDetailData[rowIndex], col.prop)">
                </lp-cell>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="formStackService?.CurrentApplicationItemDetail?.modify" [width]="35" [resizeable]="false"
            [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'" >
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
              let-i="index" style="margin-top: 10px;">
              <lp-childform-link *ngIf="formStackService?.CurrentApplicationItemDetail?.externalModifyForm && row.id !== 'footer_table'" verb="{{parentVerb}}"
                key="{{uiSyncService.historyKey}}" ky="{{uiSyncService.formatURL(formStackService?.CurrentApplicationItemDetail?.externalModifyFormPath, row).split('/')[1]}}"
                class="applicationItemDetailAction btn-update" childType="{{formStackService?.CurrentApplicationItemDetail?.externalModifyFormPath.split('/')[0]}}">
                <i class="icon vega-pencil" title="{{'general.edit' | translate}}"></i>
              </lp-childform-link>
              <a *ngIf="!formStackService?.CurrentApplicationItemDetail?.externalModifyForm && row.id !== 'footer_table'" (click)="showDetailForm(row.id)"  class="actioInTable">
                <i *ngIf="!formStackService.isApplicationItemDataReadOnly()" class="icon vega-pencil applicationItemDetailAction" title="{{'general.edit' | translate}}"></i>
                <i *ngIf="formStackService.isApplicationItemDataReadOnly()" class="icon vega-view applicationItemDetailAction" title="{{'general.show' | translate}}"></i>
              </a>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="formStackService?.CurrentApplicationItemDetail?.externalFilledForm" [width]="35" [resizeable]="false"
          [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'" >
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
            let-i="index" style="margin-top: 10px;">
              <lp-childform-link *ngIf="formStackService?.CurrentApplicationItemDetail?.externalFilledForm && row.id !== 'footer_table'" verb="{{parentVerb}}"
                key="{{uiSyncService.historyKey}}" ky="{{uiSyncService.formatURL(formStackService?.CurrentApplicationItemDetail?.path, row).split('/')[1]}}"
                class="applicationItemDetailAction btn-update" childType="{{formStackService?.CurrentApplicationItemDetail?.path.split('/')[0]}}" [indexClicked]="rowIndex">
                <i class="icon fa fa-long-arrow-right" title="{{'general.quickQuotes.creation' | translate}}"></i>
              </lp-childform-link>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="formStackService?.CurrentApplicationItemDetail?.delete && !formStackService.isApplicationItemDataReadOnly()" [width]="35" [resizeable]="false"
            [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
              let-i="index" style="margin-top: 10px;">
              <div class="actioInTable">
                <i class="ms-2 icon vega-dustbin applicationItemDetailAction" title="{{'general.delete' | translate}}"
                (click)="deleteObject(row)" *ngIf="row.id !== 'footer_table'"></i>
              </div>
            </ng-template>
          </ngx-datatable-column>

          
          <ngx-datatable-column *ngIf="formStackService?.CurrentApplicationItemDetail?.bulk && !formStackService.isApplicationItemDataReadOnly()" [width]="35" [resizeable]="false"
            [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
              let-i="index" style="margin-top: 10px;">
              <div class="actioInTable">
                <i class="ms-2 icon vega-dustbin applicationItemDetailAction" title="{{'general.delete' | translate}}"
                (click)="deleteBulk(row, rowIndex)" *ngIf="row.id !== 'footer_table'"></i>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="formStackService?.CurrentApplicationItemDetail?.duplicate" [width]="35" [resizeable]="false"
            [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'application-item-detail'" >
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
              let-i="index" style="margin-top: 10px;">
              <a (click)="duplicate(row)">
                <i class="icon vega-pencil applicationItemDetailAction" title="{{'general.duplicate' | translate}}"></i>
              </a>
            </ng-template>
          </ngx-datatable-column>

          <!--<ngx-datatable-footer *ngIf="formStackService?.CurrentApplicationItemDetail?.bulk">
            <ng-template ngx-datatable-footer-template>
              <button (click)="addRow()" class="btn btn-sm btn-primary pull-right bulk-add-row">{{'ApplicationItemDetails.bulk.addRow' | translate}}</button>
            </ng-template>
          </ngx-datatable-footer>-->

          <ngx-datatable-footer *ngIf="formStackService?.CurrentApplicationItemDetail?.pagination || formStackService?.CurrentApplicationItemDetail?.bulk">
            <ng-template ngx-datatable-footer-template>                      
              <lp-footer *ngIf="formStackService?.CurrentApplicationItemDetail?.pagination"
                [guid]="guid"
                (changePage)="changePage($event, formStackService?.CurrentApplicationItemDetail.bulk)"
                [dataCount]="formStackService.CurrentApplicationItemDetailDataCount"
                [linesPerPage]="userService.getCurrentUser().preference.linesPerPage"
                [currentPage]="currentPage"
                [allLines]=true
                [showAddRow]="formStackService?.CurrentApplicationItemDetail?.bulk"
                (addRow)="addRow($event)"
                >
              </lp-footer>
            </ng-template>
        </ngx-datatable-footer>     
        </ngx-datatable>
      </div>
    </div>

  <div *ngIf="showConfigPanel" class="detail-config-tab">
    
    <lp-ui-sort-list [isBulk]="formStackService?.CurrentApplicationItemDetail?.bulk" [list]="formStackService.CurrentApplicationItemDetail.columns" prefix="{{formStackService?.currentVerb + '.' +  formStackService?.CurrentApplicationItemDetail?.key + '.'}}" label="title" prop="isDisplayed"></lp-ui-sort-list>
  </div>
</div>

<div class="col-12">
  <i *ngIf="formStackService.CurrentApplicationItemDetail.bulk && formStackService.CurrentApplicationItemDetailData && formStackService.CurrentApplicationItemDetailData.length > 0" class="vega-diskette technical-ft-save-btn" (click)="persistBulk()"></i>
</div>