import { Component, OnInit, OnDestroy } from '@angular/core';
import { ValueFieldComponent } from '../value-field.component';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  template: ''
})
export class DateFieldComponent extends ValueFieldComponent implements OnInit, OnDestroy {

  constructor(dateTimeAdapter: DateTimeAdapter<any>, protected changeService: ChangeService,
     protected evalService: EvalService, protected formMetadataProvider: FormMetadataProvider,
     protected uiSyncService: UiSyncService, public formStackService: FormStackService, public metaFactoryService: MetaFactoryService,
     public configService: ConfigService) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
   } 

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
