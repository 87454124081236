<lp-top-banner *ngIf="topBanner" [titleTranslationKey]="formStackService.currentApplicationItem.title"></lp-top-banner>


<div class="container-fluid container-fluid-2-cols"
  *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)"
  (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-lg-3 col-md-4 label-2-colonnes">
      <form id="formulaire" (ngSubmit)="filterPricings()" ngNativeValidate>
        <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
          <lp-ui-accordion class="row me-2 ms-2" [menu]=true>
            <lp-ui-accordion-group heading="ft.filter" [open]=true key="general">
              
              <div class="row">
                <div class="form-group col-12">
                  
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">

                </div>
              </div>
            </lp-ui-accordion-group>

            <!--Accordion Debugger-->
            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>
          </lp-ui-accordion>
        </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
        <!--<button type="submit" class="technical-ft-search-btn"><i class="icon vega-search"></i></button>-->
        
      </form>
      <button type="button" class="technical-ft-search-btn" (click)="filterPricings()"><i class="icon vega-search"></i></button>
    </div>
    <div class="col-md-9 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>