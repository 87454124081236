import { Component, OnInit } from '@angular/core';
import { LpService } from 'app/meta/service';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent extends RootformComponent implements OnInit {
  public data: LpService = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('services', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
