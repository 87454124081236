import { Injectable, EventEmitter, Output } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { LpWorkflow } from 'app/meta/workflow';
import { PaginatedData } from 'app/models/paginated-data';
import { HttpParams } from '@angular/common/http';
import { Util } from 'app/statics/utils';
import { RepositoryProvider } from './repository.provider';
import { ConfigService } from 'app/services/config.service';
import { HttpProvider } from './http.provider';

const WORKFLOWS_ME: String = 'workflows/me';
const WORKFLOW: String = 'workflows';
const WORKFLOW_ACTIVITIES: string = 'workflows/activities';

@Injectable({
  providedIn: 'root'
})
export class WorkflowProvider {

  @Output() public refreshNbWorkflows: EventEmitter<number> = new EventEmitter<number>();

    /**
   * L'évènement refreshWorkflowListEvtEmitter est utilisé lorsque l'utilisateur à fait une mise à jour sur la liste des workflows.
   */
  public refreshWorkflowListEvtEmitter: EventEmitter<any> = new EventEmitter();
  public nbWorkflows: number = -1;
  public workflowSelected: any;
  public workflowSelectedForDelete: any;

  constructor(
    private alocproProvider: AlocproProvider,
    private repositoryProvider: RepositoryProvider,
    private configService: ConfigService,
    public http: HttpProvider,
  ) {}

  public refreshWorkflowList(): void {
    this.refreshWorkflowListEvtEmitter.emit();
  }

  /**
   * Get list of workflows related to an object.
   * @param relatedObject
   * @param relatedObjectId
   * @param page
   * @param limit
   */
  public getWorkflows(relatedObject: String, relatedObjectId: String, page: number, limit: Number, isMe: boolean): Promise<PaginatedData> {
    // add 1 to params page because ngx-datatable start at 0 not 1.
    // if (page) { page++ };
    if (!isMe) {
      let params: Map<string, string> = new Map();
      params.set('object', relatedObject.toString())
      params.set('key', relatedObjectId.toString())
      return this.alocproProvider.getPaginatedData(`${WORKFLOW}`, page, params, null, limit);
    } else {
      return this.alocproProvider.getPaginatedData(`${WORKFLOWS_ME}`, page, null, null, limit);
    }
  }

  public findWorkflow(id: String): Promise<any> {
    return this.alocproProvider.find(WORKFLOW, id);
  }

  public deleteWorkflow(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alocproProvider.delete('workflows', this.workflowSelectedForDelete)
        .then(() => {
          this.refreshWorkflowList();
          resolve()
        });
    });
  }

  /**
   * Used to get only the number of workflows for an object.
   */
  public getNbWorkflows(relatedObject: String, relatedObjectId: String, verb?: String): Promise<number> {
    // used to get relatedObject who is missing when we navigate backward on breadcrumb
    if (this.nbWorkflows === -1 && !relatedObject && verb) {
     this.repositoryProvider.getApplicationItem(verb).then((res) => {
        relatedObject = res.linkedObject;
      })
    };
    return new Promise<number>((resolve, reject) => {
      if (this.nbWorkflows === -1 && !Util.isNullOrUndefined(relatedObjectId)) {
        let params: HttpParams = new HttpParams()
        if (!Util.isNullOrUndefined(relatedObject)) {
          params = params.set('object', relatedObject.toString());
        }
        if (!Util.isNullOrUndefined(relatedObjectId)) {
          params = params.set('key', relatedObjectId.toString());
        }
        this.alocproProvider.getTotalCount(`${this.configService.get('alocproServiceUrl')}/${WORKFLOW}`, params).then((result: number) => {
          resolve(result);
        });
      } else {
        reject(this.nbWorkflows) // reject ici plutot si pa de workflow non ?
      }
    });
  }

    /**
   * Used to get only the number of workflows for the current logged user.
   */
  public getUserNbWorkflows(): Promise<number> {
    return this.alocproProvider.getTotalCount(`${this.configService.get('alocproServiceUrl')}/${WORKFLOWS_ME}`).then((result: number) => {
      return result;
    });
  }

  public putWorkflow(editedWorkflow: LpWorkflow): Promise<void> {
    // set recipient to null if not. Recipient, need to be null or a valid user.
    if (editedWorkflow && editedWorkflow.recipient && editedWorkflow.recipient.id === null) {
      editedWorkflow.recipient = null;
    }
    return new Promise<void>((resolve, reject) => {
      this.alocproProvider.put('workflows', editedWorkflow.id as string, editedWorkflow)
        .then(() => {
          resolve()
        });
    });
  }

  /**
   *
   * @param newWorkflow Type: LpWorkflow.  New workflow
   * @param relatedObject Type: String. Objet related to the new workflow
   */
  public postWorkflow(newWorkflow: LpWorkflow, relatedObject: String): Promise<void> {
    // Add relatedObject
    newWorkflow.relatedObject = relatedObject
    // set isCanceled to false
    newWorkflow.isCancelled = false;
    // set recipient to null if not recipient, need to be null or a valid user.
    if (newWorkflow && newWorkflow.recipient && newWorkflow.recipient.id === null) {
      newWorkflow.recipient = null;
    }
    return new Promise<void>((resolve, reject) => {
      let a: Promise<any>;
      a = this.http.httpPost(`${this.configService.get('alocproServiceUrl')}/workflows`, newWorkflow);
      a.then((response) => {
        this.refreshWorkflowList();
        resolve(resolve(response.headers.get('x-vega-id')));
      });
    });
  }

  /**
   *
   * @param currentWorkflow
   */
  public closeWorflow(currentWorkflow: LpWorkflow): Promise<void> {
    return new Promise<void>((resolve, reject) => {
     // currentWorkflow.closureDate = this.dateTimeService.toDate(this.dateTimeService.getNow());
      this.putWorkflow(currentWorkflow)
        .then(() => {
          resolve()
        });
    })
  }

  /**
   *
   * @param currentWorkflow
   */
  public cancelWorflow(currentWorkflow: LpWorkflow): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      currentWorkflow.isCancelled = true;
      this.putWorkflow(currentWorkflow)
        .then(() => {
          resolve()
        });
    })
  }

  public async getWorkflowActivities(currentWorkflow: LpWorkflow): Promise<any> {
    try {
      return await this.alocproProvider.find(WORKFLOW_ACTIVITIES, currentWorkflow.activity.id);
    } catch (error) {
      throw error;
    }
  }

}
