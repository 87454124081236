


<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.invoiceLinesForm.title" [open]=true>
      <form id="formInvoiceLines" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>
          <div class="row">
              <div class="form-group col-xl-6">
                <lp-label-field for="lines.service" class="{{arrayClassesElements.get('defaultLabel')}}"
                  text="metadataApplicationItemDetails.invoiceLinesForm.service"></lp-label-field>
                <lp-zoom-field id="lines.service" class="{{arrayClassesElements.get('defaultInput')}}" name="lines.service"
                 zoomId='lines.services' className="zoomField form-control" [(ngModel)]="data.service">
                </lp-zoom-field>
              </div>
              <div class="form-group col-xl-6">
                <lp-label-field for="wording" class="{{arrayClassesElements.get('defaultLabel')}}"
                  text="metadataApplicationItemDetails.invoiceLinesForm.wording"></lp-label-field>
                <lp-text-field id="lines.wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                  cssClass="form-control" name="lines.wording"
                  [(ngModel)]="data.wording"></lp-text-field>
              </div>
          </div>       
            

          <div class="row">
              <div class="form-group col-xl-6">
                  <lp-label-field for="lines.quantity" class="{{arrayClassesElements.get('defaultLabel')}}" text="metadataApplicationItemDetails.invoiceLinesForm.quantity"></lp-label-field>
                  <lp-number id="lines.quantity" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="lines.quantity"
                    [(ngModel)]="data.quantity"></lp-number>
            </div>
            <div class="form-group col-xl-6">
                <lp-label-field for="unitPrice" class="{{arrayClassesElements.get('defaultLabel')}}" text="metadataApplicationItemDetails.invoiceLinesForm.unitPrice"></lp-label-field>
                <lp-currency step="0.0000000000000001" id="lines.unitPrice" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="lines.unitPrice"
                  [(ngModel)]="data.unitPrice"></lp-currency>
          </div>   
          </div>

          <div class="row">            
            <div class="form-group col-xl-6">
                <lp-label-field for="lines.isWithoutTax" class="{{arrayClassesElements.get('defaultLabel')}}"
                  text="metadataApplicationItemDetails.invoiceLinesForm.isWithoutTax"></lp-label-field>
                  <lp-boolean-switch-box name="lines.isWithoutTax" switchName="isWithoutTax" id="lines.isWithoutTax" [(ngModel)]="data.isWithoutTax"></lp-boolean-switch-box>
            </div> 
          </div>

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger formulaire ligne de facture" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>
  