<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="openingHours.title" [open]=true>
    <form id="formMyComponent" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>
      <div class="row">
        <div class="form-group col-xl-12">
          <lp-label-field for="openingHours.dayOfWeek" class="{{arrayClassesElements.get('defaultLabel')}}" 
            text="openingHours.dayOfWeek" [required]="(data.date && data.date !== null) ? false : true"></lp-label-field>
          <lp-dropdown-field className="textField" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [required]="(data.date && data.date !== null) ? false : true"
            id="openingHours.dayOfWeek" name="openingHours.dayOfWeek" [values]="daysOfWeek" labelField="description" outputType="string" [(ngModel)]="data.dayOfWeek"></lp-dropdown-field>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-xl-12">
          <lp-label-field for="openingHours.openingHour" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="openingHours.hours"></lp-label-field>
          <lp-time type="time" name="openingHours.openingHour" id="openingHours.openingHour" [(ngModel)]="data.openingHour" className="textField"
            cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2"></lp-time>
          <div class="col-1 sep-input pe-0 ps-0 text-center">/</div>
          <lp-time type="time" name="openingHours.closingHour" id="openingHours.closingHour" [(ngModel)]="data.closingHour" className="textField"
            cssClass="form-control" class="col-md-4 col-lg-2 col-xl-2"></lp-time>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-xl-12">
          <lp-label-field for="openingHours.date" class="{{arrayClassesElements.get('defaultLabel')}}" text="openingHours.date" [required]="(data.dayOfWeek && data.dayOfWeek !== '') ? false : true"></lp-label-field>
          <lp-date id="openingHours.date" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="openingHours.date" placeholder="openingHours.date"
            [(ngModel)]="data.date" [required]="(data.dayOfWeek && data.dayOfWeek !== '') ? false : true"></lp-date>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-xl-12">
          <lp-label-field for="openingHours.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="openingHours.wording"></lp-label-field>
          <lp-text-field id="openingHours.wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
            cssClass="form-control" name="openingHours.wording" [(ngModel)]="data.wording"
            placeholder="openingHours.wording" type="text"></lp-text-field>
        </div>
      </div>

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger Horaires d'ouvertures" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>
