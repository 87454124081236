import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, ACCOUNTING_EVENTS } from '../detail-forms.component';
import { LpLine } from 'app/meta/line';

@Component({
  selector: 'lp-lines',
  templateUrl: './lines.component.html'
})
export class LinesComponent extends DetailFormsComponent implements OnInit {

  public data: LpLine = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(`${this.object}${ACCOUNTING_EVENTS}`);
    this.initUrl();
  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

  protected initUrl(): void {
    this.urlGet = `/short-term-contracts/{{id}}/${this.object}-accounting-events`;
    this.urlEdit = `/short-term-contracts/{{id}}/${this.object}-accounting-events`;
  }

}
