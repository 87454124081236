<ul *ngIf="displayValue" id="{{id}}" class="col-12 mb-0 {{className}} {{cssClass}}" bindingKey="bindingKey" ngDefaultControl [ngClass]="responsivClass">
    <li *ngFor="let v of displayValue;" class="checkbox checked" [ngClass]="{'col-md-6 col-lg-4 col-xl-4': !singleCol}">
        <label [class.readonly]="readonly">
            <input type="checkbox" id="{{v[idField]}}" name="{{v[idField]}}" checked="checked" (click)="removeItem(v)"/> {{v[prop][labelField]}}

        </label>
    </li>
</ul>

<hr class="offset-2 col-sm-8 mb-3 mt-3" />

<ul *ngIf="values" id="{{id}}" class="col-12 {{className}} {{cssClass}} not-selected" bindingKey="bindingKey" ngDefaultControl [ngClass]="responsivClass">
    <li *ngFor="let value of values;" class="checkbox" [class.d-none]="value.checked" [ngClass]="{'col-md-6 col-lg-4 col-xl-4': !singleCol}">
        <label [class.readonly]="readonly">
            <input type="checkbox" id="{{value[idField]}}" name="{{value[idField]}}" [checked]="value.checked" (click)="addItem(value)"/> {{value[labelField]}}
        </label>
    </li>
</ul>