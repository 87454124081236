import {NgModule} from '@angular/core';
import {DetailFormsComponent} from './detail-forms.component';
import {RibsComponent} from './ribs/ribs.component';
import {AdditionalDriversComponent} from './additional-drivers/additional-drivers.component';
import {OpeningHoursComponent} from './opening-hours/opening-hours.component';
import {ContactsComponent} from './contacts/contacts.component';
import {ThirdPartiesRelationsComponent} from './third-parties-relations/third-parties-relations.component';
import {AccountingsComponent} from './accountings/accountings.component';
import {ThirdPartyTypeComponent} from './third-party-type/third-party-type.component';
import {AddressesDetailFormComponent} from './addresses-detail-form/addresses-detail-form.component';
import {HistoricalMileagesComponent} from './historical-mileages/historical-mileages.component';
import {RegistrationsComponent} from './registrations/registrations.component';
import {EquipmentsRelationsComponent} from './equipments-relations/equipments-relations.component';
import {TechnicalSpecificationsComponent} from './technical-specifications/technical-specifications.component';
import {ShortTermContractsMediasComponent} from './short-term-contracts-medias/short-term-contracts-medias.component';
import {DepositsComponent} from './deposits/deposits.component';
import {AccountingEventsComponent} from './accounting-events/accounting-events.component';
import {InvoiceLinesComponent} from './invoice-lines/invoice-lines.component';
import {AppModuleUI} from '../ui/app.module.ui';
import {AppModuleLp} from '../lp/app.module.lp';
import {TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { FacturationLinksComponent } from './facturation-links/facturation-links.component';
import { ThirdPartyLinksComponent } from './third-party-links/third-party-links.component';
import { LinkDamageHistoricalOperationsComponent } from './link-damage-historical-operations/link-damage-historical-operations.component';
import { CircumstanceLinksComponent } from './circumstance-links/circumstance-links.component';
import { FinancialElementsLinksComponent } from './financial-elements-links/financial-elements-links.component';
import { LinkDamageValueComponent } from './link-damage-value/link-damage-value.component';
import { KindsLinksComponent } from './kinds-links/kinds-links.component';
import { CrashLinksComponent } from './crash-links/crash-links.component';
import { LocationContactsComponent } from './locationsContacts/locationContacts.component';
import { RightColComponent } from './right-col/right-col.component';
import { SubApiComponent } from './right-col/sub-api/sub-api.component';
import { FormComponentModule } from 'app/FormComponents/formComponent.module';
import { MandatesComponent } from './mandates/mandates.component';
import { LpModalWorkflowEditComponent } from 'app/ui/lp-modal/lp-modal-workflow/lp-modal-workflow-edit/lp-workflow-edit.component';
import { LpModalEdiEditComponent } from 'app/ui/lp-modal/lp-modal-edi/lp-modal-edi-edit/lp-edi-edit.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { LocationsTypeComponent } from './locations-type/locations-type.component';
import { LocationsSituationsComponent } from './locations-situation/locations-situation.component';
import { LocationSectorsComponent } from './location-sector/location-sector.component';
import { iHubCheckPlusSettingsComponent } from './ihubcheckplus-settings/ihubcheckplus-settings.component';
import { LinesComponent } from './lines/lines.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    NgxDatatableModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    TypeaheadModule,
    PopoverModule,
    AppModuleLp,
    AppModuleUI,
    FormComponentModule,
  ],
  declarations: [
    DetailFormsComponent,
    RibsComponent,
    MandatesComponent,
    AdditionalDriversComponent,
    OpeningHoursComponent,
    LocationContactsComponent,
    ContactsComponent,
    ThirdPartiesRelationsComponent,
    AccountingsComponent,
    ThirdPartyTypeComponent,
    UserGroupsComponent,
    LocationsTypeComponent,
    LocationsSituationsComponent,
    LocationSectorsComponent,
    AddressesDetailFormComponent,
    HistoricalMileagesComponent,
    RegistrationsComponent,
    EquipmentsRelationsComponent,
    TechnicalSpecificationsComponent,
    AccountingEventsComponent,
    ShortTermContractsMediasComponent,
    InvoiceLinesComponent,
    DepositsComponent,
    FacturationLinksComponent,
    ThirdPartyLinksComponent,
    LinkDamageHistoricalOperationsComponent,
    CircumstanceLinksComponent,
    FinancialElementsLinksComponent,
    LinkDamageValueComponent,
    KindsLinksComponent,
    CrashLinksComponent,
    RightColComponent,
    SubApiComponent,
    LpModalWorkflowEditComponent,
    LpModalEdiEditComponent,
    iHubCheckPlusSettingsComponent,
    LinesComponent
  ],
  exports: [
    DetailFormsComponent,
    RibsComponent,
    MandatesComponent,
    AdditionalDriversComponent,
    OpeningHoursComponent,
    LocationContactsComponent,
    ContactsComponent,
    ThirdPartiesRelationsComponent,
    AccountingsComponent,
    ThirdPartyTypeComponent,
    UserGroupsComponent,
    LocationsTypeComponent,
    LocationsSituationsComponent,
    LocationSectorsComponent,
    AddressesDetailFormComponent,
    HistoricalMileagesComponent,
    RegistrationsComponent,
    EquipmentsRelationsComponent,
    TechnicalSpecificationsComponent,
    AccountingEventsComponent,
    ShortTermContractsMediasComponent,
    InvoiceLinesComponent,
    DepositsComponent,
    FacturationLinksComponent,
    ThirdPartyLinksComponent,
    LinkDamageHistoricalOperationsComponent,
    CircumstanceLinksComponent,
    FinancialElementsLinksComponent,
    LinkDamageValueComponent,
    KindsLinksComponent,
    CrashLinksComponent,
    RightColComponent,
    SubApiComponent,
    LpModalWorkflowEditComponent,
    LpModalEdiEditComponent,
    iHubCheckPlusSettingsComponent,
    LinesComponent
  ]
})


export class AppModuleDetailForm {
}

