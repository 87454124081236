import { Component, OnInit } from '@angular/core';
import { LpInvoice } from '../../meta/invoice';
import { LpAddress } from '../../meta/address';
import { Subscription } from 'rxjs';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-invoices',
  templateUrl: './invoices.component.html',
})
export class InvoicesComponent  extends RootformComponent implements OnInit {

  public data: LpInvoice = null;
  public ky: string = null;
  public refreshZoomSubscription: Subscription;
  public accountingTypes: String = this.configService.get('accountingTypes');

  public async ngOnInit(): Promise<void> {
    await super.build('invoices', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
    this.refreshZoomSubscription = this.zoomService.selectItemFromZoomEvtEmitter.subscribe((result: Object) => {
      // Lorsque l'utilisateur selectionne un tiers pour la facturation, on réinitialise le champs addresse.
      if (result['idZoom'] === 'thirdPartiesInvoice') {
        this.data.mainAddress = new LpAddress();
        this.zoomService.reInitZoom('mainAddress');
      }
    });
  }

  public updateMainAddress(): void {
    this.data.mainAddress = new LpAddress();
    this.zoomService.reInitZoom('mainAddress');
  }
}
