import { LpThirdParty } from './third-party';
import { LpMeta } from './meta';
import { Type } from 'class-transformer';

export class LpAdditionalDriver extends LpMeta {

    public index: Number;
    @Type(() => LpThirdParty)
    public driverToCreate: LpThirdParty =  new LpThirdParty();

    protected _unoptimizedKeys: String[] = [ 'driverToCreate'];
}
