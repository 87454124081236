import {Component, OnInit} from '@angular/core';
import {LpModel} from '../../meta/model';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-models',
  templateUrl: './models.component.html'
})
export class ModelsComponent extends RootformComponent implements OnInit {

  public data: LpModel = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('models', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
