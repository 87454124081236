import { ApplicationItemDetail } from './application-item-detail';
import { LpMeta } from 'app/meta/meta';

export class RootFormDetail {
    public id: Number;
    public active: Boolean;
    public icon: String;
    public label: String;
    public path: String;
    public data: LpMeta;
    public dataRootFormdetail: LpMeta;
    public item: ApplicationItemDetail;
    public count: Number;
    public hasMetadata: boolean;
    public summaryEval: string;
    public pagination: Boolean;

    constructor(id: Number, icon: String, label: String, path?: String, active?: Boolean, item?: ApplicationItemDetail) {
        this.id = id;
        this.icon = icon;
        this.label = label;
        this.active = false;
        if (active) {
            this.active = active;
        }
        if (path) {
            this.path = path;
        }
        if (item) {
            this.item = item;
        }
    }
}

export class RootFormDetails {
    public details: RootFormDetail[] = [];
    constructor() {
    }

    public add(id: Number, icon: String, label: String, path?: String, active?: Boolean, item?: ApplicationItemDetail): void {
        this.details[id.toString()] = new RootFormDetail(id, icon, label, path, active, item);
    }

    public get(id?: String): RootFormDetail[] {
        if ( !id) {
            return this.details;
        }
        return this.details[id.toString()];
    }

    public getKeys(): string[] {
        return Object.keys(this.details);
    }

    public getList(): RootFormDetail[] {
        return this.details;
    }

    public setRootFormDetail(id: String, prop: String, value: any): void {
        if (this.details && this.details[id.toString()]) {
            this.details[id.toString()][prop.toString()] = value;
        }
    }
}
