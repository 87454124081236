import { Component, OnInit } from '@angular/core';
import { LpMeta } from 'app/meta/meta';
import { LpShortTermContract } from 'app/meta/short-term-contract';
import { PaginatedData } from 'app/models/paginated-data';
import { isUndefined } from 'lodash';
import * as _ from 'underscore';
import { Util } from 'app/statics/utils';
import { RootformComponent } from '../rootform/rootform.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'lp-quick-quote',
  templateUrl: './quick-quote.component.html',
})
export class QuickQuoteComponent extends RootformComponent implements OnInit {

  public ky: string = this.configService.get('newdataKeyWord');
  public topBanner: boolean = false;
  public loadingIndicator: boolean = false;

  public async ngOnInit(): Promise<void> {
    this.ihmStackService.ShowSubAPIDetailsTable = false;
    await super.build('quick-quotes', this.configService.get('newdataKeyWord'));
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined) {
      this.formStackService.CurrentApplicationItemDetailData = null;
      this.data = this.formStackService.currentData;
      this.ihmStackService.displaySubAPIDetails();
      this.rightColService.currentApplicationItemDetailTitle = this.translate
        .instant(this.formStackService.currentApplicationItemDetail.title.toString());
    }
    this.topBanner = true;
  }

  public async filter(): Promise<void> {
    this.uiSyncService.loader(true, false, this.translate.instant(this.translate.instant('loading.data')));
    this.loadingIndicator = true;
    let url: string = this.buildBestDealUrl();
    let p: PaginatedData = await this.alocproProvider.getPaginatedData(url, null, null, null, null, 'ASC', 'category');
    this.formStackService.CurrentApplicationItemDetailData = p.body;
    this.ihmStackService.ShowSubAPIDetailsTable = true;
    this.ihmStackService.displaySubAPIDetails();
    this.uiSyncService.loader(false);
    this.loadingIndicator = false;
    return;
  }

  public async handleQuote(lineIndex: number): Promise<void> {
    let t: LpShortTermContract = new LpShortTermContract();
    if (!Util.isNullOrUndefined(this.data['startLocation'])) {
      t.plannedReturnDate = this.data['returnDate'];
    }
    if (!Util.isNullOrUndefined(this.data['startLocation'])) {
      t.startDate = this.data['startDate'];
    }
    if (!Util.isNullOrUndefined(this.data['startLocation'])) {
      t.category = this.data['category'];
    }
    if (!Util.isNullOrUndefined(this.data['startLocation'])) {
      t.startLocation = this.data['startLocation'];
    }
    if (!Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData[lineIndex]['pricing'])) {
      t.pricing = this.formStackService.CurrentApplicationItemDetailData[lineIndex]['pricing'];
    }
    if (!Util.isNullOrUndefined(this.data['startLocation'])) {
      t.wantedMileage = this.data['wantedMileage'];
    }
    if (!Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData[lineIndex]['pricingMethod'])) {
      t.pricingMethod = this.formStackService.CurrentApplicationItemDetailData[lineIndex]['pricingMethod'];
    }
    if (!Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData[lineIndex]['category'])) {
      t.category = this.formStackService.CurrentApplicationItemDetailData[lineIndex]['category'];
    }
    const s: DateTime = DateTime.fromJSDate(new Date(t.startDate));
    const p:  DateTime = DateTime.fromJSDate(new Date(t.plannedReturnDate));
    t.wantedDays = Math.ceil(p.diff(s, 'days').toObject().days);
    if (t.wantedDays === 0) {
      t.wantedDays = 1;
    }
    this.formStackService.currentData = (t);
    await this.formStackService.addStackIem(t, null, 'quick-quotes', this.configService.get('newdataKeyWord'), true);
    await this.routerService.navigate('short-term-contracts', this.configService.get('newdataKeyWord'));
  }

  private buildBestDealUrl(): string {
    let url: string = '';
    let baseUrl: string = '/equipments/availabilities?';
    for (let key of Object.keys(this.data)) {
      if (key.indexOf('_') === -1) {
        if ((this.data[key] instanceof LpMeta && !Util.isNullOrUndefined(this.data[key]['id'])) || (!Util.isNullOrUndefined(this.data[key])
                                                                                               && !Util.isNullOrUndefined(this.data[key]['id']))) {
          url += `${ key }=${ this.data[key]['id'] }&`;
        } else if (!Util.isNullOrUndefined(this.data[key]) && isUndefined(this.data[key]['id'])) {
          url += `${ key }=${ this.data[key] }&`;
        }
      }
    }
    return baseUrl + url;
  }
}
