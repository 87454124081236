import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Injectable({
    providedIn: 'root'
  })
export class PagesGuardService  {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return true
    }

    public canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        ): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateChild(route, state);
    }

}
