import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Util } from 'app/statics/utils';
import { GoogleAlalyticsService } from './external-services/googleAnalytics.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { UiSyncService } from './ui-sync.service';

/**
 * Router service encapsule le service natif angular de routing
 * permet également d'ajouter du try catch si la naviguation pète
 *
 */

export const ROOT: string = '/'
export const LOGIN: string = 'login';
export const NOT_FOUND: string = '404';
export const INSPECTION: string = 'inspections'
export const STACK_PLANNING: string = 'planning/planning';
export const PLANNING: string = '/planning';

@Injectable({
    providedIn: 'root'
  })
export class RouterService {

    constructor(
        public router: Router, 
        public log: LoggerService, 
        private uiSyncService: UiSyncService,
        private googleAnalyticsService: GoogleAlalyticsService,
        private modalService: ModalService) {
        /*this.router.relativeLinkResolution = "legacy"; ANGULAR15 */
    }

    public async navigate(verb: String,  ky?: String): Promise<any> {
        this.modalService.closeAllModal();
        if (!Util.isNullOrUndefined(verb)) {
            let url: string = `${verb}`;
            if (!Util.isNullOrUndefined(ky)) {
                url = `${url}/${ky}`;
            }
            this.uiSyncService.IsReadyToDisplayForm = false;
            this.uiSyncService.IsKyForm = false;
            this.uiSyncService.IsMainZoom = false;
            this.uiSyncService.IsNewForm = false;
            return await this.router.navigateByUrl(url).then(async(success: boolean) => {
                await this.googleAnalyticsService.logEvents(url, {});
                if (!success) {
                    this.log.error('naviguation failed');
                }
            }).catch((e) => this.log.error('naviguation failed', e));
        }
    }

    public getUrl(): string {
        return this.router.url;
    }
}
