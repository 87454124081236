import { Injectable } from '@angular/core';
import { HttpProvider } from 'app/providers/http.provider';
import { ConfigService } from './config.service';
import { FileService } from './file.service';

@Injectable({
    providedIn: 'root'
  })
export class DownloadService {

    constructor(protected http: HttpProvider, protected fileService: FileService,
        private configService: ConfigService){}
        
    public async download(base64: Uint8Array, type: string, name: string): Promise<any> {
        try {
            let blob: Blob = new Blob([base64], {
            type: undefined
            });

            let a: HTMLAnchorElement = document.createElement('a');
            document.body.appendChild(a);
            let url: String = window.URL.createObjectURL(blob);
            a.href = url.toString();
            a.download = `${name}.${this.configService.get('iconTypeMime')[type].type}`;
            a.click();
            window.URL.revokeObjectURL(url.toString());
        } catch (error) {
            throw error;
        }
    }

   
}
