import { LpMeta, propertyMeta, RELATION_SON, RELATION_FATHER } from './meta';
import { LpEquipment } from './equipment';
import { LpModel } from './model';
import { LpEquipmentLinkType } from './equipment-link-type';
import { Type } from 'class-transformer';

export class LpEquipmentsRelation extends LpMeta {
    @propertyMeta('String')
    public _relationSwitch: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => LpEquipment)
    public father: LpEquipment = new LpEquipment();
    @Type(() => LpEquipment)
    public son: LpEquipment = new LpEquipment();
    @propertyMeta('date')
    public dateStart: Date;
    @propertyMeta('date')
    public dateEnd: Date;
    @propertyMeta('number')
    public numberOfRent: Number = 0;
    @propertyMeta('string')
    public divers1: String;
    @propertyMeta('string')
    public originId: String;
    @propertyMeta('string')
    public divers2: String;
    @Type(() => LpModel)
    public sonModel: LpModel = new LpModel();
    @Type(() => LpEquipmentLinkType)
    public equipmentLinkType: LpEquipmentLinkType = new LpEquipmentLinkType();

    public assign(data: any): LpMeta {
      if (data.son.id === data.originId) {
        data._relationSwitch = RELATION_SON;
      } if (data.father.id === data.originId) {
        data._relationSwitch = RELATION_FATHER;
      }
      return super.assign(data);
    }

    public renew(): void {
        super.renew();
        this._relationSwitch = null;
    }
}
