  
<div [class.modalError]="data.isError">
  <p *ngIf="data.title && data.title !==''" [class.titleError]="data.isError" class="titleModal text-center m-0">{{data.title}}</p>  
  
  <div *ngIf="data.message && data.message !==''" class="text-left">
    <p class="m-3">{{data.message}}</p>      
    <hr class="mt-0 mb-0 ms-3 me-3"/>
  </div>



  <div class="row w-100 m-0">
    <div class="col text-end p-3 m-0">
      <button type="button" [class.btn-primary]="!data.isError" [class.btn-secondary]="data.isError" class="modalCloseBtn btn  me-3" id="" *ngIf="!data.action" (click)="close()">{{'general.button.ok' | translate}}</button>
      <button type="button" class="modalCloseBtn btn btn-default me-3" *ngIf="data.action" (click)="close()">{{'general.button.no' | translate}}</button>
      <button type="button" [class.btn-primary]="!data.isError" [class.btn-secondary]="data.isError" class="modalCloseBtn btn" *ngIf="data.action" [mat-dialog-close]="true">{{'general.button.yes' | translate}}</button>
    </div>
  </div>
</div>