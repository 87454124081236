<div #footer>
  <a *ngIf="exportButton" class="btn btn-sm btn-primary mt-0 mb-1 me-2 btn-export" (click)="exportEmit()">
    <i class="fa fa-download"></i>
    <span> {{'general.button.export' | translate}}</span>
  </a>
  
  <select name="pageSize" [(ngModel)]="linesPerPage" class="mt-1" size="1" (change)="changeLinesPerpage()">
    <option *ngFor="let item of availablesLinesPerPage" value="{{item.id}}">{{item.wording | translate }}</option>
  </select>
  <label class="ps-2 mt-1 ps-2 form-control-label query-footer-select-label">
    <span *ngIf="!smallWidth">{{ 'general.entries' | translate }} <span *ngIf="dataCount > 0">{{ 'general.of' | translate | lowercase }} {{ dataCount }}</span></span> 
    <span *ngIf="smallWidth"><span *ngIf="dataCount > 0">/ {{ dataCount }}</span></span>     
  </label>
  
  <datatable-pager 
    #pager
    class="pt-1 query-footer-datatable-pager pull-right"
    [pagerLeftArrowIcon]="'datatable-icon-left'"
    [pagerRightArrowIcon]="'datatable-icon-right'"
    [pagerPreviousIcon]="'datatable-icon-prev'"
    [pagerNextIcon]="'datatable-icon-skip'" 
    [page]="currentPage" [size]="linesPerPage" [count]="dataCount" (change)="changePageEmit($event)"></datatable-pager>
  
  <button *ngIf="showAddRow" (click)="addRowEmit($event)" class="btn btn-sm btn-primary pull-right mt-1">{{'ApplicationItemDetails.bulk.addRow' | translate}}</button>   
</div>
