import { Component, Injectable, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Theme } from "app/models/theme";
import { RepositoryProvider } from "app/providers/repository.provider";
import { ConfigService } from "app/services/config.service";
import { ModalService } from "app/services/modal.service";
import { LpModalConfirmComponent } from "../../lp-modal-confim/lp-modal-confirm.component";


@Component({
  selector: 'lp-modal-customization-theme',
  templateUrl: './lp-modal-customization-theme.component.html'
})
@Injectable()
export class LpModalCustomizationThemeComponent implements OnInit {
  public arrayClassesElements: Map<String, String> = new Map<String, String>();

  @ViewChild('themePrimaryColorTemplate', { static: true }) public themePrimaryColorTemplate: TemplateRef<any>;
  @ViewChild('themeAvailableTemplate', { static: true }) public themeAvailableTemplate: TemplateRef<any>;
  @ViewChild('themeActions', { static: true }) public themeActions: TemplateRef<any>;

  public configTab: String ='';
  public configLoader: Boolean = true;
  public themesRows: Theme[];
  public themesColumns: any;
  public data: Theme = new Theme();
  public showForm: Boolean = false;

  constructor(
    private repositoryProvider: RepositoryProvider,
    private configService: ConfigService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {
    this.configLoader = true;
  }

  public async ngOnInit(): Promise<void> {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);

    this.themesColumns = [
      { prop: 'description', name: this.translate.instant('customization.config.themesform.description') },
      { prop: 'name', name: this.translate.instant('customization.config.themesform.name') },
      { prop: 'btnClass', name: this.translate.instant('customization.config.themesform.btnClass') },
      { prop: 'primaryColor', name: this.translate.instant('customization.config.themesform.primaryColor'), cellTemplate: this.themePrimaryColorTemplate, maxWidth:150 },
      { prop: 'available', name: this.translate.instant('customization.config.themesform.available'), cellTemplate: this.themeAvailableTemplate, maxWidth:100, cellClass:"text-center" },
      { prop: 'action', name: '', cellTemplate: this.themeActions, maxWidth: 75 }
    ];
    
    this.themesRows = await this.repositoryProvider.get('themes',null, null,true);    
    this.configLoader = false;
  }

  public changeThemeAvailability(event, theme: Theme): void {
    theme.available = event;
    this.repositoryProvider.put("themes", theme.id, theme).then((result) => {
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));  
    });
  }

  public add() {
    this.showForm = false;
    this.data = new Theme();
    this.showForm = true;      
  }

  public modify(row: Theme): void {
    this.showForm = false;
    setTimeout(() => {
      this.data = row;
      this.showForm = true;      
    }, 0);
  }

  public async delete(row: Theme) {     
    const result: boolean = await this.modalService.modalPromise(LpModalConfirmComponent, {
      backdropClass: 'alertBackdropClass',
      id : 'modalWithHeader'
    });
    if (result) {
      this.repositoryProvider.delete("themes", row).then(() => {
        this.refreshAfterAction()
      });
    }
  }

  public submit() {
    if (this.data.id !== undefined ) {      
      this.repositoryProvider.put("themes", this.data.id, this.data).then((result) => {
        this.refreshAfterAction()
      });
    } else {     
      this.repositoryProvider.post("themes", this.data).then((result) => {
        this.refreshAfterAction();
      });
    }
  }

  public refreshAfterAction() {
    this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));        
    this.configLoader = true;
    this.themesRows = null;
    this.showForm = false;
    this.data = new Theme();
    this.repositoryProvider.get('themes',null, null,true).then((result) => {   
      this.themesRows = result;
      this.configLoader = false;
    });
  }
}
