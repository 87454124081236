import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SchedulerLink } from '../../meta/scheduler/scheduler-link';
import { Subscription } from 'rxjs';
import { Util } from 'app/statics/utils';
import { FieldDetailsParameters } from 'app/models/field-details-parameters';
import { ZoomService } from 'app/services/zoom.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { FieldDetailsService } from 'app/services/fieldDetails.service';

@Component({
  selector: 'lp-field-details',
  templateUrl: './lp-field-details.component.html'
})
export class LpFieldDetailsComponent implements OnInit, OnDestroy {
  /* use fo scheduler */
  @Input() public labelClass: String;
  @Input() public valueClass: String;
  @Input() public showCloseBtn: Boolean = true;
  @Input() public followLinks: Array<SchedulerLink>;

  public show: Boolean = false;
  public title: String;
  public params: FieldDetailsParameters;
  private subscriptionFieldDetails: Subscription;

  constructor(
    public detailService: FieldDetailsService,
    public zoomService: ZoomService,
    public formStackService: FormStackService,
    private ihmService: IhmStackService) {}

  public isArray(array: Array<any>): Boolean {
    return Array.isArray(array);
  }

  public async ngOnInit(): Promise<void> {
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscriptionFieldDetails)) {
      this.subscriptionFieldDetails.unsubscribe();
    }
  }

  public closeModal(): void {
    this.ihmService.displaySubAPIDetails(this.formStackService.CurrentKy);
    this.show = false;
  }
}
