import { Component, Input } from '@angular/core';
import { ValueFieldComponent } from '../value-field.component';
import { UiSyncService } from '../../../../../../../services/ui-sync.service';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-image-field',
  templateUrl: './image-field.component.html',
})
export class ImageFieldComponent extends ValueFieldComponent {

  /**
   * Le paramètreURl contient l'URL à afficher
   */
  @Input() public url: String;

  @Input() public zoom: Boolean = false;

  constructor(protected uiSyncService: UiSyncService, public formStackService: FormStackService, protected changeService: ChangeService,
    protected evalService: EvalService, protected formMetadataProvider: FormMetadataProvider, public metaFactoryService: MetaFactoryService, protected configService: ConfigService) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
   }

  public displayZoom(): void {
    this.uiSyncService.displayZoomImage(this.url);
  }

}
