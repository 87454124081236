import { Injectable } from '@angular/core';
import { ApplicationItemDetail } from 'app/models/application-item-detail';

@Injectable()
export class ExampleApplicationItemDetailsProvider {

    private applicationItemDetail: Array<ApplicationItemDetail> = [{
      '_ready': null,
      '_count': 2,
        'enable': null,
        'externalAddFormPath': null,
        'pagination': null,
        'bulk': null,
        'externalFilledForm': false,
        'hasMetadata': null,
        'availableEval': 'return true',
        'data': null,
        'path': 'technical/supervision/ping',
        'pathOnRepository': false,
        'childZoom': null,
        'summaryEval': null,
        'active': true,
        'label': 'examples.examples',
        'TableSummaryPosition': 'bottom',
        'key': 'examples',
        'title': 'title',
        'externalAddForm': false,
        'isCustom': false,
        'externalModifyForm': false,
        'externalModifyFormPath': 'damages/{{thirdPartyLink.id}}',
        'editFormId': 'examples',
        'useJsonEditForm': true,
        'queryParams':[{
          "method": "POST",
          "key": "addService",
          "value": "test" 
        }],
        'exportable': false,
        'editForm': {
          'id': 'thirdPartyLinks',
          'active': true,
          'title': 'metadataApplicationItemDetails.examples.title',
          'availableEval': 'return true',
          'fields': [
          {
            'prop': 'wording',
            'label': 'examples.wording',
            'readonly': false,
            'mandatory': false,
            'defaultValue': '',
            'defaultValueEval': '',
            'afterBindEval': '',
            'type': 'text',
            'params': {}
          },
          {
            'prop': 'details',
            'label': 'examples.details',
            'readonly': false,
            'mandatory': false,
            'defaultValue': '',
            'defaultValueEval': '',
            'afterBindEval': '',
            'type': 'text',
            'params': {}
          },
          {
            'prop': 'date',
            'label': 'examples.date',
            'readonly': false,
            'mandatory': false,
            'defaultValue': '',
            'defaultValueEval': '',
            'afterBindEval': '',
            'type': 'date',
            'params': {}
          }
        ]
        },
        'editFormActive': false,
        'icon': 'lp-third-parties',
        'add': true,
        'modify': true,
        'delete': true,
        'TableSummaryRow': false,
        'columns': [
          {
            'name': 'id',
            'prop': 'id',
            'type': 1,
            'title': null,
            'width': null,
            'summaryFunc': null,
            'modify': null,
            'active': true,
            'resizeable': null,
            'sortable': null,
            'draggable': null,
            'canAutoResize': null,
            'minWidth': null,
            'isDisplayed': true,
            'inputField': null
          },
          {
            'name': 'details',
            'prop': 'details',
            'type': 1,
            'title': null,
            'width': null,
            'summaryFunc': null,
            'modify': null,
            'active': true,
            'resizeable': null,
            'sortable': null,
            'draggable': null,
            'canAutoResize': null,
            'minWidth': null,
            'isDisplayed': true,
            'inputField': null
          },
          {
            'name': 'date',
            'prop': 'date',
            'type': 5,
            'title': null,
            'width': null,
            'summaryFunc': null,
            'modify': null,
            'active': true,
            'resizeable': null,
            'sortable': null,
            'draggable': null,
            'canAutoResize': null,
            'minWidth': null,
            'isDisplayed': true,
            'inputField': null,
          },
          {
            'inputField': null,
            'name': 'wording',
            'prop': 'wording',
            'type': 1,
            'title': null,
            'width': null,
            'summaryFunc': null,
            'modify': null,
            'active': true,
            'resizeable': null,
            'sortable': null,
            'draggable': null,
            'canAutoResize': null,
            'minWidth': null,
            'isDisplayed': true
          }
        ],
        'id': 30,
        'reportObject': null
      }
    ];

    public getAppicationItemDetails(): Promise<Array<ApplicationItemDetail>> {
        return new Promise((resolve) => {
            resolve(this.applicationItemDetail);
        });
    }

}
