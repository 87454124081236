import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '../../../../../../../services/modal.service';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';
import { ValueFieldComponent } from '../value-field.component';


@Component({
  selector: 'lp-save',
  templateUrl: './save.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SaveComponent),
      multi: true
    }
  ]
})
export class SaveComponent extends ValueFieldComponent implements OnInit {

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  constructor(protected modalService: ModalService,
              protected uiSyncService: UiSyncService,
              protected changeService: ChangeService,
              protected evalService: EvalService,
              protected formMetadataProvider: FormMetadataProvider,
              public formStackService: FormStackService,
              private translateService: TranslateService,
              public metaFactoryService: MetaFactoryService,
              protected configService: ConfigService) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public async pushChange(): Promise<void> {
    try {
      super.pushChange();
    } catch (e) {
      this.modalService.error(this.translateService.instant('general.modalService.updateFailed'));
    }
  }
}
