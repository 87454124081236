import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AlocproProvider } from '../../providers/alocpro.provider';
import { XAxis } from '../../meta/scheduler/xAxis';
import { SchedulerData, SchedulerDate } from '../../meta/scheduler/scheduler-data';
import { SchedulerUIService } from '../../services/scheduler-ui.service';
import { UiSyncService } from '../../services/ui-sync.service';
import { ZoomService } from '../../services/zoom.service';
import { SchedulerFilter } from '../../meta/scheduler/scheduler-filter';
import { FormStackService } from '../../services/form-stack.service';
import { Util } from 'app/statics/utils';
import { DatetimeService } from 'app/services/datetime.service';
import { Subscription } from 'rxjs';
import { PlanningProvider } from 'app/providers/planning.provider';
import { Planning } from 'app/meta/scheduler/planning';
import * as _ from 'underscore';
import { FieldDetailsService } from 'app/services/fieldDetails.service';
import { ChangeService } from 'app/services/change.service';
import { debounceTime } from 'rxjs/operators';

import { LpMeta } from 'app/meta/meta';
import { TranslateService } from '@ngx-translate/core';
import { UserHistoryService } from 'app/services/user-history.service';


const DEBOUNCETIME: number = 1;
const NB_DAYS_TO_DISPLAY: number = 31;
@Component({
  selector: 'lp-planning',
  templateUrl: './planning.component.html',
})
export class PlanningComponent implements OnInit, OnDestroy {
  public isLoaded: Boolean = false;
  public startDate: String = null;
  public filter: LpMeta = new SchedulerFilter();
  public activeFilter: Array<Array<String>>;
  public showFilterPanel: Boolean = false;
  public displaySchedulerAfterDataRefresh: Boolean = false;
  public showMovementPanel: Boolean = false;
  public filterPlanningBtn: Boolean = false;
  public xAxis: Array<XAxis> = [];
  public data: Array<SchedulerData> = [];
  @Output() public date: EventEmitter<SchedulerDate> = new EventEmitter();
  private clear: Subscription;
  private subscription: Subscription;
  private arrayClassesElements: Map<String, String> = new Map<String, String>();
  private selectEventZoom: Subscription;
  private displayZoom: Subscription;
  private closeZoom: Subscription;
  public showZoom: boolean = true;

  constructor(public alocproProvider: AlocproProvider,
              public schedulerUIService: SchedulerUIService,
              public uiSyncService: UiSyncService,
              public planningProvider: PlanningProvider,
              protected zoomService: ZoomService,
              private formStackService: FormStackService,
              private datetimeService: DatetimeService,
              public detailService: FieldDetailsService,
              private changeService: ChangeService,
              private userHstoryService: UserHistoryService, 
              private translate: TranslateService) {}

  public async ngOnInit(): Promise<void> {
    if (this.formStackService.isCurrentDataPreloaded) {
      this.formStackService.isCurrentDataPreloaded = false;
    }
    this.arrayClassesElements.set('defaultLabel', 'control-label input-smcol-md-5  col-xl-3');
    this.arrayClassesElements.set('defaultInput', 'col-md-10 col-lg-8 col-xl-5');
    this.arrayClassesElements.set('defaultPostcode', 'col-md-4 col-lg-2 col-xl-2 pe-3');
    this.arrayClassesElements.set('defaultPlanningSettingsInput', 'col-md-10 col-lg-8 col-xl-8');
    this.schedulerUIService.loader = true;
    this.formStackService.CurrentVerb = 'planning';
    this.filter = this.formStackService.currentData;
    await this.formStackService.initApplicationItem(this.formStackService.CurrentVerb);
    this.formStackService.initVisualSettings();
    this.init();
    this.schedulerUIService.date.pipe(debounceTime(DEBOUNCETIME)).subscribe((date: SchedulerDate) => {
      this.schedulerUIService.DateDisplayed.date = date;
      this.updateDataDateChanged(date);
    });
    this.date.pipe(debounceTime(DEBOUNCETIME)).subscribe((f: SchedulerFilter) => {
      this.applyFilter(f);
    });
    this.schedulerUIService.refresh.subscribe((refresh: boolean) => {
      this.schedulerUIService.loader = true;
      this.displaySchedulerAfterDataRefresh = false;
      //this.toggleFilterPlanning();
    });
    this.clear = this.schedulerUIService.clearCache.subscribe(() => {
      this.reInitAll();
    });
    this.closeZoom = this.zoomService.closeEventEmitter.subscribe(() => {
      this.showZoom = false;
    });
    this.selectEventZoom = this.zoomService.selectEventEmitter.subscribe( async(row: Object) =>{
      this.showZoom = false;
    });
    this.displayZoom = this.uiSyncService.displayZoomEvtEmitter.subscribe( async(row: Object) =>{
      this.showZoom = true;
    });
    this.uiSyncService.loader(false);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.clear) {
      this.clear.unsubscribe();
    }
    if (this.displayZoom) {
      this.displayZoom.unsubscribe();
    }
    if (this.selectEventZoom) {
      this.selectEventZoom.unsubscribe();
    }
    if (this.closeZoom) {
      this.closeZoom.unsubscribe();
    }
  }

  public async init(): Promise<void> {
    this.formStackService.currentData =  new SchedulerFilter();
    this.isLoaded = false;
    this.formStackService.clearStack();
    this.changeService.initChange();
    this.filter = await this.schedulerUIService.getFilter();
    this.activeFilter = await this.schedulerUIService.getActiveFilter();
    const obj: SchedulerDate = this.getDate();
    this.startDate = obj.startDate;
    this.getData(this.schedulerUIService.getFilterRequestString(obj)).then( d => {
      this.isLoaded = true;
      this.schedulerUIService.loader = false;
      this.uiSyncService.loader(false);
      this.displaySchedulerAfterDataRefresh = true;
      this.uiSyncService.loadObject();
      this.userHstoryService.addEvent('show', this.formStackService.currentApplicationItem, null, 'planning', this.translate.instant('userHistory.showplanning'));
    });
  }

  public filterPlanning(f: SchedulerFilter): void {
    this.date.emit(f);
  }

  public isFilterActive(): Boolean {
    return this.schedulerUIService.hasActiveFilter();
  }

  public async resetFilter(filter?: Array<string>): Promise<void> {
    this.schedulerUIService.loader = true;
    if(Util.isNullOrUndefined(filter) || filter.length === 0) {
      this.filter.renew();
    } else {
      this.filter[filter[2]].renew();
    }
    this.filter.assign(await this.schedulerUIService.getDefaultFilter());
    this.filterPlanning(this.filter as SchedulerFilter);
  }

  public toggleFilterPlanning(): void {
    if (!Util.isNullOrUndefined(event)) {
      event.preventDefault();
    }
    if (!this.schedulerUIService.getFilterPanelState()) {
      this.showFilterPanel = true;
    } else {
      this.showFilterPanel = false;
    }
    this.schedulerUIService.setFilterPanelState(!!this.showFilterPanel);
    if (this.schedulerUIService.getConfigPanelState()) {
      this.schedulerUIService.setConfigPanelState(false);
    }
    if (this.schedulerUIService.getMovementDetailPanelState()) {
      this.showMovementPanel = false;
      this.schedulerUIService.setMovementDetailPanelState(false);
    }
    this.uiSyncService.loadObject();
  }

  public toggleConfigPlanning(configStatePanel: boolean): void {
    event.preventDefault();
    this.showMovementPanel = configStatePanel;
    if (configStatePanel) {
      this.showFilterPanel = false;
      this.schedulerUIService.setFilterPanelState(!!this.showFilterPanel);
    }
    this.showFilterPanel = this.schedulerUIService.getFilterPanelState();
  }

  public async updateDataDateChanged(d: SchedulerDate): Promise<void> {
    this.startDate = d.startDate;
    this.isLoaded = false;
    let params: Map<string, string> = new Map();
    params = this.schedulerUIService.getFilterRequestString(d)
    // this.activeFilter = this.schedulerUIService.Filter.getActiveFilter(this.formStackService.currentData);
    this.activeFilter = await this.schedulerUIService.getActiveFilter();
    //if (!this.planningProvider.isAlreadyCached(d)) {
      this.getData(params).then( () => {
          this.uiSyncService.loadObject();
          this.isLoaded = true;
          this.schedulerUIService.loader = false;
          this.displaySchedulerAfterDataRefresh = true;
      });
    /*} else {
      this.schedulerUIService.loader = false;
      this.uiSyncService.loadObject();
      this.isLoaded = true;
      this.displaySchedulerAfterDataRefresh = true;
      const planning: Planning = new Planning();
      planning.movements = this.data;
      planning.equipments = this.xAxis;
      this.loadPrevNextRange(params, planning);
    }*/
    this.schedulerUIService.setFilterPanelState(false);
    this.showFilterPanel = this.schedulerUIService.getFilterPanelState();
    this.showMovementPanel = this.schedulerUIService.getMovementDetailPanelState();
  }

  public reInitAll(): void {
    this.uiSyncService.loader(true, false, this.translate.instant('loading.scheduler'));
    this.data = [];
    this.xAxis = []
    this.planningProvider.maxDate = null;
    this.planningProvider.minDate = null;
    /*this.schedulerUIService.DateDisplayed.date.endDate = null;
    this.schedulerUIService.DateDisplayed.date.startDate = null;*/
    this.init();
  }

  /*private getData(searchedMap: Map<string, string>): Promise<void> {
    let tempData: Array<SchedulerData> = [];
    let tempXaxis: Array<XAxis> = [];
    return new Promise<void>((resolve) => {
      this.alocproProvider.getPaginatedData('equipments/plannings', null, searchedMap).then((data : PaginatedData) => {
        if (data.body) {
          for (let datum of data.body) {
            let schedulerEquipment: SchedulerEquipment = new SchedulerEquipment();
            Object.assign(schedulerEquipment, datum);
            tempXaxis.push(schedulerEquipment.serializeForScheduler());
            for (let movement of schedulerEquipment.movements) {
              let schedulerMovement: SchedulerMovement = new SchedulerMovement();
              Object.assign(schedulerMovement, movement)
              tempData.push(schedulerMovement.serializeForScheduler(schedulerEquipment.id));
            }
          }
        }
        this.xAxis = tempXaxis;
        this.data = tempData;
        resolve();
      });
    });
  }*/

  private async getData(searchedMap: Map<string, string>): Promise<void> {
    try{  
    let data: Planning = await this.planningProvider.getData(searchedMap);
      if (data) {
        this.xAxis = data.equipments;
        if (this.data.length === 0) {
          this.data = data.movements;
        } else {
          // this.data = this.data.concat(data.movements);
          this.data = this.data.concat(this.addMissingMovement(this.data, data.movements));
        }
      }
      this.formStackService.currentData = data;
      this.loadPrevNextRange(searchedMap, data);
      return;
    } catch (e) {
      this.uiSyncService.loader(false);
    }
  }


  private getDate(): SchedulerDate {
    let obj: SchedulerDate;
    if (Util.isNullOrUndefined(this.schedulerUIService.schedulerConfig.bufferBefore)
    || Util.isNullOrUndefined(this.schedulerUIService.schedulerConfig.bufferBefore)) {
      this.schedulerUIService.schedulerConfig.bufferBefore = 0;
    }
    if (Util.isNullOrUndefined(this.schedulerUIService.DateDisplayed.date.startDate)) {
      let now: Date = this.datetimeService.newDate();
      obj = {
        startDate: this.datetimeService.dateDBFormatComplete(
          this.datetimeService.subtract(now.toString(),
          this.schedulerUIService.schedulerConfig.bufferBefore, 'days')
        ),
        endDate: this.datetimeService.dateDBFormatComplete(
          this.datetimeService.add(now.toString(), NB_DAYS_TO_DISPLAY - this.schedulerUIService.schedulerConfig.bufferBefore, 'days')
        )
      }
    } else {
      obj = this.schedulerUIService.DateDisplayed.date;
    }
    return obj
  }

  private loadPrevNextRange(searchedMap: Map<string, string>, data: Planning): void {
    let isPrev: boolean;
    for (let i: number = 1; i <= 2; i++) {
      isPrev = i % 2 === 1;
      this.planningProvider.preloadData(searchedMap, isPrev).then((d: Planning) => {
        if (d) {
          d.movements.forEach((m: SchedulerData) => {
            data.movements.push(m);
          });
        }
        this.data = data.movements;
      });
    }
  }

  private async applyFilter(f: SchedulerFilter): Promise<void> {
    this.schedulerUIService.loader = true;
    let params: Map<string, string> = new Map();
    Object.assign(this.filter, f);
    params = this.schedulerUIService.getFilterRequestString(this.schedulerUIService.DateDisplayed.date);  
    this.activeFilter = await this.schedulerUIService.getActiveFilter();
    this.getData(params).then( d => {
      this.uiSyncService.loadObject();
      this.schedulerUIService.loader = false;
    });
  }

  private addMissingMovement(data: Array<SchedulerData>, dataHttp: Array<SchedulerData>): Array<SchedulerData> {
    let diffArray:  Array<SchedulerData> = []
    dataHttp.forEach((dHttp: SchedulerData) => {
      let add: boolean = true;
      for(let d of data){
        if (d.object.id === dHttp.object.id) {
          add = false;
          break;
        }
      }
      if (add) {
        diffArray.push(dHttp);
      }
    });
    return diffArray;
  }
}
