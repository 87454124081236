import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, ADDRESSES, DRIVER_ADDRESSES, CUSTOMER_ADDRESSES } from '../detail-forms.component';
import { LpAddress } from 'app/meta/address';

@Component({
  selector: 'lp-addresses-detail-form',
  templateUrl: './addresses-detail-form.component.html'
})
export class AddressesDetailFormComponent extends DetailFormsComponent implements OnInit {

  public data: LpAddress = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    if (this.formStackService.currentApplicationItemDetail.key === DRIVER_ADDRESSES) {
      this.setId(DRIVER_ADDRESSES);
      this.object = DRIVER_ADDRESSES;
    } else if (this.formStackService.currentApplicationItemDetail.key === CUSTOMER_ADDRESSES) {
      this.setId(CUSTOMER_ADDRESSES);
      this.object = CUSTOMER_ADDRESSES
    } else {
      this.setId(ADDRESSES);
      this.object = ADDRESSES;
    }
    this.data.assign(this.formStackService.currentDetailLineData);
    this.initUrl();
    // this.init();
  }

public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

}
