import { Component, OnInit, Input, Output } from '@angular/core';
import { InputFieldComponent } from '../input-field.component';
import { ChangeService } from 'app/services/change.service';
import { Module } from 'app/models/user.interface';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { LpMeta } from 'app/meta/meta';
import { Observable } from 'rxjs';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { ColumnsProperty } from 'app/models/breadcrumb-item';
import { CustomLabelsService } from 'app/services/custom-labels.service';
import { Util } from 'app/statics/utils';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { RouterService } from 'app/services/router.service';
import { FormService } from 'app/services/root/form.service';
import { LpModalChangeComponent } from 'app/ui/lp-modal/lp-modal-change/lp-modal-change.component';
import { ModalService } from 'app/services/modal.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ConfigService } from 'app/services/config.service';
import { mergeMap } from 'rxjs/operators';

const QUERYURL: String = '?search=';

@Component({
  selector: 'lp-search-bar',
  templateUrl: './search-bar.component.html',
})
export class SearchBarComponent extends InputFieldComponent implements OnInit {
  @Input() public typeaheadOptionField: String = 'id';
  @Output() public noResult: Boolean = false;
  public modules: Module[] ;
  public inputLetters: String = '';
  public scopeSelected: String;
  public dataSource: Observable<LpMeta[]>;
  public itemId: String;
  public mouseEvt: MouseEvent = new MouseEvent('click');
  public typeaheadLoading: Boolean = false;
  public keys: Array<ColumnsProperty>;
  public breadcrumbSummery: Array<ColumnsProperty>;

  constructor(private routerService: RouterService, protected changeService: ChangeService,
    private alocproProvider: AlocproProvider, protected evalService: EvalService,
     protected formMetadataProvider: FormMetadataProvider, private repositoryProvider: RepositoryProvider,
     private customLabelsService: CustomLabelsService,
     private modalService: ModalService, public formStackService: FormStackService,
     protected uiSyncService: UiSyncService, private formService: FormService,
     protected metaFactoryService: MetaFactoryService, protected configService: ConfigService
     ) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
  }

  public ngOnInit(): void {
    // TODO SUB
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.inputLetters);
    }).pipe(
      mergeMap((token: String) => this.getData(token))
    );

    this.repositoryProvider.getModules().then((result: Module[]) => {
      this.modules = result;
      this.scopeSelected = result[0].verb;
      this.changeOptionField();
    });
  }

  public changeOptionField(): void {
    this.repositoryProvider.getBreadcrumbItem(this.scopeSelected).then((result) => {
      this.keys = result.summary;
    });
  }

  public buildDataToDisplay(data: LpMeta): string {
    let displayedValue: string = '';
    this.keys.forEach((element: ColumnsProperty, i: number) => {
      displayedValue += this.customLabelsService.displayLabel(element, data);
      if (i < (this.keys.length - 1)) {
        displayedValue += ' - ';
      }
    });
    return displayedValue;
  }

  public typeaheadNoResults(event: Boolean): void {
    this.noResult = event;
  }

  public goToScope(): void {
    if (!Util.isNullOrUndefined(this.itemId)) {
      this.inputLetters = this.itemId;
      if (this.changeService.checkIfpreviousChanged(-1)) {
        this.modalService.modalPromise(LpModalChangeComponent, {
          id : 'modalWithHeader'
        }).then(async (result: boolean) => {
          if (result) {
            this.alocproProvider.find(this.scopeSelected, this.itemId).then(async (response) => {
              this.changeService.clearAll()
              await this.routerService.navigate(this.scopeSelected, response.id)
              setTimeout(async () => {
                await this.formService.build(this.scopeSelected.toString(), response.id.toString(), null, false);
                this.formStackService.currentApplicationItemDetail = this.formStackService.currentApplicationItem.details[0];
                this.inputLetters = '';
              })
            });
          }
        });
      } else {
        this.alocproProvider.find(this.scopeSelected, this.itemId).then(async (response) => {
          this.changeService.clearAll()
          await this.routerService.navigate(this.scopeSelected, response.id)
          setTimeout(async () => {
            await this.formService.build(this.scopeSelected.toString(), response.id.toString(), null, false);
            this.formStackService.currentApplicationItemDetail = this.formStackService.currentApplicationItem.details[0];
            this.inputLetters = '';
          })
        });
      }
    }
  }

  public async GoToScopeNew(): Promise<void> {
    if (this.formStackService.CurrentVerb !== this.scopeSelected || this.formStackService.CurrentKy !== this.configService.get('newdataKeyWord')){
      if (!Util.isNullOrUndefined(this.formStackService.currentData)) {
        this.formStackService.currentData.renew();
        this.formStackService.currentData._readOnly = false;
      }
      await this.routerService.navigate(this.scopeSelected, this.configService.get('newdataKeyWord'));
      if(this.formStackService.CurrentVerb === this.scopeSelected && this.formStackService.CurrentKy !== this.configService.get('newdataKeyWord')) {
        await this.formService.build(this.scopeSelected.toString(), this.configService.get('newdataKeyWord'), null, false);
      }
    }
  }


  public onSelect(event: any, mouseEvent: MouseEvent): void {
    if (event instanceof TypeaheadMatch) {
      this.itemId = event.item.id;
    }
    if (mouseEvent.type === 'click') {
      this.goToScope();
    }
  }

  public changeTypeaheadLoading(e: Boolean): void {
    this.typeaheadLoading = e;
  }


  private getData(token: String): Observable<any[]> {
    return this.alocproProvider.getResultFromSearchBar(`${this.scopeSelected}${QUERYURL}${token}`, this.configService.get('linesPerPage'));
  }

}
