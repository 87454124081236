import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LinksService } from 'app/services/links.service';
import { UiSyncService } from 'app/services/ui-sync.service';

@Component({
  selector: 'lp-form-card',
  templateUrl: './lp-form-card.component.html'
})
export class LpFormCardComponent {
    
  @Input() public title: String = '';
  @Input() public description: String = '';
  @Input() public class: String = '';
  @Input() public name: String = '';
  @Input() public id: String = '';
  @Input() public displayActive: boolean = false;
  @Input() public active: boolean = false;
  @Input() public bindingToActive: boolean = false;
  @Input() public objectToSendToEmit: any;
  @Output() public eventClickActive: EventEmitter<Array<void>> = new EventEmitter<Array<void>>();
  @Output() public eventClickPreview: EventEmitter<Array<void>> = new EventEmitter<Array<void>>();

  constructor(
    private uiSyncService: UiSyncService,
    private linksService: LinksService
  ) {
   }

  public emitClickPreview(): void {
    if (this.description && this.description === 'link') {
      this.linksService.previewlinks(this.objectToSendToEmit);
    } else {
      this.uiSyncService.clickPreviewFormCard.emit(this.objectToSendToEmit);
    }
  }

  public emitClickActive(): void {
    this.uiSyncService.clickActiveFormCard.emit(this.objectToSendToEmit);
  }


}
