  
<p class="titleModal text-center m-0 p-3">{{'general.modalService.mailConfirmed' | translate}}</p>  

<div class="text-left p-3">
  <lp-text-field id="accountingEvents.wording" cssClass="form-control" name="accountingEvents.wording"
    [(ngModel)]="mail" [ignoreChange]="true"></lp-text-field>
</div>

<div class="row w-100 m-0">
  <div class="col text-center p-3 m-0">
    <button type="button" class="btn btn-default me-3" (click)="close()">{{'general.button.no' | translate}}</button>
    <button type="button" class="btn btn-primary" [mat-dialog-close]="true" (click)="setReportMail()">{{'general.button.yes' | translate}}</button>
  </div>
</div>