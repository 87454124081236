import { Component, ViewContainerRef, ChangeDetectorRef, Renderer2, ElementRef, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlocproProvider } from '../../../providers/alocpro.provider';
import { UiSyncService } from '../../../services/ui-sync.service';
import { ZoomService } from '../../../services/zoom.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { ModalService } from '../../../services/modal.service';
import { RepositoryProvider } from '../../../providers/repository.provider';
import { DatetimeService } from '../../../services/datetime.service';
import { FormStackService } from '../../../services/form-stack.service';
import { ChangeService } from '../../../services/change.service';
import { FormatService } from 'app/services/format.service';
import { SchedulerUIService } from 'app/services/scheduler-ui.service';
import { MemoProvider } from 'app/providers/memo.provider';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { CustomLabelsService } from '../../../services/custom-labels.service';
import { LpInspection } from 'app/meta/inspection/inspection';
import { LpInspectionChecklist } from 'app/meta/inspection/inspection-checklist';
import { FtMeta } from 'app/meta/ft-meta';
import { Util } from 'app/statics/utils';
import { InspectionProvider } from 'app/providers/inspection.provider';
import { LpInspectionFace } from 'app/meta/inspection/inspection-face';
import { PaginatedData } from 'app/models/paginated-data';
import { Subscription } from 'rxjs';
import { LpInspectionDamage, LpInspectionFaceV2 } from 'app/meta/inspection/inspection-damage';
import { LpInspectionZone } from 'app/meta/inspection/inspection-zone';
import { LpInspectionElementZone } from 'app/meta/inspection/inspection-element-zone';
//import { CommonToolsService } from 'app/services/common-tools.service';
import { LpMemo } from 'app/meta/memo';
import { RouterService } from 'app/services/router.service';
import { LoggerService } from 'app/services/logger.service';
import { FormService } from 'app/services/root/form.service';
import { RootformComponent } from '../../rootform/rootform.component';
import * as _ from 'underscore';
import { LpMeta } from 'app/meta/meta';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { LpModalConfirmComponent } from 'app/ui/lp-modal/lp-modal-confim/lp-modal-confirm.component';
import { MemoComponent } from 'app/FormComponents/memo/memo.component';
import { FileService } from 'app/services/file.service';
import { LpModalEvalComponent } from 'app/ui/lp-modal/lp-modal-eval/lp-modal-eval.component';
import { RightColService } from 'app/services/root/rightCol.service';
import { BulkService } from 'app/services/bulk.service';
import { ConfigService } from 'app/services/config.service';
import { PricingService } from 'app/services/pricing.service';
import { PricingCustomService } from 'app/services/pricing-custom.service';
import { formatDate } from '@angular/common';
import { LpLocation } from 'app/meta/location';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

export const SIGNATURE_CUSTOMER_ATTACHMENTKIND: string = 'SIGNATURE';
export const SIGNATURE_AGENT_ATTACHMENTKIND: string = 'SIGNATURETEC';
const INSPECTIONS: string = 'inspections';
@Component({
  selector: 'lp-inspections',
  templateUrl: './inspections.component.html'
})

export class InspectionsV2Component extends RootformComponent implements OnInit, OnDestroy {

  @ViewChild('carouselFaces') public carouselFaces: ElementRef;
  @ViewChild('damageForm') public damageForm: ElementRef;
  public checkListGroups: FtMeta[] = [];
  public damageCategories: Map<String, any> = new Map<String, FtMeta>();
  public elements: Map<String, any> = new Map<String, any>();
  public showCheckList: Boolean;
  public showCarousel: Boolean;
  public showTableDamage: Boolean = false;
  public tempComment: String;
  public tempPicture: String;
  public data: LpInspection;
  public showRightCol: Boolean = true;
  public responsabilities: Object[] = [];
  public customerSignature: String = "";
  public agentSignature: String ="";
  /**
   * Utilisé pour stocké le niveau de zoom du svg en fonction de la talle de la fenêtre du navigateur.
   */
  public svgRatio: number;
  public showOldDamage: Boolean = true;
  public showNewDamage: Boolean = true;
  public showInactiveDamage: Boolean = false;
  public showDamageList: Boolean = false;
  public allDamages: LpInspectionDamage[];
  public activeSlide: Number = 0;
  public activeSlideTemp: Number;
  /**
   * Contient la zone où sera stocké notre nouveau dommage
   */
  public currentZone: LpInspectionZone;
  /**
   * Contient les infos de notre nouveau dommage
   */
  public currentDamage: LpInspectionDamage;
  public currentDamageValues: FtMeta[];
  public isReady: boolean = false;
  public signatureCustomerMemo: LpMemo = new LpMemo();
  public signatureAgentMemo: LpMemo = new LpMemo();
  public showSignature: Boolean;
  private subscription: Subscription;
  private subscriptionRootformColChanged: Subscription;
  private firstLoad: Boolean = true;

  constructor(
    protected log: LoggerService,
    protected route: ActivatedRoute,
    protected routerService: RouterService,
    public alocproProvider: AlocproProvider,
    protected repositoryProvider: RepositoryProvider,
    public uiSyncService: UiSyncService,
    public vcr: ViewContainerRef,
    protected changeDetectorRef: ChangeDetectorRef,
    public datetimeService: DatetimeService,
    public zoomService: ZoomService,
    protected translate: TranslateService,
    protected renderer: Renderer2,
    public userService: UserService,
    protected modalService: ModalService,
    public formStackService: FormStackService,
    protected formatService: FormatService,
    protected changeService: ChangeService,
    public schedulerUIService: SchedulerUIService,
    public memoProvider: MemoProvider,
    public evalService: EvalService,
    public formService: FormService,
    public formMetadataProvider: FormMetadataProvider,
    //private commonToolsService: CommonToolsService,
    public customLabelsService: CustomLabelsService,
    private inspectionProvider: InspectionProvider,
    public ihmService: IhmStackService,
    public fileService: FileService,
    public righColService: RightColService,
    public bulkService: BulkService,
    public configService: ConfigService,
    protected pricingService: PricingService,
    protected pricingCustomService: PricingCustomService
    ) {
      super(
        log,
        route,
        routerService,
        alocproProvider,
        zoomService,
        repositoryProvider,
        uiSyncService,
        vcr,
        changeDetectorRef,
        translate,
        renderer,
        modalService,
        formStackService,
        changeService,
        formService,
        memoProvider,
        userService,
        evalService,
        ihmService,
        righColService,
        bulkService,
        configService,
        pricingService,
        pricingCustomService);

        this.showCheckList = false;
        this.svgRatio = 1;
        this.subscriptionRootformColChanged = this.uiSyncService.rootformColChangedEvtEmitter.subscribe(
          (parems: Object) => {
           this.refreshPositions();
        });

        this.translate.get('inspections.nc').subscribe((value: String) => {
          this.responsabilities.push({
              id: '0',
              wording: value
            });
        });

        this.translate.get('inspections.customer').subscribe((value: String) => {
          this.responsabilities.push({
              id: '1',
              wording: value
            });
        });

        this.translate.get('inspections.renter').subscribe((value: String) => {
          this.responsabilities .push({
              id: '2',
              wording: value
            });
        });

  }

  public async ngOnInit(): Promise<void> {
    await super.build(INSPECTIONS, this.route.snapshot.params['ky']);

    this.uiSyncService.loader(true, false, this.translate.instant('loading.data'));
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
    
    if (!Util.isNullOrUndefined(this.route.snapshot.params['ky'])) {
      await this.postLoadObject();
    }
    this.isReady = true;
    this.subscriptions();
    this.uiSyncService.loader(false);    
  }

  public  subscriptions(): void {
    this.subscription = this.uiSyncService.loadObjectEvtEmitter.subscribe( async () => {
      if (!Util.isNullOrUndefined(this.route.snapshot.params['ky'])) {
        // Récupération des signatures client / technicien si existante...
        this.alocproProvider.getMemos(
          this.formStackService.currentApplicationItem.linkedObject,
          this.route.snapshot.params['ky'], 1, 1000).then((record: PaginatedData) => {
          if (record.count > 0) {
            let tempSignatureCustomerMemo: LpMeta = _.find(record.body, (m: LpMemo) => {
              return m.attachmentKind.id === SIGNATURE_CUSTOMER_ATTACHMENTKIND;
            });

            let tempSignatureAgentMemo: LpMeta = _.find(record.body, (m: LpMemo) => {
              return m.attachmentKind.id === SIGNATURE_AGENT_ATTACHMENTKIND;
            });

            if (tempSignatureCustomerMemo) {
              this.alocproProvider.getPaginatedData(tempSignatureCustomerMemo.details).then((data: PaginatedData) => {
                  this.signatureCustomerMemo.assign(data.body);

                  if (!tempSignatureAgentMemo) {
                    this.showSignature = true;
                  }
              });
            }

            if (tempSignatureAgentMemo) {
              this.alocproProvider.getPaginatedData(tempSignatureAgentMemo.details).then((data: PaginatedData) => {
                this.signatureAgentMemo.assign(data.body);
                this.showSignature = true;
              });
            }

            if (!tempSignatureAgentMemo && !tempSignatureCustomerMemo) {
              this.showSignature = true;
            }
          } else {
            this.showSignature = true;
          }
        });  
      }
    });
  }
  public getPinHalfWidth(): Number {
     return 16.5;
  }

  public getPinHeight(): Number {
     return 45;
  }
  public ngOnDestroy(): void {
    if (this.subscriptionRootformColChanged) {
      this.subscriptionRootformColChanged.unsubscribe();
    }
    if (this.subscription) { 
      this.subscription.unsubscribe(); 
    }
  }

  public getDamageResponsability(damage: LpInspectionDamage): String {
     let result: String = '';

     if (damage.clientResponsibility === '1') {
        result = 'inspections.customer';
     } else if (damage.clientResponsibility === '2') {
        result = 'inspections.renter';
     } else if (damage.clientResponsibility === '0') {
        result = 'inspections.nc';
     }

     return result;
  }

  public activeSlideChange(index: number): void {
    if (!Util.isNullOrUndefined( this.data.faces[index])) {
      let idActiveFace: String = this.data.faces[index].id;
      for (let j in this.allDamages) {
        if (this.allDamages[j].faceId === idActiveFace) {
          this.allDamages[j].activeFace = true;
        } else {
          this.allDamages[j].activeFace = false;
        }
      }
      this.showTableDamage = true;
    }
  }

  public getRowClass(row: LpInspectionDamage): any {
    return {
      'activeSlide': (row.activeFace)
    };
  }

  public changeActiveSlide(idFace: String): void {
    this.uiSyncService.scrollTo('carouselFaces');

    this.carouselFaces.nativeElement.focus();
    for (let j in this.data.faces) {
      if (this.data.faces[j].id === idFace) {
        this.activeSlide = Number(j);
      }
    }
  }

  public changeZoneHover(face: LpInspectionFace, currentZone?: LpInspectionZone): void {
    face.zones.forEach(zone => {
      if ( !Util.isNullOrUndefined(currentZone) && currentZone.id === zone.id) {
        zone.show = true;
      } else {
        zone.show = false;
      }
    });

  }

  /**
   * @param event
   * @param face
   * @param zone
   * @param i
   * Utilisé lorsque l'utilisateur clique sur une image de face.
   */
  public createDamage(event: any, face: LpInspectionFace, zone: LpInspectionZone, i: number): void {
    this.currentZone = new LpInspectionZone();
    this.currentDamage = new LpInspectionDamage();
    if (this.formStackService.isDataReadOnly()) {
      return;
    }
    const x: Number = event.offsetX / this.svgRatio;
    const y: Number = event.offsetY / this.svgRatio;

    const newDamage: LpInspectionDamage = new LpInspectionDamage();
    newDamage.xAxis = x;
    newDamage.yAxis = y;
    newDamage.isNew = true;
    newDamage.visitDate = this.datetimeService.toDate(this.datetimeService.getNow());
    newDamage.updateDate = newDamage.visitDate;
    newDamage.faceId = this.data.faces[i].id;
    newDamage.faceWording = this.data.faces[i].wording;
    newDamage.group = new FtMeta();
    newDamage.group.id = zone.zoneClick;
    newDamage.group.details = 'inspection-models/' + zone.zoneClick;
    newDamage.zone = zone;
    newDamage.inspectionDamageStatus = new FtMeta();
    newDamage.inspectionDamageStatus.id = 'ACTIF';

    let rowNumber: Number = 0;
    this.data.faces.forEach((inspectionFace: LpInspectionFace) => {
      inspectionFace.zones.forEach((zone: LpInspectionZone) => {
        zone.damages.forEach((damage: LpInspectionDamage) => {
          if (damage.rowNumber > rowNumber) {
            rowNumber = damage.rowNumber;
          }
        });
      });
    });

    newDamage.rowNumber = rowNumber.valueOf() + 1;
    this.currentZone.assign(zone);    
    this.currentDamage.assign(newDamage);

    setTimeout(() => {
      this.uiSyncService.scrollTo('damageForm');
    }, 0);
  }

 /**
  * Appelée lorsque l'utilisateur choisi un élément dans le groupe de catégorie
  */
 public afterSelectGroupCategory(): void {
   if ( 
    this.currentDamage &&
    ( this.currentDamage.groupCategory &&
    this.currentDamage.groupCategory.id &&
    this.currentDamage.groupCategory.id === '') || (
    isNullOrUndefined(this.currentDamage.groupCategory)
    )

  ) {

    this.currentDamage.groupCategory = null;
    this.currentDamage.damageValue = null;
    this.currentDamageValues = null;
    this.currentDamage.damageCategory = null;
    return;
   }
   this.currentZone.elements.forEach((element: LpInspectionElementZone) => {
     if (element.id === this.currentDamage.groupCategory.id) {
      this.currentDamage.groupCategory.wording = element.wording;
      this.currentDamage.groupCategory.details = 'inspection-models/' + element.id;
      this.currentDamageValues = element.damageCategory.possibleValues;
      this.currentDamage.wording = element.wording;
      this.currentDamage.damageCategory = new FtMeta();
      this.currentDamage.damageCategory.id = element.damageCategory.id;
      this.currentDamage.damageCategory.wording = element.damageCategory.wording;
      this.currentDamage.damageCategory.details = 'inspection-damage-categories/' + element.damageCategory.id;
     }
   });
 }

 public afterSelectDamageValue(): void {
  if (this.currentDamage.damageValue.id === '') {
   this.currentDamage.damageValue = null;
   return;
  }
  this.currentDamageValues.forEach((value: FtMeta) => {
    if (value.id === this.currentDamage.damageValue.id) {
     this.currentDamage.damageValue = value;
    }
  });
 }

 /**
  *
  * @param valid
  * fonction utilisé lors de la validation du formaire de création d'un dommage.
  * Elle ajoue le domage à l'objet data dans la bonne zone.
  */
 public saveDamage(valid: Boolean): void {
    if (valid) {
      this.showCarousel = false;
      this.showTableDamage = false;
      if ((!Util.isNullOrUndefined(this.tempComment) && this.tempComment !== '') || (!Util.isNullOrUndefined(this.tempPicture) && this.tempPicture !== '')) {
        if (this.tempComment !== '') {
          let memo: LpMemo = new LpMemo();
          memo.attachmentKind = new FtMeta
          memo.attachmentKind.id = 'TXT';
          memo.wording = this.translate.instant('inspections.commentLabel');
          memo.txt = this.tempComment;
          this.currentDamage.attachments.push(memo);
        }

        if (this.tempPicture !== '') {
          let memo: LpMemo = new LpMemo();
          memo.attachmentKind = new FtMeta
          memo.attachmentKind.id = 'PHOTO';
          memo.wording = this.translate.instant('inspections.pictureLabel');
          memo.file = this.tempPicture;
          this.currentDamage.attachments.push(memo);
        }

      }
      this.tempComment = null;
      this.tempPicture = null;

      this.data.faces.forEach((face: LpInspectionFace) => {
        face.zones.forEach((zone: LpInspectionZone) => {
          if (this.currentZone && zone.id === this.currentZone.id) {
            face.damages.push(this.currentDamage);
            this.postLoadObject();
            this.currentDamage =  null;
            this.currentZone = null;
            this.currentDamageValues = null;
          }
        });
      });
    }
 }

  public showMemos(id: String): void {
    this.modalService.modalPromise(MemoComponent, {
      height : '70vh',
      width : '80vw',
      backdropClass: 'commonToolsBackdropClass',
      data : {
        key: id
      }
    });
  }

  public showModalAwaitingBackup(): void {
    this.modalService.modalPromise(LpModalEvalComponent, {
      backdropClass: 'alertBackdropClass',
      data : {
        message: this.translate.instant('inspections.awaitingBackupDamage')
      }
    });
  }

  public async deleteDamage(damage: LpInspectionDamage, rowIndex: number): Promise<void> {
    const result: boolean = await this.modalService.modalPromise(LpModalConfirmComponent, {
      backdropClass: 'alertBackdropClass',
      id : 'modalWithHeader'
    });
    if (result) {
      if (!Util.isNullOrUndefined(damage.id)) {
        // Si le dégât a déjà été enregistré en base
        this.alocproProvider.delete(`/inspections/${this.data.id}/damages/`, damage.id);
        // Passage du dégât  en desactivé dans l'objet data
        this.data.faces.forEach((face: LpInspectionFace) => {
          if (face.id === damage.faceId) {
            for (let index: number = 0; index < face.damages.length; index++) {
              if (face.damages[index].rowNumber !== damage.rowNumber) {
                // face.damages[index]. = ;
              }
            }
          }
        });

        // Passage du dégât  en desactivé dans le tableau.
        for (let index: number = 0; index < this.allDamages.length; index++) {
          if (index !== rowIndex) {
            // this.allDamages[index]. = ;
          }
        }

        // Modification du marqueur sur la face
        let pin: any = this.col2.nativeElement.querySelectorAll(`#pin-${damage.rowNumber}`)[0];
        pin.setAttribute('xlink:href', '/assets/img/pinDeactivateDamage.svg');
        let list: any = this.col2.nativeElement.querySelectorAll(`#list-${damage.rowNumber}`)[0];
        list.setAttribute('src', '/assets/img/pinDeactivateDamage.svg');
      } else {
        // Si le dégât n'a pas encore été enregistré en base
        // Suppresion du dégât dans l'objet data
        this.data.faces.forEach((face: LpInspectionFace) => {
          if (face.id === damage.faceId) {
            let faceDamages: LpInspectionDamage[] = [];
            for (let index: number = 0; index < face.damages.length; index++) {
              if (face.damages[index].rowNumber !== damage.rowNumber) {
                faceDamages.push(face.damages[index]);
              }
            }
            face.damages = faceDamages;
          }
        });

        // Suppression du dégât dans le tableau.
        let allDamagesTemp: LpInspectionDamage[] = [];
        for (let index: number = 0; index < this.allDamages.length; index++) {
          if (index !== rowIndex) {
            allDamagesTemp.push(this.allDamages[index]);
          }
        }
        this.allDamages = allDamagesTemp;

        // Suppression du marqueur sur la face
        let pin: any = this.col2.nativeElement.querySelectorAll(`#pin-${damage.rowNumber}`)[0];
        this.renderer.removeChild(this.col2.nativeElement, pin);
        let txt: any = this.col2.nativeElement.querySelectorAll(`#txt-${damage.rowNumber}`)[0];
        this.renderer.removeChild(this.col2.nativeElement, txt);
      }
    }
  }

  public async submit(event: any): Promise<void> {
    this.showDamageList = false;
    this.activeSlideTemp = this.activeSlide;
    
    const dateString: string = formatDate(new Date(),this.configService.get('formats').date_db_format_complete,this.configService.get('moment').LOCALE_TIME);

    let location: LpLocation = this.userService.getCurrentUser().location;     
    let text: string = this.translate.instant('signaturepad.signAt')+' '+dateString+' '+this.translate.instant('signaturepad.at')+' '+location.latitude+', '+location.longitude;

    if (this.customerSignature !== "") {
      /* ajout de la date */
      let canvasCustomer: HTMLCanvasElement = document.querySelector('#canvas-customerSignature');
      let ctxCustomer = canvasCustomer.getContext('2d');
      ctxCustomer.font = "14px serif";
      ctxCustomer.fillText(text, 20, 180);
      /* création du mémo */
      let attachmentKind: FtMeta = new FtMeta();
      attachmentKind.id = "SIGNATURE";
      let attachment: LpMemo = new LpMemo();
      attachment.attachmentKind = attachmentKind;
      attachment.wording = "Signature état parc"; 
      attachment.file = canvasCustomer.toDataURL();
      /* ajout du mémo dans le tableau des signature*/
      this.data.attachments.push(attachment);
      this.customerSignature ="";
    }

    if (this.agentSignature !== "") {
      /* ajout de la date */ 
      let canvasAgent: HTMLCanvasElement = document.querySelector('#canvas-agentSignature');
      let ctxAgent = canvasAgent.getContext('2d');
      ctxAgent.font = "14px serif";
      ctxAgent.fillText(text,20, 180);
      /* création du mémo */
      let attachmentKind: FtMeta = new FtMeta();
      attachmentKind.id = "SIGNATURETEC";
      let attachment: LpMemo = new LpMemo();
      attachment.attachmentKind = attachmentKind;
      attachment.wording = "Signature état parc"; 
      attachment.file = canvasAgent.toDataURL();
      /* ajout du mémo dans le tableau des signature*/
      this.data.attachments.push(attachment);
      this.agentSignature ="";
    }
    
    super.submit(event).then(() => {
      // pour distinguer le cas save and back du save simple
      if(this.formStackService.CurrentVerb === INSPECTIONS){
        this.postLoadObject();
      }
    });
  }

  public isNewDamage(damage: LpInspectionDamage): Boolean {
    /* TODO => A améliorer
      A l'affichage, les dates sont en string avec un typeof
      Lorsque que l'on crée un domage, elle sont bien de type object Date.
      console.log(typeof damage.visitDate);
      fonctions assign des classes LpInspection et LpInspectionFace modifiées.
    */
    if ((typeof this.formStackService.currentData['visitDate'] === 'string' && this.formStackService.currentData['visitDate'] === String(damage.updateDate))
      || (typeof this.formStackService.currentData['visitDate'] !== 'string' && this.formStackService.currentData['visitDate'].getTime() === damage.updateDate.getTime())
      ) {
      return true;
    } else {
      return false;
    }
  }

  public isActiveDamage(damage: LpInspectionDamage): Boolean {
    /* TODO => A améliorer
      A l'affichage, les dates sont en string avec un typeof
      Lorsque que l'on crée un domage, elle sont bien de type object Date.
      console.log(typeof damage.visitDate);
      fonctions assign des classes LpInspection et LpInspectionFace modifiées.
    */
    /*if (damage.inspectionDamageStatus.id === 'ACTIF') {
      return true;
    } else {
      return false;
    }*/
    return true;
  }

  protected async postLoadObject(): Promise<void> {
    this.parseCheckList(this.data.checklists);
    await this.loadDamageCategoriesMap();
    await this.loadElementsMap();
    this.initFaces();
    await this.setAllDamages();
    
  }

  private async setAllDamages(): Promise<void> {
    /*this.allDamages = [];
    let damage: LpInspectionDamage;
    this.showDamageList = false;
    for (let i in this.formStackService.currentData['faces']) {
      for (let j in this.formStackService.currentData['faces'][i].damages) {
        damage = new LpInspectionDamage();
        damage.assign(this.formStackService.currentData['faces'][i].damages[j]);
        damage.faceWording = this.formStackService.currentData['faces'][i].wording;
        damage.faceId = this.formStackService.currentData['faces'][i].id;
        if (damage.id !== null && damage.id !== undefined && (damage.nbMemos === null || damage.nbMemos !== undefined)) {
          damage.nbMemos = await this.commonToolsService.hasMemos(this.formStackService.currentApplicationItem.linkedObject, damage.id);
        }
        this.allDamages.push(damage);
      }
    }
    console.log(this.allDamages)
    this.showDamageList = true;*/
    this.allDamages = [];
    this.showDamageList = false;
    const faces: LpInspectionFaceV2[] = this.formStackService.currentData['faces'];
    for (let face of faces) {
      for (let zone of face.zones) {
        for (let damage of zone.damages) {
          damage.faceWording = face.wording;
          this.allDamages.push(damage);
        }
      }
    }
    console.log(this.allDamages)
    this.showDamageList = true;
  }

  /*private async setDamageCategories(): Promise<void> {
    const faces: LpInspectionFaceV2[] = this.formStackService.currentData['faces'];
    for (let face of faces) {
      for (let zone of face.zones) {
        for (let element of zone.elements) {
          this.damageCategories.set(element.id, element);
        }
      }
    }
  }*/

  private initFaces(): void {
    if (!Util.isNullOrUndefined(this.data.faces)) {
      let length: number = this.data.faces.length;
      let index: number = 0;
      this.data.faces.forEach( (face: LpInspectionFace) => {
        // A REVOIR ! Problème de typage !
        this.inspectionProvider.getPaginatedData(`inspection-faces/${encodeURIComponent(face.id.toString())}/attachments`)
        .then((records: PaginatedData) => {
          face.picture = records.body[0]['file'];
          index = index + 1;
          if (index === length) {
            this.showCarousel = true;
            if (!this.firstLoad) {
              this.showTableDamage = true;
            }
            this.firstLoad = false;
            this.refreshPositions();
            if (!Util.isNullOrUndefined(this.activeSlideTemp)) {
                this.activeSlide =  this.activeSlideTemp;
                this.activeSlideTemp = null;
            }
          }
        });
      });
    }
  }

  private async loadDamageCategoriesMap(): Promise<void> {
    const damageCategories: PaginatedData = await this.inspectionProvider.loadDamageCategories();
    for (let damage of damageCategories.body) {
      this.damageCategories.set(damage.id, damage);
    }
  }

  private async loadElementsMap(): Promise<void> {
    const faces: LpInspectionFaceV2[] = this.data.faces;
    for (let face of faces) {
      for (let zone of face.zones) {
        for (let element of zone.elements) {
          this.elements.set(element.id, element);
        }
      }
    }
  }

  private parseCheckList(checkList: LpInspectionChecklist[]): void {
    /*let equipmentChecklist: LpInspectionChecklist;
    for (let i in equipmentChecklists) {

      if (equipmentChecklists[i].damageValue === null) {
        equipmentChecklists[i].damageValue = new FtMeta();
      }

      this.commonToolsService.hasMemos(this.formStackService.currentApplicationItem.linkedObject, equipmentChecklists[i].id).then(
        (count: number) => {
          equipmentChecklists[i].nbMemos = count;
      });
      equipmentChecklist = equipmentChecklists[i];
      console.log('equipmentChecklist.groupCategory.id', equipmentChecklist);
      // ajout du groupe de checklist
      if (equipmentChecklist.groupCategory !== null && equipmentChecklist.groupCategory !== undefined && this.checkListGroups.filter(line => line.id === equipmentChecklist.groupCategory.id).length === 0) {
        this.checkListGroups.push(equipmentChecklist.groupCategory);
      }

      // ajout de la catégorie de dommage
      if (! Util.isNullOrUndefined(equipmentChecklist.damageCategory)) {
        this.damageCategories.set(equipmentChecklist.damageCategory.id, equipmentChecklist.damageCategory);
      }
    }*/
    this.showCheckList = true;
  }

  private refreshPositions(): void {
    this.svgRatio = 1;
    if (!Util.isNullOrUndefined(this.carouselFaces)) {
      let eleWdith: number = this.carouselFaces.nativeElement.offsetWidth;
      if (eleWdith < 850) {
        this.svgRatio = eleWdith / 850;
      }
    }
  }
}
