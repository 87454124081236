<div *ngIf="(isShown && !isWidget) || isWidget" [ngModelOptions]="{standalone: true}"  [(ngModel)]="inputSearch" bindField="bindField" secondBindField="secondBindField" (binding)="onHidden()"
  [ngClass]="'panel-group'" ngDefaultControl>
  <div [ngClass]="'panel card panel-primary open'">
    <div [ngClass]="'panel-heading card-header'">
      <h5 [ngClass]="''">{{title |translate}}</h5>
      <div [ngClass]="'title-buttons'">
          <!-- tofo virer / action-->
        <lp-zoom-chooser class="pull-left me-2" *ngIf="isMainZoom()" [zoomId]="id"></lp-zoom-chooser>
        <lp-button-new *ngIf="(!zoomService.getConfig(guid) || !zoomService.getConfig(guid).bindingProp) && hasButtonAdd" (action)="navigate(urlNew, newdataKeyWord); close();"></lp-button-new>
        <lp-childform-link *ngIf="zoomService.getConfig(guid) && zoomService.getConfig(guid).bindingProp && hasButtonAdd" 
        verb="{{this.uiSyncService.parentVerb}}" childType="{{urlNew}}" ky="new" key="{{uiSyncService.historyKey}}" 
        prop="{{zoomService.getConfig(guid).bindingProp}}" title="{{'general.button.new' | translate}}">
          <span class="btn btn-primary">
            <i class="fa fa-plus"></i>
          </span>
        </lp-childform-link>
        <lp-button-cancel *ngIf="isClosable" (action)="cancel()"></lp-button-cancel>
      </div>
    </div>
    <div class="modal-body">
      <div class="row criterias-zoom" *ngIf="criterias.length>0">
        <form #zoomForm="ngForm" ngNativeValidate (ngSubmit)="submit()" class="col p-0">
          <button type="submit"  class="btn btn-primary d-none">Submit</button>
          <div *ngFor="let criterias of arrayColumnsWithCriterias; let index = index" class="lign1 col-12 p-0">
            <div *ngFor="let criteria of criterias" class="lignItem {{criteriaClass}}">
              <div *ngIf="index === 0 || displayMore" class="label-2-colonnes">
                <lp-label-field for="{{criteria.wording}}" text="{{criteria.wording | translate}}"  title="{{criteria.wording | translate}}"></lp-label-field>
                <div *ngIf="guid">
                  <span>
                    <lp-text-field
                    *ngIf="criteria && criteria.type === 'None'"
                    id="{{guid}}-{{criteria.paramName}}" className="textField" cssClass="form-control" name="{{guid}}-{{criteria.paramName}}"
                    [ignoreChange]=true [ngModelOptions]="{standalone: true}"  [(ngModel)]="criteria.defaultValue"  
                    [required]="criteria.isMandatory" [readonly]="criteria.isReadOnly"></lp-text-field>
                    

                  <lp-zoom-field *ngIf="criteria && criteria.type === 'Zoom'" [inLocProZoom]="true" [modal]=true for="criteria.wording" locProZoomAttribut="{{criteria.paramName}}" locProZoomObject="zoom"
                    id="{{guid}}-{{criteria.paramName}}" addModifyVerb="zooms" kySearch="id" libSearch="wording" zoomId='users' (closeParent)="close();"
                    className="zoomField" cssClass="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="criteria.defaultValue" [ignoreChange]=true [inputSearch]="criteria.defaultValue"
                    searchTextField="id" [tableBindValues]="['id']" [tableShowField]="['id']" name="{{guid}}-{{criteria.paramName}}" [parentZoomGuid]="guid"></lp-zoom-field>


                    <lp-date-time *ngIf="criteria && criteria.type === 'DateTime'" [ngModelOptions]="{standalone: true}"  [(ngModel)]="criteria.defaultValue" id="{{guid}}-{{criteria.paramName}}" cssClass="form-control" name="{{guid}}-{{criteria.paramName}}"
                    [readonly]="criteria.isReadOnly" [ignoreChange]=true  [required]="criteria.isMandatory" ></lp-date-time>

                    <lp-date *ngIf="criteria && criteria.type === 'Date'" [ngModelOptions]="{standalone: true}"  [(ngModel)]="criteria.defaultValue" id="{{guid}}-{{criteria.paramName}}" cssClass="form-control" name="{{guid}}-{{criteria.paramName}}"
                    [readonly]="criteria.isReadOnly" [ignoreChange]=true [required]="criteria.isMandatory" (ngModelChange)='updateDate(zoomForm, $event, criteria.paramName, criteria.defaultValue)'></lp-date>


                    <lp-currency *ngIf="criteria && criteria.type === 'Currency'" id="{{guid}}-{{criteria.paramName}}" cssClass="form-control" name="{{guid}}-{{criteria.paramName}}"
                    [ngModelOptions]="{standalone: true}"  [(ngModel)]="criteria.defaultValue" [ignoreChange]=true [readonly]="criteria.isReadOnly" [required]="criteria.isMandatory"></lp-currency>


                    <lp-dropdown-field *ngIf="criteria && criteria.type === 'List'" className="textField" 
                    cssClass="form-control" [locProZoomObject]="criteria.parameterName" locProZoomAttribut="zoom" idField="field0" labelField="field1" name="{{criteria.paramName}}" id="criteria-{{criteria.paramName}}" 
                    [ngModelOptions]="{standalone: true}"  [(ngModel)]="criteria.defaultValue" [ignoreChange]=true >
                    </lp-dropdown-field>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="text-end me-0 ps-0">
          <label class="d-block p-0 m-0"></label>
          <a *ngIf="arrayColumnsWithCriterias.length > 1 && displayMore === true" (click)="displayMore = !displayMore" class="btn btn-outline-secondary cursor-pointer me-2" title="{{'general.button.lessCriteria' | translate}}">
            <i class="fa fa-angle-double-down" aria-hidden="true"></i>
          </a>
          <a *ngIf="arrayColumnsWithCriterias.length > 1 && displayMore === false" (click)="displayMore = !displayMore" class="btn btn-outline-secondary cursor-pointer me-2" title="{{'general.button.moreCriteria' | translate}}">
            <i class="fa fa-angle-double-down" aria-hidden="true"></i>
          </a>
          <lp-button-search *ngIf="arrayColumnsWithCriterias.length > 0" class="cursor-pointer" (click)="submit()"></lp-button-search>
        </div>
      </div>
      <div *ngIf="zoomService.getConfig(guid) && zoomService.getConfig(guid).zoomParameter && zoomService.getConfig(guid).zoomParameter.columns">
        <ngx-datatable 
        [class.locprozoom]="zoomService.getConfig(guid).isLocProZoom" 
        [class.loading-table]="loadingIndicator && zoomDataList.length ===0"
        class="material table-height-35 table-lpzoom  mb-0 mt-2" 
        (activate)="selectItem($event);" 
        (sort)="onSort($event)"
        [externalSorting] = "true"
        [rows]="zoomDataList" 
        [columnMode]="'force'"
        [headerHeight]="35"
        [footerHeight]="(zoomDataList && zoomDataList.length > 0) ? 35 : 0"
        [rowHeight]="40"
        [scrollbarH]="true"
        [loadingIndicator]="loadingIndicator"
        [messages]="{emptyMessage: 'zoom.emptyDataMessage' | translate}">
            <ngx-datatable-column *ngFor="let column of cols" [width]="column.width" name="{{column.wording | translate }}" prop="{{column.prop}}"
              [canAutoResize]="(zoomService.getConfig(guid).isLocProZoom && column.width) ? false : true"
              [headerClass]="(zoomService.getConfig(guid).isLocProZoom && column.width) ? '' : 'nowidth'"
              [cellClass]="(zoomService.getConfig(guid).isLocProZoom && column.width) ? 'cursor-pointer' : 'nowidth cursor-pointer'"
              [resizeable]="false"
              [draggable]="false">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                <lp-cell [type]="column.type" [value]="value"></lp-cell>
              </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-footer *ngIf="zoomDataList && zoomDataList.length > 0">
              <ng-template ngx-datatable-footer-template>         
                <lp-footer
                  [guid]="guid"
                  [exportButton]="this.zoomService.getConfig(guid).exportButton"
                  (export)="exportCSV()"
                  (changePage)="changePage($event)"
                  [dataCount]="zoomDataCount"
                  [linesPerPage]="(this.zoomService.getConfig(guid).linesPerPage) ? this.zoomService.getConfig(guid).linesPerPage : linesPerPage"
                  [zoomId]="this.zoomService.getConfig(guid).zoomId"
                  [currentPage]="currentPage"
                  [refreshWidth]="uiSyncService.FooterWidth">
                </lp-footer>     
              </ng-template>
          </ngx-datatable-footer>     
        </ngx-datatable>     
      </div>
    </div>
  </div>
</div>