import { Component, OnInit, HostListener, ElementRef, OnDestroy, Input } from '@angular/core';
import { AlocproProvider } from '../providers/alocpro.provider';
import { UiSyncService } from '../services/ui-sync.service';
import { UserService } from '../services/user.service';
import { LanguageService } from '../services/language.service';
import { Util } from 'app/statics/utils';
import { Subscription } from 'rxjs';
import { SchedulerUIService } from 'app/services/scheduler-ui.service';
import { environment } from 'environments/environment';
import { ChangeService } from 'app/services/change.service';
import { ModalService } from 'app/services/modal.service';
import { RouterService, LOGIN, ROOT } from 'app/services/router.service';
import { LpModalChangeComponent } from 'app/ui/lp-modal/lp-modal-change/lp-modal-change.component';
import { LpModalWorkflowListComponent } from 'app/ui/lp-modal/lp-modal-workflow/lp-modal-workflow-list/lp-workflow-list.component';
import { LpModalChangeLocationComponent } from 'app/ui/lp-modal/lp-modal-change-location/lp-modal-change-location.component';
import { LpModalChangePasswordComponent } from 'app/ui/lp-modal/lp-modal-change-password/lp-modal-change-password.component';
import { LpModalDebugComponent } from 'app/ui/lp-modal/lp-modal-debug/lp-modal-debug.component';
import { LpModalPreferencesComponent } from 'app/ui/lp-modal/lp-modal-preferences/lp-modal-preferences.component';
import { LpModalCustomizationComponent } from 'app/ui/lp-modal/lp-modal-customization/lp-modal-customization.component';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { ConfigService } from 'app/services/config.service';
import { FormStackService } from 'app/services/form-stack.service';
import { LpModalConfidentialityComponent } from 'app/ui/lp-modal/lp-modal-confidentiality/lp-modal-confidentiality.component';
import { LpModalDashboardComponent } from 'app/ui/lp-modal/lp-modal-dashboard/lp-modal-dashboard.component';
import * as _ from 'lodash';
import { LpModalVisualSettingsComponent } from 'app/ui/lp-modal/lp-modal-visual-settings/lp-modal-visual-settings.component';
import { LpModalAddCompanyComponent } from 'app/ui/lp-modal/lp-modal-addcompany/lp-modal-addcompany.component';

@Component({
  selector: 'lp-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() public object: String;
  /**
   * Contient toute l'arborescence du menu renvoyé par alocproProvider
   */
  public typeheadMenu: any;
  public scopeSelected: String = 'short-term-contracts';
  public changeLocationActive: Number;
  public classTheme: String = '';
  public hideLpSwitchTheme: Boolean = true;
  public isMultiLocation: Boolean;
  public themes: any;
  public defaultPrimaryColor: string;
  public multilanguage: Boolean = false;
  public userWorkflows: Boolean = false;
  public nativePasswords: Boolean;
  public production: Boolean = environment.production;
  public showMenu: boolean = false;
  /**
   * Lorsqu'un utilisateur se connecte ou déconnecte
   */
  private subscriptionUC: Subscription;
  /**
   * Lorsque l'utilisateur selectionne un lien dans le sitemap
   */
  private subscriptionSIS: Subscription;
  private levelsAllowingDisplayMenu: Array<number> = new Array<number>();
  private profilAllowedTofastActions: string = '';

  constructor(
    public userService: UserService,
    public uiSyncService: UiSyncService,
    private routerService: RouterService,
    private alocproProvider: AlocproProvider,
    private repositoryProvider: RepositoryProvider,
    private modalService: ModalService,
    private changeService: ChangeService,
    private _elementRef: ElementRef,
    private languageService: LanguageService,
    private schedulerUIService: SchedulerUIService,
    private configService: ConfigService,
    private formStackService: FormStackService) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any): void {
    const clickedInside: boolean = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.uiSyncService.searchVal = '';
      this.uiSyncService.sitemap = false;
    }
    this.uiSyncService.clickInside = false;
  }

  public ngOnInit(): void {
    this.userWorkflows = this.configService.get('userWorkflows');
    this.multilanguage = this.configService.get('multilanguage');
    this.nativePasswords = this.configService.get('nativePasswords');
    this.defaultPrimaryColor = this.configService.get('defaultPrimaryColor');
    this.themes = this.configService.get('themes');
    this.levelsAllowingDisplayMenu = this.configService.get('levelsAllowingDisplayMenu');
    this.profilAllowedTofastActions = this.configService.get('profilAllowingToFastActions');
    if (!Util.isNullOrUndefined(this.userService.getCurrentUser())) {
      this.typeheadMenu = this.userService.getCurrentUser().menu;
    } else {
      this.schedulerUIService.isFilterLoaded = false;
      this.typeheadMenu = undefined;
      this.routerService.navigate(LOGIN);
    }

    this.subscriptionSIS = this.uiSyncService.sitemapItemSelectedEvtEmitter.subscribe(() => {
      this.uiSyncService.sitemap = false;
    });

    if (!Util.isNullOrUndefined(this.userService.getCurrentUser()) && !Util.isNullOrUndefined(this.userService.getCurrentUser().menu)) {
      this.typeheadMenu = this.userService.getCurrentUser().menu;
    }
    this.showMenus();
    // Test si l'utilisateur peut ou non changer d'agence.
  }

  public logOut(): void {
    if (this.changeService.checkIfpreviousChanged(-1)) {
      this.modalService.modalPromise(LpModalChangeComponent, {
        backdropClass: 'alertBackdropClass',
        id : 'modalWithHeader'
      }).then((result: boolean) => {
        if (result) {
          this.repositoryProvider.logout().then(() => {
            this.formStackService.clear();
            this.alocproProvider.logout();
            this.userService.userLogout();
            this.routerService.navigate(LOGIN);
          });
        }
      });
    } else {
      this.repositoryProvider.logout().then(() => {
        this.formStackService.CurrentVerb = null;
        this.alocproProvider.logout();
        this.formStackService.clear();
        this.userService.userLogout();
        this.routerService.navigate(LOGIN);
      });
    }
  }

public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscriptionUC)) {
      this.subscriptionUC.unsubscribe();
    }
    if (!Util.isNullOrUndefined(this.subscriptionSIS)) {
      this.subscriptionSIS.unsubscribe();
    }
  }

  public toggleSitemap(event: any): void {
    event.preventDefault();
    this.uiSyncService.clickInside = !this.uiSyncService.clickInside;
    this.uiSyncService.sitemap = !this.uiSyncService.sitemap;
    this.uiSyncService.searchVal = '';
  }

  public clickedInside(event: any): void {
    event.preventDefault();
    this.uiSyncService.clickInside = true;
  }

  public goHome(): void {
    this.routerService.navigate(ROOT);
  }

  public setLanguage(lang: string): void {
    this.languageService.setLanguage(lang);
  }


  public changeLpTheme(theme: string, primaryColor: string): void {
    this.classTheme = theme;
    this.hideLpSwitchTheme = true;
    this.userService.setpreference('defaultTheme', theme);
    this.uiSyncService.changeTheme(theme, primaryColor);
  }

  public onSubmit(): void { }

  public getUserWorkflows(): void {
        this.uiSyncService.showMyWorkflows();
  }

  public async getUserModalWorkflows(): Promise<void> {
    this.modalService.isMe = true;
    await this.modalService.modalPromise(LpModalWorkflowListComponent, {
      maxHeight : '90vh',
      width : '80vw',
      backdropClass: 'commonToolsBackdropClass'
    });
  }

  public changeLocation(): void {
    this.modalService.modalPromise(LpModalChangeLocationComponent, {
      width : '500px',
      backdropClass: 'commonToolsBackdropClass'
    }).then((result: boolean) => {
    });
  }

  public changePassword(): void {
    this.modalService.modalPromise(LpModalChangePasswordComponent, {
      width : '500px',
      backdropClass: 'commonToolsBackdropClass'
    }).then((result: boolean) => {

    });
  }

  public showModalDebug(): void {
    this.modalService.modalPromise(LpModalDebugComponent, {
      height : '90vh',
      width : '80vw',
      backdropClass: 'commonToolsBackdropClass'
    }).then((result: boolean) => {

    });
  }

  public showModalPreferences(): void {
    this.modalService.modalPromise(LpModalPreferencesComponent, {
      height : '90vh',
      width : '80vw',
      backdropClass: 'commonToolsBackdropClass'
    }).then((result: boolean) => {

    });
  }

  public showModalAddCompany(): void {
    this.modalService.modalPromise(LpModalAddCompanyComponent, {
      height : '90vh',
      width : '750px',
      backdropClass: 'commonToolsBackdropClass'
    }).then((result: boolean) => {

    });
  }

  public showModalDashboard(): void {
    this.modalService.modalPromise(LpModalDashboardComponent, {
      height : '90vh',
      width : '80vw',
      backdropClass: 'commonToolsBackdropClass'
    }).then((result: boolean) => {});
  }

  public showModalVisualSettings(): void {
    this.modalService.modalPromise(LpModalVisualSettingsComponent, {
      height : '90vh',
      width : '80vw',
      backdropClass: 'commonToolsBackdropClass'
    }).then((result: boolean) => {});
  }

  public showModalCustomization(): void {
    this.modalService.modalPromise(LpModalCustomizationComponent, {
      height : '90vh',
    width : '80vw',
    backdropClass: 'commonToolsBackdropClass'
  }).then((result: boolean) => {

  });
  }

  public navigate(): void {
    this.routerService.navigate('examples', this.configService.get('newdataKeyWord'));
  }

  public showModalConfidentiality(): void {
    this.modalService.modalPromise(LpModalConfidentialityComponent, {
      height : '90vh',
    width : '80vw',
    backdropClass: 'commonToolsBackdropClass'
  }).then((result: boolean) => {

  });
  }

  public showMenus(): void {
    if (!Util.isNullOrUndefined(this.userService.getCurrentUser()) &&
        !Util.isNullOrUndefined(this.userService.getCurrentUser().level) && 
        !Util.isNullOrUndefined(this.levelsAllowingDisplayMenu)) {
      this.levelsAllowingDisplayMenu.find((element)=> {
        if (String(element) === this.userService.getCurrentUser().level) {
          this.showMenu = true;
          return;
        }
      }) 
    }
  }

  public showSearchBar(): boolean {
    let showSearchBar: boolean = false;
    if ( this.formStackService && 
         this.formStackService.VisualSettings &&
         this.formStackService.VisualSettings.displaySearchBar &&
         this.formStackService.VisualSettings.displaySearchBar === true) {
          showSearchBar = true;
    }
    return showSearchBar;
  }

  public showVisualSettingsMenu(): boolean {
    let showMenu: boolean = false;
    if ( this.formStackService && 
         this.formStackService.VisualSettings &&
         this.formStackService.VisualSettings.displayMenu &&
         this.formStackService.VisualSettings.displayMenu === true) {
          showMenu = true;
    }
    return showMenu;
  }

  public userHasRightsForFastActions(): boolean {
    let userHasRights: boolean = false;
    if ( 
        this. userService && 
        this.userService.getCurrentUser() && 
        this.userService.getCurrentUser().profile &&
        this.userService.getCurrentUser().profile.id &&
        this.userService.getCurrentUser().profile.id === this.profilAllowedTofastActions
      ) {
        userHasRights = true;
      }
    return userHasRights;
  }

}
