import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { Subscription } from 'rxjs';
import { FormStackService } from 'app/services/form-stack.service';

@Component({
  selector: 'lp-ui-accordion-menu',
  templateUrl: './ui-accordion-menu.component.html'
})
export class UiAccordionMenuComponent implements OnInit, OnDestroy {

  private subscribeATend: Subscription

  constructor(
    protected uiSyncService: UiSyncService,
    public formStackService: FormStackService
    ) {  }

  public  ngOnInit(): void {
    this.subscribeATend = this.uiSyncService.accordionsToggleEndEvtEmitter.subscribe((id: string) => {
      this.uiSyncService.scrollTo(id);
    });
  }

  public ngOnDestroy(): void {
    this.subscribeATend.unsubscribe();
  }
  /**La fonction openPanel(), permet d'ouvrir ou fermé un onglet selon l'ID passé en paramètre.
   *
  */
  public openPanel(id: string): void {
    this.uiSyncService.accordiongroups.set(id, true);

    setTimeout(() => {
      this.uiSyncService.scrollTo(id);
    }, 0);
  }
}
