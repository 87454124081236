import { LpCompany } from "app/meta/company";

export class GeneralParameters {
   public id : String
   public wording: string;
   public table: String;
   public code: String;
   public order: String;
   public description: String;
   public value: String;
   public name: String;
   public company?: LpCompany;
   public importArg?: String;
   public amount?: Number;
   public icone?: String;
}