import { Component, OnInit, Input } from '@angular/core';
import { FormStackService } from '../../services/form-stack.service';
import { StackItem } from 'app/models/stackitem';
import { FormService } from 'app/services/root/form.service';
import * as _ from 'underscore'
import { UiSyncService } from 'app/services/ui-sync.service';
import { RouterService } from 'app/services/router.service';
import { ModalService } from 'app/services/modal.service';
import { ChangeService } from 'app/services/change.service';
import { LpModalChangeComponent } from '../lp-modal/lp-modal-change/lp-modal-change.component';

@Component({
  selector: 'lp-ui-breadcrumb',
  templateUrl: './lp-ui-breadcrumb.component.html'
})
export class LpUiBreadcrumbComponent implements OnInit {
  @Input() public title: String = null;
  @Input() public linkTitle: String = null;
  @Input() public infos: String = null;
  @Input() public contentTooltip: String = null;
  @Input() public placementTooltip: String = null;

  public history: Map<String, StackItem> = new Map();
  public keys: [String, StackItem][] ;
  public lastClickItem: number;

  constructor(
    private formStackService: FormStackService,
    private formService: FormService,
    public uisyncService: UiSyncService,
    private routerService: RouterService,
    private modalService: ModalService,
    private change: ChangeService) { }

  public ngOnInit(): void {
    // && (Util.isNullOrUndefined(this.uisyncService.lastBreadcrumbItemClickIndex) || this.uisyncService.lastBreadcrumbItemClickIndex === 0)
    // if (this.formStackService.stackExist() && this.formStackService.stack.size > this.uisyncService.currentBreadCrumb.length){
      this.uisyncService.currentBreadCrumb = Array.from(this.formStackService.getAllStack());
    // }
  }

  // directement dans la fonction on demande le declanhement de la modal, appelé
  public async click(link: StackItem, item: string, indexclicked: number): Promise<void> {
    if (this.change.checkIfpreviousChanged(indexclicked)) {
      this.modalService.modalPromise(LpModalChangeComponent, {
        backdropClass: 'alertBackdropClass',
        id : 'modalWithHeader'
      }).then((result: boolean) => {
        if (result) {
          this.click2(link, item, indexclicked)
        }
     });
    } else {
      this.click2(link, item, indexclicked);
    }
  }

  private async click2(link: StackItem, item: string, indexclicked: number): Promise<void> {
    this.uisyncService.lastBreadcrumbItemClickIndex = indexclicked;
    if ((item !== null || item !== undefined)) {
      this.uisyncService.currentBreadCrumb = this.getNewBreadcrumbItem(this.formStackService.stack, indexclicked);
      this.change.removeIndexes(indexclicked);
      if (item === 'planning') {
        this.routerService.navigate(link.currentVerb);
      } else {
        await this.formService.build(link.currentVerb, link.currentKy, indexclicked, false);
      }
    }
  }

  /*
  private getInfosBreadcrumb(stack: StackItem, key: String): void {
    // Récupération de l'objet breadcrumb
    let stackItem: StackItem = this.history.get(key);
    //stackItem.infosSupp = '';
    this.menuProvider.getBreadcrumb(stack.currentVerb).then((breadcrumbItem: BreadcrumbItem) => {
      if (!Util.isNullOrUndefined(breadcrumbItem) && !Util.isNullOrUndefined(breadcrumbItem.summary)) {
        breadcrumbItem.summary.forEach((element: ColumnsProperty) => {
          //stackItem.infosSupp += ' - ' + this.getValue(element, key, stack.data);
        });
      }
    });
  }
  */

  private getNewBreadcrumbItem(StackItemkey: Map<String, StackItem>, stackId: number): [String, StackItem][] {
    let m:  Map<String, StackItem> = new Map();
    _.each(Array.from(StackItemkey), (item: [string, StackItem], index: number) => {
      if (index < stackId) {
        m.set(item[0], item[1]);
      }
    });
    return Array.from(m);
  }
}
