import { Component, Inject } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { LpMemo } from '../../meta/memo';
import { AlocproProvider } from '../../providers/alocpro.provider';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../services/modal.service';
import { DatetimeService } from '../../services/datetime.service';
import { MemoProvider } from 'app/providers/memo.provider';
import { PaginatedData } from 'app/models/paginated-data';
import { FtMeta } from 'app/meta/ft-meta';
import { ChangeService } from 'app/services/change.service';
import { Util } from 'app/statics/utils';
import * as _ from 'underscore';
import { FormStackService } from 'app/services/form-stack.service';
import { LpModalConfirmComponent } from 'app/ui/lp-modal/lp-modal-confim/lp-modal-confirm.component';

import { LpModalComponent } from 'app/ui/lp-modal/lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from 'app/services/file.service';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { AFTER_GET_EVENT, BEFORE_DELETE_EVENT, BEFORE_GET_EVENT, BEFORE_INSERT_EVENT, BEFORE_UPDATE_EVENT, EvalService, INIT_NEW_EVENT, MAIN } from 'app/services/eval.service';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';


const MEMOS_PREFIX: String = 'memos';

@Component({
  selector: 'lp-memo',
  templateUrl: './memo.component.html'
})
export class MemoComponent extends LpModalComponent {
  /**
   * Propriété utilisée pour trier le type de mémos et le type de workflows
   */
  public attachmentKinds: FtMeta;
  private path: String = 'technical/attachments';
  public currentMemo: LpMemo = null;
  public columns: Array<Object> = [
    {
      'prop': 'wording',
      'name': 'memos.wording',
    },
    {
      'prop': 'action',
      'name': '',
    }
  ];
  public memos: LpMemo[];
  public loading: Boolean = true;
  /**
   * Utilisé pour la pagination des listes.
   */
  public actualPageNumber: number = 1;
    /**
   * Nb de memos liés à l'objet courant.
   */
  public nbMemos: number = 0;
  /**
   * Défini le nb max de workflows à afficher dans les listes.
   */
  public memosPerPage: number = this.configService.get('memosLimitInList')
  public pendingchanges: boolean = false;
  private key: String;
  private currentIndex: number = 0;
  private currentDeleteFile: LpMemo;
  private currentDeleteId: number = 0;

  constructor(private configService: ConfigService,
              public uiSyncService: UiSyncService,
              public alocproProvider: AlocproProvider,
              public translate: TranslateService,
              public modalService: ModalService,
              public memoProvider: MemoProvider,
              public datetimeService: DatetimeService,
              public changeService: ChangeService,
              public formstackService: FormStackService,
              public dialogRef: MatDialogRef<LpModalComponent>,
              public fileService: FileService,
              public applicationItemProvider: ApplicationItemProvider,
              private evalService: EvalService,
              private userHstoryService: UserHistoryService,

              @Inject(MAT_DIALOG_DATA) public data: any) {
                super(dialogRef, data);
                this.loading = true;
                if (!Util.isNullOrUndefined(data.key)) {
                  this.key = this.data.key;
                } else {
                  this.key = this.formstackService.currentData.id;
                }
                this.refreshMemoList(0, this.actualPageNumber, true);
                this.applicationItemProvider.getItem('memos').then(async (applicationItem: ApplicationItem) => {
                  this.formstackService.specificApplicationItem = applicationItem;                  
                  await this.evalService.executeContrainte(INIT_NEW_EVENT, MAIN, this.formstackService.specificApplicationItem.ecrId.toString(), '*',
                  this.currentMemo, this.formstackService.specificFields);
                })
                this.newMemos();
              }

  /**
   * le fonction newMemo() permet d'afficher le formulaire d'édition d'un mémo vide
   * pour en créer un nouveau.
   */
  public async newMemos(): Promise<void> {
    this.currentMemo = new LpMemo();
    this.changeService.initSpecificChange(MEMOS_PREFIX);
    if (this.memos) {
      this.currentIndex = this.memos.length;
    }
  }

  public downloadFile(base64: String): void {
    let file: String[] = base64.split(',');
    let byteCharacters: String = atob(file[1].toString());
    let byteNumbers: Array<number> = new Array(byteCharacters.length);
    for (let i: number = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray: Uint8Array = new Uint8Array(byteNumbers);
    let blob: Blob = new Blob([byteArray], {
      type: undefined
    });

    let a: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(a);
    let url: String = window.URL.createObjectURL(blob);
    a.href = url.toString();
    a.download = 'memo.' + this.configService.get('iconTypeMime')[this.fileService.base64MimeType(base64).toString()].type;
    a.click();
    window.URL.revokeObjectURL(url.toString());
  }

  public async saveMemo(valid: Boolean): Promise<void> {
    if (valid) {
      this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.updateInProgress'));
      if (this.currentMemo.id) {
        // UPDATE        
      await this.evalService.executeContrainte(BEFORE_UPDATE_EVENT, MAIN, this.formstackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentMemo, this.formstackService.currentFields);
      await this.memoProvider.putMemo(this.formstackService.currentApplicationItem.linkedObject
          , this.key, this.currentMemo, true).then((result: LpMemo) => {
           this.uiSyncService.loader(false);
          // Faire traitement pour récupérer l'index d'un mémo;
          this.getMemosIndex();
          this.refreshMemoList(this.currentIndex, this.actualPageNumber);
          this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
          this.translate.instant('general.modalService.success'));
          this.changeService.clearCurrentSpecificChanges(MEMOS_PREFIX);
          this.userHstoryService.addEvent('modify', this.formstackService.currentApplicationItem, this.formstackService.currentData.id, this.formstackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.Memo')+' (ID '+this.currentMemo.id+')');
          this.memoProvider.getMemo(this.path, this.currentMemo.id).then((memo: LpMemo) => {
          this.currentMemo = memo;
          });
         }).catch((error) => {
          this.uiSyncService.loader(false);
        });
     } else {
      await this.evalService.executeContrainte(BEFORE_INSERT_EVENT, MAIN, this.formstackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentMemo, this.formstackService.currentFields);
        this.memoProvider.postMemo(this.formstackService.currentApplicationItem.linkedObject
          , this.key, this.currentMemo, true).then((id) => {
          this.uiSyncService.loader(false);
          this.refreshMemoList(this.currentIndex, this.actualPageNumber);
          this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
          this.translate.instant('general.modalService.success'));
          this.changeService.clearCurrentSpecificChanges(MEMOS_PREFIX);
          this.memoProvider.getMemo(this.path, id).then((memo: LpMemo) => {
            this.currentMemo = memo;
          });
          this.userHstoryService.addEvent('add', this.formstackService.currentApplicationItem, this.formstackService.currentData.id, this.formstackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.Memo')+' (ID '+id+')');
        }).catch((error) => {
          this.uiSyncService.loader(false);
        });
      }
    } else {
      this.modalService.error(this.translate.instant('general.modalService.formNotValid'),
        this.translate.instant('general.modalService.error'));
    }
  }
  
  /** La fonction SelectItem(), permet d'afficher le formulaire de modification d'un mémo.
   * @param : value
  */
 public async selectItem(value: any): Promise<void> {
    if (!Util.isNullOrUndefined(value)) {
      this.loading = true;
      this.uiSyncService.loader(true, false, this.translate.instant('loading.data'));
      //this.newMemos();
      await this.evalService.executeContrainte(BEFORE_GET_EVENT, MAIN, this.formstackService.specificApplicationItem.ecrId.toString(), '*',
      this.currentMemo, this.formstackService.specificFields);
      this.currentMemo = await this.memoProvider.getMemo(value.details);
      await this.evalService.executeContrainte(AFTER_GET_EVENT, MAIN, this.formstackService.specificApplicationItem.ecrId.toString(), '*', 
      this.formstackService, this.formstackService.specificFields);
      this.changeService.initSpecificChange(MEMOS_PREFIX);
      this.uiSyncService.loader(false);
      this.loading = false;
    }
  }

  public uploadedFile(base64: String): void {
    this.currentMemo._files.push(base64);
  }

  public deleteMemo(id: number): void {
    this.currentDeleteId = id;
    this.modalService.modalPromise(LpModalConfirmComponent, {
      backdropClass: 'alertBackdropClass',
      id : 'modalWithHeader'
    }).then(async (result: boolean) => {
      if (result) {
        await this.evalService.executeContrainte(
          BEFORE_DELETE_EVENT, MAIN, 'MEMO', '*', id, null, null, this.formstackService.currentData);
        this.confirmDelete();
        this.userHstoryService.addEvent('delete', this.formstackService.currentApplicationItem, this.formstackService.currentData.id, this.formstackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.Memo')+' (ID '+id+')');
      };
    });
  }

  public confirmDelete(): void {
    this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.updateInProgress'));
    // this.modalService.modal.close('templateConfirmModal');
    // todo faire suppresion mémo
    this.memoProvider.deleteOneMemo(this.formstackService.currentApplicationItem.linkedObject
      , this.key, this.currentDeleteId, true).then(() => {
      this.uiSyncService.loader(false);
      // set actual table Page to the previous one if last row has been delete. (issues 926 on github swimlane)
      if ( this.actualPageNumber > 0 && ((this.nbMemos - 1) % this.memosPerPage) === 0) {
        this.actualPageNumber = this.actualPageNumber - 1;
      };
      // Faire traitement pour récupérer l'index d'un mémo;
      this.refreshMemoList(0, this.actualPageNumber);
      this.modalService.success(this.translate.instant('general.modalService.deletionCompleted'),
        this.translate.instant('general.modalService.success'));
    });
  }

  public getIcon(encoded: String): String {
    let mime: any = this.configService.get('iconTypeMime')[this.fileService.base64MimeType(encoded).toString()];
    if (!Util.isNullOrUndefined(mime)) {
      return mime.icon;
    }
    return this.configService.get('iconTypeMime')['default'].icon;
  }

  public getIconLabel(encoded: String): String {
    let mime: any = this.configService.get('iconTypeMime')[this.fileService.base64MimeType(encoded).toString()];
    if (!Util.isNullOrUndefined(mime)) {
      return mime.label;
    }
    return '';
  }

  /**
   * Set the right page number because ngx-DataTable first page is 0, and 1 for the backend.
   * @param pageInfo
   */
  public changePage(pageInfo: any): void {
    this.actualPageNumber = pageInfo.offset + 1;
    this.refreshMemoList(0, this.actualPageNumber);
  }

  /**
   * Used to ask confirmation if the user started any changes before he close the memo.
   */
  public checkAndConfirmChanges(): void {
    this.close();
  }

  public deleteFile(): void {
    this.currentDeleteFile = null;
    this.modalService.modalPromise(LpModalConfirmComponent, {
            backdropClass: 'alertBackdropClass',
            id : 'modalWithHeader'
    }).then((result: boolean) => {
      if (result) {
        this.confirmDeleteFile()
      }
    });
  }

  public deleteAttachmentDocument(memo: LpMemo): void {
    this.currentDeleteFile = memo;
    this.modalService.modalPromise(LpModalConfirmComponent, {
      backdropClass: 'alertBackdropClass',
      id : 'modalWithHeader'
    });
    event.stopPropagation();
  }

  public confirmDeleteFile(): void {
    if (Util.isNullOrUndefined(this.currentDeleteFile)) {
      this.currentMemo.file = '';
    } else {
      this.currentMemo.attachedDocuments = _.without(this.currentMemo.attachedDocuments, this.currentDeleteFile);
    }
    this.currentDeleteFile = null;
  }

  public zoomIconFile(encoded: String): void {
    if (this.fileService.isPdf(encoded)) {
      this.uiSyncService.displayZoomPdf(encoded);
    }
    if (this.fileService.isTxt(encoded)) {
      this.uiSyncService.displayZoomTxt(encoded);
    }
  }

  /**
   * la fonction refreshMemoList() fait un appel au backend pour chercher la liste des mémos
   */
  protected refreshMemoList(index: number, actualPage: number, openModal?: Boolean): void {
    this.loading = true;
    this.alocproProvider.getMemos(this.formstackService.currentApplicationItem.linkedObject.toString(),
    this.key.toString(), actualPage, this.memosPerPage).then(
      (record: PaginatedData) => {
        this.initLpMemo(record.body as LpMemo[], index);
        this.memoProvider.refreshMemoList(record.body as LpMemo[]);
        this.nbMemos = 0;
        if (record.count) { this.nbMemos = record.count }

        if (openModal) {
          this.uiSyncService.loadObject();
        }
    });
  }

  protected initLpMemo(memos: LpMemo[], index?: number): void {
    if (!memos) {
      this.memos = [];
      this.currentMemo = new LpMemo();
      this.loading = false;
    } else {
      this.memos = memos;
      this.currentMemo = new LpMemo();
      this.loading = false;
      if (index) {
        Object.assign(this.currentMemo, this.memos[index]);
      }
    }
  }

  protected getMemosIndex(): void {
    const currentId: String = this.currentMemo.id;
    this.memos.forEach((memo: LpMemo, i: number) => {
      if (memo.id === currentId) {
        this.currentIndex = i;
      }
    });
  }
}
