import { LpMeta, propertyMeta } from './meta';

export class LpRib extends LpMeta {
    @propertyMeta('string')
    public domicile: String;
    @propertyMeta('string')
    public holder: String;
    @propertyMeta('number')
    public order: Number;
    @propertyMeta('string')
    public bank: String;
    @propertyMeta('string')
    public desk: String;
    @propertyMeta('string')
    public account: String;
    @propertyMeta('string')
    public key: String;
    @propertyMeta('string')
    public iban: String;
    @propertyMeta('string')
    public bic: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
}
