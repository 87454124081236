import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {ExpensesComponent} from './expenses/expenses.component';
import {ShortTermContractsComponent} from './short-term-contracts/short-term-contracts.component';
import {UsersComponent} from './user/user.component';
import {LocationsComponent} from './locations/locations.component';
import {CompaniesComponent} from './companies/companies.component';
import {ModelsComponent} from './models/models.component';
import {EquipmentsComponent} from './equipments/equipments.component';
import {FinesComponent} from './fines/fines.component';
import {ThirdPartiesComponent} from './third-parties/third-parties.component';
import {InvoicesComponent} from './invoices/invoices.component';
import {PlanningComponent} from './planning/planning.component';
import {MovementsComponent} from './movements/movements.component';
import {AddressesComponent} from './addresses/addresses.component';
import {ServicesComponent} from './services/services.component';
import {PaymentMethodComponent} from './payment-method/payment-method.component';
import {FormComponentModule} from '../FormComponents/formComponent.module';
import {AppModuleDetailForm} from '../DetailForms/app.module.detailForm';
import {AppModuleUI} from '../ui/app.module.ui';
import {AppModuleLp} from '../lp/app.module.lp';
import {AlocproThirdPartyLibsModule} from '../alocpro-third-party-libs.module';
import { MainAccountsComponent } from 'app/Forms/main-accounts/main-accounts.component';
import { InspectionsV1Component } from './inspections/inspectionsV1/inspections.component';
import { InspectionsV2Component } from './inspections/inspectionsV2/inspections.component';
import { DamagesComponent } from './damages/damages.component';
import { PricingDetailsComponent } from './pricing-details/pricing-details.component';
import { FTComponent } from './ft/ft.component';
import { QuickQuoteComponent } from './quick-quote/quick-quote.component';
import { ExamplesComponent } from './examples/examples.component';
import { CustomersComponent } from './customers/customers.component';
import { DriversComponent } from './drivers/drivers.component';
import { PricingTimesComponent } from './pricing-times/pricing-times.component';
import { PricingMethodsComponent } from './pricing-methods/pricing-methods.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsPreviewComponent } from './report-preview/report-preview.component';
import { PricingsComponent } from './pricings/pricings.component';
import { ZoomsComponent } from './zooms/zooms.component';

@NgModule({
  imports: [
    AlocproThirdPartyLibsModule,
    AppModuleDetailForm,
    FormComponentModule,
    AppModuleLp,
    AppModuleUI,
  ],
  declarations: [
    ModelsComponent,
    LoginComponent,
    ExpensesComponent,
    ShortTermContractsComponent,
    UsersComponent,
    LocationsComponent,
    CompaniesComponent,
    PricingDetailsComponent,
    PricingTimesComponent,
    EquipmentsComponent,
    FinesComponent,
    ThirdPartiesComponent,
    CustomersComponent,
    DriversComponent,
    InvoicesComponent,
    PlanningComponent,
    MovementsComponent,
    AddressesComponent,
    ServicesComponent,
    PaymentMethodComponent,
    MainAccountsComponent,
    InspectionsV1Component,
    InspectionsV2Component,
    DamagesComponent,
    FTComponent,
    QuickQuoteComponent,
    PricingsComponent,
    ExamplesComponent,
    PricingMethodsComponent,
    MaintenanceComponent,
    ReportsComponent,
    ReportsPreviewComponent,
    ZoomsComponent
  ]
})

export class AppModuleForms {
}
