import { Component, OnInit } from '@angular/core';
import { LpPricingDetail } from 'app/meta/pricing-detail';
import { PricingDetailsColumn } from 'app/models/pricing-details-column';
import * as _ from 'underscore'
import { Util } from 'app/statics/utils';
import { RootformComponent } from '../rootform/rootform.component';
import { LpPricingDetailsFilters } from 'app/meta/pricing-details-filters';


const PRICING_DETAIL: string = 'pricing-details';
@Component({
  selector: 'lp-pricing-details',
  templateUrl: './pricing-details.component.html'
})

export class PricingDetailsComponent extends RootformComponent implements OnInit {
  public showListColumn: Boolean = false;
  public listPricingDetails: LpPricingDetail[] = [];
  public availableColums: PricingDetailsColumn[];

  public async ngOnInit(): Promise<void> {
    this.changeService.initChange();
    this.formStackService.initLpMeta('pricing-details');
    this.data = this.formStackService.currentData;
    this.formStackService.currentData.id = '0'
    await this.formStackService.initApplicationItem(PRICING_DETAIL);
    this.ky = this.route.snapshot.params['ky'];
    this.verb = PRICING_DETAIL;
    this.formStackService.CurrentApplicationItemDetailData = [];
    this.formStackService.CurrentKy = this.ky;
    this.formStackService.CurrentVerb = this.verb;
    this.uiSyncService.IsKyForm = true;
    this.uiSyncService.IsReadyToDisplayForm = true;
    this.formStackService.currentApplicationItemDetail = await this.repositoryProvider
      .getApplicationItemDetails(this.formStackService.currentApplicationItem.details[0].key.toString())
    this.rightColService.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail);

  }

  public async search(): Promise<void> {
    this.listPricingDetails = [];
    this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.loadPricingDetailsTable'));
    /* this.formStackService.currentApplicationItemDetail = await this.repositoryProvider
      .getApplicationItemDetails(this.formStackService.currentApplicationItem.details[0].key.toString()) */
    let filters: LpPricingDetailsFilters = new LpPricingDetailsFilters(this.data['pricing'], this.data['pricingTime'], this.data['thirdParty'],
    this.data['country'], this.data['company'], this.data['location'], this.data['model'], this.data['equipmentType'],
    this.data['category'], this.data['acriss'], this.data['service']);
    this.formStackService.CurrentApplicationItemDetailData = await this.formStackService.getPricingList(filters);
    this.formStackService.CurrentApplicationItemDetailDataCount = this.formStackService.CurrentApplicationItemDetailData.length;
    this.uiSyncService.loader(false);
    // this.rightColService.currentApplicationItemDetailLoading = this.formStackService.currentApplicationItemDetail.id;
    this.formStackService.initLpLine(this.formStackService.currentApplicationItemDetail.key.toString());
  }

  public activeColums(): PricingDetailsColumn[] {
    let activeColums: PricingDetailsColumn[] = [];
    for (let index: number = 0; index < this.availableColums.length; index++) {
      const column: PricingDetailsColumn = this.availableColums[index];
      if (column['active']) {
        activeColums.push(column);
      }
    }
    return activeColums;
  }

  public onSort(event: any): void {
    if (Util.isNullOrUndefined(event.prevValue)) {
      this.listPricingDetails =  _.sortBy(this.listPricingDetails, event.sorts[0].prop);
    } else {
      this.listPricingDetails = this.listPricingDetails.reverse()
    }
  }
}
