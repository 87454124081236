import { Injectable } from '@angular/core';
import { AlocproProvider } from '../providers/alocpro.provider';
import { Util } from 'app/statics/utils';
import { HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

const ATTACHMENTS: String = 'technical/attachments';
const SHORT_TERM_CONTACT: String = 'short-term-contracts';

@Injectable({
    providedIn: 'root'
  })

export class CommonToolsService {

    constructor(private alocproProvider: AlocproProvider,
                private configService: ConfigService) { };

    public async hasMemos(linkedObject: String, ky: String): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            let params: HttpParams = new HttpParams();
            if (!Util.isNullOrUndefined(linkedObject)) {
                params = params.set('object', linkedObject.toString())
            }
            params = params.set('key', ky.toString());
            this.alocproProvider.getTotalCount(`${this.configService.get('alocproServiceUrl')}${ATTACHMENTS}`, params).then((result: number) => {
                if (Util.isNullOrUndefined(ky)) {
                    resolve(0);
                } else {
                    resolve(result);
                }
            });
        });
    }

    public async putDeductibleBuyBacks(ky: string, mode: string): Promise<void> {
        try {
            await this.alocproProvider.putDeductibleBuyBacks(SHORT_TERM_CONTACT, ky, mode);
        } catch (error) {
            throw error;
        }
    }

}
