<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="third-parties.addresses.addAdresse" [open]=true>
    <form id="formAddressesDetailForm" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

      <!-- Field addresses TODO wait url PUT before TODO RIGHT
      <lp-zoom-field id="addresses.addresses" zoomId="addresses.addresses" name="addresses.addresses" [(ngModel)]="data.addresses"
      class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
      </lp-zoom-field>-->
    

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>