import { FtMeta } from "../ft-meta";
import { LpMeta } from "../meta";

export class Pricing extends LpMeta {
    id: string
    wording: string
    pricingMethods: Array<PricingMethod>;
    specificPricingMethods?: Array<SpecificPricingMethod>;
}

export class PricingMethod extends LpMeta {
    id: string;
    wording: string;
    includedDays: number;
    includedKilometers: number;
    category: any = new FtMeta();
    isActive: boolean;
    amount: Amount = new Amount();
    pricingTime: PricingTime = new PricingTime();
}

class Amount {
    day: number;
    additionnalDay: number;
    kilometers: number;
    additionnalKilometers: number;
    fullDeductibleBuyBack: number
    reducedDeductibleBuyback: number;
    deductibleBuyBack: number;
    specificDay: number;
    discountRate: number;
}

export class SpecificPricingMethod extends PricingMethod {
    linkedId: string;
}

class PricingTime {
    id: string;
    startDate: string;
    endDate: string;
}