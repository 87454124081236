<div class="modal-header">
<h4 class="modal-title pull-left">{{'changePassword.title' | translate}}</h4>

<div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
</div>
</div>
<div class="modal-body">
<div class="form-group col-12">
  <lp-label-field for="oldPassword" text="changePassword.oldPassword"></lp-label-field>      
  <lp-text-field id="oldPassword" className="textField" cssClass="form-control" type="password"
    name="oldPassword" [ignoreChange]="true" [(ngModel)]="dataPassword.oldPassword" [required]=true></lp-text-field>
</div>

<div class="form-group col-12">
  <lp-label-field for="newPassword" text="changePassword.newPassword"></lp-label-field>      
  <lp-text-field id="newPassword" className="textField" cssClass="form-control" type="password"
    name="newPassword" [ignoreChange]="true" [(ngModel)]="dataPassword.newPassword" [required]=true></lp-text-field>
</div>

<div class="form-group col-12">
  <lp-label-field for="newPassword2" text="changePassword.newPassword2"></lp-label-field>      
  <lp-text-field id="newPassword2" className="textField" cssClass="form-control" type="password"
    name="newPassword2" [ignoreChange]="true" [(ngModel)]="dataPassword.newPassword2" [required]=true></lp-text-field>
</div>

<div class="col-12 text-center mb-4">
  <button type="button" class="btn btn-primary" [disabled]="(!isValid()) ? true : false" (click)="validForm()">{{'changePassword.btn' | translate}}</button>
</div>
</div>
