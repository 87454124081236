import { Component, OnDestroy, OnInit, AfterViewChecked } from '@angular/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { Subscription } from 'rxjs';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LpMeta, RELATION_FATHER, RELATION_SON } from 'app/meta/meta';
import { Util } from 'app/statics/utils';
import { Preference } from 'app/models/user.interface';
import { FormStackService, SCREEN_TEST } from 'app/services/form-stack.service';
import { RightColService } from 'app/services/root/rightCol.service';
import { EvalService} from 'app/services/eval.service';
import { ZoomProvider } from 'app/providers/zoom.provider';
import { FormMetadataValues } from 'app/models/form-metadata-values';
import { IhmStackService } from 'app/services/ihm-stack.service';
import * as _ from 'underscore'
import { FormService } from 'app/services/root/form.service';
import { ConfigService } from 'app/services/config.service';

export const  DRIVER_ACCOUNTING_EVENT: string = 'driverAccountingEvents';
export const CUSTOMER_INVOICE: string = 'customerInvoices';
export const CUSTOMER_ACCOUNTING_EVENTS: string = 'customerAccountingEvents';
export const ACCOUNTING: string = 'accountings';
export const CUSTOMER_ACCOUNTING: string = 'customerAccountings';
export const DRIVER_ACCOUNTING: string = 'driverAccountings';
export const ACCOUNTING_EVENTS: string = 'AccountingEvents';
export const ADDITIONAL_DRIVERS: string = 'additionalDrivers';
export const ADDRESSES: string = 'addresses';
export const CUSTOMER_ADDRESSES: string = 'customerAddresses';
export const DRIVER_ADDRESSES: string = 'driverAddresses';
export const CIRCUMSTANCE_LINKS: string = 'circumstanceLinks';
export const CONTACTS: string = 'contacts';
export const CUSTOMER_CONTACTS: string = 'customerContacts';
export const DRIVER_CONTACTS: string = 'driverContacts';
export const LOCATION_CONTACTS: string = 'locationContacts';
export const CRASH_LINKS: string = 'crashLinks';
export const CUSTOMER_DEPOSITS: string = 'customerDeposits';
export const DEPOSITS: string = 'deposits';
export const DRIVER_DEPOSITS: string = 'driverDeposits';
export const DRIVER_INVOICES: string = 'driverInvoices';
export const EQUIPMENT_RELATIONS: string = 'equipmentsRelations';
export const FACTURATION_LINKS: string = 'facturationLinks';
export const FINANCIAL_ELEMENTS_LINKS: string = 'financialElementsLinks';
export const HISTORICAL_MILEAGE: string = 'historicalMileages';
export const INVOICE_LINES: string = 'invoiceLines';
export const KINDS_LINKS: string = 'kindsLinks';
export const LINK_DAMAGE_HISTORICAL_OPERATIONS: string = 'linkDamageHistoricalOperations';
export const LINK_DAMAGE_VALUE: string = 'linkDamageValue';
export const MOVEMENTS: string = 'movements';
export const OPENNING_HOURS: string = 'openingHours';
export const REGISTRATIONS: string = 'registrations';
export const THIRD_PARTIES_RELATIONS: string = 'thirdPartiesRelations';
export const CUSTOMER_RELATIONS: string = 'customerRelations';
export const DRIVER_RELATIONS: string = 'driverRelations';

export const THIRD_PARTY_LINKS: string = 'thirdPartyLinks';
export const RELATIONS: string = 'relations';
export const RIBS: string = 'ribs';
export const CUSTOMER_RIBS: string = 'customerRibs';
export const DRIVER_RIBS: string = 'driverRibs';
export const SHORT_TERM_CONTRACTS_MEDIAS: string = 'shortTermContractsMedias'
export const SHORT_TERM_CONTRACTS_INVOICE_LINES: string = 'shortTermContractsInvoiceLines';
export const SHORT_TERM_CONTRACTS_DRIVER_INVOICE_LINES: string = 'shortTermContractsDriverInvoiceLines';
export const TECHNICAL_SPECIFICATION: string = 'technicalSpecifications';
export const THIRD_PARTY_DEPOSIT: string = 'thirdPartiesDeposits';
export const CUSTOMER_DEPOSIT: string = 'customerthirdPartiesDeposits';
export const DRIVER_DEPOSIT: string = 'driverthirdPartiesDeposits';
export const THIRD_PARTY_TYPE: string = 'thirdPartyTypes';
export const USER_GROUPS: string = 'userGroups';
export const CUSTOMER_TYPE: string = 'customerTypes';
export const DRIVER_TYPE: string = 'driverTypes';
export const THIRD_PARTY_MANDATES: string = 'thirdPartiesMandates';
export const CUSTOMER_MANDATES: string = 'customerMandates';
export const DRIVER_MANDATES: string = 'driverMandates';
export const LOCATION_SITUATION: string = 'locationsSituations';
export const LOCATION_TYPE: string = 'locationsTypes';
export const LOCATION_SECTOR: string = 'locationsSectors';
export const COMPANIES_IHUBCHECKPLUS: string = 'iHubCheckPlusSettings';

@Component({
  template: ''
})
export class DetailFormsComponent implements OnInit, OnDestroy, AfterViewChecked {

  public data: LpMeta;
  public object: String;
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public debugg: Boolean;
  public idRootFormdetail: Number;
  public id: String;
  /**
   * utilisée lorsque l'on utilise un composant check-box-list.
   */
  public prop: String
  public filter: String;
  public verb: String;
  public api: String;
  public relations: Object[] = [];
  protected available: any[] = [];
  protected selected: any[] = [];
  protected binding: any[] = [];
  protected loadObjectRootFormSubscription: Subscription;
    /**
   * URL utilisée pour récupérer les infos de l'item à modifier
   */
  protected urlGet: String;
  protected urlEdit: String;
  protected resultObject: Boolean;

  constructor(
              protected configService: ConfigService,
              public rightColService: RightColService,
              protected uiSyncService: UiSyncService,
              private repositoryProvider: RepositoryProvider,
              protected alocproProvider: AlocproProvider,
              protected modalService: ModalService,
              protected translate: TranslateService,
              public formStackService: FormStackService,
              protected evalService: EvalService,
              protected zoomProvider: ZoomProvider,
              private ihmService: IhmStackService,
              private formService: FormService) {
    this.repositoryProvider.getPreferences().then((preferences: Preference) => {
      if (preferences) {
        this.debugg = preferences.debug;
      }
    });
  }

  public ngOnInit(): void {
    this.relations.push({
      'id': RELATION_SON,
      'wording': this.translate.instant('third-parties.thirdPartiesRelations.isSon')
    });
    this.relations.push({
      'id': RELATION_FATHER,
      'wording': this.translate.instant('third-parties.thirdPartiesRelations.isFather')
    });
  }

  public ngAfterViewChecked(): void {
    //let alreadyExists: boolean = !Util.isNullOrUndefined(this.data) && !Util.isNullOrUndefined(this.data.id)  ;
    let formMeta: FormMetadataValues = {
      'fromPlanning': this.formStackService.isPreviousStackPlanning(),
      'isNewForm': false,
      'subApi': true
    };
    this.uiSyncService.emitFormMetadataLoaded(formMeta);
}

  public ngOnDestroy(): void {
    if (this.loadObjectRootFormSubscription) {
      this.loadObjectRootFormSubscription.unsubscribe();
    }
  }

   public async submit(): Promise<void> {
    this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.updateInProgress'));
    if (this.formStackService.CurrentVerb === SCREEN_TEST) {
      this.submitExamples();
    } else {
      await this.rightColService.submitDetail(this.data);
    }
    if (this.formStackService.currentApplicationItemDetail.reloadAfterSubmit) {
      this.formService.build(this.formStackService.CurrentVerb, this.formStackService.CurrentKy)
    }
  }

  public setId(id: String): void {
    this.id = id;
  }

  protected initData(): void {
    this.data = this.formStackService.currentDetailLineData;
  }

  protected initUrl(): void {
    if (
      !Util.isNullOrUndefined(this.formStackService) && !Util.isNullOrUndefined(this.formStackService.currentApplicationItem)
      && !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail)) {
      this.urlGet = this.formStackService.currentApplicationItemDetail.path;
      this.urlEdit = this.urlGet;
    }
  }

  private submitExamples(): void {
    this.ihmService.ShowSubAPIDetailsTable = false
    if (!Util.isNullOrUndefined(this.data) && !Util.isNullOrUndefined(this.data.id)) {
      for (let i: number = 0; i < this.formStackService.CurrentApplicationItemDetailData.length; i++) {
        if (this.formStackService.CurrentApplicationItemDetailData[i].id === this.data.id) {
          this.formStackService.CurrentApplicationItemDetailData[i] = this.data;
        }
      }
    } else {
      let id: string = _.max(this.formStackService.CurrentApplicationItemDetailData,
         function(a: LpMeta): String { return a.id; })['id'].toString();
      this.data.id = (parseInt(id, 10) + 1).toString();
      this.formStackService.CurrentApplicationItemDetailData.push(this.data);
    }
    setTimeout(() => {
      this.rightColService.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail, this.formStackService.currentData);
        this.uiSyncService.loader(false);
    }, 0);
  }
}
