<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<span [hidden]="this.formStackService.getFields(name).hidden">
      <input id="for{{id}}" name="{{name | translate}}" class="{{cssClass}} hasIconClass" title=""
      [ngStyle]="style" (keydown)="keyDown($event, id)" (focus)="focus($event)" (keyup)="change($event, true)" (keypress)="onKeypressEvent($event, id)"
      on-focusout="pushChange($event);" [attr.minlength]="minlength" [attr.maxlength]="maxlength"
      placeholder="{{placeholder |translate}}" [disabled]="readonly" autocomplete="{{autocomplete}}"
      [value]="valueShowed"
      
      [pattern]="getPattern()"
      [title]="getTitle()"
      [class.is-invalid]="invalid"
      [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [readonly]="readonly" [class.hasIconClass]="!this.formStackService.getFields(name).hidden"/>
      <input type="hidden" [value]="displayValue" />
      <span class="input-group-addon" *ngIf="!this.formStackService.getFields(name).hidden">
          <i class="fa fa-sort-numeric-asc "></i>
      </span>
      <i class="input-notif"></i>
 </span>
