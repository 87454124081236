import { Component, OnInit } from '@angular/core';
import { RootformComponent } from '../rootform/rootform.component';
import { LpPricingTime } from 'app/meta/pricing-time';


@Component({
  selector: 'lp-pricing-times',
  templateUrl: './pricing-times.component.html'
})

export class PricingTimesComponent extends RootformComponent implements OnInit {

  public data: LpPricingTime = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('pricing-times', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
