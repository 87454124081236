<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'edis.titleList' | translate}}</h4>


  <div class="pull-right">
    <span (click)="changeConfigPanel()" class="me-2">
      <i class="icon fa fa-cogs icon-tableSetings ediTableSettings"></i>
    </span>
    <lp-button-new (click)="actionOnEdi('NEW')"></lp-button-new>
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
 
</div>
<div class="modal-body">
  <ngx-datatable 
    *ngIf="edis" 
    class="material table-height-35" 
    [rows]="edis"
    (activate)="actionOnEdi($event)"
    [columnMode]="'force'" 
    [headerHeight]="35" 
    [rowHeight]="'auto'"
    [messages]="{emptyMessage: 'general.emptyMessage' |translate}" 
    [rowClass]="rowClass"
    [footerHeight]="(ediStruct?.details[0]?.pagination || ediStruct?.details[0]?.bulk) ? 35 : 0"
    [loadingIndicator]="loadingIndicator" 
    (page)="changePage($event)"
    (sort)="onSort($event)"
    width="100%">

    <ngx-datatable-column *ngFor="let col of getDisplayedColumns() index as colIndex"
      name="{{(col.wording !== null && col.wording !== undefined) ? col.wording : ((col.prop === 'actionColumn') ? '' : ediStruct?.details[0]?.key + '.' + col.name |translate)}}"
      [width]="col.width" [resizeable]="col.resizeable"
      [sortable]="col.sortable" [draggable]="col.draggable"
      data-prop="{{col.prop}}" [canAutoResize]="col.canAutoResize" [hidden]="!col.isDisplayed">

      <!-- template de base -->
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row" let-i="index">

        <div>
          <lp-cell
            [type]="col.type"
            [value]="jsdataService.getDataRef(edis[rowIndex], col.prop)">
          </lp-cell>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer
      *ngIf="ediStruct?.details[0]?.pagination || ediStruct?.details[0]?.bulk">
      <ng-template ngx-datatable-footer-template>
        <lp-footer *ngIf="ediStruct?.details[0]?.pagination" [guid]="guid"
          (changePage)="changePage($event, ediStruct?.details[0]?.bulk)"
          [dataCount]="nbEdi"
          [linesPerPage]="userService.getCurrentUser().preference.linesPerPage" [currentPage]="currentPage"
          [allLines]=true [showAddRow]="ediStruct?.details[0]?.bulk" (addRow)="addRow($event)">
        </lp-footer>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <div *ngIf="showConfigPanel" class="detail-config-tab">
    <lp-ui-sort-list [isBulk]="ediStruct?.details[0]?.bulk" [isNotADetailButAModal]='true' [list]="ediStruct.details[0].columns" prefix="{{ediStruct?.details[0]?.key + '.'}}" label="title" prop="isDisplayed"></lp-ui-sort-list>
  </div>
</div>

