import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, THIRD_PARTY_MANDATES} from '../detail-forms.component';
import { LpMandate } from 'app/meta/mandates';

@Component({
  selector: 'lp-mandates',
  templateUrl: './mandates.component.html'
})
export class MandatesComponent extends DetailFormsComponent implements OnInit {

  public data: LpMandate = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
      this.setId(THIRD_PARTY_MANDATES);
      this.object = THIRD_PARTY_MANDATES;
    this.initUrl();

  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }
}
