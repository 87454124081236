import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LpModalComponent } from '../lp-modal.component';
import { ReportService } from 'app/services/report.service';
import { FormStackService } from 'app/services/form-stack.service';
import { EvalService } from 'app/services/eval.service';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-modal-mail',
  templateUrl: './lp-modal-mail.component.html'
})
export class LpModalMailComponent extends LpModalComponent {

  public mail: string;

  constructor(
    public formStackService: FormStackService,
    public evalService: EvalService,
    public reportService: ReportService,
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef, data);
  }

  public async ngOnInit(): Promise<void> {
    this.mail = await this.assignMail();
  }

  public setReportMail(): void {
    this.reportService.mail = this.mail;
  }

  private async assignMail(): Promise<string> {
    if ( !Util.isNullOrUndefined( this.formStackService) &&
         !Util.isNullOrUndefined( this.formStackService.currentApplicationItemDetail) &&
         !Util.isNullOrUndefined( this.formStackService.currentApplicationItemDetail.reportObject) &&
         !Util.isNullOrUndefined( this.formStackService.currentApplicationItemDetail.reportObject.reportDefaultMailAddressEval)
            ) {
                let email: string = await this.evalService.summaryEval( 
                  null,
                  this.formStackService.currentApplicationItemDetail.reportObject.reportDefaultMailAddressEval, 
                  this.formStackService
                );
        return email;

    }
  }

}
