export class LpReportParameters {

    public name: string;
    public wording: string;
    public type: string;
    public value: any;
    public listItem: Array<LpReportListItem>

    public formatForHTTPParams(): String {
        return this.name + '=' + this.value;
    }
}

export class LpReportListItem {
    public name: String;
}
