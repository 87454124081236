<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="equipments.equipmentsRelations.titleTab" [open]=true>
    <form id="formRelationsEquipments" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>
      <div class="row p-0 m-0">
        <!-- Field equipmentLinkType -->
        <div class="form-group col-xl-6">
          <lp-label-field for="equipmentsRelations.equipmentLinkType" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="equipments.equipmentsRelations.equipmentLinkType"></lp-label-field>
          <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
            name="equipmentsRelations.equipmentLinkType" [(ngModel)]="data.equipmentLinkType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'equipmentLinkType'}]}"></lp-dropdown-field>
        </div>

        <!-- Field isActive -->
        <div class="form-group col-xl-6">
          <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
            text="equipments.equipmentsRelations.isActive" for="equipmentsRelations.isActive"></lp-label-field>
          <lp-boolean-switch-box name="equipmentsRelations.isActive" id="equipmentsRelations.isActive" switchName="isActive" [(ngModel)]="data.isActive"></lp-boolean-switch-box>
        </div>

      </div>
      <div class="row p-0 m-0">
        <!-- no Field  -->
        <div class="form-group col-xl-6">
          <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
            text="equipments.equipmentsRelations.relation" for="equipmentsRelations.relation" [required]=true></lp-label-field>
            <lp-switch-box class="{{arrayClassesElements.get('defaultInput')}}" inputBox="tabSwitch" name="equipmentsRelations.state" id="relation" (change)="changeRelation()"
              [values]="relations"
              switchName="state" [(ngModel)]="data._relationSwitch" [required]=true></lp-switch-box>
        </div>

        <!-- Field father -->
        <div class="form-group col-xl-6" *ngIf="isSon()">
          <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
            text="equipments.equipmentsRelations.father" for="equipmentsRelationsequipmentsRelations.father"></lp-label-field>
          <lp-zoom-field id="equipmentsRelations.father" zoomId="equipments.equipments" name="equipmentsRelations.father" [(ngModel)]="data.father"
          class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
          </lp-zoom-field>
        </div>

        <!-- Field son -->
        <div class="form-group col-xl-6" *ngIf="isFather()">
          <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
            text="equipments.equipmentsRelations.son" for="equipmentsRelations.son"></lp-label-field>
          <lp-zoom-field id="equipmentsRelations.son" class="{{arrayClassesElements.get('defaultInput')}}"
            zoomId='equipments.equipments' className="zoomField form-control"
            [(ngModel)]="data.son" name="equipmentsRelations.son">
          </lp-zoom-field>
        </div>
      </div>

      <!-- Champ dateStart -->
      <div class="form-group col-xl-6">
        <lp-label-field for="equipmentsRelations.dateStart" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="equipments.equipmentsRelations.dateStart"></lp-label-field>
        <lp-date id="equipmentsRelations.dateStart" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="equipmentsRelations.dateStart"
          [(ngModel)]="data.dateStart"></lp-date>
      </div>

      <!-- Champ dateEnd -->
      <div class="form-group col-xl-6">
        <lp-label-field for="equipmentsRelations.dateEnd" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="equipments.equipmentsRelations.dateEnd"></lp-label-field>
        <lp-date id="equipmentsRelations.dateEnd" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="equipmentsRelations.dateEnd"
          [(ngModel)]="data.dateEnd"></lp-date>
      </div>

      <!-- Champ numberOfRent
        <div class="form-group col-xl-12">
        <lp-label-field for="dateEnd" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="equipments.equipmentsRelations.numberOfRent"></lp-label-field>
        <lp-number id="numberOfRent" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="numberOfRent"
          [(ngModel)]="data.numberOfRent"
          minlength="3" placeholder="equipments.equipmentsRelations.numberOfRent"></lp-number>
      </div>

      <! -- Field divers1 -- >
      <div class="form-group col-xl-12">
        <lp-label-field for="divers1" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="equipments.equipmentsRelations.divers1"></lp-label-field>
        <lp-text-field id="divers1" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
          name="divers1" [(ngModel)]="data.divers1"></lp-text-field>
      </div>

      < !-- Field divers2 -- >
      <div class="form-group col-xl-12">
        <lp-label-field for="divers2" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="equipments.equipmentsRelations.divers2"></lp-label-field>
        <lp-text-field id="divers2" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
          name="divers2" [(ngModel)]="data.divers2"></lp-text-field>
      </div>

      < !-- Field sonModel -- >
      <div class="form-group col-xl-12">
        <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
          text="equipments.equipmentsRelations.sonModel"></lp-label-field>
        <lp-zoom-field id="sonModel" class="{{arrayClassesElements.get('defaultInput')}}"
          addModifyVerb="models" kySearch="id" libSearch="id" zoomId='models' className="zoomField" cssClass="form-control"
          [(ngModel)]="data.sonModel" [inputSearch]="data.sonModel?.id" searchTextField="id" [tableBindValues]="['id', 'label','wording']"
          [tableShowField]="['label']" name="sonModel"
          [modal]="true"
          idRootFormdetail="{{idRootFormdetail}}" [dataRootFormdetail]="data"
          [parentZoomBindFields]="['personalModel']" [parentTableBindValues]="['personalModel']"
          >
        </lp-zoom-field>
      </div> -->

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>