<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.linkDamageHistoricalOperations.titleTab" [open]=true>
      <form id="formLinkDamageHistoricalOperation" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

        <div class="form-group col-xl-12">
          <lp-label-field for="linkDamageHistoricalOperations.operationKind" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.operationKind"></lp-label-field>
          <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="linkDamageHistoricalOperations.operationKind" [(ngModel)]="data.operationKind" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'operationKind'}]}">
          </lp-dropdown-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="linkDamageHistoricalOperations.date" class="{{arrayClassesElements.get('defaultLabel')}}" text="accountingEvents.date"></lp-label-field>
          <lp-date [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}" id="linkDamageHistoricalOperations.date"
          cssClass="form-control" name="linkDamageHistoricalOperations.date" placeholder="accountingEvents.date"
          [required]=true></lp-date>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="linkDamageHistoricalOperations.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wording"></lp-label-field>
          <lp-text-field id="linkDamageHistoricalOperations.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageHistoricalOperations.wording"
            [(ngModel)]="data.wording" placeholder="damages.wording"></lp-text-field>
        </div>

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>