import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';
import { LpThirdParty } from './third-party';
import { propertyMeta, LpMeta } from './meta';

export class LpExample extends LpMeta {

    @Type(() => FtMeta)
    public dropDown: FtMeta = new FtMeta();
    @Type(() => LpThirdParty)
    public zoom: LpThirdParty = new LpThirdParty();
    @Type(() => LpThirdParty)
    public customCodeZoom: LpThirdParty = new LpThirdParty();
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('boolean')
    public boolean: boolean = null;
    @propertyMeta('string')
    public string: String;
    @propertyMeta('number')
    public number: Number;
    @propertyMeta('number')
    public gauge: Number;
    @propertyMeta('number')
    public currency: Number;
    @propertyMeta('date')
    public dateTimeJetLag: Date;
}
