import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, OPENNING_HOURS } from '../detail-forms.component';
import { OpeningHour } from 'app/meta/opening-hour';

@Component({
  selector: 'lp-opening-hours',
  templateUrl: './opening-hours.component.html'
})
export class OpeningHoursComponent extends DetailFormsComponent implements OnInit {

  public daysOfWeek: Object[];
  public data: OpeningHour = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(OPENNING_HOURS);
    this.initUrl();
    // this.init();
    this.daysOfWeek = [
      { id: '1', description: this.translate.instant('dropdown.monday') },
      { id: '2', description: this.translate.instant('dropdown.tuesday') },
      { id: '3', description: this.translate.instant('dropdown.wednesday') },
      { id: '4', description: this.translate.instant('dropdown.thursday') },
      { id: '5', description: this.translate.instant('dropdown.friday') },
      { id: '6', description: this.translate.instant('dropdown.saturday') },
      { id: '7', description: this.translate.instant('dropdown.sunday') }
    ]
  }

    public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }
}
