import { Component, OnInit, Input } from '@angular/core';
import { InputComponent } from '../input.component';

@Component({
  template: ''
})
export class FieldComponent extends InputComponent implements OnInit {

  // Le paramètre Readonly permet d'interdire la modification du champ.
  @Input() public readonly: Boolean = false;

  // Le paramètre Disabled permet de désactiver la modification d'un champ.
  @Input() public disabled: Boolean = false;

}
