import { EventEmitter, Injectable } from '@angular/core';
import { LpMeta } from 'app/meta/meta';
import { ZoomCriteria, ZoomField, ZoomFieldIHM, ZoomIHM, ZoomMeta } from 'app/meta/zoom/zoomMeta';
import { ApplicationItem } from 'app/models/application-item';
import { PaginatedData } from 'app/models/paginated-data';
import { ZoomProvider } from 'app/providers/zoom.provider';
import { LpModalPromiseZoomComponent } from 'app/ui/lp-modal/lp-modal-zoom/lp-modal-zoom.component';
import * as _ from 'underscore';
import { Util } from 'app/statics/utils';

import { ZoomConfig, ZoomMetaData, ZoomMetaDataColumn } from '../models/zoom-metada';
import { FormStackService } from './form-stack.service';
import { IhmStackService } from './ihm-stack.service';
import { ModalService } from './modal.service';
import { RightColService } from './root/rightCol.service';
import { RouterService } from './router.service';
import { UiSyncService } from './ui-sync.service';

export const SUCCESS: String = 'SUCCESS';
export const DASHBOARD_REDIRECT_LINK: String = '_navigate';
const COLUMNS_MODE: string = 'standard';

// TODO ZOOM ajouter un 'locProZoomService' toutes les methodes devront etre réimplémentée
@Injectable({
  providedIn: 'root',
})
export class ZoomService {

  public updateDefaultValueFieldEvtEmitter: EventEmitter<any> = new EventEmitter();

  public selectEventEmitter: EventEmitter<Object> = new EventEmitter();
  public closeEventEmitter: EventEmitter<boolean> = new EventEmitter();
  public displayPlanning: EventEmitter<boolean> = new EventEmitter();
  public closeAllRightBlockEventEmitter: EventEmitter<boolean> = new EventEmitter();
  public data: LpMeta[];
  public zoomParameter: any;
  public appearBanner: Boolean;
  public finalMapGuidCompetitive: Map<string, ZoomConfig> = new Map();
  public zoomStackParam: Map<number, string> = new Map();
  public addModifyVerb: string = null;
  public loadingEquipmentZoom: Boolean = false;
  public currentGuid: string;
  /**
   * L'événement refreshZoomFieldEvtEmitter est utilisée lorsque l'utilisateur a sélectionné une ligne  dans le type ahead.
   */
  public refreshZoomFieldEvtEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * L'événement searchEventEvtEmitter est utilisée lorsque l'utilisateur veut unvrir un zoom.
   */
  public searchEventEvtEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * L'événement selectItemFromZoomEvtEmitter est utilisée lorsque l'utilisateur a sélectionné une ligne  dans le zoom.
   */
  public selectItemFromZoomEvtEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * L'événement selectItemFromZoomEvtEmitter est utilisée lorsque l'utilisateur a sélectionné une ligne  dans le scheduler zoom.
   */
  public selectItemFromSchedulerZoomEvtEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * L'événement reInitZoomEvtEmitter est utilisée lorsque l'utilisateur veut réinitialiser le zoom.
   */
  public reInitZoomEvtEmitter: EventEmitter<any> = new EventEmitter();

  public readyToInitZoom: EventEmitter<void> = new EventEmitter();
  
  public refreshZoomDisplayValueEvtEmitter: EventEmitter<void> = new EventEmitter();

  /**
   * La propriété configs contient les configurations de tous les zoom-fields présents dans la page.
   * Lors de l'affichage d'un zoomChild, le composant Zoom viendra piocher la configuration souhaitée par rapport à l'IDzoom.
   */

  constructor(/*private mapZoomConfig: MapZoomService,*/
              private zoomProvider: ZoomProvider,
              private formStackService: FormStackService,
              private rightColService: RightColService,
              public ihmService: IhmStackService,
              private routerService: RouterService,
              private modalService: ModalService,
              private uiSyncService: UiSyncService) {
  }

  public getConfig(guid: string): ZoomConfig {
    if (guid === null || guid === undefined) {
      return this.finalMapGuidCompetitive.get(this.currentGuid);
    } else {
      return this.finalMapGuidCompetitive.get(guid);
    }
  }

  public clearConfig(guid: string): void {
    if (guid === null || guid === undefined) {
      this.finalMapGuidCompetitive.delete(this.currentGuid);
    } else {
      this.finalMapGuidCompetitive.delete(guid);
    }
  }

  public isDropDown(criteria: ZoomCriteria): boolean {
    let isDropDown: boolean = false;
    if (criteria && criteria.type && criteria.type === 'List') {
      isDropDown = true;
    }
    return isDropDown;
  }

  public preparePathForDropDown(criteria: ZoomCriteria, guid: string): ZoomCriteria {
    // const url: string = `technical/zoom-links/${this.getId(guid)}/execute?attribute=${criteria.paramName}&object=zoom`;
    // criteria.path = url;
    return criteria;
  }

  public async displayZoom(config: ZoomConfig, guid?: string, param?: string, parentZoomGuid?: string): Promise<void> {
    try {
      if (!this.hasParentZoom(guid)) {
        // this.selectEventEmitter.complete();
        // this.selectEventEmitter = new EventEmitter();
      }
      await this.setConfig(config, guid);
      if (this.hasParentZoom(guid)) {

        if (!config.modal) {
          setTimeout(() => {
            this.uiSyncService.displayZoomEvt(param, guid);
          }, 500);
        } else {
          this.modalService.modalPromise(LpModalPromiseZoomComponent, {
            width: '80vw',
            backdropClass: 'zoomBackdropClass',
            data: { 'param': param, 'guid': guid, 'config': config },
          });
        }
      } else {
        /*
         FIX problème de non-ouverture de zoom sur la colonne de droite
         lors de la fermeture d'un zoom de critère de type zoomfield.
         Tâche V8003-1168.
         */
        if (!config.modal) {
          this.ihmService.displayRightZoomComponent();

          setTimeout(() => {
            this.uiSyncService.displayZoomEvt(null, guid);
          });
        } else {
          this.formStackService.mainZoomBody = false;
          var backdropClass: String[] = ['zoomBackdropClass'];
          var width: string = '80vw';
          if (config.locProZoomAttribut === "main") {
            this.formStackService.mainZoomBody = true;
            backdropClass = ['zoomBackdropClass', 'mainzoomBackdropClass'];
            width = null;
          } 
          
          this.modalService.modalPromise(LpModalPromiseZoomComponent, {
            backdropClass: backdropClass,
            width: width,
            id: 'mainZoom',
            data: { 'param': null, 'guid': guid, 'config': config },
          });
        }
      }
      if (parentZoomGuid !== null && parentZoomGuid !== undefined) {
        this.finalMapGuidCompetitive.get(guid).parentGuid = parentZoomGuid;
      }
    } catch (err) {
      console.error(err);
      this.uiSyncService.displayZoomEvt();
    }
  }

  public emitSelectItem(object: Object): void {
    const row: Object = object['row'];
    if (!this.getConfig(object['guid']).isLocProZoom) {
      let id: String = row[this.getConfig(object['guid']).kySearch.toString()];
      this.selectEventEmitter.emit({ 'id': id.toString(), 'search': object['search'], 'guid': object['guid'] });
    } else {
      if (this.getConfig(object['guid']) && !Util.isNullOrUndefined(this.getConfig(object['guid']).url)) {
        this.selectEventEmitter.emit({ 'id': object['row']['id'], 'search': object['search'], 'guid': object['guid'] });
      } else {
        this.selectEventEmitter.emit({ 'id': row.toString(), 'search': object['search'], 'guid': object['guid'] });
      }
    }
  }

  public openPlanning(): void {
    this.displayPlanning.emit(true);
  }

  public closePlanning(): void {
    this.displayPlanning.emit(false);
  }

  public selectRowbis(value: any, index: string, param: string, row: Object, guid: string, search?: String): void {
    if (this.hasLinkColumns(this.getConfig(guid))) {
      let path: String = this.getLinkColumns(value, this.getConfig(guid));
      this.routerService.navigate(path);
    } else {
      if (!this.getConfig(guid).isLocProZoom) {
        this.clearConfig(guid);
        this.emitSelectItem({ 'row': row, 'search': search, 'guid': guid });
      } else {
        if (index !== null && index !== undefined) {
          let config: ZoomConfig;
          if (!this.hasParentZoom(guid)) {
            config = this.getConfig(guid);
          } else {
            config = this.getConfig(this.getParentZoomGuid(guid));
          }
          let found: boolean = false;
          (config.zoomParameter.criterias).forEach((e: ZoomCriteria) => {
            if (e.paramName === param) {
              e.defaultValue = value.row.field0;
              found = true;
            }
          });
          if (found) {
            this.zoomStackParam.delete(this.zoomStackParam.size - 1);
            this.finalMapGuidCompetitive.set(this.getParentZoomGuid(guid), config);
          }
          this.emitSelectItem({ 'row': row, 'search': search, 'guid': guid });
          if (this.hasParentZoom(guid)) {
            this.getConfig(this.getParentZoomGuid(guid)).isFromChild = true;
            this.displayZoom(this.getConfig(this.getParentZoomGuid(guid)), this.getParentZoomGuid(guid));
          }
        }
      }
    }
  }

  public closeZoom(): void {
    this.closeEventEmitter.emit(false);
  }

  public async displayLpFieldDetail(hasToDisplayLpFieldDetails: boolean, binding: LpMeta, zoomId: string, ecrId: number
    , config: ZoomConfig): Promise<void> {
    if (hasToDisplayLpFieldDetails) {
      // this.config = this.getConfigByZoomId(zoomId);
      if (!Util.isNullOrUndefined(config)) {
        this.rightColService.displayZoomFieldDetail();
      }
    }
  }

  public reInitZoom(id: String): void {
    this.reInitZoomEvtEmitter.emit(id);
  }

  public refreshZoomDisplayValue(): void {
    this.refreshZoomDisplayValueEvtEmitter.emit();
  }

  public getId(guid: string): string {
    if (this.getConfig(guid).isLocProZoom) {
      return this.getConfig(guid).locProZoomId;
    } else {
      return 'main_' + this.getConfig(guid).addModifyVerb;
    }
  }

  public getLineCount(guid: string): number {
    if (Util.isNullOrUndefined(this.getConfig(guid).zoomParameter)
        || Util.isNullOrUndefined(this.getConfig(guid).zoomParameter.criteriasByLine)) {
      return 0;
    } else {
      return this.getConfig(guid).zoomParameter.criteriasByLine;
    }
  }

  public hasButtonAdd(guid: string): boolean {
    return this.getConfig(guid).zoomParameter.hasButtonAdd;
  }

  public hasButtonCriterias(guid: string): boolean {
    return this.getConfig(guid).zoomParameter.hasButtonCriterias;
  }

  public hasButtonOk(guid: string): boolean {
    return this.getConfig(guid).zoomParameter.hasButtonOk;
  }

  public hasButtonUpdate(guid: string): boolean {
    return this.getConfig(guid).zoomParameter.hasButtonUpdate;
  }

  public getTitle(guid: string): string {
    if (this.getConfig(guid).isLocProZoom) {
      if (!Util.isNullOrUndefined(this.getConfig(guid).zoomParameter) && !Util.isNullOrUndefined(this.getConfig(guid).title)
          && this.getConfig(guid).title.length !== 0) {
        return this.getConfig(guid).title.toString();
      } else {
        return '';
      }
    } else {
      if (!Util.isNullOrUndefined(this.getConfig(guid).title)) {
        return this.getConfig(guid).title.toString();
      }
      return;
    }
  }

  public getCriterias(guid: string): Array<ZoomCriteria> {
    if (!this.getConfig(guid).isLocProZoom) {
      return [{
        defaultValue: null, isMandatory: false, paramName: 'search', type: 'None',
        isReadOnly: false, wording: 'Recherche', isHidden: false, attribute: 'null',
      }];
    } else if (Util.isNullOrUndefined(this.getConfig(guid)) || Util.isNullOrUndefined(this.getConfig(guid).zoomParameter)) {
      return [];
    } else {
      if (this.getConfig(this.getParentZoomGuid(guid)) !== null && this.getConfig(this.getParentZoomGuid(guid)) !== undefined) {
        for (let indexCriteria: number = 0; indexCriteria
                                            <= this.getConfig(this.getParentZoomGuid(guid)).zoomParameter.criterias.length
          ; indexCriteria = indexCriteria + 1) {
          if (this.isDropDown(this.getConfig(guid).zoomParameter.criterias[indexCriteria]) === true) {
            // this.preparePathForDropDown(this.getConfig(guid).zoomParameter.criterias[indexCriteria], guid);
          }
        }
        return this.getConfig(guid).zoomParameter.criterias;
      } else {
        return [];
      }
    }
  }

  public hasToCloseZoom(guid: string): boolean {
    if (!this.hasParentZoom(guid)) {
      return false;
    }
    if (!Util.isNullOrUndefined(this.getConfig(guid))) {
      this.displayZoom(this.getConfig(guid));
      return false;
    }
    return true;
  }

  public clearMap(): void {
    this.finalMapGuidCompetitive.clear();
  }

  public closeAllRightBlock(): void {
    this.closeAllRightBlockEventEmitter.emit();
  }

  public setConfigAction(zoomConfig: ZoomConfig, guid?: string): void {
    this.finalMapGuidCompetitive.delete(guid);
    this.finalMapGuidCompetitive.set(guid, zoomConfig);
  }

  public async setConfig(zoomConfig: ZoomConfig, guid?: string): Promise<void> {
    try {
      this.currentGuid = guid;
      //cas pas dropdown
      if (!this.finalMapGuidCompetitive.has(guid) && Util.isNullOrUndefined(zoomConfig.url)) {
        let param: ZoomIHM = await this.getParams(zoomConfig);
        zoomConfig.zoomParameter = param;
        if (!Util.isNullOrUndefined(guid)) {
          if (!this.finalMapGuidCompetitive.has(guid)) {
            this.finalMapGuidCompetitive.set(guid, zoomConfig);
          }
        }
      }
      // casdropdown
      // alimenté le data avec locprozoomobjetc / Att quand addV null
      if (this.isDropDownMode(zoomConfig)) {
        if (!Util.isNullOrUndefined(zoomConfig) && !Util.isNullOrUndefined(zoomConfig.url)) {
          zoomConfig.data = await this.getDataWithURL(zoomConfig.url);
        } else if (!Util.isNullOrUndefined(zoomConfig)) {
          zoomConfig.data = await this.getZoomFullDataList(String(zoomConfig.locProZoomAttribut), String(zoomConfig.locProZoomObject));
          for (let c of zoomConfig.zoomParameter.criterias) {
            if (!Util.isNullOrUndefined(c.dynamicDefaultValue) && Util.isNullOrUndefined(c.defaultValue)) {
              c.defaultValue = this.zoomProvider.setcriteriaDefaultValue(c);
            }
          }
          //zoomConfig.zoomParameter = await this.getParams(zoomConfig);
        }
      }
      // cas dropdown (path mais avec addModifyVerb !== null)
      if (this.isDropDownModeWithAddModifyVerb(zoomConfig)) {
        if (!Util.isNullOrUndefined(zoomConfig) && !Util.isNullOrUndefined(zoomConfig.url)) {
          zoomConfig.data = await this.getDataWithURL(zoomConfig.url);
        } else if (!Util.isNullOrUndefined(zoomConfig)) {
          zoomConfig.data = await this.getZoomFullDataList(String(zoomConfig.locProZoomAttribut), String(zoomConfig.locProZoomObject));
        }
        /*const params: ZoomMeta = await this.zoomProvider.getParameters(
         zoomConfig.locProZoomAttribut.toString(), zoomConfig.locProZoomObject.toString());
         zoomConfig.locProZoomId = params.id;   */
        if (!Util.isNullOrUndefined(guid)) {
          if (!this.finalMapGuidCompetitive.has(guid)) {
            this.finalMapGuidCompetitive.set(guid, zoomConfig);
          }
        }
      }
      if (zoomConfig.isFromChild) {
        zoomConfig.isFromChild = false;
      } else {
        for (let c of zoomConfig.zoomParameter.criterias) {
          if (!Util.isNullOrUndefined(c.dynamicDefaultValue)) {
            c.defaultValue = this.zoomProvider.setcriteriaDefaultValue(c);
          } else {
            c.defaultValue = null;
          }
        }
      }

    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public getConfigByZoomId(zoomId: string, specificApplicationItem: Boolean): ZoomConfig {
    if (specificApplicationItem) {
      if (!Util.isNullOrUndefined(this.formStackService.specificApplicationItem)
          && !Util.isNullOrUndefined(this.formStackService.specificApplicationItem.childZoom)) {
        let tempZoomConfig: ZoomConfig = new ZoomConfig();
        tempZoomConfig = _.find(this.formStackService.specificApplicationItem.childZoom, (z: ZoomConfig) => {
          return z.zoomId === zoomId;
        });
        if (tempZoomConfig === null || tempZoomConfig === undefined) {
          return null;
        } else {
          return tempZoomConfig;
        }
      } else {
        return null;
      }
    } else {
      if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItem)
          && !Util.isNullOrUndefined(this.formStackService.currentApplicationItem.childZoom)) {
        let tempZoomConfig: ZoomConfig = new ZoomConfig();
        if (zoomId.indexOf('.') > 0) {
          tempZoomConfig = _.find(this.formStackService.currentApplicationItemDetail.childZoom, (z: ZoomConfig) => {
            return z.zoomId === zoomId;
          });
        } else {
          tempZoomConfig = _.find(this.formStackService.currentApplicationItem.childZoom, (z: ZoomConfig) => {
            return z.zoomId === zoomId;
          });
        }
        if (tempZoomConfig === null || tempZoomConfig === undefined) {
          return null;
        } else {
          return tempZoomConfig;
        }
      } else {
        return null;
      }
    }

  }

  public getLpMetaFromKey(data: LpMeta, key: string): any {
    let final: string;
    if (key.indexOf('.') === -1) {
      final = data[key];
    } else {
      let temp: any = data;
      for (let i: number = 0; i < key.split('.').length; i++) {
        if (!Util.isNullOrUndefined(temp[key.split('.')[i]])) {
          temp = temp[key.split('.')[i]];
        } else {
          return null;
        }
      }
      final = temp;
    }
    return final;
  }

  public hasParentZoom(guid: string): boolean {
    if (guid !== null || guid !== undefined) {
      for (let item of Array.from(this.finalMapGuidCompetitive)) {
        if (guid === item[0] && item[1].parentGuid !== null && item[1].parentGuid !== undefined) {
          return true;
        }
      }
    }
    return false;
  }

  public getParentZoomGuid(guid: string): string {
    if (guid === null || guid === undefined) {
      return this.currentGuid;
    }
    return this.getConfig(guid).parentGuid;
  }

  public async getZoomFullDataList(attributZoom: string, objectZoom: string): Promise<PaginatedData> {
    try {
      return await this.zoomProvider.getZoomFullDataList(attributZoom, objectZoom);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getZoomLinksList(attributZoom: string, objectZoom: string): Promise<PaginatedData> {
    try {
      return await this.zoomProvider.getZoomLinksList(attributZoom, objectZoom);
    } catch (error) {
      throw error;
    }
  }

  public async getParameters(attributZoom: string, objectZoom: string): Promise<ZoomMeta> {
    try {
      return await this.zoomProvider.getParameters(attributZoom, objectZoom);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getDataWithURL(url: string): Promise<PaginatedData> {
    try {
      let p: PaginatedData = await this.zoomProvider.getDataWithURL(url);
      return p;
    } catch (error) {
      throw error;
    }
  }

  public isStringMode(zoomConfig: ZoomConfig): boolean {
    return (!Util.isNullOrUndefined(zoomConfig) &&
            !Util.isNullOrUndefined(zoomConfig.isString) &&
            zoomConfig.isString === true) ? true : false;
  }

  public isDropDownMode(zoomConfig: ZoomConfig): boolean {
    return (!Util.isNullOrUndefined(zoomConfig.path) ||
            (!Util.isNullOrUndefined(zoomConfig.locProZoomAttribut) && !Util.isNullOrUndefined(zoomConfig.locProZoomObject)
             && Util.isNullOrUndefined(zoomConfig.addModifyVerb)));
  }

  public isDropDownModeWithAddModifyVerb(zoomConfig: ZoomConfig): boolean {
    return (!Util.isNullOrUndefined(zoomConfig.path) &&
            !Util.isNullOrUndefined(zoomConfig.locProZoomAttribut) && !Util.isNullOrUndefined(zoomConfig.locProZoomObject)
            && !Util.isNullOrUndefined(zoomConfig.addModifyVerb));
  }

  public haveApath(zoomConfig: ZoomConfig): boolean {
    return (!Util.isNullOrUndefined(zoomConfig) && !Util.isNullOrUndefined(zoomConfig.path));
  }

  public haveAaddModifyVerbEqualNull(zoomConfig: ZoomConfig): boolean {
    return (!Util.isNullOrUndefined(zoomConfig) && Util.isNullOrUndefined(zoomConfig.addModifyVerb));
  }

  public getConfigbyBindingProp(key: string, applicationItem: ApplicationItem): ZoomConfig {
    let returned: ZoomConfig;
    let zoomId: string = this.getZoomIdFromKey(key, applicationItem);
    if (!Util.isNullOrUndefined(zoomId)) {
      for (let z of applicationItem.childZoom) {
        if (z.zoomId === zoomId) {
          returned = z;
        }
      }
    }
    return returned;
  }

  public getZoomIdFromKey(key: string, applicationItem: ApplicationItem): string {
    for (let row of applicationItem.editForm.accordions[0].rows) {
      if (row.fields[0].params.zoomId === key) {
        return row.fields[0].params.zoomId;
      }
    }
  }

  public isConfigDataReady(guid: string, zoomConfig: ZoomConfig): boolean {
    let isReady: boolean = false;
    if (Util.isNullOrUndefined(this.getConfig(guid))) {
      if (!this.isDropDownMode(zoomConfig)) {
        return true;
      } else {
        return false;
      }
    }
    if (!Util.isNullOrUndefined(guid) && this.getConfig(guid)) {
      if (!Util.isNullOrUndefined(this.getConfig(guid).data)) {
        isReady = true;
      }
    }
    return isReady;
  }

  private getParams(config: ZoomConfig): Promise<ZoomIHM> {
    return new Promise<ZoomIHM>((resolve, reject) => {
      if (!config.isLocProZoom) {
        return this.zoomProvider.getDataListMetadata(config.addModifyVerb).then((data: any) => {
          let dataForIhm: ZoomIHM = this.initNativeIHMObject(data);
          resolve(dataForIhm);
        });
      } else {
        return this.zoomProvider.getParameters(
          config.locProZoomAttribut.toString(), config.locProZoomObject.toString()).then((d: ZoomMeta) => {
          let dataForIhm: ZoomIHM = this.initLocProIHMObject(d);
          config.locProZoomId = d.id;
          if (!Util.isNullOrUndefined(d.wording)) {
            config.title = d.wording;
          }
          resolve(dataForIhm);
        });
      }
    });
  }

  public async getParametersFromBackend(id:string): Promise<ZoomIHM> {
    try {
      const zoomMeta: ZoomMeta = await  this.zoomProvider.getParametersByID(id);
      return this.initLocProIHMObject(zoomMeta);
    } catch (error) {
      throw error;
    }
  }

  public buildConfig(zoomIHM: ZoomIHM): ZoomConfig {
    let config: ZoomConfig = new ZoomConfig();
    config.id = zoomIHM.id;
    config.isLocProZoom = true;
    config.locProZoomAttribut = 'locProZoomAttribut';
    config.title = zoomIHM.wording;
    config.urlNew = this.formStackService.CurrentVerb; 
    config.exportButton = true;
    config.isFromChild = false;
    config.kySearch = 'id' ;
    config.locProZoomId = zoomIHM.id;
    config.modal = true;
    config.addModifyVerb = this.formStackService.CurrentVerb;
    config.zoomParameter = {
      criteriasByLine: zoomIHM.criteriasByLine,
      hasButtonAdd: zoomIHM.hasButtonAdd,
      hasButtonCriterias: zoomIHM.hasButtonCriterias,
      hasButtonOk: zoomIHM.hasButtonOk,
      hasButtonUpdate: zoomIHM.hasButtonUpdate,
      columns: zoomIHM.columns,
      columnsMode: COLUMNS_MODE,
      criterias: zoomIHM.criterias,
      wording: zoomIHM.wording,
      displayCriterias: zoomIHM.displayCriterias,
      order: zoomIHM.order,
      searchLimit: zoomIHM.searchLimit,
      type: zoomIHM.type
    }
    return config;
 }

  private initLocProIHMObject(d: ZoomMeta): ZoomIHM {
    let temp: any = {};
    temp = this.initBase(d);
    temp.columns = [];
    d.fields.forEach((f: ZoomField) => {
      let columnsForIHM: ZoomFieldIHM = {
        prop: f.id,
        wording: f.wording,
        canAutoResize: true,
        width: f.width,
        type: f.type,
      };
      temp.columns.push(columnsForIHM);
    });

    temp.columnsMode = 'standard';
    let dataForIhm: ZoomIHM = temp;
    return dataForIhm;
  }

  private initNativeIHMObject(d: ZoomMetaData): ZoomIHM {
    let temp: any = {};
    temp = this.initBase(d);
    temp.columns = [];
    temp.path = d.path;
    d.columns.forEach((f: ZoomMetaDataColumn) => {
      let columnsForIHM: ZoomFieldIHM = {
        prop: f.prop,
        wording: f.name,
        canAutoResize: true,
        type: null,
      };
      temp.columns.push(columnsForIHM);
    });
    temp.columnsMode = 'force';
    let dataForIhm: ZoomIHM = temp;
    return dataForIhm;
  }

  private initBase(d: any): ZoomIHM {
    let temp: any = {};
    temp.criterias = d.criterias ? d.criterias : [];
    temp.criteriasByLine = d.criteriasByLine;
    temp.displayCriterias = d.displayCriterias;
    temp.id = d.id;
    temp.order = d.order;
    temp.searchLimit = d.searchLimit;
    temp.wording = d.wording;
    temp.type = d.type;
    if (Util.isNullOrUndefined(d.hasButtonAdd)) {
      temp.hasButtonAdd = true;
    } else {
      temp.hasButtonAdd = d.hasButtonAdd;
    }
    if (Util.isNullOrUndefined(d.hasButtonCriterias)) {
      temp.hasButtonCriterias = true;
    } else {
      temp.hasButtonCriterias = d.hasButtonCriterias;
    }
    if (Util.isNullOrUndefined(d.hasButtonOk)) {
      temp.hasButtonOk = true;
    } else {
      temp.hasButtonOk = d.hasButtonOk;
    }
    if (Util.isNullOrUndefined(d.hasButtonUpdate)) {
      temp.hasButtonUpdate = true;
    } else {
      temp.hasButtonUpdate = d.hasButtonUpdate;
    }
    let dataForIhm: ZoomIHM = temp;
    return dataForIhm;
  }

  /*
   private getConfigFromApplicationItem(config: ZoomConfig): ZoomConfig {
   let tempZoomConfig: ZoomConfig;
   if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.key)) {
   tempZoomConfig = _.find(this.formStackService.currentApplicationItem.childZoom, (z: ZoomConfig) => {
   return z.zoomId === config.zoomId;
   });
   }
   if (tempZoomConfig === null || tempZoomConfig === undefined) {
   return config;
   } else {
   return tempZoomConfig;
   }
   }
   */

  private hasLinkColumns(config: ZoomConfig): boolean {
    for (let c of config.zoomParameter.columns) {
      if (c.wording === DASHBOARD_REDIRECT_LINK) {
        return true;
      }
    }
    return false;
  }

  private getLinkColumns(itemmClick: any, config: ZoomConfig): String {
    for (let c of config.zoomParameter.columns) {
      if (c.wording === DASHBOARD_REDIRECT_LINK) {
        return itemmClick.row[c.prop];
      }
    }
    return null;
  }

}
