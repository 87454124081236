import { Component, OnInit } from '@angular/core';
import { MenuItem, UserInterface } from 'app/models/user.interface';
import { UserService } from 'app/services/user.service';
import { RepositoryProvider } from 'app/providers/repository.provider';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { LpUser } from 'app/meta/user';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { Util } from 'app/statics/utils';

export const USERNAME: string = 'username';
export const APPLICATION_ITEM_ID: string = 'applicationItemId';
export const YOUR_MENU: string = 'cdk-drop-list-0';
export const OUTBAR_UPDATED: string = 'general.modalService.updateOutbar';
export const OUTBAR_DELETED: string = 'general.modalService.deleteOutbar';
export const MENUS: string = 'menus';

@Component({
  selector: 'lp-outbar-preferences',
  templateUrl: './lp-outbar-preferences.component.html'
})
export class LpOutbarPreferencesComponent implements OnInit {
  public outbarMenu: any;
  public tempArray: Array<MenuItem> = [];
  public items: Array<MenuItem> = [];
  public itemsFixed: Array<MenuItem> = [];
  public user: UserInterface;
  public showSavePanel: Boolean = false;

  constructor(
      public userService: UserService,
      public repositoryProvider: RepositoryProvider,
      public modalService: ModalService,
      public translateService: TranslateService
  ) { }

  public async ngOnInit(): Promise<void> {

    this.tempArray = await this.getOutbarUser();
    this.itemsFixed = await this.getAllMenu();
    let itemFixedTemp: Array<MenuItem> = await this.getAllMenu();
    this.items = this.createYourMenuAndCompleteMenu( this.tempArray , itemFixedTemp);
    this.user = this.userService.getCurrentUser();
  }

  public async drop(event: CdkDragDrop<string[]>): Promise<void> {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  public saveConfig(id: string): void {
    if (Util.isNullOrUndefined(id)) {
      
      this.showSavePanel = false;
    } else {
      let menuItems: Array<MenuItem> = [];
      this.updateTempArrayAndItems();
      menuItems = this.tempArray.concat(this.items);
      const menuConfig: String = JSON.stringify(menuItems);
      const finalJson: string = '{' + id + '"menuItems":' + menuConfig + '}';
      this.showSavePanel = false;
      this.repositoryProvider.saveMenu(finalJson).then(() => {
        this.userService.setMenu(menuItems);
        this.modalService.success(this.translateService.instant(OUTBAR_UPDATED), this.translateService.instant(OUTBAR_UPDATED));
      });
    }
  }

  public resetOutbar(): void {
    const lpUser: LpUser = new LpUser;
    lpUser.id = String(this.user[USERNAME]);
    this.repositoryProvider.deleteMenu(lpUser).then(async () => {
      this.userService.setMenu(await this.getFullMenu());
      this.modalService.success(this.translateService.instant(OUTBAR_DELETED), this.translateService.instant(OUTBAR_DELETED));
    });
  }

  public toggleSavePanel(): void {
    if (this.userService.isAdmin()) {
      this.showSavePanel = !this.showSavePanel;
    } else {
      this.saveConfig(`"user": "${this.userService.getCurrentUser().username}", "profile": "*","location": "*","company": "*",`);
    }
  }

  private async getOutbarUser(): Promise<Array<MenuItem>> {
    let array: Array<MenuItem> = [];
    this.outbarMenu = this.userService.getMenu();
    if (this.outbarMenu) {
      for (let i: number = 0; i < this.outbarMenu.length; i = i + 1) {
        if ( this.outbarMenu && this.outbarMenu.length > 0 && this.outbarMenu[i].isOutbar &&
          this.outbarMenu[i].isOutbar !== '' && this.outbarMenu[i].isOutbar !== undefined) {
            array.push(this.outbarMenu[i]);
        }
      }
    }
    return array;
  }

  private async getAllMenu(): Promise<Array<MenuItem>> {
    return await this.userService.getCurrentUser().menu;
  }

  private async getFullMenu(): Promise<MenuItem[]> {
    return new Promise<MenuItem[]>((resolve, reject) => {
      this.repositoryProvider.get('menus', null, null, true).then((menu: any) => {
        resolve(menu.menuItems);
      }, (err) => {
        reject();
      });
    });
  }

  private createYourMenuAndCompleteMenu(arrayMenuItem: Array<MenuItem>, arrayApplicationItem: Array<MenuItem>): Array<MenuItem> {
    let arrayApplicationItemComplete: Array<MenuItem> = [];
     if ( arrayApplicationItem && arrayApplicationItem.length > 0) {
        for (let i: number = 0; i < arrayApplicationItem.length; i = i + 1) {
          for (let j: number = 0; j < arrayMenuItem.length; j = j + 1) {
            if (arrayApplicationItem[i] && arrayMenuItem[j] && String(arrayMenuItem[j][APPLICATION_ITEM_ID]) ===
              String(arrayApplicationItem[i][APPLICATION_ITEM_ID])) {
              i = i + 1;
              j = 0;
            } else if (arrayApplicationItem[i] && j === arrayMenuItem.length - 1) {
              arrayApplicationItemComplete.push(arrayApplicationItem[i]);
            }
          }
        }
      }
    return arrayApplicationItemComplete;
  }

  private updateTempArrayAndItems(): void {
    for (let i: number = 0; i < this.tempArray.length; i = i + 1) {
      this.tempArray[i].isOutbar = true;
    }
    for (let i: number = 0; i < this.items.length; i = i + 1) {
      this.items[i].isOutbar = false;
    }
  }
}
