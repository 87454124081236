import { LpMeta, propertyMeta } from './meta';

export class LpRegistration extends LpMeta {
    @propertyMeta('string')
    public registration: String;
    @propertyMeta('string')
    public department: String;
    @propertyMeta('date')
    public dateRegistration: Date;
}
