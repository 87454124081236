import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, ACCOUNTING } from '../detail-forms.component';
import { LpAccounting } from 'app/meta/accounting';
import { ZoomMeta } from 'app/meta/zoom/zoomMeta';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-accountings',
  templateUrl: './accountings.component.html'
})
export class AccountingsComponent extends DetailFormsComponent implements OnInit {

  public accountingTypes: String = this.configService.get('accountingTypes');
  public zoomMetaCustomerCollective: ZoomMeta;
  public zoomMetaSuplierCollective: ZoomMeta;
  public data: LpAccounting = null;

  public async ngOnInit(): Promise<void> {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(ACCOUNTING);
    this.initUrl();
    // this.init();
    if (Util.isNullOrUndefined(this.zoomMetaSuplierCollective)) {
      this.zoomMetaSuplierCollective = await this.zoomProvider.getParameters('suppliercollectiveaccount', 'thirdParty');
      this.zoomMetaCustomerCollective = await this.zoomProvider.getParameters('customercollectiveaccount', 'thirdParty');
    }
  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }
}
