import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { Subscription } from 'rxjs';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-ui-loader',
  templateUrl: './lp-ui-loader.component.html'
})
export class LpUiLoaderComponent implements OnDestroy, OnInit {

  /**
   * La propriété active permet d'afficher ou non le loader
   */
  public active: Boolean = false;

  /**
   * La propriété overlay permet de savoir si on bloque l'écran à lutilisateur pendant le loader
   * ou si il peut continuer de travailler pendant le chargement.
   */
  public overlay: Boolean = true;

  /**
   * La propriété placement permet de choisir la position du loader. En haut ou en bas.
   */
  public placement: String = 'bottom';

  /**
   * La propriété message permet d'afficher un message sous le loader.
   */
  public message: String = '';

  private subscription: Subscription;

  constructor(private uiSyncService: UiSyncService) {}

  public ngOnInit(): void {
    // TODO SUB
    this.subscription = this.uiSyncService.loaderEvtEmitter.subscribe((params) => {
      this.active = params.active;
      this.overlay = params.overlay;
      if (params.placement !== null) {this.placement = params.placement; }
      if (params.message !== null) {this.message = params.message; }
    });
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscription)) { this.subscription.unsubscribe(); }
  }
}
