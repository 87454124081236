import { LpMeta, propertyMeta } from '../meta';
import { Type } from 'class-transformer';
import { LpInspectionDamage } from './inspection-damage';
import { LpInspectionZone } from './inspection-zone';

export class LpInspectionFace extends LpMeta {
    @propertyMeta('string')
    public wording:                  String;
    @propertyMeta('boolean')
    public isActive:                 Boolean;
    @Type(() => LpInspectionDamage)
    public damages:                  LpInspectionDamage[];
    @Type(() => LpInspectionZone)
    public zones:                    LpInspectionZone[];
    @propertyMeta('string')
    public order:                    String;
    @propertyMeta('string')
    public picture:                  String;

    public assign(data: any): LpMeta {
        // Fonctions assign modifiée car à l'ouverture d'une inspection, les domages n'était pas typés LpInspectionDamage
        data = super.assign(data);
        let newDamages: LpInspectionDamage[] = [];
        if (data.damages !== null && data.damages !== undefined) {
            for (let i: number = 0; i < data.damages.length; i++) {
                let newDamage: LpInspectionDamage = new LpInspectionDamage();
                newDamage.assign(data.damages[i]);
                newDamages.push(newDamage);
            };
            data.damages = newDamages;
        }
        return data;
      }
}
