import { Injectable, EventEmitter } from '@angular/core';
import { LpLinks } from 'app/meta/links';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  public refreshLinksEvtEmitter: EventEmitter<Array<any>> = new EventEmitter();
  public previewLinksEvtEmitter: EventEmitter<LpLinks> = new EventEmitter();

  constructor() {}
   
  public refreshlinks(): void {
    this.refreshLinksEvtEmitter.emit();
  }

  public previewlinks(link: LpLinks): void {
    this.previewLinksEvtEmitter.emit(link);
  }
  
}
