import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { MenuItem } from 'app/models/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { RouterService } from 'app/services/router.service';
import { ModalService } from 'app/services/modal.service';
import { LpModalChangeComponent } from '../lp-modal/lp-modal-change/lp-modal-change.component';
import { ChangeService } from 'app/services/change.service';

@Component({
  selector: 'lp-sitemap',
  templateUrl: './lp-sitemap.component.html'
})


export class LpSitemapComponent implements OnInit {
  @ViewChild('sitemap', {static: true}) public sitemap: ElementRef;
  @Input() public searchVal: String;
  @Input() public typeheadMenu: MenuItem[];

  constructor(private routerService: RouterService,
              private uiSyncService: UiSyncService,
              private translate: TranslateService,
              private modalService: ModalService,
              private changeService: ChangeService) {
                this.routerService.router.routeReuseStrategy.shouldReuseRoute = function (): boolean {
                  return false;
                }
              }

  public ngOnInit(): void {
    this.initTranslateSiteMap();
    this.sitemap.nativeElement.focus();
    this.translate.onLangChange.subscribe(() => {
      this.initTranslateSiteMap();
    });
  }

  /** la fonction onSelectItemMenu() est appelée lorsque l'utilisateur selectionne un item de menu
   * dans le typeHead du champ de recherche de menu.
   * */
  public onSelectItemMenu(event: any): void {
    if (event && event.item && event.item.verb) {
      const item: MenuItem = event.item;
      if (item.verb) {
        this.uiSyncService.sitemapItemSelected();
        this.searchVal = '';
      }
      if (this.changeService.checkIfpreviousChanged(-1)) {
        this.modalService.modalPromise(LpModalChangeComponent, {
          backdropClass: 'alertBackdropClass',
          id : 'modalWithHeader'
        }).then((result: boolean) => {
          if (result) {
            this.changeService.clearAll()
            this.routerService.navigate(item.verb);
          }
        });
      } else {
        this.changeService.clearAll()
        this.routerService.navigate(item.verb);
      }
    }
  }

  private initTranslateSiteMap(): void {
    if (this.typeheadMenu && this.typeheadMenu.length !== 0 ) {
      this.typeheadMenu.forEach((item: MenuItem) => {
        if (item.ky !== null && item.ky !== undefined && item.label.indexOf(item.ky.toString()) < 0) {
          this.translate.get(item.label + '.' + item.ky).subscribe((v) => {
            item.translated = v;
            item.label = item.label + '.' + item.ky;
            item.verb += '/' + item.ky;
          });
        } else {
          this.translate.get(item.label.toString()).subscribe((v) => {
            item.translated = v;
          });
        }
      });
    }
  }
}
