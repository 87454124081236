import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { DateTimeAdapter, OwlDateTimeComponent} from '@danielmoncada/angular-datetime-picker';
import { DateFieldComponent } from '../date-field.component';
import { ChangeService } from 'app/services/change.service';
import { EvalService, MAIN, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { DatetimeService } from 'app/services/datetime.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { Util } from 'app/statics/utils';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ApplicationItem } from 'app/models/application-item';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'lp-date-month',
  templateUrl: './date-month.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateMonthComponent),
      multi: true
    }
  ]
})
export class DateMonthComponent extends DateFieldComponent implements OnInit {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre InputDate sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputDate: string;

  /**Le paramètre Type permet de configurer le type des balises HTML qui viennent constituer le composant.
   */
  @Input() public type: any;

  private dateTemp: Date;

  constructor(protected dateTimeAdapter: DateTimeAdapter<any>, protected changeService: ChangeService, protected evalService: EvalService,
      protected dateTimeService: DatetimeService, protected formMetadataProvider: FormMetadataProvider,
      public formStackService: FormStackService, protected uiSyncService: UiSyncService, public metaFactoryService: MetaFactoryService,
      public configService: ConfigService) {
      super(dateTimeAdapter, changeService, evalService, formMetadataProvider, uiSyncService, formStackService, metaFactoryService, configService);
    }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public chosenYearHandler( normalizedYear: moment.Moment ): void {
    let m = DateTime.fromJSDate(new Date(normalizedYear.toString()))
    if (this.dateTemp) {
      this.dateTemp.setUTCFullYear(m.get('year'));
    } else {
      this.dateTemp = this.dateTimeService.newDate(m.get('year'), 0, 1);
    }
  }

  public async focus(event: any): Promise<void> {
    let d: string = event.srcElement.value;
    this.setPreviousValue(d);
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);
  }

  public focusPicker(event: any): void {
    this.setPreviousValue(this.displayValue)
  }

  public async chosenMonthHandler( normalizedMonth: moment.Moment, datepicker: OwlDateTimeComponent<moment.Moment> ): Promise<void> {
    
    let m = DateTime.fromJSDate(new Date(normalizedMonth.toString()))
    const year: number = this.dateTemp.getFullYear();
    this.dateTemp = this.dateTimeService.newDate(year, m.get('month')-1, 15);
    this.setDisplayValue(this.dateTemp);
    datepicker.close();
    this.focusOutCustomCode();
  }

  public async updateFocusOut(): Promise<void> {
    const datePattern: RegExp = /(\d{1,2})\/(\d{2})/;
    const date: RegExpExecArray = datePattern.exec(this.displayValue?.toString());
    if (date && date[0]) {
      this.dateTemp = this.dateTimeService.newDate((parseInt(date[1], 10)).toString() + '/01/' + date[2]);
      this.setDisplayValue(this.dateTemp);
      this.focusOutCustomCode();
    }
  }

  protected transform(value: any): void {
    if (!Util.isNullOrUndefined(value)) {
      const v: string = DateTime.fromJSDate(new Date(value)).toFormat('MM/yy');
      this.displayValue =  v;
      if (!this.ignoreChange) {
        this.setHasChanged(true, this);
      }
    } else {
      this.displayValue = null;
    }
  }
}
