import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpInvoice } from './invoice';

export class LpFacturationLink extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @Type(() => LpInvoice)
    public relatedInvoice: LpInvoice = new LpInvoice();
}
