import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationItem } from 'app/models/application-item';
import { BreadcrumbItem } from 'app/models/breadcrumb-item';
import { UserHistory } from 'app/models/user-history';
import { MenuProvider } from 'app/providers/menu.provider';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { DateTime } from 'luxon';
import { ConfigService } from './config.service';
import { FormStackService } from './form-stack.service';
import { UiSyncService } from './ui-sync.service';
import { UserService } from './user.service';


@Injectable({
providedIn: 'root'
})
export class UserHistoryService {
    public showHistoryList: Boolean = false;
    public showButtonMore: Boolean = false;

    constructor(
        private menuProvider: MenuProvider,
        private formStackService: FormStackService,
        private uiSyncService: UiSyncService,
        private translate: TranslateService,
        private configService: ConfigService,
        private userService: UserService,
        private repositoryProvider: RepositoryProvider) {
   }


    public async getlist(): Promise<UserHistory[]> {
        //return this.localStorageService.getObject(USER_HISTORY) || [];
        return await this.repositoryProvider.getHistory(this.userService.getCurrentUser().id.toString());
    }

    public async addEvent(event: String, applicationItem: ApplicationItem, id?: String, verb?: String, title?: String, subtitle: String ="", date: string = DateTime.now().toFormat(this.configService.get('formats').date_db_format_complete)) {
        if ((title === null || title === undefined) && verb !== null) {            
            let breadcrumbItem: BreadcrumbItem = await this.menuProvider.getBreadcrumb(verb);
            title = this.translate.instant(applicationItem.verb+'.'+applicationItem.linkedObject) + " : " + this.uiSyncService.buildTopBannerTitle(breadcrumbItem, this.formStackService.currentData);
        }
        //let userHistory: UserHistory[] = this.getlist();
        /* userHistory.push({
            userId: this.userService.getCurrentUser().id,
            id : id,
            verb : verb,
            event: event,
            date: DateTime.now().toFormat(this.configService.get('formats').date_db_format_complete),
            title: title,
            subtitle: subtitle
        } as UserHistory);   */
        //this.localStorageService.setObject(USER_HISTORY, userHistory);
        let userHistory = {
            userId: this.userService.getCurrentUser().id,
            ky : id,
            verb : verb,
            event: event,
            date: DateTime.now().toFormat(this.configService.get('formats').date_db_format_complete),
            title: title,
            subtitle: subtitle
        } as UserHistory;
        this.repositoryProvider.postHistory(userHistory)
    }

    public async clearAllHistory(): Promise<void> {
        return await this.repositoryProvider.deleteHistory(this.userService.getCurrentUser().id.toString())
    }
}