import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, THIRD_PARTIES_RELATIONS, DRIVER_RELATIONS, CUSTOMER_RELATIONS } from '../detail-forms.component';
import { LpThirdPartiesRelation } from 'app/meta/third-parties-relation';
import { LpThirdParty } from 'app/meta/third-party';
import { RELATION_SON, RELATION_FATHER } from 'app/meta/meta';

@Component({
  selector: 'lp-third-parties-relations',
  templateUrl: './third-parties-relations.component.html'
})
export class ThirdPartiesRelationsComponent extends DetailFormsComponent implements OnInit {

  public data: LpThirdPartiesRelation = null;

  public ngOnInit(): void {
    super.ngOnInit();
    super.initData();
    if (this.formStackService.currentApplicationItemDetail.key === DRIVER_RELATIONS) {
      this.setId(DRIVER_RELATIONS);
      this.object = DRIVER_RELATIONS;
    } else if (this.formStackService.currentApplicationItemDetail.key === CUSTOMER_RELATIONS) {
      this.setId(CUSTOMER_RELATIONS);
      this.object = CUSTOMER_RELATIONS
    } else {
      this.setId(THIRD_PARTIES_RELATIONS);
      this.object = THIRD_PARTIES_RELATIONS;
    }
    this.data.assign(this.formStackService.currentDetailLineData);
    this.initUrl();
    this.initSonFather();
  }

  public changeRelation(): void {
    if (this.data._relationSwitch === RELATION_SON) {
      this.data.father.renew();
      this.data.son = new LpThirdParty();
      this.data.son.assign(this.formStackService.currentData)
    } else {
      this.data.son.renew();
      this.data.father = new LpThirdParty();
      this.data.father.assign(this.formStackService.currentData)
    }
    this.uiSyncService.loadObject();
  }

  public initSonFather(): void {
    if (this.data._relationSwitch === RELATION_SON) {
      this.data.son = new LpThirdParty();
      this.data.son.assign(this.formStackService.currentData)
    } else {
      this.data.father = new LpThirdParty();
      this.data.father.assign(this.formStackService.currentData)
    }
  }

  public isSon(): Boolean {
    return this.data._relationSwitch === RELATION_SON;
  }

  public isFather(): Boolean {
    return this.data._relationSwitch === RELATION_FATHER;
  }

}
