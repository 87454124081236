import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { environment } from 'environments/environment';
import { MenuProvider } from 'app/providers/menu.provider';
import { BreadcrumbItem } from 'app/models/breadcrumb-item';
import { ChangeService } from 'app/services/change.service';
import { Subscription } from 'rxjs';
import { FormStackService } from 'app/services/form-stack.service';
import * as _ from 'underscore';
import { Util } from 'app/statics/utils';
import { RightColService } from 'app/services/root/rightCol.service';
import { ApplicationItemDetail } from 'app/models/application-item-detail';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { EvalService } from 'app/services/eval.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { ModalService } from 'app/services/modal.service';
import { LpModalChangeComponent } from '../lp-modal/lp-modal-change/lp-modal-change.component';
import { ActivatedRoute } from '@angular/router';
import { PaginatedData } from 'app/models/paginated-data';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';
import { TranslateService } from '@ngx-translate/core';
import { RepositoryProvider } from 'app/providers/repository.provider';
@Component({
  selector: 'lp-top-banner',
  templateUrl: './lp-top-banner.component.html'
})
export class LpTopBannerComponent implements OnInit, OnDestroy {

  public icon: String;
  public breadcrumbItem: BreadcrumbItem;
  public summary: String;
  public maxInBadge: Number = this.configService.get('maxInBadge');
  public production: Boolean = environment.production;
  @Input() public title: String;
  @Input() public titleTranslationKey: String;
  @Input() public mainZoom: Boolean;
  private loadObjectRootFormSubscription: Subscription;
  private subscriptionRefreshDynamicMainForm: Subscription;

  constructor(
              public changeService: ChangeService,
              public formStackService: FormStackService,
              public uiSyncService: UiSyncService,
              private menuProvider: MenuProvider,
              private rightColService: RightColService,
              private alocproProvider: AlocproProvider,
              private evalService: EvalService,
              private ihmService: IhmStackService,
              private modalService: ModalService,
              protected route: ActivatedRoute,
              private configService: ConfigService,
              private translate: TranslateService,
              private userHstoryService: UserHistoryService,
              private repositoryProvider: RepositoryProvider) {
                this.subscription();
              }



  public ngOnInit(): void {
    setTimeout(() => {
      if (this.mainZoom === false) {
        this.defineDetailsVisibility(this.formStackService.currentApplicationItem.details, this.formStackService.CurrentKy);
      }            
      if (!Util.isNullOrUndefined(this.formStackService.CurrentVerb) && !Util.isNullOrUndefined(this.formStackService.currentApplicationItem)) {
        this.icon = this.formStackService.currentApplicationItem.classes;
      }
    }, 500)
  }

  public ngOnDestroy(): void {
    if (this.loadObjectRootFormSubscription) {
      this.loadObjectRootFormSubscription.unsubscribe();
    }
    if (this.subscriptionRefreshDynamicMainForm) {
      this.subscriptionRefreshDynamicMainForm.unsubscribe();
    }
  }

  public async isDetailHidden(detail: ApplicationItemDetail): Promise<void> {
    if (!Util.isNullOrUndefined(detail.availableEval)) {
      this.evalService.evalWithReturnStatement(detail.availableEval, this.formStackService.currentData).then((isDisplayed: boolean) => {
        detail.enable = isDisplayed;
        detail._ready = true;
      });
    } else {
      this.checkDetailsAndCount(detail).then((exists: boolean) => {
        detail.enable = exists;
        detail._ready = true;
      });
    }
  }



  public click(item: ApplicationItemDetail): void {
    if (this.changeService.isDetailsChanged2()) {
      this.modalService.modalPromise(LpModalChangeComponent, {
        backdropClass: 'alertBackdropClass',
        id : 'modalWithHeader'
      }).then((result: boolean) => {
        if (result) {
          this.changeService.clearDetailsChanged2();
          this.changeRightCol(item);
        }
      });
    } else {
      this.changeRightCol(item);
    }
  }

  public async changeRightCol(item: ApplicationItemDetail): Promise<void> {
      this.defineActive(item);
      this.rightColService.currentApplicationItemDetailLoading = item.id;
      this.formStackService.initLpLine(this.formStackService.currentApplicationItemDetail.key.toString());
      this.rightColService.displaySubAPIDetailsTab(item, this.formStackService.currentData);
      await this.userHstoryService.addEvent('show', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant(item.label));   
  }

  private defineDetailsVisibility(details: Array<ApplicationItemDetail>, ky: string): void {
    if (ky === this.configService.get('newdataKeyWord')) {
      this.defineEnableDetailToFalse(details);
    } else {
      if(this.route.snapshot.params['ky'] === this.formStackService.CurrentKy){
        _.each(details, (d: ApplicationItemDetail) => {
          d._ready = false;
          this.isDetailHidden(d);
        })
      }
    }
  }

  private async checkDetailsAndCount(detail: ApplicationItemDetail): Promise<Boolean> {
    return new Promise<Boolean>(async (resolve, reject) => {
        let url: String = this.formStackService.buildDetailUrl(detail.path);
        if (Util.isNullOrUndefined(url)) {
            resolve(false);
        } else {
          if ( detail && detail.pathOnRepository && detail.pathOnRepository === true) {
            const datas = await this.repositoryProvider.get(String(detail.path), null, null, true);
            this.formStackService.CurrentApplicationItemDetailRepositoryData = datas;
            if(datas) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            try {
              //let p: Boolean = await this.alocproProvider.exists(url.toString());
              let PaginatedData: PaginatedData = await this.alocproProvider.getPaginatedData(url.toString(),1,null,null,1);
              detail._count = PaginatedData.count;
              this.formStackService.currentDetailsCount.set(detail.key.toString(), PaginatedData.count)
              if(PaginatedData) {
                resolve(true);
              } else {
                resolve(false);
              }
          } catch (e) {
              resolve(false);
          }
          }
        }
    });
  }

  private async defineEnableDetailToFalse(details: Array<ApplicationItemDetail>): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if ( details && details.length !== 0 ) {
        for (let i: number = 0; i < details.length; i++) {
          details[i].enable = false;
          details[i]._ready = true;
      }
      this.ihmService.ShowTopBanner = true;
      resolve();
      }
    });
  }

  private async subscription(): Promise<void> {
    this.loadObjectRootFormSubscription = this.uiSyncService.loadObjectEvtEmitter.subscribe(() => {
      if (!this.mainZoom) {
        this.menuProvider.getBreadcrumb(this.formStackService.CurrentVerb).then((breadcrumbItem: BreadcrumbItem) => {
          this.breadcrumbItem = breadcrumbItem;
          this.summary = this.uiSyncService.buildTopBannerTitle(breadcrumbItem, this.formStackService.currentData);
        });

        this.defineDetailsVisibility(this.formStackService.currentApplicationItem.details, this.formStackService.CurrentKy);
      }
    });
    this.subscriptionRefreshDynamicMainForm = this.uiSyncService.refreshDynamicMainFormEvtEmitter.subscribe(() => {
      if ( this.formStackService.CurrentKy !== 'new' && !this.mainZoom) {
        _.each(this.formStackService.currentApplicationItem.details, (d: ApplicationItemDetail) => {
          d._ready = false;
          this.isDetailHidden(d);
        })
      }
    });
  }

  private defineActive(item: ApplicationItemDetail){
    for(let d of this.formStackService.currentApplicationItem.details){
      d.active = false
    }
    item.active = true
  }
}
