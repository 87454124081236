<!--<div class="animated fadeIn" id="dashboard">
  <ngx-gridster #gridster *ngIf="items.length > 0" [options]="gridsterOptions"
    (optionsChange)="optionsChange($event)" [draggableOptions]="gridsterDraggableOptions" #gridster class="dashboard">
    <ng-container *ngFor="let item of items">
      <ngx-gridster-item *ngIf="item.active" 
      [(xXl)]="item.position.x" [(yXl)]="item.position.y" 
      [(xLg)]="item.position.xLg" [(yLg)]="item.position.yLg" 
      [(xMd)]="item.position.xMd" [(yMd)]="item.position.yMd"
      [(xSm)]="item.position.xSm" [(ySm)]="item.position.ySm" 
      [(w)]="item.position.w" [(h)]="item.position.h" [(x)]="item.position.x" [(y)]="item.position.y"
        (end)='changes($event)' (change)="itemChanged(item, $event)" [dragAndDrop]="gridsterOptions.dragAndDrop"
        [resizable]="gridsterOptions.resizable">
        <lp-widget [id]="item.widgetId" [title]="titleWidget">
          <i [class.d-none]="!activeButtonCloseWidget" class="fa fa-close widget-close"
            (click)="$event.preventDefault(); closeWidget(item);"></i>
        </lp-widget>
      </ngx-gridster-item>
    </ng-container>
  </ngx-gridster>
</div>-->
<div>
  <lp-news></lp-news>
</div>
<ngx-gridster *ngIf="items && items.length > 0" [options]="gridsterOptions"
            (optionsChange)="optionsChange($event)" #gridster class="dashboard">

            <ng-container *ngFor="let widget of items; let indx = index">
              <ngx-gridster-item *ngIf="widget.active && widget.position" #itemComp
                            [dragAndDrop]="gridsterOptions.dragAndDrop" [resizable]="gridsterOptions.resizable"
                            [(x)]="widget.position.x" [(y)]="widget.position.y"
                            [(w)]="widget.position.w" [(h)]="widget.position.h"

                            [(xSm)]="widget.position.x" [(ySm)]="widget.position.y"
                            [(wSm)]="widget.position.w" [(hSm)]="widget.position.h"

                            [(xMd)]="widget.position.x" [(yMd)]="widget.position.y"
                            [(wMd)]="widget.position.w" [(hMd)]="widget.position.h"

                            [(xLg)]="widget.position.xLg" [(yLg)]="widget.position.yLg"
                            [(wLg)]="widget.position.wLg" [(hLg)]="widget.position.hLg"
                            
                            [(xXl)]="widget.position.xLg" [(yXl)]="widget.position.yLg"
                            [(wXl)]="widget.position.wLg" [(hXl)]="widget.position.hLg"
                            (change)="itemChanged()">
                            <lp-widget [id]="widget.widgetId" [title]="titleWidget" [screen]="20125">
                            <i [class.d-none]="!activeButtonCloseWidget" class="fa fa-close widget-close"
                              (click)="$event.preventDefault(); closeWidget(item);"></i>
                          </lp-widget>
              </ngx-gridster-item>
            </ng-container>
  </ngx-gridster>