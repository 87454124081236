import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { FieldComponent } from '../field.component';
import { ChangeService } from 'app/services/change.service';
import { EventEmitter } from '@angular/core';
import { CHANGE_EVENT, EvalService, FOCUS_OUT_CHANGE_EVENT, FOCUS_OUT_EVENT, MAIN } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { FormMetadata } from 'app/models/form-metadata';
import { Util } from 'app/statics/utils';
import { Subscription } from 'rxjs';
import { FormMetadataValues } from 'app/models/form-metadata-values';
import { ControlValueAccessor } from '@angular/forms';
import { LpMeta } from 'app/meta/meta';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { FtMeta } from 'app/meta/ft-meta';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';
import { ApplicationItem } from 'app/models/application-item';

@Component({
  template: ''
})
export class ValueFieldComponent extends FieldComponent implements OnInit, OnDestroy, ControlValueAccessor {

  /** Le paramètre Required permet de désactiver la modification d'un champ.
   */
  @Input() public required: Boolean = false;

  /** Le paramètre binding représente les données reçus par le composant par l'intermédaire du [(ngModel)].
   */
  @Input() public binding: any;

  /** Le paramètre Placeholder permet de renseigner un texte indicatif par défaut dans un champ de formulaire.
  */
  @Input() public placeholder: String;

  @Input() public hidden: Boolean = false;

  @Input() public defaultValue: string;

  /** Le paramètre putData contient tout l'objet à insérer dans la reqûete de modification à la sortie du champs
   * Nottament utilisée lorsque l'on se trouve dans une liste avec modification à la volée
  */
  @Input() public putData: any;
  /**Le paramètre internalValue représente le champ texte visible par l'utilisateur.
   */
  @Input() public internalValue: any = '';

  @Input() public cssClass: string = '';
  @Input() public maxLength: Number;
  
  @Input() public label: string;

  @Output() public putEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**Le paramètre Style permet de surcharger le style css.
   */
  @Input() public style: any;


  public displayValue: any;
  protected backendMetadata: Subscription;
  protected untouchedValue: any;
  protected previousValue: any;
  protected typeBinding: String;
  constructor(protected changeService: ChangeService, protected evalService: EvalService, public formStackService: FormStackService,
              protected formMetadataProvider: FormMetadataProvider, protected uiSyncService: UiSyncService,
              protected metaFactoryService: MetaFactoryService, protected configService: ConfigService) {
    super(changeService, evalService, formMetadataProvider, formStackService, uiSyncService);
  }

  public pushChange(): void {
    if (!this.formStackService.getFields(this.name).readonly) {
      if (!Util.isNullOrUndefined(this.displayValue) && this.previousValue !== this.displayValue
            && (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange)) {
        this.setHasChanged(true, this);
      }
      this.putOnFocusOut();
      this.setDisplayValue(this.displayValue);
    }
  }

  public propagateChange = (_: any) => { };

  public propagateTouch = (_: any) => { };

  public setDisplayValue(value: any, noSetDisplayValue?: boolean ): void {
    this.untouchedValue = value;
    if (!Util.isNullOrUndefined(value)) {
      this.transform(value, noSetDisplayValue);
    } else {
      if (this.displayValue instanceof LpMeta) {
        this.displayValue.renew();
      } else {
        this.displayValue = null;
      }
    }
    if (this.displayValue instanceof LpMeta) {
      this.propagateChange(this.displayValue);
    } else {
      this.propagateChange(value);
    }
    this.uiSyncService.emitAvailableEvalForJsonInputField();
  }

  public set InternalValue(value: any) {
    this.internalValue = value;
    this.propagateChange(value);
  }

  public get InternalValue(): any {
    return this.internalValue;
  }

  public setInternalValue(value: any): void {
    this.propagateChange(value);
  }

  public ngOnInit(): void {
    this.formStackService.currentFields.set(this.name, {name: this.name.toString(), mandatory: !!this.required, hidden: !!this.hidden, readonly: !!this.readonly})
    this.hidden = this.formStackService.getFields(this.name).hidden;
    this.required = this.formStackService.getFields(this.name).mandatory;
    this.readonly = this.formStackService.getFields(this.name).readonly;
    this.backendMetadata = this.uiSyncService.formMetadataEventEmitter.subscribe((formMetadataValues: FormMetadataValues) => {
      if (!Util.isNullOrUndefined(this.name)) {
        if (formMetadataValues.subApi) {
          if (this.name.indexOf('.') > 0 && this.formStackService.CurrentMetadata.has(this.name.toString())) {
            this.setDefaultValue(this.formStackService.CurrentMetadata.get(this.name.toString()), formMetadataValues.fromPlanning
            , formMetadataValues.isNewForm, this.typeBinding);
          }
        } else {
          if (this.formStackService.CurrentMetadata.has(this.name.toString())) {
            this.setDefaultValue(this.formStackService.CurrentMetadata.get(this.name.toString())
            , formMetadataValues.fromPlanning, formMetadataValues.isNewForm, this.typeBinding);
          }
        }
      }
    });
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.backendMetadata)) {
      this.backendMetadata.unsubscribe();
    }
  }

  /** la fonction putOnFocusOut() est appelé lorsque l'utilisateur du champ
   * Elle test si l'utilisateur veux faire une requête PUT (modification) lorsqu'il fini sa saisie.
   */
  public putOnFocusOut(): void {
    this.putEvtEmitter.emit();
  }

  public writeValue(value: any): void {
    this.untouchedValue = value;
    this.transform(value);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public async change(event: any, noSetDisplayValue?: boolean): Promise<void> {
    if (event && event.srcElement && !event.srcElement.readOnly) {
      let value: any = null;
      if (event.srcElement.value !== '') {
        value = event.srcElement.value;
        if (value.toString().includes(',')) {
          value = event.srcElement.value.replace(',', '.');
        }
      }
      let applicationItem: ApplicationItem;
      let data: LpMeta;
      this.setDisplayValue(value, noSetDisplayValue);
      if(this.formStackService.useSpecificApplicationItem(this.name)) {
        applicationItem = this.formStackService.specificApplicationItem;
        data = this.formStackService.specificData;
      } else {
        applicationItem = this.formStackService.currentApplicationItem;
        data = this.formStackService.currentData;
      }
      if(!Util.isNullOrUndefined(applicationItem)){
        await this.evalService.executeContrainte(CHANGE_EVENT, MAIN
          , applicationItem.ecrId.toString(), this.name.toString()
          , data, this.formStackService.currentFields);
      }
    }
  }

  protected transform(value: any, noSetDisplayValue?: boolean): void {
    if (this.displayValue instanceof LpMeta) {
      if (value && value.id) {
        this.displayValue.assign(value);
      } else {
        this.displayValue.renew();
      }
    } else {
      this.displayValue = value;
    }

  }

  protected async setDefaultValue(formMetaData: FormMetadata, fromPlanning: boolean = true
    , isNewForm: boolean, type?: String): Promise<void> {
    if (!Util.isNullOrUndefined(formMetaData.readonly)) {
      this.formStackService.getFields(this.name).readonly = formMetaData.readonly;
    }
    if (!Util.isNullOrUndefined(formMetaData.mandatory)) {
      this.formStackService.getFields(this.name).mandatory = formMetaData.mandatory;
    }
    if (!Util.isNullOrUndefined(formMetaData.hidden)) {
      this.formStackService.getFields(this.name).hidden = formMetaData.hidden;
    }
    if (!Util.isNullOrUndefined(formMetaData.cssClass)) {
      this.cssClass += ' ' + formMetaData.cssClass + ' ';
    }
    if (!Util.isNullOrUndefined(formMetaData.maxLength)) {
      this.maxLength = formMetaData.maxLength;
    }
  }

  protected setPreviousValue(value: any): void {
    this.previousValue = value;
  }

  protected isNull(): boolean {
    if (this.displayValue instanceof LpMeta || this.displayValue instanceof FtMeta) {
      return  Util.isNullOrUndefined(this.displayValue.id)
    } else {
      return Util.isNullOrUndefined(this.displayValue)
    }
  }
  /** Fonction permettant d'éxécuter les custom code en sorti de champ
   * Généralement utilisé dans le pushchange()
   */
  protected async focusOutCustomCode(): Promise<void> {
    let applicationItem: ApplicationItem;
    let data: LpMeta;
    if (!this.formStackService.getFields(this.name).readonly) {      
      if(this.formStackService.useSpecificApplicationItem(this.name)) {
        applicationItem = this.formStackService.specificApplicationItem;
        data = this.formStackService.specificData;
      } else {
        applicationItem = this.formStackService.currentApplicationItem;
        data = this.formStackService.currentData;
      }
      if (!Util.isNullOrUndefined(this.displayValue) && this.previousValue !== this.displayValue) {
        if (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange) {
          this.setHasChanged(true, this);
        }  
        await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
          , applicationItem.ecrId.toString(), this.name.toString()
          , data, this.formStackService.currentFields, this.previousValue);
      }
    }
    await this.evalService.executeContrainte(FOCUS_OUT_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields);
  }
}
