<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<div id="{{id}}" inputBox="inputBox" class="{{className}} {{cssClass}} switch-toggle switch-candy col-md-12" bindingKey="bindingKey"
  [(ngModel)]="displayValue" ngDefaultControl [class.disabled]="disabled" [class.readonly]="readonly" [ngClass]="responsivClass">
  <ng-container *ngFor="let value of values;">
    <input type="radio" name="{{switchName}}" id="{{switchName}}-{{value[idField]}}" [checked]="displayValue == value[idField]"  [disabled]="disabled" [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [value]="value[idField]" (click)="emit($event)"/>
    <label for="{{switchName}}-{{value[idField]}}">{{value[labelField]}}</label>
  </ng-container>
  <a></a>
</div>