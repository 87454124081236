import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  public refreshNewsEvtEmitter: EventEmitter<Array<any>> = new EventEmitter();

  constructor() {}
   
  public refreshNews(): void {
    this.refreshNewsEvtEmitter.emit();
  }
  
}
