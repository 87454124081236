import { Component, OnInit } from '@angular/core';
import { LpMainAccount } from 'app/meta/main-account';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-main-account',
  templateUrl: './main-accounts.component.html'
})
export class MainAccountsComponent extends RootformComponent implements OnInit {

  public data: LpMainAccount = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('main-accounts', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
