import { Component, Input } from '@angular/core';
import { FormStackService } from '../../services/form-stack.service';
import { ChangeService } from 'app/services/change.service';
import { LpModalChangeComponent } from '../lp-modal/lp-modal-change/lp-modal-change.component';
import { ModalService } from 'app/services/modal.service';
import { LpInvoice } from 'app/meta/invoice';
import { LpThirdParty } from 'app/meta/third-party';
import { Util } from 'app/statics/utils';
import { LpMeta } from 'app/meta/meta';
import { FormService } from 'app/services/root/form.service';
import { LpLocation } from 'app/meta/location';
import { RouterService } from 'app/services/router.service';
import { LpUser } from 'app/meta/user';

@Component({
  selector: 'lp-childform-link',
  templateUrl: './lp-childform-link.component.html'
})
export class LpChildformLinkComponent {

  /**
   * Contient les data à insérer dans l'historique
   */
  @Input() public ky: any;

  @Input() public add: boolean;
  @Input() public childType: string;
  @Input() public childLabel: string;
  /*
  * Permet de faire les zoom dans les partie de droite pour créer un lien (additionnal driver)
  * on va pas poster sur /thirdParties mais dans ccd/{ky}/additionnalDrivers
  * idem pour address dans tiers
  */
  @Input() public usePreviousAPIConfig: boolean = false;

  /**
   * Contient le verb a appeler au retours du formulaire enfant.
   */
  @Input() public verb: string;

  /**
   * Contient l'id de l'historique qui servira de clé pour l'oçbjet Map du service HistoryService.
   */
  @Input() public key: string;

    /**
   * Contient l'index de la ligné cliqué
   */
  @Input() public indexClicked: string;

  /**
   * Contient la propriété à binder dans le formulaire enfant.
   */
  @Input() public prop: string;

  /**
   * Permet de savoir si on doit binder l'objet principal ou l'objet de la colonne de droite.
   */
  @Input() public isMainData: Boolean = true;

  @Input() public isStacked: boolean = true;
  constructor(
    protected routerService: RouterService,private formStackService: FormStackService,
              private changeService: ChangeService,
              private modalService: ModalService,
              private formService: FormService
              ) { }

  /**
   * La fonction click() est appelée lorsque l'utilisateur click sur le lien.
   * Elle a pour but d'insérer les infos contenus dans la propriété data vers l'objet Map du service HistoryService.
   */
  public async click(): Promise<void> {
    if (this.changeService.isDetailsChanged2() && !this.usePreviousAPIConfig) {
      this.modalService.modalPromise(LpModalChangeComponent, {
        backdropClass: 'alertBackdropClass',
        id : 'modalWithHeader'
      }).then(async (result: boolean) => {
        if (result) {
          this.changeService.clearDetailsChanged2();
          await this.formStackService.addStackIem(null, this.prop, this.childType, this.ky, false, this.usePreviousAPIConfig) ;
        }
      });
    } else {
      if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail) &&
          this.formStackService.currentApplicationItemDetail.externalFilledForm) {
            if (this.formStackService.CurrentVerb === 'quick-quotes') {
              delete this.formStackService.currentData['returnDate']
              delete this.formStackService.CurrentApplicationItemDetailData[this.indexClicked]['returnDate'];
              this.formStackService.currentData['status'] = new LpMeta();
              this.formStackService.currentData['status']['id'] = '1';
            }
        this.formStackService.storeProperties(this.formStackService.CurrentApplicationItemDetailData[this.indexClicked],
            this.formStackService.currentData);
      }
      let idTemp: String;
      if (this.ky ==="new" && this.verb === "companies") {
        idTemp = this.formStackService.currentData.id;
      }
      
      await this.formStackService.addStackIem(null, this.prop, this.childType, this.ky, false, this.usePreviousAPIConfig);

      if (this.ky ==="new" && this.verb === "companies" && this.formStackService.CurrentApplicationItemDetail.key === "companiesLocations") {
        await this.routerService.navigate("locations", "new");
        (this.formStackService.currentData as LpLocation).company.id = idTemp;
      } else if (this.ky ==="new" && this.verb === "companies" && this.formStackService.CurrentApplicationItemDetail.key === "companiesUsers") {
        await this.routerService.navigate("users", "new");
        (this.formStackService.currentData as LpUser).company.id = idTemp;
        (this.formStackService.currentData as LpUser).location.id = "";
      } 
    }
    this.postAction();
  }

  private postAction(): void {
    if (this.checIfSTCCreateNewCustomerInvoice()) {
      this.redirectToInvoiceFromSTC('customer');
    } else if (this.checIfSTCCreateNewDriverInvoice()) {
      this.redirectToInvoiceFromSTC('driver');
    }
  }

  // K520TI68O0RR07PR00SL
  private checIfSTCCreateNewCustomerInvoice(): boolean {
    return this.formStackService.CurrentVerb === 'short-term-contracts' && this.childType === 'invoices' && this.formStackService.CurrentApplicationItemDetail.key == 'customerInvoices'
  }

  // K520TI68O0RR07PR00SL
  private checIfSTCCreateNewDriverInvoice(): boolean {
    return this.formStackService.CurrentVerb === 'short-term-contracts' && this.childType === 'invoices' && this.formStackService.CurrentApplicationItemDetail.key == 'driverInvoices'
  }
  
  private async redirectToInvoiceFromSTC(type: string): Promise<void> {
    let tempInvoice: LpInvoice = new LpInvoice();
    if (!Util.isNullOrUndefined(this.formStackService.currentData['movement'])
      && ! Util.isNullOrUndefined(this.formStackService.currentData['movement']['equipment'])) {
      tempInvoice.equipment = this.formStackService.currentData['movement']['equipment'];
      if (!Util.isNullOrUndefined(this.formStackService.currentData['movement']['equipment']['mileageValue'])) {
        tempInvoice.mileage = this.formStackService.currentData['movement']['equipment']['mileageValue'];
      }
      if (!Util.isNullOrUndefined(this.formStackService.currentData['movement']['equipment']['model'])) {
        tempInvoice.model = this.formStackService.currentData['movement']['equipment']['model'];
      }
    }
    tempInvoice.thirdParty = this.getThirdParty(this.childLabel);
    type === 'customer' ? tempInvoice.groupingEvent = this.formStackService.currentData['customerEventGroupingCode'] : tempInvoice.groupingEvent = this.formStackService.currentData['driverEventGroupingCode'] ;
    type === 'customer' ? tempInvoice.accountingType.id = '1': tempInvoice.accountingType.id = '2';
    this.formStackService.currentData = tempInvoice;
    this.formStackService.CurrentKy = 'new';
    this.formStackService.isCurrentDataPreloaded = true;
    await this.formService.build('invoices', this.formStackService.CurrentKy, null, false);
    return;
  }

  private getThirdParty(type: string): LpThirdParty {
    if (type === 'customerInvoices') {
      return this.formStackService.currentData['customer'];
    } else {
      return this.formStackService.currentData['driver'];
    }
  }
}
