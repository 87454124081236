import { Injectable } from '@angular/core';

@Injectable()
export class ExampleDataProvider {

    public getData(): Promise<any> {
        return new Promise((resolve) => {

        });
    }

}
