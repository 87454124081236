import { Type } from "class-transformer";
import { LpMeta, propertyMeta } from "./meta";

export class LpFullCompanyAddress {
    @propertyMeta('string')
    public address1: String = '';
    @propertyMeta('string')
    public zipCode: String = '';
    @propertyMeta('string')
    public city: String = '';
}

export class LpFullCompanyId {
    @propertyMeta('string')
    public id: String;
}

export class LpFullCompanyThirdParty {
    @propertyMeta('string')
    public lastName: String;
    @propertyMeta('string')
    public firstName: String;
    @Type(() => LpFullCompanyAddress)
    public mainAddress: LpFullCompanyAddress = new LpFullCompanyAddress();
}

export class LpFullCompanyCompany {
    @propertyMeta('string')
    public corporateName: String;
    @Type(() => LpFullCompanyId)
    public vatDomain: LpFullCompanyId = new LpFullCompanyId();
    @propertyMeta('string')
    public address1: String;
    @propertyMeta('string')
    public postcode: String;
    @propertyMeta('string')
    public city: String;
}

export class LpFullCompanyUser {
    @propertyMeta('string')
    public username: String;
    @propertyMeta('string')
    public password: String;
    @propertyMeta('string')
    public language: String;
}

export class LpFullCompanyAdditionnalUser {
    @Type(() => LpFullCompanyThirdParty)
    public thirdParty: LpFullCompanyThirdParty = new LpFullCompanyThirdParty();
    @Type(() => LpFullCompanyUser)
    public user: LpFullCompanyUser = new LpFullCompanyUser();
}

export class LpFullCompanyLocation {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public longitude: String;
    @propertyMeta('string')
    public latitude: String;
    @propertyMeta('string')
    public inChargeOf: String;
    @propertyMeta('string')
    public documentNumber: String;
    @propertyMeta('string')
    public description: String;
    @propertyMeta('string')
    public analyticalCenter: String;
    @Type(() => LpFullCompanyAddress)
    public mainAddress: LpFullCompanyAddress = new LpFullCompanyAddress();
}
export class LpFullCompany extends LpMeta {
    @Type(() => LpFullCompanyThirdParty)
    public manager: LpFullCompanyThirdParty = new LpFullCompanyThirdParty();
    @Type(() => LpFullCompanyCompany)
    public company: LpFullCompanyCompany = new LpFullCompanyCompany();
    @Type(() => LpFullCompanyUser)
    public userSynchroERP: LpFullCompanyUser = new LpFullCompanyUser();
    @Type(() => LpFullCompanyLocation)
    public location: LpFullCompanyLocation = new LpFullCompanyLocation();
    public additionnalUsers: LpFullCompanyAdditionnalUser[]= [];
}