import { LpMeta, propertyMeta } from './meta';

export class OpeningHour extends LpMeta {
    @propertyMeta('string')
    public details: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public openingHour: String;
    @propertyMeta('string')
    public closingHour: String;
    @propertyMeta('string')
    public dayOfWeek: String;
    @propertyMeta('date')
    public date: Date;
}
