import { Component, OnInit, Input, Attribute } from '@angular/core';

@Component({
    selector: 'lp-card-header',
    templateUrl: './card-header.component.html',
    styleUrls: ['card-header.component.scss']
})
export class LpCardHeaderComponent implements OnInit {

    @Input() public color: string;
    @Input() public textColor: string;

    public size: string;

    constructor(
        @Attribute('large') large: string,
    ) {
        this.size = (large !== null) ? 'card-header-large' : 'card-header-default';
    }

    public ngOnInit(): void {
        this.color = (this.color) ? 'card-' + this.color : '';
        this.textColor = (this.textColor) ? 'text-' + this.textColor : '';
    }

}
