<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm"
  titleTranslationKey="invoices.invoice"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire -->
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
          (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">

            <div class="row">
              <div class="form-group col-xl-6">
                <!--Field documentType-->
                <lp-zoom-field zoomId="documentType" label="invoices.documentType" id="documentType" name="documentType" cssClass="form-control"
                class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.documentType"> </lp-zoom-field>
              </div>
              <!--Field status-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="status" class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                  cssClass="form-control" name="status" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'invoiceStatus'}, {'key':'isUsedForInvoice', 'value': 1}]}"
                  [(ngModel)]="data.status"></lp-dropdown-field>-->
                <lp-zoom-field zoomId="status" id="status" name="status" cssClass="form-control" label="invoices.status"
                 class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.status"> </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field documentNumber Invoice-->
              <div class="form-group col-xl-6">
                <lp-text-field id="documentNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                  cssClass="form-control" name="documentNumber" label="invoices.documentNumber"
                  [(ngModel)]="data.documentNumber"></lp-text-field>
              </div>
              <!--Field location Invoice-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="location" class="{{arrayClassesElements.get('defaultInput')}}" 
                  name="location" label="invoices.location"
                  zoomId="startLocation" cssClass="form-control" [(ngModel)]="data.location"
                  className="zoomField"></lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field third-party-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="thirdParty" class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.thirdParty" 
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.thirdParty"  name="thirdParty"
                  (change)="updateMainAddress()" zoomId="thirdPartiesInvoice">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-xl-6">
                <!--Field paymentMethod-->
                <!--<lp-dropdown-field id="paymentMethod" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="paymentMethod" [path]="{'verb': 'payment-methods'}"
                  [(ngModel)]="data.paymentMethod"></lp-dropdown-field>-->
                <lp-zoom-field zoomId="paymentMethod" id="paymentMethod" name="paymentMethod" cssClass="form-control" label="invoices.paymentMethod"
                class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.paymentMethod"> </lp-zoom-field>
              </div>
              <!--Field termPaymentType Invoice-->
              <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'term'}]}"
                      id="termPaymentType" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="termPaymentType" [(ngModel)]="data.termPaymentType"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="termPaymentType" id="termPaymentType" name="termPaymentType" cssClass="form-control" label="invoices.termPaymentType"
                  class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.termPaymentType"> </lp-zoom-field>
                </div>
            </div>
            <div class="row">
                <!--Field billingNumber -->
                <div class="form-group col-xl-6">
                  <lp-text-field id="billingNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.billingNumber"
                    cssClass="form-control" name="billingNumber" [(ngModel)]="data.billingNumber"></lp-text-field>
                </div>
              </div>
            <div class="row">
              <!--Field billingDate -->
              <div class="form-group col-xl-6">
                  <lp-date id="billingDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.billingDate"
                    name="billingDate" [(ngModel)]="data.billingDate"></lp-date>
                </div>
                <!--Field dateTerm Invoice-->
                <div class="form-group col-xl-6">
                <lp-date id="termDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.termDate"
                  name="termDate" [(ngModel)]="data.termDate"></lp-date>
              </div>
            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="equipments.equipments" [open]=false key="equipments">
            <div class="row">
              <!--Field model TODO uncomment this field the API-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="model" class="{{arrayClassesElements.get('defaultInput')}}" name="model" label="invoices.model"
                  zoomId='driver' className="zoomField" cssClass="form-control" [(ngModel)]="data.model">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
              <!--Field equipment-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="equipment" class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.equipment"
                name="equipment" zoomId='equipment' className="zoomField" cssClass="form-control" [(ngModel)]="data.equipment">
                </lp-zoom-field>
              </div>
              <!--Field mileage-->
              <div class="form-group col-xl-6">
                <lp-number id="mileage" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.mileage"
                  name="mileage" [(ngModel)]="data.mileage" minlength="3"></lp-number>
              </div>
            </div>

          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="equipments.various" [open]=false key="equipments">
            <div class="row">
              <!--Field accountingType-->
              <div class="form-group col-xl-6">
                  <lp-dropdown-field id="accountingType" class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.accountingType"
                    className="textField" cssClass="form-control" name="accountingType" [values]="accountingTypes"
                    [(ngModel)]="data.accountingType"></lp-dropdown-field>
              </div>
              <!--Field accountingPlan -->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="accountingPlan" zoomId="accountingPlans" name="accountingPlan"
                class="{{arrayClassesElements.get('defaultInput')}}" label="invoices.accountingPlan"
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.accountingPlan">
                </lp-zoom-field>
              </div>
            </div>

            <div class="row">
                <!--Field currency -->
                <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field id="currency" class="{{arrayClassesElements.get('defaultInput')}}"
                      className="textField" cssClass="form-control" name="currency" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'currency'}]}"
                      [(ngModel)]="data.currency"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="currency" id="currency" label="invoices.currency" name="currency" cssClass="form-control"
                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.currency"> </lp-zoom-field>
                  </div>

              <!--Field currencyDoc -->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="currencyDoc" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="currencyDoc" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'currency'}]}"
                  [(ngModel)]="data.currencyDoc"></lp-dropdown-field>-->
                <lp-zoom-field zoomId="currencyDoc" id="currencyDoc" name="currencyDoc" cssClass="form-control" label="invoices.currencyDoc"
                class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.currencyDoc"> </lp-zoom-field>
              </div>
            </div>

          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>


        </lp-ui-accordion>   
      </div>       
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>    
      </form>
    </div>

    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>

  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>