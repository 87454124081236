import { LpMeta, propertyMeta } from '../meta';
import { Type } from 'class-transformer';
import { LpMemo } from '../memo';
import { FtMeta } from '../ft-meta';
import { LpInspectionZone } from './inspection-zone';
import { LpInspectionElementZone } from './inspection-element-zone';

export class LpInspectionDamage extends LpMeta {
    @propertyMeta('date')
    public visitDate:           Date;
    @propertyMeta('date')
    public updateDate:          Date;
    @Type(() => LpMemo)
    public attachments:         LpMemo[] = [];
    @propertyMeta('string')
    public wording:              String;
    @Type(() => FtMeta)
    public damageValue:         FtMeta;
    @Type(() => FtMeta)
    public damageCategory:      FtMeta;
    @Type(() => FtMeta)
    public inspectionDamageStatus:      FtMeta;
    @Type(() => FtMeta)
    public group:               FtMeta;
    @Type(() => FtMeta)
    public groupCategory:       LpInspectionElementZone;
    @propertyMeta('string')
    public clientResponsibility: String;
    @propertyMeta('number')
    public rowNumber:            Number;
    @propertyMeta('number')
    public xAxis:                Number;
    @propertyMeta('number')
    public yAxis:                Number;
    @Type(() => LpInspectionZone)
    public zone:                 LpInspectionZone;
    @propertyMeta('boolean')
    public isNew:               Boolean;
    @propertyMeta('string')
    public faceWording:         String;
    @propertyMeta('boolean')
    public activeFace:          Boolean;
    @propertyMeta('string')
    public faceId:              String;
    @propertyMeta('number')
    public nbMemos: Number;
}

export class LpInspectionFaceV2 extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('string')
    public order: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => LpInspectionZone)
    public zones: LpInspectionZone[];
}


/*@Type(() => LpMemo)
public attachments:         LpMemo[] = [];*/