import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../services/modal.service';
import { LpWorkflow } from 'app/meta/workflow';
import { LpModalComponent } from '../../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkflowProvider } from 'app/providers/workflow.provider';
import { UiSyncService } from 'app/services/ui-sync.service';
import { BEFORE_DELETE_EVENT, EvalService, MAIN } from 'app/services/eval.service';
import { FormStackService } from 'app/services/form-stack.service';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';
@Component({
  selector: 'lp-modal-workflow-confirm',
  templateUrl: './lp-workflow-confirm.component.html'
})
export class LpModalWorkflowConfirmComponent extends LpModalComponent {

  public relatedObject: String;
  public relatedObjectId: String;
  public ky: string;
  public verb: string;
  public listeMessages: any = {
    emptyMessage: '',
    totalMessage: ''
  };
  public workflows: LpWorkflow[] = [];
  public currentWorkflow: LpWorkflow = new LpWorkflow();
  public showCancelAction: Boolean = false;
  public workflowPerPage: number = this.configService.get('workflowsLimitInList');
  public actualPageNumber: number = 1;
  public nbWorkflow: number = 0;
  protected currentIdForDelete: String = '';

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public modalService: ModalService,
    private workflowProvider: WorkflowProvider,
    public uiSyncService: UiSyncService,
    private formStackService: FormStackService,
    private evalService: EvalService,
    private configService: ConfigService,
    private userHstoryService: UserHistoryService
  ) {
    super(dialogRef, data);
  }

  public closeModal(modal: String): void {
    this.close();
  }

  public async deleteWorkflow(): Promise<void> {
    try {
      await this.evalService.executeContrainte(
        BEFORE_DELETE_EVENT, MAIN, 'WORKFLOW', '*', this.data, null, null, this.formStackService.currentData);
      await this.workflowProvider.deleteWorkflow();
      this.actionAfterDeleteWorkflows();
      this.userHstoryService.addEvent('delete', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.Workflow')+' (ID '+this.workflowProvider.workflowSelectedForDelete+')');
    } catch (error) {
      this.uiSyncService.loader(false);
      throw error;
    }
  }

  private actionAfterDeleteWorkflows(): void {
    this.uiSyncService.loader(false);
    this.modalService.success(this.translate.instant('general.modalService.deletionCompleted'),
      this.translate.instant('general.modalService.success'));
    this.close();
  }


}
