
<div class="row">  
  <h2 class="col-10">{{'customization.config.themes' | translate}}</h2>
  <div class="col-2">
    <lp-button-new class="pull-right" (action)="add()"></lp-button-new>
  </div>  
</div>
<ngx-datatable class="material table-height-35 themes"
  [rows]="themesRows" 
  [columnMode]="'force'"
  [columns]="themesColumns"
  [headerHeight]="35" 
  [rowHeight]="35"
  [messages]="{emptyMessage: 'general.emptyMessage' |translate}"
  [footerHeight]="0" 
  [loadingIndicator]="configLoader" 
  [class.loading-table]="configLoader" 
  width="100%">        
</ngx-datatable>
<ng-template #themePrimaryColorTemplate let-row="row" let-value="value"> <span [style.color]="value">{{ value }}</span> </ng-template>
<ng-template #themeAvailableTemplate let-row="row" let-value="value">
  <div class="w-100 justify-content-center d-flex">
    <lp-boolean-switch-box 
      id="available-{{row.id}}" name="available-{{row.id}}" switchName="chkb_available-{{row.id}}"  [ignoreChange]="true"
      [ngModel]="value" (ngModelChange)="changeThemeAvailability($event, row)"></lp-boolean-switch-box>
  </div>
</ng-template>
<ng-template #themeActions let-row="row" let-value="value">
  <i class="icon vega-pencil " title="{{'general.delete' | translate}}"
    (click)="modify(row)"></i>

  <i class="ms-1 icon vega-dustbin " title="{{'general.delete' | translate}}"
    (click)="delete(row)"></i>
</ng-template>

<lp-ui-accordion id="them-editor" *ngIf="showForm">
  <lp-ui-accordion-group heading="customization.config.themesformTitle" [open]=true>
    <form id="forTheme" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>


      <div class="form-group col-12 col-md-6">
          <lp-label-field for="id" class="{{arrayClassesElements.get('defaultLabel')}}" text="customization.config.themesform.id"></lp-label-field>
          <lp-text-field id="id" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id"
          [(ngModel)]="data.id" placeholder="customization.config.themesform.id" [readonly]="true"></lp-text-field>
      </div>

      <div class="form-group col-12 col-md-6">
          <lp-label-field for="description" class="{{arrayClassesElements.get('defaultLabel')}}" text="customization.config.themesform.description"></lp-label-field>
          <lp-text-field id="description" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="description"
          [(ngModel)]="data.description" placeholder="customization.config.themesform.description"></lp-text-field>
      </div>

      <div class="form-group col-12 col-md-6">
          <lp-label-field for="name" class="{{arrayClassesElements.get('defaultLabel')}}" text="customization.config.themesform.name"></lp-label-field>
          <lp-text-field id="name" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="name"
          [(ngModel)]="data.name" placeholder="customization.config.themesform.name"></lp-text-field>
      </div>

      <div class="form-group col-12 col-md-6">
          <lp-label-field for="btnClass" class="{{arrayClassesElements.get('defaultLabel')}}" text="customization.config.themesform.btnClass"></lp-label-field>
          <lp-text-field id="btnClass" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="btnClass"
          [(ngModel)]="data.btnClass" placeholder="customization.config.themesform.btnClass"></lp-text-field>
      </div>

      <div class="form-group col-12 col-md-6">
          <lp-label-field for="primaryColor" class="{{arrayClassesElements.get('defaultLabel')}}" text="customization.config.themesform.primaryColor"></lp-label-field>
          <lp-text-field id="primaryColor" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="primaryColor"
          [(ngModel)]="data.primaryColor" placeholder="customization.config.themesform.primaryColor"></lp-text-field>
      </div>

      <div class="form-group col-12 col-md-6">
          <lp-label-field for="available" class="{{arrayClassesElements.get('defaultLabel')}}" text="customization.config.themesform.available"></lp-label-field>
          <lp-boolean-switch-box id="available" ignoreChange="true" name="available" switchName="availableSwitch" [ngModel]="data.available" (ngModelChange)="data.available=$event"></lp-boolean-switch-box>
      </div>

      <div class="text-end">
        <button class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
</lp-ui-accordion>