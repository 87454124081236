export class BreadcrumbInfoItem {
    public label: String;
    public value: String;
    public type: Number;

    constructor(label: String, value: String, type?: Number) {
        this.label = label;
        this.value = value;
        this.type = type;
    }
}
